import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Date: any;
    DateTime: any;
    JSON: any;
    URL: any;
    Upload: any;
};

export type AppVersion = IAppVersion & {
    code: Scalars['String'];
    currentVersion: Scalars['String'];
    id: Scalars['ID'];
    name: Scalars['String'];
    type: Scalars['String'];
};

export type AppVersionFilterInput = {
    /** 編號 */
    code?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 類型 */
    type?: InputMaybe<Scalars['String']>;
};

export type Auth = {
    token?: Maybe<Scalars['String']>;
    user?: Maybe<User>;
};

export type BaseFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type Business = IBusiness & {
    /** 客戶預算金額 */
    budgetAmount?: Maybe<Scalars['Float']>;
    /** 購買機會 */
    buyingOpportunity?: Maybe<BusinessOpportunity>;
    /** 購買機會 */
    buyingOpportunityId?: Maybe<Scalars['ID']>;
    /** 實際結束日期 */
    closedDate?: Maybe<Scalars['Date']>;
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 競爭對手 */
    competitors: Array<Competitor>;
    /** 內容 */
    content?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUser?: Maybe<User>;
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 客戶 */
    customer?: Maybe<Customer>;
    /** 客戶 */
    customerId?: Maybe<Scalars['ID']>;
    /** 雙方成交金額 */
    dealAmount?: Maybe<Scalars['Float']>;
    /** 經銷商 */
    dealer?: Maybe<Customer>;
    /** 經銷商 */
    dealerId?: Maybe<Scalars['ID']>;
    /** 預計結束日期 */
    expectedClosedDate?: Maybe<Scalars['Date']>;
    /** 眼科報價單編號 */
    eyeQuotationOrderCode?: Maybe<Scalars['String']>;
    /** 調整格式後的屬性 */
    formattedProperties: Array<FormattedBusinessProperties>;
    id: Scalars['ID'];
    /** 丟單改善計畫 */
    losingImprovementPlan?: Maybe<Scalars['String']>;
    /** 丟單原因 */
    losingReason?: Maybe<Scalars['String']>;
    /** 丟單原因 */
    losingReasons: Array<BusinessLosingReason>;
    /** 預算商品 */
    mapBudgetProducts: Array<BusinessesToBudgetProduct>;
    /** 成交商品 */
    mapDealProducts: Array<BusinessesToDealProduct>;
    /** 備註 */
    memos: Array<BusinessMemo>;
    /** 訂單編號（鼎新、道一雲、用友） */
    orderCode?: Maybe<Scalars['String']>;
    /** 主要負責聯絡人 */
    primaryContactPeople: Array<ContactPerson>;
    /** 主要負責人員 */
    primaryUser?: Maybe<User>;
    /** 主要負責業務（棄用；改用 sales_team_unit_id ） */
    primaryUserId?: Maybe<Scalars['ID']>;
    /** 屬性 */
    properties: Array<BusinessProperty>;
    /** 業務銷售金額 */
    saleAmount?: Maybe<Scalars['Float']>;
    /** 商機銷售方式 */
    salesMethod?: Maybe<BusinessSalesMethod>;
    /** 銷售方式 */
    salesMethodId?: Maybe<Scalars['ID']>;
    /** 業務團隊 */
    salesTeam?: Maybe<SalesTeam>;
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 負責業務團隊 */
    salesTeamId?: Maybe<Scalars['ID']>;
    /** 业务团队位置 */
    salesTeamUnit?: Maybe<SalesTeamUnit>;
    /** 業務團隊單位 */
    salesTeamUnitId?: Maybe<Scalars['ID']>;
    /** 商機狀態 */
    status?: Maybe<BusinessStatus>;
    /** 狀態 */
    statusId?: Maybe<Scalars['ID']>;
    /** 標題 */
    title?: Maybe<Scalars['String']>;
    /** 商機類型 */
    type?: Maybe<BusinessType>;
    /** 類型 */
    typeId?: Maybe<Scalars['ID']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUser?: Maybe<User>;
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 負責（支援）業務 */
    users: Array<BusinessesUser>;
    /** 拜訪 */
    visits: Array<Visit>;
    /** 贏單機會 */
    winningOpportunity?: Maybe<BusinessOpportunity>;
    /** 贏單機會 */
    winningOpportunityId?: Maybe<Scalars['ID']>;
};

export type BusinessAnalysis = {
    /** 商機統計 */
    businessStat: BusinessAnalysisBusinessStat;
    /** 商機商品統計排名 */
    productRank: Array<BusinessAnalysisProductRank>;
};

export type BusinessAnalysisBusinessStatArgs = {
    filters?: InputMaybe<BusinessAnalysisBusinessStatFilterInput>;
};

export type BusinessAnalysisProductRankArgs = {
    filters?: InputMaybe<BusinessAnalysisProductRankFilterInput>;
};

export type BusinessAnalysisBusinessStat = {
    /** 細項統計 */
    details: Array<BusinessAnalysisBusinessStat_Detail>;
    /** 總體統計 */
    overall: BusinessAnalysisBusinessStat_Overall;
};

export type BusinessAnalysisBusinessStatFilterInput = {
    /** 預算商品（複選） */
    budgetProductIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 購買機會（複選） */
    buyingOpportunityIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 實際結案日期（起） */
    closedDate1?: InputMaybe<Scalars['String']>;
    /** 實際結案日期（迄） */
    closedDate2?: InputMaybe<Scalars['String']>;
    /** 編號 */
    code?: InputMaybe<Scalars['String']>;
    /** 競爭對手 */
    competitorId?: InputMaybe<Scalars['ID']>;
    /** 建立日期（起） */
    createdDate1?: InputMaybe<Scalars['String']>;
    /** 建立日期（迄） */
    createdDate2?: InputMaybe<Scalars['String']>;
    /** 客戶營業統一編號 */
    customerBusinessCode?: InputMaybe<Scalars['String']>;
    /** 客戶編號 */
    customerCode?: InputMaybe<Scalars['String']>;
    /** 客戶 */
    customerId?: InputMaybe<Scalars['ID']>;
    /** 客戶（複選） */
    customerIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 客戶醫事機構代碼 */
    customerMedicalCode?: InputMaybe<Scalars['String']>;
    /** 客戶名稱 */
    customerName?: InputMaybe<Scalars['String']>;
    /** 成交商品（複選） */
    dealProductIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 預計結案日期（起） */
    expectedClosedDate1?: InputMaybe<Scalars['String']>;
    /** 預計結案日期（迄） */
    expectedClosedDate2?: InputMaybe<Scalars['String']>;
    /** 是否有「已拜訪」的拜訪資料 */
    hasVisitedVisit?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 關鍵字（同時查詢「編號、客戶名稱」欄位） */
    keyword?: InputMaybe<Scalars['String']>;
    /** 訂單編號 */
    orderCode?: InputMaybe<Scalars['String']>;
    /** 主要負責聯絡人（複選） */
    primaryContactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 主要負責業務 */
    primaryUserId?: InputMaybe<Scalars['ID']>;
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 屬性（複選） */
    propertyIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 屬性類型（複選） */
    propertyTypeIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 區域 */
    regionId?: InputMaybe<Scalars['ID']>;
    /** 銷售方式 */
    salesMethodId?: InputMaybe<Scalars['ID']>;
    /** 業務團隊組織 */
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    /** 负责业务位置 */
    salesTeamUnitId?: InputMaybe<Scalars['ID']>;
    /** 负责业务位置（複選） */
    salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 狀態（複選） */
    statusIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 狀態類型（複選） */
    statusTypes?: InputMaybe<Array<EnumBusinessStatusType>>;
    /** 成交機會 */
    transactionOpportunity?: InputMaybe<EnumBusinessTransactionOpportunity>;
    /** 更新日期（起） */
    updatedDate1?: InputMaybe<Scalars['String']>;
    /** 更新日期（迄） */
    updatedDate2?: InputMaybe<Scalars['String']>;
    /** 負責（支援）業務 */
    userId?: InputMaybe<Scalars['ID']>;
    /** 負責（支援）業務（複選） */
    userIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 拜訪查詢項 */
    visit?: InputMaybe<VisitFilterInput>;
    /** 贏單機會（複選） */
    winningOpportunityIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessAnalysisBusinessStat_Detail = {
    /** 金額 */
    amount: Scalars['Float'];
    /** 金額百分比（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（總計金額）為 0 時，百分比顯示為空值 */
    amountPercent?: Maybe<Scalars['Float']>;
    /** 數量 */
    count: Scalars['Int'];
    /** 數量百分比（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（總計數量）為 0 時，百分比顯示為空值 */
    countPercent?: Maybe<Scalars['Float']>;
    /** 類型 */
    type: EnumBusinessStatType;
};

export type BusinessAnalysisBusinessStat_Overall = {
    /** 金額 */
    amount: Scalars['Float'];
    /** 數量 */
    count: Scalars['Int'];
};

export type BusinessAnalysisProductRank = {
    businessProduct: BusinessProduct;
    /** 商機商品 */
    businessProductId: Scalars['ID'];
    /** 數量 */
    qty: Scalars['Int'];
    /** 排名 */
    rank: Scalars['Int'];
    /** 流水號 */
    seq: Scalars['Int'];
};

export type BusinessAnalysisProductRankFilterInput = {
    /** 預算商品（複選） */
    budgetProductIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 購買機會（複選） */
    buyingOpportunityIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 實際結案日期（起） */
    closedDate1?: InputMaybe<Scalars['String']>;
    /** 實際結案日期（迄） */
    closedDate2?: InputMaybe<Scalars['String']>;
    /** 編號 */
    code?: InputMaybe<Scalars['String']>;
    /** 競爭對手 */
    competitorId?: InputMaybe<Scalars['ID']>;
    /** 建立日期（起） */
    createdDate1?: InputMaybe<Scalars['String']>;
    /** 建立日期（迄） */
    createdDate2?: InputMaybe<Scalars['String']>;
    /** 客戶營業統一編號 */
    customerBusinessCode?: InputMaybe<Scalars['String']>;
    /** 客戶編號 */
    customerCode?: InputMaybe<Scalars['String']>;
    /** 客戶 */
    customerId?: InputMaybe<Scalars['ID']>;
    /** 客戶（複選） */
    customerIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 客戶醫事機構代碼 */
    customerMedicalCode?: InputMaybe<Scalars['String']>;
    /** 客戶名稱 */
    customerName?: InputMaybe<Scalars['String']>;
    /** 成交商品（複選） */
    dealProductIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 預計結案日期（起） */
    expectedClosedDate1?: InputMaybe<Scalars['String']>;
    /** 預計結案日期（迄） */
    expectedClosedDate2?: InputMaybe<Scalars['String']>;
    /** 是否有「已拜訪」的拜訪資料 */
    hasVisitedVisit?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 關鍵字（同時查詢「編號、客戶名稱」欄位） */
    keyword?: InputMaybe<Scalars['String']>;
    /** 訂單編號 */
    orderCode?: InputMaybe<Scalars['String']>;
    /** 主要負責聯絡人（複選） */
    primaryContactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 主要負責業務 */
    primaryUserId?: InputMaybe<Scalars['ID']>;
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 屬性（複選） */
    propertyIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 屬性類型（複選） */
    propertyTypeIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 區域 */
    regionId?: InputMaybe<Scalars['ID']>;
    /** 銷售方式 */
    salesMethodId?: InputMaybe<Scalars['ID']>;
    /** 業務團隊組織 */
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    /** 负责业务位置 */
    salesTeamUnitId?: InputMaybe<Scalars['ID']>;
    /** 负责业务位置（複選） */
    salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 狀態（複選） */
    statusIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 狀態類型（複選） */
    statusTypes?: InputMaybe<Array<EnumBusinessStatusType>>;
    /** 成交機會 */
    transactionOpportunity?: InputMaybe<EnumBusinessTransactionOpportunity>;
    /** 更新日期（起） */
    updatedDate1?: InputMaybe<Scalars['String']>;
    /** 更新日期（迄） */
    updatedDate2?: InputMaybe<Scalars['String']>;
    /** 負責（支援）業務 */
    userId?: InputMaybe<Scalars['ID']>;
    /** 負責（支援）業務（複選） */
    userIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 拜訪查詢項 */
    visit?: InputMaybe<VisitFilterInput>;
    /** 贏單機會（複選） */
    winningOpportunityIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessBudgetProductFilterInput = {
    businessId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessBudgetProductInput = {
    budgetProductId?: InputMaybe<Scalars['ID']>;
    qty?: InputMaybe<Scalars['Int']>;
};

export type BusinessCreateInput = {
    budgetAmount?: InputMaybe<Scalars['Float']>;
    budgetProducts?: InputMaybe<Array<BusinessBudgetProductInput>>;
    buyingOpportunityId?: InputMaybe<Scalars['ID']>;
    closedDate?: InputMaybe<Scalars['DateTime']>;
    competitorIds?: InputMaybe<Array<Scalars['ID']>>;
    content?: InputMaybe<Scalars['String']>;
    /** 客戶 */
    customerId?: InputMaybe<Scalars['ID']>;
    dealAmount?: InputMaybe<Scalars['Float']>;
    dealProducts?: InputMaybe<Array<BusinessDealProductInput>>;
    /** 經銷商 */
    dealerId?: InputMaybe<Scalars['ID']>;
    expectedClosedDate?: InputMaybe<Scalars['DateTime']>;
    /** 报价单编号 */
    eyeQuotationOrderCode?: InputMaybe<Scalars['String']>;
    losingImprovementPlan?: InputMaybe<Scalars['String']>;
    losingReason?: InputMaybe<Scalars['String']>;
    /** 丟單原因 */
    losingReasonIds?: InputMaybe<Array<Scalars['ID']>>;
    orderCode?: InputMaybe<Scalars['String']>;
    primaryContactPeopleIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 主要負責業務 */
    primaryUserId?: InputMaybe<Scalars['ID']>;
    propertyIds?: InputMaybe<Array<Scalars['ID']>>;
    saleAmount?: InputMaybe<Scalars['Float']>;
    /** 銷售方式 */
    salesMethodId?: InputMaybe<Scalars['ID']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    salesTeamId?: InputMaybe<Scalars['ID']>;
    /** 业务团队位置 */
    salesTeamUnitId?: InputMaybe<Scalars['ID']>;
    statusId?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    typeId?: InputMaybe<Scalars['ID']>;
    /** 負責（支援）業務 */
    users?: InputMaybe<Array<BusinessUserInput>>;
    winningOpportunityId?: InputMaybe<Scalars['ID']>;
};

export type BusinessDealProductFilterInput = {
    businessId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessDealProductInput = {
    dealProductId?: InputMaybe<Scalars['ID']>;
    qty?: InputMaybe<Scalars['Int']>;
};

export type BusinessFilterInput = {
    /** 預算商品（複選） */
    budgetProductIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 購買機會（複選） */
    buyingOpportunityIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 實際結案日期（起） */
    closedDate1?: InputMaybe<Scalars['String']>;
    /** 實際結案日期（迄） */
    closedDate2?: InputMaybe<Scalars['String']>;
    /** 編號 */
    code?: InputMaybe<Scalars['String']>;
    /** 競爭對手 */
    competitorId?: InputMaybe<Scalars['ID']>;
    /** 建立日期（起） */
    createdDate1?: InputMaybe<Scalars['String']>;
    /** 建立日期（迄） */
    createdDate2?: InputMaybe<Scalars['String']>;
    /** 客戶營業統一編號 */
    customerBusinessCode?: InputMaybe<Scalars['String']>;
    /** 客戶編號 */
    customerCode?: InputMaybe<Scalars['String']>;
    /** 客戶 */
    customerId?: InputMaybe<Scalars['ID']>;
    /** 客戶（複選） */
    customerIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 客戶醫事機構代碼 */
    customerMedicalCode?: InputMaybe<Scalars['String']>;
    /** 客戶名稱 */
    customerName?: InputMaybe<Scalars['String']>;
    /** 成交商品（複選） */
    dealProductIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 預計結案日期（起） */
    expectedClosedDate1?: InputMaybe<Scalars['String']>;
    /** 預計結案日期（迄） */
    expectedClosedDate2?: InputMaybe<Scalars['String']>;
    /** 是否有「已拜訪」的拜訪資料 */
    hasVisitedVisit?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 關鍵字（同時查詢「編號、客戶名稱」欄位） */
    keyword?: InputMaybe<Scalars['String']>;
    /** 訂單編號 */
    orderCode?: InputMaybe<Scalars['String']>;
    /** 主要負責聯絡人（複選） */
    primaryContactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 主要負責業務 */
    primaryUserId?: InputMaybe<Scalars['ID']>;
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 屬性（複選） */
    propertyIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 屬性類型（複選） */
    propertyTypeIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 區域 */
    regionId?: InputMaybe<Scalars['ID']>;
    /** 銷售方式 */
    salesMethodId?: InputMaybe<Scalars['ID']>;
    /** 業務團隊組織 */
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    /** 负责业务位置 */
    salesTeamUnitId?: InputMaybe<Scalars['ID']>;
    /** 负责业务位置（複選） */
    salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 狀態（複選） */
    statusIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 狀態類型（複選） */
    statusTypes?: InputMaybe<Array<EnumBusinessStatusType>>;
    /** 成交機會 */
    transactionOpportunity?: InputMaybe<EnumBusinessTransactionOpportunity>;
    /** 更新日期（起） */
    updatedDate1?: InputMaybe<Scalars['String']>;
    /** 更新日期（迄） */
    updatedDate2?: InputMaybe<Scalars['String']>;
    /** 負責（支援）業務 */
    userId?: InputMaybe<Scalars['ID']>;
    /** 負責（支援）業務（複選） */
    userIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 拜訪查詢項 */
    visit?: InputMaybe<VisitFilterInput>;
    /** 贏單機會（複選） */
    winningOpportunityIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessInput = {
    budgetAmount?: InputMaybe<Scalars['Float']>;
    budgetProducts?: InputMaybe<Array<BusinessBudgetProductInput>>;
    buyingOpportunityId?: InputMaybe<Scalars['ID']>;
    closedDate?: InputMaybe<Scalars['DateTime']>;
    competitorIds?: InputMaybe<Array<Scalars['ID']>>;
    content?: InputMaybe<Scalars['String']>;
    /** 客戶 */
    customerId?: InputMaybe<Scalars['ID']>;
    dealAmount?: InputMaybe<Scalars['Float']>;
    dealProducts?: InputMaybe<Array<BusinessDealProductInput>>;
    /** 經銷商 */
    dealerId?: InputMaybe<Scalars['ID']>;
    expectedClosedDate?: InputMaybe<Scalars['DateTime']>;
    /** 报价单编号 */
    eyeQuotationOrderCode?: InputMaybe<Scalars['String']>;
    losingImprovementPlan?: InputMaybe<Scalars['String']>;
    losingReason?: InputMaybe<Scalars['String']>;
    /** 丟單原因 */
    losingReasonIds?: InputMaybe<Array<Scalars['ID']>>;
    orderCode?: InputMaybe<Scalars['String']>;
    primaryContactPeopleIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 主要負責業務 */
    primaryUserId?: InputMaybe<Scalars['ID']>;
    propertyIds?: InputMaybe<Array<Scalars['ID']>>;
    saleAmount?: InputMaybe<Scalars['Float']>;
    /** 銷售方式 */
    salesMethodId?: InputMaybe<Scalars['ID']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    salesTeamId?: InputMaybe<Scalars['ID']>;
    /** 业务团队位置 */
    salesTeamUnitId?: InputMaybe<Scalars['ID']>;
    statusId?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    typeId?: InputMaybe<Scalars['ID']>;
    /** 負責（支援）業務 */
    users?: InputMaybe<Array<BusinessUserInput>>;
    winningOpportunityId?: InputMaybe<Scalars['ID']>;
};

export type BusinessLosingReason = IBusinessLosingReason & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type BusinessLosingReasonFilterInput = {
    businessId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessMemo = IBusinessMemo & {
    /** 商機 */
    business: Business;
    /** 商機 */
    businessId: Scalars['ID'];
    /** 內容 */
    content?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUser?: Maybe<User>;
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    /** 標題 */
    title?: Maybe<Scalars['String']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUser?: Maybe<User>;
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type BusinessMemoCreateInput = {
    businessId?: InputMaybe<Scalars['ID']>;
    content?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
};

export type BusinessMemoFilterInput = {
    businessId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessMemoInput = {
    businessId?: InputMaybe<Scalars['ID']>;
    content?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
};

export type BusinessMemoUpdateInput = {
    businessId?: InputMaybe<Scalars['ID']>;
    content?: InputMaybe<Scalars['String']>;
    id: Scalars['ID'];
    title?: InputMaybe<Scalars['String']>;
};

export type BusinessOpportunity = IBusinessOpportunity & {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 是否啟用於前端畫面顯示 */
    enabled: Scalars['Boolean'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type BusinessOpportunityCreateInput = {
    code?: InputMaybe<Scalars['String']>;
    enabled?: InputMaybe<Scalars['Boolean']>;
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessOpportunityFilterInput = {
    /** 是否啟用於前端畫面顯示 */
    enabled?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessOpportunityInput = {
    code?: InputMaybe<Scalars['String']>;
    enabled?: InputMaybe<Scalars['Boolean']>;
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessOpportunityUpdateInput = {
    code?: InputMaybe<Scalars['String']>;
    enabled?: InputMaybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessProduct = IBusinessProduct & {
    /** 廠牌 */
    brand?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 是否啟用 */
    isActive: Scalars['Boolean'];
    /** 料號 */
    materials: Array<Material>;
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 商機商品類型 */
    type?: Maybe<BusinessProductType>;
    /** 類型 */
    typeId?: Maybe<Scalars['ID']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type BusinessProductCreateInput = {
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    typeId?: InputMaybe<Scalars['ID']>;
};

export type BusinessProductFilterInput = {
    /** 競爭商品 */
    competitorId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 是否啟用（查詢條件設置為 Null 時，同時取得「啟用」及「禁用」的資料） */
    isActive?: InputMaybe<Scalars['Boolean']>;
    /** 名稱 */
    name?: InputMaybe<Scalars['String']>;
    /** 業務團隊 */
    salesTeamId?: InputMaybe<Scalars['ID']>;
    /** 業務團隊（複選） */
    salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessProductInput = {
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    typeId?: InputMaybe<Scalars['ID']>;
};

export type BusinessProductType = IBusinessProductType & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type BusinessProductTypeFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessProductUpdateInput = {
    id: Scalars['ID'];
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    typeId?: InputMaybe<Scalars['ID']>;
};

export type BusinessProperty = IBusinessProperty & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 商機屬性類型 */
    type: BusinessPropertyType;
    /** 類型 */
    typeId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type BusinessPropertyCreateInput = {
    code?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    typeId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessPropertyFilterInput = {
    businessId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    typeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessPropertyInput = {
    code?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    typeId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessPropertyType = IBusinessPropertyType & {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 商機屬性 */
    properties: Array<BusinessProperty>;
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type BusinessPropertyTypeCreateInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessPropertyTypeFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessPropertyTypeInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessPropertyTypeUpdateInput = {
    id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessPropertyUpdateInput = {
    code?: InputMaybe<Scalars['String']>;
    id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    typeId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessSalesMethod = IBusinessSalesMethod & {
    /** 編號 */
    code?: Maybe<EnumBusinessSalesMethodCode>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type BusinessSalesMethodFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessSortInput = {
    direction: EnumSortDirection;
    name: EnumBusinessSortName;
};

export type BusinessStatus = IBusinessStatus & {
    /** 購買機會 */
    buyingOpportunity?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 類型 */
    type?: Maybe<EnumBusinessStatusType>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type BusinessStatusCreateInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<EnumBusinessStatusType>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessStatusFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessStatusInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<EnumBusinessStatusType>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessStatusUpdateInput = {
    id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<EnumBusinessStatusType>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessType = IBusinessType & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type BusinessTypeCreateInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessTypeFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessTypeInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessTypeUpdateInput = {
    id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessUpdateInput = {
    budgetAmount?: InputMaybe<Scalars['Float']>;
    budgetProducts?: InputMaybe<Array<BusinessBudgetProductInput>>;
    buyingOpportunityId?: InputMaybe<Scalars['ID']>;
    closedDate?: InputMaybe<Scalars['DateTime']>;
    competitorIds?: InputMaybe<Array<Scalars['ID']>>;
    content?: InputMaybe<Scalars['String']>;
    /** 客戶 */
    customerId?: InputMaybe<Scalars['ID']>;
    dealAmount?: InputMaybe<Scalars['Float']>;
    dealProducts?: InputMaybe<Array<BusinessDealProductInput>>;
    /** 經銷商 */
    dealerId?: InputMaybe<Scalars['ID']>;
    expectedClosedDate?: InputMaybe<Scalars['DateTime']>;
    /** 报价单编号 */
    eyeQuotationOrderCode?: InputMaybe<Scalars['String']>;
    id: Scalars['ID'];
    losingImprovementPlan?: InputMaybe<Scalars['String']>;
    losingReason?: InputMaybe<Scalars['String']>;
    /** 丟單原因 */
    losingReasonIds?: InputMaybe<Array<Scalars['ID']>>;
    orderCode?: InputMaybe<Scalars['String']>;
    primaryContactPeopleIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 主要負責業務 */
    primaryUserId?: InputMaybe<Scalars['ID']>;
    propertyIds?: InputMaybe<Array<Scalars['ID']>>;
    saleAmount?: InputMaybe<Scalars['Float']>;
    /** 銷售方式 */
    salesMethodId?: InputMaybe<Scalars['ID']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    salesTeamId?: InputMaybe<Scalars['ID']>;
    /** 业务团队位置 */
    salesTeamUnitId?: InputMaybe<Scalars['ID']>;
    statusId?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    typeId?: InputMaybe<Scalars['ID']>;
    /** 負責（支援）業務 */
    users?: InputMaybe<Array<BusinessUserInput>>;
    winningOpportunityId?: InputMaybe<Scalars['ID']>;
};

export type BusinessUserInput = {
    /** 業務團隊 */
    salesTeamId?: InputMaybe<Scalars['ID']>;
    /** 負責（支援）業務 */
    userId: Scalars['ID'];
};

export type BusinessesToBudgetProduct = IBusinessesToBudgetProduct & {
    /** 預算商品 */
    budgetProduct: BusinessProduct;
    /** 預算商品 */
    budgetProductId: Scalars['ID'];
    /** 商機 */
    business: Business;
    /** 商機 */
    businessId: Scalars['ID'];
    id: Scalars['ID'];
    /** 數量 */
    qty: Scalars['Int'];
};

export type BusinessesToDealProduct = IBusinessesToDealProduct & {
    /** 商機 */
    business: Business;
    /** 商機 */
    businessId: Scalars['ID'];
    /** 成交商品 */
    dealProduct: BusinessProduct;
    /** 成交商品 */
    dealProductId: Scalars['ID'];
    id: Scalars['ID'];
    /** 數量 */
    qty: Scalars['Int'];
};

export type BusinessesUser = IBusinessesUser & {
    /** 商機 */
    businessId: Scalars['ID'];
    /** 商機 */
    customer: Business;
    id: Scalars['ID'];
    /** 業務團隊 */
    salesTeam?: Maybe<SalesTeam>;
    /** 業務團隊 */
    salesTeamId?: Maybe<Scalars['ID']>;
    /** 負責（協助）業務 */
    user: User;
    /** 負責（協助）業務 */
    userId: Scalars['ID'];
};

export type City = ICity & {
    areaId?: Maybe<Scalars['ID']>;
    createdAt: Scalars['DateTime'];
    /** 縣區、三級行政區 */
    districts: Array<District>;
    id: Scalars['ID'];
    name: Scalars['String'];
    /** 省區、一級行政區 */
    province: Province;
    provinceId: Scalars['ID'];
    /** 區域 */
    region: Region;
    regionId: Scalars['ID'];
    updatedAt: Scalars['DateTime'];
    viewOrder: Scalars['Int'];
};

export type CityFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    provinceId?: InputMaybe<Scalars['ID']>;
    provinceIds?: InputMaybe<Array<Scalars['ID']>>;
    regionId?: InputMaybe<Scalars['ID']>;
};

export type Company = ICompany & {
    /** 公司編號 */
    code?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    /** 部門 */
    depts: Array<Department>;
    enabled?: Maybe<Scalars['Boolean']>;
    hrCode?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    name: Scalars['String'];
    phone?: Maybe<Scalars['String']>;
    /** 區域 */
    region: Region;
    regionId: Scalars['ID'];
    /** 業務團隊組織 */
    salesTeamGroups: Array<SalesTeamGroup>;
    updatedAt: Scalars['DateTime'];
};

export type CompanyFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 區域 */
    regionId?: InputMaybe<Scalars['ID']>;
    /** 業務團隊組織 */
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
};

export type Competitor = ICompetitor & {
    /** 對方優勢 */
    advantage?: Maybe<Scalars['String']>;
    /** 代理商 */
    agents?: Maybe<Scalars['String']>;
    /** 廠牌 */
    brand?: Maybe<Scalars['String']>;
    /** 商機商品 */
    businessProducts?: Maybe<Array<BusinessProduct>>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUser?: Maybe<User>;
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 對方劣勢 */
    disadvantage?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    /** 備註 */
    memo?: Maybe<Scalars['String']>;
    /** 機型 */
    model?: Maybe<Scalars['String']>;
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 應對策略 */
    strategy?: Maybe<Scalars['String']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUser?: Maybe<User>;
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type CompetitorCreateInput = {
    advantage?: InputMaybe<Scalars['String']>;
    agents?: InputMaybe<Scalars['String']>;
    brand?: InputMaybe<Scalars['String']>;
    businessProductIds?: InputMaybe<Array<Scalars['ID']>>;
    disadvantage?: InputMaybe<Scalars['String']>;
    memo?: InputMaybe<Scalars['String']>;
    model?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    strategy?: InputMaybe<Scalars['String']>;
};

export type CompetitorFilterInput = {
    /** 代理商 */
    agents?: InputMaybe<Scalars['String']>;
    /** 廠牌 */
    brand?: InputMaybe<Scalars['String']>;
    /** 商機 */
    businessId?: InputMaybe<Scalars['ID']>;
    /** 商機商品（複選） */
    businessProductIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 商機商品名稱 */
    businessProductName?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 關鍵字（同時查詢「名稱、廠牌、機型、代理商」欄位） */
    keyword?: InputMaybe<Scalars['String']>;
    /** 機型 */
    model?: InputMaybe<Scalars['String']>;
    /** 名稱 */
    name?: InputMaybe<Scalars['String']>;
};

export type CompetitorInput = {
    advantage?: InputMaybe<Scalars['String']>;
    agents?: InputMaybe<Scalars['String']>;
    brand?: InputMaybe<Scalars['String']>;
    businessProductIds?: InputMaybe<Array<Scalars['ID']>>;
    disadvantage?: InputMaybe<Scalars['String']>;
    memo?: InputMaybe<Scalars['String']>;
    model?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    strategy?: InputMaybe<Scalars['String']>;
};

export type CompetitorUpdateInput = {
    advantage?: InputMaybe<Scalars['String']>;
    agents?: InputMaybe<Scalars['String']>;
    brand?: InputMaybe<Scalars['String']>;
    businessProductIds?: InputMaybe<Array<Scalars['ID']>>;
    disadvantage?: InputMaybe<Scalars['String']>;
    id: Scalars['ID'];
    memo?: InputMaybe<Scalars['String']>;
    model?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    strategy?: InputMaybe<Scalars['String']>;
};

export type ContactPerson = IContactPerson & {
    /** 地址 */
    address?: Maybe<Scalars['String']>;
    /** 身分證字號／公民身份號碼 */
    citizenCode?: Maybe<Scalars['String']>;
    /** 編號（內勤系統自動添加） */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUser?: Maybe<User>;
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 客戶 */
    customers: Array<Customer>;
    /** 部門 */
    department?: Maybe<ContactPersonDepartment>;
    /** 部門 */
    departmentId?: Maybe<Scalars['ID']>;
    /** 部門（棄用） */
    dept?: Maybe<Scalars['String']>;
    /** 醫師代碼 */
    doctorCode?: Maybe<Scalars['String']>;
    /** 電子信箱 */
    email?: Maybe<Scalars['String']>;
    /** 傳真電話 */
    fax?: Maybe<Scalars['String']>;
    /** 性別 */
    gender?: Maybe<EnumContactPeopleGender>;
    /** 興趣愛好 */
    hobby?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    /** 職稱 */
    jobTitle?: Maybe<Scalars['String']>;
    /** 備註 */
    memo?: Maybe<Scalars['String']>;
    /** 行動電話 */
    mobile?: Maybe<Scalars['String']>;
    /** 姓名 */
    name: Scalars['String'];
    /** 暱稱 */
    nickname?: Maybe<Scalars['String']>;
    /** 座機電話 */
    phone?: Maybe<Scalars['String']>;
    /** 主要負責業務 */
    primaryUsers: Array<User>;
    /** 轉介編號 */
    referenceCode?: Maybe<Scalars['String']>;
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 业务团队位置 */
    salesTeamUnits: Array<SalesTeamUnit>;
    /** 專長技能 */
    skill?: Maybe<Scalars['String']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUser?: Maybe<User>;
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 負責業務 */
    users: Array<User>;
    /** 網站 */
    website?: Maybe<Scalars['URL']>;
};

export type ContactPersonCreateInput = {
    address?: InputMaybe<Scalars['String']>;
    citizenCode?: InputMaybe<Scalars['String']>;
    customerIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 部門 */
    departmentId?: InputMaybe<Scalars['ID']>;
    dept?: InputMaybe<Scalars['String']>;
    doctorCode?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    fax?: InputMaybe<Scalars['String']>;
    gender?: InputMaybe<EnumContactPeopleGender>;
    hobby?: InputMaybe<Scalars['String']>;
    jobTitle?: InputMaybe<Scalars['String']>;
    memo?: InputMaybe<Scalars['String']>;
    mobile?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    nickname?: InputMaybe<Scalars['String']>;
    phone?: InputMaybe<Scalars['String']>;
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    /** 业务团队位置 */
    salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
    skill?: InputMaybe<Scalars['String']>;
    userIds?: InputMaybe<Array<Scalars['ID']>>;
    website?: InputMaybe<Scalars['String']>;
};

export type ContactPersonDepartment = IContactPersonDepartment & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type ContactPersonDepartmentFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type ContactPersonFilterInput = {
    address?: InputMaybe<Scalars['String']>;
    businessId?: InputMaybe<Scalars['ID']>;
    code?: InputMaybe<Scalars['String']>;
    customerBusinessCode?: InputMaybe<Scalars['String']>;
    customerCode?: InputMaybe<Scalars['String']>;
    customerId?: InputMaybe<Scalars['ID']>;
    customerMedicalCode?: InputMaybe<Scalars['String']>;
    customerName?: InputMaybe<Scalars['String']>;
    /** 部門 */
    departmentId?: InputMaybe<Scalars['ID']>;
    dept?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    jobTitle?: InputMaybe<Scalars['String']>;
    mobile?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    /** 排除客戶 */
    notCustomerId?: InputMaybe<Scalars['ID']>;
    phone?: InputMaybe<Scalars['String']>;
    primaryUserId?: InputMaybe<Scalars['ID']>;
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    salesTeamUnitId?: InputMaybe<Scalars['ID']>;
    salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
    skill?: InputMaybe<Scalars['String']>;
    userId?: InputMaybe<Scalars['ID']>;
};

export type ContactPersonInput = {
    address?: InputMaybe<Scalars['String']>;
    citizenCode?: InputMaybe<Scalars['String']>;
    customerIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 部門 */
    departmentId?: InputMaybe<Scalars['ID']>;
    dept?: InputMaybe<Scalars['String']>;
    doctorCode?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    fax?: InputMaybe<Scalars['String']>;
    gender?: InputMaybe<EnumContactPeopleGender>;
    hobby?: InputMaybe<Scalars['String']>;
    jobTitle?: InputMaybe<Scalars['String']>;
    memo?: InputMaybe<Scalars['String']>;
    mobile?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    nickname?: InputMaybe<Scalars['String']>;
    phone?: InputMaybe<Scalars['String']>;
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    /** 业务团队位置 */
    salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
    skill?: InputMaybe<Scalars['String']>;
    userIds?: InputMaybe<Array<Scalars['ID']>>;
    website?: InputMaybe<Scalars['String']>;
};

export type ContactPersonUpdateInput = {
    address?: InputMaybe<Scalars['String']>;
    citizenCode?: InputMaybe<Scalars['String']>;
    customerIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 部門 */
    departmentId?: InputMaybe<Scalars['ID']>;
    dept?: InputMaybe<Scalars['String']>;
    doctorCode?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    fax?: InputMaybe<Scalars['String']>;
    gender?: InputMaybe<EnumContactPeopleGender>;
    hobby?: InputMaybe<Scalars['String']>;
    id: Scalars['ID'];
    jobTitle?: InputMaybe<Scalars['String']>;
    memo?: InputMaybe<Scalars['String']>;
    mobile?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    nickname?: InputMaybe<Scalars['String']>;
    phone?: InputMaybe<Scalars['String']>;
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    /** 业务团队位置 */
    salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
    skill?: InputMaybe<Scalars['String']>;
    userIds?: InputMaybe<Array<Scalars['ID']>>;
    website?: InputMaybe<Scalars['String']>;
};

export type CostCenter = ICostCenter & {
    /** 編號 */
    code: Scalars['String'];
    /** 區域 */
    company: Region;
    /** 公司 */
    companyId: Scalars['ID'];
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 是否啟用 */
    isActive?: Maybe<Scalars['Boolean']>;
    /** 名稱 */
    name: Scalars['String'];
    /** 區域 */
    region: Region;
    /** 區域 */
    regionId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
};

export type CostCenterFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    provinceId?: InputMaybe<Scalars['ID']>;
    provinceIds?: InputMaybe<Array<Scalars['ID']>>;
    regionId?: InputMaybe<Scalars['ID']>;
};

export type CreateCustomerToContactPeopleInput = {
    contactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
    id?: InputMaybe<Scalars['ID']>;
};

export type CreditPeriod = ICreditPeriod & {
    createdAt?: Maybe<Scalars['DateTime']>;
    /** 天數 */
    days: Scalars['Int'];
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    name: Scalars['String'];
    /** 區域 */
    region: Region;
    regionId: Scalars['ID'];
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreditPeriodFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    regionId?: InputMaybe<Scalars['ID']>;
};

export type Currency = ICurrency & {
    /** ISO 4217 currency codes */
    code: Scalars['String'];
    /** 小數點後幾位 */
    decimalPlace: Scalars['Int'];
    id: Scalars['ID'];
    name: Scalars['String'];
    /** 貨幣符號 */
    symbol: Scalars['String'];
};

export type CurrencyFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type Customer = ICustomer & {
    /** 地址 */
    address?: Maybe<Scalars['String']>;
    /** 分區 */
    area?: Maybe<CustomerArea>;
    /** 分區 */
    areaId?: Maybe<Scalars['ID']>;
    /** 附件 */
    attachments: Array<CustomerAttachment>;
    /** 銀行戶頭代碼 */
    bankAccountCode?: Maybe<Scalars['String']>;
    /** 銀行戶頭名稱 */
    bankAccountName?: Maybe<Scalars['String']>;
    /** 開票單位名稱 */
    billingUnitName?: Maybe<Scalars['String']>;
    /** 營業統一編號 */
    businessCode?: Maybe<Scalars['String']>;
    /** 類別 */
    category?: Maybe<CustomerCategory>;
    /** 分類 */
    categoryId?: Maybe<Scalars['ID']>;
    /** 證照 */
    certificates: Array<CustomerCertificate>;
    /** 下層客戶 */
    children: Array<Customer>;
    /** 市級、二級行政區 */
    city?: Maybe<City>;
    /** 市級、二級行政區 */
    cityId?: Maybe<Scalars['ID']>;
    /** 編號（鼎新／內部通用） */
    code?: Maybe<Scalars['String']>;
    /** 聯絡人 */
    contactPeople: Array<ContactPerson>;
    /** 總負責聯絡人姓名 */
    contactPersonName?: Maybe<Scalars['String']>;
    /** 總負責聯絡人電話 */
    contactPersonPhone?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUser?: Maybe<User>;
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 信用期限（科林評定） */
    creditPeriod?: Maybe<CreditPeriod>;
    /** 信用期限（科林評定） */
    creditPeriodId?: Maybe<Scalars['ID']>;
    /** 信用額度（科林評定） */
    creditQuota?: Maybe<Scalars['Float']>;
    /** 預設付款方式 */
    defaultPaymentMethodId?: Maybe<Scalars['ID']>;
    /** 預設配送方式 */
    defaultShippingMethodId?: Maybe<Scalars['ID']>;
    /** 縣級、三級行政區 */
    district?: Maybe<District>;
    /** 縣級、三級行政區 */
    districtId?: Maybe<Scalars['ID']>;
    /** 編號（道一雲） */
    do1Code?: Maybe<Scalars['String']>;
    /** 電子信箱 */
    email?: Maybe<Scalars['String']>;
    eyeServiceOrders: Array<EyeServiceOrder>;
    /** 傳真電話 */
    fax?: Maybe<Scalars['String']>;
    /** 調整格式後的屬性 */
    formattedProperties: Array<FormattedCustomerProperties>;
    /** 集團 */
    group?: Maybe<CustomerGroup>;
    /** 集團 */
    groupId?: Maybe<Scalars['ID']>;
    /** 是否有生效的首營資料證照 */
    hasEffectiveGmpCertificate?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    /** 是否啟用 */
    isActive: Scalars['Boolean'];
    /** 是否為首營客戶 */
    isGmp?: Maybe<Scalars['Boolean']>;
    /** 最近 BPM 首營資料 Instance ID */
    lastGmpBpmInstanceId?: Maybe<Scalars['ID']>;
    /** 最近 BPM 首營資料申請狀態 */
    lastGmpBpmStatus?: Maybe<EnumLastGmpBpmStatus>;
    /** 最近 BPM 首營資料申請人員 */
    lastGmpBpmUserId?: Maybe<Scalars['ID']>;
    /** 法人姓名 */
    legalPersonName?: Maybe<Scalars['String']>;
    /** 等級 */
    levels: Array<CustomerLevel>;
    /** 醫事機構代碼 */
    medicalCode?: Maybe<Scalars['String']>;
    /** 備註 */
    memo?: Maybe<Scalars['String']>;
    /** 行動電話 */
    mobile?: Maybe<Scalars['String']>;
    /** 名稱 */
    name: Scalars['String'];
    /** 編號（NAV） */
    navCode?: Maybe<Scalars['String']>;
    /** 上層客戶 */
    parent?: Maybe<Customer>;
    /** 上層客戶 */
    parentId?: Maybe<Scalars['ID']>;
    /** 座機電話 */
    phone?: Maybe<Scalars['String']>;
    /** 主要負責業務 */
    primaryUsers: Array<CustomersPrimaryUser>;
    /** 屬性 */
    properties: Array<CustomerProperty>;
    /** 省級、一級行政區 */
    province?: Maybe<Province>;
    /** 省級、一級行政區 */
    provinceId?: Maybe<Scalars['ID']>;
    /** 轉介編號 */
    referenceCode?: Maybe<Scalars['String']>;
    /** 區域 */
    region: Region;
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    salesTeamUnits: Array<SalesTeamUnit>;
    /** 收貨地址 */
    shippingAddress?: Maybe<Scalars['String']>;
    /** 簡稱 */
    shortName?: Maybe<Scalars['String']>;
    /** 同步代碼 */
    syncCode?: Maybe<Scalars['String']>;
    /** 同步來源 */
    syncSourceId?: Maybe<Scalars['ID']>;
    /** 類型 */
    type?: Maybe<CustomerType>;
    /** 類型 */
    typeId?: Maybe<Scalars['ID']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUser?: Maybe<User>;
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 負責業務 */
    users: Array<User>;
    /** 網站 */
    website?: Maybe<Scalars['URL']>;
    /** 編號（用友） */
    yonyouCode?: Maybe<Scalars['String']>;
};

export type CustomerAnalysis = {
    /** 客戶等級類型統計 */
    levelTypeStat: CustomerAnalysisLevelTypeStat;
};

export type CustomerAnalysisLevelTypeStatArgs = {
    filters?: InputMaybe<CustomerAnalysisLevelTypeStatFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type CustomerAnalysisLevelTypeStat = {
    /** 細項統計 */
    details: Array<CustomerAnalysisLevelTypeStat_Detail>;
    /** 總體統計 */
    overall: CustomerAnalysisLevelTypeStat_Overall;
};

export type CustomerAnalysisLevelTypeStatFilterInput = {
    /** 客戶查詢項 */
    customer?: InputMaybe<CustomerFilterInput>;
    /** 客戶等級類型 */
    customerLevelTypeId: Scalars['ID'];
};

export type CustomerAnalysisLevelTypeStat_Detail = {
    /** 客戶數 */
    count: Scalars['Int'];
    /** 客戶等級欄位類型 */
    levelFieldType: CustomerLevelFieldType;
    /** 客戶等級欄位類型 */
    levelFieldTypeId: Scalars['ID'];
    /** 客戶等級欄位類型 */
    levelFields: Array<CustomerAnalysisLevelTypeStat_Detail_LevelField>;
};

export type CustomerAnalysisLevelTypeStat_Detail_LevelField = {
    /** 客戶地區 */
    areas: Array<CustomerAnalysisLevelTypeStat_Detail_LevelField_Area>;
    /** 客戶數 */
    count: Scalars['Int'];
    /** 客戶數百分比（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    countPercent?: Maybe<Scalars['Float']>;
    /** 客戶等級欄位 */
    levelField?: Maybe<CustomerLevelField>;
    /** 客戶等級欄位 */
    levelFieldId: Scalars['ID'];
};

export type CustomerAnalysisLevelTypeStat_Detail_LevelField_Area = {
    /** 客戶地區 */
    area?: Maybe<CustomerArea>;
    /** 客戶地區（數值為 Null 時，代表「未分類」） */
    areaId?: Maybe<Scalars['ID']>;
    /** 客戶數 */
    count: Scalars['Int'];
    /** 客戶數百分比（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    countPercent?: Maybe<Scalars['Float']>;
    /** 等級欄位類型中的客戶數百分比（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    countPercentInLevelFieldType?: Maybe<Scalars['Float']>;
};

export type CustomerAnalysisLevelTypeStat_Overall = {
    /** 客戶數 */
    count: Scalars['Int'];
};

export type CustomerArea = ICustomerArea & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type CustomerAreaCreateInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerAreaFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type CustomerAreaInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerAreaUpdateInput = {
    id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerAttachment = ICustomerAttachment & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUser?: Maybe<User>;
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 客戶 */
    customer: Customer;
    /** 客戶 */
    customerId: Scalars['ID'];
    /** 副檔名 */
    extension: Scalars['String'];
    id: Scalars['ID'];
    /** 備註 */
    memo?: Maybe<Scalars['String']>;
    /** 名稱 */
    name: Scalars['String'];
    /** S3 Key */
    s3Key: Scalars['String'];
    /** 檔案大小 (Bytes) */
    size: Scalars['Float'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUser?: Maybe<User>;
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 短效期的 S3 URL */
    url?: Maybe<Scalars['URL']>;
};

export type CustomerAttachmentBulkCreateInput = {
    attachments: Array<CustomerAttachmentCreateAttachmentInput>;
    customerId: Scalars['ID'];
};

export type CustomerAttachmentCreateAttachmentInput = {
    file: Scalars['Upload'];
    memo?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type CustomerAttachmentCreateInput = {
    customerId: Scalars['ID'];
    file: Scalars['Upload'];
    memo?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type CustomerAttachmentFilterInput = {
    customerId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type CustomerAttachmentInput = {
    memo?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type CustomerAttachmentUpdateInput = {
    id: Scalars['ID'];
    memo?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type CustomerCategory = ICustomerCategory & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type CustomerCategoryFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type CustomerCertificate = ICustomerCertificate & {
    /** 附件 */
    attachments: Array<CustomerCertificateAttachment>;
    /** 證號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUser?: Maybe<User>;
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 客戶 */
    customer: Customer;
    /** 客戶 */
    customerId: Scalars['ID'];
    /** 是否禁用 */
    disabled: Scalars['Boolean'];
    /** 生效日期 */
    effectiveDate?: Maybe<Scalars['Date']>;
    /** 失效日期 */
    expiryDate?: Maybe<Scalars['Date']>;
    id: Scalars['ID'];
    /** 經營範圍 */
    scope?: Maybe<Scalars['String']>;
    /** 類型 */
    type: CustomerCertificateType;
    /** 類型 */
    typeId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUser?: Maybe<User>;
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type CustomerCertificateAttachment = ICustomerCertificateAttachment & {
    /** 證照 */
    certificate: CustomerCertificate;
    /** 證照 */
    certificateId: Scalars['ID'];
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUser?: Maybe<User>;
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 副檔名 */
    extension: Scalars['String'];
    id: Scalars['ID'];
    /** 備註 */
    memo?: Maybe<Scalars['String']>;
    /** 名稱 */
    name: Scalars['String'];
    /** S3 Key */
    s3Key: Scalars['String'];
    /** 檔案大小 (Bytes) */
    size: Scalars['Float'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUser?: Maybe<User>;
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 短效期的 S3 URL */
    url?: Maybe<Scalars['URL']>;
};

export type CustomerCertificateAttachmentCreateInput = {
    certificateId: Scalars['ID'];
    file: Scalars['Upload'];
    memo?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type CustomerCertificateAttachmentFilterInput = {
    certificateId?: InputMaybe<Scalars['ID']>;
    customerId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type CustomerCertificateAttachmentInput = {
    memo?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type CustomerCertificateAttachmentRelationInput = {
    file: Scalars['Upload'];
    memo?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type CustomerCertificateAttachmentUpdateInput = {
    id: Scalars['ID'];
    memo?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type CustomerCertificateCreateInput = {
    attachments?: InputMaybe<Array<CustomerCertificateAttachmentRelationInput>>;
    /** 證號 */
    code?: InputMaybe<Scalars['String']>;
    customerId?: InputMaybe<Scalars['ID']>;
    effectiveDate?: InputMaybe<Scalars['DateTime']>;
    expiryDate?: InputMaybe<Scalars['DateTime']>;
    /** 經營範圍 */
    scope?: InputMaybe<Scalars['String']>;
    typeId?: InputMaybe<Scalars['ID']>;
};

export type CustomerCertificateFilterInput = {
    customerId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type CustomerCertificateInput = {
    /** 證號 */
    code?: InputMaybe<Scalars['String']>;
    customerId?: InputMaybe<Scalars['ID']>;
    effectiveDate?: InputMaybe<Scalars['DateTime']>;
    expiryDate?: InputMaybe<Scalars['DateTime']>;
    /** 經營範圍 */
    scope?: InputMaybe<Scalars['String']>;
    typeId?: InputMaybe<Scalars['ID']>;
};

export type CustomerCertificateType = ICustomerCertificateType & {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type CustomerCertificateTypeCreateInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerCertificateTypeFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type CustomerCertificateTypeInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerCertificateTypeUpdateInput = {
    id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerCertificateUpdateInput = {
    /** 證號 */
    code?: InputMaybe<Scalars['String']>;
    customerId?: InputMaybe<Scalars['ID']>;
    effectiveDate?: InputMaybe<Scalars['DateTime']>;
    expiryDate?: InputMaybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    /** 經營範圍 */
    scope?: InputMaybe<Scalars['String']>;
    typeId?: InputMaybe<Scalars['ID']>;
};

export type CustomerCreateInput = {
    /** 地址 */
    address?: InputMaybe<Scalars['String']>;
    /** 分區 */
    areaId?: InputMaybe<Scalars['ID']>;
    /** 銀行戶頭帳號 */
    bankAccountCode?: InputMaybe<Scalars['String']>;
    /** 銀行戶頭名稱 */
    bankAccountName?: InputMaybe<Scalars['String']>;
    /** 開票單位名稱 */
    billingUnitName?: InputMaybe<Scalars['String']>;
    /** 營業統一編號 */
    businessCode?: InputMaybe<Scalars['String']>;
    /** 分類 */
    categoryId?: InputMaybe<Scalars['ID']>;
    /** 市級、二級行政區 */
    cityId?: InputMaybe<Scalars['ID']>;
    /** 編號（鼎新／內部通用） */
    code?: InputMaybe<Scalars['String']>;
    /** 聯絡人 */
    contactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 總負責聯絡人姓名 */
    contactPersonName?: InputMaybe<Scalars['String']>;
    /** 總負責聯絡人電話 */
    contactPersonPhone?: InputMaybe<Scalars['String']>;
    /** 信用期限（科林評定） */
    creditPeriodId?: InputMaybe<Scalars['ID']>;
    /** 信用額度（科林評定） */
    creditQuota?: InputMaybe<Scalars['Float']>;
    /** 預設付款方式 */
    defaultPaymentMethodId?: InputMaybe<Scalars['Float']>;
    /** 預設配送方式 */
    defaultShippingMethodId?: InputMaybe<Scalars['Float']>;
    /** 縣級、三級行政區 */
    districtId?: InputMaybe<Scalars['ID']>;
    /** 電子信箱 */
    email?: InputMaybe<Scalars['String']>;
    /** 傳真電話 */
    fax?: InputMaybe<Scalars['String']>;
    /** 集團 */
    groupId?: InputMaybe<Scalars['ID']>;
    /** 法人姓名 */
    legalPersonName?: InputMaybe<Scalars['String']>;
    /** 等級 */
    levelIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 醫事機構代碼 */
    medicalCode?: InputMaybe<Scalars['String']>;
    /** 備註 */
    memo?: InputMaybe<Scalars['String']>;
    /** 行動電話 */
    mobile?: InputMaybe<Scalars['String']>;
    /** 名稱 */
    name?: InputMaybe<Scalars['String']>;
    /** 編號（NAV） */
    navCode?: InputMaybe<Scalars['String']>;
    /** 上層客戶 */
    parentId?: InputMaybe<Scalars['ID']>;
    /** 座機電話 */
    phone?: InputMaybe<Scalars['String']>;
    primaryUsers?: InputMaybe<Array<CustomerPrimaryUserInput>>;
    /** 屬性 */
    propertyIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 省級、一級行政區 */
    provinceId?: InputMaybe<Scalars['ID']>;
    /** 轉介編號 */
    referenceCode?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    /** 業務團隊位置 */
    salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 收貨地址 */
    shippingAddress?: InputMaybe<Scalars['String']>;
    /** 簡稱 */
    shortName?: InputMaybe<Scalars['String']>;
    /** 類型 */
    typeId?: InputMaybe<Scalars['ID']>;
    /** 網站 */
    website?: InputMaybe<Scalars['String']>;
    /** 編號（用友） */
    yonyouCode?: InputMaybe<Scalars['String']>;
};

export type CustomerFilterInput = {
    /** 分區 */
    areaId?: InputMaybe<Scalars['ID']>;
    /** 營業統一編號 */
    businessCode?: InputMaybe<Scalars['String']>;
    /** 分類 */
    categoryId?: InputMaybe<Scalars['ID']>;
    /** 市級、二級行政區 */
    cityId?: InputMaybe<Scalars['ID']>;
    /** 編號（鼎新／內部通用） */
    code?: InputMaybe<Scalars['String']>;
    /** 聯絡人 */
    contactPersonId?: InputMaybe<Scalars['ID']>;
    /** CAS 類型的客戶等級 */
    customerLevelIdInCas?: InputMaybe<Scalars['ID']>;
    /** 儀器類型的客戶等級 */
    customerLevelIdInInstrument?: InputMaybe<Scalars['ID']>;
    /** IOL 類型的客戶等級 */
    customerLevelIdInIol?: InputMaybe<Scalars['ID']>;
    /** 客戶等級（複選） */
    customerLevelIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 客戶等級類別 */
    customerLevelTypeId?: InputMaybe<Scalars['ID']>;
    /** 縣級、三級行政區 */
    districtId?: InputMaybe<Scalars['ID']>;
    /** 集團 */
    groupId?: InputMaybe<Scalars['ID']>;
    /** 是否有生效的「首營資料」證照 */
    hasEffectiveGmpCertificate?: InputMaybe<Scalars['Boolean']>;
    /** 是否有「已拜訪」的拜訪資料 */
    hasVisitedVisit?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 是否啟用（查詢條件設置為 Null 時，同時取得「啟用」及「禁用」的資料） */
    isActive?: InputMaybe<Scalars['Boolean']>;
    /** 是否為「首營」客戶 */
    isGmp?: InputMaybe<Scalars['Boolean']>;
    /** 關鍵字（同時查詢「名稱、內部編號、統一編號、醫事編號」欄位） */
    keyword?: InputMaybe<Scalars['String']>;
    /** 最近 BPM 首營資料申請狀態 */
    lastGmpBpmStatus?: InputMaybe<EnumLastGmpBpmStatus>;
    /** 醫事機構代碼 */
    medicalCode?: InputMaybe<Scalars['String']>;
    /** 名稱 */
    name?: InputMaybe<Scalars['String']>;
    /** 上層客戶 */
    parentId?: InputMaybe<Scalars['ID']>;
    /** 主要負責業務 */
    primaryUserId?: InputMaybe<Scalars['ID']>;
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 屬性（複選） */
    propertyIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 屬性類型（複選） */
    propertyTypeIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 省級、一級行政區 */
    provinceId?: InputMaybe<Scalars['ID']>;
    /** 區域 */
    regionId?: InputMaybe<Scalars['ID']>;
    /** 業務團隊組織 */
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    /** 類型編號 */
    typeCode?: InputMaybe<EnumCustomerTypeCode>;
    /** 類型 */
    typeId?: InputMaybe<Scalars['ID']>;
    /** 負責業務 */
    userId?: InputMaybe<Scalars['ID']>;
    /** 負責業務（複選） */
    userIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 拜訪查詢項 */
    visit?: InputMaybe<VisitFilterInput>;
    /** 編號（用友） */
    yonyouCode?: InputMaybe<Scalars['String']>;
};

export type CustomerGroup = ICustomerGroup & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUser?: Maybe<User>;
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    /** 名稱 */
    name?: Maybe<Scalars['String']>;
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUser?: Maybe<User>;
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type CustomerGroupCreateInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
};

export type CustomerGroupFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type CustomerGroupInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
};

export type CustomerGroupUpdateInput = {
    id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
};

export type CustomerInput = {
    /** 地址 */
    address?: InputMaybe<Scalars['String']>;
    /** 分區 */
    areaId?: InputMaybe<Scalars['ID']>;
    /** 銀行戶頭帳號 */
    bankAccountCode?: InputMaybe<Scalars['String']>;
    /** 銀行戶頭名稱 */
    bankAccountName?: InputMaybe<Scalars['String']>;
    /** 開票單位名稱 */
    billingUnitName?: InputMaybe<Scalars['String']>;
    /** 營業統一編號 */
    businessCode?: InputMaybe<Scalars['String']>;
    /** 分類 */
    categoryId?: InputMaybe<Scalars['ID']>;
    /** 市級、二級行政區 */
    cityId?: InputMaybe<Scalars['ID']>;
    /** 編號（鼎新／內部通用） */
    code?: InputMaybe<Scalars['String']>;
    /** 聯絡人 */
    contactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 總負責聯絡人姓名 */
    contactPersonName?: InputMaybe<Scalars['String']>;
    /** 總負責聯絡人電話 */
    contactPersonPhone?: InputMaybe<Scalars['String']>;
    /** 信用期限（科林評定） */
    creditPeriodId?: InputMaybe<Scalars['ID']>;
    /** 信用額度（科林評定） */
    creditQuota?: InputMaybe<Scalars['Float']>;
    /** 預設付款方式 */
    defaultPaymentMethodId?: InputMaybe<Scalars['Float']>;
    /** 預設配送方式 */
    defaultShippingMethodId?: InputMaybe<Scalars['Float']>;
    /** 縣級、三級行政區 */
    districtId?: InputMaybe<Scalars['ID']>;
    /** 電子信箱 */
    email?: InputMaybe<Scalars['String']>;
    /** 傳真電話 */
    fax?: InputMaybe<Scalars['String']>;
    /** 集團 */
    groupId?: InputMaybe<Scalars['ID']>;
    /** 法人姓名 */
    legalPersonName?: InputMaybe<Scalars['String']>;
    /** 等級 */
    levelIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 醫事機構代碼 */
    medicalCode?: InputMaybe<Scalars['String']>;
    /** 備註 */
    memo?: InputMaybe<Scalars['String']>;
    /** 行動電話 */
    mobile?: InputMaybe<Scalars['String']>;
    /** 名稱 */
    name?: InputMaybe<Scalars['String']>;
    /** 編號（NAV） */
    navCode?: InputMaybe<Scalars['String']>;
    /** 上層客戶 */
    parentId?: InputMaybe<Scalars['ID']>;
    /** 座機電話 */
    phone?: InputMaybe<Scalars['String']>;
    primaryUsers?: InputMaybe<Array<CustomerPrimaryUserInput>>;
    /** 屬性 */
    propertyIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 省級、一級行政區 */
    provinceId?: InputMaybe<Scalars['ID']>;
    /** 轉介編號 */
    referenceCode?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    /** 業務團隊位置 */
    salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 收貨地址 */
    shippingAddress?: InputMaybe<Scalars['String']>;
    /** 簡稱 */
    shortName?: InputMaybe<Scalars['String']>;
    /** 類型 */
    typeId?: InputMaybe<Scalars['ID']>;
    /** 網站 */
    website?: InputMaybe<Scalars['String']>;
    /** 編號（用友） */
    yonyouCode?: InputMaybe<Scalars['String']>;
};

export type CustomerLevel = ICustomerLevel & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 類型 */
    type: CustomerLevelType;
    /** 類型 */
    typeId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type CustomerLevelField = ICustomerLevelField & {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 類型 */
    type: CustomerLevelFieldType;
    /** 類型 */
    typeId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type CustomerLevelFieldFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    levelTypeId?: InputMaybe<Scalars['ID']>;
    typeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CustomerLevelFieldType = ICustomerLevelFieldType & {
    /** 編號 */
    code?: Maybe<EnumCustomerLevelFieldTypeCode>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 客戶等級類型 */
    levelFields: Array<CustomerLevelField>;
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type CustomerLevelFieldTypeFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    levelTypeId?: InputMaybe<Scalars['ID']>;
};

export type CustomerLevelFilterInput = {
    customerId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    levelFieldTypeId?: InputMaybe<Scalars['ID']>;
    typeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CustomerLevelType = ICustomerLevelType & {
    /** 編號 */
    code?: Maybe<EnumCustomerLevelTypeCode>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 客戶等級 */
    levels: Array<CustomerLevel>;
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type CustomerLevelTypeFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type CustomerPrimaryUserInput = {
    /** 業務團隊 */
    salesTeamId: Scalars['ID'];
    /** 主要負責業務 */
    userId: Scalars['ID'];
};

export type CustomerProperty = ICustomerProperty & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 類型 */
    type: CustomerPropertyType;
    /** 類型 */
    typeId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type CustomerPropertyCreateInput = {
    code?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    typeId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerPropertyFilterInput = {
    customerId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    typeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CustomerPropertyInput = {
    code?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    typeId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerPropertyType = ICustomerPropertyType & {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 客戶屬性 */
    properties: Array<CustomerProperty>;
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type CustomerPropertyTypeCreateInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerPropertyTypeFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type CustomerPropertyTypeInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerPropertyTypeUpdateInput = {
    id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerPropertyUpdateInput = {
    code?: InputMaybe<Scalars['String']>;
    id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    typeId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerType = ICustomerType & {
    /** 編號 */
    code?: Maybe<EnumCustomerTypeCode>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type CustomerTypeCreateInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerTypeFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type CustomerTypeInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerTypeUpdateInput = {
    id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerUpdateInput = {
    /** 地址 */
    address?: InputMaybe<Scalars['String']>;
    /** 分區 */
    areaId?: InputMaybe<Scalars['ID']>;
    /** 銀行戶頭帳號 */
    bankAccountCode?: InputMaybe<Scalars['String']>;
    /** 銀行戶頭名稱 */
    bankAccountName?: InputMaybe<Scalars['String']>;
    /** 開票單位名稱 */
    billingUnitName?: InputMaybe<Scalars['String']>;
    /** 營業統一編號 */
    businessCode?: InputMaybe<Scalars['String']>;
    /** 分類 */
    categoryId?: InputMaybe<Scalars['ID']>;
    /** 市級、二級行政區 */
    cityId?: InputMaybe<Scalars['ID']>;
    /** 編號（鼎新／內部通用） */
    code?: InputMaybe<Scalars['String']>;
    /** 聯絡人 */
    contactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 總負責聯絡人姓名 */
    contactPersonName?: InputMaybe<Scalars['String']>;
    /** 總負責聯絡人電話 */
    contactPersonPhone?: InputMaybe<Scalars['String']>;
    /** 信用期限（科林評定） */
    creditPeriodId?: InputMaybe<Scalars['ID']>;
    /** 信用額度（科林評定） */
    creditQuota?: InputMaybe<Scalars['Float']>;
    /** 預設付款方式 */
    defaultPaymentMethodId?: InputMaybe<Scalars['Float']>;
    /** 預設配送方式 */
    defaultShippingMethodId?: InputMaybe<Scalars['Float']>;
    /** 縣級、三級行政區 */
    districtId?: InputMaybe<Scalars['ID']>;
    /** 電子信箱 */
    email?: InputMaybe<Scalars['String']>;
    /** 傳真電話 */
    fax?: InputMaybe<Scalars['String']>;
    /** 集團 */
    groupId?: InputMaybe<Scalars['ID']>;
    id: Scalars['ID'];
    /** 法人姓名 */
    legalPersonName?: InputMaybe<Scalars['String']>;
    /** 等級 */
    levelIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 醫事機構代碼 */
    medicalCode?: InputMaybe<Scalars['String']>;
    /** 備註 */
    memo?: InputMaybe<Scalars['String']>;
    /** 行動電話 */
    mobile?: InputMaybe<Scalars['String']>;
    /** 名稱 */
    name?: InputMaybe<Scalars['String']>;
    /** 編號（NAV） */
    navCode?: InputMaybe<Scalars['String']>;
    /** 上層客戶 */
    parentId?: InputMaybe<Scalars['ID']>;
    /** 座機電話 */
    phone?: InputMaybe<Scalars['String']>;
    primaryUsers?: InputMaybe<Array<CustomerPrimaryUserInput>>;
    /** 屬性 */
    propertyIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 省級、一級行政區 */
    provinceId?: InputMaybe<Scalars['ID']>;
    /** 轉介編號 */
    referenceCode?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    /** 業務團隊位置 */
    salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 收貨地址 */
    shippingAddress?: InputMaybe<Scalars['String']>;
    /** 簡稱 */
    shortName?: InputMaybe<Scalars['String']>;
    /** 類型 */
    typeId?: InputMaybe<Scalars['ID']>;
    /** 網站 */
    website?: InputMaybe<Scalars['String']>;
    /** 編號（用友） */
    yonyouCode?: InputMaybe<Scalars['String']>;
};

export type CustomersPrimaryUser = ICustomersPrimaryUser & {
    /** 客戶 */
    customer: Customer;
    /** 客戶 */
    customerId: Scalars['ID'];
    id: Scalars['ID'];
    /** 業務團隊 */
    salesTeam?: Maybe<SalesTeam>;
    /** 業務團隊 */
    salesTeamId?: Maybe<Scalars['ID']>;
    /** 負責業務 */
    user: User;
    /** 負責業務 */
    userId: Scalars['ID'];
};

export type DeleteCustomerToContactPeopleInput = {
    contactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
    id?: InputMaybe<Scalars['ID']>;
};

export type Department = IDepartment & {
    /** 下層部門 */
    children: Array<Department>;
    code?: Maybe<Scalars['String']>;
    /** 公司 */
    company?: Maybe<Company>;
    companyId?: Maybe<Scalars['ID']>;
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 是否啟用 */
    isActive: Scalars['Boolean'];
    /** 主管 */
    manager?: Maybe<User>;
    /** 主管 */
    managerId?: Maybe<Scalars['ID']>;
    name: Scalars['String'];
    /** 上層部門 */
    parent?: Maybe<Department>;
    /** 上層部門 */
    parentId?: Maybe<Scalars['ID']>;
    /** 區域 */
    region: Region;
    regionId: Scalars['ID'];
    /** 業務團隊組織 */
    salesTeamGroups: Array<SalesTeamGroup>;
    /** 帳套 */
    setOfBook?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
    /** 用戶 */
    users: Array<User>;
};

export type DepartmentFilterInput = {
    code?: InputMaybe<Scalars['String']>;
    companyIds?: InputMaybe<Array<Scalars['ID']>>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 是否查詢部門的根節點（root） */
    isRoot?: InputMaybe<Scalars['Boolean']>;
    parentId?: InputMaybe<Scalars['ID']>;
    regionId?: InputMaybe<Scalars['ID']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    salesTeamId?: InputMaybe<Scalars['ID']>;
};

export type District = IDistrict & {
    /** 市區、二級行政區 */
    city?: Maybe<City>;
    cityId?: Maybe<Scalars['ID']>;
    code?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    name: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    viewOrder: Scalars['Int'];
};

export type DistrictFilterInput = {
    cityId?: InputMaybe<Scalars['ID']>;
    cityIds?: InputMaybe<Array<Scalars['ID']>>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    regionId?: InputMaybe<Scalars['ID']>;
};

export type EcDashboardAnalysis = {
    /** 銷售客戶排名 */
    salesCustomerRank: EcDashboardAnalysisSalesCustomerRank;
    /** 銷售商品排名 */
    salesMaterialRank: EcDashboardAnalysisSalesMaterialRank;
    /** 業績統計 */
    salesStat: EcDashboardAnalysisSalesStat;
    /** 拜訪客戶排名 */
    visitCustomerRank: EcDashboardAnalysisVisitCustomerRank;
    /** 拜訪目標排名 */
    visitGoalRank: EcDashboardAnalysisVisitGoalRank;
    /** 拜訪商品排名 */
    visitMentionProductRank: EcDashboardAnalysisVisitMentionProductRank;
    /** 拜訪統計 */
    visitStat: EcDashboardAnalysisVisitStat;
    /** 拜訪熱門時段 */
    visitsByDayOfWeek: EcDashboardAnalysisVisitByDayOfWeek;
};

export type EcDashboardAnalysisSalesCustomerRankArgs = {
    filters?: InputMaybe<EcDashboardAnalysisSalesCustomerRankFilterInput>;
};

export type EcDashboardAnalysisSalesMaterialRankArgs = {
    filters?: InputMaybe<EcDashboardAnalysisSalesMaterialRankFilterInput>;
};

export type EcDashboardAnalysisSalesStatArgs = {
    filters?: InputMaybe<EcDashboardAnalysisSalesStatFilterInput>;
};

export type EcDashboardAnalysisVisitCustomerRankArgs = {
    filters?: InputMaybe<EcDashboardAnalysisVisitCustomerRankFilterInput>;
};

export type EcDashboardAnalysisVisitGoalRankArgs = {
    filters?: InputMaybe<EcDashboardAnalysisVisitGoalRankFilterInput>;
};

export type EcDashboardAnalysisVisitMentionProductRankArgs = {
    filters?: InputMaybe<EcDashboardAnalysisVisitMentionProductRankFilterInput>;
};

export type EcDashboardAnalysisVisitStatArgs = {
    filters?: InputMaybe<EcDashboardAnalysisVisitStatFilterInput>;
};

export type EcDashboardAnalysisVisitsByDayOfWeekArgs = {
    filters?: InputMaybe<EcDashboardAnalysisVisitByDayOfWeekFilterInput>;
};

export type EcDashboardAnalysisSalesCustomerRank = {
    /** 日期區間 */
    dateRange: EcDashboardAnalysisSalesCustomerRankDateRange;
    /** 細項統計 */
    details: Array<EcDashboardAnalysisSalesCustomerRankDetail>;
    /** 業務團隊組織 */
    salesTeamGroupId?: Maybe<Scalars['ID']>;
};

export type EcDashboardAnalysisSalesCustomerRankDateRange = {
    /** 當期（起） */
    currentDate1: Scalars['String'];
    /** 當期（迄） */
    currentDate2: Scalars['String'];
    /** 去年同期（起） */
    lastYearYoyDate1: Scalars['String'];
    /** 去年同期（迄） */
    lastYearYoyDate2: Scalars['String'];
    /** 同期（起） */
    yoyDate1: Scalars['String'];
    /** 同期（迄） */
    yoyDate2: Scalars['String'];
};

export type EcDashboardAnalysisSalesCustomerRankDetail = {
    /** 當期 銷售金額 */
    amountCurrent: Scalars['Float'];
    /** 去年同期 (YOY) 銷售金額 */
    amountLastYearYoy: Scalars['Float'];
    /** 同期 (YOY) 銷售金額 */
    amountYoy: Scalars['Float'];
    /** 客戶編號 */
    customerCode?: Maybe<Scalars['String']>;
    /** 客戶名稱 */
    customerName?: Maybe<Scalars['String']>;
    /** 當期 銷售數量 */
    qtyCurrent: Scalars['Float'];
    /** 去年同期 (YOY) 銷售數量 */
    qtyLastYearYoy: Scalars['Float'];
    /** 同期 (YOY) 銷售數量 */
    qtyYoy: Scalars['Float'];
    /** 排名 */
    rank: Scalars['Int'];
    /** 流水號 */
    seq: Scalars['Int'];
    /** 當期 拜訪次數 */
    visitCurrent: Scalars['Int'];
    /** 去年同期 (YOY) 拜訪次數 */
    visitLastYearYoy: Scalars['Int'];
    /** 同期 (YOY) 拜訪次數 */
    visitYoy: Scalars['Int'];
};

export type EcDashboardAnalysisSalesCustomerRankFilterInput = {
    /** 日期（起） */
    date1: Scalars['String'];
    /** 日期（迄） */
    date2: Scalars['String'];
    /** 主要負責業務 */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 業務團隊 */
    salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EcDashboardAnalysisSalesDetail = {
    /** 詳細 */
    details: Array<EcDashboardAnalysisSalesDetailDetail>;
    /** 業務團隊組織 */
    salesTeamGroup?: Maybe<SalesTeamGroup>;
    /** 業務團隊組織 */
    salesTeamGroupId?: Maybe<Scalars['ID']>;
    /** 銷售業績類型 */
    salesType?: Maybe<SalesPerformanceSalesType>;
    /** 銷售業績類型 */
    salesTypeId?: Maybe<Scalars['ID']>;
};

export type EcDashboardAnalysisSalesDetailDetail = {
    /** 帳款客戶編號 */
    accountCustomerCode?: Maybe<Scalars['String']>;
    /** 帳款客戶名稱 */
    accountCustomerName?: Maybe<Scalars['String']>;
    /** 通路編號 */
    companyCode: EnumEnumEcDashboardAnalysisSalesCompanyCode;
    /** Unique key */
    key: Scalars['String'];
    /** 料件編號 */
    materialCode?: Maybe<Scalars['String']>;
    /** 料件名稱 */
    materialName?: Maybe<Scalars['String']>;
    /** 數量 */
    qty?: Maybe<Scalars['Float']>;
    /** 銷售／銷退客戶編號 */
    salesCustomerCode?: Maybe<Scalars['String']>;
    /** 銷售／銷退客戶名稱 */
    salesCustomerName?: Maybe<Scalars['String']>;
    /** 銷售／銷退日期 */
    salesDate?: Maybe<Scalars['String']>;
    /** 銷售／銷退單號 */
    salesOrderCode?: Maybe<Scalars['String']>;
    /** 銷售／銷退項次 */
    salesOrderSeq?: Maybe<Scalars['Int']>;
    /** 銷售／銷退類型 */
    salesType: EnumEcDashboardAnalysisOrderType;
    /** 含稅金額（總價） */
    taxedAmount?: Maybe<Scalars['Float']>;
    /** 未稅金額（總價） */
    untaxedAmount?: Maybe<Scalars['Float']>;
    /** 開單人員編號 */
    userCode?: Maybe<Scalars['String']>;
    /** 開單人員姓名 */
    userName?: Maybe<Scalars['String']>;
};

export type EcDashboardAnalysisSalesDetailFilterInput = {
    /** 日期（起） */
    date1: Scalars['String'];
    /** 日期（迄） */
    date2: Scalars['String'];
    /** 主要負責業務 */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 業務團隊 */
    salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 銷售類型 */
    salesTypeId?: InputMaybe<Scalars['ID']>;
};

export type EcDashboardAnalysisSalesMaterialRank = {
    /** 日期區間 */
    dateRange: EcDashboardAnalysisSalesMaterialRankDateRange;
    /** 細項統計 */
    details: Array<EcDashboardAnalysisSalesMaterialRankDetail>;
    /** 業務團隊組織 */
    salesTeamGroupId?: Maybe<Scalars['ID']>;
};

export type EcDashboardAnalysisSalesMaterialRankDateRange = {
    /** 當期（起） */
    currentDate1: Scalars['String'];
    /** 當期（迄） */
    currentDate2: Scalars['String'];
    /** 去年同期（起） */
    lastYearYoyDate1: Scalars['String'];
    /** 去年同期（迄） */
    lastYearYoyDate2: Scalars['String'];
    /** 同期（起） */
    yoyDate1: Scalars['String'];
    /** 同期（迄） */
    yoyDate2: Scalars['String'];
};

export type EcDashboardAnalysisSalesMaterialRankDetail = {
    /** 當期 銷售金額 */
    amountCurrent: Scalars['Float'];
    /** 去年同期 (YOY) 銷售金額 */
    amountLastYearYoy: Scalars['Float'];
    /** 同期 (YOY) 銷售金額 */
    amountYoy: Scalars['Float'];
    /** 料件編號 */
    materialCode?: Maybe<Scalars['String']>;
    /** 料件名稱 */
    materialName?: Maybe<Scalars['String']>;
    /** 當期 銷售數量 */
    qtyCurrent: Scalars['Float'];
    /** 去年同期 (YOY) 銷售數量 */
    qtyLastYearYoy: Scalars['Float'];
    /** 同期 (YOY) 銷售數量 */
    qtyYoy: Scalars['Float'];
    /** 排名 */
    rank: Scalars['Int'];
    /** 流水號 */
    seq: Scalars['Int'];
};

export type EcDashboardAnalysisSalesMaterialRankFilterInput = {
    /** 日期（起） */
    date1: Scalars['String'];
    /** 日期（迄） */
    date2: Scalars['String'];
    /** 主要負責業務 */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 業務團隊 */
    salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EcDashboardAnalysisSalesStat = {
    /** 日期區間 */
    dateRange: EcDashboardAnalysisSalesStatDateRange;
    /** 細項統計 */
    details: Array<EcDashboardAnalysisSalesStatDetail>;
    /** 業務團隊組織 */
    salesTeamGroupId?: Maybe<Scalars['ID']>;
};

export type EcDashboardAnalysisSalesStatDateRange = {
    /** 當期（起） */
    currentDate1: Scalars['String'];
    /** 當期（迄） */
    currentDate2: Scalars['String'];
    /** 去年至去年當天（起） */
    lastYearYtdDate1: Scalars['String'];
    /** 去年至去年當天（迄） */
    lastYearYtdDate2: Scalars['String'];
    /** 前期（起） */
    momDate1: Scalars['String'];
    /** 前期（迄） */
    momDate2: Scalars['String'];
    /** 同期（起） */
    yoyDate1: Scalars['String'];
    /** 同期（迄） */
    yoyDate2: Scalars['String'];
    /** 今年至今（起） */
    ytdDate1: Scalars['String'];
    /** 今年至今（迄） */
    ytdDate2: Scalars['String'];
};

export type EcDashboardAnalysisSalesStatDetail = {
    /** 當期 金額 */
    amountCurrent: Scalars['Float'];
    /** 當期 目標金額 */
    amountGoalCurrent?: Maybe<Scalars['Float']>;
    /** 當期 金額目標達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（總計金額）為 0 時，百分比顯示為空值 */
    amountGoalCurrentAchievementRate?: Maybe<Scalars['Float']>;
    /** 去年至去年當天 金額 */
    amountLastYearYtd: Scalars['Float'];
    /** 去年至去年當天 金額成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    amountLastYearYtdGrowthRate?: Maybe<Scalars['Float']>;
    /** 前期 (MOM) 金額 */
    amountMom: Scalars['Float'];
    /** 前期 (MOM) 金額成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    amountMomGrowthRate?: Maybe<Scalars['Float']>;
    /** 同期 (YOY) 金額 */
    amountYoy: Scalars['Float'];
    /** 同期 (YOY) 金額成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    amountYoyGrowthRate?: Maybe<Scalars['Float']>;
    /** 今年至今 (YTD) 金額 */
    amountYtd: Scalars['Float'];
    salesType: SalesPerformanceSalesType;
    /** 銷售業績類型 */
    salesTypeId: Scalars['ID'];
};

export type EcDashboardAnalysisSalesStatFilterInput = {
    /** 日期（起） */
    date1: Scalars['String'];
    /** 日期（迄） */
    date2: Scalars['String'];
    /** 主要負責業務 */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 業務團隊 */
    salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EcDashboardAnalysisVisitByDayOfWeek = {
    /** 細項統計 */
    details: Array<EcDashboardAnalysisVisitByDayOfWeekDetail>;
    /** 業務團隊組織 */
    salesTeamGroupId?: Maybe<Scalars['ID']>;
};

export type EcDashboardAnalysisVisitByDayOfWeekDetail = {
    /** 星期 */
    dayOfWeek: EnumVisitByDayOfWeek;
    /** 拜訪次數 */
    visitCountCurrent: Scalars['Int'];
};

export type EcDashboardAnalysisVisitByDayOfWeekFilterInput = {
    /** 日期（起） */
    date1: Scalars['String'];
    /** 日期（迄） */
    date2: Scalars['String'];
    /** 主要負責業務 */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 業務團隊 */
    salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EcDashboardAnalysisVisitCustomerRank = {
    /** 日期區間 */
    dateRange: EcDashboardAnalysisVisitCustomerRankDateRange;
    /** 細項統計 */
    details: Array<EcDashboardAnalysisVisitCustomerRankDetail>;
    /** 業務團隊組織 */
    salesTeamGroupId?: Maybe<Scalars['ID']>;
};

export type EcDashboardAnalysisVisitCustomerRankDateRange = {
    /** 當期（起） */
    currentDate1: Scalars['String'];
    /** 當期（迄） */
    currentDate2: Scalars['String'];
    /** 同期（起） */
    yoyDate1: Scalars['String'];
    /** 同期（迄） */
    yoyDate2: Scalars['String'];
};

export type EcDashboardAnalysisVisitCustomerRankDetail = {
    customer: Customer;
    /** 客戶 */
    customerId: Scalars['ID'];
    /** 排名 */
    rank: Scalars['Int'];
    /** 流水號 */
    seq: Scalars['Int'];
    /** 當期 拜訪次數 */
    visitCountCurrent: Scalars['Int'];
    /** 同期 (YOY) 拜訪次數 */
    visitCountYoy: Scalars['Int'];
};

export type EcDashboardAnalysisVisitCustomerRankFilterInput = {
    /** 日期（起） */
    date1: Scalars['String'];
    /** 日期（迄） */
    date2: Scalars['String'];
    /** 主要負責業務 */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 業務團隊 */
    salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EcDashboardAnalysisVisitGoalRank = {
    /** 日期區間 */
    dateRange: EcDashboardAnalysisVisitGoalRankDateRange;
    /** 細項統計 */
    details: Array<EcDashboardAnalysisVisitGoalRankDetail>;
    /** 業務團隊組織 */
    salesTeamGroupId?: Maybe<Scalars['ID']>;
};

export type EcDashboardAnalysisVisitGoalRankDateRange = {
    /** 當期（起） */
    currentDate1: Scalars['String'];
    /** 當期（迄） */
    currentDate2: Scalars['String'];
    /** 同期（起） */
    yoyDate1: Scalars['String'];
    /** 同期（迄） */
    yoyDate2: Scalars['String'];
};

export type EcDashboardAnalysisVisitGoalRankDetail = {
    goal: VisitGoal;
    /** 涉及商品 */
    goalId: Scalars['ID'];
    /** 排名 */
    rank: Scalars['Int'];
    /** 流水號 */
    seq: Scalars['Int'];
    /** 當期 拜訪次數 */
    visitCountCurrent: Scalars['Int'];
    /** 同期 (YOY) 拜訪次數 */
    visitCountYoy: Scalars['Int'];
};

export type EcDashboardAnalysisVisitGoalRankFilterInput = {
    /** 日期（起） */
    date1: Scalars['String'];
    /** 日期（迄） */
    date2: Scalars['String'];
    /** 主要負責業務 */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 業務團隊 */
    salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EcDashboardAnalysisVisitMentionProductRank = {
    /** 日期區間 */
    dateRange: EcDashboardAnalysisVisitMentionProductRankDateRange;
    /** 細項統計 */
    details: Array<EcDashboardAnalysisVisitMentionProductRankDetail>;
    /** 業務團隊組織 */
    salesTeamGroupId?: Maybe<Scalars['ID']>;
};

export type EcDashboardAnalysisVisitMentionProductRankDateRange = {
    /** 當期（起） */
    currentDate1: Scalars['String'];
    /** 當期（迄） */
    currentDate2: Scalars['String'];
    /** 同期（起） */
    yoyDate1: Scalars['String'];
    /** 同期（迄） */
    yoyDate2: Scalars['String'];
};

export type EcDashboardAnalysisVisitMentionProductRankDetail = {
    mentionProduct: BusinessProduct;
    /** 涉及商品 */
    mentionProductId: Scalars['ID'];
    /** 排名 */
    rank: Scalars['Int'];
    /** 流水號 */
    seq: Scalars['Int'];
    /** 當期 拜訪次數 */
    visitCountCurrent: Scalars['Int'];
    /** 同期 (YOY) 拜訪次數 */
    visitCountYoy: Scalars['Int'];
};

export type EcDashboardAnalysisVisitMentionProductRankFilterInput = {
    /** 日期（起） */
    date1: Scalars['String'];
    /** 日期（迄） */
    date2: Scalars['String'];
    /** 主要負責業務 */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 業務團隊 */
    salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EcDashboardAnalysisVisitStat = {
    /** 日期區間 */
    dateRange: EcDashboardAnalysisVisitStatDateRange;
    /** 總體統計 */
    overall: EcDashboardAnalysisVisitStatOverall;
    /** 業務團隊組織 */
    salesTeamGroupId?: Maybe<Scalars['ID']>;
};

export type EcDashboardAnalysisVisitStatDateRange = {
    /** 當期（起） */
    currentDate1: Scalars['String'];
    /** 當期（迄） */
    currentDate2: Scalars['String'];
    /** 去年至去年當天（起） */
    lastYearYtdDate1: Scalars['String'];
    /** 去年至去年當天（迄） */
    lastYearYtdDate2: Scalars['String'];
    /** 前期（起） */
    momDate1: Scalars['String'];
    /** 前期（迄） */
    momDate2: Scalars['String'];
    /** 同期（起） */
    yoyDate1: Scalars['String'];
    /** 同期（迄） */
    yoyDate2: Scalars['String'];
    /** 今年至今（起） */
    ytdDate1: Scalars['String'];
    /** 今年至今（迄） */
    ytdDate2: Scalars['String'];
};

export type EcDashboardAnalysisVisitStatFilterInput = {
    /** 日期（起） */
    date1: Scalars['String'];
    /** 日期（迄） */
    date2: Scalars['String'];
    /** 主要負責業務 */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 業務團隊 */
    salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EcDashboardAnalysisVisitStatOverall = {
    /** 當期 拜訪次數 */
    visitCountCurrent: Scalars['Int'];
    /** 去年至去年當天 拜訪次數 */
    visitCountLastYearYtd: Scalars['Int'];
    /** 去年至去年當天 拜訪次數成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    visitCountLastYearYtdGrowthRate?: Maybe<Scalars['Float']>;
    /** 前期 (MOM) 拜訪次數 */
    visitCountMom: Scalars['Int'];
    /** 前期 (MOM) 拜訪次數成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    visitCountMomGrowthRate?: Maybe<Scalars['Float']>;
    /** 同期 (YOY) 拜訪次數 */
    visitCountYoy: Scalars['Int'];
    /** 同期 (YOY) 拜訪次數成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    visitCountYoyGrowthRate?: Maybe<Scalars['Float']>;
    /** 今年至今 (YTD) 拜訪次數 */
    visitCountYtd: Scalars['Int'];
};

export type EcInventoryDetail = {
    /** 客戶編號 */
    customerCode?: Maybe<Scalars['String']>;
    /** 客戶名稱 */
    customerName?: Maybe<Scalars['String']>;
    /** 唯一值 */
    key: Scalars['String'];
    /** 入庫日 */
    limitDate?: Maybe<Scalars['String']>;
    /** 料件編號 */
    materialCode: Scalars['String'];
    /** 料件名稱 */
    materialName: Scalars['String'];
    /** 型號 */
    model: Scalars['String'];
    /** 庫存日 */
    orderDate?: Maybe<Scalars['String']>;
    /** 數量 */
    qty: Scalars['Int'];
    /** 暫借週期 */
    rentalPeriod?: Maybe<EnumEcInventoryRentalPeriod>;
    /** 規格 */
    spec: Scalars['String'];
    /** 狀態 */
    status?: Maybe<EnumEcInventoryStatus>;
    /** 貨倉 */
    warehouse?: Maybe<EnumEcInventoryWarehouse>;
};

export type EcInventoryDetailFilterInput = {
    /** 客戶編號 */
    customerCode?: InputMaybe<Scalars['String']>;
    /** 客戶名稱 */
    customerName?: InputMaybe<Scalars['String']>;
    /** 型號 */
    modelIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 暫借週期 */
    rentalPeriods?: InputMaybe<Array<EnumEcInventoryRentalPeriod>>;
    /** 規格 */
    specIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 狀態 */
    statuses?: InputMaybe<Array<EnumEcInventoryStatus>>;
    /** 貨倉 */
    warehouseIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EcInventoryModel = {
    /** 編號 */
    id: Scalars['ID'];
    /** 名稱 */
    name?: Maybe<Scalars['String']>;
};

export type EcInventorySpec = {
    /** 編號 */
    id: Scalars['ID'];
    /** 型號 */
    model?: Maybe<EcInventoryModel>;
    /** 型號 */
    modelId: Scalars['ID'];
    /** 名稱 */
    name?: Maybe<Scalars['String']>;
};

export type EcInventoryStat = {
    /** 詳細 */
    details: Array<EcInventoryStatDetail>;
};

export type EcInventoryStatDetail = {
    /** 型號 */
    modelId: Scalars['ID'];
    /** 數量 */
    qty: Scalars['Int'];
    /** 暫借週期 */
    rentalPeriods: Array<EcInventoryStatDetailRentalPeriod>;
    /** 貨倉 */
    warehouses: Array<EcInventoryStatDetailWarehouse>;
};

export type EcInventoryStatDetailRentalPeriod = {
    /** 數量 */
    qty: Scalars['Int'];
    /** 暫借週期 */
    rentalPeriod: EnumEcInventoryRentalPeriod;
};

export type EcInventoryStatDetailWarehouse = {
    /** 數量 */
    qty: Scalars['Int'];
    /** 狀態 */
    statuses: Array<EcInventoryStatDetailWarehouseStatus>;
    /** 貨倉 */
    warehouse: EnumEcInventoryWarehouse;
};

export type EcInventoryStatDetailWarehouseStatus = {
    /** 數量 */
    qty: Scalars['Int'];
    /** 狀態 */
    status: EnumEcInventoryStatus;
};

export type EcInventoryStatFilterInput = {
    /** 客戶編號 */
    customerCode?: InputMaybe<Scalars['String']>;
    /** 客戶名稱 */
    customerName?: InputMaybe<Scalars['String']>;
    /** 型號 */
    modelIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 規格 */
    specIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EcInventoryWarehouse = {
    /** 編號 */
    id: Scalars['ID'];
    /** 名稱 */
    name?: Maybe<Scalars['String']>;
};

export enum EnumBusinessSalesMethodCode {
    /** 直銷 */
    DirectSelling = 'DirectSelling',
    /** 分銷 */
    Distribution = 'Distribution',
}

/** 商機排序名稱 */
export enum EnumBusinessSortName {
    /** 預算商品_名稱 */
    BudgetProductName = 'BudgetProductName',
    /** 購買機會_名稱 */
    BuyingOpportunityName = 'BuyingOpportunityName',
    /** 編號 */
    Code = 'Code',
    /** 客戶_名稱 */
    CustomerName = 'CustomerName',
    /** 預計結束日期 */
    ExpectedClosedDate = 'ExpectedClosedDate',
    /** 主要負責業務_編號 */
    PrimaryUserCode = 'PrimaryUserCode',
    /** 主要負責業務_姓名 */
    PrimaryUserName = 'PrimaryUserName',
    /** 主要負責業務_業務團隊_編號 */
    PrimaryUserSalesTeamCode = 'PrimaryUserSalesTeamCode',
    /** 主要負責業務_業務團隊_名稱 */
    PrimaryUserSalesTeamName = 'PrimaryUserSalesTeamName',
    /** 商機狀態_購買機會 */
    StatusBuyingOpportunity = 'StatusBuyingOpportunity',
    /** 商機狀態_名稱 */
    StatusName = 'StatusName',
    /** 贏單機會_名稱 */
    WinningOpportunityName = 'WinningOpportunityName',
}

/** 商機統計類型 */
export enum EnumBusinessStatType {
    /** 跟進中（成交機會：高_99%成單_99%贏單） */
    HighOpportunity = 'HighOpportunity',
    /** 跟進中 */
    InProgress = 'InProgress',
    /** 丟單 */
    Losing = 'Losing',
    /** 跟進中（成交機會：中高_75%/99%成單_99/75%贏單） */
    MediumHighOpportunity = 'MediumHighOpportunity',
    /** 跟進中（成交機會：中高_75%成單_99%贏單） */
    MediumHighOpportunity_75_99 = 'MediumHighOpportunity_75_99',
    /** 跟進中（成交機會：中高_99%成單_75%贏單） */
    MediumHighOpportunity_99_75 = 'MediumHighOpportunity_99_75',
    /** 跟進中（成交機會：中_75%成單_75%贏單） */
    MediumOpportunity = 'MediumOpportunity',
    /** 跟進中（成交機會：其他） */
    OtherOpportunity = 'OtherOpportunity',
    /** 其他（髒資料） */
    Others = 'Others',
    /** 贏單 */
    Winning = 'Winning',
}

export enum EnumBusinessStatusType {
    /** 已結案（丟單） */
    ClosedInLosing = 'ClosedInLosing',
    /** 已結案（其他原因） */
    ClosedInOthers = 'ClosedInOthers',
    /** 已結案（贏單） */
    ClosedInWinning = 'ClosedInWinning',
    /** 進行中 */
    InProgress = 'InProgress',
}

/** 成交機會 */
export enum EnumBusinessTransactionOpportunity {
    /** 高（99%成單_99%贏單） */
    High = 'High',
    /** 中（75%成單_75%贏單） */
    Medium = 'Medium',
    /** 中高（75%/99%成單_99/75%贏單） */
    MediumHigh = 'MediumHigh',
    /** 中高（75%成單_99%贏單） */
    MediumHigh_75_99 = 'MediumHigh_75_99',
    /** 中高（99%成單_75%贏單） */
    MediumHigh_99_75 = 'MediumHigh_99_75',
    /** 其他 */
    Others = 'Others',
}

export enum EnumContactPeopleGender {
    /** 女性 */
    Female = 'Female',
    /** 男性 */
    Male = 'Male',
    /** 其他 */
    Other = 'Other',
}

export enum EnumCustomerLevelFieldTypeCode {
    /** 客戶類型 */
    CustomerType = 'CustomerType',
    /** 刀量 */
    Surgery = 'Surgery',
    /** 使用度 */
    Usage = 'Usage',
}

export enum EnumCustomerLevelTypeCode {
    /** CAS */
    Cas = 'Cas',
    /** 儀器 */
    Instrument = 'Instrument',
    /** IOL */
    Iol = 'Iol',
}

export enum EnumCustomerTypeCode {
    /** 經銷商 */
    Dealer = 'Dealer',
    /** 醫療機構 */
    MedicalInstitution = 'MedicalInstitution',
}

/** 訂單類型 */
export enum EnumEcDashboardAnalysisOrderType {
    /** 固資 */
    FixedAsset = 'FixedAsset',
    /** 銷退 */
    SalesReturn = 'SalesReturn',
    /** 銷貨 */
    ShippingOrder = 'ShippingOrder',
}

/** 科明庫存暫借週期 */
export enum EnumEcInventoryRentalPeriod {
    /** 30 < n ≤ 45 天 */
    From31To45Days = 'From31To45Days',
    /** 45 < n 天 */
    From46Days = 'From46Days',
    /** n ≤ 30 天 */
    To30Days = 'To30Days',
}

/** 科明庫存狀態 */
export enum EnumEcInventoryStatus {
    /** 已過期 < 0 月 */
    Expired = 'Expired',
    /** 需監控 < 6 月 */
    MustBeMonitored = 'MustBeMonitored',
    /** 正常品 ≥ 12 月 */
    Normal = 'Normal',
    /** 將過期 < 6 月 */
    WillBeExpired = 'WillBeExpired',
}

/** 科明庫存貨倉 */
export enum EnumEcInventoryWarehouse {
    /** 暫借 */
    Borrow = 'Borrow',
    /** 辦公室(中) */
    Middle = 'Middle',
    /** 寄銷倉 */
    North = 'North',
    /** 辦公室(南) */
    South = 'South',
    /** 五股倉 */
    Wugu = 'Wugu',
}

/** 通路編號 */
export enum EnumEnumEcDashboardAnalysisSalesCompanyCode {
    /** 科明 */
    Ec = 'EC',
    /** 明睿 */
    Mr = 'MR',
}

export enum EnumEyeFixedAssetRentalObjectCode {
    /** 客户 */
    Customer = 'Customer',
    /** 展场 */
    Exhibition = 'Exhibition',
    /** 其他 */
    Others = 'Others',
}

export enum EnumEyeFixedAssetTypeCode {
    /** 展示機 */
    DemoMachines = 'DemoMachines',
    /** 一般固資 */
    GeneralFixedAssets = 'GeneralFixedAssets',
}

export enum EnumEyeFixedAssetsRentalStatus {
    /** 租借中 */
    Booking = 'Booking',
    /** 取消 */
    Cancel = 'Cancel',
    /** 已歸還 */
    Returned = 'Returned',
    /** 待租借 */
    Waiting = 'Waiting',
}

export enum EnumEyeFixedAssetsStatus {
    /** 無效 */
    Disabled = 'Disabled',
    /** 有效 */
    Enabled = 'Enabled',
}

export enum EnumEyeProductStatus {
    /** 不可販售 */
    Disabled = 'Disabled',
    /** 可販售 */
    Enabled = 'Enabled',
}

export enum EnumEyeQuotationOrderOfficialSealStatus {
    /** 完成 */
    Approved = 'Approved',
    /** 取消 */
    Canceled = 'Canceled',
    /** 送簽中 */
    Processing = 'Processing',
    /** 拒絕 */
    Rejected = 'Rejected',
}

export enum EnumEyeQuotationOrderStatus {
    /** 完成 */
    Approved = 'Approved',
    /** 取消 */
    Canceled = 'Canceled',
    /** 送簽中 */
    Processing = 'Processing',
    /** 拒絕 */
    Rejected = 'Rejected',
}

export enum EnumEyeQuotationOrderType {
    /** 維修 */
    Repair = 'Repair',
    /** 銷售 */
    Sale = 'Sale',
}

export enum EnumEyeServiceOrderApprovalStatus {
    /** 已核准 */
    Approved = 'Approved',
    /** 取消 */
    Canceled = 'Canceled',
    /** 核准中 */
    Processing = 'Processing',
    /** 拒絕 */
    Rejected = 'Rejected',
}

export enum EnumEyeServiceOrderExtensionApprovalStatus {
    /** 已核准 */
    Approved = 'Approved',
    /** 取消 */
    Canceled = 'Canceled',
    /** 核准中 */
    Processing = 'Processing',
    /** 拒絕 */
    Rejected = 'Rejected',
}

export enum EnumEyeServiceOrderPriority {
    /** 高 */
    High = 'High',
    /** 低 */
    Low = 'Low',
    /** 中 */
    Middle = 'Middle',
}

export enum EnumEyeServiceOrderStatus {
    /** 驗收中 */
    Acceptance = 'Acceptance',
    /** 已完工 */
    Done = 'Done',
    /** 新建立 */
    New = 'New',
    /** 等待中 */
    Pendding = 'Pendding',
    /** 處理中 */
    Processing = 'Processing',
}

export enum EnumEyeServiceOrderTypeCode {
    /** 固資租借 */
    FixedAssetRental = 'FixedAssetRental',
    /** 一般維修 */
    GeneralMaintenance = 'GeneralMaintenance',
    /** 保固保養 */
    WarrantyMaintenance = 'WarrantyMaintenance',
}

export enum EnumEyeServiceOrderWorkDiaryStatus {
    /** 取消 */
    Cancel = 'Cancel',
    /** 已完成 */
    Done = 'Done',
    /** 新建立 */
    New = 'New',
}

export enum EnumEyeWarrantyContractStatus {
    /** 草稿 */
    Draft = 'Draft',
    /** 有效 */
    Enabled = 'Enabled',
    /** 作廢 */
    Void = 'Void',
}

export enum EnumEyeWarrantyPeriodTypeUnit {
    /** 月 */
    Month = 'Month',
    /** 年 */
    Year = 'Year',
}

export enum EnumLastGmpBpmStatus {
    /** 已取消 */
    Canceled = 'Canceled',
    /** 已完成 */
    Completed = 'Completed',
    /** 再商議 */
    Reconsidered = 'Reconsidered',
    /** 已拒絕（被退件） */
    Rejected = 'Rejected',
    /** 執行中 */
    Waiting = 'Waiting',
}

export enum EnumMaterialStatus {
    /** 停用 */
    Disabled = 'Disabled',
    /** 啟用 */
    Enabled = 'Enabled',
}

/** 區間類型 */
export enum EnumSalesAnalysisPeriodType {
    /** 同期比 */
    Yoy = 'YOY',
}

export enum EnumSalesInvoiceType {
    /** 銷售單 */
    SalesInvoice = 'SalesInvoice',
    /** 銷退單 */
    SalesReturn = 'SalesReturn',
}

/** 時態 */
export enum EnumSalesProductOverallViewTense {
    /** 未來 */
    Future = 'Future',
    /** 過去 */
    Past = 'Past',
    /** 現在 */
    Present = 'Present',
}

/** 銷售商品類型 */
export enum EnumSalesProductType {
    /** 飛秒 */
    Femtosecond = 'Femtosecond',
    /** 傳統 */
    Tradition = 'Tradition',
}

/** 銷售類型 */
export enum EnumSalesStatType {
    /** 飛秒_銷售及銷售退貨_淨值 */
    FemtosecondSalesAndReturn = 'FemtosecondSalesAndReturn',
    /** 總體_銷售退貨 */
    OverallReturn = 'OverallReturn',
    /** 總體_銷售 */
    OverallSales = 'OverallSales',
    /** 傳統_銷售及銷售退貨_淨值 */
    TraditionSalesAndReturn = 'TraditionSalesAndReturn',
}

export enum EnumSalesTeamGroupCode {
    /** 中國_眼科業務 */
    ChnEye = 'CHN_EYE',
    /** 臺灣_電子耳業務 */
    TwnCi = 'TWN_CI',
    /** 臺灣_科明及明睿業務 */
    TwnEcAndMr = 'TWN_EC_AND_MR',
    /** 臺灣_眼科業務 */
    TwnEye = 'TWN_EYE',
    /** 臺灣_新創業務 */
    TwnStartup = 'TWN_STARTUP',
}

/** 排序方向 */
export enum EnumSortDirection {
    /** A-Z 升序（Ascending） */
    Asc = 'ASC',
    /** Z-A 降序（Descending） */
    Desc = 'DESC',
}

/** 依星期統計拜訪 */
export enum EnumVisitByDayOfWeek {
    /** 星期五 */
    Friday = 'Friday',
    /** 星期一 */
    Monday = 'Monday',
    /** 星期六 */
    Saturday = 'Saturday',
    /** 星期日 */
    Sunday = 'Sunday',
    /** 星期四 */
    Thursday = 'Thursday',
    /** 星期二 */
    Tuesday = 'Tuesday',
    /** 星期三 */
    Wednesday = 'Wednesday',
}

/** 拜訪統計類型 */
export enum EnumVisitStatType {
    /** 未拜訪過的商機 */
    UnvisitedBusiness = 'UnvisitedBusiness',
    /** 已拜訪過的商機 */
    VisitedBusiness = 'VisitedBusiness',
    /** 有綁定商機 */
    WithBusiness = 'WithBusiness',
    /** 未綁定商機 */
    WithoutBusiness = 'WithoutBusiness',
}

export enum EnumVisitStatus {
    /** 已取消 */
    Canceled = 'Canceled',
    /** 未拜訪 */
    Unvisited = 'Unvisited',
    /** 已拜訪 */
    Visited = 'Visited',
}

export enum EnumVisitTypeCode {
    /** 商機拜訪 */
    BusinessIncluded = 'BusinessIncluded',
    /** 無商機拜訪 */
    BusinessNotIncluded = 'BusinessNotIncluded',
}

export enum EnumWarrantyBuyType {
    /** 單買合約 */
    BuySeparately = 'BuySeparately',
    /** 與商品一起買合約 */
    BuyWithProduct = 'BuyWithProduct',
    /** 不買合約 */
    NotBuy = 'NotBuy',
}

/** 一週拜訪統計類型 */
export enum EnumWeeklyVisitStatType {
    /** 星期五 */
    Friday = 'Friday',
    /** 星期一 */
    Monday = 'Monday',
    /** 星期六 */
    Saturday = 'Saturday',
    /** 星期日 */
    Sunday = 'Sunday',
    /** 星期四 */
    Thursday = 'Thursday',
    /** 星期二 */
    Tuesday = 'Tuesday',
    /** 星期三 */
    Wednesday = 'Wednesday',
}

export type ExchangeRate = IExchangeRate & {
    createdAt?: Maybe<Scalars['DateTime']>;
    /** 對象幣別 */
    currency1: Currency;
    /** 對象幣別 */
    currency1Id: Scalars['ID'];
    /** 轉換幣別 */
    currency2: Currency;
    /** 轉換幣別 */
    currency2Id: Scalars['ID'];
    id: Scalars['ID'];
    rate: Scalars['Float'];
    /** 區域 */
    region: Region;
    regionId: Scalars['ID'];
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExchangeRateFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    regionId?: InputMaybe<Scalars['ID']>;
};

export type ExchangeRateMethods = {
    count?: Maybe<Scalars['Int']>;
    exchangeRates?: Maybe<Array<ExchangeRate>>;
};

export type EyeFixedAsset = IEyeFixedAsset & {
    /** 借用天數 */
    borrowingDays?: Maybe<Scalars['Int']>;
    /** 廠牌 */
    brand?: Maybe<Scalars['String']>;
    /** 編號 */
    code: Scalars['String'];
    company: Company;
    /** 公司 */
    companyId?: Maybe<Scalars['ID']>;
    createdAt: Scalars['DateTime'];
    createdUser?: Maybe<User>;
    createdUserId?: Maybe<Scalars['ID']>;
    /** 目前「租借中」的租借單 */
    currEyeFixedAssetRentalRecord?: Maybe<EyeFixedAssetRentalRecord>;
    /** 目前所在位置 */
    currentLocation?: Maybe<Scalars['String']>;
    /** 運送天數 */
    deliveryDays?: Maybe<Scalars['Int']>;
    /** 保管部門（於 issues/101545 棄用） */
    dept?: Maybe<Department>;
    /** 保管部門（於 issues/101545 棄用） */
    deptId?: Maybe<Scalars['ID']>;
    eyeFixedAssetItems: Array<EyeFixedAssetItem>;
    eyeFixedAssetRentalRecords: Array<EyeFixedAssetRentalRecord>;
    id: Scalars['ID'];
    /** 是否被預約 */
    isBooking: Scalars['Boolean'];
    /** 最大展延天數 */
    maxExtendedDays?: Maybe<Scalars['Int']>;
    /** 最大展延次數 */
    maxExtendedTimes?: Maybe<Scalars['Int']>;
    memo?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    /** 下一個「待租借」的租借單 */
    nextEyeFixedAssetRentalRecord?: Maybe<EyeFixedAssetRentalRecord>;
    /** PM 負責人員 */
    pmUser?: Maybe<User>;
    /** PM 負責人 */
    pmUserId?: Maybe<Scalars['ID']>;
    /** 上一個「已歸還」的租借單 */
    prevEyeFixedAssetRentalRecord?: Maybe<EyeFixedAssetRentalRecord>;
    region: Region;
    regionId: Scalars['ID'];
    status: EnumEyeFixedAssetsStatus;
    /** 類別 */
    types: Array<EyeFixedAssetType>;
    updatedAt: Scalars['DateTime'];
    updatedUser?: Maybe<User>;
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 保管人員（於 issues/101545 棄用） */
    user?: Maybe<User>;
    /** 保管人員（於 issues/101545 棄用） */
    userId?: Maybe<Scalars['ID']>;
    /** 倉儲 */
    warehouseId?: Maybe<Scalars['ID']>;
};

export type EyeFixedAssetFilterInput = {
    /** 可租借日期（起） */
    availableRentalDate1?: InputMaybe<Scalars['String']>;
    /** 可租借日期（迄） */
    availableRentalDate2?: InputMaybe<Scalars['String']>;
    /** 眼科固定資產租借紀錄 */
    eyeFixedAssetRentalRecordId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 料件編號 */
    materialCode?: InputMaybe<Scalars['String']>;
    /** 料件名稱 */
    materialName?: InputMaybe<Scalars['String']>;
    /** 名稱 */
    name?: InputMaybe<Scalars['String']>;
    /** 料件序號 */
    sn?: InputMaybe<Scalars['String']>;
    /** 狀態 */
    status?: InputMaybe<EnumEyeFixedAssetsStatus>;
    /** 類型 */
    typeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeFixedAssetItem = IEyeFixedAssetItem & {
    createdAt?: Maybe<Scalars['DateTime']>;
    eyeFixedAsset: EyeFixedAsset;
    eyeFixedAssetId: Scalars['ID'];
    id: Scalars['ID'];
    material: Material;
    materialCode: Scalars['String'];
    materialId: Scalars['ID'];
    materialModel?: Maybe<Scalars['String']>;
    materialName?: Maybe<Scalars['String']>;
    materialSpec?: Maybe<Scalars['String']>;
    materialUnit?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
    /** 序號 */
    sn?: Maybe<Scalars['String']>;
    /** 醫療器材單一識別碼 */
    udi?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EyeFixedAssetItemFilterInput = {
    /** 眼科固定資產 */
    eyeFixedAssetId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 產品料件 */
    materialId?: InputMaybe<Scalars['ID']>;
};

export type EyeFixedAssetRentalGoal = IEyeFixedAssetRentalGoal & {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 是否啟用 */
    isActive: Scalars['Boolean'];
    /** 名稱 */
    name: Scalars['String'];
    region: EyeFixedAsset;
    /** 區域 */
    regionId?: Maybe<Scalars['ID']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
};

export type EyeFixedAssetRentalGoalFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeFixedAssetRentalObject = IEyeFixedAssetRentalObject & {
    /** 編號 */
    code?: Maybe<EnumEyeFixedAssetRentalObjectCode>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 是否啟用 */
    isActive: Scalars['Boolean'];
    /** 名稱 */
    name: Scalars['String'];
    region: EyeFixedAsset;
    /** 區域 */
    regionId?: Maybe<Scalars['ID']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
};

export type EyeFixedAssetRentalObjectFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeFixedAssetRentalRecord = IEyeFixedAssetRentalRecord & {
    company: Company;
    /** 公司 */
    companyId?: Maybe<Scalars['ID']>;
    createdAt: Scalars['DateTime'];
    createdUser?: Maybe<User>;
    createdUserId?: Maybe<Scalars['ID']>;
    /** 租借日(起) */
    date1: Scalars['DateTime'];
    /** 租借日(迄) */
    date2: Scalars['DateTime'];
    /** 拆機日 */
    dismantleDate?: Maybe<Scalars['DateTime']>;
    eyeFixedAssets: Array<EyeFixedAsset>;
    eyeServiceOrder?: Maybe<EyeServiceOrder>;
    eyeServiceOrderId: Scalars['ID'];
    id: Scalars['ID'];
    /** 安裝日 */
    installDate?: Maybe<Scalars['DateTime']>;
    region: Region;
    regionId: Scalars['ID'];
    remark?: Maybe<Scalars['String']>;
    status: EnumEyeFixedAssetsRentalStatus;
    updatedAt: Scalars['DateTime'];
    updatedUser?: Maybe<User>;
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type EyeFixedAssetRentalRecordFilterInput = {
    customerId?: InputMaybe<Scalars['ID']>;
    /** 眼科固定資產 */
    eyeFixedAssetId?: InputMaybe<Scalars['ID']>;
    /** 眼科固定資產(复数) */
    eyeFixedAssetIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 眼科服務單 */
    eyeServiceOrderId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 租借日期（起） */
    rentalDate1?: InputMaybe<Scalars['String']>;
    /** 租借日期（迄） */
    rentalDate2?: InputMaybe<Scalars['String']>;
    /** 狀態 */
    status?: InputMaybe<EnumEyeFixedAssetsRentalStatus>;
};

export type EyeFixedAssetType = IEyeFixedAssetType & {
    /** 編號 */
    code: EnumEyeFixedAssetTypeCode;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 是否啟用 */
    isActive: Scalars['Boolean'];
    /** 名稱 */
    name: Scalars['String'];
    region: Region;
    /** 區域 */
    regionId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
};

export type EyeFixedAssetTypeFilterInput = {
    /** 眼科固定資產 */
    eyeFixedAssetId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 區域 */
    regionId?: InputMaybe<Scalars['ID']>;
};

export type EyeProduct = IEyeProduct & {
    /** 台數獎金係數 */
    bonusCoefficient?: Maybe<Scalars['Float']>;
    /** 商品品牌 */
    brand?: Maybe<Scalars['String']>;
    /** 事業主管金額 */
    businessManagerPrice?: Maybe<Scalars['Float']>;
    company: Company;
    /** 公司 */
    companyId: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    createdUser?: Maybe<User>;
    createdUserId?: Maybe<Scalars['ID']>;
    currency: Currency;
    /** 幣別 */
    currencyId: Scalars['ID'];
    /** 有效日期1 */
    date1?: Maybe<Scalars['Date']>;
    /** 有效日期2 */
    date2?: Maybe<Scalars['Date']>;
    /** 經銷金額 */
    dealerPrice?: Maybe<Scalars['Float']>;
    /** 敘述 */
    description?: Maybe<Scalars['String']>;
    /** DSM(辦事處)金額 */
    dsmPrice?: Maybe<Scalars['Float']>;
    /** 群組 */
    eyeProductGroupId?: Maybe<Scalars['ID']>;
    eyeProductItems: Array<EyeProductItem>;
    eyeProductType?: Maybe<EyeProductType>;
    eyeProductTypeId?: Maybe<Scalars['ID']>;
    /** 眼科商品 */
    eyePromotions: Array<EyePromotion>;
    /** 總經理金額 */
    generalManagerPrice?: Maybe<Scalars['Float']>;
    id: Scalars['ID'];
    /** 商品型號 */
    model?: Maybe<Scalars['String']>;
    /** 商品名稱 */
    name: Scalars['String'];
    region: Region;
    regionId: Scalars['ID'];
    /** 大區主管金額 */
    regionalManagerPrice?: Maybe<Scalars['Float']>;
    /** 販售金額 */
    salePrice: Scalars['Float'];
    /** 標準交期（天數） */
    standardDeliveryDays?: Maybe<Scalars['Int']>;
    /** 狀態 */
    status: EnumEyeProductStatus;
    updatedAt: Scalars['DateTime'];
    updatedUser?: Maybe<User>;
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type EyeProductFilterInput = {
    /** 廠牌 */
    brand?: InputMaybe<Scalars['String']>;
    /** 貨幣 */
    currencyId?: InputMaybe<Scalars['ID']>;
    /** 眼科商品類型 */
    eyeProductTypeId?: InputMaybe<Scalars['ID']>;
    /** 眼科促銷活動 */
    eyePromotionId?: InputMaybe<Scalars['ID']>;
    /** 眼科促銷活動 */
    eyePromotionIds?: InputMaybe<Array<Scalars['ID']>>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 今日有效（可選擇）的商品 */
    isValidToday?: InputMaybe<Scalars['Boolean']>;
    /** 型號 */
    model?: InputMaybe<Scalars['String']>;
    /** 名稱 */
    name?: InputMaybe<Scalars['String']>;
    /** 區域 */
    regionId?: InputMaybe<Scalars['ID']>;
    /** 販售金額（起） */
    salePrice1?: InputMaybe<Scalars['Int']>;
    /** 販售金額（迄） */
    salePrice2?: InputMaybe<Scalars['Int']>;
    /** 狀態 */
    statuses?: InputMaybe<Array<EnumEyeProductStatus>>;
};

export type EyeProductItem = IEyeProductItem & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    createdUser?: Maybe<User>;
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 敘述 */
    description?: Maybe<Scalars['String']>;
    eyeProduct: EyeProduct;
    /** 商品 */
    eyeProductId: Scalars['ID'];
    eyeProductItemType: EyeProductItemType;
    /** 商品項目類別 */
    eyeProductItemTypeId: Scalars['ID'];
    id: Scalars['ID'];
    /** 是否為可選的 */
    isOptional: Scalars['Boolean'];
    material: Material;
    /** 料號 */
    materialId: Scalars['ID'];
    /** 名稱 */
    materialName: Scalars['String'];
    /** 料號_銷售計量單位 */
    materialSellingUnit?: Maybe<Scalars['String']>;
    /** 數量 */
    qty: Scalars['Int'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    updatedUser?: Maybe<User>;
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type EyeProductItemFilterInput = {
    eyeProductId?: InputMaybe<Scalars['ID']>;
    eyeProductItemTypeId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeProductItemType = IEyeProductItemType & {
    /** 1: 主商品, 2: 配件, 3: 贈品, 4: 其他 */
    category: Scalars['Int'];
    eyeProductItems: Array<EyeProductItem>;
    id: Scalars['ID'];
    name: Scalars['String'];
    viewOrder?: Maybe<Scalars['Int']>;
};

export type EyeProductItemTypeFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeProductType = IEyeProductType & {
    company: Company;
    /** 公司 */
    companyId: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    name: Scalars['String'];
    region: Region;
    regionId: Scalars['ID'];
    updatedAt: Scalars['DateTime'];
    viewOrder?: Maybe<Scalars['Int']>;
};

export type EyeProductTypeFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 區域 */
    regionId?: InputMaybe<Scalars['ID']>;
};

export type EyePromotion = IEyePromotion & {
    company: Company;
    /** 公司 */
    companyId: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    createdUser?: Maybe<User>;
    createdUserId?: Maybe<Scalars['ID']>;
    currency: Currency;
    /** 幣別 */
    currencyId: Scalars['ID'];
    /** 有效日期1 */
    date1: Scalars['Date'];
    /** 有效日期2 */
    date2?: Maybe<Scalars['Date']>;
    /** 敘述 */
    description?: Maybe<Scalars['String']>;
    /** 依照金額折扣 */
    discountAmount?: Maybe<Scalars['Float']>;
    /** 依照比率折扣 */
    discountRate?: Maybe<Scalars['Float']>;
    /** 眼科商品 */
    eyeProducts: Array<EyeProduct>;
    /** 眼科促銷活動加贈商品項目 */
    eyePromotionAddonProductItems: Array<EyePromotionAddonProductItem>;
    /** 眼科促銷活動條件 */
    eyePromotionConditions: Array<EyePromotionCondition>;
    id: Scalars['ID'];
    /** 是否加贈 */
    isAddon: Scalars['Boolean'];
    /** 優惠名稱 */
    name?: Maybe<Scalars['String']>;
    region: Region;
    regionId: Scalars['ID'];
    updatedAt: Scalars['DateTime'];
    updatedUser?: Maybe<User>;
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type EyePromotionAddonProductItem = IEyePromotionAddonProductItem & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    createdUser?: Maybe<User>;
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 敘述 */
    description?: Maybe<Scalars['String']>;
    eyeProductItemType: EyeProductItemType;
    /** 商品項目類別 */
    eyeProductItemTypeId: Scalars['ID'];
    eyePromotion: EyePromotion;
    /** 促銷活動 */
    eyePromotionId: Scalars['ID'];
    id: Scalars['ID'];
    /** 是否為可選的 */
    isOptional: Scalars['Boolean'];
    material: Material;
    /** 料號 */
    materialId: Scalars['ID'];
    /** 料號_計量單位 */
    materialUnit?: Maybe<Scalars['String']>;
    /** 數量 */
    qty: Scalars['Int'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    updatedUser?: Maybe<User>;
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type EyePromotionAddonProductItemFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyePromotionCondition = IEyePromotionCondition & {
    condition: Scalars['JSON'];
    createdAt: Scalars['DateTime'];
    eyePromotion: EyePromotion;
    eyePromotionId: Scalars['ID'];
    id: Scalars['ID'];
    updatedAt: Scalars['DateTime'];
};

export type EyePromotionConditionFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyePromotionFilterInput = {
    /** 可用的眼科商品 */
    availableEyeProductIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 幣別 */
    currencyId?: InputMaybe<Scalars['ID']>;
    /** 折扣金額（起） */
    discountAmount1?: InputMaybe<Scalars['Int']>;
    /** 折扣金額（迄） */
    discountAmount2?: InputMaybe<Scalars['Int']>;
    /** 折扣率（起） */
    discountRate1?: InputMaybe<Scalars['Int']>;
    /** 折扣率（迄） */
    discountRate2?: InputMaybe<Scalars['Int']>;
    /** 眼科商品 */
    eyeProductId?: InputMaybe<Scalars['ID']>;
    /** 眼科商品 */
    eyeProductIds?: InputMaybe<Array<Scalars['ID']>>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 是否有加贈商品項目 */
    isAddon?: InputMaybe<Scalars['Boolean']>;
    /** 今日有效（可選擇）的商品 */
    isValidToday?: InputMaybe<Scalars['Boolean']>;
    /** 名稱 */
    name?: InputMaybe<Scalars['String']>;
    /** 區域 */
    regionId?: InputMaybe<Scalars['ID']>;
    /** 優惠有效日期（起） */
    validDate1?: InputMaybe<Scalars['String']>;
    /** 優惠有效時間（迄） */
    validDate2?: InputMaybe<Scalars['String']>;
};

export type EyeQuotationOrder = IEyeQuotationOrder & {
    /** 地址 */
    address: Scalars['String'];
    /** 招標金額 */
    biddingPrice?: Maybe<Scalars['Float']>;
    /** BPM Instance id */
    bpmInstanceId?: Maybe<Scalars['ID']>;
    /** 用印BPM id */
    bpmOfficialSealInstanceId?: Maybe<Scalars['ID']>;
    /** BPM 連結 */
    bpmUrl?: Maybe<Scalars['URL']>;
    /** @deprecated 改用「多對一關聯」 */
    business?: Maybe<Business>;
    /** 商機（棄用；改用多對一關聯） */
    businessId?: Maybe<Scalars['ID']>;
    city?: Maybe<City>;
    /** 市級、二級行政區 */
    cityId?: Maybe<Scalars['ID']>;
    /** 編號 */
    code: Scalars['String'];
    /** 佣金 (研究費) */
    commissionAmount: Scalars['Float'];
    company: Company;
    /** 公司 */
    companyId: Scalars['ID'];
    /** 聯絡人 */
    contactPerson: Scalars['String'];
    /** 聯絡電話 */
    contactPhone: Scalars['String'];
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    createdUser: User;
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    creditPeriod?: Maybe<CreditPeriod>;
    /** 信用期間 */
    creditPeriodId: Scalars['ID'];
    currency: Currency;
    /** 幣別 */
    currencyId: Scalars['ID'];
    customer?: Maybe<Customer>;
    /** 裝機客戶 */
    customerId: Scalars['ID'];
    dept: Department;
    /** 負責部門 */
    deptId: Scalars['ID'];
    /** 描述 */
    description?: Maybe<Scalars['String']>;
    /** 折扣金額 */
    discountAmount: Scalars['Float'];
    /** 折扣率 */
    discountRate: Scalars['Float'];
    /** 不包含佣金折扣率 */
    discountRateWithoutCommission: Scalars['Float'];
    district?: Maybe<District>;
    /** 縣級、三級行政區 */
    districtId?: Maybe<Scalars['ID']>;
    /** 匯率 */
    exchangeRate?: Maybe<Scalars['Float']>;
    /** 預計交付日 */
    expectDeliveryDate: Scalars['DateTime'];
    /** 預計收款日 */
    expectPaymentDate: Scalars['DateTime'];
    /** 其他折扣金額 */
    extraDiscountAmount: Scalars['Float'];
    /** 眼科報價單商機 */
    eyeQuotationOrderBusinesses: Array<EyeQuotationOrderBusiness>;
    eyeQuotationOrderProductItems: Array<EyeQuotationOrderProductItem>;
    eyeQuotationOrderProducts: Array<EyeQuotationOrderProduct>;
    eyeQuotationOrderPromotions: Array<EyeQuotationOrderPromotion>;
    /** 眼科报价单类别 */
    eyeQuotationOrderType?: Maybe<EyeQuotationOrderType>;
    /** 眼科報價單類別 */
    eyeQuotationOrderTypeId?: Maybe<Scalars['ID']>;
    eyeQuotationOrderWarrantyItems: Array<EyeQuotationOrderWarrantyItem>;
    eyeServiceOrder?: Maybe<EyeServiceOrder>;
    /** 服務單 */
    eyeServiceOrderId?: Maybe<Scalars['ID']>;
    eyeWarrantyPeriodType?: Maybe<EyeWarrantyPeriodType>;
    id: Scalars['ID'];
    invoicingCustomer?: Maybe<Customer>;
    /** 發票客戶 */
    invoicingCustomerId: Scalars['ID'];
    localCurrency: Currency;
    /** 當地幣別 */
    localCurrencyId: Scalars['ID'];
    /** 當地幣別原價 */
    localStandardAmount: Scalars['Float'];
    /** BPM連結 */
    officialSealBpmUrl?: Maybe<Scalars['URL']>;
    /** 用印狀態 */
    officialSealStatus?: Maybe<EnumEyeQuotationOrderOfficialSealStatus>;
    /** 僅配件及耗材 */
    onlyMaterials: Scalars['Boolean'];
    /** 訂單編號 */
    orderCode?: Maybe<Scalars['String']>;
    province?: Maybe<Province>;
    /** 省級、一級行政區 */
    provinceId?: Maybe<Scalars['ID']>;
    /** 實際金額 */
    realAmount: Scalars['Float'];
    /** 實際折扣金額 */
    realDiscountAmount: Scalars['Float'];
    /** 實際折扣率 */
    realDiscountRate: Scalars['Float'];
    region: Region;
    /** 區域 */
    regionId: Scalars['ID'];
    /** 原價 */
    standardAmount: Scalars['Float'];
    /** 狀態 */
    status: EnumEyeQuotationOrderStatus;
    /** 稅率 */
    taxRate: Scalars['Float'];
    /** 未稅金額 */
    untaxedAmount: Scalars['Float'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    updatedUser: User;
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
    user: User;
    /** 負責人 */
    userId: Scalars['ID'];
    /** 保固購買方式 */
    warrantyBuyType: EnumWarrantyBuyType;
    /** 保固月份 */
    warrantyMonths?: Maybe<Scalars['Int']>;
    /** 保固類型週期 */
    warrantyPeriodTypeId?: Maybe<Scalars['ID']>;
};

export type EyeQuotationOrderBusiness = IEyeQuotationOrderBusiness & {
    /** 商機 */
    business?: Maybe<Business>;
    /** 商機 */
    businessId: Scalars['ID'];
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 眼科報價單 */
    eyeQuotationOrder?: Maybe<EyeQuotationOrder>;
    /** 眼科報價單 */
    eyeQuotationOrderId: Scalars['ID'];
    id: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
};

export type EyeQuotationOrderBusinessFilterInput = {
    businessId?: InputMaybe<Scalars['ID']>;
    eyeQuotationOrderId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeQuotationOrderCreateInput = {
    /** 客戶地址 */
    address?: InputMaybe<Scalars['String']>;
    /** 招標金額 */
    biddingPrice?: InputMaybe<Scalars['Float']>;
    /** 商機（複選） */
    businessIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 市級、二級行政區 */
    cityId?: InputMaybe<Scalars['ID']>;
    /** 佣金 (研究費) */
    commissionAmount: Scalars['Float'];
    /** 公司 */
    companyId: Scalars['ID'];
    /** 客戶聯絡人姓名 */
    contactPerson: Scalars['String'];
    /** 客戶聯絡人電話 */
    contactPhone: Scalars['String'];
    /** 信用期限（預計收款日） */
    creditPeriodId: Scalars['ID'];
    /** 貨幣 */
    currencyId: Scalars['ID'];
    /** 客戶 */
    customerId: Scalars['ID'];
    /** 負責部門 */
    deptId: Scalars['ID'];
    /** 說明 */
    description?: InputMaybe<Scalars['String']>;
    /** 折扣金額 */
    discountAmount: Scalars['Float'];
    /** 折扣率 */
    discountRate: Scalars['Float'];
    /** 不包含佣金折扣率 */
    discountRateWithoutCommission: Scalars['Float'];
    /** 縣級、三級行政區 */
    districtId?: InputMaybe<Scalars['ID']>;
    /** 預計交付日 */
    expectDeliveryDate: Scalars['DateTime'];
    /** 額外折扣金額 */
    extraDiscountAmount: Scalars['Float'];
    /** 報價加購商品項目 */
    eyeQuotationOrderProductItems: Array<EyeQuotationOrderCreateProductItemInput>;
    /** 報價商品 */
    eyeQuotationOrderProducts: Array<EyeQuotationOrderCreateProductInput>;
    /** 優惠方案 */
    eyeQuotationOrderPromotions: Array<EyeQuotationOrderCreatePromotionInput>;
    /** 眼科报价单类别 */
    eyeQuotationOrderTypeId: Scalars['ID'];
    /** 報價保固合約項目 */
    eyeQuotationOrderWarrantyItems: Array<EyeQuotationOrderCreateWarrantyItemInput>;
    /** 眼科服務單 */
    eyeServiceOrderId?: InputMaybe<Scalars['ID']>;
    /** 發票客戶 */
    invoicingCustomerId: Scalars['ID'];
    /** 當地幣別原價 */
    localStandardAmount: Scalars['Float'];
    /** 是否僅配件及耗材 */
    onlyMaterials: Scalars['Boolean'];
    /** 省級、一級行政區 */
    provinceId?: InputMaybe<Scalars['ID']>;
    /** 實際金額 */
    realAmount: Scalars['Float'];
    /** 實際折扣金額 */
    realDiscountAmount: Scalars['Float'];
    /** 實際折扣率 */
    realDiscountRate: Scalars['Float'];
    /** 地區 */
    regionId: Scalars['ID'];
    /** 原價金額 */
    standardAmount: Scalars['Float'];
    /** 稅率 */
    taxRateId: Scalars['ID'];
    /** 未稅金額 */
    untaxedAmount: Scalars['Float'];
    /** 負責人員 */
    userId: Scalars['ID'];
    /** 保固購買方式 */
    warrantyBuyType: EnumWarrantyBuyType;
    /** 保固月份 */
    warrantyMonths?: InputMaybe<Scalars['Int']>;
    /** 保固類型週期 */
    warrantyPeriodTypeId?: InputMaybe<Scalars['ID']>;
};

export type EyeQuotationOrderCreateProductEyeProductItemInput = {
    /** 眼科商品項目id */
    id: Scalars['ID'];
    /** 數量 */
    qty: Scalars['Int'];
};

export type EyeQuotationOrderCreateProductInput = {
    /** 匯率 */
    exchangeRateId?: InputMaybe<Scalars['ID']>;
    /** 眼科商品 */
    eyeProductId: Scalars['ID'];
    /** 眼科商品項目 */
    eyeProductItems: Array<EyeQuotationOrderCreateProductEyeProductItemInput>;
    /** 數量 */
    qty: Scalars['Int'];
};

export type EyeQuotationOrderCreateProductItemInput = {
    /** 匯率 */
    exchangeRateId?: InputMaybe<Scalars['ID']>;
    /** 產品料件 */
    materialId: Scalars['ID'];
    /** 數量 */
    qty: Scalars['Int'];
};

export type EyeQuotationOrderCreatePromotionInput = {
    /** 匯率 */
    exchangeRateId?: InputMaybe<Scalars['ID']>;
    /** 眼科促銷活動加贈商品項目 */
    eyePromotionAddonProductItemIds: Array<Scalars['ID']>;
    /** 眼科促銷活動 */
    eyePromotionId: Scalars['ID'];
};

export type EyeQuotationOrderCreateWarrantyItemInput = {
    /** 產品料件 */
    materialId: Scalars['ID'];
    /** 數量 */
    qty: Scalars['Int'];
    /** 序號 */
    sn: Scalars['String'];
    /** 醫療器材單一識別碼 */
    udi: Scalars['String'];
};

export type EyeQuotationOrderFilterInput = {
    /** 商機 */
    businessId?: InputMaybe<Array<Scalars['ID']>>;
    /** 編號 */
    code?: InputMaybe<Scalars['String']>;
    /** 客戶營業統一編號 */
    customerBusinessCode?: InputMaybe<Scalars['String']>;
    /** 客戶編號 */
    customerCode?: InputMaybe<Scalars['String']>;
    /** 客戶 */
    customerId?: InputMaybe<Scalars['ID']>;
    /** 客戶醫事機構代碼 */
    customerMedicalCode?: InputMaybe<Scalars['String']>;
    /** 客戶名稱 */
    customerName?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 發票客戶營業統一編號 */
    invoiceCustomerBusinessCode?: InputMaybe<Scalars['String']>;
    /** 發票客戶編號 */
    invoiceCustomerCode?: InputMaybe<Scalars['String']>;
    /** 發票客戶 */
    invoiceCustomerId?: InputMaybe<Scalars['ID']>;
    /** 發票客戶醫事機構代碼 */
    invoiceCustomerMedicalCode?: InputMaybe<Scalars['String']>;
    /** 發票客戶名稱 */
    invoiceCustomerName?: InputMaybe<Scalars['String']>;
    /** 訂單編號 */
    orderCode?: InputMaybe<Scalars['String']>;
    statuses?: InputMaybe<Array<EnumEyeQuotationOrderStatus>>;
    /** 負責業務 */
    userId?: InputMaybe<Scalars['ID']>;
    /** 負責業務（複選） */
    userIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 保固合約審查號 */
    warrantyContractApprovalCode?: InputMaybe<Scalars['String']>;
    /** 保固合約編號 */
    warrantyContractCode?: InputMaybe<Scalars['String']>;
};

export type EyeQuotationOrderProduct = IEyeQuotationOrderProduct & {
    /** 商品品牌 */
    brand?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    currency: Currency;
    /** 幣別 */
    currencyId: Scalars['ID'];
    /** 敘述 */
    description?: Maybe<Scalars['String']>;
    /** 匯率 */
    exchangeRate: Scalars['Float'];
    eyeProduct: EyeProduct;
    /** 眼科商品 */
    eyeProductId: Scalars['ID'];
    eyeQuotationOrder: EyeQuotationOrder;
    /** 眼科報價單 */
    eyeQuotationOrderId: Scalars['ID'];
    eyeQuotationOrderProductItems: Array<EyeQuotationOrderProductItem>;
    id: Scalars['ID'];
    /** 商品型號 */
    model?: Maybe<Scalars['String']>;
    /** 商品名稱 */
    name: Scalars['String'];
    /** 數量 */
    qty: Scalars['Int'];
    /** 販售金額 */
    salePrice: Scalars['Float'];
    /** 稅率 */
    taxRate: Scalars['Float'];
    /** 不含稅金額 */
    unitPrice: Scalars['Float'];
    /** 含稅金額 */
    unitPriceVat: Scalars['Float'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
};

export type EyeQuotationOrderProductFilterInput = {
    eyeQuotationOrderId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeQuotationOrderProductItem = IEyeQuotationOrderProductItem & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 敘述 */
    description?: Maybe<Scalars['String']>;
    /** 折扣金額 */
    discountPrice?: Maybe<Scalars['Float']>;
    /** 折扣後販售金額 */
    discountedSellingPrice?: Maybe<Scalars['Float']>;
    /** 匯率 */
    exchangeRate: Scalars['Float'];
    eyeProductItem?: Maybe<EyeProductItem>;
    /** 眼科商品項目 */
    eyeProductItemId?: Maybe<Scalars['ID']>;
    eyePromotionAddonProductItem?: Maybe<EyePromotionAddonProductItem>;
    /** 眼科優惠加購商品項目 */
    eyePromotionAddonProductItemId?: Maybe<Scalars['ID']>;
    eyeQuotationOrder: EyeQuotationOrder;
    /** 眼科報價單 */
    eyeQuotationOrderId: Scalars['ID'];
    eyeQuotationOrderProduct?: Maybe<EyeQuotationOrderProduct>;
    /** 眼科報價單商品 */
    eyeQuotationOrderProductId?: Maybe<Scalars['ID']>;
    eyeQuotationOrderPromotion?: Maybe<EyeQuotationOrderPromotion>;
    /** 眼科報價單優惠 */
    eyeQuotationOrderPromotionId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    material: Material;
    /** 料號_編號 */
    materialCode: Scalars['String'];
    /** 料號 */
    materialId: Scalars['ID'];
    /** 料號_型號 */
    materialModel?: Maybe<Scalars['String']>;
    /** 料號_名稱 */
    materialName?: Maybe<Scalars['String']>;
    materialSellingCurrency: Currency;
    /** 販售幣別 */
    materialSellingCurrencyId?: Maybe<Scalars['ID']>;
    /** 販售金額 */
    materialSellingPrice?: Maybe<Scalars['Float']>;
    /** 料號_規格／機型 */
    materialSpec?: Maybe<Scalars['String']>;
    /** 料號_計量單位 */
    materialUnit?: Maybe<Scalars['String']>;
    /** 實際數量 */
    qty: Scalars['Int'];
    /** 稅率 */
    taxRate: Scalars['Float'];
    /** 不含稅金額 */
    unitPrice: Scalars['Float'];
    /** 含稅金額 */
    unitPriceVat: Scalars['Float'];
    /** 商品項目單位數量 */
    unitQty: Scalars['Int'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
};

export type EyeQuotationOrderProductItemFilterInput = {
    eyeQuotationOrderId?: InputMaybe<Scalars['ID']>;
    eyeQuotationOrderProductId?: InputMaybe<Scalars['ID']>;
    eyeQuotationOrderPromotionId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeQuotationOrderPromotion = IEyeQuotationOrderPromotion & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    currency: Currency;
    /** 幣別 */
    currencyId: Scalars['ID'];
    /** 敘述 */
    description?: Maybe<Scalars['String']>;
    /** 依照金額折扣 */
    discountAmount?: Maybe<Scalars['Float']>;
    /** 依照比率折扣 */
    discountRate?: Maybe<Scalars['Float']>;
    eyePromotion: EyePromotion;
    /** 眼科優惠 */
    eyePromotionId: Scalars['ID'];
    eyeQuotationOrder: EyeQuotationOrder;
    /** 眼科報價單 */
    eyeQuotationOrderId: Scalars['ID'];
    eyeQuotationOrderProductItems: Array<EyeQuotationOrderProductItem>;
    id: Scalars['ID'];
    /** 優惠名稱 */
    name: Scalars['String'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
};

export type EyeQuotationOrderPromotionFilterInput = {
    currencyId?: InputMaybe<Scalars['ID']>;
    eyePromotionId?: InputMaybe<Scalars['ID']>;
    eyeQuotationOrderId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeQuotationOrderType = IEyeQuotationOrderType & {
    /** 編號 */
    code?: Maybe<EnumEyeQuotationOrderType>;
    /** 公司 */
    companyId: Scalars['ID'];
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 區域 */
    regionId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type EyeQuotationOrderTypeFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeQuotationOrderUpdateInput = {
    id: Scalars['ID'];
};

export type EyeQuotationOrderWarrantyItem = IEyeQuotationOrderWarrantyItem & {
    createdAt?: Maybe<Scalars['DateTime']>;
    eyeQuotationOrder: EyeQuotationOrder;
    eyeQuotationOrderId: Scalars['ID'];
    id: Scalars['ID'];
    material: Material;
    materialCode: Scalars['String'];
    materialId: Scalars['ID'];
    materialModel?: Maybe<Scalars['String']>;
    materialName?: Maybe<Scalars['String']>;
    materialSpec?: Maybe<Scalars['String']>;
    materialUnit?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
    /** 實際數量 */
    qty: Scalars['Int'];
    /** 序號 */
    sn?: Maybe<Scalars['String']>;
    /** 醫療器材單一識別碼 */
    udi?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EyeQuotationOrderWarrantyItemFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeServiceOrder = IEyeServiceOrder & {
    /** 地址 */
    address: Scalars['String'];
    /** 核准狀態 */
    approvalStatus: EnumEyeServiceOrderApprovalStatus;
    /** 核准人 */
    approverUser?: Maybe<User>;
    /** 核准人 */
    approverUserId?: Maybe<Scalars['ID']>;
    /** 指派部門 */
    assigneeDept?: Maybe<Department>;
    /** 指派部門 */
    assigneeDeptId: Scalars['ID'];
    /** 被分派人 */
    assigneeUser?: Maybe<User>;
    /** 被分派人 */
    assigneeUserId?: Maybe<Scalars['ID']>;
    /** BPM Instance id */
    bpmInstanceId?: Maybe<Scalars['ID']>;
    /** BPM 連結 */
    bpmUrl?: Maybe<Scalars['URL']>;
    /** 商機 */
    business?: Maybe<Business>;
    /** 商機 */
    businessId?: Maybe<Scalars['ID']>;
    /** 市級、二級行政區 */
    city?: Maybe<City>;
    /** 市級、二級行政區 */
    cityId?: Maybe<Scalars['ID']>;
    code: Scalars['String'];
    company?: Maybe<Company>;
    /** 公司 */
    companyId?: Maybe<Scalars['ID']>;
    /** 聯絡人 */
    contactPerson: Scalars['String'];
    /** 聯絡電話 */
    contactPhone: Scalars['String'];
    /** 成本中心 */
    costCenter?: Maybe<CostCenter>;
    /** 成本中心 */
    costCenterId?: Maybe<Scalars['ID']>;
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUser?: Maybe<User>;
    createdUserId?: Maybe<Scalars['ID']>;
    /** 客戶 */
    customer?: Maybe<Customer>;
    /** 對象客戶 */
    customerId?: Maybe<Scalars['ID']>;
    description?: Maybe<Scalars['String']>;
    /** 調度士 */
    dispatcherUser?: Maybe<User>;
    /** 調度士 */
    dispatcherUserId?: Maybe<Scalars['ID']>;
    /** 縣級、三級行政區 */
    district?: Maybe<District>;
    /** 縣級、三級行政區 */
    districtId?: Maybe<Scalars['ID']>;
    /** 預計派工日 */
    estimatedDate: Scalars['Date'];
    /** 展延紀錄 */
    extension?: Maybe<EyeServiceOrderExtension>;
    /** 展延次數 */
    extensionCount?: Maybe<Scalars['Int']>;
    /** 眼科固资租借目的 */
    eyeFixedAssetRentalGoal?: Maybe<EyeFixedAssetRentalGoal>;
    /** 眼科固定資產租借目的 */
    eyeFixedAssetRentalGoalId?: Maybe<Scalars['ID']>;
    /** 眼科固資租借對象 */
    eyeFixedAssetRentalObject?: Maybe<EyeFixedAssetRentalObject>;
    /** 眼科固定資產租借對象 */
    eyeFixedAssetRentalObjectId?: Maybe<Scalars['ID']>;
    /** 眼科固資租借紀錄 */
    eyeFixedAssetRentalRecords: Array<EyeFixedAssetRentalRecord>;
    eyeServiceOrderType: EyeServiceOrderType;
    eyeServiceOrderTypeId: Scalars['ID'];
    /** 派工單 */
    eyeServiceOrderWorkDiaries: Array<EyeServiceOrderWorkDiary>;
    eyeWarrantyContract?: Maybe<EyeWarrantyContract>;
    eyeWarrantyContractId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    memo?: Maybe<Scalars['String']>;
    /** 優先權 */
    priority: EnumEyeServiceOrderPriority;
    /** 省級、一級行政區 */
    province?: Maybe<Province>;
    /** 省級、一級行政區 */
    provinceId?: Maybe<Scalars['ID']>;
    region: Region;
    regionId: Scalars['ID'];
    status: EnumEyeServiceOrderStatus;
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUser?: Maybe<User>;
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 申請人 */
    user?: Maybe<User>;
    /** 對象使用者 */
    userId?: Maybe<Scalars['ID']>;
};

export type EyeServiceOrderAttachFile = IEyeServiceOrderAttachFile & {
    createdAt: Scalars['DateTime'];
    createdUser?: Maybe<User>;
    createdUserId?: Maybe<Scalars['ID']>;
    extension: Scalars['String'];
    eyeServiceOrder: EyeServiceOrder;
    eyeServiceOrderId: Scalars['ID'];
    id: Scalars['ID'];
    memo?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    s3Key: Scalars['String'];
    /** 檔案大小 (Bytes) */
    size: Scalars['Float'];
    updatedAt: Scalars['DateTime'];
    updatedUser?: Maybe<User>;
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 短效期的 S3 URL */
    url?: Maybe<Scalars['URL']>;
};

export type EyeServiceOrderAttachFileFilterInput = {
    /** 眼科服務單 */
    eyeServiceOrderId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeServiceOrderCreateInput = {
    /** 地址 */
    address?: InputMaybe<Scalars['String']>;
    /** 核准狀態 */
    approvalStatus?: InputMaybe<EnumEyeServiceOrderApprovalStatus>;
    /** 核准人 */
    approverUserId?: InputMaybe<Scalars['ID']>;
    /** 指派部門 */
    assigneeDeptId?: InputMaybe<Scalars['ID']>;
    /** 被指派人 */
    assigneeUserId?: InputMaybe<Scalars['ID']>;
    /** 商機 */
    businessId?: InputMaybe<Scalars['ID']>;
    /** 市級、二級行政區 */
    cityId?: InputMaybe<Scalars['ID']>;
    /** 公司 */
    companyId?: InputMaybe<Scalars['ID']>;
    /** 聯絡人名稱 */
    contactPerson?: InputMaybe<Scalars['String']>;
    /** 聯絡人電話 */
    contactPhone?: InputMaybe<Scalars['String']>;
    /** 成本中心 */
    costCenterId?: InputMaybe<Scalars['ID']>;
    /** 客戶 */
    customerId?: InputMaybe<Scalars['ID']>;
    /** 描述 */
    description?: InputMaybe<Scalars['String']>;
    /** 調度士 */
    dispatcherUserId?: InputMaybe<Scalars['ID']>;
    /** 縣級、三級行政區 */
    districtId?: InputMaybe<Scalars['ID']>;
    /** 預計派工日 */
    estimatedDate?: InputMaybe<Scalars['DateTime']>;
    /** 租借目的 */
    eyeFixedAssetRentalGoalId?: InputMaybe<Scalars['ID']>;
    /** 租借對象 */
    eyeFixedAssetRentalObjectId?: InputMaybe<Scalars['ID']>;
    /** 優惠方案 */
    eyeFixedAssetRentalRecords?: InputMaybe<Array<EyeServiceOrder_EyeFixedAssetRentalRecordInput>>;
    /** 眼科服務單類型 */
    eyeServiceOrderTypeId: Scalars['ID'];
    /** 眼科保固合約 */
    eyeWarrantyContractId?: InputMaybe<Scalars['ID']>;
    /** 報價商品 */
    items?: InputMaybe<Array<EyeServiceOrder_EyeServiceOrderItemInput>>;
    /** 備註 */
    memo?: InputMaybe<Scalars['String']>;
    /** 優先權 */
    priority?: InputMaybe<EnumEyeServiceOrderPriority>;
    /** 省級、一級行政區 */
    provinceId?: InputMaybe<Scalars['ID']>;
    /** 服務狀態 */
    status?: InputMaybe<EnumEyeServiceOrderStatus>;
    /** 申請人 */
    userId?: InputMaybe<Scalars['ID']>;
};

export type EyeServiceOrderExtension = IEyeServiceOrderExtension & {
    approvalStatus: EnumEyeServiceOrderExtensionApprovalStatus;
    bpmInstanceId?: Maybe<Scalars['ID']>;
    /** BPM 連結 */
    bpmUrl?: Maybe<Scalars['URL']>;
    createdAt: Scalars['DateTime'];
    extendDate1: Scalars['Date'];
    extendDate2: Scalars['Date'];
    eyeServiceOrder: EyeServiceOrder;
    eyeServiceOrderId: Scalars['ID'];
    id: Scalars['ID'];
    memo?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type EyeServiceOrderExtensionFilterInput = {
    /** 服務單 */
    eyeServiceOrderId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeServiceOrderFilterInput = {
    /** 核准狀態 */
    approvalStatus?: InputMaybe<EnumEyeServiceOrderApprovalStatus>;
    /** 核准人 */
    approverUserId?: InputMaybe<Scalars['ID']>;
    /** 指派部門 */
    assigneeDeptId?: InputMaybe<Scalars['ID']>;
    /** 被指派人 */
    assigneeUserId?: InputMaybe<Scalars['ID']>;
    /** 商機 */
    businessId?: InputMaybe<Scalars['ID']>;
    /** 編號 */
    code?: InputMaybe<Scalars['String']>;
    /** 客戶編號 */
    customerCode?: InputMaybe<Scalars['String']>;
    /** 客戶 */
    customerId?: InputMaybe<Scalars['ID']>;
    /** 客戶名稱 */
    customerName?: InputMaybe<Scalars['String']>;
    /** 預計派工日（起） */
    estimatedDate1?: InputMaybe<Scalars['String']>;
    /** 預計派工日（迄） */
    estimatedDate2?: InputMaybe<Scalars['String']>;
    /** 眼科服務單類型編號 */
    eyeServiceOrderTypeCode?: InputMaybe<EnumEyeServiceOrderTypeCode>;
    /** 類型 */
    eyeServiceOrderTypeId?: InputMaybe<Scalars['ID']>;
    /** 保固合約編號 */
    eyeWarrantyContractCode?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 優先權 */
    priority?: InputMaybe<EnumEyeServiceOrderPriority>;
    /** 區域 */
    regionId?: InputMaybe<Scalars['ID']>;
    /** 服務狀態 */
    status?: InputMaybe<EnumEyeServiceOrderStatus>;
    /** 申請人 */
    userId?: InputMaybe<Scalars['ID']>;
};

export type EyeServiceOrderInput = {
    /** 地址 */
    address?: InputMaybe<Scalars['String']>;
    /** 核准狀態 */
    approvalStatus?: InputMaybe<EnumEyeServiceOrderApprovalStatus>;
    /** 核准人 */
    approverUserId?: InputMaybe<Scalars['ID']>;
    /** 指派部門 */
    assigneeDeptId?: InputMaybe<Scalars['ID']>;
    /** 被指派人 */
    assigneeUserId?: InputMaybe<Scalars['ID']>;
    /** 商機 */
    businessId?: InputMaybe<Scalars['ID']>;
    /** 市級、二級行政區 */
    cityId?: InputMaybe<Scalars['ID']>;
    /** 聯絡人名稱 */
    contactPerson?: InputMaybe<Scalars['String']>;
    /** 聯絡人電話 */
    contactPhone?: InputMaybe<Scalars['String']>;
    /** 成本中心 */
    costCenterId?: InputMaybe<Scalars['ID']>;
    /** 描述 */
    description?: InputMaybe<Scalars['String']>;
    /** 調度士 */
    dispatcherUserId?: InputMaybe<Scalars['ID']>;
    /** 縣級、三級行政區 */
    districtId?: InputMaybe<Scalars['ID']>;
    /** 預計派工日 */
    estimatedDate?: InputMaybe<Scalars['DateTime']>;
    /** 租借目的 */
    eyeFixedAssetRentalGoalId?: InputMaybe<Scalars['ID']>;
    /** 租借對象 */
    eyeFixedAssetRentalObjectId?: InputMaybe<Scalars['ID']>;
    /** 眼科服務單類型 */
    eyeServiceOrderTypeId: Scalars['ID'];
    /** 備註 */
    memo?: InputMaybe<Scalars['String']>;
    /** 優先權 */
    priority?: InputMaybe<EnumEyeServiceOrderPriority>;
    /** 省級、一級行政區 */
    provinceId?: InputMaybe<Scalars['ID']>;
    /** 服務狀態 */
    status?: InputMaybe<EnumEyeServiceOrderStatus>;
};

export type EyeServiceOrderItem = IEyeServiceOrderItem & {
    createdAt: Scalars['DateTime'];
    createdUserId?: Maybe<Scalars['ID']>;
    eyeServiceOrder: EyeServiceOrder;
    eyeServiceOrderId: Scalars['ID'];
    id: Scalars['ID'];
    material: Material;
    materialCode: Scalars['String'];
    materialId: Scalars['ID'];
    materialModel?: Maybe<Scalars['String']>;
    materialName?: Maybe<Scalars['String']>;
    materialSpec?: Maybe<Scalars['String']>;
    materialUnit?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
    qty: Scalars['Int'];
    sn?: Maybe<Scalars['String']>;
    /** 醫療器材單一識別碼 */
    udi: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type EyeServiceOrderItemFilterInput = {
    /** 服務單 */
    eyeServiceOrderId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeServiceOrderType = IEyeServiceOrderType & {
    /** 編號 */
    code?: Maybe<EnumEyeServiceOrderTypeCode>;
    id: Scalars['ID'];
    name: Scalars['String'];
    viewOrder?: Maybe<Scalars['Int']>;
};

export type EyeServiceOrderTypeFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeServiceOrderUpdateInput = {
    /** 地址 */
    address?: InputMaybe<Scalars['String']>;
    /** 核准狀態 */
    approvalStatus?: InputMaybe<EnumEyeServiceOrderApprovalStatus>;
    /** 核准人 */
    approverUserId?: InputMaybe<Scalars['ID']>;
    /** 指派部門 */
    assigneeDeptId?: InputMaybe<Scalars['ID']>;
    /** 被指派人 */
    assigneeUserId?: InputMaybe<Scalars['ID']>;
    /** 商機 */
    businessId?: InputMaybe<Scalars['ID']>;
    /** 市級、二級行政區 */
    cityId?: InputMaybe<Scalars['ID']>;
    /** 聯絡人名稱 */
    contactPerson?: InputMaybe<Scalars['String']>;
    /** 聯絡人電話 */
    contactPhone?: InputMaybe<Scalars['String']>;
    /** 成本中心 */
    costCenterId?: InputMaybe<Scalars['ID']>;
    /** 描述 */
    description?: InputMaybe<Scalars['String']>;
    /** 調度士 */
    dispatcherUserId?: InputMaybe<Scalars['ID']>;
    /** 縣級、三級行政區 */
    districtId?: InputMaybe<Scalars['ID']>;
    /** 預計派工日 */
    estimatedDate?: InputMaybe<Scalars['DateTime']>;
    /** 租借目的 */
    eyeFixedAssetRentalGoalId?: InputMaybe<Scalars['ID']>;
    /** 租借對象 */
    eyeFixedAssetRentalObjectId?: InputMaybe<Scalars['ID']>;
    /** 眼科服務單類型 */
    eyeServiceOrderTypeId: Scalars['ID'];
    id: Scalars['ID'];
    /** 備註 */
    memo?: InputMaybe<Scalars['String']>;
    /** 優先權 */
    priority?: InputMaybe<EnumEyeServiceOrderPriority>;
    /** 省級、一級行政區 */
    provinceId?: InputMaybe<Scalars['ID']>;
    /** 服務狀態 */
    status?: InputMaybe<EnumEyeServiceOrderStatus>;
};

export type EyeServiceOrderWorkDiary = IEyeServiceOrderWorkDiary & {
    company?: Maybe<Company>;
    /** 公司 */
    companyId?: Maybe<Scalars['ID']>;
    createdAt: Scalars['DateTime'];
    createdUser?: Maybe<User>;
    createdUserId?: Maybe<Scalars['ID']>;
    /** 實際服務日期 */
    date?: Maybe<Scalars['DateTime']>;
    /** 預計服務日期 */
    estimatedDate?: Maybe<Scalars['DateTime']>;
    /** 預計服務時數 */
    estimatedHours?: Maybe<Scalars['Int']>;
    eyeFixedAssetRentalRecord?: Maybe<EyeFixedAssetRentalRecord>;
    eyeFixedAssetRentalRecordId?: Maybe<Scalars['ID']>;
    eyeServiceOrder: EyeServiceOrder;
    eyeServiceOrderId: Scalars['ID'];
    eyeServiceOrderWorkDiaryType: EyeServiceOrderWorkDiaryType;
    eyeServiceOrderWorkDiaryTypeId: Scalars['ID'];
    /** 實際服務時數 */
    hours?: Maybe<Scalars['Int']>;
    id: Scalars['ID'];
    region: Region;
    regionId: Scalars['ID'];
    remark?: Maybe<Scalars['String']>;
    status: EnumEyeServiceOrderWorkDiaryStatus;
    updatedAt: Scalars['DateTime'];
    updatedUser?: Maybe<User>;
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 相關人員 */
    users: Array<User>;
};

export type EyeServiceOrderWorkDiaryAttachFile = IEyeServiceOrderWorkDiaryAttachFile & {
    createdAt: Scalars['DateTime'];
    createdUser?: Maybe<User>;
    createdUserId?: Maybe<Scalars['ID']>;
    extension: Scalars['String'];
    eyeServiceOrderWorkDiary: EyeServiceOrderWorkDiary;
    eyeServiceOrderWorkDiaryId: Scalars['ID'];
    id: Scalars['ID'];
    memo?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    s3Key: Scalars['String'];
    /** 檔案大小 (Bytes) */
    size: Scalars['Float'];
    updatedAt: Scalars['DateTime'];
    updatedUser?: Maybe<User>;
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 短效期的 S3 URL */
    url?: Maybe<Scalars['URL']>;
};

export type EyeServiceOrderWorkDiaryAttachFileFilterInput = {
    /** 眼科服務單工作記錄 */
    eyeServiceOrderWorkDiaryId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeServiceOrderWorkDiaryCreateInput = {
    /** 實際服務日期 */
    date?: InputMaybe<Scalars['DateTime']>;
    /** 預計服務日期 */
    estimatedDate?: InputMaybe<Scalars['DateTime']>;
    /** 預計服務時數 */
    estimatedHours?: InputMaybe<Scalars['Int']>;
    /** 固資租借 */
    eyeFixedAssetRentalRecordId?: InputMaybe<Scalars['ID']>;
    /** 服務單 */
    eyeServiceOrderId?: InputMaybe<Scalars['ID']>;
    /** 服務單工作記錄類型 */
    eyeServiceOrderWorkDiaryTypeId?: InputMaybe<Scalars['ID']>;
    /** 實際服務時數 */
    hours?: InputMaybe<Scalars['Int']>;
    /** 備註 */
    remark?: InputMaybe<Scalars['String']>;
    /** 狀態 */
    status?: InputMaybe<EnumEyeServiceOrderWorkDiaryStatus>;
    /** 負責（支援）業務 */
    userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeServiceOrderWorkDiaryFilterInput = {
    /** 公司 */
    companyId?: InputMaybe<Scalars['ID']>;
    /** 客戶編號 */
    customerCode?: InputMaybe<Scalars['String']>;
    /** 客戶名稱 */
    customerName?: InputMaybe<Scalars['String']>;
    /** 實際服務日期（起） */
    date1?: InputMaybe<Scalars['String']>;
    /** 實際服務日期（迄） */
    date2?: InputMaybe<Scalars['String']>;
    /** 固資租借紀錄 */
    eyeFixedAssetRentalRecordId?: InputMaybe<Scalars['ID']>;
    /** 服務單編號 */
    eyeServiceOrderCode?: InputMaybe<Scalars['String']>;
    /** 服務單 */
    eyeServiceOrderId?: InputMaybe<Scalars['ID']>;
    /** 服務單狀態 */
    eyeServiceOrderStatus?: InputMaybe<EnumEyeServiceOrderStatus>;
    /** 服務單工作記錄類型 */
    eyeServiceOrderWorkDiaryTypeId?: InputMaybe<Scalars['ID']>;
    /** 保固合約編號 */
    eyeWarrantyContractCode?: InputMaybe<Scalars['String']>;
    /** 保固合約 */
    eyeWarrantyContractId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 區域 */
    regionId?: InputMaybe<Scalars['ID']>;
    /** 狀態 */
    status?: InputMaybe<EnumEyeServiceOrderWorkDiaryStatus>;
    /** 處理人（複選） */
    userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeServiceOrderWorkDiaryInput = {
    /** 實際服務日期 */
    date?: InputMaybe<Scalars['DateTime']>;
    /** 預計服務日期 */
    estimatedDate?: InputMaybe<Scalars['DateTime']>;
    /** 預計服務時數 */
    estimatedHours?: InputMaybe<Scalars['Int']>;
    /** 固資租借 */
    eyeFixedAssetRentalRecordId?: InputMaybe<Scalars['ID']>;
    /** 服務單 */
    eyeServiceOrderId?: InputMaybe<Scalars['ID']>;
    /** 服務單工作記錄類型 */
    eyeServiceOrderWorkDiaryTypeId?: InputMaybe<Scalars['ID']>;
    /** 實際服務時數 */
    hours?: InputMaybe<Scalars['Int']>;
    /** 備註 */
    remark?: InputMaybe<Scalars['String']>;
    /** 狀態 */
    status?: InputMaybe<EnumEyeServiceOrderWorkDiaryStatus>;
    /** 負責（支援）業務 */
    userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeServiceOrderWorkDiaryType = IEyeServiceOrderWorkDiaryType & {
    createdAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    name: Scalars['String'];
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EyeServiceOrderWorkDiaryTypeFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeServiceOrderWorkDiaryUpdateInput = {
    /** 實際服務日期 */
    date?: InputMaybe<Scalars['DateTime']>;
    /** 預計服務日期 */
    estimatedDate?: InputMaybe<Scalars['DateTime']>;
    /** 預計服務時數 */
    estimatedHours?: InputMaybe<Scalars['Int']>;
    /** 固資租借 */
    eyeFixedAssetRentalRecordId?: InputMaybe<Scalars['ID']>;
    /** 服務單 */
    eyeServiceOrderId?: InputMaybe<Scalars['ID']>;
    /** 服務單工作記錄類型 */
    eyeServiceOrderWorkDiaryTypeId?: InputMaybe<Scalars['ID']>;
    /** 實際服務時數 */
    hours?: InputMaybe<Scalars['Int']>;
    id: Scalars['ID'];
    /** 備註 */
    remark?: InputMaybe<Scalars['String']>;
    /** 狀態 */
    status?: InputMaybe<EnumEyeServiceOrderWorkDiaryStatus>;
    /** 負責（支援）業務 */
    userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeServiceOrder_EyeFixedAssetRentalRecordInput = {
    /** 租借日（起） */
    date1: Scalars['DateTime'];
    /** 租借日（迄） */
    date2: Scalars['DateTime'];
    /** 拆機日 */
    dismantleDate?: InputMaybe<Scalars['DateTime']>;
    /** 固定資產（複選） */
    eyeFixedAssetIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 安裝日 */
    installDate?: InputMaybe<Scalars['DateTime']>;
    /** 備註 */
    remark?: InputMaybe<Scalars['String']>;
};

export type EyeServiceOrder_EyeServiceOrderItemInput = {
    /** 料件 */
    materialId: Scalars['ID'];
    /** 備註 */
    memo?: InputMaybe<Scalars['String']>;
    /** 數量 */
    qty?: InputMaybe<Scalars['Int']>;
    /** 序號 */
    sn?: InputMaybe<Scalars['String']>;
    /** 醫療器材單一識別碼 */
    udi?: InputMaybe<Scalars['String']>;
};

export type EyeWarrantyContract = IEyeWarrantyContract & {
    /** 地址 */
    address: Scalars['String'];
    /** 合約審查號 */
    approvalCode: Scalars['String'];
    city: City;
    /** 市級、二級行政區 */
    cityId?: Maybe<Scalars['ID']>;
    /** 合約編號 */
    code: Scalars['String'];
    company: Company;
    /** 公司 */
    companyId: Scalars['ID'];
    /** 聯絡人 */
    contactPerson: Scalars['String'];
    /** 聯絡電話 */
    contactPhone: Scalars['String'];
    createdAt: Scalars['DateTime'];
    createdUser: User;
    createdUserId?: Maybe<Scalars['ID']>;
    customer: Customer;
    /** 客戶 */
    customerId: Scalars['ID'];
    dept: Department;
    /** 負責部門 */
    deptId: Scalars['ID'];
    description?: Maybe<Scalars['String']>;
    district: District;
    /** 縣級、三級行政區 */
    districtId?: Maybe<Scalars['ID']>;
    eyeWarrantyPeriodType: EyeWarrantyPeriodType;
    id: Scalars['ID'];
    memo?: Maybe<Scalars['String']>;
    /** 訂單編號 */
    orderCode: Scalars['String'];
    province: Province;
    /** 省級、一級行政區 */
    provinceId?: Maybe<Scalars['ID']>;
    region: Region;
    regionId: Scalars['ID'];
    status: EnumEyeWarrantyContractStatus;
    updatedAt: Scalars['DateTime'];
    updatedUser: User;
    updatedUserId?: Maybe<Scalars['ID']>;
    user: User;
    /** 負責人 */
    userId: Scalars['ID'];
    /** 保固起始日 */
    warrantyDate1: Scalars['Date'];
    /** 保固結束日 */
    warrantyDate2: Scalars['Date'];
    /** 保固月份 */
    warrantyMonths: Scalars['Int'];
    /** 保固週期類型 */
    warrantyPeriodTypeId: Scalars['ID'];
};

export type EyeWarrantyContractFilterInput = {
    /** 合約編號 */
    code?: InputMaybe<Scalars['String']>;
    customerId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeWarrantyContractItem = IEyeWarrantyContractItem & {
    createdAt: Scalars['DateTime'];
    eyeWarrantyContract: EyeWarrantyContract;
    eyeWarrantyContractId: Scalars['ID'];
    id: Scalars['ID'];
    material: Material;
    materialCode: Scalars['String'];
    materialId: Scalars['ID'];
    materialModel?: Maybe<Scalars['String']>;
    materialName?: Maybe<Scalars['String']>;
    materialSpec?: Maybe<Scalars['String']>;
    materialUnit?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
    qty: Scalars['Int'];
    /** 序號 */
    sn?: Maybe<Scalars['String']>;
    /** 醫療器材單一識別碼 */
    udi?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type EyeWarrantyContractItemFilterInput = {
    eyeWarrantyContractId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeWarrantyPeriodType = IEyeWarrantyPeriodType & {
    code: Scalars['String'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    name: Scalars['String'];
    unit: EnumEyeWarrantyPeriodTypeUnit;
    updatedAt: Scalars['DateTime'];
    value: Scalars['Int'];
};

export type EyeWarrantyPeriodTypeFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type FormattedBusinessProperties = {
    properties?: Maybe<Array<BusinessProperty>>;
    propertyType?: Maybe<BusinessPropertyType>;
};

export type FormattedCustomerProperties = {
    properties?: Maybe<Array<CustomerProperty>>;
    propertyType?: Maybe<CustomerPropertyType>;
};

export type FormattedVisitProperties = {
    properties?: Maybe<Array<VisitProperty>>;
    propertyType?: Maybe<VisitPropertyType>;
};

export type IAppVersion = {
    code: Scalars['String'];
    currentVersion: Scalars['String'];
    id: Scalars['ID'];
    name: Scalars['String'];
    type: Scalars['String'];
};

export type IBusiness = {
    /** 客戶預算金額 */
    budgetAmount?: Maybe<Scalars['Float']>;
    /** 購買機會 */
    buyingOpportunityId?: Maybe<Scalars['ID']>;
    /** 實際結束日期 */
    closedDate?: Maybe<Scalars['Date']>;
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 內容 */
    content?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 客戶 */
    customerId?: Maybe<Scalars['ID']>;
    /** 雙方成交金額 */
    dealAmount?: Maybe<Scalars['Float']>;
    /** 經銷商 */
    dealerId?: Maybe<Scalars['ID']>;
    /** 預計結束日期 */
    expectedClosedDate?: Maybe<Scalars['Date']>;
    /** 眼科報價單編號 */
    eyeQuotationOrderCode?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    /** 丟單改善計畫 */
    losingImprovementPlan?: Maybe<Scalars['String']>;
    /** 丟單原因 */
    losingReason?: Maybe<Scalars['String']>;
    /** 訂單編號（鼎新、道一雲、用友） */
    orderCode?: Maybe<Scalars['String']>;
    /** 主要負責業務（棄用；改用 sales_team_unit_id ） */
    primaryUserId?: Maybe<Scalars['ID']>;
    /** 業務銷售金額 */
    saleAmount?: Maybe<Scalars['Float']>;
    /** 銷售方式 */
    salesMethodId?: Maybe<Scalars['ID']>;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 負責業務團隊 */
    salesTeamId?: Maybe<Scalars['ID']>;
    /** 業務團隊單位 */
    salesTeamUnitId?: Maybe<Scalars['ID']>;
    /** 狀態 */
    statusId?: Maybe<Scalars['ID']>;
    /** 標題 */
    title?: Maybe<Scalars['String']>;
    /** 類型 */
    typeId?: Maybe<Scalars['ID']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 贏單機會 */
    winningOpportunityId?: Maybe<Scalars['ID']>;
};

export type IBusinessLosingReason = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IBusinessMemo = {
    /** 商機 */
    businessId: Scalars['ID'];
    /** 內容 */
    content?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    /** 標題 */
    title?: Maybe<Scalars['String']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type IBusinessOpportunity = {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 是否啟用於前端畫面顯示 */
    enabled: Scalars['Boolean'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IBusinessProduct = {
    /** 廠牌 */
    brand?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 是否啟用 */
    isActive: Scalars['Boolean'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 類型 */
    typeId?: Maybe<Scalars['ID']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IBusinessProductType = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IBusinessProperty = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 類型 */
    typeId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IBusinessPropertyType = {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IBusinessSalesMethod = {
    /** 編號 */
    code?: Maybe<EnumBusinessSalesMethodCode>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IBusinessStatus = {
    /** 購買機會 */
    buyingOpportunity?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 類型 */
    type?: Maybe<EnumBusinessStatusType>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IBusinessType = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IBusinessesToBudgetProduct = {
    /** 預算商品 */
    budgetProductId: Scalars['ID'];
    /** 商機 */
    businessId: Scalars['ID'];
    id: Scalars['ID'];
    /** 數量 */
    qty: Scalars['Int'];
};

export type IBusinessesToDealProduct = {
    /** 商機 */
    businessId: Scalars['ID'];
    /** 成交商品 */
    dealProductId: Scalars['ID'];
    id: Scalars['ID'];
    /** 數量 */
    qty: Scalars['Int'];
};

export type IBusinessesUser = {
    /** 商機 */
    businessId: Scalars['ID'];
    id: Scalars['ID'];
    /** 業務團隊 */
    salesTeamId?: Maybe<Scalars['ID']>;
    /** 負責（協助）業務 */
    userId: Scalars['ID'];
};

export type ICity = {
    areaId?: Maybe<Scalars['ID']>;
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    name: Scalars['String'];
    provinceId: Scalars['ID'];
    regionId: Scalars['ID'];
    updatedAt: Scalars['DateTime'];
    viewOrder: Scalars['Int'];
};

export type ICompany = {
    /** 公司編號 */
    code?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    enabled?: Maybe<Scalars['Boolean']>;
    hrCode?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    name: Scalars['String'];
    phone?: Maybe<Scalars['String']>;
    regionId: Scalars['ID'];
    updatedAt: Scalars['DateTime'];
};

export type ICompetitor = {
    /** 對方優勢 */
    advantage?: Maybe<Scalars['String']>;
    /** 代理商 */
    agents?: Maybe<Scalars['String']>;
    /** 廠牌 */
    brand?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 對方劣勢 */
    disadvantage?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    /** 備註 */
    memo?: Maybe<Scalars['String']>;
    /** 機型 */
    model?: Maybe<Scalars['String']>;
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 應對策略 */
    strategy?: Maybe<Scalars['String']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type IContactPerson = {
    /** 地址 */
    address?: Maybe<Scalars['String']>;
    /** 身分證字號／公民身份號碼 */
    citizenCode?: Maybe<Scalars['String']>;
    /** 編號（內勤系統自動添加） */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 部門 */
    departmentId?: Maybe<Scalars['ID']>;
    /** 部門（棄用） */
    dept?: Maybe<Scalars['String']>;
    /** 醫師代碼 */
    doctorCode?: Maybe<Scalars['String']>;
    /** 電子信箱 */
    email?: Maybe<Scalars['String']>;
    /** 傳真電話 */
    fax?: Maybe<Scalars['String']>;
    /** 性別 */
    gender?: Maybe<EnumContactPeopleGender>;
    /** 興趣愛好 */
    hobby?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    /** 職稱 */
    jobTitle?: Maybe<Scalars['String']>;
    /** 備註 */
    memo?: Maybe<Scalars['String']>;
    /** 行動電話 */
    mobile?: Maybe<Scalars['String']>;
    /** 姓名 */
    name: Scalars['String'];
    /** 暱稱 */
    nickname?: Maybe<Scalars['String']>;
    /** 座機電話 */
    phone?: Maybe<Scalars['String']>;
    /** 轉介編號 */
    referenceCode?: Maybe<Scalars['String']>;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 專長技能 */
    skill?: Maybe<Scalars['String']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 網站 */
    website?: Maybe<Scalars['URL']>;
};

export type IContactPersonDepartment = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type ICostCenter = {
    /** 編號 */
    code: Scalars['String'];
    /** 公司 */
    companyId: Scalars['ID'];
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 是否啟用 */
    isActive?: Maybe<Scalars['Boolean']>;
    /** 名稱 */
    name: Scalars['String'];
    /** 區域 */
    regionId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
};

export type ICreditPeriod = {
    createdAt?: Maybe<Scalars['DateTime']>;
    /** 天數 */
    days: Scalars['Int'];
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    name: Scalars['String'];
    regionId: Scalars['ID'];
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ICurrency = {
    /** ISO 4217 currency codes */
    code: Scalars['String'];
    /** 小數點後幾位 */
    decimalPlace: Scalars['Int'];
    id: Scalars['ID'];
    name: Scalars['String'];
    /** 貨幣符號 */
    symbol: Scalars['String'];
};

export type ICustomer = {
    /** 地址 */
    address?: Maybe<Scalars['String']>;
    /** 分區 */
    areaId?: Maybe<Scalars['ID']>;
    /** 銀行戶頭代碼 */
    bankAccountCode?: Maybe<Scalars['String']>;
    /** 銀行戶頭名稱 */
    bankAccountName?: Maybe<Scalars['String']>;
    /** 開票單位名稱 */
    billingUnitName?: Maybe<Scalars['String']>;
    /** 營業統一編號 */
    businessCode?: Maybe<Scalars['String']>;
    /** 分類 */
    categoryId?: Maybe<Scalars['ID']>;
    /** 市級、二級行政區 */
    cityId?: Maybe<Scalars['ID']>;
    /** 編號（鼎新／內部通用） */
    code?: Maybe<Scalars['String']>;
    /** 總負責聯絡人姓名 */
    contactPersonName?: Maybe<Scalars['String']>;
    /** 總負責聯絡人電話 */
    contactPersonPhone?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 信用期限（科林評定） */
    creditPeriodId?: Maybe<Scalars['ID']>;
    /** 信用額度（科林評定） */
    creditQuota?: Maybe<Scalars['Float']>;
    /** 預設付款方式 */
    defaultPaymentMethodId?: Maybe<Scalars['ID']>;
    /** 預設配送方式 */
    defaultShippingMethodId?: Maybe<Scalars['ID']>;
    /** 縣級、三級行政區 */
    districtId?: Maybe<Scalars['ID']>;
    /** 編號（道一雲） */
    do1Code?: Maybe<Scalars['String']>;
    /** 電子信箱 */
    email?: Maybe<Scalars['String']>;
    /** 傳真電話 */
    fax?: Maybe<Scalars['String']>;
    /** 集團 */
    groupId?: Maybe<Scalars['ID']>;
    /** 是否有生效的首營資料證照 */
    hasEffectiveGmpCertificate?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    /** 是否啟用 */
    isActive: Scalars['Boolean'];
    /** 是否為首營客戶 */
    isGmp?: Maybe<Scalars['Boolean']>;
    /** 最近 BPM 首營資料 Instance ID */
    lastGmpBpmInstanceId?: Maybe<Scalars['ID']>;
    /** 最近 BPM 首營資料申請狀態 */
    lastGmpBpmStatus?: Maybe<EnumLastGmpBpmStatus>;
    /** 最近 BPM 首營資料申請人員 */
    lastGmpBpmUserId?: Maybe<Scalars['ID']>;
    /** 法人姓名 */
    legalPersonName?: Maybe<Scalars['String']>;
    /** 醫事機構代碼 */
    medicalCode?: Maybe<Scalars['String']>;
    /** 備註 */
    memo?: Maybe<Scalars['String']>;
    /** 行動電話 */
    mobile?: Maybe<Scalars['String']>;
    /** 名稱 */
    name: Scalars['String'];
    /** 編號（NAV） */
    navCode?: Maybe<Scalars['String']>;
    /** 上層客戶 */
    parentId?: Maybe<Scalars['ID']>;
    /** 座機電話 */
    phone?: Maybe<Scalars['String']>;
    /** 省級、一級行政區 */
    provinceId?: Maybe<Scalars['ID']>;
    /** 轉介編號 */
    referenceCode?: Maybe<Scalars['String']>;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 收貨地址 */
    shippingAddress?: Maybe<Scalars['String']>;
    /** 簡稱 */
    shortName?: Maybe<Scalars['String']>;
    /** 同步代碼 */
    syncCode?: Maybe<Scalars['String']>;
    /** 同步來源 */
    syncSourceId?: Maybe<Scalars['ID']>;
    /** 類型 */
    typeId?: Maybe<Scalars['ID']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 網站 */
    website?: Maybe<Scalars['URL']>;
    /** 編號（用友） */
    yonyouCode?: Maybe<Scalars['String']>;
};

export type ICustomerArea = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type ICustomerAttachment = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 客戶 */
    customerId: Scalars['ID'];
    /** 副檔名 */
    extension: Scalars['String'];
    id: Scalars['ID'];
    /** 備註 */
    memo?: Maybe<Scalars['String']>;
    /** 名稱 */
    name: Scalars['String'];
    /** S3 Key */
    s3Key: Scalars['String'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type ICustomerCategory = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type ICustomerCertificate = {
    /** 證號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 客戶 */
    customerId: Scalars['ID'];
    /** 是否禁用 */
    disabled: Scalars['Boolean'];
    /** 生效日期 */
    effectiveDate?: Maybe<Scalars['Date']>;
    /** 失效日期 */
    expiryDate?: Maybe<Scalars['Date']>;
    id: Scalars['ID'];
    /** 經營範圍 */
    scope?: Maybe<Scalars['String']>;
    /** 類型 */
    typeId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type ICustomerCertificateAttachment = {
    /** 證照 */
    certificateId: Scalars['ID'];
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 副檔名 */
    extension: Scalars['String'];
    id: Scalars['ID'];
    /** 備註 */
    memo?: Maybe<Scalars['String']>;
    /** 名稱 */
    name: Scalars['String'];
    /** S3 Key */
    s3Key: Scalars['String'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type ICustomerCertificateType = {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type ICustomerGroup = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    /** 名稱 */
    name?: Maybe<Scalars['String']>;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type ICustomerLevel = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 類型 */
    typeId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type ICustomerLevelField = {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 類型 */
    typeId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type ICustomerLevelFieldType = {
    /** 編號 */
    code?: Maybe<EnumCustomerLevelFieldTypeCode>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type ICustomerLevelType = {
    /** 編號 */
    code?: Maybe<EnumCustomerLevelTypeCode>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type ICustomerProperty = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 類型 */
    typeId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type ICustomerPropertyType = {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type ICustomerType = {
    /** 編號 */
    code?: Maybe<EnumCustomerTypeCode>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type ICustomersPrimaryUser = {
    /** 客戶 */
    customerId: Scalars['ID'];
    id: Scalars['ID'];
    /** 業務團隊 */
    salesTeamId?: Maybe<Scalars['ID']>;
    /** 負責業務 */
    userId: Scalars['ID'];
};

export type IDepartment = {
    code?: Maybe<Scalars['String']>;
    companyId?: Maybe<Scalars['ID']>;
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 是否啟用 */
    isActive: Scalars['Boolean'];
    /** 主管 */
    managerId?: Maybe<Scalars['ID']>;
    name: Scalars['String'];
    /** 上層部門 */
    parentId?: Maybe<Scalars['ID']>;
    regionId: Scalars['ID'];
    /** 帳套 */
    setOfBook?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type IDistrict = {
    cityId?: Maybe<Scalars['ID']>;
    code?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    name: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    viewOrder: Scalars['Int'];
};

export type IExchangeRate = {
    createdAt?: Maybe<Scalars['DateTime']>;
    /** 對象幣別 */
    currency1Id: Scalars['ID'];
    /** 轉換幣別 */
    currency2Id: Scalars['ID'];
    id: Scalars['ID'];
    rate: Scalars['Float'];
    regionId: Scalars['ID'];
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IEyeFixedAsset = {
    /** 借用天數 */
    borrowingDays?: Maybe<Scalars['Int']>;
    /** 廠牌 */
    brand?: Maybe<Scalars['String']>;
    /** 編號 */
    code: Scalars['String'];
    /** 公司 */
    companyId?: Maybe<Scalars['ID']>;
    createdAt: Scalars['DateTime'];
    createdUserId?: Maybe<Scalars['ID']>;
    /** 目前所在位置 */
    currentLocation?: Maybe<Scalars['String']>;
    /** 運送天數 */
    deliveryDays?: Maybe<Scalars['Int']>;
    /** 保管部門（於 issues/101545 棄用） */
    deptId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    /** 最大展延天數 */
    maxExtendedDays?: Maybe<Scalars['Int']>;
    /** 最大展延次數 */
    maxExtendedTimes?: Maybe<Scalars['Int']>;
    memo?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    /** PM 負責人 */
    pmUserId?: Maybe<Scalars['ID']>;
    regionId: Scalars['ID'];
    status: EnumEyeFixedAssetsStatus;
    updatedAt: Scalars['DateTime'];
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 保管人員（於 issues/101545 棄用） */
    userId?: Maybe<Scalars['ID']>;
    /** 倉儲 */
    warehouseId?: Maybe<Scalars['ID']>;
};

export type IEyeFixedAssetItem = {
    createdAt?: Maybe<Scalars['DateTime']>;
    eyeFixedAssetId: Scalars['ID'];
    id: Scalars['ID'];
    materialCode: Scalars['String'];
    materialId: Scalars['ID'];
    materialModel?: Maybe<Scalars['String']>;
    materialName?: Maybe<Scalars['String']>;
    materialSpec?: Maybe<Scalars['String']>;
    materialUnit?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
    /** 序號 */
    sn?: Maybe<Scalars['String']>;
    /** 醫療器材單一識別碼 */
    udi?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IEyeFixedAssetRentalGoal = {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 是否啟用 */
    isActive: Scalars['Boolean'];
    /** 名稱 */
    name: Scalars['String'];
    /** 區域 */
    regionId?: Maybe<Scalars['ID']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
};

export type IEyeFixedAssetRentalObject = {
    /** 編號 */
    code?: Maybe<EnumEyeFixedAssetRentalObjectCode>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 是否啟用 */
    isActive: Scalars['Boolean'];
    /** 名稱 */
    name: Scalars['String'];
    /** 區域 */
    regionId?: Maybe<Scalars['ID']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
};

export type IEyeFixedAssetRentalRecord = {
    /** 公司 */
    companyId?: Maybe<Scalars['ID']>;
    createdAt: Scalars['DateTime'];
    createdUserId?: Maybe<Scalars['ID']>;
    /** 租借日(起) */
    date1: Scalars['DateTime'];
    /** 租借日(迄) */
    date2: Scalars['DateTime'];
    /** 拆機日 */
    dismantleDate?: Maybe<Scalars['DateTime']>;
    eyeServiceOrderId: Scalars['ID'];
    id: Scalars['ID'];
    /** 安裝日 */
    installDate?: Maybe<Scalars['DateTime']>;
    regionId: Scalars['ID'];
    remark?: Maybe<Scalars['String']>;
    status: EnumEyeFixedAssetsRentalStatus;
    updatedAt: Scalars['DateTime'];
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyeFixedAssetType = {
    /** 編號 */
    code: EnumEyeFixedAssetTypeCode;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 是否啟用 */
    isActive: Scalars['Boolean'];
    /** 名稱 */
    name: Scalars['String'];
    /** 區域 */
    regionId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
};

export type IEyeProduct = {
    /** 台數獎金係數 */
    bonusCoefficient?: Maybe<Scalars['Float']>;
    /** 商品品牌 */
    brand?: Maybe<Scalars['String']>;
    /** 事業主管金額 */
    businessManagerPrice?: Maybe<Scalars['Float']>;
    /** 公司 */
    companyId: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    createdUserId?: Maybe<Scalars['ID']>;
    /** 幣別 */
    currencyId: Scalars['ID'];
    /** 有效日期1 */
    date1?: Maybe<Scalars['Date']>;
    /** 有效日期2 */
    date2?: Maybe<Scalars['Date']>;
    /** 經銷金額 */
    dealerPrice?: Maybe<Scalars['Float']>;
    /** 敘述 */
    description?: Maybe<Scalars['String']>;
    /** DSM(辦事處)金額 */
    dsmPrice?: Maybe<Scalars['Float']>;
    /** 群組 */
    eyeProductGroupId?: Maybe<Scalars['ID']>;
    eyeProductTypeId?: Maybe<Scalars['ID']>;
    /** 總經理金額 */
    generalManagerPrice?: Maybe<Scalars['Float']>;
    id: Scalars['ID'];
    /** 商品型號 */
    model?: Maybe<Scalars['String']>;
    /** 商品名稱 */
    name: Scalars['String'];
    regionId: Scalars['ID'];
    /** 大區主管金額 */
    regionalManagerPrice?: Maybe<Scalars['Float']>;
    /** 販售金額 */
    salePrice: Scalars['Float'];
    /** 標準交期（天數） */
    standardDeliveryDays?: Maybe<Scalars['Int']>;
    /** 狀態 */
    status: EnumEyeProductStatus;
    updatedAt: Scalars['DateTime'];
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyeProductItem = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 敘述 */
    description?: Maybe<Scalars['String']>;
    /** 商品 */
    eyeProductId: Scalars['ID'];
    /** 商品項目類別 */
    eyeProductItemTypeId: Scalars['ID'];
    id: Scalars['ID'];
    /** 是否為可選的 */
    isOptional: Scalars['Boolean'];
    /** 料號 */
    materialId: Scalars['ID'];
    /** 名稱 */
    materialName: Scalars['String'];
    /** 料號_銷售計量單位 */
    materialSellingUnit?: Maybe<Scalars['String']>;
    /** 數量 */
    qty: Scalars['Int'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IEyeProductItemType = {
    /** 1: 主商品, 2: 配件, 3: 贈品, 4: 其他 */
    category: Scalars['Int'];
    id: Scalars['ID'];
    name: Scalars['String'];
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IEyeProductType = {
    /** 公司 */
    companyId: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    name: Scalars['String'];
    regionId: Scalars['ID'];
    updatedAt: Scalars['DateTime'];
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IEyePromotion = {
    /** 公司 */
    companyId: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    createdUserId?: Maybe<Scalars['ID']>;
    /** 幣別 */
    currencyId: Scalars['ID'];
    /** 有效日期1 */
    date1: Scalars['Date'];
    /** 有效日期2 */
    date2?: Maybe<Scalars['Date']>;
    /** 敘述 */
    description?: Maybe<Scalars['String']>;
    /** 依照金額折扣 */
    discountAmount?: Maybe<Scalars['Float']>;
    /** 依照比率折扣 */
    discountRate?: Maybe<Scalars['Float']>;
    id: Scalars['ID'];
    /** 是否加贈 */
    isAddon: Scalars['Boolean'];
    /** 優惠名稱 */
    name?: Maybe<Scalars['String']>;
    regionId: Scalars['ID'];
    updatedAt: Scalars['DateTime'];
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyePromotionAddonProductItem = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 敘述 */
    description?: Maybe<Scalars['String']>;
    /** 商品項目類別 */
    eyeProductItemTypeId: Scalars['ID'];
    /** 促銷活動 */
    eyePromotionId: Scalars['ID'];
    id: Scalars['ID'];
    /** 是否為可選的 */
    isOptional: Scalars['Boolean'];
    /** 料號 */
    materialId: Scalars['ID'];
    /** 料號_計量單位 */
    materialUnit?: Maybe<Scalars['String']>;
    /** 數量 */
    qty: Scalars['Int'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyePromotionCondition = {
    condition: Scalars['JSON'];
    createdAt: Scalars['DateTime'];
    eyePromotionId: Scalars['ID'];
    id: Scalars['ID'];
    updatedAt: Scalars['DateTime'];
};

export type IEyeQuotationOrder = {
    /** 地址 */
    address: Scalars['String'];
    /** 招標金額 */
    biddingPrice?: Maybe<Scalars['Float']>;
    /** BPM Instance id */
    bpmInstanceId?: Maybe<Scalars['ID']>;
    /** 用印BPM id */
    bpmOfficialSealInstanceId?: Maybe<Scalars['ID']>;
    /** 商機（棄用；改用多對一關聯） */
    businessId?: Maybe<Scalars['ID']>;
    /** 市級、二級行政區 */
    cityId?: Maybe<Scalars['ID']>;
    /** 編號 */
    code: Scalars['String'];
    /** 佣金 (研究費) */
    commissionAmount: Scalars['Float'];
    /** 公司 */
    companyId: Scalars['ID'];
    /** 聯絡人 */
    contactPerson: Scalars['String'];
    /** 聯絡電話 */
    contactPhone: Scalars['String'];
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 信用期間 */
    creditPeriodId: Scalars['ID'];
    /** 幣別 */
    currencyId: Scalars['ID'];
    /** 裝機客戶 */
    customerId: Scalars['ID'];
    /** 負責部門 */
    deptId: Scalars['ID'];
    /** 描述 */
    description?: Maybe<Scalars['String']>;
    /** 折扣金額 */
    discountAmount: Scalars['Float'];
    /** 折扣率 */
    discountRate: Scalars['Float'];
    /** 不包含佣金折扣率 */
    discountRateWithoutCommission: Scalars['Float'];
    /** 縣級、三級行政區 */
    districtId?: Maybe<Scalars['ID']>;
    /** 匯率 */
    exchangeRate?: Maybe<Scalars['Float']>;
    /** 預計交付日 */
    expectDeliveryDate: Scalars['DateTime'];
    /** 預計收款日 */
    expectPaymentDate: Scalars['DateTime'];
    /** 其他折扣金額 */
    extraDiscountAmount: Scalars['Float'];
    /** 眼科報價單類別 */
    eyeQuotationOrderTypeId?: Maybe<Scalars['ID']>;
    /** 服務單 */
    eyeServiceOrderId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    /** 發票客戶 */
    invoicingCustomerId: Scalars['ID'];
    /** 當地幣別 */
    localCurrencyId: Scalars['ID'];
    /** 當地幣別原價 */
    localStandardAmount: Scalars['Float'];
    /** 用印狀態 */
    officialSealStatus?: Maybe<EnumEyeQuotationOrderOfficialSealStatus>;
    /** 僅配件及耗材 */
    onlyMaterials: Scalars['Boolean'];
    /** 訂單編號 */
    orderCode?: Maybe<Scalars['String']>;
    /** 省級、一級行政區 */
    provinceId?: Maybe<Scalars['ID']>;
    /** 實際金額 */
    realAmount: Scalars['Float'];
    /** 實際折扣金額 */
    realDiscountAmount: Scalars['Float'];
    /** 實際折扣率 */
    realDiscountRate: Scalars['Float'];
    /** 區域 */
    regionId: Scalars['ID'];
    /** 原價 */
    standardAmount: Scalars['Float'];
    /** 狀態 */
    status: EnumEyeQuotationOrderStatus;
    /** 稅率 */
    taxRate: Scalars['Float'];
    /** 未稅金額 */
    untaxedAmount: Scalars['Float'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 負責人 */
    userId: Scalars['ID'];
    /** 保固購買方式 */
    warrantyBuyType: EnumWarrantyBuyType;
    /** 保固月份 */
    warrantyMonths?: Maybe<Scalars['Int']>;
    /** 保固類型週期 */
    warrantyPeriodTypeId?: Maybe<Scalars['ID']>;
};

export type IEyeQuotationOrderBusiness = {
    /** 商機 */
    businessId: Scalars['ID'];
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 眼科報價單 */
    eyeQuotationOrderId: Scalars['ID'];
    id: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
};

export type IEyeQuotationOrderProduct = {
    /** 商品品牌 */
    brand?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 幣別 */
    currencyId: Scalars['ID'];
    /** 敘述 */
    description?: Maybe<Scalars['String']>;
    /** 匯率 */
    exchangeRate: Scalars['Float'];
    /** 眼科商品 */
    eyeProductId: Scalars['ID'];
    /** 眼科報價單 */
    eyeQuotationOrderId: Scalars['ID'];
    id: Scalars['ID'];
    /** 商品型號 */
    model?: Maybe<Scalars['String']>;
    /** 商品名稱 */
    name: Scalars['String'];
    /** 數量 */
    qty: Scalars['Int'];
    /** 販售金額 */
    salePrice: Scalars['Float'];
    /** 稅率 */
    taxRate: Scalars['Float'];
    /** 不含稅金額 */
    unitPrice: Scalars['Float'];
    /** 含稅金額 */
    unitPriceVat: Scalars['Float'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
};

export type IEyeQuotationOrderProductItem = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 敘述 */
    description?: Maybe<Scalars['String']>;
    /** 折扣金額 */
    discountPrice?: Maybe<Scalars['Float']>;
    /** 折扣後販售金額 */
    discountedSellingPrice?: Maybe<Scalars['Float']>;
    /** 匯率 */
    exchangeRate: Scalars['Float'];
    /** 眼科商品項目 */
    eyeProductItemId?: Maybe<Scalars['ID']>;
    /** 眼科優惠加購商品項目 */
    eyePromotionAddonProductItemId?: Maybe<Scalars['ID']>;
    /** 眼科報價單 */
    eyeQuotationOrderId: Scalars['ID'];
    /** 眼科報價單商品 */
    eyeQuotationOrderProductId?: Maybe<Scalars['ID']>;
    /** 眼科報價單優惠 */
    eyeQuotationOrderPromotionId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    /** 料號_編號 */
    materialCode: Scalars['String'];
    /** 料號 */
    materialId: Scalars['ID'];
    /** 料號_型號 */
    materialModel?: Maybe<Scalars['String']>;
    /** 料號_名稱 */
    materialName?: Maybe<Scalars['String']>;
    /** 販售幣別 */
    materialSellingCurrencyId?: Maybe<Scalars['ID']>;
    /** 販售金額 */
    materialSellingPrice?: Maybe<Scalars['Float']>;
    /** 料號_規格／機型 */
    materialSpec?: Maybe<Scalars['String']>;
    /** 料號_計量單位 */
    materialUnit?: Maybe<Scalars['String']>;
    /** 實際數量 */
    qty: Scalars['Int'];
    /** 稅率 */
    taxRate: Scalars['Float'];
    /** 不含稅金額 */
    unitPrice: Scalars['Float'];
    /** 含稅金額 */
    unitPriceVat: Scalars['Float'];
    /** 商品項目單位數量 */
    unitQty: Scalars['Int'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
};

export type IEyeQuotationOrderPromotion = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 幣別 */
    currencyId: Scalars['ID'];
    /** 敘述 */
    description?: Maybe<Scalars['String']>;
    /** 依照金額折扣 */
    discountAmount?: Maybe<Scalars['Float']>;
    /** 依照比率折扣 */
    discountRate?: Maybe<Scalars['Float']>;
    /** 眼科優惠 */
    eyePromotionId: Scalars['ID'];
    /** 眼科報價單 */
    eyeQuotationOrderId: Scalars['ID'];
    id: Scalars['ID'];
    /** 優惠名稱 */
    name: Scalars['String'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
};

export type IEyeQuotationOrderType = {
    /** 編號 */
    code?: Maybe<EnumEyeQuotationOrderType>;
    /** 公司 */
    companyId: Scalars['ID'];
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 區域 */
    regionId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IEyeQuotationOrderWarrantyItem = {
    createdAt?: Maybe<Scalars['DateTime']>;
    eyeQuotationOrderId: Scalars['ID'];
    id: Scalars['ID'];
    materialCode: Scalars['String'];
    materialId: Scalars['ID'];
    materialModel?: Maybe<Scalars['String']>;
    materialName?: Maybe<Scalars['String']>;
    materialSpec?: Maybe<Scalars['String']>;
    materialUnit?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
    /** 實際數量 */
    qty: Scalars['Int'];
    /** 序號 */
    sn?: Maybe<Scalars['String']>;
    /** 醫療器材單一識別碼 */
    udi?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IEyeServiceOrder = {
    /** 地址 */
    address: Scalars['String'];
    /** 核准狀態 */
    approvalStatus: EnumEyeServiceOrderApprovalStatus;
    /** 核准人 */
    approverUserId?: Maybe<Scalars['ID']>;
    /** 指派部門 */
    assigneeDeptId: Scalars['ID'];
    /** 被分派人 */
    assigneeUserId?: Maybe<Scalars['ID']>;
    /** BPM Instance id */
    bpmInstanceId?: Maybe<Scalars['ID']>;
    /** 商機 */
    businessId?: Maybe<Scalars['ID']>;
    /** 市級、二級行政區 */
    cityId?: Maybe<Scalars['ID']>;
    code: Scalars['String'];
    /** 公司 */
    companyId?: Maybe<Scalars['ID']>;
    /** 聯絡人 */
    contactPerson: Scalars['String'];
    /** 聯絡電話 */
    contactPhone: Scalars['String'];
    /** 成本中心 */
    costCenterId?: Maybe<Scalars['ID']>;
    createdAt: Scalars['DateTime'];
    createdUserId?: Maybe<Scalars['ID']>;
    /** 對象客戶 */
    customerId?: Maybe<Scalars['ID']>;
    description?: Maybe<Scalars['String']>;
    /** 調度士 */
    dispatcherUserId?: Maybe<Scalars['ID']>;
    /** 縣級、三級行政區 */
    districtId?: Maybe<Scalars['ID']>;
    /** 預計派工日 */
    estimatedDate: Scalars['Date'];
    /** 眼科固定資產租借目的 */
    eyeFixedAssetRentalGoalId?: Maybe<Scalars['ID']>;
    /** 眼科固定資產租借對象 */
    eyeFixedAssetRentalObjectId?: Maybe<Scalars['ID']>;
    eyeServiceOrderTypeId: Scalars['ID'];
    eyeWarrantyContractId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    memo?: Maybe<Scalars['String']>;
    /** 優先權 */
    priority: EnumEyeServiceOrderPriority;
    /** 省級、一級行政區 */
    provinceId?: Maybe<Scalars['ID']>;
    regionId: Scalars['ID'];
    status: EnumEyeServiceOrderStatus;
    updatedAt: Scalars['DateTime'];
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 對象使用者 */
    userId?: Maybe<Scalars['ID']>;
};

export type IEyeServiceOrderAttachFile = {
    createdAt: Scalars['DateTime'];
    createdUserId?: Maybe<Scalars['ID']>;
    extension: Scalars['String'];
    eyeServiceOrderId: Scalars['ID'];
    id: Scalars['ID'];
    memo?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    s3Key: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyeServiceOrderExtension = {
    approvalStatus: EnumEyeServiceOrderExtensionApprovalStatus;
    bpmInstanceId?: Maybe<Scalars['ID']>;
    createdAt: Scalars['DateTime'];
    extendDate1: Scalars['Date'];
    extendDate2: Scalars['Date'];
    eyeServiceOrderId: Scalars['ID'];
    id: Scalars['ID'];
    memo?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type IEyeServiceOrderItem = {
    createdAt: Scalars['DateTime'];
    createdUserId?: Maybe<Scalars['ID']>;
    eyeServiceOrderId: Scalars['ID'];
    id: Scalars['ID'];
    materialCode: Scalars['String'];
    materialId: Scalars['ID'];
    materialModel?: Maybe<Scalars['String']>;
    materialName?: Maybe<Scalars['String']>;
    materialSpec?: Maybe<Scalars['String']>;
    materialUnit?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
    qty: Scalars['Int'];
    sn?: Maybe<Scalars['String']>;
    /** 醫療器材單一識別碼 */
    udi: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyeServiceOrderType = {
    /** 編號 */
    code?: Maybe<EnumEyeServiceOrderTypeCode>;
    id: Scalars['ID'];
    name: Scalars['String'];
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IEyeServiceOrderWorkDiary = {
    /** 公司 */
    companyId?: Maybe<Scalars['ID']>;
    createdAt: Scalars['DateTime'];
    createdUserId?: Maybe<Scalars['ID']>;
    /** 實際服務日期 */
    date?: Maybe<Scalars['DateTime']>;
    /** 預計服務日期 */
    estimatedDate?: Maybe<Scalars['DateTime']>;
    /** 預計服務時數 */
    estimatedHours?: Maybe<Scalars['Int']>;
    eyeFixedAssetRentalRecordId?: Maybe<Scalars['ID']>;
    eyeServiceOrderId: Scalars['ID'];
    eyeServiceOrderWorkDiaryTypeId: Scalars['ID'];
    /** 實際服務時數 */
    hours?: Maybe<Scalars['Int']>;
    id: Scalars['ID'];
    regionId: Scalars['ID'];
    remark?: Maybe<Scalars['String']>;
    status: EnumEyeServiceOrderWorkDiaryStatus;
    updatedAt: Scalars['DateTime'];
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyeServiceOrderWorkDiaryAttachFile = {
    createdAt: Scalars['DateTime'];
    createdUserId?: Maybe<Scalars['ID']>;
    extension: Scalars['String'];
    eyeServiceOrderWorkDiaryId: Scalars['ID'];
    id: Scalars['ID'];
    memo?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    s3Key: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyeServiceOrderWorkDiaryType = {
    createdAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    name: Scalars['String'];
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IEyeWarrantyContract = {
    /** 地址 */
    address: Scalars['String'];
    /** 合約審查號 */
    approvalCode: Scalars['String'];
    /** 市級、二級行政區 */
    cityId?: Maybe<Scalars['ID']>;
    /** 合約編號 */
    code: Scalars['String'];
    /** 公司 */
    companyId: Scalars['ID'];
    /** 聯絡人 */
    contactPerson: Scalars['String'];
    /** 聯絡電話 */
    contactPhone: Scalars['String'];
    createdAt: Scalars['DateTime'];
    createdUserId?: Maybe<Scalars['ID']>;
    /** 客戶 */
    customerId: Scalars['ID'];
    /** 負責部門 */
    deptId: Scalars['ID'];
    description?: Maybe<Scalars['String']>;
    /** 縣級、三級行政區 */
    districtId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    memo?: Maybe<Scalars['String']>;
    /** 訂單編號 */
    orderCode: Scalars['String'];
    /** 省級、一級行政區 */
    provinceId?: Maybe<Scalars['ID']>;
    regionId: Scalars['ID'];
    status: EnumEyeWarrantyContractStatus;
    updatedAt: Scalars['DateTime'];
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 負責人 */
    userId: Scalars['ID'];
    /** 保固起始日 */
    warrantyDate1: Scalars['Date'];
    /** 保固結束日 */
    warrantyDate2: Scalars['Date'];
    /** 保固月份 */
    warrantyMonths: Scalars['Int'];
    /** 保固週期類型 */
    warrantyPeriodTypeId: Scalars['ID'];
};

export type IEyeWarrantyContractItem = {
    createdAt: Scalars['DateTime'];
    eyeWarrantyContractId: Scalars['ID'];
    id: Scalars['ID'];
    materialCode: Scalars['String'];
    materialId: Scalars['ID'];
    materialModel?: Maybe<Scalars['String']>;
    materialName?: Maybe<Scalars['String']>;
    materialSpec?: Maybe<Scalars['String']>;
    materialUnit?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
    qty: Scalars['Int'];
    /** 序號 */
    sn?: Maybe<Scalars['String']>;
    /** 醫療器材單一識別碼 */
    udi?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type IEyeWarrantyPeriodType = {
    code: Scalars['String'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    name: Scalars['String'];
    unit: EnumEyeWarrantyPeriodTypeUnit;
    updatedAt: Scalars['DateTime'];
    value: Scalars['Int'];
};

export type IMaterial = {
    /** 廠牌 */
    brand?: Maybe<Scalars['String']>;
    /** 編號 */
    code: Scalars['String'];
    /** 顏色 */
    color?: Maybe<Scalars['String']>;
    /** 公司 */
    companyId: Scalars['ID'];
    /** 成本幣別 */
    costCurrencyId?: Maybe<Scalars['ID']>;
    /** 成本金額 */
    costPrice?: Maybe<Scalars['Float']>;
    /** 庫存單位 */
    countingUnitId?: Maybe<Scalars['ID']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    /** 是否可用（棄用；改用 status） */
    isActive: Scalars['Boolean'];
    /** 維修類別 */
    materialRepairTypeId?: Maybe<Scalars['ID']>;
    /** 類別 */
    materialTypeId?: Maybe<Scalars['ID']>;
    /** 型號 */
    model?: Maybe<Scalars['String']>;
    /** 名稱 */
    name: Scalars['String'];
    /** 名稱（中文） */
    name2?: Maybe<Scalars['String']>;
    /** 區域 */
    regionId: Scalars['ID'];
    /** 銷售幣別 */
    saleCurrencyId?: Maybe<Scalars['ID']>;
    /** 銷售金額 */
    salePrice?: Maybe<Scalars['Float']>;
    /** 規格／機型 */
    spec?: Maybe<Scalars['String']>;
    /** 狀態（0 = 停用, 1 = 啟用） */
    status: EnumMaterialStatus;
    /** 供應商 */
    supplierId?: Maybe<Scalars['ID']>;
    /** 同步代碼 */
    syncCode?: Maybe<Scalars['String']>;
    /** 同步來源 */
    syncSourceId?: Maybe<Scalars['ID']>;
    /** 稅率 */
    taxRate: Scalars['Float'];
    /** 庫存單位（棄用；改用 counting_unit_id） */
    unit?: Maybe<Scalars['String']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type IMaterialExtraProperty = {
    createdAt: Scalars['DateTime'];
    /** 1 = 左耳, 2 = 右耳, 3 = 雙耳 */
    earType?: Maybe<Scalars['Int']>;
    id: Scalars['ID'];
    /** 是否為延保 */
    isExtendedWarranty?: Maybe<Scalars['Boolean']>;
    /** 是否為訂閱制主機 */
    isSubscriptionMainProduct?: Maybe<Scalars['Boolean']>;
    /** 是否保固 */
    isWarranty?: Maybe<Scalars['Boolean']>;
    materialId: Scalars['ID'];
    updatedAt: Scalars['DateTime'];
    /** 保固月份數 */
    warrantyMonths?: Maybe<Scalars['Int']>;
};

export type IMaterialProperty = {
    code: Scalars['String'];
    companyId: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    name: Scalars['String'];
    regionId: Scalars['ID'];
    updatedAt: Scalars['DateTime'];
};

export type IMaterialRepairType = {
    code: Scalars['String'];
    companyId: Scalars['ID'];
    id: Scalars['ID'];
    name: Scalars['String'];
    regionId: Scalars['ID'];
};

export type IMaterialType = {
    code: Scalars['String'];
    companyId: Scalars['ID'];
    id: Scalars['ID'];
    name: Scalars['String'];
    regionId: Scalars['ID'];
};

export type IPaymentMethod = {
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    name: Scalars['String'];
    regionId: Scalars['ID'];
    updatedAt: Scalars['DateTime'];
};

export type IPermission = {
    applicationId: Scalars['ID'];
    /** 編號 */
    code: Scalars['String'];
    /** 敘述 */
    description?: Maybe<Scalars['String']>;
    groupId: Scalars['ID'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
};

export type IPermissionApplication = {
    /** 編號 */
    code: Scalars['String'];
    /** 敘述 */
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
};

export type IPermissionGroup = {
    /** 編號 */
    code: Scalars['String'];
    /** 敘述 */
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
};

/** 商品 */
export type IProduct = {
    /** 廠牌 */
    brand: Scalars['String'];
    /** 編號 */
    code: Scalars['String'];
    id: Scalars['ID'];
};

export type IProvince = {
    code?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    regionId: Scalars['ID'];
    updatedAt: Scalars['DateTime'];
    viewOrder: Scalars['Int'];
};

export type IRegion = {
    code?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 當地幣別 */
    localCurrencyId: Scalars['ID'];
    name: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type IRentProduct = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 序號 */
    sn?: Maybe<Scalars['String']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IRole = {
    /** 允許的公司ids */
    allowCompanyIds: Array<Scalars['Int']>;
    /** 允許的商品團隊ids */
    allowProductTeamIds: Array<Scalars['Int']>;
    /** 允許的區域ids */
    allowRegionIds: Array<Scalars['Int']>;
    /** 允許的業務團隊組織ids */
    allowSalesTeamGroupIds: Array<Scalars['Int']>;
    /** 允許的業務團隊ids */
    allowSalesTeamIds: Array<Scalars['Int']>;
    /** 允許的使用者ids */
    allowUserIds: Array<Scalars['Int']>;
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    /** 是否允許所有業務團隊(中国眼科) */
    isAllowAllChnEyeSalesTeam: Scalars['Boolean'];
    /** 是否允許所有公司(cn) */
    isAllowAllCnCompanies: Scalars['Boolean'];
    /** 是否允許所有區域 */
    isAllowAllRegions: Scalars['Boolean'];
    /** 是否允許所有業務團隊組織 */
    isAllowAllSalesTeamGroups: Scalars['Boolean'];
    /** 是否允許所有公司(tw) */
    isAllowAllTwCompanies: Scalars['Boolean'];
    /** 是否允許所有業務團隊(電子耳) */
    isAllowAllTwnClSalesTeam: Scalars['Boolean'];
    /** 是否允許所有業務團隊(科明／明睿) */
    isAllowAllTwnEcAndMrSalesTeam: Scalars['Boolean'];
    /** 是否允許所有業務團隊(臺灣眼科) */
    isAllowAllTwnEyeSalesTeam: Scalars['Boolean'];
    /** 是否只允許自己的資料 */
    isOnlyOwnUser: Scalars['Boolean'];
    /** 是否為系統管理者 */
    isSystemAdmin: Scalars['Boolean'];
    /** 名稱 */
    name: Scalars['String'];
};

export type ISalesProductOverallForecastItem = {
    /** 金額 */
    amount: Scalars['Float'];
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 月份 */
    month: Scalars['Int'];
    /** 單頭 */
    overallForecastId: Scalars['ID'];
    /** 數量 */
    qty: Scalars['Float'];
    /** 單價 */
    unitPrice: Scalars['Float'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
};

export type ISalesProductOverallGoalItem = {
    /** 金額 */
    amount: Scalars['Float'];
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 月份 */
    month: Scalars['Int'];
    /** 單頭 */
    overallGoalId: Scalars['ID'];
    /** 數量 */
    qty: Scalars['Float'];
    /** 單價 */
    unitPrice: Scalars['Float'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
};

export type ISalesProductType = {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 群組 */
    groupId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type ISalesProductTypeGroup = {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type ISalesProductUserForecastItem = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 月份 */
    month: Scalars['Int'];
    /** 月初預測數量 */
    qtyEarlyMonth: Scalars['Int'];
    /** 月底預測數量 */
    qtyLateMonth: Scalars['Int'];
    /** Quota */
    quota: Scalars['Int'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 單頭 */
    userForecastId: Scalars['ID'];
};

export type ISalesTeam = {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 組織 */
    groupId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    /** 主管 */
    managerId?: Maybe<Scalars['ID']>;
    /** 名稱 */
    name: Scalars['String'];
    /** 上層團隊 */
    parentId?: Maybe<Scalars['ID']>;
    /** 需要為客戶指定主要負責業務 */
    requiredForPrimaryCustomer: Scalars['Boolean'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type ISalesTeamGroup = {
    /** 編號 */
    code?: Maybe<EnumSalesTeamGroupCode>;
    /** 公司 */
    companyId?: Maybe<Scalars['ID']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 主管 */
    directorId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 區域 */
    regionId?: Maybe<Scalars['ID']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type ISalesTeamUnit = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊 */
    salesTeamId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 負責業務 */
    userId?: Maybe<Scalars['ID']>;
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type ISalesType = {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type ISalesUserForecastItem = {
    /** 月初預測金額 */
    amountEarlyMonth: Scalars['Int'];
    /** 月底預測金額 */
    amountLateMonth: Scalars['Int'];
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 月份 */
    month: Scalars['Int'];
    /** Quota */
    quota: Scalars['Int'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 單頭 */
    userForecastId: Scalars['ID'];
};

export type IShippingMethod = {
    code: Scalars['String'];
    companyId: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    isActive: Scalars['Boolean'];
    name: Scalars['String'];
    regionId: Scalars['ID'];
    updatedAt: Scalars['DateTime'];
    viewOrder?: Maybe<Scalars['Int']>;
};

export type ITaxRate = {
    createdAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    rate: Scalars['Float'];
    regionId: Scalars['ID'];
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IUser = {
    active?: Maybe<Scalars['String']>;
    /** 驗證方式(1. LDAP, 2. password) */
    authType: Scalars['Int'];
    code?: Maybe<Scalars['String']>;
    companyId?: Maybe<Scalars['ID']>;
    createdAt: Scalars['DateTime'];
    departmentId?: Maybe<Scalars['ID']>;
    email?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    /** 在職狀態 */
    isActive: Scalars['Boolean'];
    /** 聽力師 */
    isAudiologist: Scalars['Boolean'];
    /** 助聽器工程師 */
    isHearingAidEngineer: Scalars['Boolean'];
    isLocked: Scalars['Boolean'];
    /** 外包人員 */
    isOutsourcing: Scalars['Boolean'];
    /** 使用系統，最後選擇的門市 */
    lastSelectStoreId?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    regionId: Scalars['ID'];
    storeId?: Maybe<Scalars['ID']>;
    updatedAt: Scalars['DateTime'];
};

/** 料件 */
export type IvMaterial = {
    /** 料號 */
    code: Scalars['String'];
    /** 描述 */
    description?: Maybe<Scalars['String']>;
    /** 描述（第二行） */
    description2?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
};

/** 訂單 */
export type IvOrder = {
    /** 報價單號 (合約審查號) */
    approvalCode: Scalars['String'];
    /** 帳款（開票）客戶地址 */
    billToCustomerAddress?: Maybe<Scalars['String']>;
    /** 帳款（開票）客戶地址（第二行） */
    billToCustomerAddress2?: Maybe<Scalars['String']>;
    /** 帳款（開票）客戶編號 */
    billToCustomerCode: Scalars['String'];
    /** 帳款（開票）客戶名稱 */
    billToCustomerName?: Maybe<Scalars['String']>;
    /** 帳款（開票）客戶名稱（第二行） */
    billToCustomerName2?: Maybe<Scalars['String']>;
    /** 訂單單號 */
    code: Scalars['String'];
    /** 部門代碼 */
    deptCode: Scalars['String'];
    id: Scalars['ID'];
    /** 訂單日期 */
    orderDate: Scalars['DateTime'];
    /** 銷售日期 */
    postingDate: Scalars['DateTime'];
    /** 銷售備註 */
    postingDescription?: Maybe<Scalars['String']>;
    /** 原因代碼 */
    reasonCode: Scalars['String'];
    /** 出貨客戶地址 */
    sellToCustomerAddress?: Maybe<Scalars['String']>;
    /** 出貨客戶地址（第二行） */
    sellToCustomerAddress2?: Maybe<Scalars['String']>;
    /** 出貨客戶編號 */
    sellToCustomerCode: Scalars['String'];
    /** 出貨客戶名稱 */
    sellToCustomerName?: Maybe<Scalars['String']>;
    /** 出貨客戶名稱（第二行） */
    sellToCustomerName2?: Maybe<Scalars['String']>;
    /** 員工代碼 */
    userCode: Scalars['String'];
};

/** 訂單詳細 */
export type IvOrderDetail = {
    /** 總價（未稅） */
    amount: Scalars['Float'];
    /** 備註 */
    description?: Maybe<Scalars['String']>;
    /** 備註（第二行） */
    description2?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    /** 商品料號 */
    materialCode: Scalars['String'];
    /** 出貨單號 */
    orderCode: Scalars['String'];
    /** 數量 */
    qty: Scalars['Float'];
    /** 次序 */
    seq: Scalars['Int'];
    /** 出貨日期 */
    shipmentDate: Scalars['DateTime'];
    /** 單位代碼 */
    unitCode?: Maybe<Scalars['String']>;
    /** 單位名稱 */
    unitName?: Maybe<Scalars['String']>;
    /** 單價 */
    unitPrice: Scalars['Float'];
};

/** 銷售單及銷退單 */
export type IvSalesInvoiceAndReturn = {
    /** 報價單號 (合約審查號) */
    approvalCode: Scalars['String'];
    /** 帳款（開票）客戶地址 */
    billToCustomerAddress?: Maybe<Scalars['String']>;
    /** 帳款（開票）客戶地址（第二行） */
    billToCustomerAddress2?: Maybe<Scalars['String']>;
    /** 帳款（開票）客戶編號 */
    billToCustomerCode: Scalars['String'];
    /** 帳款（開票）客戶名稱 */
    billToCustomerName?: Maybe<Scalars['String']>;
    /** 帳款（開票）客戶名稱（第二行） */
    billToCustomerName2?: Maybe<Scalars['String']>;
    /** 單據編號 */
    code: Scalars['String'];
    /** 部門代碼 */
    deptCode: Scalars['String'];
    id: Scalars['ID'];
    /** 訂單單號 */
    orderCode: Scalars['String'];
    /** 訂單日期 */
    orderDate: Scalars['DateTime'];
    /** 銷售日期 */
    postingDate: Scalars['DateTime'];
    /** 銷售備註 */
    postingDescription?: Maybe<Scalars['String']>;
    /** 原因代碼 */
    reasonCode: Scalars['String'];
    /** 出貨客戶地址 */
    sellToCustomerAddress?: Maybe<Scalars['String']>;
    /** 出貨客戶地址（第二行） */
    sellToCustomerAddress2?: Maybe<Scalars['String']>;
    /** 出貨客戶編號 */
    sellToCustomerCode: Scalars['String'];
    /** 出貨客戶名稱 */
    sellToCustomerName?: Maybe<Scalars['String']>;
    /** 出貨客戶名稱（第二行） */
    sellToCustomerName2?: Maybe<Scalars['String']>;
    /** 單據類型 */
    type: EnumSalesInvoiceType;
    /** 員工代碼 */
    userCode: Scalars['String'];
};

/** 銷售單詳細及銷退單詳細 */
export type IvSalesInvoiceAndReturnDetail = {
    /** 總價（未稅） */
    amount: Scalars['Float'];
    /** 備註 */
    description?: Maybe<Scalars['String']>;
    /** 備註（第二行） */
    description2?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    /** 單據單號 */
    invoiceCode: Scalars['String'];
    /** 商品料號 */
    materialCode: Scalars['String'];
    /** 數量 */
    qty: Scalars['Float'];
    /** 次序 */
    seq: Scalars['Int'];
    /** 出貨日期 */
    shipmentDate: Scalars['DateTime'];
    /** 單位代碼 */
    unitCode?: Maybe<Scalars['String']>;
    /** 單位名稱 */
    unitName?: Maybe<Scalars['String']>;
    /** 單價 */
    unitPrice: Scalars['Float'];
};

export type IVisit = {
    /** 行為內容 */
    actionContent?: Maybe<Scalars['String']>;
    /** 行為 */
    actionId?: Maybe<Scalars['ID']>;
    /** 協訪主管 */
    assistedVisitSupervisorId?: Maybe<Scalars['ID']>;
    /** 商機 */
    businessId?: Maybe<Scalars['ID']>;
    /** 內容 */
    content?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 客戶 */
    customerId?: Maybe<Scalars['ID']>;
    /** 預估刀量 */
    estimatedSurgeryCount?: Maybe<Scalars['String']>;
    /** 是否主管協訪 */
    hasAssistedVisitSupervisor: Scalars['Boolean'];
    id: Scalars['ID'];
    /** 主要負責業務（棄用；改用 sales_team_unit_id ） */
    primaryUserId?: Maybe<Scalars['ID']>;
    /** 優先順序 */
    priorityOrder?: Maybe<Scalars['Int']>;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 負責業務團隊 */
    salesTeamId?: Maybe<Scalars['ID']>;
    /** 業務團隊單位 */
    salesTeamUnitId?: Maybe<Scalars['ID']>;
    /** 狀態 */
    status: EnumVisitStatus;
    /** 實際刀量 */
    surgeryCount?: Maybe<Scalars['String']>;
    /** 標題 */
    title?: Maybe<Scalars['String']>;
    /** 類型 */
    typeId?: Maybe<Scalars['ID']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 拜訪地址 */
    visitAddress?: Maybe<Scalars['String']>;
    /** 拜訪日期 */
    visitDate: Scalars['Date'];
};

export type IVisitAction = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IVisitCheckIn = {
    /** 內容 */
    content?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    /** WGS84 緯度 */
    lat: Scalars['Float'];
    /** WGS84 經度 */
    lng: Scalars['Float'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 拜訪 */
    visitId: Scalars['ID'];
};

export type IVisitGoal = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IVisitMemo = {
    /** 內容 */
    content?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    /** 標題 */
    title?: Maybe<Scalars['String']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 拜訪 */
    visitId: Scalars['ID'];
};

export type IVisitProperty = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 類型 */
    typeId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IVisitPropertyType = {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IVisitTimePeriod = {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IVisitType = {
    /** 編號 */
    code?: Maybe<EnumVisitTypeCode>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type IVisitsToMentionProduct = {
    id: Scalars['ID'];
    /** 涉及商品 */
    mentionProductId: Scalars['ID'];
    /** 拜訪 */
    visitId: Scalars['ID'];
};

export type IVisitsToRentFixedAsset = {
    id: Scalars['ID'];
    /** 借用固定資產 */
    rentFixedAssetId: Scalars['ID'];
    /** 拜訪 */
    visitId: Scalars['ID'];
};

export type IVisitsToRentProduct = {
    id: Scalars['ID'];
    /** 租借商品 */
    rentProductId: Scalars['ID'];
    /** 拜訪 */
    visitId: Scalars['ID'];
};

export type Material = IMaterial & {
    /** 廠牌 */
    brand?: Maybe<Scalars['String']>;
    /** 商機商品 */
    businessProducts: Array<BusinessProduct>;
    /** 編號 */
    code: Scalars['String'];
    /** 顏色 */
    color?: Maybe<Scalars['String']>;
    /** 公司 */
    company: Company;
    /** 公司 */
    companyId: Scalars['ID'];
    /** 成本幣別 */
    costCurrency?: Maybe<Currency>;
    /** 成本幣別 */
    costCurrencyId?: Maybe<Scalars['ID']>;
    /** 成本金額 */
    costPrice?: Maybe<Scalars['Float']>;
    /** 庫存單位 */
    countingUnitId?: Maybe<Scalars['ID']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    /** 是否可用（棄用；改用 status） */
    isActive: Scalars['Boolean'];
    /** 維修類別 */
    materialRepairTypeId?: Maybe<Scalars['ID']>;
    /** 類別 */
    materialTypeId?: Maybe<Scalars['ID']>;
    /** 型號 */
    model?: Maybe<Scalars['String']>;
    /** 名稱 */
    name: Scalars['String'];
    /** 名稱（中文） */
    name2?: Maybe<Scalars['String']>;
    /** 區域 */
    region: Region;
    /** 區域 */
    regionId: Scalars['ID'];
    repairType: MaterialRepairType;
    /** 銷售幣別 */
    saleCurrency?: Maybe<Currency>;
    /** 銷售幣別 */
    saleCurrencyId?: Maybe<Scalars['ID']>;
    /** 銷售金額 */
    salePrice?: Maybe<Scalars['Float']>;
    /** 規格／機型 */
    spec?: Maybe<Scalars['String']>;
    /** 狀態（0 = 停用, 1 = 啟用） */
    status: EnumMaterialStatus;
    /** 供應商 */
    supplierId?: Maybe<Scalars['ID']>;
    /** 同步代碼 */
    syncCode?: Maybe<Scalars['String']>;
    /** 同步來源 */
    syncSourceId?: Maybe<Scalars['ID']>;
    /** 稅率 */
    taxRate: Scalars['Float'];
    /** 料號類別 */
    type: MaterialType;
    /** 庫存單位（棄用；改用 counting_unit_id） */
    unit?: Maybe<Scalars['String']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
};

export type MaterialExtraProperty = IMaterialExtraProperty & {
    createdAt: Scalars['DateTime'];
    /** 1 = 左耳, 2 = 右耳, 3 = 雙耳 */
    earType?: Maybe<Scalars['Int']>;
    id: Scalars['ID'];
    /** 是否為延保 */
    isExtendedWarranty?: Maybe<Scalars['Boolean']>;
    /** 是否為訂閱制主機 */
    isSubscriptionMainProduct?: Maybe<Scalars['Boolean']>;
    /** 是否保固 */
    isWarranty?: Maybe<Scalars['Boolean']>;
    /** 料號 */
    material: Material;
    materialId: Scalars['ID'];
    updatedAt: Scalars['DateTime'];
    /** 保固月份數 */
    warrantyMonths?: Maybe<Scalars['Int']>;
};

export type MaterialExtraPropertyFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 料號 */
    materialId?: InputMaybe<Scalars['ID']>;
};

export type MaterialFilterInput = {
    /** 公司 */
    companyId?: InputMaybe<Scalars['ID']>;
    /** 有銷售幣別的料件 */
    hasSaleCurrency?: InputMaybe<Scalars['Boolean']>;
    /** 有銷售金額的料件 */
    hasSalePrice?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 區域 */
    regionId?: InputMaybe<Scalars['ID']>;
};

export type MaterialProperty = IMaterialProperty & {
    code: Scalars['String'];
    /** 公司 */
    company: Company;
    companyId: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    name: Scalars['String'];
    /** 區域 */
    region: Region;
    regionId: Scalars['ID'];
    updatedAt: Scalars['DateTime'];
    values: Array<Scalars['String']>;
};

export type MaterialPropertyFilterInput = {
    /** 公司 */
    companyId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 區域 */
    regionId?: InputMaybe<Scalars['ID']>;
};

export type MaterialRepairType = IMaterialRepairType & {
    code: Scalars['String'];
    /** 公司 */
    company: Company;
    companyId: Scalars['ID'];
    id: Scalars['ID'];
    name: Scalars['String'];
    /** 區域 */
    region: Region;
    regionId: Scalars['ID'];
};

export type MaterialRepairTypeFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type MaterialType = IMaterialType & {
    code: Scalars['String'];
    /** 公司 */
    company: Company;
    companyId: Scalars['ID'];
    id: Scalars['ID'];
    name: Scalars['String'];
    /** 區域 */
    region: Region;
    regionId: Scalars['ID'];
};

export type MaterialTypeFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type Me = {
    /** 可選取的業務團隊組織 */
    availableSalesTeamGroups: Array<SalesTeamGroup>;
    code?: Maybe<Scalars['String']>;
    /** 所屬公司 */
    company?: Maybe<Company>;
    /** 預設選取業務團隊組織 */
    defaultAvailableSalesTeamGroup?: Maybe<SalesTeamGroup>;
    /**
     * 預設選取業務團隊組織
     * @deprecated 要留給「所屬xxxx」命名規則用途，原功能由 defaultAvailableSalesTeamGroup 接手。等前端確認調整完成再移除原功能。
     */
    defaultSalesTeamGroup?: Maybe<SalesTeamGroup>;
    /** 所屬部門 */
    dept?: Maybe<Department>;
    email?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    /** 所屬區域 */
    region?: Maybe<Region>;
    /**
     * 可選取的業務團隊組織
     * @deprecated 要留給「所屬xxxx」命名規則用途，原功能由 availableSalesTeamGroups 接手。等前端確認調整完成再移除原功能。
     */
    salesTeamGroups: Array<SalesTeamGroup>;
    /** 業務團隊位置 */
    salesTeamUnit?: Maybe<SalesTeamUnit>;
    /** 所屬業務團隊 */
    salesTeams: Array<SalesTeam>;
    /** 擁有權限 */
    userPermission: UserPermission;
};

export type Mutation = {
    bulkCreateCustomerAttachment: Array<CustomerAttachment>;
    bulkUpdateSalesPerformanceSalesProductOverallView: SalesPerformanceSalesProductOverallViewBulkUpdateResult;
    bulkUpdateSalesPerformanceSalesProductUserForecastView: Array<SalesPerformanceSalesProductUserForecastItem>;
    bulkUpdateSalesPerformanceSalesUserForecastView: Array<SalesPerformanceSalesUserForecastItem>;
    bulkUpdateVisit: Array<Visit>;
    createBusiness: Business;
    createBusinessMemo: BusinessMemo;
    createBusinessOpportunity: BusinessOpportunity;
    createBusinessProduct: BusinessProduct;
    createBusinessProperty: BusinessProperty;
    createBusinessPropertyType: BusinessPropertyType;
    createBusinessStatus: BusinessStatus;
    createBusinessType: BusinessType;
    createCompetitor: Competitor;
    createContactPerson: ContactPerson;
    createCustomer: Customer;
    createCustomerArea: CustomerArea;
    createCustomerAttachment: CustomerAttachment;
    createCustomerCertificate: CustomerCertificate;
    createCustomerCertificateAttachment: CustomerCertificateAttachment;
    createCustomerCertificateType: CustomerCertificateType;
    createCustomerGroup: CustomerGroup;
    createCustomerProperty: CustomerProperty;
    createCustomerPropertyType: CustomerPropertyType;
    /** @deprecated 改從 createCustomer mutation 處理此情境 */
    createCustomerToContactPeople: Customer;
    createCustomerType: CustomerType;
    createEyeQuotationOrder: EyeQuotationOrder;
    createEyeServiceOrder: EyeServiceOrder;
    createEyeServiceOrderWorkDiary: EyeServiceOrderWorkDiary;
    createVisit: Visit;
    createVisitAction: VisitAction;
    createVisitCheckIn: VisitCheckIn;
    createVisitGoal: VisitGoal;
    createVisitMemo: VisitMemo;
    createVisitProperty: VisitProperty;
    createVisitPropertyType: VisitPropertyType;
    createVisitTimePeriod: VisitTimePeriod;
    createVisitType: VisitType;
    deleteBusiness: Scalars['Boolean'];
    deleteBusinessMemo: Scalars['Boolean'];
    deleteBusinessOpportunity: Scalars['Boolean'];
    deleteBusinessProduct: Scalars['Boolean'];
    deleteBusinessProperty: Scalars['Boolean'];
    deleteBusinessPropertyType: Scalars['Boolean'];
    deleteBusinessStatus: Scalars['Boolean'];
    deleteBusinessType: Scalars['Boolean'];
    deleteCompetitor: Scalars['Boolean'];
    deleteContactPerson: Scalars['Boolean'];
    deleteCustomer: Scalars['Boolean'];
    deleteCustomerArea: Scalars['Boolean'];
    deleteCustomerAttachment: Scalars['Boolean'];
    deleteCustomerCertificate: Scalars['Boolean'];
    deleteCustomerCertificateAttachment: Scalars['Boolean'];
    deleteCustomerCertificateType: Scalars['Boolean'];
    deleteCustomerGroup: Scalars['Boolean'];
    deleteCustomerProperty: Scalars['Boolean'];
    deleteCustomerPropertyType: Scalars['Boolean'];
    /** @deprecated 改從 createCustomer mutation 處理此情境 */
    deleteCustomerToContactPeople: Customer;
    deleteCustomerType: Scalars['Boolean'];
    deleteVisit: Scalars['Boolean'];
    deleteVisitAction: Scalars['Boolean'];
    deleteVisitGoal: Scalars['Boolean'];
    deleteVisitMemo: Scalars['Boolean'];
    deleteVisitProperty: Scalars['Boolean'];
    deleteVisitPropertyType: Scalars['Boolean'];
    deleteVisitTimePeriod: Scalars['Boolean'];
    deleteVisitType: Scalars['Boolean'];
    loginBySSO: Auth;
    /** 申請報價單合約用印 */
    requestEyeQuotationOrderOfficialSeal: Scalars['Boolean'];
    /** 申請固資租借展延 */
    requestEyeServiceOrderExtension: Scalars['Boolean'];
    sayHelloByGrpc: PlaygroundSayHelloByGrpc;
    sayHelloByWebSocket: PlaygroundSayHelloByWebSocket;
    updateBusiness: Business;
    updateBusinessMemo: BusinessMemo;
    updateBusinessOpportunity: BusinessOpportunity;
    updateBusinessProduct: BusinessProduct;
    updateBusinessProperty: BusinessProperty;
    updateBusinessPropertyType: BusinessPropertyType;
    updateBusinessStatus: BusinessStatus;
    updateBusinessType: BusinessType;
    updateCompetitor: Competitor;
    updateContactPerson: ContactPerson;
    updateCustomer: Customer;
    updateCustomerArea: CustomerArea;
    updateCustomerAttachment: CustomerAttachment;
    updateCustomerCertificate: CustomerCertificate;
    updateCustomerCertificateAttachment: CustomerCertificateAttachment;
    updateCustomerCertificateType: CustomerCertificateType;
    updateCustomerGroup: CustomerGroup;
    updateCustomerProperty: CustomerProperty;
    updateCustomerPropertyType: CustomerPropertyType;
    updateCustomerType: CustomerType;
    updateEyeQuotationOrder: EyeQuotationOrder;
    updateEyeServiceOrder: EyeServiceOrder;
    updateEyeServiceOrderWorkDiary: EyeServiceOrderWorkDiary;
    updateVisit: Visit;
    updateVisitAction: VisitAction;
    updateVisitCheckIn: VisitCheckIn;
    updateVisitGoal: VisitGoal;
    updateVisitMemo: VisitMemo;
    updateVisitProperty: VisitProperty;
    updateVisitPropertyType: VisitPropertyType;
    updateVisitTimePeriod: VisitTimePeriod;
    updateVisitType: VisitType;
};

export type MutationBulkCreateCustomerAttachmentArgs = {
    input: CustomerAttachmentBulkCreateInput;
};

export type MutationBulkUpdateSalesPerformanceSalesProductOverallViewArgs = {
    input: SalesPerformanceSalesProductOverallViewBulkUpdateInput;
};

export type MutationBulkUpdateSalesPerformanceSalesProductUserForecastViewArgs = {
    input: SalesPerformanceSalesProductUserForecastViewBulkUpdateInput;
};

export type MutationBulkUpdateSalesPerformanceSalesUserForecastViewArgs = {
    input: SalesPerformanceSalesUserForecastViewBulkUpdateInput;
};

export type MutationBulkUpdateVisitArgs = {
    input: VisitBulkUpdateInput;
};

export type MutationCreateBusinessArgs = {
    input: BusinessCreateInput;
};

export type MutationCreateBusinessMemoArgs = {
    input: BusinessMemoCreateInput;
};

export type MutationCreateBusinessOpportunityArgs = {
    input: BusinessOpportunityCreateInput;
};

export type MutationCreateBusinessProductArgs = {
    input: BusinessProductCreateInput;
};

export type MutationCreateBusinessPropertyArgs = {
    input: BusinessPropertyCreateInput;
};

export type MutationCreateBusinessPropertyTypeArgs = {
    input: BusinessPropertyTypeCreateInput;
};

export type MutationCreateBusinessStatusArgs = {
    input: BusinessStatusCreateInput;
};

export type MutationCreateBusinessTypeArgs = {
    input: BusinessTypeCreateInput;
};

export type MutationCreateCompetitorArgs = {
    input: CompetitorCreateInput;
};

export type MutationCreateContactPersonArgs = {
    input: ContactPersonCreateInput;
};

export type MutationCreateCustomerArgs = {
    input: CustomerCreateInput;
};

export type MutationCreateCustomerAreaArgs = {
    input: CustomerAreaCreateInput;
};

export type MutationCreateCustomerAttachmentArgs = {
    input: CustomerAttachmentCreateInput;
};

export type MutationCreateCustomerCertificateArgs = {
    input: CustomerCertificateCreateInput;
};

export type MutationCreateCustomerCertificateAttachmentArgs = {
    input: CustomerCertificateAttachmentCreateInput;
};

export type MutationCreateCustomerCertificateTypeArgs = {
    input: CustomerCertificateTypeCreateInput;
};

export type MutationCreateCustomerGroupArgs = {
    input: CustomerGroupCreateInput;
};

export type MutationCreateCustomerPropertyArgs = {
    input: CustomerPropertyCreateInput;
};

export type MutationCreateCustomerPropertyTypeArgs = {
    input: CustomerPropertyTypeCreateInput;
};

export type MutationCreateCustomerToContactPeopleArgs = {
    input: CreateCustomerToContactPeopleInput;
};

export type MutationCreateCustomerTypeArgs = {
    input: CustomerTypeCreateInput;
};

export type MutationCreateEyeQuotationOrderArgs = {
    input: EyeQuotationOrderCreateInput;
};

export type MutationCreateEyeServiceOrderArgs = {
    input: EyeServiceOrderCreateInput;
};

export type MutationCreateEyeServiceOrderWorkDiaryArgs = {
    input: EyeServiceOrderWorkDiaryCreateInput;
};

export type MutationCreateVisitArgs = {
    input: VisitCreateInput;
};

export type MutationCreateVisitActionArgs = {
    input: VisitActionCreateInput;
};

export type MutationCreateVisitCheckInArgs = {
    input: VisitCheckInCreateInput;
};

export type MutationCreateVisitGoalArgs = {
    input: VisitGoalCreateInput;
};

export type MutationCreateVisitMemoArgs = {
    input: VisitMemoCreateInput;
};

export type MutationCreateVisitPropertyArgs = {
    input: VisitPropertyCreateInput;
};

export type MutationCreateVisitPropertyTypeArgs = {
    input: VisitPropertyTypeCreateInput;
};

export type MutationCreateVisitTimePeriodArgs = {
    input: VisitTimePeriodCreateInput;
};

export type MutationCreateVisitTypeArgs = {
    input: VisitTypeCreateInput;
};

export type MutationDeleteBusinessArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteBusinessMemoArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteBusinessOpportunityArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteBusinessProductArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteBusinessPropertyArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteBusinessPropertyTypeArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteBusinessStatusArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteBusinessTypeArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteCompetitorArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteContactPersonArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteCustomerArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteCustomerAreaArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteCustomerAttachmentArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteCustomerCertificateArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteCustomerCertificateAttachmentArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteCustomerCertificateTypeArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteCustomerGroupArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteCustomerPropertyArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteCustomerPropertyTypeArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteCustomerToContactPeopleArgs = {
    input: DeleteCustomerToContactPeopleInput;
};

export type MutationDeleteCustomerTypeArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteVisitArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteVisitActionArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteVisitGoalArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteVisitMemoArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteVisitPropertyArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteVisitPropertyTypeArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteVisitTimePeriodArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteVisitTypeArgs = {
    id: Scalars['ID'];
};

export type MutationLoginBySsoArgs = {
    SSOToken: Scalars['String'];
};

export type MutationRequestEyeQuotationOrderOfficialSealArgs = {
    input: RequestEyeQuotationOrderOfficialSealInput;
};

export type MutationRequestEyeServiceOrderExtensionArgs = {
    input: RequestEyeServiceOrderExtensionInput;
};

export type MutationSayHelloByGrpcArgs = {
    input: PlaygroundSayHelloByGrpcInput;
};

export type MutationSayHelloByWebSocketArgs = {
    input: PlaygroundSayHelloByWebSocketInput;
};

export type MutationUpdateBusinessArgs = {
    input: BusinessUpdateInput;
};

export type MutationUpdateBusinessMemoArgs = {
    input: BusinessMemoUpdateInput;
};

export type MutationUpdateBusinessOpportunityArgs = {
    input: BusinessOpportunityUpdateInput;
};

export type MutationUpdateBusinessProductArgs = {
    input: BusinessProductUpdateInput;
};

export type MutationUpdateBusinessPropertyArgs = {
    input: BusinessPropertyUpdateInput;
};

export type MutationUpdateBusinessPropertyTypeArgs = {
    input: BusinessPropertyTypeUpdateInput;
};

export type MutationUpdateBusinessStatusArgs = {
    input: BusinessStatusUpdateInput;
};

export type MutationUpdateBusinessTypeArgs = {
    input: BusinessTypeUpdateInput;
};

export type MutationUpdateCompetitorArgs = {
    input: CompetitorUpdateInput;
};

export type MutationUpdateContactPersonArgs = {
    input: ContactPersonUpdateInput;
};

export type MutationUpdateCustomerArgs = {
    input: CustomerUpdateInput;
};

export type MutationUpdateCustomerAreaArgs = {
    input: CustomerAreaUpdateInput;
};

export type MutationUpdateCustomerAttachmentArgs = {
    input: CustomerAttachmentUpdateInput;
};

export type MutationUpdateCustomerCertificateArgs = {
    input: CustomerCertificateUpdateInput;
};

export type MutationUpdateCustomerCertificateAttachmentArgs = {
    input: CustomerCertificateAttachmentUpdateInput;
};

export type MutationUpdateCustomerCertificateTypeArgs = {
    input: CustomerCertificateTypeUpdateInput;
};

export type MutationUpdateCustomerGroupArgs = {
    input: CustomerGroupUpdateInput;
};

export type MutationUpdateCustomerPropertyArgs = {
    input: CustomerPropertyUpdateInput;
};

export type MutationUpdateCustomerPropertyTypeArgs = {
    input: CustomerPropertyTypeUpdateInput;
};

export type MutationUpdateCustomerTypeArgs = {
    input: CustomerTypeUpdateInput;
};

export type MutationUpdateEyeQuotationOrderArgs = {
    input: EyeQuotationOrderUpdateInput;
};

export type MutationUpdateEyeServiceOrderArgs = {
    input: EyeServiceOrderUpdateInput;
};

export type MutationUpdateEyeServiceOrderWorkDiaryArgs = {
    input: EyeServiceOrderWorkDiaryUpdateInput;
};

export type MutationUpdateVisitArgs = {
    input: VisitUpdateInput;
};

export type MutationUpdateVisitActionArgs = {
    input: VisitActionUpdateInput;
};

export type MutationUpdateVisitCheckInArgs = {
    input: VisitCheckInUpdateInput;
};

export type MutationUpdateVisitGoalArgs = {
    input: VisitGoalUpdateInput;
};

export type MutationUpdateVisitMemoArgs = {
    input: VisitMemoUpdateInput;
};

export type MutationUpdateVisitPropertyArgs = {
    input: VisitPropertyUpdateInput;
};

export type MutationUpdateVisitPropertyTypeArgs = {
    input: VisitPropertyTypeUpdateInput;
};

export type MutationUpdateVisitTimePeriodArgs = {
    input: VisitTimePeriodUpdateInput;
};

export type MutationUpdateVisitTypeArgs = {
    input: VisitTypeUpdateInput;
};

export type NavMaterial = IvMaterial & {
    /** 料號 */
    code: Scalars['String'];
    /** 描述 */
    description?: Maybe<Scalars['String']>;
    /** 描述（第二行） */
    description2?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
};

export type NavMaterialFilterInput = {
    /** 料號 */
    code?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** NAV 商品 */
    navProductId?: InputMaybe<Scalars['ID']>;
};

export type NavOrder = IvOrder & {
    /** 訂單金額 */
    amount?: Maybe<Scalars['Float']>;
    /** 報價單號 (合約審查號) */
    approvalCode: Scalars['String'];
    /** 帳款（開票）客戶 */
    billToCustomer?: Maybe<Customer>;
    /** 帳款（開票）客戶地址 */
    billToCustomerAddress?: Maybe<Scalars['String']>;
    /** 帳款（開票）客戶地址（第二行） */
    billToCustomerAddress2?: Maybe<Scalars['String']>;
    /** 帳款（開票）客戶編號 */
    billToCustomerCode: Scalars['String'];
    /** 帳款（開票）客戶名稱 */
    billToCustomerName?: Maybe<Scalars['String']>;
    /** 帳款（開票）客戶名稱（第二行） */
    billToCustomerName2?: Maybe<Scalars['String']>;
    /** 商機 */
    businesses: Array<Business>;
    /** 訂單單號 */
    code: Scalars['String'];
    /** 負責部門 */
    dept?: Maybe<Department>;
    /** 部門代碼 */
    deptCode: Scalars['String'];
    id: Scalars['ID'];
    /** 詳細 */
    navOrderDetails: Array<NavOrderDetail>;
    /** 訂單日期 */
    orderDate: Scalars['DateTime'];
    /** 銷售日期 */
    postingDate: Scalars['DateTime'];
    /** 銷售備註 */
    postingDescription?: Maybe<Scalars['String']>;
    /** 原因代碼 */
    reasonCode: Scalars['String'];
    /** 出貨客戶 */
    sellToCustomer?: Maybe<Customer>;
    /** 出貨客戶地址 */
    sellToCustomerAddress?: Maybe<Scalars['String']>;
    /** 出貨客戶地址（第二行） */
    sellToCustomerAddress2?: Maybe<Scalars['String']>;
    /** 出貨客戶編號 */
    sellToCustomerCode: Scalars['String'];
    /** 出貨客戶名稱 */
    sellToCustomerName?: Maybe<Scalars['String']>;
    /** 出貨客戶名稱（第二行） */
    sellToCustomerName2?: Maybe<Scalars['String']>;
    /** 出貨已結金額 */
    shipInvoicedAmount?: Maybe<Scalars['Float']>;
    /** 負責業務 */
    user?: Maybe<User>;
    /** 員工代碼 */
    userCode: Scalars['String'];
};

export type NavOrderDetail = IvOrderDetail & {
    /** 總價（未稅） */
    amount: Scalars['Float'];
    /** 備註 */
    description?: Maybe<Scalars['String']>;
    /** 備註（第二行） */
    description2?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    /** 商品料號 */
    materialCode: Scalars['String'];
    /** 商品 */
    navMaterial?: Maybe<NavMaterial>;
    /** 訂單 */
    navOrder?: Maybe<NavOrder>;
    /** 出貨單號 */
    orderCode: Scalars['String'];
    /** 數量 */
    qty: Scalars['Float'];
    /** 次序 */
    seq: Scalars['Int'];
    /** 出貨日期 */
    shipmentDate: Scalars['DateTime'];
    /** 單位代碼 */
    unitCode?: Maybe<Scalars['String']>;
    /** 單位名稱 */
    unitName?: Maybe<Scalars['String']>;
    /** 單價 */
    unitPrice: Scalars['Float'];
};

export type NavOrderDetailFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** NAV 訂單 */
    navOrderId?: InputMaybe<Scalars['ID']>;
    /** NAV 訂單 */
    orderId?: InputMaybe<Scalars['String']>;
};

export type NavOrderFilterInput = {
    /** 報價單號 (合約審查號)  */
    approvalCode?: InputMaybe<Scalars['String']>;
    /** 帳款（開票）客戶編號 */
    billToCustomerCode?: InputMaybe<Scalars['String']>;
    /** 帳款（開票）客戶 */
    billToCustomerIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 帳款（開票）客戶名稱 */
    billToCustomerName?: InputMaybe<Scalars['String']>;
    /** 帳款（開票）客戶編號 */
    billToCustomerNo?: InputMaybe<Scalars['String']>;
    /** 商機編號 */
    businessCode?: InputMaybe<Scalars['String']>;
    /** 單據編號 */
    code?: InputMaybe<Scalars['String']>;
    /** 客戶編號（同時查詢「帳款（開票）客戶」及「出貨客戶」） */
    customerCode?: InputMaybe<Scalars['String']>;
    /** 客戶（同時查詢「帳款（開票）客戶」及「出貨客戶」） */
    customerIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 客戶名稱（同時查詢「帳款（開票）客戶」及「出貨客戶」） */
    customerName?: InputMaybe<Scalars['String']>;
    /** 負責部門（複選） */
    deptIds?: InputMaybe<Array<Scalars['ID']>>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 訂單日期（起） */
    orderDate1?: InputMaybe<Scalars['String']>;
    /** 訂單日期（迄） */
    orderDate2?: InputMaybe<Scalars['String']>;
    /** 出貨客戶編號 */
    sellToCustomerCode?: InputMaybe<Scalars['String']>;
    /** 出貨客戶 */
    sellToCustomerIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 出貨客戶名稱 */
    sellToCustomerName?: InputMaybe<Scalars['String']>;
    /** 出貨客戶編號 */
    sellToCustomerNo?: InputMaybe<Scalars['String']>;
    /** 負責業務（複選） */
    userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type NavProduct = IProduct & {
    /** 廠牌 */
    brand: Scalars['String'];
    /** 編號 */
    code: Scalars['String'];
    id: Scalars['ID'];
};

export type NavProductFilterInput = {
    /** 編號 */
    code?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type NavSalesAnalysis = {
    /** 客戶統計排名 */
    customerRank: Array<NavSalesAnalysisCustomerRank>;
    /** 訂單統計 */
    orderStat: NavSalesAnalysisOrderStat;
    /** 商品統計排名 */
    productRank: Array<NavSalesAnalysisProductRank>;
    /** 業績統計 */
    salesStat: NavSalesAnalysisSalesStat;
    /** 年度訂單統計（by 業務團隊） */
    yearlyOrderStatBySalesTeam: Array<NavSalesAnalysisYearlyOrderStatBySalesTeam>;
    /** 年度訂單統計（by 業務人員） */
    yearlyOrderStatByUser: Array<NavSalesAnalysisYearlyOrderStatByUser>;
    /** 年度訂單統計（by 年份） */
    yearlyOrderStatByYear: Array<NavSalesAnalysisYearlyOrderStatByYear>;
    /** 年度銷售統計（by 業務團隊） */
    yearlySalesStatBySalesTeam: Array<NavSalesAnalysisYearlySalesStatBySalesTeam>;
    /** 年度銷售統計（by 業務人員） */
    yearlySalesStatByUser: Array<NavSalesAnalysisYearlySalesStatByUser>;
    /** 年度銷售統計（by 年份） */
    yearlySalesStatByYear: Array<NavSalesAnalysisYearlySalesStatByYear>;
};

export type NavSalesAnalysisCustomerRankArgs = {
    filters?: InputMaybe<NavSalesAnalysisSalesStatFilterInput>;
};

export type NavSalesAnalysisOrderStatArgs = {
    filters?: InputMaybe<NavSalesAnalysisSalesStatFilterInput>;
};

export type NavSalesAnalysisProductRankArgs = {
    filters?: InputMaybe<NavSalesAnalysisSalesStatFilterInput>;
};

export type NavSalesAnalysisSalesStatArgs = {
    filters?: InputMaybe<NavSalesAnalysisSalesStatFilterInput>;
};

export type NavSalesAnalysisYearlyOrderStatBySalesTeamArgs = {
    filters?: InputMaybe<NavSalesAnalysisYearlyOrderStatBySalesTeamFilterInput>;
};

export type NavSalesAnalysisYearlyOrderStatByUserArgs = {
    filters?: InputMaybe<NavSalesAnalysisYearlyOrderStatByUserFilterInput>;
};

export type NavSalesAnalysisYearlyOrderStatByYearArgs = {
    filters?: InputMaybe<NavSalesAnalysisYearlyOrderStatByYearFilterInput>;
};

export type NavSalesAnalysisYearlySalesStatBySalesTeamArgs = {
    filters?: InputMaybe<NavSalesAnalysisYearlySalesStatBySalesTeamFilterInput>;
};

export type NavSalesAnalysisYearlySalesStatByUserArgs = {
    filters?: InputMaybe<NavSalesAnalysisYearlySalesStatByUserFilterInput>;
};

export type NavSalesAnalysisYearlySalesStatByYearArgs = {
    filters?: InputMaybe<NavSalesAnalysisYearlySalesStatByYearFilterInput>;
};

export type NavSalesAnalysisCustomerRank = {
    /** 金額 */
    amount: Scalars['Float'];
    /** 數量 */
    qty: Scalars['Int'];
    /** 排名 */
    rank: Scalars['Int'];
    /** 客戶 */
    sellToCustomer?: Maybe<Customer>;
    /** 出貨客戶名稱 */
    sellToCustomerName: Scalars['String'];
    /** 出貨客戶 NAV 編號 */
    sellToCustomerNavCode: Scalars['String'];
    /** 流水號 */
    seq: Scalars['Int'];
};

export type NavSalesAnalysisCustomerRankFilterInput = {
    /** 日期（起）（訂單資料依「訂單日期」、銷貨單依「銷售日期」） */
    date1?: InputMaybe<Scalars['String']>;
    /** 日期（迄）（訂單資料依「訂單日期」、銷貨單依「銷售日期」） */
    date2?: InputMaybe<Scalars['String']>;
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type NavSalesAnalysisOrderStat = {
    /** 總體統計 */
    overall: NavSalesAnalysisOrderStat_Overall;
};

export type NavSalesAnalysisOrderStatFilterInput = {
    /** 日期（起）（訂單資料依「訂單日期」、銷貨單依「銷售日期」） */
    date1?: InputMaybe<Scalars['String']>;
    /** 日期（迄）（訂單資料依「訂單日期」、銷貨單依「銷售日期」） */
    date2?: InputMaybe<Scalars['String']>;
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type NavSalesAnalysisOrderStat_Overall = {
    /** 金額 */
    amount: Scalars['Float'];
    /** 金額目標（未設定時，為空值） */
    amountGoal?: Maybe<Scalars['Float']>;
    /** 金額目標達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（總計金額）為 0 時，百分比顯示為空值 */
    amountGoalAchievementRate?: Maybe<Scalars['Float']>;
    /** 日期（起） */
    date1: Scalars['String'];
    /** 日期（迄） */
    date2: Scalars['String'];
    /** 區間統計 */
    periods: Array<NavSalesAnalysisOrderStat_Period>;
};

export type NavSalesAnalysisOrderStat_Period = {
    /** 金額 */
    amount: Scalars['Float'];
    /** 日期（起） */
    date1: Scalars['String'];
    /** 日期（迄） */
    date2: Scalars['String'];
    /** 類型 */
    type: EnumSalesAnalysisPeriodType;
};

export type NavSalesAnalysisProductRank = {
    /** 金額 */
    amount: Scalars['Float'];
    /** 商品 */
    navProduct?: Maybe<NavProduct>;
    /** 商品 */
    navProductId: Scalars['ID'];
    /** 數量 */
    qty: Scalars['Int'];
    /** 排名 */
    rank: Scalars['Int'];
    /** 流水號 */
    seq: Scalars['Int'];
};

export type NavSalesAnalysisProductRankFilterInput = {
    /** 日期（起）（訂單資料依「訂單日期」、銷貨單依「銷售日期」） */
    date1?: InputMaybe<Scalars['String']>;
    /** 日期（迄）（訂單資料依「訂單日期」、銷貨單依「銷售日期」） */
    date2?: InputMaybe<Scalars['String']>;
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type NavSalesAnalysisSalesStat = {
    /** 細項統計 */
    details: Array<NavSalesAnalysisSalesStat_Detail>;
    /** 總體統計 */
    overall: NavSalesAnalysisSalesStat_Overall;
};

export type NavSalesAnalysisSalesStatFilterInput = {
    /** 日期（起）（訂單資料依「訂單日期」、銷貨單依「銷售日期」） */
    date1?: InputMaybe<Scalars['String']>;
    /** 日期（迄）（訂單資料依「訂單日期」、銷貨單依「銷售日期」） */
    date2?: InputMaybe<Scalars['String']>;
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type NavSalesAnalysisSalesStat_Detail = {
    /** 金額 */
    amount: Scalars['Float'];
    /** 金額流動百分比（分母 = abs(銷售額) + abs(銷退額)）（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（總計金額）為 0 時，百分比顯示為空值 */
    amountFlowPercent?: Maybe<Scalars['Float']>;
    /** 金額目標（未設定時，為空值） */
    amountGoal?: Maybe<Scalars['Float']>;
    /** 金額目標達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（總計金額）為 0 時，百分比顯示為空值 */
    amountGoalAchievementRate?: Maybe<Scalars['Float']>;
    /** 金額淨值百分比（分母 = 銷售額 + 銷退額）（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（總計金額）為 0 時，百分比顯示為空值 */
    amountNetPercent?: Maybe<Scalars['Float']>;
    /** 日期（起） */
    date1: Scalars['String'];
    /** 日期（迄） */
    date2: Scalars['String'];
    /** 區間統計 */
    periods: Array<NavSalesAnalysisSalesStat_Period>;
    /** 類型 */
    type: EnumSalesStatType;
};

export type NavSalesAnalysisSalesStat_Overall = {
    /** 金額 */
    amount: Scalars['Float'];
    /** 金額目標（未設定時，為空值） */
    amountGoal?: Maybe<Scalars['Float']>;
    /** 金額目標達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（總計金額）為 0 時，百分比顯示為空值 */
    amountGoalAchievementRate?: Maybe<Scalars['Float']>;
    /** 日期（起） */
    date1: Scalars['String'];
    /** 日期（迄） */
    date2: Scalars['String'];
    /** 區間統計 */
    periods: Array<NavSalesAnalysisSalesStat_Period>;
};

export type NavSalesAnalysisSalesStat_Period = {
    /** 金額 */
    amount: Scalars['Float'];
    /** 日期（起） */
    date1: Scalars['String'];
    /** 日期（迄） */
    date2: Scalars['String'];
    /** 類型 */
    type: EnumSalesAnalysisPeriodType;
};

export type NavSalesAnalysisYearlyOrderStatBySalesTeam = {
    /** 金額 */
    amount: Scalars['Float'];
    /** 月份統計 */
    months: Array<NavSalesAnalysisYearlyOrderStatBySalesTeam_Month>;
    /** 業務團隊 */
    salesTeam?: Maybe<SalesTeam>;
    /** 業務團隊 */
    salesTeamId: Scalars['ID'];
    /** 年份 */
    year: Scalars['Int'];
};

export type NavSalesAnalysisYearlyOrderStatBySalesTeamFilterInput = {
    /** 關鍵字（同時查詢「業務團隊名稱」欄位） */
    keyword?: InputMaybe<Scalars['String']>;
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 年份 */
    year: Scalars['Int'];
};

export type NavSalesAnalysisYearlyOrderStatBySalesTeam_Month = {
    /** 金額（如果收到空值，表示沒有訂單資料） */
    amount?: Maybe<Scalars['Float']>;
    /** 月份 */
    month: Scalars['Int'];
};

export type NavSalesAnalysisYearlyOrderStatByUser = {
    /** 金額（如果收到空值，表示沒有訂單資料） */
    amount: Scalars['Float'];
    /** 月份統計 */
    months: Array<NavSalesAnalysisYearlyOrderStatByUser_Month>;
    /** 業務人員 */
    user?: Maybe<User>;
    /** 業務人員編號 */
    userCode?: Maybe<Scalars['String']>;
    /** 業務人員 */
    userId: Scalars['ID'];
    /** 年份 */
    year: Scalars['Int'];
};

export type NavSalesAnalysisYearlyOrderStatByUserFilterInput = {
    /** 關鍵字（同時查詢「業務人員編號、業務人員名稱」欄位） */
    keyword?: InputMaybe<Scalars['String']>;
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 年份 */
    year?: InputMaybe<Scalars['Int']>;
};

export type NavSalesAnalysisYearlyOrderStatByUser_Month = {
    /** 金額（如果收到空值，表示沒有訂單資料） */
    amount?: Maybe<Scalars['Float']>;
    /** 月份 */
    month: Scalars['Int'];
};

export type NavSalesAnalysisYearlyOrderStatByYear = {
    /** 金額 */
    amount: Scalars['Float'];
    /** 月份統計 */
    months: Array<NavSalesAnalysisYearlyOrderStatByYear_Month>;
    /** 年份 */
    year: Scalars['Int'];
};

export type NavSalesAnalysisYearlyOrderStatByYearFilterInput = {
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 年份 */
    year: Scalars['Int'];
};

export type NavSalesAnalysisYearlyOrderStatByYear_Month = {
    /** 金額（如果收到空值，表示沒有訂單資料） */
    amount?: Maybe<Scalars['Float']>;
    /** 月份 */
    month: Scalars['Int'];
};

export type NavSalesAnalysisYearlySalesStatBySalesTeam = {
    /** 金額 */
    amount: Scalars['Float'];
    /** 金額目標（未設定時，為空值） */
    amountGoal?: Maybe<Scalars['Float']>;
    /** 金額目標達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（總計金額）為 0 時，百分比顯示為空值 */
    amountGoalAchievementRate?: Maybe<Scalars['Float']>;
    /** 月份統計 */
    months: Array<NavSalesAnalysisYearlySalesStatBySalesTeam_Month>;
    /** 業務團隊 */
    salesTeam?: Maybe<SalesTeam>;
    /** 業務團隊 */
    salesTeamId: Scalars['ID'];
    /** 年份 */
    year: Scalars['Int'];
};

export type NavSalesAnalysisYearlySalesStatBySalesTeamFilterInput = {
    /** 關鍵字（同時查詢「業務團隊名稱」欄位） */
    keyword?: InputMaybe<Scalars['String']>;
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 年份 */
    year: Scalars['Int'];
};

export type NavSalesAnalysisYearlySalesStatBySalesTeam_Month = {
    /** 金額（如果收到空值，表示沒有銷售資料） */
    amount?: Maybe<Scalars['Float']>;
    /** 月份 */
    month: Scalars['Int'];
};

export type NavSalesAnalysisYearlySalesStatByUser = {
    /** 金額 */
    amount?: Maybe<Scalars['Float']>;
    /** 金額目標（未設定時，為空值） */
    amountGoal?: Maybe<Scalars['Float']>;
    /** 金額目標達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（總計金額）為 0 時，百分比顯示為空值 */
    amountGoalAchievementRate?: Maybe<Scalars['Float']>;
    /** 月份統計 */
    months: Array<NavSalesAnalysisYearlySalesStatByUser_Month>;
    /** 業務人員 */
    user?: Maybe<User>;
    /** 業務人員編號 */
    userCode?: Maybe<Scalars['String']>;
    /** 業務人員 */
    userId: Scalars['ID'];
    /** 年份 */
    year: Scalars['Int'];
};

export type NavSalesAnalysisYearlySalesStatByUserFilterInput = {
    /** 關鍵字（同時查詢「業務人員編號、業務人員名稱」欄位） */
    keyword?: InputMaybe<Scalars['String']>;
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 年份 */
    year?: InputMaybe<Scalars['Int']>;
};

export type NavSalesAnalysisYearlySalesStatByUser_Month = {
    /** 金額（如果收到空值，表示沒有訂單資料） */
    amount?: Maybe<Scalars['Float']>;
    /** 月份 */
    month: Scalars['Int'];
};

export type NavSalesAnalysisYearlySalesStatByYear = {
    /** 金額 */
    amount: Scalars['Float'];
    /** 月份統計 */
    months: Array<NavSalesAnalysisYearlySalesStatByYear_Month>;
    /** 年份 */
    year: Scalars['Int'];
};

export type NavSalesAnalysisYearlySalesStatByYearFilterInput = {
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 年份 */
    year: Scalars['Int'];
};

export type NavSalesAnalysisYearlySalesStatByYear_Month = {
    /** 金額（如果收到空值，表示沒有訂單資料） */
    amount?: Maybe<Scalars['Float']>;
    /** 月份 */
    month: Scalars['Int'];
};

export type NavSalesInvoice = IvSalesInvoiceAndReturn & {
    /** 總價（未稅） */
    amount?: Maybe<Scalars['Float']>;
    /** 報價單號 (合約審查號) */
    approvalCode: Scalars['String'];
    /** 帳款（開票）客戶 */
    billToCustomer?: Maybe<Customer>;
    /** 帳款（開票）客戶地址 */
    billToCustomerAddress?: Maybe<Scalars['String']>;
    /** 帳款（開票）客戶地址（第二行） */
    billToCustomerAddress2?: Maybe<Scalars['String']>;
    /** 帳款（開票）客戶編號 */
    billToCustomerCode: Scalars['String'];
    /** 帳款（開票）客戶名稱 */
    billToCustomerName?: Maybe<Scalars['String']>;
    /** 帳款（開票）客戶名稱（第二行） */
    billToCustomerName2?: Maybe<Scalars['String']>;
    /** 單據編號 */
    code: Scalars['String'];
    /** 負責部門 */
    dept?: Maybe<Department>;
    /** 部門代碼 */
    deptCode: Scalars['String'];
    id: Scalars['ID'];
    /** 詳細 */
    navSalesInvoiceDetails: Array<NavSalesInvoiceDetail>;
    /** 訂單單號 */
    orderCode: Scalars['String'];
    /** 訂單日期 */
    orderDate: Scalars['DateTime'];
    /** 銷售日期 */
    postingDate: Scalars['DateTime'];
    /** 銷售備註 */
    postingDescription?: Maybe<Scalars['String']>;
    /** 原因代碼 */
    reasonCode: Scalars['String'];
    /** 出貨客戶 */
    sellToCustomer?: Maybe<Customer>;
    /** 出貨客戶地址 */
    sellToCustomerAddress?: Maybe<Scalars['String']>;
    /** 出貨客戶地址（第二行） */
    sellToCustomerAddress2?: Maybe<Scalars['String']>;
    /** 出貨客戶編號 */
    sellToCustomerCode: Scalars['String'];
    /** 出貨客戶名稱 */
    sellToCustomerName?: Maybe<Scalars['String']>;
    /** 出貨客戶名稱（第二行） */
    sellToCustomerName2?: Maybe<Scalars['String']>;
    /** 單據類型 */
    type: EnumSalesInvoiceType;
    /** 負責業務 */
    user?: Maybe<User>;
    /** 員工代碼 */
    userCode: Scalars['String'];
};

export type NavSalesInvoiceDetail = IvSalesInvoiceAndReturnDetail & {
    /** 總價（未稅） */
    amount: Scalars['Float'];
    /** 備註 */
    description?: Maybe<Scalars['String']>;
    /** 備註（第二行） */
    description2?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    /** 單據單號 */
    invoiceCode: Scalars['String'];
    /** 商品料號 */
    materialCode: Scalars['String'];
    /** 商品 */
    navMaterial?: Maybe<NavMaterial>;
    /** 出貨訂單 */
    navSalesInvoice?: Maybe<NavSalesInvoice>;
    /** 數量 */
    qty: Scalars['Float'];
    /** 次序 */
    seq: Scalars['Int'];
    /** 出貨日期 */
    shipmentDate: Scalars['DateTime'];
    /** 單位代碼 */
    unitCode?: Maybe<Scalars['String']>;
    /** 單位名稱 */
    unitName?: Maybe<Scalars['String']>;
    /** 單價 */
    unitPrice: Scalars['Float'];
};

export type NavSalesInvoiceDetailFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** NAV 銷售單 */
    invoiceId?: InputMaybe<Scalars['String']>;
    /** NAV 銷售單 */
    navInvoiceId?: InputMaybe<Scalars['ID']>;
};

export type NavSalesInvoiceFilterInput = {
    /** 報價單號 (合約審查號)  */
    approvalCode?: InputMaybe<Scalars['String']>;
    /** 帳款（開票）客戶編號 */
    billToCustomerCode?: InputMaybe<Scalars['String']>;
    /** 帳款（開票）客戶 */
    billToCustomerIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 帳款（開票）客戶名稱 */
    billToCustomerName?: InputMaybe<Scalars['String']>;
    /** 帳款（開票）客戶編號 */
    billToCustomerNo?: InputMaybe<Scalars['String']>;
    /** 商機編號 */
    businessCode?: InputMaybe<Scalars['String']>;
    /** 單據編號 */
    code?: InputMaybe<Scalars['String']>;
    /** 客戶編號（同時查詢「帳款（開票）客戶」及「出貨客戶」） */
    customerCode?: InputMaybe<Scalars['String']>;
    /** 客戶（同時查詢「帳款（開票）客戶」及「出貨客戶」） */
    customerIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 客戶名稱（同時查詢「帳款（開票）客戶」及「出貨客戶」） */
    customerName?: InputMaybe<Scalars['String']>;
    /** 負責部門（複選） */
    deptIds?: InputMaybe<Array<Scalars['ID']>>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 訂單編號 */
    orderCode?: InputMaybe<Scalars['String']>;
    /** 訂單日期（起） */
    orderDate1?: InputMaybe<Scalars['String']>;
    /** 訂單日期（迄） */
    orderDate2?: InputMaybe<Scalars['String']>;
    /** 銷售日期（起） */
    postingDate1?: InputMaybe<Scalars['String']>;
    /** 銷售日期（迄） */
    postingDate2?: InputMaybe<Scalars['String']>;
    /** 銷售商品類型 */
    salesProductType?: InputMaybe<EnumSalesProductType>;
    /** 出貨客戶編號 */
    sellToCustomerCode?: InputMaybe<Scalars['String']>;
    /** 出貨客戶 */
    sellToCustomerIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 出貨客戶名稱 */
    sellToCustomerName?: InputMaybe<Scalars['String']>;
    /** 出貨客戶編號 */
    sellToCustomerNo?: InputMaybe<Scalars['String']>;
    /** 類型 */
    type?: InputMaybe<EnumSalesInvoiceType>;
    /** 負責業務（複選） */
    userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type PaginatedAppVersions = {
    appVersions?: Maybe<Array<AppVersion>>;
    count?: Maybe<Scalars['Int']>;
};

export type PaginatedBusinessLosingReasons = {
    businessLosingReasons?: Maybe<Array<BusinessLosingReason>>;
    count?: Maybe<Scalars['Int']>;
};

export type PaginatedBusinessMemos = {
    businessMemos?: Maybe<Array<BusinessMemo>>;
    count?: Maybe<Scalars['Int']>;
};

export type PaginatedBusinessOpportunities = {
    businessOpportunities?: Maybe<Array<BusinessOpportunity>>;
    count?: Maybe<Scalars['Int']>;
};

export type PaginatedBusinessProductTypes = {
    businessProductTypes?: Maybe<Array<BusinessProductType>>;
    count?: Maybe<Scalars['Int']>;
};

export type PaginatedBusinessProducts = {
    businessProducts?: Maybe<Array<BusinessProduct>>;
    count?: Maybe<Scalars['Int']>;
};

export type PaginatedBusinessProperties = {
    businessProperties?: Maybe<Array<BusinessProperty>>;
    count?: Maybe<Scalars['Int']>;
};

export type PaginatedBusinessPropertyTypes = {
    businessPropertyTypes?: Maybe<Array<BusinessPropertyType>>;
    count?: Maybe<Scalars['Int']>;
};

export type PaginatedBusinessSalesMethods = {
    businessSalesMethods?: Maybe<Array<BusinessSalesMethod>>;
    count?: Maybe<Scalars['Int']>;
};

export type PaginatedBusinessStatuses = {
    businessStatuses?: Maybe<Array<BusinessStatus>>;
    count?: Maybe<Scalars['Int']>;
};

export type PaginatedBusinessTypes = {
    businessTypes?: Maybe<Array<BusinessType>>;
    count?: Maybe<Scalars['Int']>;
};

export type PaginatedBusinesses = {
    /** 客戶預算金額。總計 */
    budgetAmountSum: Scalars['Float'];
    businesses?: Maybe<Array<Business>>;
    count?: Maybe<Scalars['Int']>;
};

export type PaginatedCities = {
    cities?: Maybe<Array<City>>;
    count?: Maybe<Scalars['Int']>;
};

export type PaginatedCompanies = {
    companies?: Maybe<Array<Company>>;
    count?: Maybe<Scalars['Int']>;
};

export type PaginatedCompetitors = {
    competitors?: Maybe<Array<Competitor>>;
    count?: Maybe<Scalars['Int']>;
};

export type PaginatedContactPeople = {
    contactPeople?: Maybe<Array<ContactPerson>>;
    count?: Maybe<Scalars['Int']>;
};

export type PaginatedContactPersonDepartments = {
    contactPersonDepartments?: Maybe<Array<ContactPersonDepartment>>;
    count?: Maybe<Scalars['Int']>;
};

export type PaginatedCostCenters = {
    costCenters?: Maybe<Array<CostCenter>>;
    count?: Maybe<Scalars['Int']>;
};

export type PaginatedCreditPeriods = {
    count?: Maybe<Scalars['Int']>;
    creditPeriods?: Maybe<Array<CreditPeriod>>;
};

export type PaginatedCurrencies = {
    count?: Maybe<Scalars['Int']>;
    currencies?: Maybe<Array<Currency>>;
};

export type PaginatedCustomerAreas = {
    count?: Maybe<Scalars['Int']>;
    customerAreas?: Maybe<Array<CustomerArea>>;
};

export type PaginatedCustomerAttachments = {
    count?: Maybe<Scalars['Int']>;
    customerAttachments?: Maybe<Array<CustomerAttachment>>;
};

export type PaginatedCustomerCategories = {
    count?: Maybe<Scalars['Int']>;
    customerCategories?: Maybe<Array<CustomerCategory>>;
};

export type PaginatedCustomerCertificateAttachments = {
    count?: Maybe<Scalars['Int']>;
    customerCertificateAttachments?: Maybe<Array<CustomerCertificateAttachment>>;
};

export type PaginatedCustomerCertificateTypes = {
    count?: Maybe<Scalars['Int']>;
    customerCertificateTypes?: Maybe<Array<CustomerCertificateType>>;
};

export type PaginatedCustomerCertificates = {
    count?: Maybe<Scalars['Int']>;
    customerCertificates?: Maybe<Array<CustomerCertificate>>;
};

export type PaginatedCustomerGroups = {
    count?: Maybe<Scalars['Int']>;
    customerGroups?: Maybe<Array<CustomerGroup>>;
};

export type PaginatedCustomerLevelFieldTypes = {
    count?: Maybe<Scalars['Int']>;
    customerLevelFieldTypes?: Maybe<Array<CustomerLevelFieldType>>;
};

export type PaginatedCustomerLevelFields = {
    count?: Maybe<Scalars['Int']>;
    customerLevelFields?: Maybe<Array<CustomerLevelField>>;
};

export type PaginatedCustomerLevelTypes = {
    count?: Maybe<Scalars['Int']>;
    customerLevelTypes?: Maybe<Array<CustomerLevelType>>;
};

export type PaginatedCustomerLevels = {
    count?: Maybe<Scalars['Int']>;
    customerLevels?: Maybe<Array<CustomerLevel>>;
};

export type PaginatedCustomerProperties = {
    count?: Maybe<Scalars['Int']>;
    customerProperties?: Maybe<Array<CustomerProperty>>;
};

export type PaginatedCustomerPropertyTypes = {
    count?: Maybe<Scalars['Int']>;
    customerPropertyTypes?: Maybe<Array<CustomerPropertyType>>;
};

export type PaginatedCustomerTypes = {
    count?: Maybe<Scalars['Int']>;
    customerTypes?: Maybe<Array<CustomerType>>;
};

export type PaginatedCustomers = {
    count?: Maybe<Scalars['Int']>;
    customers?: Maybe<Array<Customer>>;
};

export type PaginatedDepartments = {
    count?: Maybe<Scalars['Int']>;
    departments?: Maybe<Array<Department>>;
};

export type PaginatedDistricts = {
    count?: Maybe<Scalars['Int']>;
    districts?: Maybe<Array<District>>;
};

export type PaginatedEyeFixedAssetItems = {
    count?: Maybe<Scalars['Int']>;
    eyeFixedAssetItems?: Maybe<Array<EyeFixedAssetItem>>;
};

export type PaginatedEyeFixedAssetRentalGoals = {
    count?: Maybe<Scalars['Int']>;
    eyeFixedAssetRentalGoals?: Maybe<Array<EyeFixedAssetRentalGoal>>;
};

export type PaginatedEyeFixedAssetRentalObjects = {
    count?: Maybe<Scalars['Int']>;
    eyeFixedAssetRentalObjects?: Maybe<Array<EyeFixedAssetRentalObject>>;
};

export type PaginatedEyeFixedAssetRentalRecords = {
    count?: Maybe<Scalars['Int']>;
    eyeFixedAssetRentalRecords?: Maybe<Array<EyeFixedAssetRentalRecord>>;
};

export type PaginatedEyeFixedAssetTypes = {
    count?: Maybe<Scalars['Int']>;
    eyeFixedAssetTypes?: Maybe<Array<EyeFixedAssetType>>;
};

export type PaginatedEyeFixedAssets = {
    count?: Maybe<Scalars['Int']>;
    eyeFixedAssets?: Maybe<Array<EyeFixedAsset>>;
};

export type PaginatedEyeProductItemTypes = {
    count?: Maybe<Scalars['Int']>;
    eyeProductItemTypes?: Maybe<Array<EyeProductItemType>>;
};

export type PaginatedEyeProductItems = {
    count?: Maybe<Scalars['Int']>;
    eyeProductItems?: Maybe<Array<EyeProductItem>>;
};

export type PaginatedEyeProductTypes = {
    count?: Maybe<Scalars['Int']>;
    eyeProductTypes?: Maybe<Array<EyeProductType>>;
};

export type PaginatedEyeProducts = {
    count?: Maybe<Scalars['Int']>;
    eyeProducts?: Maybe<Array<EyeProduct>>;
};

export type PaginatedEyePromotionAddonProductItems = {
    count?: Maybe<Scalars['Int']>;
    eyePromotionAddonProductItems?: Maybe<Array<EyePromotionAddonProductItem>>;
};

export type PaginatedEyePromotionConditions = {
    count?: Maybe<Scalars['Int']>;
    eyePromotionConditions?: Maybe<Array<EyePromotionCondition>>;
};

export type PaginatedEyePromotions = {
    count?: Maybe<Scalars['Int']>;
    eyePromotions?: Maybe<Array<EyePromotion>>;
};

export type PaginatedEyeQuotationOrderBusinesses = {
    count?: Maybe<Scalars['Int']>;
    eyeQuotationOrderBusinesses?: Maybe<Array<EyeQuotationOrderBusiness>>;
};

export type PaginatedEyeQuotationOrderProductItems = {
    count?: Maybe<Scalars['Int']>;
    eyeQuotationOrderProductItems?: Maybe<Array<EyeQuotationOrderProductItem>>;
};

export type PaginatedEyeQuotationOrderProducts = {
    count?: Maybe<Scalars['Int']>;
    eyeQuotationOrderProducts?: Maybe<Array<EyeQuotationOrderProduct>>;
};

export type PaginatedEyeQuotationOrderPromotions = {
    count?: Maybe<Scalars['Int']>;
    eyeQuotationOrderPromotions?: Maybe<Array<EyeQuotationOrderPromotion>>;
};

export type PaginatedEyeQuotationOrderTypes = {
    count?: Maybe<Scalars['Int']>;
    eyeQuotationOrderTypes?: Maybe<Array<EyeQuotationOrderType>>;
};

export type PaginatedEyeQuotationOrderWarrantyItems = {
    count?: Maybe<Scalars['Int']>;
    eyeQuotationOrderWarrantyItems?: Maybe<Array<EyeQuotationOrderWarrantyItem>>;
};

export type PaginatedEyeQuotationOrders = {
    count?: Maybe<Scalars['Int']>;
    eyeQuotationOrders?: Maybe<Array<EyeQuotationOrder>>;
};

export type PaginatedEyeServiceOrderAttachFiles = {
    count?: Maybe<Scalars['Int']>;
    eyeServiceOrderAttachFiles?: Maybe<Array<EyeServiceOrderAttachFile>>;
};

export type PaginatedEyeServiceOrderExtensions = {
    count?: Maybe<Scalars['Int']>;
    eyeServiceOrderExtensions?: Maybe<Array<EyeServiceOrderExtension>>;
};

export type PaginatedEyeServiceOrderItems = {
    count?: Maybe<Scalars['Int']>;
    eyeServiceOrderItems?: Maybe<Array<EyeServiceOrderItem>>;
};

export type PaginatedEyeServiceOrderTypes = {
    count?: Maybe<Scalars['Int']>;
    eyeServiceOrderTypes?: Maybe<Array<EyeServiceOrderType>>;
};

export type PaginatedEyeServiceOrderWorkDiaries = {
    count?: Maybe<Scalars['Int']>;
    eyeServiceOrderWorkDiaries?: Maybe<Array<EyeServiceOrderWorkDiary>>;
};

export type PaginatedEyeServiceOrderWorkDiaryAttachFiles = {
    count?: Maybe<Scalars['Int']>;
    eyeServiceOrderWorkDiaryAttachFiles?: Maybe<Array<EyeServiceOrderWorkDiaryAttachFile>>;
};

export type PaginatedEyeServiceOrderWorkDiaryTypes = {
    count?: Maybe<Scalars['Int']>;
    eyeServiceOrderWorkDiaryTypes?: Maybe<Array<EyeServiceOrderWorkDiaryType>>;
};

export type PaginatedEyeServiceOrders = {
    count?: Maybe<Scalars['Int']>;
    eyeServiceOrders?: Maybe<Array<EyeServiceOrder>>;
};

export type PaginatedEyeWarrantyContractItems = {
    count?: Maybe<Scalars['Int']>;
    eyeWarrantyContractItems?: Maybe<Array<EyeWarrantyContractItem>>;
};

export type PaginatedEyeWarrantyContracts = {
    count?: Maybe<Scalars['Int']>;
    eyeWarrantyContracts?: Maybe<Array<EyeWarrantyContract>>;
};

export type PaginatedEyeWarrantyPeriodTypes = {
    count?: Maybe<Scalars['Int']>;
    eyeWarrantyPeriodTypes?: Maybe<Array<EyeWarrantyPeriodType>>;
};

export type PaginatedMaterialExtraProperties = {
    count?: Maybe<Scalars['Int']>;
    materialExtraProperties?: Maybe<Array<MaterialExtraProperty>>;
};

export type PaginatedMaterialProperties = {
    count?: Maybe<Scalars['Int']>;
    materialProperties?: Maybe<Array<MaterialProperty>>;
};

export type PaginatedMaterialRepairTypes = {
    count?: Maybe<Scalars['Int']>;
    materialRepairTypes?: Maybe<Array<MaterialRepairType>>;
};

export type PaginatedMaterialTypes = {
    count?: Maybe<Scalars['Int']>;
    materialTypes?: Maybe<Array<MaterialType>>;
};

export type PaginatedMaterials = {
    count?: Maybe<Scalars['Int']>;
    materials?: Maybe<Array<Material>>;
};

export type PaginatedNavMaterial = {
    count?: Maybe<Scalars['Int']>;
    navMaterials?: Maybe<Array<NavMaterial>>;
};

export type PaginatedNavOrder = {
    count?: Maybe<Scalars['Int']>;
    navOrders?: Maybe<Array<NavOrder>>;
};

export type PaginatedNavOrderDetail = {
    count?: Maybe<Scalars['Int']>;
    navOrderDetails?: Maybe<Array<NavOrderDetail>>;
};

export type PaginatedNavProduct = {
    count?: Maybe<Scalars['Int']>;
    navProducts?: Maybe<Array<NavProduct>>;
};

export type PaginatedNavSalesInvoice = {
    count?: Maybe<Scalars['Int']>;
    navSalesInvoices?: Maybe<Array<NavSalesInvoice>>;
};

export type PaginatedNavSalesInvoiceDetail = {
    count?: Maybe<Scalars['Int']>;
    navSalesInvoiceDetails?: Maybe<Array<NavSalesInvoiceDetail>>;
};

export type PaginatedObject = {
    count?: Maybe<Scalars['Int']>;
};

export type PaginatedPaymentMethods = {
    count?: Maybe<Scalars['Int']>;
    paymentMethods?: Maybe<Array<PaymentMethod>>;
};

export type PaginatedPermissionApplications = {
    count?: Maybe<Scalars['Int']>;
    permissionApplications?: Maybe<Array<PermissionApplication>>;
};

export type PaginatedPermissionGroups = {
    count?: Maybe<Scalars['Int']>;
    permissionGroups?: Maybe<Array<PermissionGroup>>;
};

export type PaginatedPermissions = {
    count?: Maybe<Scalars['Int']>;
    permissions?: Maybe<Array<Permission>>;
};

export type PaginatedProvinces = {
    count?: Maybe<Scalars['Int']>;
    provinces?: Maybe<Array<Province>>;
};

export type PaginatedRegions = {
    count?: Maybe<Scalars['Int']>;
    regions?: Maybe<Array<Region>>;
};

export type PaginatedRoles = {
    count?: Maybe<Scalars['Int']>;
    roles?: Maybe<Array<Role>>;
};

export type PaginatedSalesPerformanceSalesProductTypeGroups = {
    count?: Maybe<Scalars['Int']>;
    salesPerformanceSalesProductTypeGroups?: Maybe<Array<SalesPerformanceSalesProductTypeGroup>>;
};

export type PaginatedSalesPerformanceSalesProductTypes = {
    count?: Maybe<Scalars['Int']>;
    salesPerformanceSalesProductTypes?: Maybe<Array<SalesPerformanceSalesProductType>>;
};

export type PaginatedSalesPerformanceSalesTypes = {
    count?: Maybe<Scalars['Int']>;
    salesPerformanceSalesTypes?: Maybe<Array<SalesPerformanceSalesType>>;
};

export type PaginatedSalesTeamGroups = {
    count?: Maybe<Scalars['Int']>;
    salesTeamGroups?: Maybe<Array<SalesTeamGroup>>;
};

export type PaginatedSalesTeamUnits = {
    count?: Maybe<Scalars['Int']>;
    salesTeamUnits?: Maybe<Array<SalesTeamUnit>>;
};

export type PaginatedSalesTeams = {
    count?: Maybe<Scalars['Int']>;
    salesTeams?: Maybe<Array<SalesTeam>>;
};

export type PaginatedShippingMethods = {
    count?: Maybe<Scalars['Int']>;
    shippingMethods?: Maybe<Array<ShippingMethod>>;
};

export type PaginatedTaxRates = {
    count?: Maybe<Scalars['Int']>;
    taxRates?: Maybe<Array<TaxRate>>;
};

export type PaginatedUsers = {
    count?: Maybe<Scalars['Int']>;
    users?: Maybe<Array<User>>;
};

export type PaginatedVisitActions = {
    count?: Maybe<Scalars['Int']>;
    visitActions?: Maybe<Array<VisitAction>>;
};

export type PaginatedVisitCheckIns = {
    count?: Maybe<Scalars['Int']>;
    visitCheckIns?: Maybe<Array<VisitCheckIn>>;
};

export type PaginatedVisitGoals = {
    count?: Maybe<Scalars['Int']>;
    visitGoals?: Maybe<Array<VisitGoal>>;
};

export type PaginatedVisitMemos = {
    count?: Maybe<Scalars['Int']>;
    visitMemos?: Maybe<Array<VisitMemo>>;
};

export type PaginatedVisitProperties = {
    count?: Maybe<Scalars['Int']>;
    visitProperties?: Maybe<Array<VisitProperty>>;
};

export type PaginatedVisitPropertyTypes = {
    count?: Maybe<Scalars['Int']>;
    visitPropertyTypes?: Maybe<Array<VisitPropertyType>>;
};

export type PaginatedVisitTimePeriods = {
    count?: Maybe<Scalars['Int']>;
    visitTimePeriods?: Maybe<Array<VisitTimePeriod>>;
};

export type PaginatedVisitTypes = {
    count?: Maybe<Scalars['Int']>;
    visitTypes?: Maybe<Array<VisitType>>;
};

export type PaginatedVisits = {
    count?: Maybe<Scalars['Int']>;
    visits?: Maybe<Array<Visit>>;
};

export type PaginationInput = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
};

export type PaymentMethod = IPaymentMethod & {
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    name: Scalars['String'];
    /** 區域 */
    region: Region;
    regionId: Scalars['ID'];
    updatedAt: Scalars['DateTime'];
};

export type PaymentMethodFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    regionId?: InputMaybe<Scalars['ID']>;
};

export type Permission = IPermission & {
    applicationId: Scalars['ID'];
    /** 編號 */
    code: Scalars['String'];
    /** 敘述 */
    description?: Maybe<Scalars['String']>;
    /** 群組 */
    group: PermissionGroup;
    groupId: Scalars['ID'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
};

export type PermissionApplication = IPermissionApplication & {
    /** 編號 */
    code: Scalars['String'];
    /** 敘述 */
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    permissions: Array<Permission>;
};

export type PermissionApplicationFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type PermissionFilterInput = {
    /** 權限歸屬系統編號 */
    applicationCode?: InputMaybe<Scalars['String']>;
    /** 權限歸屬系統 */
    applicationId?: InputMaybe<Scalars['ID']>;
    /** 權限群組 */
    groupId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 角色（複選） */
    roleIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type PermissionGroup = IPermissionGroup & {
    /** 編號 */
    code: Scalars['String'];
    /** 敘述 */
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    permissions: Array<Permission>;
};

export type PermissionGroupFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type Playground = {
    /** API 版本 */
    version: Scalars['String'];
};

export type PlaygroundSayHelloByGrpc = {
    message: Scalars['String'];
};

export type PlaygroundSayHelloByGrpcInput = {
    name: Scalars['String'];
};

export type PlaygroundSayHelloByWebSocket = {
    message: Scalars['String'];
    user?: Maybe<User>;
};

export type PlaygroundSayHelloByWebSocketInput = {
    name: Scalars['String'];
};

export type PlaygroundSayHelloSubscriptionInput = {
    /** 發送人員 */
    senderId?: InputMaybe<Scalars['ID']>;
};

export type Province = IProvince & {
    /** 市區、二級行政區 */
    cities: Array<City>;
    code?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    /** 區域 */
    region: Region;
    regionId: Scalars['ID'];
    updatedAt: Scalars['DateTime'];
    viewOrder: Scalars['Int'];
};

export type ProvinceFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    regionId?: InputMaybe<Scalars['ID']>;
};

export type Query = {
    EcDashboardAnalysis: EcDashboardAnalysis;
    EcDashboardAnalysisDetail: EcDashboardAnalysisSalesDetail;
    EcInventoryDetails: Array<EcInventoryDetail>;
    EcInventoryModels: Array<EcInventoryModel>;
    EcInventorySpecs: Array<EcInventorySpec>;
    EcInventoryStat: EcInventoryStat;
    EcInventoryWarehouses: Array<EcInventoryWarehouse>;
    appVersion: AppVersion;
    appVersions: Array<AppVersion>;
    business: Business;
    businessAnalysis: BusinessAnalysis;
    businessLosingReason: BusinessLosingReason;
    businessLosingReasons: Array<BusinessLosingReason>;
    businessMemo: BusinessMemo;
    businessMemos: Array<BusinessMemo>;
    businessOpportunities: Array<BusinessOpportunity>;
    businessOpportunity: BusinessOpportunity;
    businessProduct: BusinessProduct;
    businessProductType: BusinessProductType;
    businessProductTypes: Array<BusinessProductType>;
    businessProducts: Array<BusinessProduct>;
    businessProperties: Array<BusinessProperty>;
    businessProperty: BusinessProperty;
    businessPropertyType: BusinessPropertyType;
    businessPropertyTypes: Array<BusinessPropertyType>;
    businessSalesMethod: BusinessSalesMethod;
    businessSalesMethods: Array<BusinessSalesMethod>;
    businessStatus: BusinessStatus;
    businessStatuses: Array<BusinessStatus>;
    businessType: BusinessType;
    businessTypes: Array<BusinessType>;
    businesses: Array<Business>;
    cities: Array<City>;
    city: City;
    companies: Array<Company>;
    company: Company;
    competitor: Competitor;
    competitors: Array<Competitor>;
    contactPeople: Array<ContactPerson>;
    contactPerson: ContactPerson;
    contactPersonDepartment: ContactPersonDepartment;
    contactPersonDepartments: Array<ContactPersonDepartment>;
    costCenter: CostCenter;
    costCenters: Array<CostCenter>;
    creditPeriod: CreditPeriod;
    creditPeriods: Array<CreditPeriod>;
    currencies: Array<Currency>;
    currency: Currency;
    customer: Customer;
    customerAnalysis: CustomerAnalysis;
    customerArea: CustomerArea;
    customerAreas: Array<CustomerArea>;
    customerAttachment: CustomerAttachment;
    customerAttachments: Array<CustomerAttachment>;
    customerCategories: Array<CustomerCategory>;
    customerCategory: CustomerCategory;
    customerCertificate: CustomerCertificate;
    customerCertificateAttachment: CustomerCertificateAttachment;
    customerCertificateAttachments: Array<CustomerCertificateAttachment>;
    customerCertificateType: CustomerCertificateType;
    customerCertificateTypes: Array<CustomerCertificateType>;
    customerCertificates: Array<CustomerCertificate>;
    customerGroup: CustomerGroup;
    customerGroups: Array<CustomerGroup>;
    customerLevel: CustomerLevel;
    customerLevelField: CustomerLevelField;
    customerLevelFieldType: CustomerLevelFieldType;
    customerLevelFieldTypes: Array<CustomerLevelFieldType>;
    customerLevelFields: Array<CustomerLevelField>;
    customerLevelType: CustomerLevelType;
    customerLevelTypes: Array<CustomerLevelType>;
    customerLevels: Array<CustomerLevel>;
    customerProperties: Array<CustomerProperty>;
    customerProperty: CustomerProperty;
    customerPropertyType: CustomerPropertyType;
    customerPropertyTypes: Array<CustomerPropertyType>;
    customerType: CustomerType;
    customerTypes: Array<CustomerType>;
    customers: Array<Customer>;
    department: Department;
    departments: Array<Department>;
    districts: Array<District>;
    exchangeRate: ExchangeRate;
    exchangeRates: Array<ExchangeRate>;
    eyeFixedAsset: EyeFixedAsset;
    eyeFixedAssetItem: EyeFixedAssetItem;
    eyeFixedAssetItems: Array<EyeFixedAssetItem>;
    eyeFixedAssetRentalGoal: EyeFixedAssetRentalGoal;
    eyeFixedAssetRentalGoals: Array<EyeFixedAssetRentalGoal>;
    eyeFixedAssetRentalObject: EyeFixedAssetRentalObject;
    eyeFixedAssetRentalObjects: Array<EyeFixedAssetRentalObject>;
    eyeFixedAssetRentalRecord: EyeFixedAssetRentalRecord;
    eyeFixedAssetRentalRecords: Array<EyeFixedAssetRentalRecord>;
    eyeFixedAssetType: EyeFixedAssetType;
    eyeFixedAssetTypes: Array<EyeFixedAssetType>;
    eyeFixedAssets: Array<EyeFixedAsset>;
    eyeProduct: EyeProduct;
    eyeProductItem: EyeProductItem;
    eyeProductItemType: EyeProductItemType;
    eyeProductItemTypes: Array<EyeProductItemType>;
    eyeProductItems: Array<EyeProductItem>;
    eyeProductType: EyeProductType;
    eyeProductTypes: Array<EyeProductType>;
    eyeProducts: Array<EyeProduct>;
    eyePromotion: EyePromotion;
    eyePromotionAddonProductItem: EyePromotionAddonProductItem;
    eyePromotionAddonProductItems: Array<EyePromotionAddonProductItem>;
    eyePromotionCondition: EyePromotionCondition;
    eyePromotionConditions: Array<EyePromotionCondition>;
    eyePromotions: Array<EyePromotion>;
    eyeQuotationOrder: EyeQuotationOrder;
    eyeQuotationOrderBusiness: EyeQuotationOrderBusiness;
    eyeQuotationOrderBusinesses: Array<EyeQuotationOrderBusiness>;
    eyeQuotationOrderProduct: EyeQuotationOrderProduct;
    eyeQuotationOrderProductItem: EyeQuotationOrderProductItem;
    eyeQuotationOrderProductItems: Array<EyeQuotationOrderProductItem>;
    eyeQuotationOrderProducts: Array<EyeQuotationOrderProduct>;
    eyeQuotationOrderPromotion: EyeQuotationOrderPromotion;
    eyeQuotationOrderPromotions: Array<EyeQuotationOrderPromotion>;
    eyeQuotationOrderType: EyeQuotationOrderType;
    eyeQuotationOrderTypes: Array<EyeQuotationOrderType>;
    eyeQuotationOrderWarrantyItem: EyeQuotationOrderWarrantyItem;
    eyeQuotationOrderWarrantyItems: Array<EyeQuotationOrderWarrantyItem>;
    eyeQuotationOrders: Array<EyeQuotationOrder>;
    eyeServiceOrder: EyeServiceOrder;
    eyeServiceOrderAttachFile: EyeServiceOrderAttachFile;
    eyeServiceOrderAttachFiles: Array<EyeServiceOrderAttachFile>;
    eyeServiceOrderExtension: EyeServiceOrderExtension;
    eyeServiceOrderExtensions: Array<EyeServiceOrderExtension>;
    eyeServiceOrderItem: EyeServiceOrderItem;
    eyeServiceOrderItems: Array<EyeServiceOrderItem>;
    eyeServiceOrderType: EyeServiceOrderType;
    eyeServiceOrderTypes: Array<EyeServiceOrderType>;
    eyeServiceOrderWorkDiaries: Array<EyeServiceOrderWorkDiary>;
    eyeServiceOrderWorkDiary: EyeServiceOrderWorkDiary;
    eyeServiceOrderWorkDiaryAttachFile: EyeServiceOrderWorkDiaryAttachFile;
    eyeServiceOrderWorkDiaryAttachFiles: Array<EyeServiceOrderWorkDiaryAttachFile>;
    eyeServiceOrderWorkDiaryType: EyeServiceOrderWorkDiaryType;
    eyeServiceOrderWorkDiaryTypes: Array<EyeServiceOrderWorkDiaryType>;
    eyeServiceOrders: Array<EyeServiceOrder>;
    eyeWarrantyContract: EyeWarrantyContract;
    eyeWarrantyContractItem: EyeWarrantyContractItem;
    eyeWarrantyContractItems: Array<EyeWarrantyContractItem>;
    eyeWarrantyContracts: Array<EyeWarrantyContract>;
    eyeWarrantyPeriodType: EyeWarrantyPeriodType;
    eyeWarrantyPeriodTypes: Array<EyeWarrantyPeriodType>;
    material: Material;
    materialExtraProperties: Array<MaterialExtraProperty>;
    materialExtraProperty: MaterialExtraProperty;
    materialProperties: Array<MaterialProperty>;
    materialProperty: MaterialProperty;
    materialRepairType: MaterialRepairType;
    materialRepairTypes: Array<MaterialRepairType>;
    materialType: MaterialType;
    materialTypes: Array<MaterialType>;
    materials: Array<Material>;
    me: Me;
    navMaterial: NavMaterial;
    navMaterials: Array<NavMaterial>;
    navOrder: NavOrder;
    navOrderDetail: NavOrderDetail;
    navOrderDetails: Array<NavOrderDetail>;
    navOrders: Array<NavOrder>;
    navProduct: NavProduct;
    navProducts: Array<NavProduct>;
    navSalesAnalysis: NavSalesAnalysis;
    navSalesInvoice: NavSalesInvoice;
    navSalesInvoiceDetail: NavSalesInvoiceDetail;
    navSalesInvoiceDetails: Array<NavSalesInvoiceDetail>;
    navSalesInvoices: Array<NavSalesInvoice>;
    paginatedAppVersions: PaginatedAppVersions;
    paginatedBusinessLosingReasons: PaginatedBusinessLosingReasons;
    paginatedBusinessMemos: PaginatedBusinessMemos;
    paginatedBusinessOpportunities: PaginatedBusinessOpportunities;
    paginatedBusinessProductTypes: PaginatedBusinessProductTypes;
    paginatedBusinessProducts: PaginatedBusinessProducts;
    paginatedBusinessProperties: PaginatedBusinessProperties;
    paginatedBusinessPropertyTypes: PaginatedBusinessPropertyTypes;
    paginatedBusinessSalesMethods: PaginatedBusinessSalesMethods;
    paginatedBusinessStatuses: PaginatedBusinessStatuses;
    paginatedBusinessTypes: PaginatedBusinessTypes;
    paginatedBusinesses: PaginatedBusinesses;
    paginatedCities: PaginatedCities;
    paginatedCompanies: PaginatedCompanies;
    paginatedCompetitors: PaginatedCompetitors;
    paginatedContactPeople: PaginatedContactPeople;
    paginatedContactPersonDepartments: PaginatedContactPersonDepartments;
    paginatedCostCenters: PaginatedCostCenters;
    paginatedCreditPeriods: PaginatedCreditPeriods;
    paginatedCurrencies: PaginatedCurrencies;
    paginatedCustomerAreas: PaginatedCustomerAreas;
    paginatedCustomerAttachments: PaginatedCustomerAttachments;
    paginatedCustomerCategories: PaginatedCustomerCategories;
    paginatedCustomerCertificateAttachments: PaginatedCustomerCertificateAttachments;
    paginatedCustomerCertificateTypes: PaginatedCustomerCertificateTypes;
    paginatedCustomerCertificates: PaginatedCustomerCertificates;
    paginatedCustomerGroups: PaginatedCustomerGroups;
    paginatedCustomerLevelFieldTypes: PaginatedCustomerLevelFieldTypes;
    paginatedCustomerLevelFields: PaginatedCustomerLevelFields;
    paginatedCustomerLevelTypes: PaginatedCustomerLevelTypes;
    paginatedCustomerLevels: PaginatedCustomerLevels;
    paginatedCustomerProperties: PaginatedCustomerProperties;
    paginatedCustomerPropertyTypes: PaginatedCustomerPropertyTypes;
    paginatedCustomerTypes: PaginatedCustomerTypes;
    paginatedCustomers: PaginatedCustomers;
    paginatedDepartments: PaginatedDepartments;
    paginatedDistricts: PaginatedDistricts;
    paginatedExchangeRates: ExchangeRateMethods;
    paginatedEyeFixedAssetItems: PaginatedEyeFixedAssetItems;
    paginatedEyeFixedAssetRentalGoals: PaginatedEyeFixedAssetRentalGoals;
    paginatedEyeFixedAssetRentalObjects: PaginatedEyeFixedAssetRentalObjects;
    paginatedEyeFixedAssetRentalRecords: PaginatedEyeFixedAssetRentalRecords;
    paginatedEyeFixedAssetTypes: PaginatedEyeFixedAssetTypes;
    paginatedEyeFixedAssets: PaginatedEyeFixedAssets;
    paginatedEyeProductItemTypes: PaginatedEyeProductItemTypes;
    paginatedEyeProductItems: PaginatedEyeProductItems;
    paginatedEyeProductTypes: PaginatedEyeProductTypes;
    paginatedEyeProducts: PaginatedEyeProducts;
    paginatedEyePromotionAddonProductItems: PaginatedEyePromotionAddonProductItems;
    paginatedEyePromotionConditions: PaginatedEyePromotionConditions;
    paginatedEyePromotions: PaginatedEyePromotions;
    paginatedEyeQuotationOrderBusinesses: PaginatedEyeQuotationOrderBusinesses;
    paginatedEyeQuotationOrderProductItems: PaginatedEyeQuotationOrderProductItems;
    paginatedEyeQuotationOrderProducts: PaginatedEyeQuotationOrderProducts;
    paginatedEyeQuotationOrderPromotions: PaginatedEyeQuotationOrderPromotions;
    paginatedEyeQuotationOrderTypes: PaginatedEyeQuotationOrderTypes;
    paginatedEyeQuotationOrderWarrantyItems: PaginatedEyeQuotationOrderWarrantyItems;
    paginatedEyeQuotationOrders: PaginatedEyeQuotationOrders;
    paginatedEyeServiceOrderAttachFiles: PaginatedEyeServiceOrders;
    paginatedEyeServiceOrderExtensions: PaginatedEyeServiceOrderExtensions;
    paginatedEyeServiceOrderItems: PaginatedEyeServiceOrderItems;
    paginatedEyeServiceOrderTypes: PaginatedEyeServiceOrderTypes;
    paginatedEyeServiceOrderWorkDiaries: PaginatedEyeServiceOrderWorkDiaries;
    paginatedEyeServiceOrderWorkDiaryAttachFiles: PaginatedEyeServiceOrderWorkDiaries;
    paginatedEyeServiceOrderWorkDiaryTypes: PaginatedEyeServiceOrderWorkDiaryTypes;
    paginatedEyeServiceOrders: PaginatedEyeServiceOrders;
    paginatedEyeWarrantyContractItems: PaginatedEyeWarrantyContractItems;
    paginatedEyeWarrantyContracts: PaginatedEyeWarrantyContracts;
    paginatedEyeWarrantyPeriodTypes: PaginatedEyeWarrantyPeriodTypes;
    paginatedMaterialExtraProperties: PaginatedMaterialExtraProperties;
    paginatedMaterialProperties: PaginatedMaterialProperties;
    paginatedMaterialRepairTypes: PaginatedMaterialRepairTypes;
    paginatedMaterialTypes: PaginatedMaterialTypes;
    paginatedMaterials: PaginatedMaterials;
    paginatedNavMaterials: PaginatedNavMaterial;
    paginatedNavOrderDetails: PaginatedNavOrderDetail;
    paginatedNavOrders: PaginatedNavOrder;
    paginatedNavProducts: PaginatedNavProduct;
    paginatedNavSalesInvoiceDetails: PaginatedNavSalesInvoiceDetail;
    paginatedNavSalesInvoices: PaginatedNavSalesInvoice;
    paginatedPaymentMethods: PaginatedPaymentMethods;
    paginatedPermissionApplications: PaginatedPermissionApplications;
    paginatedPermissionGroups: PaginatedPermissionGroups;
    paginatedPermissions: PaginatedPermissions;
    paginatedProvinces: PaginatedProvinces;
    paginatedRegions: PaginatedRegions;
    paginatedRentProducts: PaginatedSalesTeamGroups;
    paginatedRoles: PaginatedRoles;
    paginatedSalesPerformanceSalesProductTypeGroups: PaginatedSalesPerformanceSalesProductTypeGroups;
    paginatedSalesPerformanceSalesProductTypes: PaginatedSalesPerformanceSalesProductTypes;
    paginatedSalesPerformanceSalesTypes: PaginatedSalesPerformanceSalesTypes;
    paginatedSalesTeamGroups: PaginatedSalesTeamGroups;
    paginatedSalesTeamUnits: PaginatedSalesTeamUnits;
    paginatedSalesTeams: PaginatedSalesTeams;
    paginatedShippingMethods: PaginatedShippingMethods;
    paginatedTaxRates: PaginatedTaxRates;
    paginatedUsers: PaginatedUsers;
    paginatedVisitActions: PaginatedVisitActions;
    paginatedVisitCheckIns: PaginatedVisitCheckIns;
    paginatedVisitGoals: PaginatedVisitGoals;
    paginatedVisitMemos: PaginatedVisitMemos;
    paginatedVisitProperties: PaginatedVisitProperties;
    paginatedVisitPropertyTypes: PaginatedVisitPropertyTypes;
    paginatedVisitTimePeriods: PaginatedVisitTimePeriods;
    paginatedVisitTypes: PaginatedVisitTypes;
    paginatedVisits: PaginatedVisits;
    paymentMethod: PaymentMethod;
    paymentMethods: Array<PaymentMethod>;
    permission: Permission;
    permissionApplication: PermissionApplication;
    permissionApplications: Array<PermissionApplication>;
    permissionGroup: PermissionGroup;
    permissionGroups: Array<PermissionGroup>;
    permissions: Array<Permission>;
    provinces: Array<Province>;
    publicDistrict: District;
    publicProvince: Province;
    region: Region;
    regions: Array<Region>;
    rentProduct: RentProduct;
    rentProducts: Array<RentProduct>;
    role: Role;
    roles: Array<Role>;
    salesPerformanceSalesProductOverallView: SalesPerformanceSalesProductOverallView;
    salesPerformanceSalesProductType: SalesPerformanceSalesProductType;
    salesPerformanceSalesProductTypeGroup: SalesPerformanceSalesProductTypeGroup;
    salesPerformanceSalesProductTypeGroups: Array<SalesPerformanceSalesProductTypeGroup>;
    salesPerformanceSalesProductTypes: Array<SalesPerformanceSalesProductType>;
    salesPerformanceSalesProductUserForecastView: SalesPerformanceSalesProductUserForecastView;
    salesPerformanceSalesType: SalesPerformanceSalesType;
    salesPerformanceSalesTypes: Array<SalesPerformanceSalesType>;
    salesPerformanceSalesUserForecastView: SalesPerformanceSalesUserForecastView;
    salesProductTypeSalesAnalysis: SalesProductTypeSalesAnalysis;
    salesTeam: SalesTeam;
    salesTeamGroup: SalesTeamGroup;
    salesTeamGroups: Array<SalesTeamGroup>;
    salesTeamUnit: SalesTeamUnit;
    salesTeamUnits: Array<SalesTeamUnit>;
    salesTeams: Array<SalesTeam>;
    shippingMethod: ShippingMethod;
    shippingMethods: Array<ShippingMethod>;
    taxRate: TaxRate;
    taxRates: Array<TaxRate>;
    user: User;
    users: Array<User>;
    visit: Visit;
    visitAction: VisitAction;
    visitActions: Array<VisitAction>;
    visitAnalysis: VisitAnalysis;
    visitCheckIn: VisitCheckIn;
    visitCheckIns: Array<VisitCheckIn>;
    visitCustomerAnalysis: VisitCustomerAnalysis;
    visitCustomerLevelTypeAnalysis: VisitCustomerLevelTypeAnalysis;
    visitGoal: VisitGoal;
    visitGoalAnalysis: VisitGoalAnalysis;
    visitGoals: Array<VisitGoal>;
    visitMemo: VisitMemo;
    visitMemos: Array<VisitMemo>;
    visitProperties: Array<VisitProperty>;
    visitProperty: VisitProperty;
    visitPropertyType: VisitPropertyType;
    visitPropertyTypes: Array<VisitPropertyType>;
    visitTimePeriod: VisitTimePeriod;
    visitTimePeriods: Array<VisitTimePeriod>;
    visitType: VisitType;
    visitTypes: Array<VisitType>;
    visits: Array<Visit>;
};

export type QueryEcDashboardAnalysisDetailArgs = {
    filters: EcDashboardAnalysisSalesDetailFilterInput;
};

export type QueryEcInventoryDetailsArgs = {
    filter: EcInventoryDetailFilterInput;
};

export type QueryEcInventoryStatArgs = {
    filter: EcInventoryStatFilterInput;
};

export type QueryAppVersionArgs = {
    id: Scalars['ID'];
};

export type QueryAppVersionsArgs = {
    filters?: InputMaybe<AppVersionFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryBusinessArgs = {
    id: Scalars['ID'];
};

export type QueryBusinessLosingReasonArgs = {
    id: Scalars['ID'];
};

export type QueryBusinessLosingReasonsArgs = {
    filters?: InputMaybe<BusinessLosingReasonFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryBusinessMemoArgs = {
    id: Scalars['ID'];
};

export type QueryBusinessMemosArgs = {
    filters?: InputMaybe<BusinessMemoFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryBusinessOpportunitiesArgs = {
    filters?: InputMaybe<BusinessOpportunityFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryBusinessOpportunityArgs = {
    id: Scalars['ID'];
};

export type QueryBusinessProductArgs = {
    id: Scalars['ID'];
};

export type QueryBusinessProductTypeArgs = {
    id: Scalars['ID'];
};

export type QueryBusinessProductTypesArgs = {
    filters?: InputMaybe<BusinessProductTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryBusinessProductsArgs = {
    filters?: InputMaybe<BusinessProductFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryBusinessPropertiesArgs = {
    filters?: InputMaybe<BusinessPropertyFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryBusinessPropertyArgs = {
    id: Scalars['ID'];
};

export type QueryBusinessPropertyTypeArgs = {
    id: Scalars['ID'];
};

export type QueryBusinessPropertyTypesArgs = {
    filters?: InputMaybe<BusinessPropertyTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryBusinessSalesMethodArgs = {
    id: Scalars['ID'];
};

export type QueryBusinessSalesMethodsArgs = {
    filters?: InputMaybe<BusinessSalesMethodFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryBusinessStatusArgs = {
    id: Scalars['ID'];
};

export type QueryBusinessStatusesArgs = {
    filters?: InputMaybe<BusinessStatusFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryBusinessTypeArgs = {
    id: Scalars['ID'];
};

export type QueryBusinessTypesArgs = {
    filters?: InputMaybe<BusinessTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryBusinessesArgs = {
    filters?: InputMaybe<BusinessFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
    sorts?: InputMaybe<Array<BusinessSortInput>>;
};

export type QueryCitiesArgs = {
    filters?: InputMaybe<CityFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryCityArgs = {
    id: Scalars['ID'];
};

export type QueryCompaniesArgs = {
    filters?: InputMaybe<CompanyFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryCompanyArgs = {
    id: Scalars['ID'];
};

export type QueryCompetitorArgs = {
    id: Scalars['ID'];
};

export type QueryCompetitorsArgs = {
    filters?: InputMaybe<CompetitorFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryContactPeopleArgs = {
    filters?: InputMaybe<ContactPersonFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryContactPersonArgs = {
    id: Scalars['ID'];
};

export type QueryContactPersonDepartmentArgs = {
    id: Scalars['ID'];
};

export type QueryContactPersonDepartmentsArgs = {
    filters?: InputMaybe<ContactPersonDepartmentFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryCostCenterArgs = {
    id: Scalars['ID'];
};

export type QueryCostCentersArgs = {
    filters?: InputMaybe<CostCenterFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryCreditPeriodArgs = {
    id: Scalars['ID'];
};

export type QueryCreditPeriodsArgs = {
    filters?: InputMaybe<CreditPeriodFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryCurrenciesArgs = {
    filters?: InputMaybe<CurrencyFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryCurrencyArgs = {
    id: Scalars['ID'];
};

export type QueryCustomerArgs = {
    id: Scalars['ID'];
};

export type QueryCustomerAreaArgs = {
    id: Scalars['ID'];
};

export type QueryCustomerAreasArgs = {
    filters?: InputMaybe<CustomerAreaFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryCustomerAttachmentArgs = {
    id: Scalars['ID'];
};

export type QueryCustomerAttachmentsArgs = {
    filters?: InputMaybe<CustomerAttachmentFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryCustomerCategoriesArgs = {
    filters?: InputMaybe<CustomerCategoryFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryCustomerCategoryArgs = {
    id: Scalars['ID'];
};

export type QueryCustomerCertificateArgs = {
    id: Scalars['ID'];
};

export type QueryCustomerCertificateAttachmentArgs = {
    id: Scalars['ID'];
};

export type QueryCustomerCertificateAttachmentsArgs = {
    filters?: InputMaybe<CustomerCertificateAttachmentFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryCustomerCertificateTypeArgs = {
    id: Scalars['ID'];
};

export type QueryCustomerCertificateTypesArgs = {
    filters?: InputMaybe<CustomerCertificateTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryCustomerCertificatesArgs = {
    filters?: InputMaybe<CustomerCertificateFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryCustomerGroupArgs = {
    id: Scalars['ID'];
};

export type QueryCustomerGroupsArgs = {
    filters?: InputMaybe<CustomerGroupFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryCustomerLevelArgs = {
    id: Scalars['ID'];
};

export type QueryCustomerLevelFieldArgs = {
    id: Scalars['ID'];
};

export type QueryCustomerLevelFieldTypeArgs = {
    id: Scalars['ID'];
};

export type QueryCustomerLevelFieldTypesArgs = {
    filters?: InputMaybe<CustomerLevelFieldTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryCustomerLevelFieldsArgs = {
    filters?: InputMaybe<CustomerLevelFieldFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryCustomerLevelTypeArgs = {
    id: Scalars['ID'];
};

export type QueryCustomerLevelTypesArgs = {
    filters?: InputMaybe<CustomerLevelTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryCustomerLevelsArgs = {
    filters?: InputMaybe<CustomerLevelFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryCustomerPropertiesArgs = {
    filters?: InputMaybe<CustomerPropertyFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryCustomerPropertyArgs = {
    id: Scalars['ID'];
};

export type QueryCustomerPropertyTypeArgs = {
    id: Scalars['ID'];
};

export type QueryCustomerPropertyTypesArgs = {
    filters?: InputMaybe<CustomerPropertyTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryCustomerTypeArgs = {
    id: Scalars['ID'];
};

export type QueryCustomerTypesArgs = {
    filters?: InputMaybe<CustomerTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryCustomersArgs = {
    filters?: InputMaybe<CustomerFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryDepartmentArgs = {
    id: Scalars['ID'];
};

export type QueryDepartmentsArgs = {
    filters?: InputMaybe<DepartmentFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryDistrictsArgs = {
    filters?: InputMaybe<DistrictFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryExchangeRateArgs = {
    id: Scalars['ID'];
};

export type QueryExchangeRatesArgs = {
    filters?: InputMaybe<ExchangeRateFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeFixedAssetArgs = {
    id: Scalars['ID'];
};

export type QueryEyeFixedAssetItemArgs = {
    id: Scalars['ID'];
};

export type QueryEyeFixedAssetItemsArgs = {
    filters?: InputMaybe<EyeFixedAssetItemFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeFixedAssetRentalGoalArgs = {
    id: Scalars['ID'];
};

export type QueryEyeFixedAssetRentalGoalsArgs = {
    filters?: InputMaybe<EyeFixedAssetRentalGoalFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeFixedAssetRentalObjectArgs = {
    id: Scalars['ID'];
};

export type QueryEyeFixedAssetRentalObjectsArgs = {
    filters?: InputMaybe<EyeFixedAssetRentalObjectFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeFixedAssetRentalRecordArgs = {
    id: Scalars['ID'];
};

export type QueryEyeFixedAssetRentalRecordsArgs = {
    filters?: InputMaybe<EyeFixedAssetRentalRecordFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeFixedAssetTypeArgs = {
    id: Scalars['ID'];
};

export type QueryEyeFixedAssetTypesArgs = {
    filters?: InputMaybe<EyeFixedAssetTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeFixedAssetsArgs = {
    filters?: InputMaybe<EyeFixedAssetFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeProductArgs = {
    id: Scalars['ID'];
};

export type QueryEyeProductItemArgs = {
    id: Scalars['ID'];
};

export type QueryEyeProductItemTypeArgs = {
    id: Scalars['ID'];
};

export type QueryEyeProductItemTypesArgs = {
    filters?: InputMaybe<EyeProductItemTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeProductItemsArgs = {
    filters?: InputMaybe<EyeProductItemFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeProductTypeArgs = {
    id: Scalars['ID'];
};

export type QueryEyeProductTypesArgs = {
    filters?: InputMaybe<EyeProductTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeProductsArgs = {
    filters?: InputMaybe<EyeProductFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyePromotionArgs = {
    id: Scalars['ID'];
};

export type QueryEyePromotionAddonProductItemArgs = {
    id: Scalars['ID'];
};

export type QueryEyePromotionAddonProductItemsArgs = {
    filters?: InputMaybe<EyePromotionAddonProductItemFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyePromotionConditionArgs = {
    id: Scalars['ID'];
};

export type QueryEyePromotionConditionsArgs = {
    filters?: InputMaybe<EyePromotionConditionFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyePromotionsArgs = {
    filters?: InputMaybe<EyePromotionFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeQuotationOrderArgs = {
    id: Scalars['ID'];
};

export type QueryEyeQuotationOrderBusinessArgs = {
    id: Scalars['ID'];
};

export type QueryEyeQuotationOrderBusinessesArgs = {
    filters?: InputMaybe<EyeQuotationOrderBusinessFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeQuotationOrderProductArgs = {
    id: Scalars['ID'];
};

export type QueryEyeQuotationOrderProductItemArgs = {
    id: Scalars['ID'];
};

export type QueryEyeQuotationOrderProductItemsArgs = {
    filters?: InputMaybe<EyeQuotationOrderProductItemFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeQuotationOrderProductsArgs = {
    filters?: InputMaybe<EyeQuotationOrderProductFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeQuotationOrderPromotionArgs = {
    id: Scalars['ID'];
};

export type QueryEyeQuotationOrderPromotionsArgs = {
    filters?: InputMaybe<EyeQuotationOrderPromotionFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeQuotationOrderTypeArgs = {
    id: Scalars['ID'];
};

export type QueryEyeQuotationOrderTypesArgs = {
    filters?: InputMaybe<EyeQuotationOrderTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeQuotationOrderWarrantyItemArgs = {
    id: Scalars['ID'];
};

export type QueryEyeQuotationOrderWarrantyItemsArgs = {
    filters?: InputMaybe<EyeQuotationOrderWarrantyItemFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeQuotationOrdersArgs = {
    filters?: InputMaybe<EyeQuotationOrderFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeServiceOrderArgs = {
    id: Scalars['ID'];
};

export type QueryEyeServiceOrderAttachFileArgs = {
    id: Scalars['ID'];
};

export type QueryEyeServiceOrderAttachFilesArgs = {
    filters?: InputMaybe<EyeServiceOrderFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeServiceOrderExtensionArgs = {
    id: Scalars['ID'];
};

export type QueryEyeServiceOrderExtensionsArgs = {
    filters?: InputMaybe<EyeServiceOrderExtensionFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeServiceOrderItemArgs = {
    id: Scalars['ID'];
};

export type QueryEyeServiceOrderItemsArgs = {
    filters?: InputMaybe<EyeServiceOrderItemFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeServiceOrderTypeArgs = {
    id: Scalars['ID'];
};

export type QueryEyeServiceOrderTypesArgs = {
    filters?: InputMaybe<EyeServiceOrderTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeServiceOrderWorkDiariesArgs = {
    filters?: InputMaybe<EyeServiceOrderWorkDiaryFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeServiceOrderWorkDiaryArgs = {
    id: Scalars['ID'];
};

export type QueryEyeServiceOrderWorkDiaryAttachFileArgs = {
    id: Scalars['ID'];
};

export type QueryEyeServiceOrderWorkDiaryAttachFilesArgs = {
    filters?: InputMaybe<EyeServiceOrderWorkDiaryFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeServiceOrderWorkDiaryTypeArgs = {
    id: Scalars['ID'];
};

export type QueryEyeServiceOrderWorkDiaryTypesArgs = {
    filters?: InputMaybe<EyeServiceOrderWorkDiaryTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeServiceOrdersArgs = {
    filters?: InputMaybe<EyeServiceOrderFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeWarrantyContractArgs = {
    id: Scalars['ID'];
};

export type QueryEyeWarrantyContractItemArgs = {
    id: Scalars['ID'];
};

export type QueryEyeWarrantyContractItemsArgs = {
    filters?: InputMaybe<EyeWarrantyContractItemFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeWarrantyContractsArgs = {
    filters?: InputMaybe<EyeWarrantyContractFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryEyeWarrantyPeriodTypeArgs = {
    id: Scalars['ID'];
};

export type QueryEyeWarrantyPeriodTypesArgs = {
    filters?: InputMaybe<EyeWarrantyPeriodTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryMaterialArgs = {
    id: Scalars['ID'];
};

export type QueryMaterialExtraPropertiesArgs = {
    filters?: InputMaybe<MaterialExtraPropertyFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryMaterialExtraPropertyArgs = {
    id: Scalars['ID'];
};

export type QueryMaterialPropertiesArgs = {
    filters?: InputMaybe<MaterialPropertyFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryMaterialPropertyArgs = {
    id: Scalars['ID'];
};

export type QueryMaterialRepairTypeArgs = {
    id: Scalars['ID'];
};

export type QueryMaterialRepairTypesArgs = {
    filters?: InputMaybe<MaterialRepairTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryMaterialTypeArgs = {
    id: Scalars['ID'];
};

export type QueryMaterialTypesArgs = {
    filters?: InputMaybe<MaterialTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryMaterialsArgs = {
    filters?: InputMaybe<MaterialFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryNavMaterialArgs = {
    id: Scalars['ID'];
};

export type QueryNavMaterialsArgs = {
    filters?: InputMaybe<NavMaterialFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryNavOrderArgs = {
    id: Scalars['ID'];
};

export type QueryNavOrderDetailArgs = {
    id: Scalars['ID'];
};

export type QueryNavOrderDetailsArgs = {
    filters?: InputMaybe<NavOrderDetailFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryNavOrdersArgs = {
    filters?: InputMaybe<NavOrderFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryNavProductArgs = {
    id: Scalars['ID'];
};

export type QueryNavProductsArgs = {
    filters?: InputMaybe<NavProductFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryNavSalesInvoiceArgs = {
    id: Scalars['ID'];
};

export type QueryNavSalesInvoiceDetailArgs = {
    id: Scalars['ID'];
};

export type QueryNavSalesInvoiceDetailsArgs = {
    filters?: InputMaybe<NavSalesInvoiceDetailFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryNavSalesInvoicesArgs = {
    filters?: InputMaybe<NavSalesInvoiceFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedAppVersionsArgs = {
    filters?: InputMaybe<AppVersionFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedBusinessLosingReasonsArgs = {
    filters?: InputMaybe<BusinessLosingReasonFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedBusinessMemosArgs = {
    filters?: InputMaybe<BusinessMemoFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedBusinessOpportunitiesArgs = {
    filters?: InputMaybe<BusinessOpportunityFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedBusinessProductTypesArgs = {
    filters?: InputMaybe<BusinessProductTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedBusinessProductsArgs = {
    filters?: InputMaybe<BusinessProductFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedBusinessPropertiesArgs = {
    filters?: InputMaybe<BusinessPropertyFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedBusinessPropertyTypesArgs = {
    filters?: InputMaybe<BusinessPropertyTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedBusinessSalesMethodsArgs = {
    filters?: InputMaybe<BusinessSalesMethodFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedBusinessStatusesArgs = {
    filters?: InputMaybe<BusinessStatusFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedBusinessTypesArgs = {
    filters?: InputMaybe<BusinessTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedBusinessesArgs = {
    filters?: InputMaybe<BusinessFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
    sorts?: InputMaybe<Array<BusinessSortInput>>;
};

export type QueryPaginatedCitiesArgs = {
    filters?: InputMaybe<CityFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedCompaniesArgs = {
    filters?: InputMaybe<CompanyFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedCompetitorsArgs = {
    filters?: InputMaybe<CompetitorFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedContactPeopleArgs = {
    filters?: InputMaybe<ContactPersonFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedContactPersonDepartmentsArgs = {
    filters?: InputMaybe<ContactPersonDepartmentFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedCostCentersArgs = {
    filters?: InputMaybe<CostCenterFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedCreditPeriodsArgs = {
    filters?: InputMaybe<CreditPeriodFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedCurrenciesArgs = {
    filters?: InputMaybe<CurrencyFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedCustomerAreasArgs = {
    filters?: InputMaybe<CustomerAreaFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedCustomerAttachmentsArgs = {
    filters?: InputMaybe<CustomerAttachmentFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedCustomerCategoriesArgs = {
    filters?: InputMaybe<CustomerCategoryFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedCustomerCertificateAttachmentsArgs = {
    filters?: InputMaybe<CustomerCertificateAttachmentFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedCustomerCertificateTypesArgs = {
    filters?: InputMaybe<CustomerCertificateTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedCustomerCertificatesArgs = {
    filters?: InputMaybe<CustomerCertificateFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedCustomerGroupsArgs = {
    filters?: InputMaybe<CustomerGroupFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedCustomerLevelFieldTypesArgs = {
    filters?: InputMaybe<CustomerLevelFieldTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedCustomerLevelFieldsArgs = {
    filters?: InputMaybe<CustomerLevelFieldFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedCustomerLevelTypesArgs = {
    filters?: InputMaybe<CustomerLevelTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedCustomerLevelsArgs = {
    filters?: InputMaybe<CustomerLevelFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedCustomerPropertiesArgs = {
    filters?: InputMaybe<CustomerPropertyFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedCustomerPropertyTypesArgs = {
    filters?: InputMaybe<CustomerPropertyTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedCustomerTypesArgs = {
    filters?: InputMaybe<CustomerTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedCustomersArgs = {
    filters?: InputMaybe<CustomerFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedDepartmentsArgs = {
    filters?: InputMaybe<DepartmentFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedDistrictsArgs = {
    filters?: InputMaybe<DistrictFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedExchangeRatesArgs = {
    filters?: InputMaybe<ExchangeRateFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeFixedAssetItemsArgs = {
    filters?: InputMaybe<EyeFixedAssetItemFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeFixedAssetRentalGoalsArgs = {
    filters?: InputMaybe<EyeFixedAssetRentalGoalFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeFixedAssetRentalObjectsArgs = {
    filters?: InputMaybe<EyeFixedAssetRentalObjectFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeFixedAssetRentalRecordsArgs = {
    filters?: InputMaybe<EyeFixedAssetRentalRecordFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeFixedAssetTypesArgs = {
    filters?: InputMaybe<EyeFixedAssetTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeFixedAssetsArgs = {
    filters?: InputMaybe<EyeFixedAssetFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeProductItemTypesArgs = {
    filters?: InputMaybe<EyeProductItemTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeProductItemsArgs = {
    filters?: InputMaybe<EyeProductItemFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeProductTypesArgs = {
    filters?: InputMaybe<EyeProductTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeProductsArgs = {
    filters?: InputMaybe<EyeProductFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyePromotionAddonProductItemsArgs = {
    filters?: InputMaybe<EyePromotionAddonProductItemFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyePromotionConditionsArgs = {
    filters?: InputMaybe<EyePromotionConditionFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyePromotionsArgs = {
    filters?: InputMaybe<EyePromotionFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeQuotationOrderBusinessesArgs = {
    filters?: InputMaybe<EyeQuotationOrderBusinessFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeQuotationOrderProductItemsArgs = {
    filters?: InputMaybe<EyeQuotationOrderProductItemFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeQuotationOrderProductsArgs = {
    filters?: InputMaybe<EyeQuotationOrderProductFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeQuotationOrderPromotionsArgs = {
    filters?: InputMaybe<EyeQuotationOrderPromotionFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeQuotationOrderTypesArgs = {
    filters?: InputMaybe<EyeQuotationOrderTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeQuotationOrderWarrantyItemsArgs = {
    filters?: InputMaybe<EyeQuotationOrderWarrantyItemFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeQuotationOrdersArgs = {
    filters?: InputMaybe<EyeQuotationOrderFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeServiceOrderAttachFilesArgs = {
    filters?: InputMaybe<EyeServiceOrderFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeServiceOrderExtensionsArgs = {
    filters?: InputMaybe<EyeServiceOrderExtensionFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeServiceOrderItemsArgs = {
    filters?: InputMaybe<EyeServiceOrderItemFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeServiceOrderTypesArgs = {
    filters?: InputMaybe<EyeServiceOrderTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeServiceOrderWorkDiariesArgs = {
    filters?: InputMaybe<EyeServiceOrderWorkDiaryFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeServiceOrderWorkDiaryAttachFilesArgs = {
    filters?: InputMaybe<EyeServiceOrderWorkDiaryFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeServiceOrderWorkDiaryTypesArgs = {
    filters?: InputMaybe<EyeServiceOrderWorkDiaryTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeServiceOrdersArgs = {
    filters?: InputMaybe<EyeServiceOrderFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeWarrantyContractItemsArgs = {
    filters?: InputMaybe<EyeWarrantyContractItemFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeWarrantyContractsArgs = {
    filters?: InputMaybe<EyeWarrantyContractFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedEyeWarrantyPeriodTypesArgs = {
    filters?: InputMaybe<EyeWarrantyPeriodTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedMaterialExtraPropertiesArgs = {
    filters?: InputMaybe<MaterialExtraPropertyFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedMaterialPropertiesArgs = {
    filters?: InputMaybe<MaterialPropertyFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedMaterialRepairTypesArgs = {
    filters?: InputMaybe<MaterialRepairTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedMaterialTypesArgs = {
    filters?: InputMaybe<MaterialTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedMaterialsArgs = {
    filters?: InputMaybe<MaterialFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedNavMaterialsArgs = {
    filters?: InputMaybe<NavMaterialFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedNavOrderDetailsArgs = {
    filters?: InputMaybe<NavOrderDetailFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedNavOrdersArgs = {
    filters?: InputMaybe<NavOrderFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedNavProductsArgs = {
    filters?: InputMaybe<NavProductFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedNavSalesInvoiceDetailsArgs = {
    filters?: InputMaybe<NavSalesInvoiceDetailFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedNavSalesInvoicesArgs = {
    filters?: InputMaybe<NavSalesInvoiceFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedPaymentMethodsArgs = {
    filters?: InputMaybe<PaymentMethodFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedPermissionApplicationsArgs = {
    filters?: InputMaybe<PermissionApplicationFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedPermissionGroupsArgs = {
    filters?: InputMaybe<PermissionGroupFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedPermissionsArgs = {
    filters?: InputMaybe<PermissionFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedProvincesArgs = {
    filters?: InputMaybe<ProvinceFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedRegionsArgs = {
    filters?: InputMaybe<RegionFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedRentProductsArgs = {
    filters?: InputMaybe<SalesTeamGroupFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedRolesArgs = {
    filters?: InputMaybe<RoleFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedSalesPerformanceSalesProductTypeGroupsArgs = {
    filters?: InputMaybe<SalesPerformanceSalesProductTypeGroupFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedSalesPerformanceSalesProductTypesArgs = {
    filters?: InputMaybe<SalesPerformanceSalesProductTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedSalesPerformanceSalesTypesArgs = {
    filters?: InputMaybe<SalesPerformanceSalesTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedSalesTeamGroupsArgs = {
    filters?: InputMaybe<SalesTeamGroupFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedSalesTeamUnitsArgs = {
    filters?: InputMaybe<SalesTeamUnitFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedSalesTeamsArgs = {
    filters?: InputMaybe<SalesTeamFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedShippingMethodsArgs = {
    filters?: InputMaybe<ShippingMethodFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedTaxRatesArgs = {
    filters?: InputMaybe<TaxRateFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedUsersArgs = {
    filters?: InputMaybe<UserFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedVisitActionsArgs = {
    filters?: InputMaybe<VisitActionFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedVisitCheckInsArgs = {
    filters?: InputMaybe<VisitCheckInFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedVisitGoalsArgs = {
    filters?: InputMaybe<VisitGoalFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedVisitMemosArgs = {
    filters?: InputMaybe<VisitMemoFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedVisitPropertiesArgs = {
    filters?: InputMaybe<VisitPropertyFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedVisitPropertyTypesArgs = {
    filters?: InputMaybe<VisitPropertyTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedVisitTimePeriodsArgs = {
    filters?: InputMaybe<VisitTimePeriodFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedVisitTypesArgs = {
    filters?: InputMaybe<VisitTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaginatedVisitsArgs = {
    filters?: InputMaybe<VisitFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPaymentMethodArgs = {
    id: Scalars['ID'];
};

export type QueryPaymentMethodsArgs = {
    filters?: InputMaybe<PaymentMethodFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPermissionArgs = {
    id: Scalars['ID'];
};

export type QueryPermissionApplicationArgs = {
    id: Scalars['ID'];
};

export type QueryPermissionApplicationsArgs = {
    filters?: InputMaybe<PermissionApplicationFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPermissionGroupArgs = {
    id: Scalars['ID'];
};

export type QueryPermissionGroupsArgs = {
    filters?: InputMaybe<PermissionGroupFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPermissionsArgs = {
    filters?: InputMaybe<PermissionFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryProvincesArgs = {
    filters?: InputMaybe<ProvinceFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryPublicDistrictArgs = {
    id: Scalars['ID'];
};

export type QueryPublicProvinceArgs = {
    id: Scalars['ID'];
};

export type QueryRegionArgs = {
    id: Scalars['ID'];
};

export type QueryRegionsArgs = {
    filters?: InputMaybe<RegionFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryRentProductArgs = {
    id: Scalars['ID'];
};

export type QueryRentProductsArgs = {
    filters?: InputMaybe<SalesTeamGroupFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryRoleArgs = {
    id: Scalars['ID'];
};

export type QueryRolesArgs = {
    filters?: InputMaybe<RoleFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QuerySalesPerformanceSalesProductOverallViewArgs = {
    filters: SalesPerformanceSalesProductOverallViewFilterInput;
};

export type QuerySalesPerformanceSalesProductTypeArgs = {
    id: Scalars['ID'];
};

export type QuerySalesPerformanceSalesProductTypeGroupArgs = {
    id: Scalars['ID'];
};

export type QuerySalesPerformanceSalesProductTypeGroupsArgs = {
    filters?: InputMaybe<SalesPerformanceSalesProductTypeGroupFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QuerySalesPerformanceSalesProductTypesArgs = {
    filters?: InputMaybe<SalesPerformanceSalesProductTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QuerySalesPerformanceSalesProductUserForecastViewArgs = {
    filters: SalesPerformanceSalesProductUserForecastViewFilterInput;
};

export type QuerySalesPerformanceSalesTypeArgs = {
    id: Scalars['ID'];
};

export type QuerySalesPerformanceSalesTypesArgs = {
    filters?: InputMaybe<SalesPerformanceSalesTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QuerySalesPerformanceSalesUserForecastViewArgs = {
    filters: SalesPerformanceSalesUserForecastViewFilterInput;
};

export type QuerySalesTeamArgs = {
    id: Scalars['ID'];
};

export type QuerySalesTeamGroupArgs = {
    id: Scalars['ID'];
};

export type QuerySalesTeamGroupsArgs = {
    filters?: InputMaybe<SalesTeamGroupFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QuerySalesTeamUnitArgs = {
    id: Scalars['ID'];
};

export type QuerySalesTeamUnitsArgs = {
    filters?: InputMaybe<SalesTeamUnitFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QuerySalesTeamsArgs = {
    filters?: InputMaybe<SalesTeamFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryShippingMethodArgs = {
    id: Scalars['ID'];
};

export type QueryShippingMethodsArgs = {
    filters?: InputMaybe<ShippingMethodFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryTaxRateArgs = {
    id: Scalars['ID'];
};

export type QueryTaxRatesArgs = {
    filters?: InputMaybe<TaxRateFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryUserArgs = {
    id: Scalars['ID'];
};

export type QueryUsersArgs = {
    filters?: InputMaybe<UserFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryVisitArgs = {
    id: Scalars['ID'];
};

export type QueryVisitActionArgs = {
    id: Scalars['ID'];
};

export type QueryVisitActionsArgs = {
    filters?: InputMaybe<VisitActionFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryVisitCheckInArgs = {
    id: Scalars['ID'];
};

export type QueryVisitCheckInsArgs = {
    filters?: InputMaybe<VisitCheckInFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryVisitGoalArgs = {
    id: Scalars['ID'];
};

export type QueryVisitGoalsArgs = {
    filters?: InputMaybe<VisitGoalFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryVisitMemoArgs = {
    id: Scalars['ID'];
};

export type QueryVisitMemosArgs = {
    filters?: InputMaybe<VisitMemoFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryVisitPropertiesArgs = {
    filters?: InputMaybe<VisitPropertyFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryVisitPropertyArgs = {
    id: Scalars['ID'];
};

export type QueryVisitPropertyTypeArgs = {
    id: Scalars['ID'];
};

export type QueryVisitPropertyTypesArgs = {
    filters?: InputMaybe<VisitPropertyTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryVisitTimePeriodArgs = {
    id: Scalars['ID'];
};

export type QueryVisitTimePeriodsArgs = {
    filters?: InputMaybe<VisitTimePeriodFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryVisitTypeArgs = {
    id: Scalars['ID'];
};

export type QueryVisitTypesArgs = {
    filters?: InputMaybe<VisitTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type QueryVisitsArgs = {
    filters?: InputMaybe<VisitFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type Region = IRegion & {
    code?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 當地幣別 */
    localCurrency?: Maybe<Currency>;
    /** 當地幣別 */
    localCurrencyId: Scalars['ID'];
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroups: Array<SalesTeamGroup>;
    /** 稅率 */
    taxRates: Array<TaxRate>;
    updatedAt: Scalars['DateTime'];
};

export type RegionFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type RentProduct = IRentProduct & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 序號 */
    sn?: Maybe<Scalars['String']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type RentProductFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type RequestEyeQuotationOrderOfficialSealInput = {
    /** 報價單 */
    eyeQuotationOrderId: Scalars['ID'];
};

export type RequestEyeServiceOrderExtensionInput = {
    /** 展延日（起） */
    extendDate1: Scalars['String'];
    /** 展延日（迄） */
    extendDate2: Scalars['String'];
    /** 服務單 */
    eyeServiceOrderId: Scalars['ID'];
    /** 展延備註 */
    memo?: InputMaybe<Scalars['String']>;
};

export type Role = IRole & {
    /** 允許的公司ids */
    allowCompanyIds: Array<Scalars['Int']>;
    /** 允許的商品團隊ids */
    allowProductTeamIds: Array<Scalars['Int']>;
    /** 允許的區域ids */
    allowRegionIds: Array<Scalars['Int']>;
    /** 允許的業務團隊組織ids */
    allowSalesTeamGroupIds: Array<Scalars['Int']>;
    /** 允許的業務團隊ids */
    allowSalesTeamIds: Array<Scalars['Int']>;
    /** 允許的使用者ids */
    allowUserIds: Array<Scalars['Int']>;
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    /** 是否允許所有業務團隊(中国眼科) */
    isAllowAllChnEyeSalesTeam: Scalars['Boolean'];
    /** 是否允許所有公司(cn) */
    isAllowAllCnCompanies: Scalars['Boolean'];
    /** 是否允許所有區域 */
    isAllowAllRegions: Scalars['Boolean'];
    /** 是否允許所有業務團隊組織 */
    isAllowAllSalesTeamGroups: Scalars['Boolean'];
    /** 是否允許所有公司(tw) */
    isAllowAllTwCompanies: Scalars['Boolean'];
    /** 是否允許所有業務團隊(電子耳) */
    isAllowAllTwnClSalesTeam: Scalars['Boolean'];
    /** 是否允許所有業務團隊(科明／明睿) */
    isAllowAllTwnEcAndMrSalesTeam: Scalars['Boolean'];
    /** 是否允許所有業務團隊(臺灣眼科) */
    isAllowAllTwnEyeSalesTeam: Scalars['Boolean'];
    /** 是否只允許自己的資料 */
    isOnlyOwnUser: Scalars['Boolean'];
    /** 是否為系統管理者 */
    isSystemAdmin: Scalars['Boolean'];
    /** 名稱 */
    name: Scalars['String'];
};

export type RoleFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 用戶編號 */
    userId?: InputMaybe<Scalars['Float']>;
};

export type SalesPerformanceSalesProductOverallForecastItem = ISalesProductOverallForecastItem & {
    /** 金額 */
    amount: Scalars['Float'];
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 月份 */
    month: Scalars['Int'];
    /** 單頭 */
    overallForecastId: Scalars['ID'];
    /** 數量 */
    qty: Scalars['Float'];
    /** 單價 */
    unitPrice: Scalars['Float'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
};

export type SalesPerformanceSalesProductOverallForecastItemFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type SalesPerformanceSalesProductOverallGoalItem = ISalesProductOverallGoalItem & {
    /** 金額 */
    amount: Scalars['Float'];
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 月份 */
    month: Scalars['Int'];
    /** 單頭 */
    overallGoalId: Scalars['ID'];
    /** 數量 */
    qty: Scalars['Float'];
    /** 單價 */
    unitPrice: Scalars['Float'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
};

export type SalesPerformanceSalesProductOverallGoalItemFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type SalesPerformanceSalesProductOverallView = {
    /** 整體 */
    overall: SalesPerformanceSalesProductOverallViewOverall;
    /** 業務團隊組織 */
    salesTeamGroup?: Maybe<SalesTeamGroup>;
    /** 業務團隊組織 */
    salesTeamGroupId?: Maybe<Scalars['ID']>;
    /** 商品業績類型清單 */
    types: Array<SalesPerformanceSalesProductOverallViewType>;
    /** 年份 */
    year: Scalars['Int'];
};

export type SalesPerformanceSalesProductOverallViewBulkUpdateInput = {
    inputs: Array<SalesPerformanceSalesProductOverallViewUpdateInput>;
};

export type SalesPerformanceSalesProductOverallViewBulkUpdateResult = {
    /** 總體預測 */
    forecasts: Array<SalesPerformanceSalesProductOverallForecastItem>;
    /** 總體目標 */
    goals: Array<SalesPerformanceSalesProductOverallGoalItem>;
};

export type SalesPerformanceSalesProductOverallViewFilterInput = {
    /** 年份 */
    year: Scalars['Int'];
};

export type SalesPerformanceSalesProductOverallViewOverall = {
    /** 目前金額 */
    currentAmount: Scalars['Float'];
    /** 目前數量 */
    currentQty: Scalars['Float'];
    /** 目前單價 */
    currentUnitPrice: Scalars['Float'];
    /** 預測金額 */
    forecastAmount: Scalars['Float'];
    /** 預測數量 */
    forecastQty: Scalars['Float'];
    /** 預測單價 */
    forecastUnitPrice: Scalars['Float'];
    /** 目標金額 */
    goalAmount: Scalars['Float'];
    /** 目標金額達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    goalAmountAchievementRate?: Maybe<Scalars['Float']>;
    /** 目標數量 */
    goalQty: Scalars['Float'];
    /** 目標數量達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    goalQtyAchievementRate?: Maybe<Scalars['Float']>;
    /** 目標單價 */
    goalUnitPrice: Scalars['Float'];
    /** 目標單價達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    goalUnitPriceAchievementRate?: Maybe<Scalars['Float']>;
    /** 月份清單 */
    months: Array<SalesPerformanceSalesProductOverallViewOverallMonth>;
    /** 實際金額 */
    salesAmount: Scalars['Float'];
    /** 實際數量 */
    salesQty: Scalars['Float'];
    /** 實際單價 */
    salesUnitPrice: Scalars['Float'];
    /** 今年至今 目前金額 */
    ytdCurrentAmount: Scalars['Float'];
    /** 今年至今 目前數量 */
    ytdCurrentQty: Scalars['Float'];
    /** 今年至今 目前單價 */
    ytdCurrentUnitPrice: Scalars['Float'];
    /** 今年至今 目標金額 */
    ytdGoalAmount: Scalars['Float'];
    /** 今年至今 目標金額達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    ytdGoalAmountAchievementRate?: Maybe<Scalars['Float']>;
    /** 今年至今 目標數量 */
    ytdGoalQty: Scalars['Float'];
    /** 今年至今 目標數量達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    ytdGoalQtyAchievementRate?: Maybe<Scalars['Float']>;
    /** 今年至今 目標單價 */
    ytdGoalUnitPrice: Scalars['Float'];
    /** 今年至今 目標單價達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    ytdGoalUnitPriceAchievementRate?: Maybe<Scalars['Float']>;
};

export type SalesPerformanceSalesProductOverallViewOverallMonth = {
    /** 目前金額 */
    currentAmount: Scalars['Float'];
    /** 目前數量 */
    currentQty: Scalars['Float'];
    /** 目前單價 */
    currentUnitPrice: Scalars['Float'];
    /** 預測金額 */
    forecastAmount: Scalars['Float'];
    /** 預測數量 */
    forecastQty: Scalars['Float'];
    /** 預測單價 */
    forecastUnitPrice: Scalars['Float'];
    /** 目標金額 */
    goalAmount: Scalars['Float'];
    /** 目標金額達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    goalAmountAchievementRate?: Maybe<Scalars['Float']>;
    /** 目標數量 */
    goalQty: Scalars['Float'];
    /** 目標數量達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    goalQtyAchievementRate?: Maybe<Scalars['Float']>;
    /** 目標單價 */
    goalUnitPrice: Scalars['Float'];
    /** 目標單價達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    goalUnitPriceAchievementRate?: Maybe<Scalars['Float']>;
    /** 月份 */
    month: Scalars['Int'];
    /** 實際金額 */
    salesAmount: Scalars['Float'];
    /** 實際數量 */
    salesQty: Scalars['Float'];
    /** 實際單價 */
    salesUnitPrice: Scalars['Float'];
    /** 時態 */
    tense: EnumSalesProductOverallViewTense;
};

export type SalesPerformanceSalesProductOverallViewType = {
    /** 目前金額 */
    currentAmount: Scalars['Float'];
    /** 目前數量 */
    currentQty: Scalars['Float'];
    /** 目前單價 */
    currentUnitPrice: Scalars['Float'];
    /** 預測金額 */
    forecastAmount: Scalars['Float'];
    /** 預測數量 */
    forecastQty: Scalars['Float'];
    /** 預測單價 */
    forecastUnitPrice: Scalars['Float'];
    /** 目標金額 */
    goalAmount: Scalars['Float'];
    /** 目標金額達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    goalAmountAchievementRate?: Maybe<Scalars['Float']>;
    /** 目標數量 */
    goalQty: Scalars['Float'];
    /** 目標數量達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    goalQtyAchievementRate?: Maybe<Scalars['Float']>;
    /** 目標單價 */
    goalUnitPrice: Scalars['Float'];
    /** 目標單價達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    goalUnitPriceAchievementRate?: Maybe<Scalars['Float']>;
    /** 月份清單 */
    months: Array<SalesPerformanceSalesProductOverallViewTypeMonth>;
    /** 實際金額 */
    salesAmount: Scalars['Float'];
    /** 商品業績類型 */
    salesProductType?: Maybe<SalesPerformanceSalesProductType>;
    /** 商品業績類型 */
    salesProductTypeId: Scalars['ID'];
    /** 實際數量 */
    salesQty: Scalars['Float'];
    /** 實際單價 */
    salesUnitPrice: Scalars['Float'];
    /** 今年至今 目前金額 */
    ytdCurrentAmount: Scalars['Float'];
    /** 今年至今 目前數量 */
    ytdCurrentQty: Scalars['Float'];
    /** 今年至今 目前單價 */
    ytdCurrentUnitPrice: Scalars['Float'];
    /** 今年至今 目標金額 */
    ytdGoalAmount: Scalars['Float'];
    /** 今年至今 目標金額達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    ytdGoalAmountAchievementRate?: Maybe<Scalars['Float']>;
    /** 今年至今 目標數量 */
    ytdGoalQty: Scalars['Float'];
    /** 今年至今 目標數量達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    ytdGoalQtyAchievementRate?: Maybe<Scalars['Float']>;
    /** 今年至今 目標單價 */
    ytdGoalUnitPrice: Scalars['Float'];
    /** 今年至今 目標單價達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    ytdGoalUnitPriceAchievementRate?: Maybe<Scalars['Float']>;
};

export type SalesPerformanceSalesProductOverallViewTypeMonth = {
    /** 目前金額（過去時態：映射「實際數量」、現在、未來時態：映射「預測數量」） */
    currentAmount: Scalars['Float'];
    /** 目前數量（過去時態：映射「實際數量」、現在、未來時態：映射「預測數量」） */
    currentQty: Scalars['Float'];
    /** 目前單價（過去時態：映射「實際數量」、現在、未來時態：映射「預測數量」） */
    currentUnitPrice: Scalars['Float'];
    /** 預測金額 */
    forecastAmount: Scalars['Float'];
    /** 預測數量 */
    forecastQty: Scalars['Float'];
    /** 預測單價 */
    forecastUnitPrice: Scalars['Float'];
    /** 目標金額 */
    goalAmount: Scalars['Float'];
    /** 目標數量 */
    goalQty: Scalars['Float'];
    /** 目標單價 */
    goalUnitPrice: Scalars['Float'];
    /** 月份 */
    month: Scalars['Int'];
    /** 實際金額 */
    salesAmount: Scalars['Float'];
    /** 實際數量 */
    salesQty: Scalars['Float'];
    /** 實際單價 */
    salesUnitPrice: Scalars['Float'];
    /** 時態 */
    tense: EnumSalesProductOverallViewTense;
};

export type SalesPerformanceSalesProductOverallViewUpdateInput = {
    /** 預測數量 */
    forecastQty?: InputMaybe<Scalars['Float']>;
    /** 預測單價 */
    forecastUnitPrice?: InputMaybe<Scalars['Float']>;
    /** 目標金額 */
    goalAmount?: InputMaybe<Scalars['Float']>;
    /** 目標數量 */
    goalQty?: InputMaybe<Scalars['Float']>;
    /** 月份 */
    month: Scalars['Int'];
    /** 銷售商品類型 */
    salesProductTypeId: Scalars['ID'];
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 年份 */
    year: Scalars['Int'];
};

export type SalesPerformanceSalesProductType = ISalesProductType & {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 群組 */
    groupId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type SalesPerformanceSalesProductTypeFilterInput = {
    /** 群組 */
    groupIds?: InputMaybe<Array<Scalars['ID']>>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type SalesPerformanceSalesProductTypeGroup = ISalesProductTypeGroup & {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type SalesPerformanceSalesProductTypeGroupFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type SalesPerformanceSalesProductUserForecastItem = ISalesProductUserForecastItem & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 月份 */
    month: Scalars['Int'];
    /** 月初預測數量 */
    qtyEarlyMonth: Scalars['Int'];
    /** 月底預測數量 */
    qtyLateMonth: Scalars['Int'];
    /** Quota */
    quota: Scalars['Int'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 單頭 */
    userForecastId: Scalars['ID'];
};

export type SalesPerformanceSalesProductUserForecastItemFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type SalesPerformanceSalesProductUserForecastView = {
    /** 整體 */
    overall: SalesPerformanceSalesProductUserForecastViewOverall;
    /** 業務團隊組織 */
    salesTeamGroup?: Maybe<SalesTeamGroup>;
    /** 業務團隊組織 */
    salesTeamGroupId?: Maybe<Scalars['ID']>;
    /** 個人清單 */
    users: Array<SalesPerformanceSalesProductUserForecastViewUser>;
    /** 年份 */
    year: Scalars['Int'];
};

export type SalesPerformanceSalesProductUserForecastViewBulkUpdateInput = {
    inputs: Array<SalesPerformanceSalesProductUserForecastViewUpdateInput>;
};

export type SalesPerformanceSalesProductUserForecastViewFilterInput = {
    /** 月份（起） */
    month1?: InputMaybe<Scalars['Int']>;
    /** 月份（迄） */
    month2?: InputMaybe<Scalars['Int']>;
    /** 商品業績類型 */
    salesProductTypeIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 人員 */
    userIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 年份 */
    year: Scalars['Int'];
};

export type SalesPerformanceSalesProductUserForecastViewOverall = {
    /** 月份清單 */
    months: Array<SalesPerformanceSalesProductUserForecastViewOverallMonth>;
};

export type SalesPerformanceSalesProductUserForecastViewOverallMonth = {
    /** 月份 */
    month: Scalars['Int'];
    /** 商品業績類型清單 */
    types: Array<SalesPerformanceSalesProductUserForecastViewOverallMonthType>;
};

export type SalesPerformanceSalesProductUserForecastViewOverallMonthType = {
    /** 實際業績 */
    qtyActual: Scalars['Int'];
    /** 月初預測 */
    qtyEarlyMonth: Scalars['Int'];
    /** 月初預測準確率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（月初預測）為 0 時，百分比顯示為空值 */
    qtyEarlyMonthAccuracyRate?: Maybe<Scalars['Float']>;
    /** 月底預測 */
    qtyLateMonth: Scalars['Int'];
    /** 月底預測準確率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（月底預測）為 0 時，百分比顯示為空值 */
    qtyLateMonthAccuracyRate?: Maybe<Scalars['Float']>;
    /** Quota */
    quota: Scalars['Int'];
    /** Quota 達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（Quota）為 0 時，百分比顯示為空值 */
    quotaAchievementRate?: Maybe<Scalars['Float']>;
    /** 商品業績類型 */
    salesProductType?: Maybe<SalesPerformanceSalesProductType>;
    /** 商品業績類型 */
    salesProductTypeId: Scalars['ID'];
};

export type SalesPerformanceSalesProductUserForecastViewUpdateInput = {
    /** 月份 */
    month: Scalars['Int'];
    /** 月初預測 */
    qtyEarlyMonth: Scalars['Int'];
    /** 月底預測 */
    qtyLateMonth: Scalars['Int'];
    /** Quota */
    quota: Scalars['Int'];
    /** 商品業績類型 */
    salesProductTypeId: Scalars['ID'];
    /** 負責業務位置 */
    salesTeamUnitId: Scalars['ID'];
    /** 年份 */
    year: Scalars['Int'];
};

export type SalesPerformanceSalesProductUserForecastViewUser = {
    /** 月份清單 */
    months: Array<SalesPerformanceSalesProductUserForecastViewUserMonth>;
    /** 負責業務位置 */
    salesTeamUnit?: Maybe<SalesTeamUnit>;
    /** 負責業務位置 */
    salesTeamUnitId: Scalars['ID'];
};

export type SalesPerformanceSalesProductUserForecastViewUserMonth = {
    /** 月份 */
    month: Scalars['Int'];
    /** 商品業績類型清單 */
    types: Array<SalesPerformanceSalesProductUserForecastViewUserMonthType>;
};

export type SalesPerformanceSalesProductUserForecastViewUserMonthType = {
    /** 實際業績 */
    qtyActual: Scalars['Int'];
    /** 月初預測 */
    qtyEarlyMonth: Scalars['Int'];
    /** 月初預測準確率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（月初預測）為 0 時，百分比顯示為空值 */
    qtyEarlyMonthAccuracyRate?: Maybe<Scalars['Float']>;
    /** 月底預測 */
    qtyLateMonth: Scalars['Int'];
    /** 月底預測準確率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（月底預測）為 0 時，百分比顯示為空值 */
    qtyLateMonthAccuracyRate?: Maybe<Scalars['Float']>;
    /** Quota */
    quota: Scalars['Int'];
    /** Quota 達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（Quota）為 0 時，百分比顯示為空值 */
    quotaAchievementRate?: Maybe<Scalars['Float']>;
    /** 商品業績類型 */
    salesProductType?: Maybe<SalesPerformanceSalesProductType>;
    /** 商品業績類型 */
    salesProductTypeId: Scalars['ID'];
};

export type SalesPerformanceSalesType = ISalesType & {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type SalesPerformanceSalesTypeFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type SalesPerformanceSalesUserForecastItem = ISalesUserForecastItem & {
    /** 月初預測金額 */
    amountEarlyMonth: Scalars['Int'];
    /** 月底預測金額 */
    amountLateMonth: Scalars['Int'];
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 月份 */
    month: Scalars['Int'];
    /** Quota */
    quota: Scalars['Int'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 單頭 */
    userForecastId: Scalars['ID'];
};

export type SalesPerformanceSalesUserForecastItemFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type SalesPerformanceSalesUserForecastView = {
    /** 整體 */
    overall: SalesPerformanceSalesUserForecastViewOverall;
    /** 業務團隊組織 */
    salesTeamGroup?: Maybe<SalesTeamGroup>;
    /** 業務團隊組織 */
    salesTeamGroupId?: Maybe<Scalars['ID']>;
    /** 個人清單 */
    users: Array<SalesPerformanceSalesUserForecastViewUser>;
    /** 年份 */
    year: Scalars['Int'];
};

export type SalesPerformanceSalesUserForecastViewBulkUpdateInput = {
    inputs: Array<SalesPerformanceSalesUserForecastViewUpdateInput>;
};

export type SalesPerformanceSalesUserForecastViewFilterInput = {
    /** 月份（起） */
    month1?: InputMaybe<Scalars['Int']>;
    /** 月份（迄） */
    month2?: InputMaybe<Scalars['Int']>;
    /** 銷售業績類型 */
    salesTypeIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 人員 */
    userIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 年份 */
    year: Scalars['Int'];
};

export type SalesPerformanceSalesUserForecastViewOverall = {
    /** 月份清單 */
    months: Array<SalesPerformanceSalesUserForecastViewOverallMonth>;
};

export type SalesPerformanceSalesUserForecastViewOverallMonth = {
    /** 月份 */
    month: Scalars['Int'];
    /** 銷售業績類型清單 */
    types: Array<SalesPerformanceSalesUserForecastViewOverallMonthType>;
};

export type SalesPerformanceSalesUserForecastViewOverallMonthType = {
    /** 實際業績 */
    amountActual: Scalars['Int'];
    /** 月初預測 */
    amountEarlyMonth: Scalars['Int'];
    /** 月初預測準確率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（月初預測）為 0 時，百分比顯示為空值 */
    amountEarlyMonthAccuracyRate?: Maybe<Scalars['Float']>;
    /** 月底預測 */
    amountLateMonth: Scalars['Int'];
    /** 月底預測準確率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（月底預測）為 0 時，百分比顯示為空值 */
    amountLateMonthAccuracyRate?: Maybe<Scalars['Float']>;
    /** Quota */
    quota: Scalars['Int'];
    /** Quota 達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（Quota）為 0 時，百分比顯示為空值 */
    quotaAchievementRate?: Maybe<Scalars['Float']>;
    /** 銷售業績類型 */
    salesType?: Maybe<SalesPerformanceSalesType>;
    /** 銷售業績類型 */
    salesTypeId: Scalars['ID'];
};

export type SalesPerformanceSalesUserForecastViewUpdateInput = {
    /** 月初預測 */
    amountEarlyMonth: Scalars['Int'];
    /** 月底預測 */
    amountLateMonth: Scalars['Int'];
    /** 月份 */
    month: Scalars['Int'];
    /** Quota */
    quota: Scalars['Int'];
    /** 負責業務位置 */
    salesTeamUnitId: Scalars['ID'];
    /** 銷售業績類型 */
    salesTypeId: Scalars['ID'];
    /** 年份 */
    year: Scalars['Int'];
};

export type SalesPerformanceSalesUserForecastViewUser = {
    /** 月份清單 */
    months: Array<SalesPerformanceSalesUserForecastViewUserMonth>;
    /** 負責業務位置 */
    salesTeamUnit?: Maybe<SalesTeamUnit>;
    /** 負責業務位置 */
    salesTeamUnitId: Scalars['ID'];
};

export type SalesPerformanceSalesUserForecastViewUserMonth = {
    /** 月份 */
    month: Scalars['Int'];
    /** 銷售業績類型清單 */
    types: Array<SalesPerformanceSalesUserForecastViewUserMonthType>;
};

export type SalesPerformanceSalesUserForecastViewUserMonthType = {
    /** 實際業績 */
    amountActual: Scalars['Int'];
    /** 月初預測 */
    amountEarlyMonth: Scalars['Int'];
    /** 月初預測準確率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（月初預測）為 0 時，百分比顯示為空值 */
    amountEarlyMonthAccuracyRate?: Maybe<Scalars['Float']>;
    /** 月底預測 */
    amountLateMonth: Scalars['Int'];
    /** 月底預測準確率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（月底預測）為 0 時，百分比顯示為空值 */
    amountLateMonthAccuracyRate?: Maybe<Scalars['Float']>;
    /** Quota */
    quota: Scalars['Int'];
    /** Quota 達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（Quota）為 0 時，百分比顯示為空值 */
    quotaAchievementRate?: Maybe<Scalars['Float']>;
    /** 銷售業績類型 */
    salesType?: Maybe<SalesPerformanceSalesType>;
    /** 銷售業績類型 */
    salesTypeId: Scalars['ID'];
};

export type SalesProductTypeSalesAnalysis = {
    /** 銷售統計 */
    salesStat: SalesProductTypeSalesAnalysisSalesStat;
};

export type SalesProductTypeSalesAnalysisSalesStatArgs = {
    filters?: InputMaybe<SalesProductTypeSalesAnalysisSalesStatFilterInput>;
};

export type SalesProductTypeSalesAnalysisSalesStat = {
    /** 日期區間 */
    dateRange: SalesProductTypeSalesAnalysisSalesStatDateRange;
    /** 細項統計 */
    details: Array<SalesProductTypeSalesAnalysisSalesStatDetail>;
    salesTeamGroup?: Maybe<SalesTeamGroup>;
    /** 業務團隊組織 */
    salesTeamGroupId?: Maybe<Scalars['ID']>;
};

export type SalesProductTypeSalesAnalysisSalesStatDateRange = {
    /** 當期（起） */
    currentDate1: Scalars['String'];
    /** 當期（迄） */
    currentDate2: Scalars['String'];
    /** 去年至去年當天（起） */
    lastYearYtdDate1: Scalars['String'];
    /** 去年至去年當天（迄） */
    lastYearYtdDate2: Scalars['String'];
    /** 前期（起） */
    momDate1: Scalars['String'];
    /** 前期（迄） */
    momDate2: Scalars['String'];
    /** 同期（起） */
    yoyDate1: Scalars['String'];
    /** 同期（迄） */
    yoyDate2: Scalars['String'];
    /** 今年至今（起） */
    ytdDate1: Scalars['String'];
    /** 今年至今（迄） */
    ytdDate2: Scalars['String'];
};

export type SalesProductTypeSalesAnalysisSalesStatDetail = {
    salesProductTypeGroup?: Maybe<SalesPerformanceSalesProductTypeGroup>;
    /** 商品業績類型群組 */
    salesProductTypeGroupId: Scalars['ID'];
    /** 商品業績類型清單 */
    types: Array<SalesProductTypeSalesAnalysisSalesStatDetailType>;
};

export type SalesProductTypeSalesAnalysisSalesStatDetailType = {
    /** 當期 銷售金額 */
    amountCurrent: Scalars['Int'];
    /** 預測目標金額 */
    amountForecastGoal: Scalars['Float'];
    /** 銷售目標金額 */
    amountGoal: Scalars['Float'];
    /** 去年至去年當天 銷售金額 */
    amountLastYearYtd: Scalars['Int'];
    /** 去年至去年當天 銷售金額成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    amountLastYearYtdGrowthRate?: Maybe<Scalars['Float']>;
    /** 前期 (MOM) 銷售金額 */
    amountMom: Scalars['Int'];
    /** 前期 (MOM) 銷售金額成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    amountMomGrowthRate?: Maybe<Scalars['Float']>;
    /** 同期 (YOY) 銷售金額 */
    amountYoy: Scalars['Int'];
    /** 同期 (YOY) 銷售金額成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    amountYoyGrowthRate?: Maybe<Scalars['Float']>;
    /** 今年至今 (YTD) 銷售金額 */
    amountYtd: Scalars['Int'];
    /** 當期 銷售數量 */
    qtyCurrent: Scalars['Int'];
    /** 預測目標數量 */
    qtyForecastGoal: Scalars['Float'];
    /** 銷售目標數量 */
    qtyGoal: Scalars['Float'];
    /** 去年至去年當天 銷售數量 */
    qtyLastYearYtd: Scalars['Int'];
    /** 去年至去年當天 銷售數量成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    qtyLastYearYtdGrowthRate?: Maybe<Scalars['Float']>;
    /** 前期 (MOM) 銷售數量 */
    qtyMom: Scalars['Int'];
    /** 前期 (MOM) 銷售數量成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    qtyMomGrowthRate?: Maybe<Scalars['Float']>;
    /** 同期 (YOY) 銷售數量 */
    qtyYoy: Scalars['Int'];
    /** 同期 (YOY) 銷售數量成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    qtyYoyGrowthRate?: Maybe<Scalars['Float']>;
    /** 今年至今 (YTD) 銷售數量 */
    qtyYtd: Scalars['Int'];
    salesProductType?: Maybe<SalesPerformanceSalesProductType>;
    /** 商品業績類型 */
    salesProductTypeId: Scalars['ID'];
};

export type SalesProductTypeSalesAnalysisSalesStatFilterInput = {
    /** 日期（起） */
    date1: Scalars['String'];
    /** 日期（迄） */
    date2: Scalars['String'];
};

export type SalesTeam = ISalesTeam & {
    /** 商機商品 */
    businessProducts: Array<BusinessProduct>;
    /** 子層團隊 */
    children: Array<SalesTeam>;
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 組織 */
    group?: Maybe<SalesTeamGroup>;
    /** 組織 */
    groupId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    /** 主管 */
    manager?: Maybe<User>;
    /** 主管 */
    managerId?: Maybe<Scalars['ID']>;
    /** 名稱 */
    name: Scalars['String'];
    /** 該層底下（包含）的所有業務團隊位置 */
    nestedUnits: Array<SalesTeamUnit>;
    /** 該層底下（包含）的所有業務成員 */
    nestedUsers: Array<User>;
    /** 上層團隊 */
    parent?: Maybe<SalesTeam>;
    /** 上層團隊 */
    parentId?: Maybe<Scalars['ID']>;
    /** 需要為客戶指定主要負責業務 */
    requiredForPrimaryCustomer: Scalars['Boolean'];
    /** 頂層團隊 */
    top?: Maybe<SalesTeam>;
    /** 業務團隊位置 */
    units: Array<SalesTeamUnit>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 業務成員 */
    users: Array<User>;
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type SalesTeamFilterInput = {
    groupCode?: InputMaybe<EnumSalesTeamGroupCode>;
    groupId?: InputMaybe<Scalars['ID']>;
    groupIds?: InputMaybe<Array<Scalars['ID']>>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 是否查詢業務團隊的根節點（root） */
    isRoot?: InputMaybe<Scalars['Boolean']>;
    /** 上層團隊 */
    parentId?: InputMaybe<Scalars['ID']>;
    /** 需要為客戶指定主要負責業務 */
    requiredForPrimaryCustomer?: InputMaybe<Scalars['Boolean']>;
    userId?: InputMaybe<Scalars['ID']>;
};

export type SalesTeamGroup = ISalesTeamGroup & {
    /** 編號 */
    code?: Maybe<EnumSalesTeamGroupCode>;
    /** 公司 */
    company?: Maybe<Company>;
    /** 公司 */
    companyId?: Maybe<Scalars['ID']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 主管 */
    directorId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 區域 */
    region?: Maybe<Region>;
    /** 區域 */
    regionId?: Maybe<Scalars['ID']>;
    /** 業務團隊 */
    teams: Array<SalesTeam>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 業務成員 */
    users: Array<User>;
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type SalesTeamGroupFilterInput = {
    code?: InputMaybe<Scalars['String']>;
    companyId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    regionId?: InputMaybe<Scalars['ID']>;
    regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type SalesTeamUnit = ISalesTeamUnit & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊 */
    salesTeam?: Maybe<SalesTeam>;
    /** 業務團隊 */
    salesTeamId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 負責業務 */
    user?: Maybe<User>;
    /** 負責業務 */
    userId?: Maybe<Scalars['ID']>;
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type SalesTeamUnitFilterInput = {
    /** 客戶 */
    customerId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 名稱 */
    name?: InputMaybe<Scalars['String']>;
    /** 業務團隊（複選） */
    salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 負責業務（複選） */
    userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type SayHelloSubscription = {
    message: Scalars['String'];
};

export type ShippingMethod = IShippingMethod & {
    code: Scalars['String'];
    companyId: Scalars['ID'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    isActive: Scalars['Boolean'];
    name: Scalars['String'];
    /** 區域 */
    region: Region;
    regionId: Scalars['ID'];
    updatedAt: Scalars['DateTime'];
    viewOrder?: Maybe<Scalars['Int']>;
};

export type ShippingMethodFilterInput = {
    companyId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    isActive?: InputMaybe<Scalars['Boolean']>;
    regionId?: InputMaybe<Scalars['ID']>;
};

export type Subscription = {
    sayHelloSubscription: SayHelloSubscription;
};

export type SubscriptionSayHelloSubscriptionArgs = {
    input: PlaygroundSayHelloSubscriptionInput;
};

export type TaxRate = ITaxRate & {
    createdAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    rate: Scalars['Float'];
    /** 區域 */
    region: Region;
    regionId: Scalars['ID'];
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TaxRateFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    regionId?: InputMaybe<Scalars['ID']>;
    regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type User = IUser & {
    active?: Maybe<Scalars['String']>;
    /** 驗證方式(1. LDAP, 2. password) */
    authType: Scalars['Int'];
    code?: Maybe<Scalars['String']>;
    companyId?: Maybe<Scalars['ID']>;
    createdAt: Scalars['DateTime'];
    departmentId?: Maybe<Scalars['ID']>;
    /** 部門 */
    dept?: Maybe<Department>;
    email?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    /** 在職狀態 */
    isActive: Scalars['Boolean'];
    /** 聽力師 */
    isAudiologist: Scalars['Boolean'];
    /** 助聽器工程師 */
    isHearingAidEngineer: Scalars['Boolean'];
    isLocked: Scalars['Boolean'];
    /** 外包人員 */
    isOutsourcing: Scalars['Boolean'];
    /** 使用系統，最後選擇的門市 */
    lastSelectStoreId?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    regionId: Scalars['ID'];
    /** 業務團隊組織 */
    salesTeamGroups?: Maybe<Array<SalesTeamGroup>>;
    /** 業務團隊 */
    salesTeams: Array<SalesTeam>;
    storeId?: Maybe<Scalars['ID']>;
    updatedAt: Scalars['DateTime'];
};

export type UserFilterInput = {
    code?: InputMaybe<Scalars['String']>;
    deptIds?: InputMaybe<Array<Scalars['ID']>>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 是否在職 */
    isActive?: InputMaybe<Scalars['Boolean']>;
    /** 關鍵字（同時查詢「名稱、編號、部門名稱、部門編號」欄位） */
    keyword?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    salesTeamId?: InputMaybe<Scalars['ID']>;
};

export type UserPermission = {
    /** 允許的公司 */
    allowCompanyIds: Array<Scalars['ID']>;
    /** 允許的區域 */
    allowRegionIds: Array<Scalars['ID']>;
    /** 允許的業務團隊組織 */
    allowSalesTeamGroupIds: Array<Scalars['ID']>;
    /** 允許的業務團隊 */
    allowSalesTeamIds: Array<Scalars['ID']>;
    /** 允許的員工（IF Array.prototype.length === 0 THEN 查詢所有 ELSE 允許的員工） */
    allowUserIds: Array<Scalars['ID']>;
    /** 權限編號 */
    codes: Array<Scalars['String']>;
};

export type Visit = IVisit & {
    /** 行為 */
    action?: Maybe<VisitAction>;
    /** 行為內容 */
    actionContent?: Maybe<Scalars['String']>;
    /** 行為 */
    actionId?: Maybe<Scalars['ID']>;
    /** 协访主管 */
    assistedVisitSupervisor?: Maybe<User>;
    /** 協訪主管 */
    assistedVisitSupervisorId?: Maybe<Scalars['ID']>;
    /** 商機 */
    business?: Maybe<Business>;
    /** 商機 */
    businessId?: Maybe<Scalars['ID']>;
    /** 打卡記錄 */
    checkIns: Array<VisitCheckIn>;
    /** 內容 */
    content?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUser?: Maybe<User>;
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    /** 客戶 */
    customer?: Maybe<Customer>;
    /** 客戶 */
    customerId?: Maybe<Scalars['ID']>;
    /** 預估刀量 */
    estimatedSurgeryCount?: Maybe<Scalars['String']>;
    /** 調整格式後的屬性 */
    formattedProperties: Array<FormattedVisitProperties>;
    /** 目標 */
    goals: Array<VisitGoal>;
    /** 是否主管協訪 */
    hasAssistedVisitSupervisor: Scalars['Boolean'];
    id: Scalars['ID'];
    /** 涉及商品 */
    mapMentionProducts: Array<VisitsToMentionProduct>;
    /** 借用固定資產 */
    mapRentFixedAssets: Array<VisitsToRentFixedAsset>;
    /**
     * 租借商品
     * @deprecated 改用 mapRentFixedAssets
     */
    mapRentProducts: Array<VisitsToRentProduct>;
    /** 備註 */
    memos: Array<VisitMemo>;
    /** 主要負責聯絡人 */
    primaryContactPeople: Array<ContactPerson>;
    /** 主要負責業務 */
    primaryUser?: Maybe<User>;
    /** 主要負責業務（棄用；改用 sales_team_unit_id ） */
    primaryUserId?: Maybe<Scalars['ID']>;
    /** 優先順序 */
    priorityOrder?: Maybe<Scalars['Int']>;
    /** 屬性 */
    properties: Array<VisitProperty>;
    /** 業務團隊 */
    salesTeam?: Maybe<SalesTeam>;
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 負責業務團隊 */
    salesTeamId?: Maybe<Scalars['ID']>;
    /** 业务团队位置 */
    salesTeamUnit?: Maybe<SalesTeamUnit>;
    /** 業務團隊單位 */
    salesTeamUnitId?: Maybe<Scalars['ID']>;
    /** 狀態 */
    status: EnumVisitStatus;
    /** 實際刀量 */
    surgeryCount?: Maybe<Scalars['String']>;
    /** 時段 */
    timePeriods: Array<VisitTimePeriod>;
    /** 標題 */
    title?: Maybe<Scalars['String']>;
    /** 類型 */
    type?: Maybe<VisitType>;
    /** 類型 */
    typeId?: Maybe<Scalars['ID']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUser?: Maybe<User>;
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 拜訪地址 */
    visitAddress?: Maybe<Scalars['String']>;
    /** 拜訪日期 */
    visitDate: Scalars['Date'];
};

export type VisitAction = IVisitAction & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type VisitActionCreateInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitActionFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type VisitActionInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitActionUpdateInput = {
    id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitAnalysis = {
    /** 涉及商品排名 */
    mentionProductRank: Array<VisitAnalysisMentionProductRank>;
    /** 未拜訪過的商機排名 */
    unvisitedBusinessRank: Array<VisitAnalysisBusinessRank>;
    /** 拜訪統計 */
    visitStat: VisitAnalysisVisitStat;
    /** 有拜訪過的商機排名 */
    visitedBusinessRank: Array<VisitAnalysisBusinessRank>;
    /** 一週拜訪統計 */
    weeklyVisitStat: VisitAnalysisWeeklyVisitStat;
};

export type VisitAnalysisMentionProductRankArgs = {
    filters?: InputMaybe<VisitAnalysisMentionProductRankFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type VisitAnalysisUnvisitedBusinessRankArgs = {
    filters?: InputMaybe<VisitAnalysisBusinessRankFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type VisitAnalysisVisitStatArgs = {
    filters?: InputMaybe<VisitAnalysisVisitStatFilterInput>;
};

export type VisitAnalysisVisitedBusinessRankArgs = {
    filters?: InputMaybe<VisitAnalysisBusinessRankFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type VisitAnalysisWeeklyVisitStatArgs = {
    filters?: InputMaybe<VisitAnalysisWeeklyVisitStatFilterInput>;
};

export type VisitAnalysisBusinessRank = {
    /** 商機數量 */
    businessCount: Scalars['Int'];
    /** 客户 */
    customer: Customer;
    /** 客戶 */
    customerId: Scalars['ID'];
    /** 排名 */
    rank: Scalars['Int'];
    /** 流水號 */
    seq: Scalars['Int'];
    /** 預算金額 */
    totalBudgetAmount: Scalars['Float'];
    /** 拜訪次數 */
    visitCount: Scalars['Int'];
};

export type VisitAnalysisBusinessRankFilterInput = {
    /** 行為（複選） */
    actionIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 商機 */
    businessId?: InputMaybe<Scalars['ID']>;
    /** 商機訂單編號 */
    businessOrderCode?: InputMaybe<Scalars['String']>;
    /** 客戶分區 */
    customerAreaId?: InputMaybe<Scalars['ID']>;
    /** 客戶編號 */
    customerCode?: InputMaybe<Scalars['String']>;
    /** 客戶 */
    customerId?: InputMaybe<Scalars['ID']>;
    /** 客戶醫事機構代碼 */
    customerMedicalCode?: InputMaybe<Scalars['String']>;
    /** 客戶名稱 */
    customerName?: InputMaybe<Scalars['String']>;
    /** 目標（複選） */
    goalIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 是否有商機資料 */
    hasBusiness?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 涉及商品（複選） */
    mentionProductIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 主要負責聯絡人（複選） */
    primaryContactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 主要負責業務 */
    primaryUserId?: InputMaybe<Scalars['ID']>;
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 屬性（複選） */
    propertyIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 屬性類型（複選） */
    propertyTypeIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 借用固定資產（複選） */
    rentFixedAssetIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 租借商品（複選） */
    rentProductIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 業務團隊組織 */
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    /** 负责业务位置 */
    salesTeamUnitId?: InputMaybe<Scalars['ID']>;
    /** 负责业务位置（複選） */
    salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 狀態（複選） */
    statuses?: InputMaybe<Array<EnumVisitStatus>>;
    /** 時段（複選） */
    timePeriodIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 類型（複選） */
    typeIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 拜訪日期（起） */
    visitDate1?: InputMaybe<Scalars['String']>;
    /** 拜訪日期（迄） */
    visitDate2?: InputMaybe<Scalars['String']>;
};

export type VisitAnalysisMentionProductRank = {
    /** 涉及商品 */
    mentionProduct: BusinessProduct;
    /** 涉及商品 */
    mentionProductId: Scalars['ID'];
    /** 排名 */
    rank: Scalars['Int'];
    /** 流水號 */
    seq: Scalars['Int'];
    /** 拜訪次數 */
    visitCount: Scalars['Int'];
};

export type VisitAnalysisMentionProductRankFilterInput = {
    /** 行為（複選） */
    actionIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 商機 */
    businessId?: InputMaybe<Scalars['ID']>;
    /** 商機訂單編號 */
    businessOrderCode?: InputMaybe<Scalars['String']>;
    /** 客戶分區 */
    customerAreaId?: InputMaybe<Scalars['ID']>;
    /** 客戶編號 */
    customerCode?: InputMaybe<Scalars['String']>;
    /** 客戶 */
    customerId?: InputMaybe<Scalars['ID']>;
    /** 客戶醫事機構代碼 */
    customerMedicalCode?: InputMaybe<Scalars['String']>;
    /** 客戶名稱 */
    customerName?: InputMaybe<Scalars['String']>;
    /** 目標（複選） */
    goalIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 是否有商機資料 */
    hasBusiness?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 涉及商品（複選） */
    mentionProductIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 主要負責聯絡人（複選） */
    primaryContactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 主要負責業務 */
    primaryUserId?: InputMaybe<Scalars['ID']>;
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 屬性（複選） */
    propertyIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 屬性類型（複選） */
    propertyTypeIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 借用固定資產（複選） */
    rentFixedAssetIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 租借商品（複選） */
    rentProductIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 業務團隊組織 */
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    /** 负责业务位置 */
    salesTeamUnitId?: InputMaybe<Scalars['ID']>;
    /** 负责业务位置（複選） */
    salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 狀態（複選） */
    statuses?: InputMaybe<Array<EnumVisitStatus>>;
    /** 時段（複選） */
    timePeriodIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 類型（複選） */
    typeIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 拜訪日期（起） */
    visitDate1?: InputMaybe<Scalars['String']>;
    /** 拜訪日期（迄） */
    visitDate2?: InputMaybe<Scalars['String']>;
};

export type VisitAnalysisVisitStat = {
    /** 細項統計 */
    details: Array<VisitAnalysisVisitStat_Detail>;
    /** 總體統計 */
    overall: VisitAnalysisVisitStat_Overall;
};

export type VisitAnalysisVisitStatFilterInput = {
    /** 行為（複選） */
    actionIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 商機_預計結案日期（起） */
    businessExpectedClosedDate1?: InputMaybe<Scalars['String']>;
    /** 商機_預計結案日期（迄） */
    businessExpectedClosedDate2?: InputMaybe<Scalars['String']>;
    /** 商機 */
    businessId?: InputMaybe<Scalars['ID']>;
    /** 商機訂單編號 */
    businessOrderCode?: InputMaybe<Scalars['String']>;
    /** 客戶分區 */
    customerAreaId?: InputMaybe<Scalars['ID']>;
    /** 客戶編號 */
    customerCode?: InputMaybe<Scalars['String']>;
    /** 客戶 */
    customerId?: InputMaybe<Scalars['ID']>;
    /** 客戶醫事機構代碼 */
    customerMedicalCode?: InputMaybe<Scalars['String']>;
    /** 客戶名稱 */
    customerName?: InputMaybe<Scalars['String']>;
    /** 目標（複選） */
    goalIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 是否有商機資料 */
    hasBusiness?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 涉及商品（複選） */
    mentionProductIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 主要負責聯絡人（複選） */
    primaryContactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 主要負責業務 */
    primaryUserId?: InputMaybe<Scalars['ID']>;
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 屬性（複選） */
    propertyIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 屬性類型（複選） */
    propertyTypeIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 借用固定資產（複選） */
    rentFixedAssetIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 租借商品（複選） */
    rentProductIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 業務團隊組織 */
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    /** 负责业务位置 */
    salesTeamUnitId?: InputMaybe<Scalars['ID']>;
    /** 负责业务位置（複選） */
    salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 狀態（複選） */
    statuses?: InputMaybe<Array<EnumVisitStatus>>;
    /** 時段（複選） */
    timePeriodIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 類型（複選） */
    typeIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 拜訪日期（起） */
    visitDate1?: InputMaybe<Scalars['String']>;
    /** 拜訪日期（迄） */
    visitDate2?: InputMaybe<Scalars['String']>;
};

export type VisitAnalysisVisitStat_Detail = {
    /** 商機數量 */
    businessCount: Scalars['Int'];
    /** 在所有跟進中的商機佔有率 */
    businessCountPercentInProgress?: Maybe<Scalars['Float']>;
    /** 拜訪次數 */
    count: Scalars['Int'];
    /** 拜訪次數百分比（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（總計數量）為 0 時，百分比顯示為空值 */
    countPercent?: Maybe<Scalars['Float']>;
    /** 客戶數量 */
    customerCount: Scalars['Int'];
    /** 在所有負責的客戶佔有率 */
    customerCountPercentInResponsible?: Maybe<Scalars['Float']>;
    /** 預算金額 */
    totalBudgetAmount: Scalars['Float'];
    /** 類型 */
    type: EnumVisitStatType;
};

export type VisitAnalysisVisitStat_Overall = {
    /** 平均每日拜訪次數 */
    averageCountPerDay?: Maybe<Scalars['Float']>;
    /** 平均每日客戶數量 */
    averageCustomerCountPerDay?: Maybe<Scalars['Float']>;
    /** 商機數量 */
    businessCount: Scalars['Int'];
    /** 拜訪次數 */
    count: Scalars['Int'];
    /** 客戶數量 */
    customerCount: Scalars['Int'];
    /** 查詢天數 */
    days: Scalars['Int'];
    /** 預算金額 */
    totalBudgetAmount: Scalars['Float'];
};

export type VisitAnalysisWeeklyVisitStat = {
    /** 細項統計 */
    details: Array<VisitAnalysisWeeklyVisitStat_Detail>;
    /** 總體統計 */
    overall: VisitAnalysisWeeklyVisitStat_Overall;
};

export type VisitAnalysisWeeklyVisitStatFilterInput = {
    /** 行為（複選） */
    actionIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 商機 */
    businessId?: InputMaybe<Scalars['ID']>;
    /** 商機訂單編號 */
    businessOrderCode?: InputMaybe<Scalars['String']>;
    /** 客戶分區 */
    customerAreaId?: InputMaybe<Scalars['ID']>;
    /** 客戶編號 */
    customerCode?: InputMaybe<Scalars['String']>;
    /** 客戶 */
    customerId?: InputMaybe<Scalars['ID']>;
    /** 客戶醫事機構代碼 */
    customerMedicalCode?: InputMaybe<Scalars['String']>;
    /** 客戶名稱 */
    customerName?: InputMaybe<Scalars['String']>;
    /** 目標（複選） */
    goalIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 是否有商機資料 */
    hasBusiness?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 涉及商品（複選） */
    mentionProductIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 主要負責聯絡人（複選） */
    primaryContactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 主要負責業務 */
    primaryUserId?: InputMaybe<Scalars['ID']>;
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 屬性（複選） */
    propertyIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 屬性類型（複選） */
    propertyTypeIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 借用固定資產（複選） */
    rentFixedAssetIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 租借商品（複選） */
    rentProductIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 業務團隊組織 */
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    /** 负责业务位置 */
    salesTeamUnitId?: InputMaybe<Scalars['ID']>;
    /** 负责业务位置（複選） */
    salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 狀態（複選） */
    statuses?: InputMaybe<Array<EnumVisitStatus>>;
    /** 時段（複選） */
    timePeriodIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 類型（複選） */
    typeIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 拜訪日期（起） */
    visitDate1?: InputMaybe<Scalars['String']>;
    /** 拜訪日期（迄） */
    visitDate2?: InputMaybe<Scalars['String']>;
};

export type VisitAnalysisWeeklyVisitStat_Detail = {
    /** 拜訪次數 */
    count: Scalars['Int'];
    /** 一週拜訪統計類型 */
    type: EnumWeeklyVisitStatType;
};

export type VisitAnalysisWeeklyVisitStat_Overall = {
    /** 拜訪次數 */
    count: Scalars['Int'];
};

export type VisitBulkUpdateInput = {
    visits: Array<VisitUpdateInput>;
};

export type VisitCheckIn = IVisitCheckIn & {
    /** 內容 */
    content?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUser?: Maybe<User>;
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    /** WGS84 緯度 */
    lat: Scalars['Float'];
    /** WGS84 經度 */
    lng: Scalars['Float'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUser?: Maybe<User>;
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 拜訪 */
    visit: Visit;
    /** 拜訪 */
    visitId: Scalars['ID'];
};

export type VisitCheckInCreateInput = {
    /** 內容 */
    content?: InputMaybe<Scalars['String']>;
    /** WGS84 緯度 */
    lat?: InputMaybe<Scalars['Float']>;
    /** WGS84 經度 */
    lng?: InputMaybe<Scalars['Float']>;
    /** 拜訪 */
    visitId?: InputMaybe<Scalars['ID']>;
};

export type VisitCheckInFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    visitId?: InputMaybe<Scalars['ID']>;
};

export type VisitCheckInInput = {
    /** 內容 */
    content?: InputMaybe<Scalars['String']>;
};

export type VisitCheckInUpdateInput = {
    /** 內容 */
    content?: InputMaybe<Scalars['String']>;
    id: Scalars['ID'];
};

export type VisitCreateInput = {
    actionContent?: InputMaybe<Scalars['String']>;
    actionId?: InputMaybe<Scalars['ID']>;
    /** 协访主管 */
    assistedVisitSupervisorId?: InputMaybe<Scalars['ID']>;
    businessId?: InputMaybe<Scalars['ID']>;
    content?: InputMaybe<Scalars['String']>;
    customerId?: InputMaybe<Scalars['ID']>;
    estimatedSurgeryCount?: InputMaybe<Scalars['String']>;
    goalIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 是否协访主管 */
    hasAssistedVisitSupervisor?: InputMaybe<Scalars['Boolean']>;
    mentionProducts?: InputMaybe<Array<VisitMentionProductInput>>;
    primaryContactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
    primaryUserId?: InputMaybe<Scalars['ID']>;
    priorityOrder?: InputMaybe<Scalars['Int']>;
    propertyIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 借用固定資產（複選） */
    rentFixedAssets?: InputMaybe<Array<VisitRentFixedAssetInput>>;
    rentProducts?: InputMaybe<Array<VisitRentProductInput>>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    salesTeamId?: InputMaybe<Scalars['ID']>;
    /** 业务团队位置 */
    salesTeamUnitId?: InputMaybe<Scalars['ID']>;
    status?: InputMaybe<EnumVisitStatus>;
    surgeryCount?: InputMaybe<Scalars['String']>;
    timePeriodIds?: InputMaybe<Array<Scalars['ID']>>;
    title?: InputMaybe<Scalars['String']>;
    typeId?: InputMaybe<Scalars['ID']>;
    visitAddress?: InputMaybe<Scalars['String']>;
    visitDate?: InputMaybe<Scalars['DateTime']>;
};

export type VisitCustomerAnalysis = {
    /** 拜訪統計 */
    visitStat: VisitCustomerAnalysisVisitStat;
};

export type VisitCustomerAnalysisVisitStatArgs = {
    filters?: InputMaybe<VisitCustomerAnalysisVisitStatFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
};

export type VisitCustomerAnalysisVisitStat = {
    /** 細項統計 */
    details: Array<VisitCustomerAnalysisVisitStat_Detail>;
    /** 總體統計 */
    overall: VisitCustomerAnalysisVisitStat_Overall;
};

export type VisitCustomerAnalysisVisitStatFilterInput = {
    /** 客戶查詢項 */
    customer?: InputMaybe<CustomerFilterInput>;
    /** 拜訪查詢項 */
    visit?: InputMaybe<VisitFilterInput>;
};

export type VisitCustomerAnalysisVisitStat_Detail = {
    /** 客戶 */
    customer: Customer;
    /** 客戶 */
    customerId: Scalars['Int'];
    /** 涉及商品 */
    mentionProducts: Array<VisitCustomerAnalysisVisitStat_Detail_MentionProduct>;
    /** 業務負責位置 */
    salesTeamUnits: Array<VisitCustomerAnalysisVisitStat_Detail_SalesTeamUnit>;
    /** 拜訪次數 */
    visitCount: Scalars['Int'];
};

export type VisitCustomerAnalysisVisitStat_Detail_MentionProduct = {
    /** 涉及商品 */
    mentionProduct: BusinessProduct;
    /** 涉及商品 */
    mentionProductId: Scalars['Int'];
    /** 拜訪次數 */
    visitCount: Scalars['Int'];
};

export type VisitCustomerAnalysisVisitStat_Detail_SalesTeamUnit = {
    /** 業務負責位置 */
    salesTeamUnit: SalesTeamUnit;
    /** 業務負責位置 */
    salesTeamUnitId: Scalars['Int'];
    /** 拜訪次數 */
    visitCount: Scalars['Int'];
};

export type VisitCustomerAnalysisVisitStat_Overall = {
    /** 拜訪次數 */
    visitCount: Scalars['Int'];
};

export type VisitCustomerLevelTypeAnalysis = {
    /** 客戶統計 */
    customerStat: VisitCustomerLevelTypeAnalysisCustomerStat;
    /** 涉及商品統計 */
    mentionProductStat: VisitCustomerLevelTypeAnalysisMentionProductStat;
    /** 拜訪目標統計 */
    visitGoalStat: VisitCustomerLevelTypeAnalysisVisitGoalStat;
};

export type VisitCustomerLevelTypeAnalysisCustomerStatArgs = {
    filters: VisitCustomerLevelTypeAnalysisCustomerStatFilterInput;
};

export type VisitCustomerLevelTypeAnalysisMentionProductStatArgs = {
    filters: VisitCustomerLevelTypeAnalysisMentionProductStatFilterInput;
    pagination?: InputMaybe<PaginationInput>;
};

export type VisitCustomerLevelTypeAnalysisVisitGoalStatArgs = {
    filters?: InputMaybe<VisitCustomerLevelTypeAnalysisVisitGoalStatFilterInput>;
};

export type VisitCustomerLevelTypeAnalysisCustomerStat = {
    /** 細項統計 */
    details: Array<VisitCustomerLevelTypeAnalysisCustomerStat_Detail>;
    /** 總體統計 */
    overall: VisitCustomerLevelTypeAnalysisCustomerStat_Overall;
    /** 排序欄位類型（第一個欄位） */
    sortByFirstFieldType?: Maybe<CustomerLevelFieldType>;
    /** 排序欄位類型（第一個欄位） */
    sortByFirstFieldTypeId?: Maybe<Scalars['ID']>;
    /** 排序欄位類型（第二個欄位） */
    sortBySecondFieldType?: Maybe<CustomerLevelFieldType>;
    /** 排序欄位類型（第二個欄位） */
    sortBySecondFieldTypeId?: Maybe<Scalars['ID']>;
    /** 細項統計（依第一個欄位排序） */
    sortedDetailsByFirstFieldType: Array<VisitCustomerLevelTypeAnalysisCustomerStat_Detail>;
    /** 細項統計（依第二個欄位排序） */
    sortedDetailsBySecondFieldType: Array<VisitCustomerLevelTypeAnalysisCustomerStat_Detail>;
};

export type VisitCustomerLevelTypeAnalysisCustomerStatFilterInput = {
    /** 客戶分區 */
    customerAreaId?: InputMaybe<Scalars['ID']>;
    /** 客戶等級類型 */
    customerLevelTypeId: Scalars['ID'];
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 拜訪日期（起） */
    visitDate1?: InputMaybe<Scalars['String']>;
    /** 拜訪日期（迄） */
    visitDate2?: InputMaybe<Scalars['String']>;
};

export type VisitCustomerLevelTypeAnalysisCustomerStat_Detail = {
    /** 平均每位客戶涉及商品數 */
    averageMentionProductCountPerCustomer?: Maybe<Scalars['Float']>;
    /** 平均每位客戶拜訪次數 */
    averageVisitCountPerCustomer?: Maybe<Scalars['Float']>;
    /** 客戶數 */
    customerCount: Scalars['String'];
    /** 客戶數百分比（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    customerCountPercent?: Maybe<Scalars['Float']>;
    /** 客戶等級 */
    customerLevel: CustomerLevel;
    /** 客戶等級 */
    customerLevelId: Scalars['ID'];
    /** 涉及商品數 */
    mentionProductCount: Scalars['Int'];
    /** 拜訪次數 */
    visitCount: Scalars['Int'];
    /** 拜訪客戶數 */
    visitedCustomerCount: Scalars['Int'];
    /** 拜訪客戶涵蓋率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    visitedCustomerOverlapPercent?: Maybe<Scalars['Float']>;
};

export type VisitCustomerLevelTypeAnalysisCustomerStat_Overall = {
    /** 客戶數 */
    customerCount: Scalars['Int'];
    /** 客戶等級類型 */
    customerLevelType: CustomerLevelType;
    /** 客戶等級類型 */
    customerLevelTypeId: Scalars['ID'];
    /** 拜訪次數 */
    visitCount: Scalars['Int'];
    /** 拜訪客戶數 */
    visitedCustomerCount: Scalars['Int'];
};

export type VisitCustomerLevelTypeAnalysisMentionProductStat = {
    /** 細項統計 */
    details: Array<VisitCustomerLevelTypeAnalysisMentionProductStat_Detail>;
    /** 總體統計 */
    overall: VisitCustomerLevelTypeAnalysisMentionProductStat_Overall;
};

export type VisitCustomerLevelTypeAnalysisMentionProductStatFilterInput = {
    /** 客戶分區 */
    customerAreaId?: InputMaybe<Scalars['ID']>;
    /** 客戶等級類型 */
    customerLevelTypeId: Scalars['ID'];
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 拜訪日期（起） */
    visitDate1?: InputMaybe<Scalars['String']>;
    /** 拜訪日期（迄） */
    visitDate2?: InputMaybe<Scalars['String']>;
};

export type VisitCustomerLevelTypeAnalysisMentionProductStat_Detail = {
    /** 涉及次數 */
    mentionCount: Scalars['Int'];
    /** 涉及次數百分比（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
    mentionCountPercent?: Maybe<Scalars['Float']>;
    /** 涉及商品 */
    mentionProduct: BusinessProduct;
    /** 涉及商品 */
    mentionProductId: Scalars['ID'];
};

export type VisitCustomerLevelTypeAnalysisMentionProductStat_Overall = {
    /** 涉及次數 */
    mentionCount: Scalars['Int'];
};

export type VisitCustomerLevelTypeAnalysisVisitGoalStat = {
    /** 細項統計 */
    details: Array<VisitCustomerLevelTypeAnalysisVisitGoalStat_Detail>;
    /** 總體統計 */
    overall: VisitCustomerLevelTypeAnalysisVisitGoalStat_Overall;
};

export type VisitCustomerLevelTypeAnalysisVisitGoalStatFilterInput = {
    /** 客戶分區 */
    customerAreaId?: InputMaybe<Scalars['ID']>;
    /** 客戶等級類型 */
    customerLevelTypeId: Scalars['ID'];
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 拜訪日期（起） */
    visitDate1?: InputMaybe<Scalars['String']>;
    /** 拜訪日期（迄） */
    visitDate2?: InputMaybe<Scalars['String']>;
};

export type VisitCustomerLevelTypeAnalysisVisitGoalStat_Detail = {
    /** 涉及商品 */
    mentionProducts: Array<VisitCustomerLevelTypeAnalysisVisitGoalStat_Detail_MentionProduct>;
    /** 拜訪次數 */
    visitCount: Scalars['Int'];
    /** 拜訪次數百分比（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（總計數量）為 0 時，百分比顯示為空值 */
    visitCountPercent?: Maybe<Scalars['Float']>;
    /** 拜訪目標 */
    visitGoal: VisitGoal;
    /** 拜訪目標 */
    visitGoalId: Scalars['ID'];
};

export type VisitCustomerLevelTypeAnalysisVisitGoalStat_Detail_MentionProduct = {
    /** 涉及商品 */
    mentionProduct: BusinessProduct;
    /** 涉及商品 */
    mentionProductId: Scalars['ID'];
    /** 拜訪次數 */
    visitCount: Scalars['Int'];
};

export type VisitCustomerLevelTypeAnalysisVisitGoalStat_Overall = {
    /** 拜訪次數 */
    visitCount: Scalars['Int'];
};

export type VisitFilterInput = {
    /** 行為（複選） */
    actionIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 商機 */
    businessId?: InputMaybe<Scalars['ID']>;
    /** 商機訂單編號 */
    businessOrderCode?: InputMaybe<Scalars['String']>;
    /** 客戶分區 */
    customerAreaId?: InputMaybe<Scalars['ID']>;
    /** 客戶編號 */
    customerCode?: InputMaybe<Scalars['String']>;
    /** 客戶 */
    customerId?: InputMaybe<Scalars['ID']>;
    /** 客戶醫事機構代碼 */
    customerMedicalCode?: InputMaybe<Scalars['String']>;
    /** 客戶名稱 */
    customerName?: InputMaybe<Scalars['String']>;
    /** 目標（複選） */
    goalIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 是否有商機資料 */
    hasBusiness?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 涉及商品（複選） */
    mentionProductIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 主要負責聯絡人（複選） */
    primaryContactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 主要負責業務 */
    primaryUserId?: InputMaybe<Scalars['ID']>;
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 屬性（複選） */
    propertyIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 屬性類型（複選） */
    propertyTypeIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 借用固定資產（複選） */
    rentFixedAssetIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 租借商品（複選） */
    rentProductIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 業務團隊組織 */
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    /** 负责业务位置 */
    salesTeamUnitId?: InputMaybe<Scalars['ID']>;
    /** 负责业务位置（複選） */
    salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 狀態（複選） */
    statuses?: InputMaybe<Array<EnumVisitStatus>>;
    /** 時段（複選） */
    timePeriodIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 類型（複選） */
    typeIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 拜訪日期（起） */
    visitDate1?: InputMaybe<Scalars['String']>;
    /** 拜訪日期（迄） */
    visitDate2?: InputMaybe<Scalars['String']>;
};

export type VisitGoal = IVisitGoal & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type VisitGoalAnalysis = {
    /** 涉及商品統計 */
    mentionProductStat: VisitGoalAnalysisMentionProductStat;
};

export type VisitGoalAnalysisMentionProductStatArgs = {
    filters?: InputMaybe<VisitGoalAnalysisMentionProductStatFilterInput>;
};

export type VisitGoalAnalysisMentionProductStat = {
    /** 細項統計 */
    details: Array<VisitGoalAnalysisMentionProductStat_Detail>;
    /** 總體統計 */
    overall: VisitGoalAnalysisMentionProductStat_Overall;
};

export type VisitGoalAnalysisMentionProductStatFilterInput = {
    /** 行為（複選） */
    actionIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 商機 */
    businessId?: InputMaybe<Scalars['ID']>;
    /** 商機訂單編號 */
    businessOrderCode?: InputMaybe<Scalars['String']>;
    /** 客戶分區 */
    customerAreaId?: InputMaybe<Scalars['ID']>;
    /** 客戶編號 */
    customerCode?: InputMaybe<Scalars['String']>;
    /** 客戶 */
    customerId?: InputMaybe<Scalars['ID']>;
    /** 客戶醫事機構代碼 */
    customerMedicalCode?: InputMaybe<Scalars['String']>;
    /** 客戶名稱 */
    customerName?: InputMaybe<Scalars['String']>;
    /** 目標（複選） */
    goalIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 是否有商機資料 */
    hasBusiness?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    /** 涉及商品（複選） */
    mentionProductIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 主要負責聯絡人（複選） */
    primaryContactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 主要負責業務 */
    primaryUserId?: InputMaybe<Scalars['ID']>;
    /** 主要負責業務（複選） */
    primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 屬性（複選） */
    propertyIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 屬性類型（複選） */
    propertyTypeIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 借用固定資產（複選） */
    rentFixedAssetIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 租借商品（複選） */
    rentProductIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 業務團隊組織 */
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    /** 负责业务位置 */
    salesTeamUnitId?: InputMaybe<Scalars['ID']>;
    /** 负责业务位置（複選） */
    salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 狀態（複選） */
    statuses?: InputMaybe<Array<EnumVisitStatus>>;
    /** 時段（複選） */
    timePeriodIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 類型（複選） */
    typeIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 拜訪日期（起） */
    visitDate1?: InputMaybe<Scalars['String']>;
    /** 拜訪日期（迄） */
    visitDate2?: InputMaybe<Scalars['String']>;
};

export type VisitGoalAnalysisMentionProductStat_Detail = {
    /** 拜訪次數 */
    count: Scalars['Int'];
    /** 拜訪次數百分比（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（總計數量）為 0 時，百分比顯示為空值 */
    countPercent?: Maybe<Scalars['Float']>;
    /** 涉及商品 */
    mentionProducts: Array<VisitGoalAnalysisMentionProductStat_Detail_MentionProduct>;
    /** 拜訪目標 */
    visitGoal: VisitGoal;
    /** 拜訪目標 */
    visitGoalId: Scalars['ID'];
};

export type VisitGoalAnalysisMentionProductStat_Detail_MentionProduct = {
    /** 拜訪次數 */
    count: Scalars['Int'];
    /** 涉及商品 */
    mentionProduct: BusinessProduct;
    /** 涉及商品 */
    mentionProductId: Scalars['ID'];
};

export type VisitGoalAnalysisMentionProductStat_Overall = {
    /** 拜訪次數 */
    count: Scalars['Int'];
};

export type VisitGoalCreateInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitGoalFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type VisitGoalInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitGoalUpdateInput = {
    id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitInput = {
    actionContent?: InputMaybe<Scalars['String']>;
    actionId?: InputMaybe<Scalars['ID']>;
    /** 协访主管 */
    assistedVisitSupervisorId?: InputMaybe<Scalars['ID']>;
    businessId?: InputMaybe<Scalars['ID']>;
    content?: InputMaybe<Scalars['String']>;
    customerId?: InputMaybe<Scalars['ID']>;
    estimatedSurgeryCount?: InputMaybe<Scalars['String']>;
    goalIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 是否协访主管 */
    hasAssistedVisitSupervisor?: InputMaybe<Scalars['Boolean']>;
    mentionProducts?: InputMaybe<Array<VisitMentionProductInput>>;
    primaryContactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
    primaryUserId?: InputMaybe<Scalars['ID']>;
    priorityOrder?: InputMaybe<Scalars['Int']>;
    propertyIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 借用固定資產（複選） */
    rentFixedAssets?: InputMaybe<Array<VisitRentFixedAssetInput>>;
    rentProducts?: InputMaybe<Array<VisitRentProductInput>>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    salesTeamId?: InputMaybe<Scalars['ID']>;
    /** 业务团队位置 */
    salesTeamUnitId?: InputMaybe<Scalars['ID']>;
    status?: InputMaybe<EnumVisitStatus>;
    surgeryCount?: InputMaybe<Scalars['String']>;
    timePeriodIds?: InputMaybe<Array<Scalars['ID']>>;
    title?: InputMaybe<Scalars['String']>;
    typeId?: InputMaybe<Scalars['ID']>;
    visitAddress?: InputMaybe<Scalars['String']>;
    visitDate?: InputMaybe<Scalars['DateTime']>;
};

export type VisitMemo = IVisitMemo & {
    /** 內容 */
    content?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    /** 資料建立人員 */
    createdUser?: Maybe<User>;
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    /** 標題 */
    title?: Maybe<Scalars['String']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 資料修改人員 */
    updatedUser?: Maybe<User>;
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 拜訪 */
    visit: Visit;
    /** 拜訪 */
    visitId: Scalars['ID'];
};

export type VisitMemoCreateInput = {
    content?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
    visitId?: InputMaybe<Scalars['ID']>;
};

export type VisitMemoFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    visitId?: InputMaybe<Scalars['ID']>;
};

export type VisitMemoInput = {
    content?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
    visitId?: InputMaybe<Scalars['ID']>;
};

export type VisitMemoUpdateInput = {
    content?: InputMaybe<Scalars['String']>;
    id: Scalars['ID'];
    title?: InputMaybe<Scalars['String']>;
    visitId?: InputMaybe<Scalars['ID']>;
};

export type VisitMentionProductInput = {
    mentionProductId: Scalars['ID'];
};

export type VisitProperty = IVisitProperty & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 類型 */
    type: VisitPropertyType;
    /** 類型 */
    typeId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type VisitPropertyCreateInput = {
    code?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    typeId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitPropertyFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
    typeIds?: InputMaybe<Array<Scalars['ID']>>;
    visitId?: InputMaybe<Scalars['ID']>;
};

export type VisitPropertyInput = {
    code?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    typeId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitPropertyType = IVisitPropertyType & {
    /** 編號 */
    code?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 拜訪屬性 */
    properties: Array<VisitProperty>;
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type VisitPropertyTypeCreateInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitPropertyTypeFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type VisitPropertyTypeInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitPropertyTypeUpdateInput = {
    id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitPropertyUpdateInput = {
    code?: InputMaybe<Scalars['String']>;
    id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    typeId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitRentFixedAssetInput = {
    rentFixedAssetId: Scalars['ID'];
};

export type VisitRentProductInput = {
    rentProductId: Scalars['ID'];
};

export type VisitTimePeriod = IVisitTimePeriod & {
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type VisitTimePeriodCreateInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitTimePeriodFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type VisitTimePeriodInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitTimePeriodUpdateInput = {
    id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitType = IVisitType & {
    /** 編號 */
    code?: Maybe<EnumVisitTypeCode>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 名稱 */
    name: Scalars['String'];
    /** 業務團隊組織 */
    salesTeamGroup: SalesTeamGroup;
    /** 業務團隊組織 */
    salesTeamGroupId: Scalars['ID'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    /** 檢視順序 */
    viewOrder?: Maybe<Scalars['Int']>;
};

export type VisitTypeCreateInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitTypeFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type VisitTypeInput = {
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitTypeUpdateInput = {
    id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitUpdateInput = {
    actionContent?: InputMaybe<Scalars['String']>;
    actionId?: InputMaybe<Scalars['ID']>;
    /** 协访主管 */
    assistedVisitSupervisorId?: InputMaybe<Scalars['ID']>;
    businessId?: InputMaybe<Scalars['ID']>;
    content?: InputMaybe<Scalars['String']>;
    customerId?: InputMaybe<Scalars['ID']>;
    estimatedSurgeryCount?: InputMaybe<Scalars['String']>;
    goalIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 是否协访主管 */
    hasAssistedVisitSupervisor?: InputMaybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    mentionProducts?: InputMaybe<Array<VisitMentionProductInput>>;
    primaryContactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
    primaryUserId?: InputMaybe<Scalars['ID']>;
    priorityOrder?: InputMaybe<Scalars['Int']>;
    propertyIds?: InputMaybe<Array<Scalars['ID']>>;
    /** 借用固定資產（複選） */
    rentFixedAssets?: InputMaybe<Array<VisitRentFixedAssetInput>>;
    rentProducts?: InputMaybe<Array<VisitRentProductInput>>;
    salesTeamGroupId?: InputMaybe<Scalars['ID']>;
    salesTeamId?: InputMaybe<Scalars['ID']>;
    /** 业务团队位置 */
    salesTeamUnitId?: InputMaybe<Scalars['ID']>;
    status?: InputMaybe<EnumVisitStatus>;
    surgeryCount?: InputMaybe<Scalars['String']>;
    timePeriodIds?: InputMaybe<Array<Scalars['ID']>>;
    title?: InputMaybe<Scalars['String']>;
    typeId?: InputMaybe<Scalars['ID']>;
    visitAddress?: InputMaybe<Scalars['String']>;
    visitDate?: InputMaybe<Scalars['DateTime']>;
};

export type VisitsToMentionProduct = IVisitsToMentionProduct & {
    id: Scalars['ID'];
    /** 涉及商品 */
    mentionProduct: BusinessProduct;
    /** 涉及商品 */
    mentionProductId: Scalars['ID'];
    /** 拜訪 */
    visit: Visit;
    /** 拜訪 */
    visitId: Scalars['ID'];
};

export type VisitsToMentionProductFilterInput = {
    id?: InputMaybe<Scalars['ID']>;
    ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type VisitsToRentFixedAsset = IVisitsToRentFixedAsset & {
    id: Scalars['ID'];
    /** 租借商品 */
    rentFixedAsset?: Maybe<EyeFixedAsset>;
    /** 借用固定資產 */
    rentFixedAssetId: Scalars['ID'];
    /** 拜訪 */
    visit?: Maybe<Visit>;
    /** 拜訪 */
    visitId: Scalars['ID'];
};

export type VisitsToRentProduct = IVisitsToRentProduct & {
    id: Scalars['ID'];
    /** 租借商品 */
    rentProduct: RentProduct;
    /** 租借商品 */
    rentProductId: Scalars['ID'];
    /** 拜訪 */
    visit: Visit;
    /** 拜訪 */
    visitId: Scalars['ID'];
};

export type CitiesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<CityFilterInput>;
}>;

export type CitiesQuery = { cities: Array<{ id: string; name: string }> };

export type CityQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type CityQuery = { city: { id: string; name: string } };

export type DistrictsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<DistrictFilterInput>;
}>;

export type DistrictsQuery = { districts: Array<{ id: string; name: string }> };

export type ProvincesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<ProvinceFilterInput>;
}>;

export type ProvincesQuery = {
    provinces: Array<{
        id: string;
        name?: string | null;
        cities: Array<{ id: string; name: string; districts: Array<{ id: string; name: string }> }>;
    }>;
};

export type ContactPeopleQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<ContactPersonFilterInput>;
}>;

export type ContactPeopleQuery = {
    contactPeople: Array<{
        id: string;
        name: string;
        jobTitle?: string | null;
        mobile?: string | null;
    }>;
};

export type CustomerTypesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<CustomerTypeFilterInput>;
}>;

export type CustomerTypesQuery = {
    customerTypes: Array<{
        id: string;
        name: string;
        code?: EnumCustomerTypeCode | null;
        salesTeamGroup: { id: string; name: string };
    }>;
};

export type CustomerAreasQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<CustomerAreaFilterInput>;
}>;

export type CustomerAreasQuery = { customerAreas: Array<{ id: string; name: string }> };

export type CustomerCertificateTypesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<CustomerCertificateTypeFilterInput>;
}>;

export type CustomerCertificateTypesQuery = {
    customerCertificateTypes: Array<{ id: string; name: string }>;
};

export type CustomerCategoriesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<CustomerCategoryFilterInput>;
}>;

export type CustomerCategoriesQuery = { customerCategories: Array<{ id: string; name: string }> };

export type CustomerLevelsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<CustomerLevelFilterInput>;
}>;

export type CustomerLevelsQuery = {
    customerLevels: Array<{
        createdAt: any;
        id: string;
        name: string;
        salesTeamGroupId: string;
        typeId: string;
        updatedAt: any;
        viewOrder?: number | null;
        salesTeamGroup: { id: string; name: string; code?: EnumSalesTeamGroupCode | null };
        type: { id: string; name: string; code?: EnumCustomerLevelTypeCode | null };
    }>;
};

export type UsersQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<UserFilterInput>;
}>;

export type UsersQuery = {
    users: Array<{
        name?: string | null;
        id: string;
        code?: string | null;
        salesTeams: Array<{ name: string }>;
        salesTeamGroups?: Array<{ name: string }> | null;
    }>;
};

export type UsersSelectQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<UserFilterInput>;
}>;

export type UsersSelectQuery = {
    users: Array<{ id: string; name?: string | null; code?: string | null }>;
};

export type SalesTeamGroupQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type SalesTeamGroupQuery = {
    salesTeamGroup: {
        id: string;
        name: string;
        code?: EnumSalesTeamGroupCode | null;
        region?: { id: string; name: string } | null;
        teams: Array<{
            id: string;
            name: string;
            code?: string | null;
            children: Array<{
                id: string;
                name: string;
                code?: string | null;
                children: Array<{
                    id: string;
                    name: string;
                    code?: string | null;
                    children: Array<{
                        id: string;
                        name: string;
                        code?: string | null;
                        children: Array<{
                            id: string;
                            name: string;
                            code?: string | null;
                            children: Array<{
                                id: string;
                                name: string;
                                code?: string | null;
                                children: Array<{
                                    id: string;
                                    name: string;
                                    code?: string | null;
                                    users: Array<{
                                        id: string;
                                        name?: string | null;
                                        code?: string | null;
                                    }>;
                                }>;
                                users: Array<{
                                    id: string;
                                    name?: string | null;
                                    code?: string | null;
                                }>;
                            }>;
                            users: Array<{
                                id: string;
                                name?: string | null;
                                code?: string | null;
                            }>;
                        }>;
                        users: Array<{ id: string; name?: string | null; code?: string | null }>;
                    }>;
                    users: Array<{ id: string; name?: string | null; code?: string | null }>;
                }>;
                users: Array<{ id: string; name?: string | null; code?: string | null }>;
            }>;
            users: Array<{ id: string; name?: string | null; code?: string | null }>;
        }>;
        users: Array<{ id: string; name?: string | null; code?: string | null }>;
    };
};

export type SalesTeamsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<SalesTeamFilterInput>;
}>;

export type SalesTeamsQuery = {
    salesTeams: Array<{
        id: string;
        code?: string | null;
        name: string;
        nestedUsers: Array<{ id: string; name?: string | null; code?: string | null }>;
        nestedUnits: Array<{
            id: string;
            name: string;
            user?: { id: string; name?: string | null; code?: string | null } | null;
        }>;
    }>;
};

export type SalesTeamGroupsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<SalesTeamGroupFilterInput>;
}>;

export type SalesTeamGroupsQuery = {
    salesTeamGroups: Array<{
        id: string;
        code?: EnumSalesTeamGroupCode | null;
        name: string;
        users: Array<{ id: string; name?: string | null; code?: string | null }>;
    }>;
};

export type PaginatedSalesTeamsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<SalesTeamFilterInput>;
}>;

export type PaginatedSalesTeamsQuery = {
    paginatedSalesTeams: {
        salesTeams?: Array<{
            id: string;
            name: string;
            users: Array<{ id: string; code?: string | null; name?: string | null }>;
            children: Array<{
                id: string;
                name: string;
                code?: string | null;
                children: Array<{
                    id: string;
                    name: string;
                    code?: string | null;
                    children: Array<{
                        id: string;
                        name: string;
                        code?: string | null;
                        children: Array<{
                            id: string;
                            name: string;
                            code?: string | null;
                            children: Array<{
                                id: string;
                                name: string;
                                code?: string | null;
                                children: Array<{
                                    id: string;
                                    name: string;
                                    code?: string | null;
                                    users: Array<{
                                        id: string;
                                        name?: string | null;
                                        code?: string | null;
                                    }>;
                                }>;
                                users: Array<{
                                    id: string;
                                    name?: string | null;
                                    code?: string | null;
                                }>;
                            }>;
                            users: Array<{
                                id: string;
                                name?: string | null;
                                code?: string | null;
                            }>;
                        }>;
                        users: Array<{ id: string; name?: string | null; code?: string | null }>;
                    }>;
                    users: Array<{ id: string; name?: string | null; code?: string | null }>;
                }>;
                users: Array<{ id: string; name?: string | null; code?: string | null }>;
            }>;
        }> | null;
    };
};

export type TeamsFragment = {
    id: string;
    name: string;
    code?: string | null;
    children: Array<{
        id: string;
        name: string;
        code?: string | null;
        children: Array<{
            id: string;
            name: string;
            code?: string | null;
            children: Array<{
                id: string;
                name: string;
                code?: string | null;
                children: Array<{
                    id: string;
                    name: string;
                    code?: string | null;
                    children: Array<{
                        id: string;
                        name: string;
                        code?: string | null;
                        children: Array<{
                            id: string;
                            name: string;
                            code?: string | null;
                            users: Array<{
                                id: string;
                                name?: string | null;
                                code?: string | null;
                            }>;
                        }>;
                        users: Array<{ id: string; name?: string | null; code?: string | null }>;
                    }>;
                    users: Array<{ id: string; name?: string | null; code?: string | null }>;
                }>;
                users: Array<{ id: string; name?: string | null; code?: string | null }>;
            }>;
            users: Array<{ id: string; name?: string | null; code?: string | null }>;
        }>;
        users: Array<{ id: string; name?: string | null; code?: string | null }>;
    }>;
    users: Array<{ id: string; name?: string | null; code?: string | null }>;
};

export type ChildrenFragment = {
    id: string;
    name: string;
    code?: string | null;
    users: Array<{ id: string; name?: string | null; code?: string | null }>;
};

export type SalesTeamUnitFragment = {
    id: string;
    name: string;
    user?: { id: string; name?: string | null } | null;
    salesTeam?: { id: string; name: string; code?: string | null } | null;
};

export type SalesTeamUnitsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<SalesTeamUnitFilterInput>;
}>;

export type SalesTeamUnitsQuery = {
    paginatedSalesTeamUnits: {
        count?: number | null;
        salesTeamUnits?: Array<{
            id: string;
            name: string;
            user?: { id: string; name?: string | null } | null;
            salesTeam?: { id: string; name: string; code?: string | null } | null;
        }> | null;
    };
};

export type SalesTeamUnitQueryVariables = Exact<{
    salesTeamUnitId: Scalars['ID'];
}>;

export type SalesTeamUnitQuery = {
    salesTeamUnit: {
        id: string;
        name: string;
        user?: { id: string; name?: string | null } | null;
        salesTeam?: { id: string; name: string; code?: string | null } | null;
    };
};

export type VisitTypesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<VisitTypeFilterInput>;
}>;

export type VisitTypesQuery = {
    visitTypes: Array<{
        id: string;
        name: string;
        code?: EnumVisitTypeCode | null;
        salesTeamGroup: { id: string; name: string; code?: EnumSalesTeamGroupCode | null };
    }>;
};

export type VisitTimePeriodsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<VisitTimePeriodFilterInput>;
}>;

export type VisitTimePeriodsQuery = {
    visitTimePeriods: Array<{
        id: string;
        name: string;
        salesTeamGroup: { id: string; name: string; code?: EnumSalesTeamGroupCode | null };
    }>;
};

export type VisitPropertiesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<VisitPropertyFilterInput>;
}>;

export type VisitPropertiesQuery = {
    visitProperties: Array<{
        id: string;
        name: string;
        salesTeamGroup: { id: string; name: string; code?: EnumSalesTeamGroupCode | null };
    }>;
};

export type VisitPropertyTypesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<VisitPropertyTypeFilterInput>;
}>;

export type VisitPropertyTypesQuery = {
    visitPropertyTypes: Array<{
        id: string;
        name: string;
        code?: string | null;
        salesTeamGroup: { id: string; name: string; code?: EnumSalesTeamGroupCode | null };
        properties: Array<{ id: string; name: string }>;
    }>;
};

export type VisitGoalsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<VisitGoalFilterInput>;
}>;

export type VisitGoalsQuery = { visitGoals: Array<{ id: string; name: string }> };

export type VisitActionsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<VisitActionFilterInput>;
}>;

export type VisitActionsQuery = {
    visitActions: Array<{
        id: string;
        name: string;
        salesTeamGroup: { id: string; name: string; code?: EnumSalesTeamGroupCode | null };
    }>;
};

export type SalesTeamGroupFragment = {
    id: string;
    name: string;
    code?: EnumSalesTeamGroupCode | null;
};

export type BusinessProductFragmentFragment = {
    id: string;
    name: string;
    isActive: boolean;
    brand?: string | null;
    salesTeamGroup: { id: string };
};

export type BusinessFragmentFragment = {
    id: string;
    content?: string | null;
    title?: string | null;
    eyeQuotationOrderCode?: string | null;
    code?: string | null;
    typeId?: string | null;
    budgetAmount?: number | null;
    saleAmount?: number | null;
    dealAmount?: number | null;
    expectedClosedDate?: any | null;
    closedDate?: any | null;
    createdAt: any;
    orderCode?: string | null;
    updatedAt: any;
    losingReason?: string | null;
    losingImprovementPlan?: string | null;
    salesMethodId?: string | null;
    dealerId?: string | null;
    salesTeamUnitId?: string | null;
    dealer?: { id: string; name: string; code?: string | null; address?: string | null } | null;
    losingReasons: Array<{ id: string; name: string }>;
    properties: Array<{
        id: string;
        name: string;
        typeId: string;
        type: { name: string; id: string; code?: string | null };
    }>;
    primaryContactPeople: Array<{ id: string; name: string }>;
    status?: { id: string; name: string; buyingOpportunity?: string | null } | null;
    primaryUser?: {
        id: string;
        name?: string | null;
        code?: string | null;
        salesTeams: Array<{ id: string; name: string }>;
    } | null;
    mapBudgetProducts: Array<{
        id: string;
        qty: number;
        budgetProduct: {
            id: string;
            name: string;
            isActive: boolean;
            brand?: string | null;
            salesTeamGroup: { id: string };
        };
    }>;
    customer?: {
        id: string;
        name: string;
        code?: string | null;
        phone?: string | null;
        address?: string | null;
        contactPersonName?: string | null;
        contactPersonPhone?: string | null;
        province?: {
            id: string;
            name?: string | null;
            cities: Array<{
                id: string;
                name: string;
                districts: Array<{ id: string; name: string }>;
            }>;
        } | null;
        city?: { id: string; name: string; districts: Array<{ id: string; name: string }> } | null;
        district?: { id: string; name: string } | null;
        eyeServiceOrders: Array<{ id: string; code: string }>;
    } | null;
    createdUser?: { id: string; name?: string | null; code?: string | null } | null;
    updatedUser?: { id: string; name?: string | null; code?: string | null } | null;
    users: Array<{
        id: string;
        userId: string;
        salesTeamId?: string | null;
        user: { id: string; name?: string | null };
        salesTeam?: { name: string; id: string } | null;
    }>;
    competitors: Array<{ id: string; name: string }>;
    winningOpportunity?: { id: string; name: string; code?: string | null } | null;
    buyingOpportunity?: { id: string; name: string } | null;
    mapDealProducts: Array<{
        id: string;
        qty: number;
        dealProduct: {
            id: string;
            name: string;
            isActive: boolean;
            brand?: string | null;
            salesTeamGroup: { id: string };
        };
    }>;
    salesTeamUnit?: {
        id: string;
        name: string;
        user?: { id: string; name?: string | null } | null;
        salesTeam?: { id: string; name: string; code?: string | null } | null;
    } | null;
};

export type UpdateBusinessMutationVariables = Exact<{
    input: BusinessUpdateInput;
}>;

export type UpdateBusinessMutation = { updateBusiness: { id: string; code?: string | null } };

export type CreateBusinessMutationVariables = Exact<{
    input: BusinessCreateInput;
}>;

export type CreateBusinessMutation = { createBusiness: { id: string; code?: string | null } };

export type CreateBusinessMemoMutationVariables = Exact<{
    input: BusinessMemoCreateInput;
}>;

export type CreateBusinessMemoMutation = { createBusinessMemo: { id: string } };

export type UpdateBusinessMemoMutationVariables = Exact<{
    input: BusinessMemoUpdateInput;
}>;

export type UpdateBusinessMemoMutation = { updateBusinessMemo: { id: string } };

export type DeleteBusinessMutationVariables = Exact<{
    deleteBusinessId: Scalars['ID'];
}>;

export type DeleteBusinessMutation = { deleteBusiness: boolean };

export type BusinessesQueryVariables = Exact<{
    filters?: InputMaybe<BusinessFilterInput>;
}>;

export type BusinessesQuery = {
    businesses: Array<{
        id: string;
        content?: string | null;
        title?: string | null;
        eyeQuotationOrderCode?: string | null;
        code?: string | null;
        typeId?: string | null;
        budgetAmount?: number | null;
        saleAmount?: number | null;
        dealAmount?: number | null;
        expectedClosedDate?: any | null;
        closedDate?: any | null;
        createdAt: any;
        orderCode?: string | null;
        updatedAt: any;
        losingReason?: string | null;
        losingImprovementPlan?: string | null;
        salesMethodId?: string | null;
        dealerId?: string | null;
        salesTeamUnitId?: string | null;
        dealer?: { id: string; name: string; code?: string | null; address?: string | null } | null;
        losingReasons: Array<{ id: string; name: string }>;
        properties: Array<{
            id: string;
            name: string;
            typeId: string;
            type: { name: string; id: string; code?: string | null };
        }>;
        primaryContactPeople: Array<{ id: string; name: string }>;
        status?: { id: string; name: string; buyingOpportunity?: string | null } | null;
        primaryUser?: {
            id: string;
            name?: string | null;
            code?: string | null;
            salesTeams: Array<{ id: string; name: string }>;
        } | null;
        mapBudgetProducts: Array<{
            id: string;
            qty: number;
            budgetProduct: {
                id: string;
                name: string;
                isActive: boolean;
                brand?: string | null;
                salesTeamGroup: { id: string };
            };
        }>;
        customer?: {
            id: string;
            name: string;
            code?: string | null;
            phone?: string | null;
            address?: string | null;
            contactPersonName?: string | null;
            contactPersonPhone?: string | null;
            province?: {
                id: string;
                name?: string | null;
                cities: Array<{
                    id: string;
                    name: string;
                    districts: Array<{ id: string; name: string }>;
                }>;
            } | null;
            city?: {
                id: string;
                name: string;
                districts: Array<{ id: string; name: string }>;
            } | null;
            district?: { id: string; name: string } | null;
            eyeServiceOrders: Array<{ id: string; code: string }>;
        } | null;
        createdUser?: { id: string; name?: string | null; code?: string | null } | null;
        updatedUser?: { id: string; name?: string | null; code?: string | null } | null;
        users: Array<{
            id: string;
            userId: string;
            salesTeamId?: string | null;
            user: { id: string; name?: string | null };
            salesTeam?: { name: string; id: string } | null;
        }>;
        competitors: Array<{ id: string; name: string }>;
        winningOpportunity?: { id: string; name: string; code?: string | null } | null;
        buyingOpportunity?: { id: string; name: string } | null;
        mapDealProducts: Array<{
            id: string;
            qty: number;
            dealProduct: {
                id: string;
                name: string;
                isActive: boolean;
                brand?: string | null;
                salesTeamGroup: { id: string };
            };
        }>;
        salesTeamUnit?: {
            id: string;
            name: string;
            user?: { id: string; name?: string | null } | null;
            salesTeam?: { id: string; name: string; code?: string | null } | null;
        } | null;
    }>;
};

export type PaginatedBusinessesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    sorts?: InputMaybe<Array<BusinessSortInput> | BusinessSortInput>;
    filters?: InputMaybe<BusinessFilterInput>;
}>;

export type PaginatedBusinessesQuery = {
    paginatedBusinesses: {
        count?: number | null;
        budgetAmountSum: number;
        businesses?: Array<{
            id: string;
            content?: string | null;
            title?: string | null;
            eyeQuotationOrderCode?: string | null;
            code?: string | null;
            typeId?: string | null;
            budgetAmount?: number | null;
            saleAmount?: number | null;
            dealAmount?: number | null;
            expectedClosedDate?: any | null;
            closedDate?: any | null;
            createdAt: any;
            orderCode?: string | null;
            updatedAt: any;
            losingReason?: string | null;
            losingImprovementPlan?: string | null;
            salesMethodId?: string | null;
            dealerId?: string | null;
            salesTeamUnitId?: string | null;
            dealer?: {
                id: string;
                name: string;
                code?: string | null;
                address?: string | null;
            } | null;
            losingReasons: Array<{ id: string; name: string }>;
            properties: Array<{
                id: string;
                name: string;
                typeId: string;
                type: { name: string; id: string; code?: string | null };
            }>;
            primaryContactPeople: Array<{ id: string; name: string }>;
            status?: { id: string; name: string; buyingOpportunity?: string | null } | null;
            primaryUser?: {
                id: string;
                name?: string | null;
                code?: string | null;
                salesTeams: Array<{ id: string; name: string }>;
            } | null;
            mapBudgetProducts: Array<{
                id: string;
                qty: number;
                budgetProduct: {
                    id: string;
                    name: string;
                    isActive: boolean;
                    brand?: string | null;
                    salesTeamGroup: { id: string };
                };
            }>;
            customer?: {
                id: string;
                name: string;
                code?: string | null;
                phone?: string | null;
                address?: string | null;
                contactPersonName?: string | null;
                contactPersonPhone?: string | null;
                province?: {
                    id: string;
                    name?: string | null;
                    cities: Array<{
                        id: string;
                        name: string;
                        districts: Array<{ id: string; name: string }>;
                    }>;
                } | null;
                city?: {
                    id: string;
                    name: string;
                    districts: Array<{ id: string; name: string }>;
                } | null;
                district?: { id: string; name: string } | null;
                eyeServiceOrders: Array<{ id: string; code: string }>;
            } | null;
            createdUser?: { id: string; name?: string | null; code?: string | null } | null;
            updatedUser?: { id: string; name?: string | null; code?: string | null } | null;
            users: Array<{
                id: string;
                userId: string;
                salesTeamId?: string | null;
                user: { id: string; name?: string | null };
                salesTeam?: { name: string; id: string } | null;
            }>;
            competitors: Array<{ id: string; name: string }>;
            winningOpportunity?: { id: string; name: string; code?: string | null } | null;
            buyingOpportunity?: { id: string; name: string } | null;
            mapDealProducts: Array<{
                id: string;
                qty: number;
                dealProduct: {
                    id: string;
                    name: string;
                    isActive: boolean;
                    brand?: string | null;
                    salesTeamGroup: { id: string };
                };
            }>;
            salesTeamUnit?: {
                id: string;
                name: string;
                user?: { id: string; name?: string | null } | null;
                salesTeam?: { id: string; name: string; code?: string | null } | null;
            } | null;
        }> | null;
    };
};

export type BusinessQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type BusinessQuery = {
    business: {
        id: string;
        content?: string | null;
        title?: string | null;
        eyeQuotationOrderCode?: string | null;
        code?: string | null;
        typeId?: string | null;
        budgetAmount?: number | null;
        saleAmount?: number | null;
        dealAmount?: number | null;
        expectedClosedDate?: any | null;
        closedDate?: any | null;
        createdAt: any;
        orderCode?: string | null;
        updatedAt: any;
        losingReason?: string | null;
        losingImprovementPlan?: string | null;
        salesMethodId?: string | null;
        dealerId?: string | null;
        salesTeamUnitId?: string | null;
        dealer?: { id: string; name: string; code?: string | null; address?: string | null } | null;
        losingReasons: Array<{ id: string; name: string }>;
        properties: Array<{
            id: string;
            name: string;
            typeId: string;
            type: { name: string; id: string; code?: string | null };
        }>;
        primaryContactPeople: Array<{ id: string; name: string }>;
        status?: { id: string; name: string; buyingOpportunity?: string | null } | null;
        primaryUser?: {
            id: string;
            name?: string | null;
            code?: string | null;
            salesTeams: Array<{ id: string; name: string }>;
        } | null;
        mapBudgetProducts: Array<{
            id: string;
            qty: number;
            budgetProduct: {
                id: string;
                name: string;
                isActive: boolean;
                brand?: string | null;
                salesTeamGroup: { id: string };
            };
        }>;
        customer?: {
            id: string;
            name: string;
            code?: string | null;
            phone?: string | null;
            address?: string | null;
            contactPersonName?: string | null;
            contactPersonPhone?: string | null;
            province?: {
                id: string;
                name?: string | null;
                cities: Array<{
                    id: string;
                    name: string;
                    districts: Array<{ id: string; name: string }>;
                }>;
            } | null;
            city?: {
                id: string;
                name: string;
                districts: Array<{ id: string; name: string }>;
            } | null;
            district?: { id: string; name: string } | null;
            eyeServiceOrders: Array<{ id: string; code: string }>;
        } | null;
        createdUser?: { id: string; name?: string | null; code?: string | null } | null;
        updatedUser?: { id: string; name?: string | null; code?: string | null } | null;
        users: Array<{
            id: string;
            userId: string;
            salesTeamId?: string | null;
            user: { id: string; name?: string | null };
            salesTeam?: { name: string; id: string } | null;
        }>;
        competitors: Array<{ id: string; name: string }>;
        winningOpportunity?: { id: string; name: string; code?: string | null } | null;
        buyingOpportunity?: { id: string; name: string } | null;
        mapDealProducts: Array<{
            id: string;
            qty: number;
            dealProduct: {
                id: string;
                name: string;
                isActive: boolean;
                brand?: string | null;
                salesTeamGroup: { id: string };
            };
        }>;
        salesTeamUnit?: {
            id: string;
            name: string;
            user?: { id: string; name?: string | null } | null;
            salesTeam?: { id: string; name: string; code?: string | null } | null;
        } | null;
    };
};

export type BusinessOpportunitiesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<BusinessOpportunityFilterInput>;
}>;

export type BusinessOpportunitiesQuery = {
    businessOpportunities: Array<{
        id: string;
        name: string;
        code?: string | null;
        enabled: boolean;
        salesTeamGroup: { id: string; name: string; teams: Array<{ id: string; name: string }> };
    }>;
};

export type BusinessProductsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<BusinessProductFilterInput>;
}>;

export type BusinessProductsQuery = {
    businessProducts: Array<{
        id: string;
        name: string;
        isActive: boolean;
        brand?: string | null;
        salesTeamGroup: { id: string };
    }>;
};

export type BusinessStatusesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<BusinessStatusFilterInput>;
}>;

export type BusinessStatusesQuery = {
    businessStatuses: Array<{
        name: string;
        id: string;
        type?: EnumBusinessStatusType | null;
        buyingOpportunity?: string | null;
    }>;
};

export type BusinessLosingReasonsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<BusinessLosingReasonFilterInput>;
}>;

export type BusinessLosingReasonsQuery = {
    businessLosingReasons: Array<{ name: string; id: string; salesTeamGroupId: string }>;
};

export type BusinessTypesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<BusinessTypeFilterInput>;
}>;

export type BusinessTypesQuery = {
    businessTypes: Array<{ name: string; id: string; salesTeamGroupId: string }>;
};

export type BusinessPropertyTypesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<BusinessPropertyTypeFilterInput>;
}>;

export type BusinessPropertyTypesQuery = {
    businessPropertyTypes: Array<{
        name: string;
        id: string;
        salesTeamGroupId: string;
        code?: string | null;
        properties: Array<{ id: string; name: string }>;
    }>;
};

export type BusinessPropertiesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<BusinessPropertyFilterInput>;
}>;

export type BusinessPropertiesQuery = {
    businessProperties: Array<{
        name: string;
        id: string;
        typeId: string;
        type: {
            id: string;
            name: string;
            code?: string | null;
            properties: Array<{ id: string; name: string }>;
        };
    }>;
};

export type BusinessesAnalysisDetailQueryVariables = Exact<{
    expectedClosedDate1?: InputMaybe<Scalars['String']>;
    expectedClosedDate2?: InputMaybe<Scalars['String']>;
    primaryUserIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
    pagination?: InputMaybe<PaginationInput>;
    budgetProductIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type BusinessesAnalysisDetailQuery = {
    High: {
        count?: number | null;
        budgetAmountSum: number;
        businesses?: Array<{
            id: string;
            saleAmount?: number | null;
            budgetAmount?: number | null;
            code?: string | null;
            expectedClosedDate?: any | null;
            buyingOpportunity?: { name: string } | null;
            winningOpportunity?: { name: string } | null;
            customer?: { name: string } | null;
            mapBudgetProducts: Array<{ budgetProduct: { id: string; name: string } }>;
            primaryUser?: {
                id: string;
                code?: string | null;
                name?: string | null;
                salesTeams: Array<{ id: string; name: string }>;
            } | null;
            status?: { id: string; name: string; buyingOpportunity?: string | null } | null;
        }> | null;
    };
    MediumHigh: {
        count?: number | null;
        budgetAmountSum: number;
        businesses?: Array<{
            id: string;
            saleAmount?: number | null;
            budgetAmount?: number | null;
            code?: string | null;
            expectedClosedDate?: any | null;
            buyingOpportunity?: { name: string } | null;
            winningOpportunity?: { name: string } | null;
            customer?: { name: string } | null;
            mapBudgetProducts: Array<{ budgetProduct: { name: string } }>;
            primaryUser?: {
                id: string;
                code?: string | null;
                name?: string | null;
                salesTeams: Array<{ id: string; name: string }>;
            } | null;
            status?: { id: string; name: string; buyingOpportunity?: string | null } | null;
        }> | null;
    };
    Medium: {
        count?: number | null;
        budgetAmountSum: number;
        businesses?: Array<{
            id: string;
            saleAmount?: number | null;
            budgetAmount?: number | null;
            code?: string | null;
            expectedClosedDate?: any | null;
            buyingOpportunity?: { name: string } | null;
            winningOpportunity?: { name: string } | null;
            customer?: { name: string } | null;
            mapBudgetProducts: Array<{ budgetProduct: { name: string } }>;
            primaryUser?: {
                id: string;
                code?: string | null;
                name?: string | null;
                salesTeams: Array<{ id: string; name: string }>;
            } | null;
            status?: { id: string; name: string; buyingOpportunity?: string | null } | null;
        }> | null;
    };
};

export type BusinessAnalysisBusinessStatFragmentFragment = {
    details: Array<{
        type: EnumBusinessStatType;
        amount: number;
        amountPercent?: number | null;
        count: number;
        countPercent?: number | null;
    }>;
    overall: { amount: number; count: number };
};

export type ProductRankFragmentFragment = {
    rank: number;
    businessProductId: string;
    qty: number;
    businessProduct: { id: string; name: string };
};

export type BusinessAnalysisQueryVariables = Exact<{
    expectedClosedDate1?: InputMaybe<Scalars['String']>;
    expectedClosedDate2?: InputMaybe<Scalars['String']>;
    primaryUserIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
    budgetProductIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type BusinessAnalysisQuery = {
    businessAnalysis: {
        High: Array<{
            rank: number;
            businessProductId: string;
            qty: number;
            businessProduct: { id: string; name: string };
        }>;
        MediumHigh: Array<{
            rank: number;
            businessProductId: string;
            qty: number;
            businessProduct: { id: string; name: string };
        }>;
        Medium: Array<{
            rank: number;
            businessProductId: string;
            qty: number;
            businessProduct: { id: string; name: string };
        }>;
        Default: {
            details: Array<{
                type: EnumBusinessStatType;
                amount: number;
                amountPercent?: number | null;
                count: number;
                countPercent?: number | null;
            }>;
            overall: { amount: number; count: number };
        };
        InProgress: {
            details: Array<{
                type: EnumBusinessStatType;
                amount: number;
                amountPercent?: number | null;
                count: number;
                countPercent?: number | null;
            }>;
            overall: { amount: number; count: number };
        };
    };
};

export type PaginatedBusinessSalesMethodsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<BusinessSalesMethodFilterInput>;
}>;

export type PaginatedBusinessSalesMethodsQuery = {
    paginatedBusinessSalesMethods: {
        count?: number | null;
        businessSalesMethods?: Array<{
            id: string;
            name: string;
            code?: EnumBusinessSalesMethodCode | null;
        }> | null;
    };
};

export type PaginatedCurrenciesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<CurrencyFilterInput>;
}>;

export type PaginatedCurrenciesQuery = {
    paginatedCurrencies: {
        count?: number | null;
        currencies?: Array<{ id: string; name: string; code: string; decimalPlace: number }> | null;
    };
};

export type PaginatedPaymentMethodsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<PaymentMethodFilterInput>;
}>;

export type PaginatedPaymentMethodsQuery = {
    paginatedPaymentMethods: {
        count?: number | null;
        paymentMethods?: Array<{ id: string; name: string }> | null;
    };
};

export type PaginatedRegionsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<RegionFilterInput>;
}>;

export type PaginatedRegionsQuery = {
    paginatedRegions: {
        count?: number | null;
        regions?: Array<{ id: string; name: string }> | null;
    };
};

export type CreateCompetitorMutationVariables = Exact<{
    input: CompetitorCreateInput;
}>;

export type CreateCompetitorMutation = { createCompetitor: { id: string } };

export type UpdateCompetitorMutationVariables = Exact<{
    input: CompetitorUpdateInput;
}>;

export type UpdateCompetitorMutation = { updateCompetitor: { id: string } };

export type CompetitorsQueryVariables = Exact<{
    filters?: InputMaybe<CompetitorFilterInput>;
}>;

export type CompetitorsQuery = {
    competitors: Array<{
        id: string;
        name: string;
        advantage?: string | null;
        disadvantage?: string | null;
        strategy?: string | null;
        memo?: string | null;
        brand?: string | null;
        model?: string | null;
        agents?: string | null;
        createdAt: any;
        updatedAt: any;
        salesTeamGroup: { id: string; name: string };
        createdUser?: { id: string; name?: string | null } | null;
        updatedUser?: { id: string; name?: string | null } | null;
        businessProducts?: Array<{ id: string; name: string }> | null;
    }>;
};

export type PaginatedCompetitorsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<CompetitorFilterInput>;
}>;

export type PaginatedCompetitorsQuery = {
    paginatedCompetitors: {
        count?: number | null;
        competitors?: Array<{
            id: string;
            name: string;
            advantage?: string | null;
            disadvantage?: string | null;
            strategy?: string | null;
            memo?: string | null;
            brand?: string | null;
            model?: string | null;
            agents?: string | null;
            createdAt: any;
            updatedAt: any;
            salesTeamGroup: { id: string; name: string };
            createdUser?: { id: string; name?: string | null } | null;
            updatedUser?: { id: string; name?: string | null } | null;
            businessProducts?: Array<{ id: string; name: string }> | null;
        }> | null;
    };
};

export type CompetitorQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type CompetitorQuery = {
    competitor: {
        id: string;
        name: string;
        advantage?: string | null;
        disadvantage?: string | null;
        strategy?: string | null;
        memo?: string | null;
        brand?: string | null;
        model?: string | null;
        agents?: string | null;
        createdAt: any;
        updatedAt: any;
        salesTeamGroup: { id: string; name: string };
        createdUser?: { id: string; name?: string | null } | null;
        updatedUser?: { id: string; name?: string | null } | null;
        businessProducts?: Array<{ id: string; name: string }> | null;
    };
};

export type UpdateContactPersonMutationVariables = Exact<{
    input: ContactPersonUpdateInput;
}>;

export type UpdateContactPersonMutation = { updateContactPerson: { id: string } };

export type CreateContactPersonMutationVariables = Exact<{
    input: ContactPersonCreateInput;
}>;

export type CreateContactPersonMutation = {
    createContactPerson: { id: string; code?: string | null; name: string };
};

export type DeleteContactPersonMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DeleteContactPersonMutation = { deleteContactPerson: boolean };

export type DeleteCustomerToContactPeopleMutationVariables = Exact<{
    input: DeleteCustomerToContactPeopleInput;
}>;

export type DeleteCustomerToContactPeopleMutation = {
    deleteCustomerToContactPeople: { id: string };
};

export type CreateCustomerToContactPeopleMutationVariables = Exact<{
    input: CreateCustomerToContactPeopleInput;
}>;

export type CreateCustomerToContactPeopleMutation = {
    createCustomerToContactPeople: { id: string };
};

export type PaginatedContactPeopleQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<ContactPersonFilterInput>;
}>;

export type PaginatedContactPeopleQuery = {
    paginatedContactPeople: {
        count?: number | null;
        contactPeople?: Array<{
            id: string;
            name: string;
            jobTitle?: string | null;
            mobile?: string | null;
            email?: string | null;
            address?: string | null;
            gender?: EnumContactPeopleGender | null;
            doctorCode?: string | null;
            memo?: string | null;
            phone?: string | null;
            fax?: string | null;
            createdAt: any;
            updatedAt: any;
            code?: string | null;
            departmentId?: string | null;
            citizenCode?: string | null;
            skill?: string | null;
            salesTeamUnits: Array<{
                id: string;
                name: string;
                user?: { id: string; name?: string | null } | null;
                salesTeam?: { id: string; name: string; code?: string | null } | null;
            }>;
            department?: { id: string; name: string } | null;
            salesTeamGroup: { name: string; id: string; code?: EnumSalesTeamGroupCode | null };
            customers: Array<{ name: string; id: string; code?: string | null }>;
            primaryUsers: Array<{ id: string; name?: string | null; code?: string | null }>;
            createdUser?: { id: string; name?: string | null } | null;
            updatedUser?: { id: string; name?: string | null } | null;
        }> | null;
    };
};

export type ContactPersonQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type ContactPersonQuery = {
    contactPerson: {
        id: string;
        name: string;
        jobTitle?: string | null;
        mobile?: string | null;
        email?: string | null;
        address?: string | null;
        gender?: EnumContactPeopleGender | null;
        doctorCode?: string | null;
        memo?: string | null;
        phone?: string | null;
        fax?: string | null;
        createdAt: any;
        updatedAt: any;
        code?: string | null;
        departmentId?: string | null;
        citizenCode?: string | null;
        skill?: string | null;
        salesTeamUnits: Array<{
            id: string;
            name: string;
            user?: { id: string; name?: string | null } | null;
            salesTeam?: { id: string; name: string; code?: string | null } | null;
        }>;
        department?: { id: string; name: string } | null;
        salesTeamGroup: { name: string; id: string; code?: EnumSalesTeamGroupCode | null };
        customers: Array<{ name: string; id: string; code?: string | null }>;
        primaryUsers: Array<{ id: string; name?: string | null; code?: string | null }>;
        createdUser?: { id: string; name?: string | null } | null;
        updatedUser?: { id: string; name?: string | null } | null;
    };
};

export type ContactPeopleInfoFragment = {
    id: string;
    name: string;
    jobTitle?: string | null;
    mobile?: string | null;
    email?: string | null;
    address?: string | null;
    gender?: EnumContactPeopleGender | null;
    doctorCode?: string | null;
    memo?: string | null;
    phone?: string | null;
    fax?: string | null;
    createdAt: any;
    updatedAt: any;
    code?: string | null;
    departmentId?: string | null;
    citizenCode?: string | null;
    skill?: string | null;
    salesTeamUnits: Array<{
        id: string;
        name: string;
        user?: { id: string; name?: string | null } | null;
        salesTeam?: { id: string; name: string; code?: string | null } | null;
    }>;
    department?: { id: string; name: string } | null;
    salesTeamGroup: { name: string; id: string; code?: EnumSalesTeamGroupCode | null };
    customers: Array<{ name: string; id: string; code?: string | null }>;
    primaryUsers: Array<{ id: string; name?: string | null; code?: string | null }>;
    createdUser?: { id: string; name?: string | null } | null;
    updatedUser?: { id: string; name?: string | null } | null;
};

export type ContactPersonDepartmentsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<ContactPersonDepartmentFilterInput>;
}>;

export type ContactPersonDepartmentsQuery = {
    contactPersonDepartments: Array<{ id: string; name: string }>;
};

export type CustomerFragment = {
    id: string;
    name: string;
    isActive: boolean;
    code?: string | null;
    address?: string | null;
    businessCode?: string | null;
    medicalCode?: string | null;
    memo?: string | null;
    phone?: string | null;
    email?: string | null;
    fax?: string | null;
    mobile?: string | null;
    contactPersonPhone?: string | null;
    yonyouCode?: string | null;
    do1Code?: string | null;
    navCode?: string | null;
    creditQuota?: number | null;
    shippingAddress?: string | null;
    contactPersonName?: string | null;
    bankAccountCode?: string | null;
    bankAccountName?: string | null;
    legalPersonName?: string | null;
    billingUnitName?: string | null;
    hasEffectiveGmpCertificate?: boolean | null;
    isGmp?: boolean | null;
    lastGmpBpmInstanceId?: string | null;
    lastGmpBpmStatus?: EnumLastGmpBpmStatus | null;
    createdAt: any;
    updatedAt: any;
    cityId?: string | null;
    districtId?: string | null;
    categoryId?: string | null;
    areaId?: string | null;
    type?: { id: string; name: string } | null;
    area?: { id: string; name: string } | null;
    primaryUsers: Array<{
        id: string;
        userId: string;
        salesTeamId?: string | null;
        user: { id: string; name?: string | null };
    }>;
    salesTeamGroup: {
        id: string;
        name: string;
        region?: { id: string; code?: string | null; name: string } | null;
        teams: Array<{ id: string; name: string }>;
    };
    creditPeriod?: { id: string; name: string } | null;
    district?: { id: string; name: string } | null;
    province?: {
        id: string;
        name?: string | null;
        cities: Array<{ id: string; name: string; districts: Array<{ id: string; name: string }> }>;
    } | null;
    createdUser?: { id: string; name?: string | null; createdAt: any; updatedAt: any } | null;
    updatedUser?: { id: string; name?: string | null; createdAt: any; updatedAt: any } | null;
    contactPeople: Array<{
        id: string;
        name: string;
        phone?: string | null;
        mobile?: string | null;
        fax?: string | null;
        gender?: EnumContactPeopleGender | null;
        doctorCode?: string | null;
        jobTitle?: string | null;
        dept?: string | null;
    }>;
    attachments: Array<{ id: string; name: string; s3Key: string; memo?: string | null }>;
    eyeServiceOrders: Array<{ id: string; code: string }>;
    city?: { id: string; name: string; districts: Array<{ id: string; name: string }> } | null;
    salesTeamUnits: Array<{
        id: string;
        name: string;
        salesTeam?: { id: string; name: string; code?: string | null } | null;
        user?: { id: string; name?: string | null; code?: string | null } | null;
    }>;
    levels: Array<{
        id: string;
        name: string;
        typeId: string;
        type: { id: string; name: string; code?: EnumCustomerLevelTypeCode | null };
    }>;
};

export type UpdateCustomerMutationVariables = Exact<{
    input: CustomerUpdateInput;
}>;

export type UpdateCustomerMutation = {
    updateCustomer: { id: string; name: string; code?: string | null };
};

export type CreateCustomerMutationVariables = Exact<{
    input: CustomerCreateInput;
}>;

export type CreateCustomerMutation = {
    createCustomer: { id: string; name: string; code?: string | null };
};

export type CreateCustomerCertificateMutationVariables = Exact<{
    input: CustomerCertificateCreateInput;
}>;

export type CreateCustomerCertificateMutation = {
    createCustomerCertificate: { id: string; code?: string | null };
};

export type UpdateCustomerCertificateMutationVariables = Exact<{
    input: CustomerCertificateUpdateInput;
}>;

export type UpdateCustomerCertificateMutation = {
    updateCustomerCertificate: { id: string; code?: string | null };
};

export type CreateCustomerAttachmentMutationVariables = Exact<{
    input: CustomerAttachmentCreateInput;
}>;

export type CreateCustomerAttachmentMutation = {
    createCustomerAttachment: { id: string; url?: any | null; name: string; s3Key: string };
};

export type UpdateCustomerAttachmentMutationVariables = Exact<{
    input: CustomerAttachmentUpdateInput;
}>;

export type UpdateCustomerAttachmentMutation = {
    updateCustomerAttachment: { id: string; url?: any | null; name: string; s3Key: string };
};

export type CreateCustomerCertificateAttachmentMutationVariables = Exact<{
    input: CustomerCertificateAttachmentCreateInput;
}>;

export type CreateCustomerCertificateAttachmentMutation = {
    createCustomerCertificateAttachment: {
        id: string;
        url?: any | null;
        name: string;
        s3Key: string;
    };
};

export type UpdateCustomerCertificateAttachmentMutationVariables = Exact<{
    input: CustomerCertificateAttachmentUpdateInput;
}>;

export type UpdateCustomerCertificateAttachmentMutation = {
    updateCustomerCertificateAttachment: {
        id: string;
        url?: any | null;
        name: string;
        s3Key: string;
    };
};

export type DeleteCustomerCertificateAttachmentMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DeleteCustomerCertificateAttachmentMutation = {
    deleteCustomerCertificateAttachment: boolean;
};

export type BulkCreateCustomerAttachmentMutationVariables = Exact<{
    input: CustomerAttachmentBulkCreateInput;
}>;

export type BulkCreateCustomerAttachmentMutation = {
    bulkCreateCustomerAttachment: Array<{
        id: string;
        url?: any | null;
        name: string;
        s3Key: string;
    }>;
};

export type DeleteCustomerAttachmentMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DeleteCustomerAttachmentMutation = { deleteCustomerAttachment: boolean };

export type DeleteCustomerCertificateMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DeleteCustomerCertificateMutation = { deleteCustomerCertificate: boolean };

export type CustomersQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<CustomerFilterInput>;
}>;

export type CustomersQuery = {
    customers: Array<{
        id: string;
        name: string;
        isActive: boolean;
        code?: string | null;
        address?: string | null;
        businessCode?: string | null;
        medicalCode?: string | null;
        memo?: string | null;
        phone?: string | null;
        email?: string | null;
        fax?: string | null;
        mobile?: string | null;
        contactPersonPhone?: string | null;
        yonyouCode?: string | null;
        do1Code?: string | null;
        navCode?: string | null;
        creditQuota?: number | null;
        shippingAddress?: string | null;
        contactPersonName?: string | null;
        bankAccountCode?: string | null;
        bankAccountName?: string | null;
        legalPersonName?: string | null;
        billingUnitName?: string | null;
        hasEffectiveGmpCertificate?: boolean | null;
        isGmp?: boolean | null;
        lastGmpBpmInstanceId?: string | null;
        lastGmpBpmStatus?: EnumLastGmpBpmStatus | null;
        createdAt: any;
        updatedAt: any;
        cityId?: string | null;
        districtId?: string | null;
        categoryId?: string | null;
        areaId?: string | null;
        type?: { id: string; name: string } | null;
        area?: { id: string; name: string } | null;
        primaryUsers: Array<{
            id: string;
            userId: string;
            salesTeamId?: string | null;
            user: { id: string; name?: string | null };
        }>;
        salesTeamGroup: {
            id: string;
            name: string;
            region?: { id: string; code?: string | null; name: string } | null;
            teams: Array<{ id: string; name: string }>;
        };
        creditPeriod?: { id: string; name: string } | null;
        district?: { id: string; name: string } | null;
        province?: {
            id: string;
            name?: string | null;
            cities: Array<{
                id: string;
                name: string;
                districts: Array<{ id: string; name: string }>;
            }>;
        } | null;
        createdUser?: { id: string; name?: string | null; createdAt: any; updatedAt: any } | null;
        updatedUser?: { id: string; name?: string | null; createdAt: any; updatedAt: any } | null;
        contactPeople: Array<{
            id: string;
            name: string;
            phone?: string | null;
            mobile?: string | null;
            fax?: string | null;
            gender?: EnumContactPeopleGender | null;
            doctorCode?: string | null;
            jobTitle?: string | null;
            dept?: string | null;
        }>;
        attachments: Array<{ id: string; name: string; s3Key: string; memo?: string | null }>;
        eyeServiceOrders: Array<{ id: string; code: string }>;
        city?: { id: string; name: string; districts: Array<{ id: string; name: string }> } | null;
        salesTeamUnits: Array<{
            id: string;
            name: string;
            salesTeam?: { id: string; name: string; code?: string | null } | null;
            user?: { id: string; name?: string | null; code?: string | null } | null;
        }>;
        levels: Array<{
            id: string;
            name: string;
            typeId: string;
            type: { id: string; name: string; code?: EnumCustomerLevelTypeCode | null };
        }>;
    }>;
};

export type PaginatedCustomersQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<CustomerFilterInput>;
}>;

export type PaginatedCustomersQuery = {
    paginatedCustomers: {
        count?: number | null;
        customers?: Array<{
            id: string;
            name: string;
            isActive: boolean;
            code?: string | null;
            address?: string | null;
            businessCode?: string | null;
            medicalCode?: string | null;
            memo?: string | null;
            phone?: string | null;
            email?: string | null;
            fax?: string | null;
            mobile?: string | null;
            contactPersonPhone?: string | null;
            yonyouCode?: string | null;
            do1Code?: string | null;
            navCode?: string | null;
            creditQuota?: number | null;
            shippingAddress?: string | null;
            contactPersonName?: string | null;
            bankAccountCode?: string | null;
            bankAccountName?: string | null;
            legalPersonName?: string | null;
            billingUnitName?: string | null;
            hasEffectiveGmpCertificate?: boolean | null;
            isGmp?: boolean | null;
            lastGmpBpmInstanceId?: string | null;
            lastGmpBpmStatus?: EnumLastGmpBpmStatus | null;
            createdAt: any;
            updatedAt: any;
            cityId?: string | null;
            districtId?: string | null;
            categoryId?: string | null;
            areaId?: string | null;
            type?: { id: string; name: string } | null;
            area?: { id: string; name: string } | null;
            primaryUsers: Array<{
                id: string;
                userId: string;
                salesTeamId?: string | null;
                user: { id: string; name?: string | null };
            }>;
            salesTeamGroup: {
                id: string;
                name: string;
                region?: { id: string; code?: string | null; name: string } | null;
                teams: Array<{ id: string; name: string }>;
            };
            creditPeriod?: { id: string; name: string } | null;
            district?: { id: string; name: string } | null;
            province?: {
                id: string;
                name?: string | null;
                cities: Array<{
                    id: string;
                    name: string;
                    districts: Array<{ id: string; name: string }>;
                }>;
            } | null;
            createdUser?: {
                id: string;
                name?: string | null;
                createdAt: any;
                updatedAt: any;
            } | null;
            updatedUser?: {
                id: string;
                name?: string | null;
                createdAt: any;
                updatedAt: any;
            } | null;
            contactPeople: Array<{
                id: string;
                name: string;
                phone?: string | null;
                mobile?: string | null;
                fax?: string | null;
                gender?: EnumContactPeopleGender | null;
                doctorCode?: string | null;
                jobTitle?: string | null;
                dept?: string | null;
            }>;
            attachments: Array<{ id: string; name: string; s3Key: string; memo?: string | null }>;
            eyeServiceOrders: Array<{ id: string; code: string }>;
            city?: {
                id: string;
                name: string;
                districts: Array<{ id: string; name: string }>;
            } | null;
            salesTeamUnits: Array<{
                id: string;
                name: string;
                salesTeam?: { id: string; name: string; code?: string | null } | null;
                user?: { id: string; name?: string | null; code?: string | null } | null;
            }>;
            levels: Array<{
                id: string;
                name: string;
                typeId: string;
                type: { id: string; name: string; code?: EnumCustomerLevelTypeCode | null };
            }>;
        }> | null;
    };
};

export type CustomerQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type CustomerQuery = {
    customer: {
        id: string;
        name: string;
        isActive: boolean;
        code?: string | null;
        address?: string | null;
        businessCode?: string | null;
        medicalCode?: string | null;
        memo?: string | null;
        phone?: string | null;
        email?: string | null;
        fax?: string | null;
        mobile?: string | null;
        contactPersonPhone?: string | null;
        yonyouCode?: string | null;
        do1Code?: string | null;
        navCode?: string | null;
        creditQuota?: number | null;
        shippingAddress?: string | null;
        contactPersonName?: string | null;
        bankAccountCode?: string | null;
        bankAccountName?: string | null;
        legalPersonName?: string | null;
        billingUnitName?: string | null;
        hasEffectiveGmpCertificate?: boolean | null;
        isGmp?: boolean | null;
        lastGmpBpmInstanceId?: string | null;
        lastGmpBpmStatus?: EnumLastGmpBpmStatus | null;
        createdAt: any;
        updatedAt: any;
        cityId?: string | null;
        districtId?: string | null;
        categoryId?: string | null;
        areaId?: string | null;
        type?: { id: string; name: string } | null;
        area?: { id: string; name: string } | null;
        primaryUsers: Array<{
            id: string;
            userId: string;
            salesTeamId?: string | null;
            user: { id: string; name?: string | null };
        }>;
        salesTeamGroup: {
            id: string;
            name: string;
            region?: { id: string; code?: string | null; name: string } | null;
            teams: Array<{ id: string; name: string }>;
        };
        creditPeriod?: { id: string; name: string } | null;
        district?: { id: string; name: string } | null;
        province?: {
            id: string;
            name?: string | null;
            cities: Array<{
                id: string;
                name: string;
                districts: Array<{ id: string; name: string }>;
            }>;
        } | null;
        createdUser?: { id: string; name?: string | null; createdAt: any; updatedAt: any } | null;
        updatedUser?: { id: string; name?: string | null; createdAt: any; updatedAt: any } | null;
        contactPeople: Array<{
            id: string;
            name: string;
            phone?: string | null;
            mobile?: string | null;
            fax?: string | null;
            gender?: EnumContactPeopleGender | null;
            doctorCode?: string | null;
            jobTitle?: string | null;
            dept?: string | null;
        }>;
        attachments: Array<{ id: string; name: string; s3Key: string; memo?: string | null }>;
        eyeServiceOrders: Array<{ id: string; code: string }>;
        city?: { id: string; name: string; districts: Array<{ id: string; name: string }> } | null;
        salesTeamUnits: Array<{
            id: string;
            name: string;
            salesTeam?: { id: string; name: string; code?: string | null } | null;
            user?: { id: string; name?: string | null; code?: string | null } | null;
        }>;
        levels: Array<{
            id: string;
            name: string;
            typeId: string;
            type: { id: string; name: string; code?: EnumCustomerLevelTypeCode | null };
        }>;
    };
};

export type CustomerCertificatesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<CustomerCertificateFilterInput>;
}>;

export type CustomerCertificatesQuery = {
    customerCertificates: Array<{
        id: string;
        code?: string | null;
        scope?: string | null;
        effectiveDate?: any | null;
        expiryDate?: any | null;
        customer: { id: string; name: string };
        type: { id: string; name: string };
        attachments: Array<{
            id: string;
            name: string;
            s3Key: string;
            url?: any | null;
            memo?: string | null;
        }>;
    }>;
};

export type CustomerAttachmentsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<CustomerAttachmentFilterInput>;
}>;

export type CustomerAttachmentsQuery = {
    customerAttachments: Array<{
        id: string;
        url?: any | null;
        s3Key: string;
        name: string;
        customer: { id: string; name: string };
    }>;
};

export type EyeServiceOrdersQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<EyeServiceOrderFilterInput>;
}>;

export type EyeServiceOrdersQuery = {
    eyeServiceOrders: Array<{
        id: string;
        code: string;
        priority: EnumEyeServiceOrderPriority;
        contactPerson: string;
        contactPhone: string;
        address: string;
        estimatedDate: any;
        status: EnumEyeServiceOrderStatus;
        assigneeUser?: { id: string; name?: string | null } | null;
        approverUser?: { id: string; name?: string | null } | null;
        eyeWarrantyContract?: { id: string; code: string } | null;
        eyeServiceOrderType: { id: string; name: string };
        customer?: { id: string; code?: string | null; name: string } | null;
        assigneeDept?: { id: string; code?: string | null; name: string } | null;
    }>;
};

export type CreditPeriodsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<CreditPeriodFilterInput>;
}>;

export type CreditPeriodsQuery = { creditPeriods: Array<{ id: string; name: string }> };

export type LevelTypeStatQueryVariables = Exact<{
    filters?: InputMaybe<CustomerAnalysisLevelTypeStatFilterInput>;
}>;

export type LevelTypeStatQuery = {
    customerAnalysis: {
        levelTypeStat: {
            details: Array<{
                levelFieldTypeId: string;
                levelFieldType: {
                    code?: EnumCustomerLevelFieldTypeCode | null;
                    name: string;
                    id: string;
                };
                levelFields: Array<{
                    levelFieldId: string;
                    countPercent?: number | null;
                    count: number;
                    levelField?: { id: string; name: string; code?: string | null } | null;
                    areas: Array<{
                        areaId?: string | null;
                        count: number;
                        countPercent?: number | null;
                        area?: { id: string; name: string } | null;
                    }>;
                }>;
            }>;
        };
    };
};

export type RentalRecordsInfoFragment = {
    id: string;
    status: EnumEyeFixedAssetsRentalStatus;
    installDate?: any | null;
    date1: any;
    date2: any;
    dismantleDate?: any | null;
    remark?: string | null;
    region: { id: string; name: string; salesTeamGroups: Array<{ id: string; name: string }> };
    eyeFixedAssets: Array<{
        id: string;
        name: string;
        status: EnumEyeFixedAssetsStatus;
        memo?: string | null;
        currentLocation?: string | null;
        dept?: { id: string; name: string } | null;
        types: Array<{ id: string; name: string; code: EnumEyeFixedAssetTypeCode }>;
        eyeFixedAssetItems: Array<{ materialId: string; materialCode: string }>;
        eyeFixedAssetRentalRecords: Array<{ id: string; date1: any; date2: any }>;
    }>;
};

export type EyeFixedAssetsFragment = {
    id: string;
    name: string;
    status: EnumEyeFixedAssetsStatus;
    memo?: string | null;
    currentLocation?: string | null;
    dept?: { id: string; name: string } | null;
    types: Array<{ id: string; name: string; code: EnumEyeFixedAssetTypeCode }>;
    eyeFixedAssetItems: Array<{ materialId: string; materialCode: string }>;
    eyeFixedAssetRentalRecords: Array<{ id: string; date1: any; date2: any }>;
};

export type EyeFixedAssetTypeFragment = {
    id: string;
    name: string;
    isActive: boolean;
    code: EnumEyeFixedAssetTypeCode;
};

export type EyeFixedAssetRentalGoalFragment = {
    id: string;
    name: string;
    isActive: boolean;
    code?: string | null;
};

export type PaginatedEyeFixedAssetRentalRecordsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<EyeFixedAssetRentalRecordFilterInput>;
}>;

export type PaginatedEyeFixedAssetRentalRecordsQuery = {
    paginatedEyeFixedAssetRentalRecords: {
        count?: number | null;
        eyeFixedAssetRentalRecords?: Array<{
            id: string;
            status: EnumEyeFixedAssetsRentalStatus;
            installDate?: any | null;
            date1: any;
            date2: any;
            dismantleDate?: any | null;
            remark?: string | null;
            region: {
                id: string;
                name: string;
                salesTeamGroups: Array<{ id: string; name: string }>;
            };
            eyeFixedAssets: Array<{
                id: string;
                name: string;
                status: EnumEyeFixedAssetsStatus;
                memo?: string | null;
                currentLocation?: string | null;
                dept?: { id: string; name: string } | null;
                types: Array<{ id: string; name: string; code: EnumEyeFixedAssetTypeCode }>;
                eyeFixedAssetItems: Array<{ materialId: string; materialCode: string }>;
                eyeFixedAssetRentalRecords: Array<{ id: string; date1: any; date2: any }>;
            }>;
        }> | null;
    };
};

export type EyeFixedAssetRentalRecordQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type EyeFixedAssetRentalRecordQuery = {
    eyeFixedAssetRentalRecord: {
        id: string;
        status: EnumEyeFixedAssetsRentalStatus;
        installDate?: any | null;
        date1: any;
        date2: any;
        dismantleDate?: any | null;
        remark?: string | null;
        region: { id: string; name: string; salesTeamGroups: Array<{ id: string; name: string }> };
        eyeFixedAssets: Array<{
            id: string;
            name: string;
            status: EnumEyeFixedAssetsStatus;
            memo?: string | null;
            currentLocation?: string | null;
            dept?: { id: string; name: string } | null;
            types: Array<{ id: string; name: string; code: EnumEyeFixedAssetTypeCode }>;
            eyeFixedAssetItems: Array<{ materialId: string; materialCode: string }>;
            eyeFixedAssetRentalRecords: Array<{ id: string; date1: any; date2: any }>;
        }>;
    };
};

export type EyeFixedAssetsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<EyeFixedAssetFilterInput>;
}>;

export type EyeFixedAssetsQuery = {
    paginatedEyeFixedAssets: {
        count?: number | null;
        eyeFixedAssets?: Array<{
            id: string;
            name: string;
            status: EnumEyeFixedAssetsStatus;
            memo?: string | null;
            currentLocation?: string | null;
            dept?: { id: string; name: string } | null;
            types: Array<{ id: string; name: string; code: EnumEyeFixedAssetTypeCode }>;
            eyeFixedAssetItems: Array<{ materialId: string; materialCode: string }>;
            eyeFixedAssetRentalRecords: Array<{ id: string; date1: any; date2: any }>;
        }> | null;
    };
    eyeFixedAssets: Array<{ id: string; name: string; code: string }>;
};

export type EyeFixedAssetQueryVariables = Exact<{
    eyeFixedAssetId: Scalars['ID'];
}>;

export type EyeFixedAssetQuery = {
    eyeFixedAsset: {
        id: string;
        name: string;
        status: EnumEyeFixedAssetsStatus;
        memo?: string | null;
        currentLocation?: string | null;
        dept?: { id: string; name: string } | null;
        types: Array<{ id: string; name: string; code: EnumEyeFixedAssetTypeCode }>;
        eyeFixedAssetItems: Array<{ materialId: string; materialCode: string }>;
        eyeFixedAssetRentalRecords: Array<{ id: string; date1: any; date2: any }>;
    };
};

export type EyeFixedAssetTypesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<EyeFixedAssetTypeFilterInput>;
}>;

export type EyeFixedAssetTypesQuery = {
    paginatedEyeFixedAssetTypes: {
        eyeFixedAssetTypes?: Array<{
            id: string;
            name: string;
            isActive: boolean;
            code: EnumEyeFixedAssetTypeCode;
        }> | null;
    };
};

export type EyeFixedAssetRentalGoalsQueryVariables = Exact<{ [key: string]: never }>;

export type EyeFixedAssetRentalGoalsQuery = {
    paginatedEyeFixedAssetRentalGoals: {
        eyeFixedAssetRentalGoals?: Array<{
            id: string;
            name: string;
            isActive: boolean;
            code?: string | null;
        }> | null;
    };
};

export type EyeServiceOrderWorkDiarysFragment = {
    id: string;
    hours?: number | null;
    date?: any | null;
    estimatedDate?: any | null;
    estimatedHours?: number | null;
    remark?: string | null;
    status: EnumEyeServiceOrderWorkDiaryStatus;
    eyeServiceOrderWorkDiaryTypeId: string;
    eyeServiceOrder: {
        id: string;
        approvalStatus: EnumEyeServiceOrderApprovalStatus;
        status: EnumEyeServiceOrderStatus;
        code: string;
        contactPerson: string;
        address: string;
        contactPhone: string;
        customer?: { code?: string | null; id: string; name: string } | null;
        assigneeDept?: { id: string; code?: string | null; name: string } | null;
        eyeServiceOrderType: { name: string };
    };
    users: Array<{ id: string; code?: string | null; name?: string | null }>;
    eyeServiceOrderWorkDiaryType: { id: string; name: string };
};

export type CreateEyeServiceOrderWorkDiaryMutationVariables = Exact<{
    input: EyeServiceOrderWorkDiaryCreateInput;
}>;

export type CreateEyeServiceOrderWorkDiaryMutation = {
    createEyeServiceOrderWorkDiary: {
        id: string;
        hours?: number | null;
        date?: any | null;
        estimatedDate?: any | null;
        estimatedHours?: number | null;
        remark?: string | null;
        status: EnumEyeServiceOrderWorkDiaryStatus;
        eyeServiceOrderWorkDiaryTypeId: string;
        eyeServiceOrder: {
            id: string;
            approvalStatus: EnumEyeServiceOrderApprovalStatus;
            status: EnumEyeServiceOrderStatus;
            code: string;
            contactPerson: string;
            address: string;
            contactPhone: string;
            customer?: { code?: string | null; id: string; name: string } | null;
            assigneeDept?: { id: string; code?: string | null; name: string } | null;
            eyeServiceOrderType: { name: string };
        };
        users: Array<{ id: string; code?: string | null; name?: string | null }>;
        eyeServiceOrderWorkDiaryType: { id: string; name: string };
    };
};

export type UpdateEyeServiceOrderWorkDiaryMutationVariables = Exact<{
    input: EyeServiceOrderWorkDiaryUpdateInput;
}>;

export type UpdateEyeServiceOrderWorkDiaryMutation = {
    updateEyeServiceOrderWorkDiary: {
        id: string;
        hours?: number | null;
        date?: any | null;
        estimatedDate?: any | null;
        estimatedHours?: number | null;
        remark?: string | null;
        status: EnumEyeServiceOrderWorkDiaryStatus;
        eyeServiceOrderWorkDiaryTypeId: string;
        eyeServiceOrder: {
            id: string;
            approvalStatus: EnumEyeServiceOrderApprovalStatus;
            status: EnumEyeServiceOrderStatus;
            code: string;
            contactPerson: string;
            address: string;
            contactPhone: string;
            customer?: { code?: string | null; id: string; name: string } | null;
            assigneeDept?: { id: string; code?: string | null; name: string } | null;
            eyeServiceOrderType: { name: string };
        };
        users: Array<{ id: string; code?: string | null; name?: string | null }>;
        eyeServiceOrderWorkDiaryType: { id: string; name: string };
    };
};

export type PaginatedEyeServiceOrderWorkDiariesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<EyeServiceOrderWorkDiaryFilterInput>;
}>;

export type PaginatedEyeServiceOrderWorkDiariesQuery = {
    paginatedEyeServiceOrderWorkDiaries: {
        count?: number | null;
        eyeServiceOrderWorkDiaries?: Array<{
            id: string;
            hours?: number | null;
            date?: any | null;
            estimatedDate?: any | null;
            estimatedHours?: number | null;
            remark?: string | null;
            status: EnumEyeServiceOrderWorkDiaryStatus;
            eyeServiceOrderWorkDiaryTypeId: string;
            eyeServiceOrder: {
                id: string;
                approvalStatus: EnumEyeServiceOrderApprovalStatus;
                status: EnumEyeServiceOrderStatus;
                code: string;
                contactPerson: string;
                address: string;
                contactPhone: string;
                customer?: { code?: string | null; id: string; name: string } | null;
                assigneeDept?: { id: string; code?: string | null; name: string } | null;
                eyeServiceOrderType: { name: string };
            };
            users: Array<{ id: string; code?: string | null; name?: string | null }>;
            eyeServiceOrderWorkDiaryType: { id: string; name: string };
        }> | null;
    };
};

export type EyeServiceOrderWorkDiaryQueryVariables = Exact<{
    eyeServiceOrderWorkDiaryId: Scalars['ID'];
}>;

export type EyeServiceOrderWorkDiaryQuery = {
    eyeServiceOrderWorkDiary: {
        id: string;
        hours?: number | null;
        date?: any | null;
        estimatedDate?: any | null;
        estimatedHours?: number | null;
        remark?: string | null;
        status: EnumEyeServiceOrderWorkDiaryStatus;
        eyeServiceOrderWorkDiaryTypeId: string;
        eyeServiceOrder: {
            id: string;
            approvalStatus: EnumEyeServiceOrderApprovalStatus;
            status: EnumEyeServiceOrderStatus;
            code: string;
            contactPerson: string;
            address: string;
            contactPhone: string;
            customer?: { code?: string | null; id: string; name: string } | null;
            assigneeDept?: { id: string; code?: string | null; name: string } | null;
            eyeServiceOrderType: { name: string };
        };
        users: Array<{ id: string; code?: string | null; name?: string | null }>;
        eyeServiceOrderWorkDiaryType: { id: string; name: string };
    };
};

export type PaginatedEyeServiceOrderWorkDiaryTypesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<EyeServiceOrderWorkDiaryTypeFilterInput>;
}>;

export type PaginatedEyeServiceOrderWorkDiaryTypesQuery = {
    paginatedEyeServiceOrderWorkDiaryTypes: {
        count?: number | null;
        eyeServiceOrderWorkDiaryTypes?: Array<{ id: string; name: string }> | null;
    };
};

export type PaginatedEyeWarrantyContractsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<EyeWarrantyContractFilterInput>;
}>;

export type PaginatedEyeWarrantyContractsQuery = {
    paginatedEyeWarrantyContracts: {
        count?: number | null;
        eyeWarrantyContracts?: Array<{
            id: string;
            orderCode: string;
            approvalCode: string;
            code: string;
            warrantyMonths: number;
            warrantyDate1: any;
            warrantyDate2: any;
            status: EnumEyeWarrantyContractStatus;
            customer: { id: string; name: string; code?: string | null };
            eyeWarrantyPeriodType: { id: string; code: string; name: string };
        }> | null;
    };
};

export type EyeWarrantyContractQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type EyeWarrantyContractQuery = {
    eyeWarrantyContract: {
        id: string;
        orderCode: string;
        approvalCode: string;
        code: string;
        warrantyMonths: number;
        warrantyDate1: any;
        warrantyDate2: any;
        status: EnumEyeWarrantyContractStatus;
        customer: { id: string; name: string; code?: string | null };
        eyeWarrantyPeriodType: { id: string; code: string; name: string };
    };
};

export type EyeWarrantyContractsFragment = {
    id: string;
    orderCode: string;
    approvalCode: string;
    code: string;
    warrantyMonths: number;
    warrantyDate1: any;
    warrantyDate2: any;
    status: EnumEyeWarrantyContractStatus;
    customer: { id: string; name: string; code?: string | null };
    eyeWarrantyPeriodType: { id: string; code: string; name: string };
};

export type EcInventoryModelsQueryVariables = Exact<{ [key: string]: never }>;

export type EcInventoryModelsQuery = {
    EcInventoryModels: Array<{ id: string; name?: string | null }>;
};

export type EcInventorySpecsQueryVariables = Exact<{ [key: string]: never }>;

export type EcInventorySpecsQuery = {
    EcInventorySpecs: Array<{
        id: string;
        name?: string | null;
        model?: { id: string; name?: string | null } | null;
    }>;
};

export type EcInventoryWarehousesQueryVariables = Exact<{ [key: string]: never }>;

export type EcInventoryWarehousesQuery = {
    EcInventoryWarehouses: Array<{ id: string; name?: string | null }>;
};

export type EcInventoryStatQueryVariables = Exact<{
    filter: EcInventoryStatFilterInput;
}>;

export type EcInventoryStatQuery = {
    EcInventoryStat: {
        details: Array<{
            modelId: string;
            qty: number;
            warehouses: Array<{
                warehouse: EnumEcInventoryWarehouse;
                qty: number;
                statuses: Array<{ status: EnumEcInventoryStatus; qty: number }>;
            }>;
            rentalPeriods: Array<{ rentalPeriod: EnumEcInventoryRentalPeriod; qty: number }>;
        }>;
    };
};

export type EcInventoryDetailsQueryVariables = Exact<{
    filter: EcInventoryDetailFilterInput;
}>;

export type EcInventoryDetailsQuery = {
    EcInventoryDetails: Array<{
        key: string;
        model: string;
        spec: string;
        status?: EnumEcInventoryStatus | null;
        rentalPeriod?: EnumEcInventoryRentalPeriod | null;
        warehouse?: EnumEcInventoryWarehouse | null;
        materialCode: string;
        materialName: string;
        customerCode?: string | null;
        customerName?: string | null;
        limitDate?: string | null;
        orderDate?: string | null;
        qty: number;
    }>;
};

export type NavOrderFragment = {
    id: string;
    code: string;
    orderDate: any;
    postingDate: any;
    postingDescription?: string | null;
    reasonCode: string;
    amount?: number | null;
    approvalCode: string;
    shipInvoicedAmount?: number | null;
    businesses: Array<{ id: string; code?: string | null }>;
    sellToCustomer?: {
        id: string;
        code?: string | null;
        name: string;
        address?: string | null;
    } | null;
    dept?: { id: string; code?: string | null; name: string } | null;
    billToCustomer?: {
        id: string;
        name: string;
        code?: string | null;
        address?: string | null;
    } | null;
    user?: { id: string; code?: string | null; name?: string | null } | null;
    navOrderDetails: Array<{ id: string; amount: number; materialCode: string; qty: number }>;
};

export type NavSalesInvoiceFragment = {
    id: string;
    approvalCode: string;
    code: string;
    reasonCode: string;
    postingDescription?: string | null;
    postingDate: any;
    orderDate: any;
    orderCode: string;
    amount?: number | null;
    type: EnumSalesInvoiceType;
    billToCustomer?: {
        id: string;
        address?: string | null;
        code?: string | null;
        name: string;
    } | null;
    dept?: { id: string; code?: string | null; name: string } | null;
    user?: { id: string; code?: string | null; name?: string | null } | null;
    sellToCustomer?: {
        address?: string | null;
        code?: string | null;
        id: string;
        name: string;
    } | null;
    navSalesInvoiceDetails: Array<{
        qty: number;
        id: string;
        amount: number;
        materialCode: string;
    }>;
};

export type NavSalesAnalysisOrderStatFragment = {
    overall: {
        amountGoalAchievementRate?: number | null;
        amountGoal?: number | null;
        amount: number;
        periods: Array<{ amount: number; type: EnumSalesAnalysisPeriodType }>;
    };
};

export type NavSalesAnalysisSalesStatFragment = {
    overall: {
        amount: number;
        amountGoal?: number | null;
        amountGoalAchievementRate?: number | null;
        periods: Array<{ type: EnumSalesAnalysisPeriodType; amount: number }>;
    };
    details: Array<{
        type: EnumSalesStatType;
        amount: number;
        amountGoal?: number | null;
        amountGoalAchievementRate?: number | null;
        periods: Array<{ type: EnumSalesAnalysisPeriodType; amount: number }>;
    }>;
};

export type NavSalesAnalysisProductRankFragment = {
    seq: number;
    rank: number;
    qty: number;
    navProductId: string;
    amount: number;
    navProduct?: { id: string; code: string; brand: string } | null;
};

export type NavSalesAnalysisCustomerRankFragment = {
    seq: number;
    sellToCustomerNavCode: string;
    sellToCustomerName: string;
    rank: number;
    qty: number;
    amount: number;
    sellToCustomer?: { name: string; code?: string | null; id: string } | null;
};

export type NavSalesAnalysisYearlySalesStatByYearFragment = {
    year: number;
    amount: number;
    months: Array<{ month: number; amount?: number | null }>;
};

export type NavSalesAnalysisYearlySalesStatByUserFragment = {
    userCode?: string | null;
    amountGoalAchievementRate?: number | null;
    amountGoal?: number | null;
    amount?: number | null;
    user?: {
        id: string;
        code?: string | null;
        name?: string | null;
        salesTeams: Array<{ id: string; name: string }>;
    } | null;
    months: Array<{ month: number; amount?: number | null }>;
};

export type NavSalesAnalysisYearlySalesStatBySalesTeamFragment = {
    salesTeamId: string;
    amountGoalAchievementRate?: number | null;
    amountGoal?: number | null;
    amount: number;
    salesTeam?: { id: string; name: string } | null;
    months: Array<{ month: number; amount?: number | null }>;
};

export type NavSalesAnalysisYearlyOrderStatByYearFragment = {
    year: number;
    amount: number;
    months: Array<{ month: number; amount?: number | null }>;
};

export type NavSalesAnalysisYearlyOrderStatByUserFragment = {
    userCode?: string | null;
    amount: number;
    user?: {
        id: string;
        code?: string | null;
        name?: string | null;
        salesTeams: Array<{ id: string; name: string }>;
    } | null;
    months: Array<{ month: number; amount?: number | null }>;
};

export type NavSalesAnalysisYearlyOrderStatBySalesTeamFragment = {
    salesTeamId: string;
    amount: number;
    salesTeam?: { id: string; name: string } | null;
    months: Array<{ month: number; amount?: number | null }>;
};

export type BulkUpdateSalesPerformanceSalesUserForecastViewMutationVariables = Exact<{
    input: SalesPerformanceSalesUserForecastViewBulkUpdateInput;
}>;

export type BulkUpdateSalesPerformanceSalesUserForecastViewMutation = {
    bulkUpdateSalesPerformanceSalesUserForecastView: Array<{
        id: string;
        userForecastId: string;
        quota: number;
        amountEarlyMonth: number;
        amountLateMonth: number;
        createdAt: any;
        updatedAt: any;
    }>;
};

export type BulkUpdateSalesPerformanceSalesProductUserForecastViewMutationVariables = Exact<{
    input: SalesPerformanceSalesProductUserForecastViewBulkUpdateInput;
}>;

export type BulkUpdateSalesPerformanceSalesProductUserForecastViewMutation = {
    bulkUpdateSalesPerformanceSalesProductUserForecastView: Array<{
        id: string;
        userForecastId: string;
        quota: number;
        qtyEarlyMonth: number;
        qtyLateMonth: number;
        createdAt: any;
        updatedAt: any;
    }>;
};

export type BulkUpdateSalesPerformanceSalesProductOverallViewMutationVariables = Exact<{
    input: SalesPerformanceSalesProductOverallViewBulkUpdateInput;
}>;

export type BulkUpdateSalesPerformanceSalesProductOverallViewMutation = {
    bulkUpdateSalesPerformanceSalesProductOverallView: {
        goals: Array<{ id: string; amount: number; unitPrice: number; qty: number }>;
        forecasts: Array<{ id: string; amount: number; unitPrice: number; qty: number }>;
    };
};

export type PaginatedNavOrdersQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<NavOrderFilterInput>;
}>;

export type PaginatedNavOrdersQuery = {
    paginatedNavOrders: {
        count?: number | null;
        navOrders?: Array<{
            id: string;
            code: string;
            orderDate: any;
            postingDate: any;
            postingDescription?: string | null;
            reasonCode: string;
            amount?: number | null;
            approvalCode: string;
            shipInvoicedAmount?: number | null;
            businesses: Array<{ id: string; code?: string | null }>;
            sellToCustomer?: {
                id: string;
                code?: string | null;
                name: string;
                address?: string | null;
            } | null;
            dept?: { id: string; code?: string | null; name: string } | null;
            billToCustomer?: {
                id: string;
                name: string;
                code?: string | null;
                address?: string | null;
            } | null;
            user?: { id: string; code?: string | null; name?: string | null } | null;
            navOrderDetails: Array<{
                id: string;
                amount: number;
                materialCode: string;
                qty: number;
            }>;
        }> | null;
    };
};

export type NavOrderQueryVariables = Exact<{
    navOrderId: Scalars['ID'];
}>;

export type NavOrderQuery = {
    navOrder: {
        id: string;
        code: string;
        orderDate: any;
        postingDate: any;
        postingDescription?: string | null;
        reasonCode: string;
        amount?: number | null;
        approvalCode: string;
        shipInvoicedAmount?: number | null;
        businesses: Array<{ id: string; code?: string | null }>;
        sellToCustomer?: {
            id: string;
            code?: string | null;
            name: string;
            address?: string | null;
        } | null;
        dept?: { id: string; code?: string | null; name: string } | null;
        billToCustomer?: {
            id: string;
            name: string;
            code?: string | null;
            address?: string | null;
        } | null;
        user?: { id: string; code?: string | null; name?: string | null } | null;
        navOrderDetails: Array<{ id: string; amount: number; materialCode: string; qty: number }>;
    };
};

export type PaginatedNavSalesInvoicesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<NavSalesInvoiceFilterInput>;
}>;

export type PaginatedNavSalesInvoicesQuery = {
    paginatedNavSalesInvoices: {
        count?: number | null;
        navSalesInvoices?: Array<{
            id: string;
            approvalCode: string;
            code: string;
            reasonCode: string;
            postingDescription?: string | null;
            postingDate: any;
            orderDate: any;
            orderCode: string;
            amount?: number | null;
            type: EnumSalesInvoiceType;
            billToCustomer?: {
                id: string;
                address?: string | null;
                code?: string | null;
                name: string;
            } | null;
            dept?: { id: string; code?: string | null; name: string } | null;
            user?: { id: string; code?: string | null; name?: string | null } | null;
            sellToCustomer?: {
                address?: string | null;
                code?: string | null;
                id: string;
                name: string;
            } | null;
            navSalesInvoiceDetails: Array<{
                qty: number;
                id: string;
                amount: number;
                materialCode: string;
            }>;
        }> | null;
    };
};

export type NavSalesInvoiceQueryVariables = Exact<{
    navSalesInvoiceId: Scalars['ID'];
}>;

export type NavSalesInvoiceQuery = {
    navSalesInvoice: {
        id: string;
        approvalCode: string;
        code: string;
        reasonCode: string;
        postingDescription?: string | null;
        postingDate: any;
        orderDate: any;
        orderCode: string;
        amount?: number | null;
        type: EnumSalesInvoiceType;
        billToCustomer?: {
            id: string;
            address?: string | null;
            code?: string | null;
            name: string;
        } | null;
        dept?: { id: string; code?: string | null; name: string } | null;
        user?: { id: string; code?: string | null; name?: string | null } | null;
        sellToCustomer?: {
            address?: string | null;
            code?: string | null;
            id: string;
            name: string;
        } | null;
        navSalesInvoiceDetails: Array<{
            qty: number;
            id: string;
            amount: number;
            materialCode: string;
        }>;
    };
};

export type NavSalesAnalysisQueryVariables = Exact<{
    date1?: InputMaybe<Scalars['String']>;
    date2?: InputMaybe<Scalars['String']>;
    primaryUserIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type NavSalesAnalysisQuery = {
    navSalesAnalysis: {
        orderStat: {
            overall: {
                amountGoalAchievementRate?: number | null;
                amountGoal?: number | null;
                amount: number;
                periods: Array<{ amount: number; type: EnumSalesAnalysisPeriodType }>;
            };
        };
        salesStat: {
            overall: {
                amount: number;
                amountGoal?: number | null;
                amountGoalAchievementRate?: number | null;
                periods: Array<{ type: EnumSalesAnalysisPeriodType; amount: number }>;
            };
            details: Array<{
                type: EnumSalesStatType;
                amount: number;
                amountGoal?: number | null;
                amountGoalAchievementRate?: number | null;
                periods: Array<{ type: EnumSalesAnalysisPeriodType; amount: number }>;
            }>;
        };
        customerRank: Array<{
            seq: number;
            sellToCustomerNavCode: string;
            sellToCustomerName: string;
            rank: number;
            qty: number;
            amount: number;
            sellToCustomer?: { name: string; code?: string | null; id: string } | null;
        }>;
        productRank: Array<{
            seq: number;
            rank: number;
            qty: number;
            navProductId: string;
            amount: number;
            navProduct?: { id: string; code: string; brand: string } | null;
        }>;
    };
};

export type YearSalesAnalysisQueryVariables = Exact<{
    year: Scalars['Int'];
    primaryUserIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
    keyword?: InputMaybe<Scalars['String']>;
}>;

export type YearSalesAnalysisQuery = {
    navSalesAnalysis: {
        yearlySalesStatByYear: Array<{
            year: number;
            amount: number;
            months: Array<{ month: number; amount?: number | null }>;
        }>;
        yearlySalesStatByUser: Array<{
            userCode?: string | null;
            amountGoalAchievementRate?: number | null;
            amountGoal?: number | null;
            amount?: number | null;
            user?: {
                id: string;
                code?: string | null;
                name?: string | null;
                salesTeams: Array<{ id: string; name: string }>;
            } | null;
            months: Array<{ month: number; amount?: number | null }>;
        }>;
        yearlySalesStatBySalesTeam: Array<{
            salesTeamId: string;
            amountGoalAchievementRate?: number | null;
            amountGoal?: number | null;
            amount: number;
            salesTeam?: { id: string; name: string } | null;
            months: Array<{ month: number; amount?: number | null }>;
        }>;
    };
};

export type YearOrderAnalysisQueryVariables = Exact<{
    year: Scalars['Int'];
    primaryUserIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
    keyword?: InputMaybe<Scalars['String']>;
}>;

export type YearOrderAnalysisQuery = {
    navSalesAnalysis: {
        yearlyOrderStatByYear: Array<{
            year: number;
            amount: number;
            months: Array<{ month: number; amount?: number | null }>;
        }>;
        yearlyOrderStatByUser: Array<{
            userCode?: string | null;
            amount: number;
            user?: {
                id: string;
                code?: string | null;
                name?: string | null;
                salesTeams: Array<{ id: string; name: string }>;
            } | null;
            months: Array<{ month: number; amount?: number | null }>;
        }>;
        yearlyOrderStatBySalesTeam: Array<{
            salesTeamId: string;
            amount: number;
            salesTeam?: { id: string; name: string } | null;
            months: Array<{ month: number; amount?: number | null }>;
        }>;
    };
};

export type SalesPerformanceSalesUserForecastViewQueryVariables = Exact<{
    filters: SalesPerformanceSalesUserForecastViewFilterInput;
}>;

export type SalesPerformanceSalesUserForecastViewQuery = {
    salesPerformanceSalesUserForecastView: {
        year: number;
        salesTeamGroup?: { id: string; name: string } | null;
        overall: {
            months: Array<{
                month: number;
                types: Array<{
                    quota: number;
                    quotaAchievementRate?: number | null;
                    amountEarlyMonth: number;
                    amountEarlyMonthAccuracyRate?: number | null;
                    amountLateMonth: number;
                    amountLateMonthAccuracyRate?: number | null;
                    amountActual: number;
                    salesType?: { id: string; name: string } | null;
                }>;
            }>;
        };
        users: Array<{
            salesTeamUnit?: { id: string; name: string } | null;
            months: Array<{
                month: number;
                types: Array<{
                    quota: number;
                    quotaAchievementRate?: number | null;
                    amountEarlyMonth: number;
                    amountEarlyMonthAccuracyRate?: number | null;
                    amountLateMonth: number;
                    amountLateMonthAccuracyRate?: number | null;
                    amountActual: number;
                    salesType?: { id: string; name: string } | null;
                }>;
            }>;
        }>;
    };
};

export type PaginatedSalesPerformanceSalesTypesQueryVariables = Exact<{ [key: string]: never }>;

export type PaginatedSalesPerformanceSalesTypesQuery = {
    paginatedSalesPerformanceSalesTypes: {
        count?: number | null;
        salesPerformanceSalesTypes?: Array<{ id: string; name: string }> | null;
    };
};

export type SalesPerformanceSalesProductUserForecastViewQueryVariables = Exact<{
    filters: SalesPerformanceSalesProductUserForecastViewFilterInput;
}>;

export type SalesPerformanceSalesProductUserForecastViewQuery = {
    salesPerformanceSalesProductUserForecastView: {
        year: number;
        salesTeamGroup?: { id: string; name: string } | null;
        overall: {
            months: Array<{
                month: number;
                types: Array<{
                    quota: number;
                    quotaAchievementRate?: number | null;
                    qtyEarlyMonth: number;
                    qtyEarlyMonthAccuracyRate?: number | null;
                    qtyLateMonth: number;
                    qtyLateMonthAccuracyRate?: number | null;
                    qtyActual: number;
                    salesProductType?: { id: string; name: string } | null;
                }>;
            }>;
        };
        users: Array<{
            salesTeamUnit?: { id: string; name: string } | null;
            months: Array<{
                month: number;
                types: Array<{
                    quota: number;
                    quotaAchievementRate?: number | null;
                    qtyEarlyMonth: number;
                    qtyEarlyMonthAccuracyRate?: number | null;
                    qtyLateMonth: number;
                    qtyLateMonthAccuracyRate?: number | null;
                    qtyActual: number;
                    salesProductType?: { id: string; name: string } | null;
                }>;
            }>;
        }>;
    };
};

export type PaginatedSalesPerformanceSalesProductTypesQueryVariables = Exact<{
    [key: string]: never;
}>;

export type PaginatedSalesPerformanceSalesProductTypesQuery = {
    paginatedSalesPerformanceSalesProductTypes: {
        count?: number | null;
        salesPerformanceSalesProductTypes?: Array<{ id: string; name: string }> | null;
    };
};

export type SalesPerformanceSalesProductTypeGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type SalesPerformanceSalesProductTypeGroupsQuery = {
    salesPerformanceSalesProductTypeGroups: Array<{ id: string; name: string }>;
};

export type SalesProductTypeSalesAnalysisQueryVariables = Exact<{
    filters?: InputMaybe<SalesProductTypeSalesAnalysisSalesStatFilterInput>;
}>;

export type SalesProductTypeSalesAnalysisQuery = {
    salesProductTypeSalesAnalysis: {
        salesStat: {
            details: Array<{
                salesProductTypeGroupId: string;
                salesProductTypeGroup?: { id: string; name: string } | null;
                types: Array<{
                    salesProductTypeId: string;
                    amountGoal: number;
                    amountForecastGoal: number;
                    amountCurrent: number;
                    amountMom: number;
                    amountMomGrowthRate?: number | null;
                    amountYoy: number;
                    amountYoyGrowthRate?: number | null;
                    amountYtd: number;
                    amountLastYearYtd: number;
                    amountLastYearYtdGrowthRate?: number | null;
                    qtyGoal: number;
                    qtyForecastGoal: number;
                    qtyCurrent: number;
                    qtyMom: number;
                    qtyMomGrowthRate?: number | null;
                    qtyYoy: number;
                    qtyYoyGrowthRate?: number | null;
                    qtyLastYearYtd: number;
                    qtyLastYearYtdGrowthRate?: number | null;
                    salesProductType?: { id: string; name: string } | null;
                }>;
            }>;
            dateRange: {
                currentDate1: string;
                currentDate2: string;
                yoyDate1: string;
                yoyDate2: string;
                momDate1: string;
                momDate2: string;
                ytdDate1: string;
                ytdDate2: string;
                lastYearYtdDate1: string;
                lastYearYtdDate2: string;
            };
        };
    };
};

export type SalesPerformanceSalesProductOverallViewQueryVariables = Exact<{
    filters: SalesPerformanceSalesProductOverallViewFilterInput;
}>;

export type SalesPerformanceSalesProductOverallViewQuery = {
    salesPerformanceSalesProductOverallView: {
        year: number;
        salesTeamGroup?: { id: string; code?: EnumSalesTeamGroupCode | null; name: string } | null;
        overall: {
            salesAmount: number;
            salesUnitPrice: number;
            salesQty: number;
            forecastAmount: number;
            forecastQty: number;
            forecastUnitPrice: number;
            goalAmount: number;
            goalAmountAchievementRate?: number | null;
            goalUnitPrice: number;
            goalUnitPriceAchievementRate?: number | null;
            goalQty: number;
            goalQtyAchievementRate?: number | null;
            ytdCurrentAmount: number;
            ytdCurrentUnitPrice: number;
            ytdCurrentQty: number;
            ytdGoalAmount: number;
            ytdGoalAmountAchievementRate?: number | null;
            ytdGoalUnitPrice: number;
            ytdGoalUnitPriceAchievementRate?: number | null;
            ytdGoalQty: number;
            ytdGoalQtyAchievementRate?: number | null;
            months: Array<{
                salesAmount: number;
                salesUnitPrice: number;
                salesQty: number;
                forecastAmount: number;
                forecastQty: number;
                forecastUnitPrice: number;
                goalAmount: number;
                goalUnitPrice: number;
                goalQty: number;
            }>;
        };
        types: Array<{
            salesAmount: number;
            salesUnitPrice: number;
            salesQty: number;
            forecastAmount: number;
            forecastQty: number;
            forecastUnitPrice: number;
            goalAmount: number;
            goalAmountAchievementRate?: number | null;
            goalUnitPrice: number;
            goalUnitPriceAchievementRate?: number | null;
            goalQty: number;
            goalQtyAchievementRate?: number | null;
            ytdCurrentAmount: number;
            ytdCurrentUnitPrice: number;
            ytdCurrentQty: number;
            ytdGoalAmount: number;
            ytdGoalAmountAchievementRate?: number | null;
            ytdGoalUnitPrice: number;
            ytdGoalUnitPriceAchievementRate?: number | null;
            ytdGoalQty: number;
            ytdGoalQtyAchievementRate?: number | null;
            salesProductType?: { id: string; code?: string | null; name: string } | null;
            months: Array<{
                month: number;
                salesAmount: number;
                salesUnitPrice: number;
                salesQty: number;
                forecastAmount: number;
                forecastQty: number;
                forecastUnitPrice: number;
                goalAmount: number;
                goalUnitPrice: number;
                goalQty: number;
            }>;
        }>;
    };
};

export type EcDashboardAnalysisQueryVariables = Exact<{
    filters?: InputMaybe<EcDashboardAnalysisSalesCustomerRankFilterInput>;
    salesMaterialRankFilters2?: InputMaybe<EcDashboardAnalysisSalesMaterialRankFilterInput>;
    visitCustomerRankFilters2?: InputMaybe<EcDashboardAnalysisVisitCustomerRankFilterInput>;
    visitGoalRankFilters2?: InputMaybe<EcDashboardAnalysisVisitGoalRankFilterInput>;
    visitMentionProductRankFilters2?: InputMaybe<EcDashboardAnalysisVisitMentionProductRankFilterInput>;
    visitsByDayOfWeekFilters2?: InputMaybe<EcDashboardAnalysisVisitByDayOfWeekFilterInput>;
    visitStatFilters2?: InputMaybe<EcDashboardAnalysisVisitStatFilterInput>;
    salesStatFilters2?: InputMaybe<EcDashboardAnalysisSalesStatFilterInput>;
}>;

export type EcDashboardAnalysisQuery = {
    EcDashboardAnalysis: {
        salesCustomerRank: {
            dateRange: {
                currentDate1: string;
                currentDate2: string;
                yoyDate1: string;
                yoyDate2: string;
                lastYearYoyDate1: string;
                lastYearYoyDate2: string;
            };
            details: Array<{
                seq: number;
                rank: number;
                customerName?: string | null;
                customerCode?: string | null;
                visitCurrent: number;
                visitYoy: number;
                visitLastYearYoy: number;
                amountCurrent: number;
                amountYoy: number;
                amountLastYearYoy: number;
                qtyCurrent: number;
                qtyYoy: number;
                qtyLastYearYoy: number;
            }>;
        };
        salesMaterialRank: {
            dateRange: {
                currentDate1: string;
                currentDate2: string;
                yoyDate1: string;
                yoyDate2: string;
                lastYearYoyDate1: string;
                lastYearYoyDate2: string;
            };
            details: Array<{
                seq: number;
                rank: number;
                materialName?: string | null;
                materialCode?: string | null;
                amountCurrent: number;
                amountYoy: number;
                amountLastYearYoy: number;
                qtyCurrent: number;
                qtyYoy: number;
                qtyLastYearYoy: number;
            }>;
        };
        visitCustomerRank: {
            dateRange: { currentDate1: string; yoyDate1: string };
            details: Array<{
                seq: number;
                rank: number;
                customerId: string;
                visitCountCurrent: number;
                visitCountYoy: number;
                customer: { id: string; name: string };
            }>;
        };
        visitGoalRank: {
            dateRange: { currentDate1: string; yoyDate1: string };
            details: Array<{
                seq: number;
                rank: number;
                goalId: string;
                visitCountCurrent: number;
                visitCountYoy: number;
                goal: { id: string; name: string };
            }>;
        };
        visitMentionProductRank: {
            dateRange: { currentDate1: string; yoyDate1: string };
            details: Array<{
                seq: number;
                rank: number;
                mentionProductId: string;
                visitCountCurrent: number;
                visitCountYoy: number;
                mentionProduct: { id: string; name: string };
            }>;
        };
        visitsByDayOfWeek: {
            details: Array<{ dayOfWeek: EnumVisitByDayOfWeek; visitCountCurrent: number }>;
        };
        visitStat: {
            dateRange: {
                currentDate1: string;
                currentDate2: string;
                lastYearYtdDate1: string;
                lastYearYtdDate2: string;
                momDate1: string;
                momDate2: string;
                yoyDate1: string;
                yoyDate2: string;
                ytdDate1: string;
                ytdDate2: string;
            };
            overall: {
                visitCountCurrent: number;
                visitCountMom: number;
                visitCountMomGrowthRate?: number | null;
                visitCountYoy: number;
                visitCountYoyGrowthRate?: number | null;
                visitCountYtd: number;
                visitCountLastYearYtd: number;
                visitCountLastYearYtdGrowthRate?: number | null;
            };
        };
        salesStat: {
            salesTeamGroupId?: string | null;
            dateRange: {
                currentDate1: string;
                currentDate2: string;
                lastYearYtdDate1: string;
                lastYearYtdDate2: string;
                momDate1: string;
                momDate2: string;
                yoyDate1: string;
                yoyDate2: string;
                ytdDate1: string;
                ytdDate2: string;
            };
            details: Array<{
                salesTypeId: string;
                amountCurrent: number;
                amountGoalCurrent?: number | null;
                amountGoalCurrentAchievementRate?: number | null;
                amountMom: number;
                amountMomGrowthRate?: number | null;
                amountYoy: number;
                amountYoyGrowthRate?: number | null;
                amountYtd: number;
                amountLastYearYtd: number;
                amountLastYearYtdGrowthRate?: number | null;
                salesType: { id: string; code?: string | null; name: string };
            }>;
        };
    };
};

export type EcDashboardAnalysisDetailQueryVariables = Exact<{
    filters: EcDashboardAnalysisSalesDetailFilterInput;
}>;

export type EcDashboardAnalysisDetailQuery = {
    EcDashboardAnalysisDetail: {
        salesTeamGroup?: { id: string; name: string } | null;
        salesType?: { id: string; name: string } | null;
        details: Array<{
            key: string;
            companyCode: EnumEnumEcDashboardAnalysisSalesCompanyCode;
            salesType: EnumEcDashboardAnalysisOrderType;
            salesOrderCode?: string | null;
            salesDate?: string | null;
            salesCustomerCode?: string | null;
            salesCustomerName?: string | null;
            accountCustomerCode?: string | null;
            accountCustomerName?: string | null;
            materialCode?: string | null;
            materialName?: string | null;
            qty?: number | null;
            untaxedAmount?: number | null;
            taxedAmount?: number | null;
            userCode?: string | null;
            userName?: string | null;
        }>;
    };
};

export type PaginatedEyeProductsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<EyeProductFilterInput>;
}>;

export type PaginatedEyeProductsQuery = {
    paginatedEyeProducts: {
        count?: number | null;
        eyeProducts?: Array<{
            id: string;
            name: string;
            model?: string | null;
            brand?: string | null;
            salePrice: number;
            description?: string | null;
            date1?: any | null;
            date2?: any | null;
            status: EnumEyeProductStatus;
            eyeProductType?: { id: string; name: string } | null;
            region: { id: string; name: string };
            currency: { id: string; name: string };
            eyeProductItems: Array<{
                id: string;
                isOptional: boolean;
                qty: number;
                description?: string | null;
                material: {
                    id: string;
                    code: string;
                    name: string;
                    model?: string | null;
                    spec?: string | null;
                    unit?: string | null;
                };
            }>;
            eyePromotions: Array<{ id: string }>;
        }> | null;
    };
};

export type EyeProductsInfoFragment = {
    id: string;
    name: string;
    model?: string | null;
    brand?: string | null;
    salePrice: number;
    description?: string | null;
    date1?: any | null;
    date2?: any | null;
    status: EnumEyeProductStatus;
    eyeProductType?: { id: string; name: string } | null;
    region: { id: string; name: string };
    currency: { id: string; name: string };
    eyeProductItems: Array<{
        id: string;
        isOptional: boolean;
        qty: number;
        description?: string | null;
        material: {
            id: string;
            code: string;
            name: string;
            model?: string | null;
            spec?: string | null;
            unit?: string | null;
        };
    }>;
    eyePromotions: Array<{ id: string }>;
};

export type PaginatedEyeProductTypesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<EyeProductTypeFilterInput>;
}>;

export type PaginatedEyeProductTypesQuery = {
    paginatedEyeProductTypes: {
        count?: number | null;
        eyeProductTypes?: Array<{ id: string; name: string }> | null;
    };
};

export type EyeProductQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type EyeProductQuery = {
    eyeProduct: {
        id: string;
        name: string;
        model?: string | null;
        brand?: string | null;
        salePrice: number;
        description?: string | null;
        date1?: any | null;
        date2?: any | null;
        status: EnumEyeProductStatus;
        eyeProductType?: { id: string; name: string } | null;
        region: { id: string; name: string };
        currency: { id: string; name: string };
        eyeProductItems: Array<{
            id: string;
            isOptional: boolean;
            qty: number;
            description?: string | null;
            material: {
                id: string;
                code: string;
                name: string;
                model?: string | null;
                spec?: string | null;
                unit?: string | null;
            };
        }>;
        eyePromotions: Array<{ id: string }>;
    };
};

export type PaginatedEyePromotionsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<EyePromotionFilterInput>;
}>;

export type PaginatedEyePromotionsQuery = {
    paginatedEyePromotions: {
        count?: number | null;
        eyePromotions?: Array<{
            id: string;
            name?: string | null;
            isAddon: boolean;
            discountRate?: number | null;
            discountAmount?: number | null;
            date1: any;
            date2?: any | null;
            description?: string | null;
            region: { id: string; name: string };
            eyeProducts: Array<{ id: string; name: string }>;
            eyePromotionAddonProductItems: Array<{
                id: string;
                isOptional: boolean;
                qty: number;
                material: { id: string };
            }>;
        }> | null;
    };
};

export type EyePromotionQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type EyePromotionQuery = {
    eyePromotion: {
        id: string;
        name?: string | null;
        isAddon: boolean;
        discountRate?: number | null;
        discountAmount?: number | null;
        date1: any;
        date2?: any | null;
        description?: string | null;
        region: { id: string; name: string };
        eyeProducts: Array<{
            id: string;
            name: string;
            salePrice: number;
            status: EnumEyeProductStatus;
            description?: string | null;
            currencyId: string;
            model?: string | null;
            regionId: string;
            brand?: string | null;
            createdAt: any;
            updatedAt: any;
            eyeProductTypeId?: string | null;
            eyeProductType?: {
                id: string;
                regionId: string;
                name: string;
                createdAt: any;
                updatedAt: any;
            } | null;
            eyeProductItems: Array<{
                id: string;
                eyeProductId: string;
                eyeProductItemTypeId: string;
                qty: number;
                isOptional: boolean;
                createdAt: any;
                material: {
                    id: string;
                    code: string;
                    name: string;
                    model?: string | null;
                    spec?: string | null;
                    unit?: string | null;
                };
            }>;
            eyePromotions: Array<{
                id: string;
                name?: string | null;
                isAddon: boolean;
                discountRate?: number | null;
                discountAmount?: number | null;
                createdAt: any;
                date1: any;
                regionId: string;
                updatedAt: any;
                currencyId: string;
                eyePromotionAddonProductItems: Array<{
                    id: string;
                    qty: number;
                    isOptional: boolean;
                    material: { id: string; unit?: string | null };
                }>;
            }>;
        }>;
        eyePromotionAddonProductItems: Array<{
            id: string;
            isOptional: boolean;
            qty: number;
            material: { id: string };
        }>;
        currency: { id: string; name: string };
    };
};

export type Quotation_MaterialFragment = {
    id: string;
    code: string;
    model?: string | null;
    name: string;
    spec?: string | null;
    unit?: string | null;
    companyId: string;
    salePrice?: number | null;
    saleCurrencyId?: string | null;
    taxRate: number;
    saleCurrency?: { id: string; name: string } | null;
    company: { id: string; name: string; code?: string | null };
};

export type Quotation_ProductItemFragment = {
    id: string;
    eyeProductId: string;
    eyeProductItemTypeId: string;
    qty: number;
    isOptional: boolean;
    createdAt: any;
    material: {
        id: string;
        code: string;
        model?: string | null;
        name: string;
        spec?: string | null;
        unit?: string | null;
        companyId: string;
        salePrice?: number | null;
        saleCurrencyId?: string | null;
        taxRate: number;
        saleCurrency?: { id: string; name: string } | null;
        company: { id: string; name: string; code?: string | null };
    };
};

export type Quotation_PromotionAddonProductItemFragment = {
    id: string;
    qty: number;
    isOptional: boolean;
    eyePromotionId: string;
    eyeProductItemTypeId: string;
    material: {
        id: string;
        code: string;
        model?: string | null;
        name: string;
        spec?: string | null;
        unit?: string | null;
        companyId: string;
        salePrice?: number | null;
        saleCurrencyId?: string | null;
        taxRate: number;
        saleCurrency?: { id: string; name: string } | null;
        company: { id: string; name: string; code?: string | null };
    };
};

export type Quotation_ProductFragment = {
    id: string;
    name: string;
    salePrice: number;
    brand?: string | null;
    status: EnumEyeProductStatus;
    description?: string | null;
    currencyId: string;
    model?: string | null;
    regionId: string;
    createdAt: any;
    updatedAt: any;
    eyeProductTypeId?: string | null;
    currency: { id: string; name: string };
    eyeProductItems: Array<{
        id: string;
        eyeProductId: string;
        eyeProductItemTypeId: string;
        qty: number;
        isOptional: boolean;
        createdAt: any;
        material: {
            id: string;
            code: string;
            model?: string | null;
            name: string;
            spec?: string | null;
            unit?: string | null;
            companyId: string;
            salePrice?: number | null;
            saleCurrencyId?: string | null;
            taxRate: number;
            saleCurrency?: { id: string; name: string } | null;
            company: { id: string; name: string; code?: string | null };
        };
    }>;
    region: { id: string; name: string };
    eyeProductType?: {
        id: string;
        regionId: string;
        name: string;
        createdAt: any;
        updatedAt: any;
    } | null;
    eyePromotions: Array<{
        id: string;
        name?: string | null;
        isAddon: boolean;
        discountRate?: number | null;
        discountAmount?: number | null;
        createdAt: any;
        date1: any;
        regionId: string;
        updatedAt: any;
        currencyId: string;
        eyePromotionAddonProductItems: Array<{
            id: string;
            qty: number;
            isOptional: boolean;
            eyePromotionId: string;
            eyeProductItemTypeId: string;
            material: {
                id: string;
                code: string;
                model?: string | null;
                name: string;
                spec?: string | null;
                unit?: string | null;
                companyId: string;
                salePrice?: number | null;
                saleCurrencyId?: string | null;
                taxRate: number;
                saleCurrency?: { id: string; name: string } | null;
                company: { id: string; name: string; code?: string | null };
            };
        }>;
    }>;
};

export type Quotation_PromotionFragment = {
    id: string;
    name?: string | null;
    regionId: string;
    currencyId: string;
    date1: any;
    date2?: any | null;
    isAddon: boolean;
    description?: string | null;
    discountRate?: number | null;
    discountAmount?: number | null;
    region: { id: string; name: string };
    eyePromotionAddonProductItems: Array<{
        id: string;
        qty: number;
        isOptional: boolean;
        eyePromotionId: string;
        eyeProductItemTypeId: string;
        material: {
            id: string;
            code: string;
            model?: string | null;
            name: string;
            spec?: string | null;
            unit?: string | null;
            companyId: string;
            salePrice?: number | null;
            saleCurrencyId?: string | null;
            taxRate: number;
            saleCurrency?: { id: string; name: string } | null;
            company: { id: string; name: string; code?: string | null };
        };
    }>;
};

export type EyeQuotationOrderProductItemFragment = {
    id: string;
    materialId: string;
    materialCode: string;
    materialModel?: string | null;
    materialSpec?: string | null;
    materialUnit?: string | null;
    materialName?: string | null;
    materialSellingCurrencyId?: string | null;
    qty: number;
    unitPrice: number;
    unitPriceVat: number;
    eyeQuotationOrderProductId?: string | null;
    eyeQuotationOrderPromotionId?: string | null;
    discountedSellingPrice?: number | null;
};

export type EyeQuotationOrderProductFragment = {
    id: string;
    name: string;
    qty: number;
    model?: string | null;
    brand?: string | null;
    unitPrice: number;
    unitPriceVat: number;
    eyeProduct: {
        id: string;
        name: string;
        salePrice: number;
        brand?: string | null;
        status: EnumEyeProductStatus;
        description?: string | null;
        currencyId: string;
        model?: string | null;
        regionId: string;
        createdAt: any;
        updatedAt: any;
        eyeProductTypeId?: string | null;
        currency: { id: string; name: string };
        eyeProductItems: Array<{
            id: string;
            eyeProductId: string;
            eyeProductItemTypeId: string;
            qty: number;
            isOptional: boolean;
            createdAt: any;
            material: {
                id: string;
                code: string;
                model?: string | null;
                name: string;
                spec?: string | null;
                unit?: string | null;
                companyId: string;
                salePrice?: number | null;
                saleCurrencyId?: string | null;
                taxRate: number;
                saleCurrency?: { id: string; name: string } | null;
                company: { id: string; name: string; code?: string | null };
            };
        }>;
        region: { id: string; name: string };
        eyeProductType?: {
            id: string;
            regionId: string;
            name: string;
            createdAt: any;
            updatedAt: any;
        } | null;
        eyePromotions: Array<{
            id: string;
            name?: string | null;
            isAddon: boolean;
            discountRate?: number | null;
            discountAmount?: number | null;
            createdAt: any;
            date1: any;
            regionId: string;
            updatedAt: any;
            currencyId: string;
            eyePromotionAddonProductItems: Array<{
                id: string;
                qty: number;
                isOptional: boolean;
                eyePromotionId: string;
                eyeProductItemTypeId: string;
                material: {
                    id: string;
                    code: string;
                    model?: string | null;
                    name: string;
                    spec?: string | null;
                    unit?: string | null;
                    companyId: string;
                    salePrice?: number | null;
                    saleCurrencyId?: string | null;
                    taxRate: number;
                    saleCurrency?: { id: string; name: string } | null;
                    company: { id: string; name: string; code?: string | null };
                };
            }>;
        }>;
    };
    eyeQuotationOrderProductItems: Array<{
        id: string;
        qty: number;
        discountedSellingPrice?: number | null;
        material: {
            id: string;
            code: string;
            model?: string | null;
            name: string;
            spec?: string | null;
            unit?: string | null;
            companyId: string;
            salePrice?: number | null;
            saleCurrencyId?: string | null;
            taxRate: number;
            saleCurrency?: { id: string; name: string } | null;
            company: { id: string; name: string; code?: string | null };
        };
    }>;
};

export type EyeQuotationOrderPromotionFragment = {
    id: string;
    name: string;
    eyePromotionId: string;
    discountRate?: number | null;
    discountAmount?: number | null;
    currencyId: string;
    eyePromotion: {
        id: string;
        name?: string | null;
        regionId: string;
        currencyId: string;
        date1: any;
        date2?: any | null;
        isAddon: boolean;
        description?: string | null;
        discountRate?: number | null;
        discountAmount?: number | null;
        region: { id: string; name: string };
        eyePromotionAddonProductItems: Array<{
            id: string;
            qty: number;
            isOptional: boolean;
            eyePromotionId: string;
            eyeProductItemTypeId: string;
            material: {
                id: string;
                code: string;
                model?: string | null;
                name: string;
                spec?: string | null;
                unit?: string | null;
                companyId: string;
                salePrice?: number | null;
                saleCurrencyId?: string | null;
                taxRate: number;
                saleCurrency?: { id: string; name: string } | null;
                company: { id: string; name: string; code?: string | null };
            };
        }>;
    };
    eyeQuotationOrderProductItems: Array<{
        id: string;
        qty: number;
        material: {
            id: string;
            code: string;
            model?: string | null;
            name: string;
            spec?: string | null;
            unit?: string | null;
            companyId: string;
            salePrice?: number | null;
            saleCurrencyId?: string | null;
            taxRate: number;
            saleCurrency?: { id: string; name: string } | null;
            company: { id: string; name: string; code?: string | null };
        };
    }>;
};

export type EyeQuotationOrderWarrantyItemFragment = {
    id: string;
    materialId: string;
    materialCode: string;
    materialModel?: string | null;
    materialUnit?: string | null;
    materialName?: string | null;
    sn?: string | null;
    udi?: string | null;
    qty: number;
    memo?: string | null;
};

export type EyeQuotationOrderTypeFragment = {
    id: string;
    name: string;
    code?: EnumEyeQuotationOrderType | null;
};

export type EyeQuotationOrderBusinessFragment = {
    business?: {
        id: string;
        code?: string | null;
        title?: string | null;
        users: Array<{
            id: string;
            salesTeam?: { id: string; name: string } | null;
            user: { id: string; name?: string | null };
        }>;
    } | null;
};

export type EyeQuotationOrderFragment = {
    id: string;
    code: string;
    bpmUrl?: any | null;
    officialSealStatus?: EnumEyeQuotationOrderOfficialSealStatus | null;
    officialSealBpmUrl?: any | null;
    localStandardAmount: number;
    eyeQuotationOrderTypeId?: string | null;
    onlyMaterials: boolean;
    biddingPrice?: number | null;
    userId: string;
    customerId: string;
    invoicingCustomerId: string;
    eyeServiceOrderId?: string | null;
    bpmInstanceId?: string | null;
    exchangeRate?: number | null;
    warrantyMonths?: number | null;
    warrantyPeriodTypeId?: string | null;
    expectDeliveryDate: any;
    expectPaymentDate: any;
    warrantyBuyType: EnumWarrantyBuyType;
    orderCode?: string | null;
    realAmount: number;
    untaxedAmount: number;
    contactPerson: string;
    contactPhone: string;
    regionId: string;
    status: EnumEyeQuotationOrderStatus;
    address: string;
    currencyId: string;
    standardAmount: number;
    discountRate: number;
    discountAmount: number;
    extraDiscountAmount: number;
    realDiscountAmount: number;
    realDiscountRate: number;
    taxRate: number;
    commissionAmount: number;
    discountRateWithoutCommission: number;
    description?: string | null;
    createdAt: any;
    eyeQuotationOrderType?: {
        id: string;
        name: string;
        code?: EnumEyeQuotationOrderType | null;
    } | null;
    localCurrency: { id: string; name: string };
    province?: { id: string; name?: string | null } | null;
    city?: { id: string; name: string } | null;
    district?: { id: string; name: string } | null;
    eyeQuotationOrderBusinesses: Array<{
        business?: {
            id: string;
            code?: string | null;
            title?: string | null;
            users: Array<{
                id: string;
                salesTeam?: { id: string; name: string } | null;
                user: { id: string; name?: string | null };
            }>;
        } | null;
    }>;
    creditPeriod?: { id: string; name: string } | null;
    region: { id: string; name: string; localCurrency?: { id: string; name: string } | null };
    eyeServiceOrder?: { id: string; code: string } | null;
    customer?: { id: string; code?: string | null; name: string } | null;
    currency: { id: string; name: string; code: string };
    dept: { id: string; code?: string | null; name: string };
    invoicingCustomer?: { id: string; code?: string | null; name: string } | null;
    createdUser: { id: string; code?: string | null; name?: string | null };
    user: { id: string; code?: string | null; name?: string | null };
    eyeWarrantyPeriodType?: { id: string; code: string; name: string } | null;
    eyeQuotationOrderProducts: Array<{
        id: string;
        name: string;
        qty: number;
        model?: string | null;
        brand?: string | null;
        unitPrice: number;
        unitPriceVat: number;
        eyeProduct: {
            id: string;
            name: string;
            salePrice: number;
            brand?: string | null;
            status: EnumEyeProductStatus;
            description?: string | null;
            currencyId: string;
            model?: string | null;
            regionId: string;
            createdAt: any;
            updatedAt: any;
            eyeProductTypeId?: string | null;
            currency: { id: string; name: string };
            eyeProductItems: Array<{
                id: string;
                eyeProductId: string;
                eyeProductItemTypeId: string;
                qty: number;
                isOptional: boolean;
                createdAt: any;
                material: {
                    id: string;
                    code: string;
                    model?: string | null;
                    name: string;
                    spec?: string | null;
                    unit?: string | null;
                    companyId: string;
                    salePrice?: number | null;
                    saleCurrencyId?: string | null;
                    taxRate: number;
                    saleCurrency?: { id: string; name: string } | null;
                    company: { id: string; name: string; code?: string | null };
                };
            }>;
            region: { id: string; name: string };
            eyeProductType?: {
                id: string;
                regionId: string;
                name: string;
                createdAt: any;
                updatedAt: any;
            } | null;
            eyePromotions: Array<{
                id: string;
                name?: string | null;
                isAddon: boolean;
                discountRate?: number | null;
                discountAmount?: number | null;
                createdAt: any;
                date1: any;
                regionId: string;
                updatedAt: any;
                currencyId: string;
                eyePromotionAddonProductItems: Array<{
                    id: string;
                    qty: number;
                    isOptional: boolean;
                    eyePromotionId: string;
                    eyeProductItemTypeId: string;
                    material: {
                        id: string;
                        code: string;
                        model?: string | null;
                        name: string;
                        spec?: string | null;
                        unit?: string | null;
                        companyId: string;
                        salePrice?: number | null;
                        saleCurrencyId?: string | null;
                        taxRate: number;
                        saleCurrency?: { id: string; name: string } | null;
                        company: { id: string; name: string; code?: string | null };
                    };
                }>;
            }>;
        };
        eyeQuotationOrderProductItems: Array<{
            id: string;
            qty: number;
            discountedSellingPrice?: number | null;
            material: {
                id: string;
                code: string;
                model?: string | null;
                name: string;
                spec?: string | null;
                unit?: string | null;
                companyId: string;
                salePrice?: number | null;
                saleCurrencyId?: string | null;
                taxRate: number;
                saleCurrency?: { id: string; name: string } | null;
                company: { id: string; name: string; code?: string | null };
            };
        }>;
    }>;
    eyeQuotationOrderProductItems: Array<{
        id: string;
        materialId: string;
        materialCode: string;
        materialModel?: string | null;
        materialSpec?: string | null;
        materialUnit?: string | null;
        materialName?: string | null;
        materialSellingCurrencyId?: string | null;
        qty: number;
        unitPrice: number;
        unitPriceVat: number;
        eyeQuotationOrderProductId?: string | null;
        eyeQuotationOrderPromotionId?: string | null;
        discountedSellingPrice?: number | null;
    }>;
    eyeQuotationOrderPromotions: Array<{
        id: string;
        name: string;
        eyePromotionId: string;
        discountRate?: number | null;
        discountAmount?: number | null;
        currencyId: string;
        eyePromotion: {
            id: string;
            name?: string | null;
            regionId: string;
            currencyId: string;
            date1: any;
            date2?: any | null;
            isAddon: boolean;
            description?: string | null;
            discountRate?: number | null;
            discountAmount?: number | null;
            region: { id: string; name: string };
            eyePromotionAddonProductItems: Array<{
                id: string;
                qty: number;
                isOptional: boolean;
                eyePromotionId: string;
                eyeProductItemTypeId: string;
                material: {
                    id: string;
                    code: string;
                    model?: string | null;
                    name: string;
                    spec?: string | null;
                    unit?: string | null;
                    companyId: string;
                    salePrice?: number | null;
                    saleCurrencyId?: string | null;
                    taxRate: number;
                    saleCurrency?: { id: string; name: string } | null;
                    company: { id: string; name: string; code?: string | null };
                };
            }>;
        };
        eyeQuotationOrderProductItems: Array<{
            id: string;
            qty: number;
            material: {
                id: string;
                code: string;
                model?: string | null;
                name: string;
                spec?: string | null;
                unit?: string | null;
                companyId: string;
                salePrice?: number | null;
                saleCurrencyId?: string | null;
                taxRate: number;
                saleCurrency?: { id: string; name: string } | null;
                company: { id: string; name: string; code?: string | null };
            };
        }>;
    }>;
    eyeQuotationOrderWarrantyItems: Array<{
        id: string;
        materialId: string;
        materialCode: string;
        materialModel?: string | null;
        materialUnit?: string | null;
        materialName?: string | null;
        sn?: string | null;
        udi?: string | null;
        qty: number;
        memo?: string | null;
    }>;
};

export type CreateEyeQuotationOrderMutationVariables = Exact<{
    input: EyeQuotationOrderCreateInput;
}>;

export type CreateEyeQuotationOrderMutation = {
    createEyeQuotationOrder: { id: string; code: string };
};

export type RequestEyeQuotationOrderOfficialSealMutationVariables = Exact<{
    input: RequestEyeQuotationOrderOfficialSealInput;
}>;

export type RequestEyeQuotationOrderOfficialSealMutation = {
    requestEyeQuotationOrderOfficialSeal: boolean;
};

export type PaginatedEyeQuotationOrdersQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<EyeQuotationOrderFilterInput>;
}>;

export type PaginatedEyeQuotationOrdersQuery = {
    paginatedEyeQuotationOrders: {
        count?: number | null;
        eyeQuotationOrders?: Array<{
            id: string;
            code: string;
            bpmUrl?: any | null;
            officialSealStatus?: EnumEyeQuotationOrderOfficialSealStatus | null;
            officialSealBpmUrl?: any | null;
            localStandardAmount: number;
            eyeQuotationOrderTypeId?: string | null;
            onlyMaterials: boolean;
            biddingPrice?: number | null;
            userId: string;
            customerId: string;
            invoicingCustomerId: string;
            eyeServiceOrderId?: string | null;
            bpmInstanceId?: string | null;
            exchangeRate?: number | null;
            warrantyMonths?: number | null;
            warrantyPeriodTypeId?: string | null;
            expectDeliveryDate: any;
            expectPaymentDate: any;
            warrantyBuyType: EnumWarrantyBuyType;
            orderCode?: string | null;
            realAmount: number;
            untaxedAmount: number;
            contactPerson: string;
            contactPhone: string;
            regionId: string;
            status: EnumEyeQuotationOrderStatus;
            address: string;
            currencyId: string;
            standardAmount: number;
            discountRate: number;
            discountAmount: number;
            extraDiscountAmount: number;
            realDiscountAmount: number;
            realDiscountRate: number;
            taxRate: number;
            commissionAmount: number;
            discountRateWithoutCommission: number;
            description?: string | null;
            createdAt: any;
            eyeQuotationOrderType?: {
                id: string;
                name: string;
                code?: EnumEyeQuotationOrderType | null;
            } | null;
            localCurrency: { id: string; name: string };
            province?: { id: string; name?: string | null } | null;
            city?: { id: string; name: string } | null;
            district?: { id: string; name: string } | null;
            eyeQuotationOrderBusinesses: Array<{
                business?: {
                    id: string;
                    code?: string | null;
                    title?: string | null;
                    users: Array<{
                        id: string;
                        salesTeam?: { id: string; name: string } | null;
                        user: { id: string; name?: string | null };
                    }>;
                } | null;
            }>;
            creditPeriod?: { id: string; name: string } | null;
            region: {
                id: string;
                name: string;
                localCurrency?: { id: string; name: string } | null;
            };
            eyeServiceOrder?: { id: string; code: string } | null;
            customer?: { id: string; code?: string | null; name: string } | null;
            currency: { id: string; name: string; code: string };
            dept: { id: string; code?: string | null; name: string };
            invoicingCustomer?: { id: string; code?: string | null; name: string } | null;
            createdUser: { id: string; code?: string | null; name?: string | null };
            user: { id: string; code?: string | null; name?: string | null };
            eyeWarrantyPeriodType?: { id: string; code: string; name: string } | null;
            eyeQuotationOrderProducts: Array<{
                id: string;
                name: string;
                qty: number;
                model?: string | null;
                brand?: string | null;
                unitPrice: number;
                unitPriceVat: number;
                eyeProduct: {
                    id: string;
                    name: string;
                    salePrice: number;
                    brand?: string | null;
                    status: EnumEyeProductStatus;
                    description?: string | null;
                    currencyId: string;
                    model?: string | null;
                    regionId: string;
                    createdAt: any;
                    updatedAt: any;
                    eyeProductTypeId?: string | null;
                    currency: { id: string; name: string };
                    eyeProductItems: Array<{
                        id: string;
                        eyeProductId: string;
                        eyeProductItemTypeId: string;
                        qty: number;
                        isOptional: boolean;
                        createdAt: any;
                        material: {
                            id: string;
                            code: string;
                            model?: string | null;
                            name: string;
                            spec?: string | null;
                            unit?: string | null;
                            companyId: string;
                            salePrice?: number | null;
                            saleCurrencyId?: string | null;
                            taxRate: number;
                            saleCurrency?: { id: string; name: string } | null;
                            company: { id: string; name: string; code?: string | null };
                        };
                    }>;
                    region: { id: string; name: string };
                    eyeProductType?: {
                        id: string;
                        regionId: string;
                        name: string;
                        createdAt: any;
                        updatedAt: any;
                    } | null;
                    eyePromotions: Array<{
                        id: string;
                        name?: string | null;
                        isAddon: boolean;
                        discountRate?: number | null;
                        discountAmount?: number | null;
                        createdAt: any;
                        date1: any;
                        regionId: string;
                        updatedAt: any;
                        currencyId: string;
                        eyePromotionAddonProductItems: Array<{
                            id: string;
                            qty: number;
                            isOptional: boolean;
                            eyePromotionId: string;
                            eyeProductItemTypeId: string;
                            material: {
                                id: string;
                                code: string;
                                model?: string | null;
                                name: string;
                                spec?: string | null;
                                unit?: string | null;
                                companyId: string;
                                salePrice?: number | null;
                                saleCurrencyId?: string | null;
                                taxRate: number;
                                saleCurrency?: { id: string; name: string } | null;
                                company: { id: string; name: string; code?: string | null };
                            };
                        }>;
                    }>;
                };
                eyeQuotationOrderProductItems: Array<{
                    id: string;
                    qty: number;
                    discountedSellingPrice?: number | null;
                    material: {
                        id: string;
                        code: string;
                        model?: string | null;
                        name: string;
                        spec?: string | null;
                        unit?: string | null;
                        companyId: string;
                        salePrice?: number | null;
                        saleCurrencyId?: string | null;
                        taxRate: number;
                        saleCurrency?: { id: string; name: string } | null;
                        company: { id: string; name: string; code?: string | null };
                    };
                }>;
            }>;
            eyeQuotationOrderProductItems: Array<{
                id: string;
                materialId: string;
                materialCode: string;
                materialModel?: string | null;
                materialSpec?: string | null;
                materialUnit?: string | null;
                materialName?: string | null;
                materialSellingCurrencyId?: string | null;
                qty: number;
                unitPrice: number;
                unitPriceVat: number;
                eyeQuotationOrderProductId?: string | null;
                eyeQuotationOrderPromotionId?: string | null;
                discountedSellingPrice?: number | null;
            }>;
            eyeQuotationOrderPromotions: Array<{
                id: string;
                name: string;
                eyePromotionId: string;
                discountRate?: number | null;
                discountAmount?: number | null;
                currencyId: string;
                eyePromotion: {
                    id: string;
                    name?: string | null;
                    regionId: string;
                    currencyId: string;
                    date1: any;
                    date2?: any | null;
                    isAddon: boolean;
                    description?: string | null;
                    discountRate?: number | null;
                    discountAmount?: number | null;
                    region: { id: string; name: string };
                    eyePromotionAddonProductItems: Array<{
                        id: string;
                        qty: number;
                        isOptional: boolean;
                        eyePromotionId: string;
                        eyeProductItemTypeId: string;
                        material: {
                            id: string;
                            code: string;
                            model?: string | null;
                            name: string;
                            spec?: string | null;
                            unit?: string | null;
                            companyId: string;
                            salePrice?: number | null;
                            saleCurrencyId?: string | null;
                            taxRate: number;
                            saleCurrency?: { id: string; name: string } | null;
                            company: { id: string; name: string; code?: string | null };
                        };
                    }>;
                };
                eyeQuotationOrderProductItems: Array<{
                    id: string;
                    qty: number;
                    material: {
                        id: string;
                        code: string;
                        model?: string | null;
                        name: string;
                        spec?: string | null;
                        unit?: string | null;
                        companyId: string;
                        salePrice?: number | null;
                        saleCurrencyId?: string | null;
                        taxRate: number;
                        saleCurrency?: { id: string; name: string } | null;
                        company: { id: string; name: string; code?: string | null };
                    };
                }>;
            }>;
            eyeQuotationOrderWarrantyItems: Array<{
                id: string;
                materialId: string;
                materialCode: string;
                materialModel?: string | null;
                materialUnit?: string | null;
                materialName?: string | null;
                sn?: string | null;
                udi?: string | null;
                qty: number;
                memo?: string | null;
            }>;
        }> | null;
    };
};

export type EyeQuotationOrderQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type EyeQuotationOrderQuery = {
    eyeQuotationOrder: {
        id: string;
        code: string;
        bpmUrl?: any | null;
        officialSealStatus?: EnumEyeQuotationOrderOfficialSealStatus | null;
        officialSealBpmUrl?: any | null;
        localStandardAmount: number;
        eyeQuotationOrderTypeId?: string | null;
        onlyMaterials: boolean;
        biddingPrice?: number | null;
        userId: string;
        customerId: string;
        invoicingCustomerId: string;
        eyeServiceOrderId?: string | null;
        bpmInstanceId?: string | null;
        exchangeRate?: number | null;
        warrantyMonths?: number | null;
        warrantyPeriodTypeId?: string | null;
        expectDeliveryDate: any;
        expectPaymentDate: any;
        warrantyBuyType: EnumWarrantyBuyType;
        orderCode?: string | null;
        realAmount: number;
        untaxedAmount: number;
        contactPerson: string;
        contactPhone: string;
        regionId: string;
        status: EnumEyeQuotationOrderStatus;
        address: string;
        currencyId: string;
        standardAmount: number;
        discountRate: number;
        discountAmount: number;
        extraDiscountAmount: number;
        realDiscountAmount: number;
        realDiscountRate: number;
        taxRate: number;
        commissionAmount: number;
        discountRateWithoutCommission: number;
        description?: string | null;
        createdAt: any;
        eyeQuotationOrderType?: {
            id: string;
            name: string;
            code?: EnumEyeQuotationOrderType | null;
        } | null;
        localCurrency: { id: string; name: string };
        province?: { id: string; name?: string | null } | null;
        city?: { id: string; name: string } | null;
        district?: { id: string; name: string } | null;
        eyeQuotationOrderBusinesses: Array<{
            business?: {
                id: string;
                code?: string | null;
                title?: string | null;
                users: Array<{
                    id: string;
                    salesTeam?: { id: string; name: string } | null;
                    user: { id: string; name?: string | null };
                }>;
            } | null;
        }>;
        creditPeriod?: { id: string; name: string } | null;
        region: { id: string; name: string; localCurrency?: { id: string; name: string } | null };
        eyeServiceOrder?: { id: string; code: string } | null;
        customer?: { id: string; code?: string | null; name: string } | null;
        currency: { id: string; name: string; code: string };
        dept: { id: string; code?: string | null; name: string };
        invoicingCustomer?: { id: string; code?: string | null; name: string } | null;
        createdUser: { id: string; code?: string | null; name?: string | null };
        user: { id: string; code?: string | null; name?: string | null };
        eyeWarrantyPeriodType?: { id: string; code: string; name: string } | null;
        eyeQuotationOrderProducts: Array<{
            id: string;
            name: string;
            qty: number;
            model?: string | null;
            brand?: string | null;
            unitPrice: number;
            unitPriceVat: number;
            eyeProduct: {
                id: string;
                name: string;
                salePrice: number;
                brand?: string | null;
                status: EnumEyeProductStatus;
                description?: string | null;
                currencyId: string;
                model?: string | null;
                regionId: string;
                createdAt: any;
                updatedAt: any;
                eyeProductTypeId?: string | null;
                currency: { id: string; name: string };
                eyeProductItems: Array<{
                    id: string;
                    eyeProductId: string;
                    eyeProductItemTypeId: string;
                    qty: number;
                    isOptional: boolean;
                    createdAt: any;
                    material: {
                        id: string;
                        code: string;
                        model?: string | null;
                        name: string;
                        spec?: string | null;
                        unit?: string | null;
                        companyId: string;
                        salePrice?: number | null;
                        saleCurrencyId?: string | null;
                        taxRate: number;
                        saleCurrency?: { id: string; name: string } | null;
                        company: { id: string; name: string; code?: string | null };
                    };
                }>;
                region: { id: string; name: string };
                eyeProductType?: {
                    id: string;
                    regionId: string;
                    name: string;
                    createdAt: any;
                    updatedAt: any;
                } | null;
                eyePromotions: Array<{
                    id: string;
                    name?: string | null;
                    isAddon: boolean;
                    discountRate?: number | null;
                    discountAmount?: number | null;
                    createdAt: any;
                    date1: any;
                    regionId: string;
                    updatedAt: any;
                    currencyId: string;
                    eyePromotionAddonProductItems: Array<{
                        id: string;
                        qty: number;
                        isOptional: boolean;
                        eyePromotionId: string;
                        eyeProductItemTypeId: string;
                        material: {
                            id: string;
                            code: string;
                            model?: string | null;
                            name: string;
                            spec?: string | null;
                            unit?: string | null;
                            companyId: string;
                            salePrice?: number | null;
                            saleCurrencyId?: string | null;
                            taxRate: number;
                            saleCurrency?: { id: string; name: string } | null;
                            company: { id: string; name: string; code?: string | null };
                        };
                    }>;
                }>;
            };
            eyeQuotationOrderProductItems: Array<{
                id: string;
                qty: number;
                discountedSellingPrice?: number | null;
                material: {
                    id: string;
                    code: string;
                    model?: string | null;
                    name: string;
                    spec?: string | null;
                    unit?: string | null;
                    companyId: string;
                    salePrice?: number | null;
                    saleCurrencyId?: string | null;
                    taxRate: number;
                    saleCurrency?: { id: string; name: string } | null;
                    company: { id: string; name: string; code?: string | null };
                };
            }>;
        }>;
        eyeQuotationOrderProductItems: Array<{
            id: string;
            materialId: string;
            materialCode: string;
            materialModel?: string | null;
            materialSpec?: string | null;
            materialUnit?: string | null;
            materialName?: string | null;
            materialSellingCurrencyId?: string | null;
            qty: number;
            unitPrice: number;
            unitPriceVat: number;
            eyeQuotationOrderProductId?: string | null;
            eyeQuotationOrderPromotionId?: string | null;
            discountedSellingPrice?: number | null;
        }>;
        eyeQuotationOrderPromotions: Array<{
            id: string;
            name: string;
            eyePromotionId: string;
            discountRate?: number | null;
            discountAmount?: number | null;
            currencyId: string;
            eyePromotion: {
                id: string;
                name?: string | null;
                regionId: string;
                currencyId: string;
                date1: any;
                date2?: any | null;
                isAddon: boolean;
                description?: string | null;
                discountRate?: number | null;
                discountAmount?: number | null;
                region: { id: string; name: string };
                eyePromotionAddonProductItems: Array<{
                    id: string;
                    qty: number;
                    isOptional: boolean;
                    eyePromotionId: string;
                    eyeProductItemTypeId: string;
                    material: {
                        id: string;
                        code: string;
                        model?: string | null;
                        name: string;
                        spec?: string | null;
                        unit?: string | null;
                        companyId: string;
                        salePrice?: number | null;
                        saleCurrencyId?: string | null;
                        taxRate: number;
                        saleCurrency?: { id: string; name: string } | null;
                        company: { id: string; name: string; code?: string | null };
                    };
                }>;
            };
            eyeQuotationOrderProductItems: Array<{
                id: string;
                qty: number;
                material: {
                    id: string;
                    code: string;
                    model?: string | null;
                    name: string;
                    spec?: string | null;
                    unit?: string | null;
                    companyId: string;
                    salePrice?: number | null;
                    saleCurrencyId?: string | null;
                    taxRate: number;
                    saleCurrency?: { id: string; name: string } | null;
                    company: { id: string; name: string; code?: string | null };
                };
            }>;
        }>;
        eyeQuotationOrderWarrantyItems: Array<{
            id: string;
            materialId: string;
            materialCode: string;
            materialModel?: string | null;
            materialUnit?: string | null;
            materialName?: string | null;
            sn?: string | null;
            udi?: string | null;
            qty: number;
            memo?: string | null;
        }>;
    };
};

export type QuotatioProductQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type QuotatioProductQuery = {
    eyeProduct: {
        id: string;
        name: string;
        salePrice: number;
        brand?: string | null;
        status: EnumEyeProductStatus;
        description?: string | null;
        currencyId: string;
        model?: string | null;
        regionId: string;
        createdAt: any;
        updatedAt: any;
        eyeProductTypeId?: string | null;
        currency: { id: string; name: string };
        eyeProductItems: Array<{
            id: string;
            eyeProductId: string;
            eyeProductItemTypeId: string;
            qty: number;
            isOptional: boolean;
            createdAt: any;
            material: {
                id: string;
                code: string;
                model?: string | null;
                name: string;
                spec?: string | null;
                unit?: string | null;
                companyId: string;
                salePrice?: number | null;
                saleCurrencyId?: string | null;
                taxRate: number;
                saleCurrency?: { id: string; name: string } | null;
                company: { id: string; name: string; code?: string | null };
            };
        }>;
        region: { id: string; name: string };
        eyeProductType?: {
            id: string;
            regionId: string;
            name: string;
            createdAt: any;
            updatedAt: any;
        } | null;
        eyePromotions: Array<{
            id: string;
            name?: string | null;
            isAddon: boolean;
            discountRate?: number | null;
            discountAmount?: number | null;
            createdAt: any;
            date1: any;
            regionId: string;
            updatedAt: any;
            currencyId: string;
            eyePromotionAddonProductItems: Array<{
                id: string;
                qty: number;
                isOptional: boolean;
                eyePromotionId: string;
                eyeProductItemTypeId: string;
                material: {
                    id: string;
                    code: string;
                    model?: string | null;
                    name: string;
                    spec?: string | null;
                    unit?: string | null;
                    companyId: string;
                    salePrice?: number | null;
                    saleCurrencyId?: string | null;
                    taxRate: number;
                    saleCurrency?: { id: string; name: string } | null;
                    company: { id: string; name: string; code?: string | null };
                };
            }>;
        }>;
    };
};

export type QuotatioProductsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<EyeProductFilterInput>;
}>;

export type QuotatioProductsQuery = {
    paginatedEyeProducts: {
        count?: number | null;
        eyeProducts?: Array<{
            id: string;
            name: string;
            salePrice: number;
            brand?: string | null;
            status: EnumEyeProductStatus;
            description?: string | null;
            currencyId: string;
            model?: string | null;
            regionId: string;
            createdAt: any;
            updatedAt: any;
            eyeProductTypeId?: string | null;
            currency: { id: string; name: string };
            eyeProductItems: Array<{
                id: string;
                eyeProductId: string;
                eyeProductItemTypeId: string;
                qty: number;
                isOptional: boolean;
                createdAt: any;
                material: {
                    id: string;
                    code: string;
                    model?: string | null;
                    name: string;
                    spec?: string | null;
                    unit?: string | null;
                    companyId: string;
                    salePrice?: number | null;
                    saleCurrencyId?: string | null;
                    taxRate: number;
                    saleCurrency?: { id: string; name: string } | null;
                    company: { id: string; name: string; code?: string | null };
                };
            }>;
            region: { id: string; name: string };
            eyeProductType?: {
                id: string;
                regionId: string;
                name: string;
                createdAt: any;
                updatedAt: any;
            } | null;
            eyePromotions: Array<{
                id: string;
                name?: string | null;
                isAddon: boolean;
                discountRate?: number | null;
                discountAmount?: number | null;
                createdAt: any;
                date1: any;
                regionId: string;
                updatedAt: any;
                currencyId: string;
                eyePromotionAddonProductItems: Array<{
                    id: string;
                    qty: number;
                    isOptional: boolean;
                    eyePromotionId: string;
                    eyeProductItemTypeId: string;
                    material: {
                        id: string;
                        code: string;
                        model?: string | null;
                        name: string;
                        spec?: string | null;
                        unit?: string | null;
                        companyId: string;
                        salePrice?: number | null;
                        saleCurrencyId?: string | null;
                        taxRate: number;
                        saleCurrency?: { id: string; name: string } | null;
                        company: { id: string; name: string; code?: string | null };
                    };
                }>;
            }>;
        }> | null;
    };
};

export type QuotatioMaterialQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type QuotatioMaterialQuery = {
    material: {
        id: string;
        code: string;
        model?: string | null;
        name: string;
        spec?: string | null;
        unit?: string | null;
        companyId: string;
        salePrice?: number | null;
        saleCurrencyId?: string | null;
        taxRate: number;
        saleCurrency?: { id: string; name: string } | null;
        company: { id: string; name: string; code?: string | null };
    };
};

export type QuotatioMaterialsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<MaterialFilterInput>;
}>;

export type QuotatioMaterialsQuery = {
    paginatedMaterials: {
        count?: number | null;
        materials?: Array<{
            id: string;
            code: string;
            model?: string | null;
            name: string;
            spec?: string | null;
            unit?: string | null;
            companyId: string;
            salePrice?: number | null;
            saleCurrencyId?: string | null;
            taxRate: number;
            saleCurrency?: { id: string; name: string } | null;
            company: { id: string; name: string; code?: string | null };
        }> | null;
    };
};

export type QuotatioPromotionQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type QuotatioPromotionQuery = {
    eyePromotion: {
        id: string;
        name?: string | null;
        regionId: string;
        currencyId: string;
        date1: any;
        date2?: any | null;
        isAddon: boolean;
        description?: string | null;
        discountRate?: number | null;
        discountAmount?: number | null;
        region: { id: string; name: string };
        eyePromotionAddonProductItems: Array<{
            id: string;
            qty: number;
            isOptional: boolean;
            eyePromotionId: string;
            eyeProductItemTypeId: string;
            material: {
                id: string;
                code: string;
                model?: string | null;
                name: string;
                spec?: string | null;
                unit?: string | null;
                companyId: string;
                salePrice?: number | null;
                saleCurrencyId?: string | null;
                taxRate: number;
                saleCurrency?: { id: string; name: string } | null;
                company: { id: string; name: string; code?: string | null };
            };
        }>;
    };
};

export type QuotatioPromotionsQueryVariables = Exact<{
    filters?: InputMaybe<EyePromotionFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
}>;

export type QuotatioPromotionsQuery = {
    eyePromotions: Array<{
        id: string;
        name?: string | null;
        regionId: string;
        currencyId: string;
        date1: any;
        date2?: any | null;
        isAddon: boolean;
        description?: string | null;
        discountRate?: number | null;
        discountAmount?: number | null;
        region: { id: string; name: string };
        eyePromotionAddonProductItems: Array<{
            id: string;
            qty: number;
            isOptional: boolean;
            eyePromotionId: string;
            eyeProductItemTypeId: string;
            material: {
                id: string;
                code: string;
                model?: string | null;
                name: string;
                spec?: string | null;
                unit?: string | null;
                companyId: string;
                salePrice?: number | null;
                saleCurrencyId?: string | null;
                taxRate: number;
                saleCurrency?: { id: string; name: string } | null;
                company: { id: string; name: string; code?: string | null };
            };
        }>;
    }>;
};

export type CurrenciesQueryVariables = Exact<{ [key: string]: never }>;

export type CurrenciesQuery = { currencies: Array<{ id: string; name: string; code: string }> };

export type ExchangeRatesQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<ExchangeRateFilterInput>;
}>;

export type ExchangeRatesQuery = {
    exchangeRates: Array<{ id: string; rate: number; currency1Id: string; currency2Id: string }>;
};

export type EyeWarrantyPeriodTypesQueryVariables = Exact<{ [key: string]: never }>;

export type EyeWarrantyPeriodTypesQuery = {
    eyeWarrantyPeriodTypes: Array<{ name: string; id: string }>;
};

export type EyeQuotationOrderTypesQueryVariables = Exact<{
    filters?: InputMaybe<EyeQuotationOrderTypeFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
}>;

export type EyeQuotationOrderTypesQuery = {
    eyeQuotationOrderTypes: Array<{
        id: string;
        name: string;
        code?: EnumEyeQuotationOrderType | null;
    }>;
};

export type EyeServiceOrderFragment = {
    bpmUrl?: any | null;
    extensionCount?: number | null;
    id: string;
    regionId: string;
    status: EnumEyeServiceOrderStatus;
    updatedAt: any;
    priority: EnumEyeServiceOrderPriority;
    approvalStatus: EnumEyeServiceOrderApprovalStatus;
    memo?: string | null;
    districtId?: string | null;
    address: string;
    estimatedDate: any;
    description?: string | null;
    createdAt: any;
    contactPerson: string;
    contactPhone: string;
    code: string;
    extension?: {
        bpmUrl?: any | null;
        approvalStatus: EnumEyeServiceOrderExtensionApprovalStatus;
    } | null;
    user?: { id: string; code?: string | null; name?: string | null } | null;
    updatedUser?: { id: string; code?: string | null; name?: string | null } | null;
    eyeFixedAssetRentalGoal?: { id: string; name: string } | null;
    eyeFixedAssetRentalObject?: { id: string; name: string } | null;
    province?: { id: string; name?: string | null; code?: string | null } | null;
    district?: { id: string; code?: string | null; name: string } | null;
    approverUser?: { id: string; name?: string | null; code?: string | null } | null;
    eyeServiceOrderType: { id: string; name: string };
    assigneeDept?: {
        id: string;
        name: string;
        company?: { id: string; name: string; code?: string | null } | null;
    } | null;
    customer?: { id: string; name: string; code?: string | null } | null;
    createdUser?: { id: string; code?: string | null; name?: string | null } | null;
    company?: { id: string; name: string; code?: string | null } | null;
    city?: { id: string; name: string } | null;
    assigneeUser?: {
        id: string;
        code?: string | null;
        name?: string | null;
        dept?: { id: string; name: string } | null;
    } | null;
    business?: {
        id: string;
        code?: string | null;
        title?: string | null;
        expectedClosedDate?: any | null;
        status?: { id: string; name: string } | null;
        customer?: { id: string; name: string } | null;
        mapBudgetProducts: Array<{ id: string; budgetProduct: { id: string; name: string } }>;
    } | null;
    costCenter?: { id: string; code: string; name: string } | null;
    eyeWarrantyContract?: { id: string; code: string } | null;
    region: { id: string; name: string };
    eyeFixedAssetRentalRecords: Array<{
        date2: any;
        date1: any;
        id: string;
        status: EnumEyeFixedAssetsRentalStatus;
        remark?: string | null;
        eyeFixedAssets: Array<{
            id: string;
            name: string;
            status: EnumEyeFixedAssetsStatus;
            memo?: string | null;
            currentLocation?: string | null;
            eyeFixedAssetItems: Array<{
                id: string;
                materialCode: string;
                materialName?: string | null;
            }>;
        }>;
    }>;
    eyeServiceOrderWorkDiaries: Array<{
        id: string;
        hours?: number | null;
        date?: any | null;
        status: EnumEyeServiceOrderWorkDiaryStatus;
        remark?: string | null;
        users: Array<{ name?: string | null }>;
    }>;
};

export type EyeServiceOrderTypeFragment = {
    name: string;
    id: string;
    code?: EnumEyeServiceOrderTypeCode | null;
};

export type CostCenterFragment = {
    id: string;
    isActive?: boolean | null;
    name: string;
    code: string;
};

export type EyeServiceOrderWorkDiaryFragment = {
    id: string;
    hours?: number | null;
    date?: any | null;
    status: EnumEyeServiceOrderWorkDiaryStatus;
    remark?: string | null;
    users: Array<{ name?: string | null }>;
};

export type ServiceOrderAssetRentalRecordFragment = {
    id: string;
    date1: any;
    date2: any;
    eyeServiceOrder?: {
        customer?: { id: string; code?: string | null; name: string } | null;
    } | null;
};

export type CreateEyeServiceOrderMutationVariables = Exact<{
    input: EyeServiceOrderCreateInput;
}>;

export type CreateEyeServiceOrderMutation = {
    createEyeServiceOrder: {
        bpmUrl?: any | null;
        extensionCount?: number | null;
        id: string;
        regionId: string;
        status: EnumEyeServiceOrderStatus;
        updatedAt: any;
        priority: EnumEyeServiceOrderPriority;
        approvalStatus: EnumEyeServiceOrderApprovalStatus;
        memo?: string | null;
        districtId?: string | null;
        address: string;
        estimatedDate: any;
        description?: string | null;
        createdAt: any;
        contactPerson: string;
        contactPhone: string;
        code: string;
        extension?: {
            bpmUrl?: any | null;
            approvalStatus: EnumEyeServiceOrderExtensionApprovalStatus;
        } | null;
        user?: { id: string; code?: string | null; name?: string | null } | null;
        updatedUser?: { id: string; code?: string | null; name?: string | null } | null;
        eyeFixedAssetRentalGoal?: { id: string; name: string } | null;
        eyeFixedAssetRentalObject?: { id: string; name: string } | null;
        province?: { id: string; name?: string | null; code?: string | null } | null;
        district?: { id: string; code?: string | null; name: string } | null;
        approverUser?: { id: string; name?: string | null; code?: string | null } | null;
        eyeServiceOrderType: { id: string; name: string };
        assigneeDept?: {
            id: string;
            name: string;
            company?: { id: string; name: string; code?: string | null } | null;
        } | null;
        customer?: { id: string; name: string; code?: string | null } | null;
        createdUser?: { id: string; code?: string | null; name?: string | null } | null;
        company?: { id: string; name: string; code?: string | null } | null;
        city?: { id: string; name: string } | null;
        assigneeUser?: {
            id: string;
            code?: string | null;
            name?: string | null;
            dept?: { id: string; name: string } | null;
        } | null;
        business?: {
            id: string;
            code?: string | null;
            title?: string | null;
            expectedClosedDate?: any | null;
            status?: { id: string; name: string } | null;
            customer?: { id: string; name: string } | null;
            mapBudgetProducts: Array<{ id: string; budgetProduct: { id: string; name: string } }>;
        } | null;
        costCenter?: { id: string; code: string; name: string } | null;
        eyeWarrantyContract?: { id: string; code: string } | null;
        region: { id: string; name: string };
        eyeFixedAssetRentalRecords: Array<{
            date2: any;
            date1: any;
            id: string;
            status: EnumEyeFixedAssetsRentalStatus;
            remark?: string | null;
            eyeFixedAssets: Array<{
                id: string;
                name: string;
                status: EnumEyeFixedAssetsStatus;
                memo?: string | null;
                currentLocation?: string | null;
                eyeFixedAssetItems: Array<{
                    id: string;
                    materialCode: string;
                    materialName?: string | null;
                }>;
            }>;
        }>;
        eyeServiceOrderWorkDiaries: Array<{
            id: string;
            hours?: number | null;
            date?: any | null;
            status: EnumEyeServiceOrderWorkDiaryStatus;
            remark?: string | null;
            users: Array<{ name?: string | null }>;
        }>;
    };
};

export type UpdateEyeServiceOrderMutationVariables = Exact<{
    input: EyeServiceOrderUpdateInput;
}>;

export type UpdateEyeServiceOrderMutation = {
    updateEyeServiceOrder: {
        bpmUrl?: any | null;
        extensionCount?: number | null;
        id: string;
        regionId: string;
        status: EnumEyeServiceOrderStatus;
        updatedAt: any;
        priority: EnumEyeServiceOrderPriority;
        approvalStatus: EnumEyeServiceOrderApprovalStatus;
        memo?: string | null;
        districtId?: string | null;
        address: string;
        estimatedDate: any;
        description?: string | null;
        createdAt: any;
        contactPerson: string;
        contactPhone: string;
        code: string;
        extension?: {
            bpmUrl?: any | null;
            approvalStatus: EnumEyeServiceOrderExtensionApprovalStatus;
        } | null;
        user?: { id: string; code?: string | null; name?: string | null } | null;
        updatedUser?: { id: string; code?: string | null; name?: string | null } | null;
        eyeFixedAssetRentalGoal?: { id: string; name: string } | null;
        eyeFixedAssetRentalObject?: { id: string; name: string } | null;
        province?: { id: string; name?: string | null; code?: string | null } | null;
        district?: { id: string; code?: string | null; name: string } | null;
        approverUser?: { id: string; name?: string | null; code?: string | null } | null;
        eyeServiceOrderType: { id: string; name: string };
        assigneeDept?: {
            id: string;
            name: string;
            company?: { id: string; name: string; code?: string | null } | null;
        } | null;
        customer?: { id: string; name: string; code?: string | null } | null;
        createdUser?: { id: string; code?: string | null; name?: string | null } | null;
        company?: { id: string; name: string; code?: string | null } | null;
        city?: { id: string; name: string } | null;
        assigneeUser?: {
            id: string;
            code?: string | null;
            name?: string | null;
            dept?: { id: string; name: string } | null;
        } | null;
        business?: {
            id: string;
            code?: string | null;
            title?: string | null;
            expectedClosedDate?: any | null;
            status?: { id: string; name: string } | null;
            customer?: { id: string; name: string } | null;
            mapBudgetProducts: Array<{ id: string; budgetProduct: { id: string; name: string } }>;
        } | null;
        costCenter?: { id: string; code: string; name: string } | null;
        eyeWarrantyContract?: { id: string; code: string } | null;
        region: { id: string; name: string };
        eyeFixedAssetRentalRecords: Array<{
            date2: any;
            date1: any;
            id: string;
            status: EnumEyeFixedAssetsRentalStatus;
            remark?: string | null;
            eyeFixedAssets: Array<{
                id: string;
                name: string;
                status: EnumEyeFixedAssetsStatus;
                memo?: string | null;
                currentLocation?: string | null;
                eyeFixedAssetItems: Array<{
                    id: string;
                    materialCode: string;
                    materialName?: string | null;
                }>;
            }>;
        }>;
        eyeServiceOrderWorkDiaries: Array<{
            id: string;
            hours?: number | null;
            date?: any | null;
            status: EnumEyeServiceOrderWorkDiaryStatus;
            remark?: string | null;
            users: Array<{ name?: string | null }>;
        }>;
    };
};

export type RequestEyeServiceOrderExtensionMutationVariables = Exact<{
    input: RequestEyeServiceOrderExtensionInput;
}>;

export type RequestEyeServiceOrderExtensionMutation = { requestEyeServiceOrderExtension: boolean };

export type PaginatedEyeServiceOrdersQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<EyeServiceOrderFilterInput>;
}>;

export type PaginatedEyeServiceOrdersQuery = {
    paginatedEyeServiceOrders: {
        count?: number | null;
        eyeServiceOrders?: Array<{
            bpmUrl?: any | null;
            extensionCount?: number | null;
            id: string;
            regionId: string;
            status: EnumEyeServiceOrderStatus;
            updatedAt: any;
            priority: EnumEyeServiceOrderPriority;
            approvalStatus: EnumEyeServiceOrderApprovalStatus;
            memo?: string | null;
            districtId?: string | null;
            address: string;
            estimatedDate: any;
            description?: string | null;
            createdAt: any;
            contactPerson: string;
            contactPhone: string;
            code: string;
            extension?: {
                bpmUrl?: any | null;
                approvalStatus: EnumEyeServiceOrderExtensionApprovalStatus;
            } | null;
            user?: { id: string; code?: string | null; name?: string | null } | null;
            updatedUser?: { id: string; code?: string | null; name?: string | null } | null;
            eyeFixedAssetRentalGoal?: { id: string; name: string } | null;
            eyeFixedAssetRentalObject?: { id: string; name: string } | null;
            province?: { id: string; name?: string | null; code?: string | null } | null;
            district?: { id: string; code?: string | null; name: string } | null;
            approverUser?: { id: string; name?: string | null; code?: string | null } | null;
            eyeServiceOrderType: { id: string; name: string };
            assigneeDept?: {
                id: string;
                name: string;
                company?: { id: string; name: string; code?: string | null } | null;
            } | null;
            customer?: { id: string; name: string; code?: string | null } | null;
            createdUser?: { id: string; code?: string | null; name?: string | null } | null;
            company?: { id: string; name: string; code?: string | null } | null;
            city?: { id: string; name: string } | null;
            assigneeUser?: {
                id: string;
                code?: string | null;
                name?: string | null;
                dept?: { id: string; name: string } | null;
            } | null;
            business?: {
                id: string;
                code?: string | null;
                title?: string | null;
                expectedClosedDate?: any | null;
                status?: { id: string; name: string } | null;
                customer?: { id: string; name: string } | null;
                mapBudgetProducts: Array<{
                    id: string;
                    budgetProduct: { id: string; name: string };
                }>;
            } | null;
            costCenter?: { id: string; code: string; name: string } | null;
            eyeWarrantyContract?: { id: string; code: string } | null;
            region: { id: string; name: string };
            eyeFixedAssetRentalRecords: Array<{
                date2: any;
                date1: any;
                id: string;
                status: EnumEyeFixedAssetsRentalStatus;
                remark?: string | null;
                eyeFixedAssets: Array<{
                    id: string;
                    name: string;
                    status: EnumEyeFixedAssetsStatus;
                    memo?: string | null;
                    currentLocation?: string | null;
                    eyeFixedAssetItems: Array<{
                        id: string;
                        materialCode: string;
                        materialName?: string | null;
                    }>;
                }>;
            }>;
            eyeServiceOrderWorkDiaries: Array<{
                id: string;
                hours?: number | null;
                date?: any | null;
                status: EnumEyeServiceOrderWorkDiaryStatus;
                remark?: string | null;
                users: Array<{ name?: string | null }>;
            }>;
        }> | null;
    };
};

export type EyeServiceOrderQueryVariables = Exact<{
    eyeServiceOrderId: Scalars['ID'];
}>;

export type EyeServiceOrderQuery = {
    eyeServiceOrder: {
        bpmUrl?: any | null;
        extensionCount?: number | null;
        id: string;
        regionId: string;
        status: EnumEyeServiceOrderStatus;
        updatedAt: any;
        priority: EnumEyeServiceOrderPriority;
        approvalStatus: EnumEyeServiceOrderApprovalStatus;
        memo?: string | null;
        districtId?: string | null;
        address: string;
        estimatedDate: any;
        description?: string | null;
        createdAt: any;
        contactPerson: string;
        contactPhone: string;
        code: string;
        extension?: {
            bpmUrl?: any | null;
            approvalStatus: EnumEyeServiceOrderExtensionApprovalStatus;
        } | null;
        user?: { id: string; code?: string | null; name?: string | null } | null;
        updatedUser?: { id: string; code?: string | null; name?: string | null } | null;
        eyeFixedAssetRentalGoal?: { id: string; name: string } | null;
        eyeFixedAssetRentalObject?: { id: string; name: string } | null;
        province?: { id: string; name?: string | null; code?: string | null } | null;
        district?: { id: string; code?: string | null; name: string } | null;
        approverUser?: { id: string; name?: string | null; code?: string | null } | null;
        eyeServiceOrderType: { id: string; name: string };
        assigneeDept?: {
            id: string;
            name: string;
            company?: { id: string; name: string; code?: string | null } | null;
        } | null;
        customer?: { id: string; name: string; code?: string | null } | null;
        createdUser?: { id: string; code?: string | null; name?: string | null } | null;
        company?: { id: string; name: string; code?: string | null } | null;
        city?: { id: string; name: string } | null;
        assigneeUser?: {
            id: string;
            code?: string | null;
            name?: string | null;
            dept?: { id: string; name: string } | null;
        } | null;
        business?: {
            id: string;
            code?: string | null;
            title?: string | null;
            expectedClosedDate?: any | null;
            status?: { id: string; name: string } | null;
            customer?: { id: string; name: string } | null;
            mapBudgetProducts: Array<{ id: string; budgetProduct: { id: string; name: string } }>;
        } | null;
        costCenter?: { id: string; code: string; name: string } | null;
        eyeWarrantyContract?: { id: string; code: string } | null;
        region: { id: string; name: string };
        eyeFixedAssetRentalRecords: Array<{
            date2: any;
            date1: any;
            id: string;
            status: EnumEyeFixedAssetsRentalStatus;
            remark?: string | null;
            eyeFixedAssets: Array<{
                id: string;
                name: string;
                status: EnumEyeFixedAssetsStatus;
                memo?: string | null;
                currentLocation?: string | null;
                eyeFixedAssetItems: Array<{
                    id: string;
                    materialCode: string;
                    materialName?: string | null;
                }>;
            }>;
        }>;
        eyeServiceOrderWorkDiaries: Array<{
            id: string;
            hours?: number | null;
            date?: any | null;
            status: EnumEyeServiceOrderWorkDiaryStatus;
            remark?: string | null;
            users: Array<{ name?: string | null }>;
        }>;
    };
};

export type EyeServiceOrderTypesQueryVariables = Exact<{ [key: string]: never }>;

export type EyeServiceOrderTypesQuery = {
    paginatedEyeServiceOrderTypes: {
        eyeServiceOrderTypes?: Array<{
            id: string;
            code?: EnumEyeServiceOrderTypeCode | null;
            name: string;
        }> | null;
    };
};

export type CostCentersQueryVariables = Exact<{ [key: string]: never }>;

export type CostCentersQuery = {
    paginatedCostCenters: {
        costCenters?: Array<{
            id: string;
            isActive?: boolean | null;
            name: string;
            code: string;
        }> | null;
    };
};

export type EyeFixedAssetRentalObjectsQueryVariables = Exact<{
    filters?: InputMaybe<EyeFixedAssetRentalObjectFilterInput>;
}>;

export type EyeFixedAssetRentalObjectsQuery = {
    eyeFixedAssetRentalObjects: Array<{ id: string; name: string; isActive: boolean }>;
};

export type ServiceOrderEyeFixedAssetsQueryVariables = Exact<{
    filters?: InputMaybe<EyeFixedAssetFilterInput>;
}>;

export type ServiceOrderEyeFixedAssetsQuery = {
    eyeFixedAssets: Array<{
        nextEyeFixedAssetRentalRecord?: {
            id: string;
            date1: any;
            date2: any;
            eyeServiceOrder?: {
                customer?: { id: string; code?: string | null; name: string } | null;
            } | null;
        } | null;
        prevEyeFixedAssetRentalRecord?: {
            id: string;
            date1: any;
            date2: any;
            eyeServiceOrder?: {
                customer?: { id: string; code?: string | null; name: string } | null;
            } | null;
        } | null;
    }>;
};

export type DepartmentFragment = {
    id: string;
    companyId?: string | null;
    name: string;
    code?: string | null;
    createdAt: any;
    updatedAt: any;
    regionId: string;
    setOfBook?: string | null;
    isActive: boolean;
    company?: {
        id: string;
        code?: string | null;
        name: string;
        createdAt: any;
        updatedAt: any;
        regionId: string;
    } | null;
    users: Array<{ id: string; code?: string | null; name?: string | null }>;
    children: Array<{
        id: string;
        name: string;
        code?: string | null;
        children: Array<{
            id: string;
            name: string;
            code?: string | null;
            children: Array<{
                id: string;
                name: string;
                code?: string | null;
                children: Array<{
                    id: string;
                    name: string;
                    code?: string | null;
                    children: Array<{
                        id: string;
                        name: string;
                        code?: string | null;
                        children: Array<{
                            id: string;
                            name: string;
                            code?: string | null;
                            company?: { id: string; name: string } | null;
                        }>;
                        company?: { id: string; name: string } | null;
                    }>;
                    company?: { id: string; name: string } | null;
                }>;
                company?: { id: string; name: string } | null;
            }>;
            company?: { id: string; name: string } | null;
        }>;
        company?: { id: string; name: string } | null;
    }>;
};

export type ChildrensFragment = {
    id: string;
    name: string;
    code?: string | null;
    company?: { id: string; name: string } | null;
};

export type LoginBySsoMutationVariables = Exact<{
    SSOToken: Scalars['String'];
}>;

export type LoginBySsoMutation = {
    loginBySSO: {
        token?: string | null;
        user?: {
            id: string;
            name?: string | null;
            email?: string | null;
            code?: string | null;
        } | null;
    };
};

export type PaginatedUsersQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<UserFilterInput>;
}>;

export type PaginatedUsersQuery = {
    paginatedUsers: {
        count?: number | null;
        users?: Array<{
            id: string;
            name?: string | null;
            code?: string | null;
            email?: string | null;
        }> | null;
    };
};

export type PaginatedDepartmentsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<DepartmentFilterInput>;
}>;

export type PaginatedDepartmentsQuery = {
    paginatedDepartments: {
        count?: number | null;
        departments?: Array<{
            id: string;
            companyId?: string | null;
            name: string;
            code?: string | null;
            createdAt: any;
            updatedAt: any;
            regionId: string;
            setOfBook?: string | null;
            isActive: boolean;
            company?: {
                id: string;
                code?: string | null;
                name: string;
                createdAt: any;
                updatedAt: any;
                regionId: string;
            } | null;
            users: Array<{ id: string; code?: string | null; name?: string | null }>;
            children: Array<{
                id: string;
                name: string;
                code?: string | null;
                children: Array<{
                    id: string;
                    name: string;
                    code?: string | null;
                    children: Array<{
                        id: string;
                        name: string;
                        code?: string | null;
                        children: Array<{
                            id: string;
                            name: string;
                            code?: string | null;
                            children: Array<{
                                id: string;
                                name: string;
                                code?: string | null;
                                children: Array<{
                                    id: string;
                                    name: string;
                                    code?: string | null;
                                    company?: { id: string; name: string } | null;
                                }>;
                                company?: { id: string; name: string } | null;
                            }>;
                            company?: { id: string; name: string } | null;
                        }>;
                        company?: { id: string; name: string } | null;
                    }>;
                    company?: { id: string; name: string } | null;
                }>;
                company?: { id: string; name: string } | null;
            }>;
        }> | null;
    };
};

export type DepartmentQueryVariables = Exact<{
    departmentId: Scalars['ID'];
}>;

export type DepartmentQuery = {
    department: {
        id: string;
        companyId?: string | null;
        name: string;
        code?: string | null;
        createdAt: any;
        updatedAt: any;
        regionId: string;
        setOfBook?: string | null;
        isActive: boolean;
        company?: {
            id: string;
            code?: string | null;
            name: string;
            createdAt: any;
            updatedAt: any;
            regionId: string;
        } | null;
        users: Array<{ id: string; code?: string | null; name?: string | null }>;
        children: Array<{
            id: string;
            name: string;
            code?: string | null;
            children: Array<{
                id: string;
                name: string;
                code?: string | null;
                children: Array<{
                    id: string;
                    name: string;
                    code?: string | null;
                    children: Array<{
                        id: string;
                        name: string;
                        code?: string | null;
                        children: Array<{
                            id: string;
                            name: string;
                            code?: string | null;
                            children: Array<{
                                id: string;
                                name: string;
                                code?: string | null;
                                company?: { id: string; name: string } | null;
                            }>;
                            company?: { id: string; name: string } | null;
                        }>;
                        company?: { id: string; name: string } | null;
                    }>;
                    company?: { id: string; name: string } | null;
                }>;
                company?: { id: string; name: string } | null;
            }>;
            company?: { id: string; name: string } | null;
        }>;
    };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
    me: {
        id: string;
        name?: string | null;
        email?: string | null;
        code?: string | null;
        salesTeamUnit?: {
            id: string;
            name: string;
            user?: { id: string; name?: string | null } | null;
            salesTeam?: { id: string; name: string; code?: string | null } | null;
        } | null;
        defaultAvailableSalesTeamGroup?: {
            id: string;
            name: string;
            code?: EnumSalesTeamGroupCode | null;
            region?: {
                id: string;
                name: string;
                localCurrency?: { id: string; code: string; name: string } | null;
                taxRates: Array<{ id: string; rate: number }>;
            } | null;
        } | null;
        salesTeams: Array<{
            id: string;
            name: string;
            top?: { code?: string | null; id: string; name: string } | null;
        }>;
        availableSalesTeamGroups: Array<{
            id: string;
            name: string;
            code?: EnumSalesTeamGroupCode | null;
            region?: {
                id: string;
                name: string;
                localCurrency?: { id: string; code: string; name: string } | null;
                taxRates: Array<{ id: string; rate: number }>;
            } | null;
        }>;
        dept?: {
            id: string;
            name: string;
            manager?: { id: string; name?: string | null } | null;
        } | null;
        region?: {
            id: string;
            name: string;
            localCurrency?: { id: string; code: string; name: string } | null;
            taxRates: Array<{ id: string; rate: number }>;
        } | null;
        company?: { id: string; name: string } | null;
        userPermission: {
            codes: Array<string>;
            allowRegionIds: Array<string>;
            allowCompanyIds: Array<string>;
            allowSalesTeamGroupIds: Array<string>;
            allowSalesTeamIds: Array<string>;
        };
    };
};

export type VisitBasicInfoFragment = {
    id: string;
    hasAssistedVisitSupervisor: boolean;
    createdAt: any;
    updatedAt: any;
    status: EnumVisitStatus;
    title?: string | null;
    content?: string | null;
    visitAddress?: string | null;
    visitDate: any;
    priorityOrder?: number | null;
    salesTeamUnitId?: string | null;
    actionContent?: string | null;
    assistedVisitSupervisor?: { id: string; name?: string | null } | null;
    salesTeamGroup: { id: string; name: string };
    salesTeamUnit?: {
        id: string;
        name: string;
        user?: { id: string; name?: string | null } | null;
        salesTeam?: { id: string; name: string; code?: string | null } | null;
    } | null;
    type?: { id: string; name: string; code?: EnumVisitTypeCode | null } | null;
    business?: {
        id: string;
        title?: string | null;
        content?: string | null;
        code?: string | null;
        budgetAmount?: number | null;
        saleAmount?: number | null;
        dealAmount?: number | null;
        expectedClosedDate?: any | null;
        closedDate?: any | null;
        status?: { id: string; buyingOpportunity?: string | null; name: string } | null;
        winningOpportunity?: { id: string; name: string } | null;
        buyingOpportunity?: { id: string; name: string } | null;
        mapBudgetProducts: Array<{
            id: string;
            qty: number;
            budgetProduct: { id: string; name: string };
        }>;
        customer?: { id: string; name: string } | null;
    } | null;
    action?: { id: string; name: string } | null;
    customer?: {
        id: string;
        name: string;
        code?: string | null;
        address?: string | null;
        type?: { id: string; name: string } | null;
        city?: { id: string; name: string } | null;
        district?: { id: string; name: string } | null;
    } | null;
    salesTeam?: { id: string; name: string } | null;
    primaryUser?: {
        id: string;
        code?: string | null;
        name?: string | null;
        email?: string | null;
    } | null;
    timePeriods: Array<{ id: string; name: string }>;
    goals: Array<{ id: string; name: string }>;
    properties: Array<{ id: string; name: string; type: { id: string; name: string } }>;
    mapMentionProducts: Array<{ id: string; mentionProduct: { id: string; name: string } }>;
    mapRentFixedAssets: Array<{
        id: string;
        rentFixedAsset?: { id: string; name: string; code: string } | null;
    }>;
    createdUser?: {
        id: string;
        code?: string | null;
        name?: string | null;
        email?: string | null;
    } | null;
    updatedUser?: {
        id: string;
        code?: string | null;
        name?: string | null;
        email?: string | null;
    } | null;
    primaryContactPeople: Array<{ id: string; name: string; jobTitle?: string | null }>;
};

export type VisitDetailDataFragment = {
    memos: Array<{
        id: string;
        title?: string | null;
        content?: string | null;
        createdAt: any;
        updatedAt: any;
        createdUser?: {
            id: string;
            code?: string | null;
            name?: string | null;
            email?: string | null;
        } | null;
        updatedUser?: {
            id: string;
            code?: string | null;
            name?: string | null;
            email?: string | null;
        } | null;
    }>;
};

export type BusinessBasicInfoFragment = {
    id: string;
    title?: string | null;
    content?: string | null;
    code?: string | null;
    budgetAmount?: number | null;
    saleAmount?: number | null;
    dealAmount?: number | null;
    expectedClosedDate?: any | null;
    closedDate?: any | null;
    status?: { id: string; buyingOpportunity?: string | null; name: string } | null;
    winningOpportunity?: { id: string; name: string } | null;
    buyingOpportunity?: { id: string; name: string } | null;
    mapBudgetProducts: Array<{
        id: string;
        qty: number;
        budgetProduct: { id: string; name: string };
    }>;
    customer?: { id: string; name: string } | null;
};

export type CustomerBasicInfoFragment = {
    id: string;
    name: string;
    code?: string | null;
    address?: string | null;
    type?: { id: string; name: string } | null;
    city?: { id: string; name: string } | null;
    district?: { id: string; name: string } | null;
};

export type StaffBasicInfoFragment = {
    id: string;
    code?: string | null;
    name?: string | null;
    email?: string | null;
};

export type VisitMemoBasicInfoFragment = {
    id: string;
    title?: string | null;
    content?: string | null;
    createdAt: any;
    updatedAt: any;
    createdUser?: {
        id: string;
        code?: string | null;
        name?: string | null;
        email?: string | null;
    } | null;
    updatedUser?: {
        id: string;
        code?: string | null;
        name?: string | null;
        email?: string | null;
    } | null;
};

export type VisitActionBasicInfoFragment = { id: string; name: string };

export type VisitGoalBasicInfoFragment = { id: string; name: string };

export type VisitPropertyTypeBasicInfoFragment = { id: string; name: string };

export type VisitPropertyBasicInfoFragment = { id: string; name: string };

export type VisitTimePeriodBasicInfoFragment = { id: string; name: string };

export type VisitTypeBasicInfoFragment = {
    id: string;
    name: string;
    code?: EnumVisitTypeCode | null;
};

export type VisitProductBasicInfoFragment = { id: string; name: string };

export type VisitCheckInFragment = {
    id: string;
    lat: number;
    lng: number;
    createdAt: any;
    content?: string | null;
};

export type WeeklyVisitStatFragment = {
    overall: { count: number };
    details: Array<{ type: EnumWeeklyVisitStatType; count: number }>;
};

export type VisitStatFragment = {
    overall: {
        days: number;
        count: number;
        averageCountPerDay?: number | null;
        customerCount: number;
        averageCustomerCountPerDay?: number | null;
        businessCount: number;
        totalBudgetAmount: number;
    };
    details: Array<{
        type: EnumVisitStatType;
        count: number;
        countPercent?: number | null;
        customerCount: number;
        customerCountPercentInResponsible?: number | null;
        businessCount: number;
        businessCountPercentInProgress?: number | null;
        totalBudgetAmount: number;
    }>;
};

export type VisitAnalysisBusinessRankFragment = {
    visitCount: number;
    totalBudgetAmount: number;
    seq: number;
    rank: number;
    businessCount: number;
    customer: { name: string; id: string; code?: string | null };
};

export type VisitAnalysisMentionProductRankFragment = {
    visitCount: number;
    seq: number;
    rank: number;
    mentionProductId: string;
    mentionProduct: { id: string; name: string; brand?: string | null };
};

export type CreateVisitMutationVariables = Exact<{
    input: VisitCreateInput;
}>;

export type CreateVisitMutation = { createVisit: { id: string; visitDate: any } };

export type UpdateVisitMutationVariables = Exact<{
    input: VisitUpdateInput;
}>;

export type UpdateVisitMutation = {
    updateVisit: {
        id: string;
        visitDate: any;
        mapRentFixedAssets: Array<{ rentFixedAsset?: { id: string; name: string } | null }>;
    };
};

export type BulkUpdateVisitMutationVariables = Exact<{
    input: VisitBulkUpdateInput;
}>;

export type BulkUpdateVisitMutation = { bulkUpdateVisit: Array<{ id: string; visitDate: any }> };

export type PaginatedVisitsQueryVariables = Exact<{
    filters?: InputMaybe<VisitFilterInput>;
    pagination?: InputMaybe<PaginationInput>;
}>;

export type PaginatedVisitsQuery = {
    paginatedVisits: {
        count?: number | null;
        visits?: Array<{
            id: string;
            hasAssistedVisitSupervisor: boolean;
            createdAt: any;
            updatedAt: any;
            status: EnumVisitStatus;
            title?: string | null;
            content?: string | null;
            visitAddress?: string | null;
            visitDate: any;
            priorityOrder?: number | null;
            salesTeamUnitId?: string | null;
            actionContent?: string | null;
            formattedProperties: Array<{
                propertyType?: { id: string; name: string } | null;
                properties?: Array<{ id: string; name: string }> | null;
            }>;
            assistedVisitSupervisor?: { id: string; name?: string | null } | null;
            salesTeamGroup: { id: string; name: string };
            salesTeamUnit?: {
                id: string;
                name: string;
                user?: { id: string; name?: string | null } | null;
                salesTeam?: { id: string; name: string; code?: string | null } | null;
            } | null;
            type?: { id: string; name: string; code?: EnumVisitTypeCode | null } | null;
            business?: {
                id: string;
                title?: string | null;
                content?: string | null;
                code?: string | null;
                budgetAmount?: number | null;
                saleAmount?: number | null;
                dealAmount?: number | null;
                expectedClosedDate?: any | null;
                closedDate?: any | null;
                status?: { id: string; buyingOpportunity?: string | null; name: string } | null;
                winningOpportunity?: { id: string; name: string } | null;
                buyingOpportunity?: { id: string; name: string } | null;
                mapBudgetProducts: Array<{
                    id: string;
                    qty: number;
                    budgetProduct: { id: string; name: string };
                }>;
                customer?: { id: string; name: string } | null;
            } | null;
            action?: { id: string; name: string } | null;
            customer?: {
                id: string;
                name: string;
                code?: string | null;
                address?: string | null;
                type?: { id: string; name: string } | null;
                city?: { id: string; name: string } | null;
                district?: { id: string; name: string } | null;
            } | null;
            salesTeam?: { id: string; name: string } | null;
            primaryUser?: {
                id: string;
                code?: string | null;
                name?: string | null;
                email?: string | null;
            } | null;
            timePeriods: Array<{ id: string; name: string }>;
            goals: Array<{ id: string; name: string }>;
            properties: Array<{ id: string; name: string; type: { id: string; name: string } }>;
            mapMentionProducts: Array<{ id: string; mentionProduct: { id: string; name: string } }>;
            mapRentFixedAssets: Array<{
                id: string;
                rentFixedAsset?: { id: string; name: string; code: string } | null;
            }>;
            createdUser?: {
                id: string;
                code?: string | null;
                name?: string | null;
                email?: string | null;
            } | null;
            updatedUser?: {
                id: string;
                code?: string | null;
                name?: string | null;
                email?: string | null;
            } | null;
            primaryContactPeople: Array<{ id: string; name: string; jobTitle?: string | null }>;
        }> | null;
    };
};

export type VisitQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type VisitQuery = {
    visit: {
        estimatedSurgeryCount?: string | null;
        surgeryCount?: string | null;
        id: string;
        hasAssistedVisitSupervisor: boolean;
        createdAt: any;
        updatedAt: any;
        status: EnumVisitStatus;
        title?: string | null;
        content?: string | null;
        visitAddress?: string | null;
        visitDate: any;
        priorityOrder?: number | null;
        salesTeamUnitId?: string | null;
        actionContent?: string | null;
        formattedProperties: Array<{
            propertyType?: { id: string; name: string } | null;
            properties?: Array<{ id: string; name: string }> | null;
        }>;
        assistedVisitSupervisor?: { id: string; name?: string | null } | null;
        salesTeamGroup: { id: string; name: string };
        salesTeamUnit?: {
            id: string;
            name: string;
            user?: { id: string; name?: string | null } | null;
            salesTeam?: { id: string; name: string; code?: string | null } | null;
        } | null;
        type?: { id: string; name: string; code?: EnumVisitTypeCode | null } | null;
        business?: {
            id: string;
            title?: string | null;
            content?: string | null;
            code?: string | null;
            budgetAmount?: number | null;
            saleAmount?: number | null;
            dealAmount?: number | null;
            expectedClosedDate?: any | null;
            closedDate?: any | null;
            status?: { id: string; buyingOpportunity?: string | null; name: string } | null;
            winningOpportunity?: { id: string; name: string } | null;
            buyingOpportunity?: { id: string; name: string } | null;
            mapBudgetProducts: Array<{
                id: string;
                qty: number;
                budgetProduct: { id: string; name: string };
            }>;
            customer?: { id: string; name: string } | null;
        } | null;
        action?: { id: string; name: string } | null;
        customer?: {
            id: string;
            name: string;
            code?: string | null;
            address?: string | null;
            type?: { id: string; name: string } | null;
            city?: { id: string; name: string } | null;
            district?: { id: string; name: string } | null;
        } | null;
        salesTeam?: { id: string; name: string } | null;
        primaryUser?: {
            id: string;
            code?: string | null;
            name?: string | null;
            email?: string | null;
        } | null;
        timePeriods: Array<{ id: string; name: string }>;
        goals: Array<{ id: string; name: string }>;
        properties: Array<{ id: string; name: string; type: { id: string; name: string } }>;
        mapMentionProducts: Array<{ id: string; mentionProduct: { id: string; name: string } }>;
        mapRentFixedAssets: Array<{
            id: string;
            rentFixedAsset?: { id: string; name: string; code: string } | null;
        }>;
        createdUser?: {
            id: string;
            code?: string | null;
            name?: string | null;
            email?: string | null;
        } | null;
        updatedUser?: {
            id: string;
            code?: string | null;
            name?: string | null;
            email?: string | null;
        } | null;
        primaryContactPeople: Array<{ id: string; name: string; jobTitle?: string | null }>;
        memos: Array<{
            id: string;
            title?: string | null;
            content?: string | null;
            createdAt: any;
            updatedAt: any;
            createdUser?: {
                id: string;
                code?: string | null;
                name?: string | null;
                email?: string | null;
            } | null;
            updatedUser?: {
                id: string;
                code?: string | null;
                name?: string | null;
                email?: string | null;
            } | null;
        }>;
    };
};

export type VisitsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<VisitFilterInput>;
}>;

export type VisitsQuery = {
    visits: Array<{
        id: string;
        hasAssistedVisitSupervisor: boolean;
        createdAt: any;
        updatedAt: any;
        status: EnumVisitStatus;
        title?: string | null;
        content?: string | null;
        visitAddress?: string | null;
        visitDate: any;
        priorityOrder?: number | null;
        salesTeamUnitId?: string | null;
        actionContent?: string | null;
        formattedProperties: Array<{
            propertyType?: { id: string; name: string } | null;
            properties?: Array<{ id: string; name: string }> | null;
        }>;
        assistedVisitSupervisor?: { id: string; name?: string | null } | null;
        salesTeamGroup: { id: string; name: string };
        salesTeamUnit?: {
            id: string;
            name: string;
            user?: { id: string; name?: string | null } | null;
            salesTeam?: { id: string; name: string; code?: string | null } | null;
        } | null;
        type?: { id: string; name: string; code?: EnumVisitTypeCode | null } | null;
        business?: {
            id: string;
            title?: string | null;
            content?: string | null;
            code?: string | null;
            budgetAmount?: number | null;
            saleAmount?: number | null;
            dealAmount?: number | null;
            expectedClosedDate?: any | null;
            closedDate?: any | null;
            status?: { id: string; buyingOpportunity?: string | null; name: string } | null;
            winningOpportunity?: { id: string; name: string } | null;
            buyingOpportunity?: { id: string; name: string } | null;
            mapBudgetProducts: Array<{
                id: string;
                qty: number;
                budgetProduct: { id: string; name: string };
            }>;
            customer?: { id: string; name: string } | null;
        } | null;
        action?: { id: string; name: string } | null;
        customer?: {
            id: string;
            name: string;
            code?: string | null;
            address?: string | null;
            type?: { id: string; name: string } | null;
            city?: { id: string; name: string } | null;
            district?: { id: string; name: string } | null;
        } | null;
        salesTeam?: { id: string; name: string } | null;
        primaryUser?: {
            id: string;
            code?: string | null;
            name?: string | null;
            email?: string | null;
        } | null;
        timePeriods: Array<{ id: string; name: string }>;
        goals: Array<{ id: string; name: string }>;
        properties: Array<{ id: string; name: string; type: { id: string; name: string } }>;
        mapMentionProducts: Array<{ id: string; mentionProduct: { id: string; name: string } }>;
        mapRentFixedAssets: Array<{
            id: string;
            rentFixedAsset?: { id: string; name: string; code: string } | null;
        }>;
        createdUser?: {
            id: string;
            code?: string | null;
            name?: string | null;
            email?: string | null;
        } | null;
        updatedUser?: {
            id: string;
            code?: string | null;
            name?: string | null;
            email?: string | null;
        } | null;
        primaryContactPeople: Array<{ id: string; name: string; jobTitle?: string | null }>;
    }>;
};

export type PaginatedVisitCheckInsQueryVariables = Exact<{
    pagination?: InputMaybe<PaginationInput>;
    filters?: InputMaybe<VisitCheckInFilterInput>;
}>;

export type PaginatedVisitCheckInsQuery = {
    paginatedVisitCheckIns: {
        count?: number | null;
        visitCheckIns?: Array<{
            id: string;
            lat: number;
            lng: number;
            createdAt: any;
            content?: string | null;
        }> | null;
    };
};

export type VisitOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type VisitOptionsQuery = {
    visitActions: Array<{ id: string; name: string }>;
    visitGoals: Array<{ id: string; name: string }>;
    visitPropertyTypes: Array<{ id: string; name: string }>;
    visitProperties: Array<{ id: string; name: string; type: { id: string; name: string } }>;
    visitTimePeriods: Array<{ id: string; name: string }>;
    visitTypes: Array<{ id: string; name: string; code?: EnumVisitTypeCode | null }>;
    businessProducts: Array<{ id: string; name: string }>;
};

export type VisitAnalysisQueryVariables = Exact<{
    visitDate1?: InputMaybe<Scalars['String']>;
    visitDate2?: InputMaybe<Scalars['String']>;
    primaryUserIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
    businessExpectedClosedDate1?: InputMaybe<Scalars['String']>;
    businessExpectedClosedDate2?: InputMaybe<Scalars['String']>;
}>;

export type VisitAnalysisQuery = {
    visitAnalysis: {
        visitStat: {
            overall: {
                days: number;
                count: number;
                averageCountPerDay?: number | null;
                customerCount: number;
                averageCustomerCountPerDay?: number | null;
                businessCount: number;
                totalBudgetAmount: number;
            };
            details: Array<{
                type: EnumVisitStatType;
                count: number;
                countPercent?: number | null;
                customerCount: number;
                customerCountPercentInResponsible?: number | null;
                businessCount: number;
                businessCountPercentInProgress?: number | null;
                totalBudgetAmount: number;
            }>;
        };
        weeklyVisitStat: {
            overall: { count: number };
            details: Array<{ type: EnumWeeklyVisitStatType; count: number }>;
        };
        visitedBusinessRank: Array<{
            visitCount: number;
            totalBudgetAmount: number;
            seq: number;
            rank: number;
            businessCount: number;
            customer: { name: string; id: string; code?: string | null };
        }>;
        unvisitedBusinessRank: Array<{
            visitCount: number;
            totalBudgetAmount: number;
            seq: number;
            rank: number;
            businessCount: number;
            customer: { name: string; id: string; code?: string | null };
        }>;
        mentionProductRank: Array<{
            visitCount: number;
            seq: number;
            rank: number;
            mentionProductId: string;
            mentionProduct: { id: string; name: string; brand?: string | null };
        }>;
    };
};

export type VisitGoalAnalysisQueryVariables = Exact<{
    filters?: InputMaybe<VisitGoalAnalysisMentionProductStatFilterInput>;
}>;

export type VisitGoalAnalysisQuery = {
    visitGoalAnalysis: {
        mentionProductStat: {
            details: Array<{
                count: number;
                countPercent?: number | null;
                visitGoalId: string;
                mentionProducts: Array<{
                    count: number;
                    mentionProductId: string;
                    mentionProduct: { name: string; id: string };
                }>;
                visitGoal: { name: string };
            }>;
        };
    };
};

export type VisitCustomerLevelTypeAnalysisQueryVariables = Exact<{
    filters: VisitCustomerLevelTypeAnalysisCustomerStatFilterInput;
    mentionProductStatFilters2: VisitCustomerLevelTypeAnalysisMentionProductStatFilterInput;
    visitGoalStatFilters2?: InputMaybe<VisitCustomerLevelTypeAnalysisVisitGoalStatFilterInput>;
}>;

export type VisitCustomerLevelTypeAnalysisQuery = {
    visitCustomerLevelTypeAnalysis: {
        customerStat: {
            details: Array<{
                averageMentionProductCountPerCustomer?: number | null;
                averageVisitCountPerCustomer?: number | null;
                customerCount: string;
                customerCountPercent?: number | null;
                customerLevelId: string;
                mentionProductCount: number;
                visitCount: number;
                visitedCustomerCount: number;
                visitedCustomerOverlapPercent?: number | null;
                customerLevel: { name: string; viewOrder?: number | null };
            }>;
            sortByFirstFieldType?: { name: string } | null;
            sortedDetailsByFirstFieldType: Array<{
                averageMentionProductCountPerCustomer?: number | null;
                averageVisitCountPerCustomer?: number | null;
                customerCount: string;
                customerCountPercent?: number | null;
                customerLevelId: string;
                mentionProductCount: number;
                visitCount: number;
                visitedCustomerCount: number;
                visitedCustomerOverlapPercent?: number | null;
                customerLevel: { name: string; viewOrder?: number | null };
            }>;
            sortedDetailsBySecondFieldType: Array<{
                averageMentionProductCountPerCustomer?: number | null;
                averageVisitCountPerCustomer?: number | null;
                customerCount: string;
                customerCountPercent?: number | null;
                customerLevelId: string;
                mentionProductCount: number;
                visitCount: number;
                visitedCustomerCount: number;
                visitedCustomerOverlapPercent?: number | null;
                customerLevel: { name: string; viewOrder?: number | null };
            }>;
        };
        mentionProductStat: {
            details: Array<{
                mentionCount: number;
                mentionCountPercent?: number | null;
                mentionProductId: string;
                mentionProduct: { name: string };
            }>;
        };
        visitGoalStat: {
            details: Array<{
                visitCount: number;
                visitCountPercent?: number | null;
                visitGoalId: string;
                mentionProducts: Array<{
                    mentionProductId: string;
                    visitCount: number;
                    mentionProduct: { name: string; id: string };
                }>;
                visitGoal: { name: string };
            }>;
        };
    };
};

export type RentProductsQueryVariables = Exact<{ [key: string]: never }>;

export type RentProductsQuery = { rentProducts: Array<{ id: string; name: string }> };

export type CustomerStatDetailFragment = {
    averageMentionProductCountPerCustomer?: number | null;
    averageVisitCountPerCustomer?: number | null;
    customerCount: string;
    customerCountPercent?: number | null;
    customerLevelId: string;
    mentionProductCount: number;
    visitCount: number;
    visitedCustomerCount: number;
    visitedCustomerOverlapPercent?: number | null;
    customerLevel: { name: string; viewOrder?: number | null };
};

export type VisitCustomerAnalysisQueryVariables = Exact<{
    filters?: InputMaybe<VisitCustomerAnalysisVisitStatFilterInput>;
}>;

export type VisitCustomerAnalysisQuery = {
    visitCustomerAnalysis: {
        visitStat: {
            details: Array<{
                customerId: number;
                visitCount: number;
                customer: {
                    id: string;
                    name: string;
                    type?: { name: string } | null;
                    levels: Array<{
                        name: string;
                        type: { name: string; id: string; code?: EnumCustomerLevelTypeCode | null };
                    }>;
                };
                salesTeamUnits: Array<{
                    salesTeamUnitId: number;
                    visitCount: number;
                    salesTeamUnit: {
                        id: string;
                        name: string;
                        user?: { id: string; name?: string | null } | null;
                    };
                }>;
                mentionProducts: Array<{
                    mentionProductId: number;
                    visitCount: number;
                    mentionProduct: { id: string; name: string };
                }>;
            }>;
        };
    };
};

export const ChildrenFragmentDoc = gql`
    fragment children on SalesTeam {
        id
        name
        code
        users {
            id
            name
            code
        }
    }
`;
export const TeamsFragmentDoc = gql`
    fragment teams on SalesTeam {
        id
        name
        code
        children {
            ...children
            children {
                ...children
                children {
                    ...children
                    children {
                        ...children
                        children {
                            ...children
                            children {
                                ...children
                            }
                        }
                    }
                }
            }
        }
        users {
            id
            name
            code
        }
    }
    ${ChildrenFragmentDoc}
`;
export const SalesTeamUnitFragmentDoc = gql`
    fragment SalesTeamUnit on SalesTeamUnit {
        id
        name
        user {
            id
            name
        }
        salesTeam {
            id
            name
            code
        }
    }
`;
export const SalesTeamGroupFragmentDoc = gql`
    fragment SalesTeamGroup on SalesTeamGroup {
        id
        name
        code
    }
`;
export const BusinessProductFragmentFragmentDoc = gql`
    fragment BusinessProductFragment on BusinessProduct {
        id
        name
        isActive
        brand
        salesTeamGroup {
            id
        }
    }
`;
export const BusinessFragmentFragmentDoc = gql`
    fragment businessFragment on Business {
        id
        content
        title
        eyeQuotationOrderCode
        code
        typeId
        budgetAmount
        saleAmount
        dealAmount
        expectedClosedDate
        closedDate
        createdAt
        orderCode
        updatedAt
        dealer {
            id
            name
            code
            address
        }
        losingReasons {
            id
            name
        }
        properties {
            id
            name
            typeId
            type {
                name
                id
                code
            }
        }
        primaryContactPeople {
            id
            name
        }
        status {
            id
            name
            buyingOpportunity
        }
        primaryUser {
            id
            name
            code
            salesTeams {
                id
                name
            }
        }
        mapBudgetProducts {
            id
            qty
            budgetProduct {
                ...BusinessProductFragment
            }
        }
        customer {
            id
            name
            code
            phone
            address
            contactPersonName
            contactPersonPhone
            province {
                id
                name
                cities {
                    id
                    name
                    districts {
                        id
                        name
                    }
                }
            }
            city {
                id
                name
                districts {
                    id
                    name
                }
            }
            district {
                id
                name
            }
            eyeServiceOrders {
                id
                code
            }
        }
        createdUser {
            id
            name
            code
        }
        updatedUser {
            id
            name
            code
        }
        users {
            id
            userId
            user {
                id
                name
            }
            salesTeam {
                name
                id
            }
            salesTeamId
        }
        competitors {
            id
            name
        }
        winningOpportunity {
            id
            name
            code
        }
        buyingOpportunity {
            id
            name
        }
        mapDealProducts {
            id
            qty
            dealProduct {
                ...BusinessProductFragment
            }
        }
        losingReason
        losingImprovementPlan
        salesMethodId
        dealerId
        salesTeamUnitId
        salesTeamUnit {
            id
            name
            user {
                id
                name
            }
            salesTeam {
                id
                name
                code
            }
        }
    }
    ${BusinessProductFragmentFragmentDoc}
`;
export const BusinessAnalysisBusinessStatFragmentFragmentDoc = gql`
    fragment BusinessAnalysisBusinessStatFragment on BusinessAnalysisBusinessStat {
        details {
            type
            amount
            amountPercent
            count
            countPercent
        }
        overall {
            amount
            count
        }
    }
`;
export const ProductRankFragmentFragmentDoc = gql`
    fragment ProductRankFragment on BusinessAnalysisProductRank {
        rank
        businessProductId
        qty
        businessProduct {
            id
            name
        }
    }
`;
export const ContactPeopleInfoFragmentDoc = gql`
    fragment contactPeopleInfo on ContactPerson {
        id
        name
        jobTitle
        mobile
        email
        address
        gender
        doctorCode
        memo
        phone
        fax
        createdAt
        updatedAt
        code
        salesTeamUnits {
            id
            name
            user {
                id
                name
            }
            salesTeam {
                id
                name
                code
            }
        }
        departmentId
        department {
            id
            name
        }
        salesTeamGroup {
            name
            id
            code
        }
        customers {
            name
            id
            code
        }
        primaryUsers {
            id
            name
            code
        }
        createdUser {
            id
            name
        }
        updatedUser {
            id
            name
        }
        citizenCode
        skill
    }
`;
export const CustomerFragmentDoc = gql`
    fragment Customer on Customer {
        id
        name
        isActive
        type {
            id
            name
        }
        code
        address
        area {
            id
            name
        }
        primaryUsers {
            id
            userId
            salesTeamId
            user {
                id
                name
            }
        }
        salesTeamGroup {
            id
            name
            region {
                id
                code
                name
            }
            teams {
                id
                name
            }
        }
        businessCode
        medicalCode
        memo
        phone
        email
        fax
        mobile
        contactPersonPhone
        yonyouCode
        do1Code
        navCode
        creditQuota
        creditPeriod {
            id
            name
        }
        shippingAddress
        contactPersonName
        bankAccountCode
        bankAccountName
        legalPersonName
        billingUnitName
        hasEffectiveGmpCertificate
        isGmp
        lastGmpBpmInstanceId
        lastGmpBpmStatus
        district {
            id
            name
        }
        province {
            id
            name
        }
        createdAt
        updatedAt
        createdUser {
            id
            name
            createdAt
            updatedAt
        }
        updatedUser {
            id
            name
            createdAt
            updatedAt
        }
        contactPeople {
            id
            name
            phone
            mobile
            fax
            gender
            doctorCode
            jobTitle
            dept
        }
        attachments {
            id
            name
            s3Key
            memo
        }
        eyeServiceOrders {
            id
            code
        }
        province {
            id
            name
            cities {
                id
                name
                districts {
                    id
                    name
                }
            }
        }
        city {
            id
            name
            districts {
                id
                name
            }
        }
        cityId
        districtId
        categoryId
        areaId
        salesTeamUnits {
            id
            name
            salesTeam {
                id
                name
                code
            }
            user {
                id
                name
                code
            }
        }
        levels {
            id
            name
            typeId
            type {
                id
                name
                code
            }
        }
    }
`;
export const EyeFixedAssetsFragmentDoc = gql`
    fragment eyeFixedAssets on EyeFixedAsset {
        id
        name
        status
        dept {
            id
            name
        }
        memo
        currentLocation
        types {
            id
            name
            code
        }
        eyeFixedAssetItems {
            materialId
            materialCode
        }
        eyeFixedAssetRentalRecords {
            id
            date1
            date2
        }
    }
`;
export const RentalRecordsInfoFragmentDoc = gql`
    fragment rentalRecordsInfo on EyeFixedAssetRentalRecord {
        id
        status
        region {
            id
            name
            salesTeamGroups {
                id
                name
            }
        }
        installDate
        date1
        date2
        dismantleDate
        remark
        eyeFixedAssets {
            ...eyeFixedAssets
        }
    }
    ${EyeFixedAssetsFragmentDoc}
`;
export const EyeFixedAssetTypeFragmentDoc = gql`
    fragment EyeFixedAssetType on EyeFixedAssetType {
        id
        name
        isActive
        code
    }
`;
export const EyeFixedAssetRentalGoalFragmentDoc = gql`
    fragment EyeFixedAssetRentalGoal on EyeFixedAssetRentalGoal {
        id
        name
        isActive
        code
    }
`;
export const EyeServiceOrderWorkDiarysFragmentDoc = gql`
    fragment EyeServiceOrderWorkDiarys on EyeServiceOrderWorkDiary {
        id
        hours
        eyeServiceOrder {
            id
            approvalStatus
            status
            code
            customer {
                code
                id
                name
            }
            contactPerson
            address
            contactPhone
            assigneeDept {
                id
                code
                name
            }
            eyeServiceOrderType {
                name
            }
        }
        date
        estimatedDate
        estimatedHours
        remark
        status
        users {
            id
            code
            name
        }
        eyeServiceOrderWorkDiaryTypeId
        eyeServiceOrderWorkDiaryType {
            id
            name
        }
    }
`;
export const EyeWarrantyContractsFragmentDoc = gql`
    fragment EyeWarrantyContracts on EyeWarrantyContract {
        id
        orderCode
        approvalCode
        code
        customer {
            id
            name
            code
        }
        warrantyMonths
        eyeWarrantyPeriodType {
            id
            code
            name
        }
        warrantyDate1
        warrantyDate2
        status
    }
`;
export const NavOrderFragmentDoc = gql`
    fragment NavOrder on NavOrder {
        id
        code
        orderDate
        postingDate
        postingDescription
        reasonCode
        amount
        approvalCode
        shipInvoicedAmount
        businesses {
            id
            code
        }
        sellToCustomer {
            id
            code
            name
            address
        }
        dept {
            id
            code
            name
        }
        billToCustomer {
            id
            name
            code
            address
        }
        user {
            id
            code
            name
        }
        navOrderDetails {
            id
            amount
            materialCode
            qty
        }
    }
`;
export const NavSalesInvoiceFragmentDoc = gql`
    fragment NavSalesInvoice on NavSalesInvoice {
        id
        approvalCode
        code
        reasonCode
        postingDescription
        postingDate
        orderDate
        orderCode
        amount
        type
        billToCustomer {
            id
            address
            code
            name
        }
        dept {
            id
            code
            name
        }
        user {
            id
            code
            name
        }
        sellToCustomer {
            address
            code
            id
            name
        }
        navSalesInvoiceDetails {
            qty
            id
            amount
            materialCode
        }
    }
`;
export const NavSalesAnalysisOrderStatFragmentDoc = gql`
    fragment NavSalesAnalysisOrderStat on NavSalesAnalysisOrderStat {
        overall {
            amountGoalAchievementRate
            amountGoal
            amount
            periods {
                amount
                type
            }
        }
    }
`;
export const NavSalesAnalysisSalesStatFragmentDoc = gql`
    fragment NavSalesAnalysisSalesStat on NavSalesAnalysisSalesStat {
        overall {
            amount
            amountGoal
            amountGoalAchievementRate
            periods {
                type
                amount
            }
        }
        details {
            type
            amount
            amountGoal
            amountGoalAchievementRate
            periods {
                type
                amount
            }
        }
    }
`;
export const NavSalesAnalysisProductRankFragmentDoc = gql`
    fragment NavSalesAnalysisProductRank on NavSalesAnalysisProductRank {
        seq
        rank
        qty
        navProductId
        amount
        navProduct {
            id
            code
            brand
        }
    }
`;
export const NavSalesAnalysisCustomerRankFragmentDoc = gql`
    fragment NavSalesAnalysisCustomerRank on NavSalesAnalysisCustomerRank {
        seq
        sellToCustomerNavCode
        sellToCustomerName
        rank
        qty
        amount
        sellToCustomer {
            name
            code
            id
        }
    }
`;
export const NavSalesAnalysisYearlySalesStatByYearFragmentDoc = gql`
    fragment NavSalesAnalysisYearlySalesStatByYear on NavSalesAnalysisYearlySalesStatByYear {
        months {
            month
            amount
        }
        year
        amount
    }
`;
export const NavSalesAnalysisYearlySalesStatByUserFragmentDoc = gql`
    fragment NavSalesAnalysisYearlySalesStatByUser on NavSalesAnalysisYearlySalesStatByUser {
        user {
            id
            code
            name
            salesTeams {
                id
                name
            }
        }
        userCode
        months {
            month
            amount
        }
        amountGoalAchievementRate
        amountGoal
        amount
    }
`;
export const NavSalesAnalysisYearlySalesStatBySalesTeamFragmentDoc = gql`
    fragment NavSalesAnalysisYearlySalesStatBySalesTeam on NavSalesAnalysisYearlySalesStatBySalesTeam {
        salesTeam {
            id
            name
        }
        salesTeamId
        months {
            month
            amount
        }
        amountGoalAchievementRate
        amountGoal
        amount
    }
`;
export const NavSalesAnalysisYearlyOrderStatByYearFragmentDoc = gql`
    fragment NavSalesAnalysisYearlyOrderStatByYear on NavSalesAnalysisYearlyOrderStatByYear {
        months {
            month
            amount
        }
        year
        amount
    }
`;
export const NavSalesAnalysisYearlyOrderStatByUserFragmentDoc = gql`
    fragment NavSalesAnalysisYearlyOrderStatByUser on NavSalesAnalysisYearlyOrderStatByUser {
        user {
            id
            code
            name
            salesTeams {
                id
                name
            }
        }
        userCode
        months {
            month
            amount
        }
        amount
    }
`;
export const NavSalesAnalysisYearlyOrderStatBySalesTeamFragmentDoc = gql`
    fragment NavSalesAnalysisYearlyOrderStatBySalesTeam on NavSalesAnalysisYearlyOrderStatBySalesTeam {
        salesTeam {
            id
            name
        }
        salesTeamId
        months {
            month
            amount
        }
        amount
    }
`;
export const EyeProductsInfoFragmentDoc = gql`
    fragment eyeProductsInfo on EyeProduct {
        id
        eyeProductType {
            id
            name
        }
        region {
            id
            name
        }
        name
        model
        brand
        currency {
            id
            name
        }
        salePrice
        description
        date1
        date2
        status
        eyeProductItems {
            id
            isOptional
            qty
            description
            material {
                id
                code
                name
                model
                spec
                unit
            }
        }
        eyePromotions {
            id
        }
    }
`;
export const EyeQuotationOrderTypeFragmentDoc = gql`
    fragment EyeQuotationOrderType on EyeQuotationOrderType {
        id
        id
        name
        code
    }
`;
export const EyeQuotationOrderBusinessFragmentDoc = gql`
    fragment EyeQuotationOrderBusiness on EyeQuotationOrderBusiness {
        business {
            id
            code
            title
            users {
                id
                salesTeam {
                    id
                    name
                }
                user {
                    id
                    name
                }
            }
        }
    }
`;
export const Quotation_MaterialFragmentDoc = gql`
    fragment Quotation_Material on Material {
        id
        code
        model
        name
        spec
        unit
        companyId
        salePrice
        saleCurrencyId
        saleCurrency {
            id
            name
        }
        taxRate
        company {
            id
            name
            code
        }
    }
`;
export const Quotation_ProductItemFragmentDoc = gql`
    fragment Quotation_ProductItem on EyeProductItem {
        id
        eyeProductId
        eyeProductItemTypeId
        qty
        isOptional
        createdAt
        material {
            ...Quotation_Material
        }
    }
    ${Quotation_MaterialFragmentDoc}
`;
export const Quotation_PromotionAddonProductItemFragmentDoc = gql`
    fragment Quotation_PromotionAddonProductItem on EyePromotionAddonProductItem {
        id
        qty
        isOptional
        eyePromotionId
        eyeProductItemTypeId
        material {
            ...Quotation_Material
        }
    }
    ${Quotation_MaterialFragmentDoc}
`;
export const Quotation_ProductFragmentDoc = gql`
    fragment Quotation_Product on EyeProduct {
        id
        name
        salePrice
        brand
        status
        description
        currencyId
        currency {
            id
            name
        }
        model
        regionId
        createdAt
        updatedAt
        eyeProductTypeId
        eyeProductItems {
            ...Quotation_ProductItem
        }
        region {
            id
            name
        }
        eyeProductType {
            id
            regionId
            name
            createdAt
            updatedAt
        }
        eyePromotions {
            id
            name
            isAddon
            discountRate
            discountAmount
            createdAt
            date1
            regionId
            updatedAt
            currencyId
            eyePromotionAddonProductItems {
                ...Quotation_PromotionAddonProductItem
            }
        }
    }
    ${Quotation_ProductItemFragmentDoc}
    ${Quotation_PromotionAddonProductItemFragmentDoc}
`;
export const EyeQuotationOrderProductFragmentDoc = gql`
    fragment EyeQuotationOrderProduct on EyeQuotationOrderProduct {
        id
        name
        qty
        model
        brand
        unitPrice
        unitPriceVat
        eyeProduct {
            ...Quotation_Product
        }
        eyeQuotationOrderProductItems {
            id
            qty
            discountedSellingPrice
            material {
                ...Quotation_Material
            }
        }
    }
    ${Quotation_ProductFragmentDoc}
    ${Quotation_MaterialFragmentDoc}
`;
export const EyeQuotationOrderProductItemFragmentDoc = gql`
    fragment EyeQuotationOrderProductItem on EyeQuotationOrderProductItem {
        id
        materialId
        materialCode
        materialModel
        materialSpec
        materialUnit
        materialName
        materialSellingCurrencyId
        qty
        unitPrice
        unitPriceVat
        eyeQuotationOrderProductId
        eyeQuotationOrderPromotionId
        discountedSellingPrice
    }
`;
export const Quotation_PromotionFragmentDoc = gql`
    fragment Quotation_Promotion on EyePromotion {
        id
        name
        regionId
        currencyId
        date1
        date2
        isAddon
        description
        discountRate
        discountAmount
        region {
            id
            name
        }
        eyePromotionAddonProductItems {
            ...Quotation_PromotionAddonProductItem
        }
    }
    ${Quotation_PromotionAddonProductItemFragmentDoc}
`;
export const EyeQuotationOrderPromotionFragmentDoc = gql`
    fragment EyeQuotationOrderPromotion on EyeQuotationOrderPromotion {
        id
        name
        eyePromotionId
        discountRate
        discountAmount
        currencyId
        eyePromotion {
            ...Quotation_Promotion
        }
        eyeQuotationOrderProductItems {
            id
            qty
            material {
                ...Quotation_Material
            }
        }
    }
    ${Quotation_PromotionFragmentDoc}
    ${Quotation_MaterialFragmentDoc}
`;
export const EyeQuotationOrderWarrantyItemFragmentDoc = gql`
    fragment EyeQuotationOrderWarrantyItem on EyeQuotationOrderWarrantyItem {
        id
        materialId
        materialCode
        materialModel
        materialUnit
        materialName
        sn
        udi
        qty
        memo
    }
`;
export const EyeQuotationOrderFragmentDoc = gql`
    fragment EyeQuotationOrder on EyeQuotationOrder {
        id
        code
        bpmUrl
        officialSealStatus
        officialSealBpmUrl
        localStandardAmount
        eyeQuotationOrderTypeId
        eyeQuotationOrderType {
            ...EyeQuotationOrderType
        }
        onlyMaterials
        biddingPrice
        userId
        customerId
        invoicingCustomerId
        eyeServiceOrderId
        bpmInstanceId
        exchangeRate
        warrantyMonths
        warrantyPeriodTypeId
        expectDeliveryDate
        expectPaymentDate
        warrantyBuyType
        orderCode
        realAmount
        untaxedAmount
        contactPerson
        contactPhone
        regionId
        status
        address
        currencyId
        standardAmount
        discountRate
        discountAmount
        extraDiscountAmount
        realAmount
        realDiscountAmount
        realDiscountRate
        taxRate
        commissionAmount
        discountRateWithoutCommission
        status
        description
        createdAt
        status
        createdAt
        localCurrency {
            id
            name
        }
        province {
            id
            name
        }
        city {
            id
            name
        }
        district {
            id
            name
        }
        eyeQuotationOrderBusinesses {
            ...EyeQuotationOrderBusiness
        }
        creditPeriod {
            id
            name
        }
        region {
            id
            name
            localCurrency {
                id
                name
            }
        }
        eyeServiceOrder {
            id
            code
        }
        customer {
            id
            code
            name
        }
        currency {
            id
            name
            code
        }
        dept {
            id
            code
            name
        }
        invoicingCustomer {
            id
            code
            name
        }
        createdUser {
            id
            code
            name
        }
        user {
            id
            code
            name
        }
        user {
            name
        }
        eyeWarrantyPeriodType {
            id
            code
            name
        }
        eyeQuotationOrderProducts {
            ...EyeQuotationOrderProduct
        }
        eyeQuotationOrderProductItems {
            ...EyeQuotationOrderProductItem
        }
        eyeQuotationOrderPromotions {
            ...EyeQuotationOrderPromotion
        }
        eyeQuotationOrderWarrantyItems {
            ...EyeQuotationOrderWarrantyItem
        }
    }
    ${EyeQuotationOrderTypeFragmentDoc}
    ${EyeQuotationOrderBusinessFragmentDoc}
    ${EyeQuotationOrderProductFragmentDoc}
    ${EyeQuotationOrderProductItemFragmentDoc}
    ${EyeQuotationOrderPromotionFragmentDoc}
    ${EyeQuotationOrderWarrantyItemFragmentDoc}
`;
export const EyeServiceOrderWorkDiaryFragmentDoc = gql`
    fragment EyeServiceOrderWorkDiary on EyeServiceOrderWorkDiary {
        id
        hours
        date
        users {
            name
        }
        status
        remark
    }
`;
export const EyeServiceOrderFragmentDoc = gql`
    fragment EyeServiceOrder on EyeServiceOrder {
        bpmUrl
        extensionCount
        extension {
            bpmUrl
            approvalStatus
        }
        user {
            id
            code
            name
        }
        updatedUser {
            id
            code
            name
        }
        eyeFixedAssetRentalGoal {
            id
            name
        }
        id
        regionId
        status
        updatedAt
        priority
        approvalStatus
        eyeFixedAssetRentalObject {
            id
            name
        }
        province {
            id
            name
            code
        }
        memo
        districtId
        district {
            id
            code
            name
        }
        address
        approverUser {
            id
            name
            code
        }
        estimatedDate
        eyeServiceOrderType {
            id
            name
        }
        description
        assigneeDept {
            id
            name
            company {
                id
                name
                code
            }
        }
        customer {
            id
            name
            code
        }
        createdUser {
            id
            code
            name
        }
        createdAt
        contactPerson
        contactPhone
        company {
            id
            name
            code
        }
        code
        city {
            id
            name
        }
        assigneeUser {
            id
            code
            name
            dept {
                id
                name
            }
        }
        business {
            id
            code
            title
            status {
                id
                name
            }
            customer {
                id
                name
            }
            mapBudgetProducts {
                id
                budgetProduct {
                    id
                    name
                }
            }
            expectedClosedDate
        }
        costCenter {
            id
            code
            name
        }
        eyeWarrantyContract {
            id
            code
        }
        region {
            id
            name
        }
        eyeFixedAssetRentalRecords {
            date2
            date1
            id
            status
            eyeFixedAssets {
                id
                name
                status
                memo
                currentLocation
                eyeFixedAssetItems {
                    id
                    materialCode
                    materialName
                }
            }
            remark
        }
        eyeServiceOrderWorkDiaries {
            ...EyeServiceOrderWorkDiary
        }
    }
    ${EyeServiceOrderWorkDiaryFragmentDoc}
`;
export const EyeServiceOrderTypeFragmentDoc = gql`
    fragment EyeServiceOrderType on EyeServiceOrderType {
        name
        id
        code
    }
`;
export const CostCenterFragmentDoc = gql`
    fragment CostCenter on CostCenter {
        id
        isActive
        name
        code
    }
`;
export const ServiceOrderAssetRentalRecordFragmentDoc = gql`
    fragment serviceOrderAssetRentalRecord on EyeFixedAssetRentalRecord {
        id
        date1
        date2
        eyeServiceOrder {
            customer {
                id
                code
                name
            }
        }
    }
`;
export const ChildrensFragmentDoc = gql`
    fragment childrens on Department {
        id
        name
        code
        company {
            id
            name
        }
    }
`;
export const DepartmentFragmentDoc = gql`
    fragment Department on Department {
        id
        companyId
        name
        code
        createdAt
        updatedAt
        regionId
        setOfBook
        isActive
        company {
            id
            code
            name
            createdAt
            updatedAt
            regionId
        }
        users {
            id
            code
            name
        }
        children {
            ...childrens
            children {
                ...childrens
                children {
                    ...childrens
                    children {
                        ...childrens
                        children {
                            ...childrens
                            children {
                                ...childrens
                            }
                        }
                    }
                }
            }
        }
    }
    ${ChildrensFragmentDoc}
`;
export const VisitTypeBasicInfoFragmentDoc = gql`
    fragment VisitTypeBasicInfo on VisitType {
        id
        name
        code
    }
`;
export const BusinessBasicInfoFragmentDoc = gql`
    fragment BusinessBasicInfo on Business {
        id
        title
        content
        code
        budgetAmount
        saleAmount
        dealAmount
        expectedClosedDate
        closedDate
        status {
            id
            buyingOpportunity
            name
        }
        winningOpportunity {
            id
            name
        }
        buyingOpportunity {
            id
            name
        }
        mapBudgetProducts {
            id
            budgetProduct {
                id
                name
            }
            qty
        }
        customer {
            id
            name
        }
    }
`;
export const VisitActionBasicInfoFragmentDoc = gql`
    fragment VisitActionBasicInfo on VisitAction {
        id
        name
    }
`;
export const CustomerBasicInfoFragmentDoc = gql`
    fragment CustomerBasicInfo on Customer {
        id
        name
        code
        type {
            id
            name
        }
        city {
            id
            name
        }
        district {
            id
            name
        }
        address
    }
`;
export const StaffBasicInfoFragmentDoc = gql`
    fragment StaffBasicInfo on User {
        id
        code
        name
        email
    }
`;
export const VisitTimePeriodBasicInfoFragmentDoc = gql`
    fragment VisitTimePeriodBasicInfo on VisitTimePeriod {
        id
        name
    }
`;
export const VisitGoalBasicInfoFragmentDoc = gql`
    fragment VisitGoalBasicInfo on VisitGoal {
        id
        name
    }
`;
export const VisitPropertyBasicInfoFragmentDoc = gql`
    fragment VisitPropertyBasicInfo on VisitProperty {
        id
        name
    }
`;
export const VisitPropertyTypeBasicInfoFragmentDoc = gql`
    fragment VisitPropertyTypeBasicInfo on VisitPropertyType {
        id
        name
    }
`;
export const VisitBasicInfoFragmentDoc = gql`
    fragment VisitBasicInfo on Visit {
        id
        hasAssistedVisitSupervisor
        assistedVisitSupervisor {
            id
            name
        }
        createdAt
        updatedAt
        salesTeamGroup {
            id
            name
        }
        status
        title
        content
        visitAddress
        visitDate
        priorityOrder
        salesTeamUnitId
        salesTeamUnit {
            id
            name
            user {
                id
                name
            }
            salesTeam {
                id
                name
                code
            }
        }
        type {
            ...VisitTypeBasicInfo
        }
        business {
            ...BusinessBasicInfo
        }
        action {
            ...VisitActionBasicInfo
        }
        actionContent
        customer {
            ...CustomerBasicInfo
        }
        salesTeam {
            id
            name
        }
        primaryUser {
            ...StaffBasicInfo
        }
        timePeriods {
            ...VisitTimePeriodBasicInfo
        }
        goals {
            ...VisitGoalBasicInfo
        }
        properties {
            ...VisitPropertyBasicInfo
            type {
                ...VisitPropertyTypeBasicInfo
            }
        }
        mapMentionProducts {
            id
            mentionProduct {
                id
                name
            }
        }
        mapRentFixedAssets {
            id
            rentFixedAsset {
                id
                name
                code
            }
        }
        createdUser {
            ...StaffBasicInfo
        }
        updatedUser {
            ...StaffBasicInfo
        }
        primaryContactPeople {
            id
            name
            jobTitle
        }
    }
    ${VisitTypeBasicInfoFragmentDoc}
    ${BusinessBasicInfoFragmentDoc}
    ${VisitActionBasicInfoFragmentDoc}
    ${CustomerBasicInfoFragmentDoc}
    ${StaffBasicInfoFragmentDoc}
    ${VisitTimePeriodBasicInfoFragmentDoc}
    ${VisitGoalBasicInfoFragmentDoc}
    ${VisitPropertyBasicInfoFragmentDoc}
    ${VisitPropertyTypeBasicInfoFragmentDoc}
`;
export const VisitMemoBasicInfoFragmentDoc = gql`
    fragment VisitMemoBasicInfo on VisitMemo {
        id
        title
        content
        createdUser {
            ...StaffBasicInfo
        }
        updatedUser {
            ...StaffBasicInfo
        }
        createdAt
        updatedAt
    }
    ${StaffBasicInfoFragmentDoc}
`;
export const VisitDetailDataFragmentDoc = gql`
    fragment VisitDetailData on Visit {
        memos {
            ...VisitMemoBasicInfo
        }
    }
    ${VisitMemoBasicInfoFragmentDoc}
`;
export const VisitProductBasicInfoFragmentDoc = gql`
    fragment VisitProductBasicInfo on BusinessProduct {
        id
        name
    }
`;
export const VisitCheckInFragmentDoc = gql`
    fragment VisitCheckIn on VisitCheckIn {
        id
        lat
        lng
        createdAt
        content
    }
`;
export const WeeklyVisitStatFragmentDoc = gql`
    fragment weeklyVisitStat on VisitAnalysisWeeklyVisitStat {
        overall {
            count
        }
        details {
            type
            count
        }
    }
`;
export const VisitStatFragmentDoc = gql`
    fragment visitStat on VisitAnalysisVisitStat {
        overall {
            days
            count
            averageCountPerDay
            customerCount
            averageCustomerCountPerDay
            businessCount
            totalBudgetAmount
        }
        details {
            type
            count
            countPercent
            customerCount
            customerCountPercentInResponsible
            businessCount
            businessCountPercentInProgress
            totalBudgetAmount
        }
    }
`;
export const VisitAnalysisBusinessRankFragmentDoc = gql`
    fragment visitAnalysisBusinessRank on VisitAnalysisBusinessRank {
        visitCount
        totalBudgetAmount
        seq
        rank
        customer {
            name
            id
            code
        }
        businessCount
    }
`;
export const VisitAnalysisMentionProductRankFragmentDoc = gql`
    fragment VisitAnalysisMentionProductRank on VisitAnalysisMentionProductRank {
        visitCount
        seq
        rank
        mentionProductId
        mentionProduct {
            id
            name
            brand
        }
    }
`;
export const CustomerStatDetailFragmentDoc = gql`
    fragment customerStatDetail on VisitCustomerLevelTypeAnalysisCustomerStat_Detail {
        averageMentionProductCountPerCustomer
        averageVisitCountPerCustomer
        customerCount
        customerCountPercent
        customerLevelId
        mentionProductCount
        visitCount
        visitedCustomerCount
        visitedCustomerOverlapPercent
        customerLevel {
            name
            viewOrder
        }
    }
`;
export const CitiesDocument = gql`
    query cities($pagination: PaginationInput, $filters: CityFilterInput) {
        cities(pagination: $pagination, filters: $filters) {
            id
            name
        }
    }
`;

/**
 * __useCitiesQuery__
 *
 * To run a query within a React component, call `useCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCitiesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<CitiesQuery, CitiesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, options);
}
export function useCitiesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CitiesQuery, CitiesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CitiesQuery, CitiesQueryVariables>(
        CitiesDocument,
        options
    );
}
export type CitiesQueryHookResult = ReturnType<typeof useCitiesQuery>;
export type CitiesLazyQueryHookResult = ReturnType<typeof useCitiesLazyQuery>;
export type CitiesQueryResult = ApolloReactCommon.QueryResult<CitiesQuery, CitiesQueryVariables>;
export const CityDocument = gql`
    query city($id: ID!) {
        city(id: $id) {
            id
            name
        }
    }
`;

/**
 * __useCityQuery__
 *
 * To run a query within a React component, call `useCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCityQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CityQuery, CityQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CityQuery, CityQueryVariables>(CityDocument, options);
}
export function useCityLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CityQuery, CityQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CityQuery, CityQueryVariables>(CityDocument, options);
}
export type CityQueryHookResult = ReturnType<typeof useCityQuery>;
export type CityLazyQueryHookResult = ReturnType<typeof useCityLazyQuery>;
export type CityQueryResult = ApolloReactCommon.QueryResult<CityQuery, CityQueryVariables>;
export const DistrictsDocument = gql`
    query districts($pagination: PaginationInput, $filters: DistrictFilterInput) {
        districts(pagination: $pagination, filters: $filters) {
            id
            name
        }
    }
`;

/**
 * __useDistrictsQuery__
 *
 * To run a query within a React component, call `useDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDistrictsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<DistrictsQuery, DistrictsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<DistrictsQuery, DistrictsQueryVariables>(
        DistrictsDocument,
        options
    );
}
export function useDistrictsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DistrictsQuery, DistrictsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<DistrictsQuery, DistrictsQueryVariables>(
        DistrictsDocument,
        options
    );
}
export type DistrictsQueryHookResult = ReturnType<typeof useDistrictsQuery>;
export type DistrictsLazyQueryHookResult = ReturnType<typeof useDistrictsLazyQuery>;
export type DistrictsQueryResult = ApolloReactCommon.QueryResult<
    DistrictsQuery,
    DistrictsQueryVariables
>;
export const ProvincesDocument = gql`
    query provinces($pagination: PaginationInput, $filters: ProvinceFilterInput) {
        provinces(pagination: $pagination, filters: $filters) {
            id
            name
            cities {
                id
                name
                districts {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useProvincesQuery__
 *
 * To run a query within a React component, call `useProvincesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvincesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProvincesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProvincesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<ProvincesQuery, ProvincesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ProvincesQuery, ProvincesQueryVariables>(
        ProvincesDocument,
        options
    );
}
export function useProvincesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProvincesQuery, ProvincesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ProvincesQuery, ProvincesQueryVariables>(
        ProvincesDocument,
        options
    );
}
export type ProvincesQueryHookResult = ReturnType<typeof useProvincesQuery>;
export type ProvincesLazyQueryHookResult = ReturnType<typeof useProvincesLazyQuery>;
export type ProvincesQueryResult = ApolloReactCommon.QueryResult<
    ProvincesQuery,
    ProvincesQueryVariables
>;
export const ContactPeopleDocument = gql`
    query contactPeople($pagination: PaginationInput, $filters: ContactPersonFilterInput) {
        contactPeople(pagination: $pagination, filters: $filters) {
            id
            name
            jobTitle
            mobile
        }
    }
`;

/**
 * __useContactPeopleQuery__
 *
 * To run a query within a React component, call `useContactPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactPeopleQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useContactPeopleQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<ContactPeopleQuery, ContactPeopleQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ContactPeopleQuery, ContactPeopleQueryVariables>(
        ContactPeopleDocument,
        options
    );
}
export function useContactPeopleLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ContactPeopleQuery,
        ContactPeopleQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ContactPeopleQuery, ContactPeopleQueryVariables>(
        ContactPeopleDocument,
        options
    );
}
export type ContactPeopleQueryHookResult = ReturnType<typeof useContactPeopleQuery>;
export type ContactPeopleLazyQueryHookResult = ReturnType<typeof useContactPeopleLazyQuery>;
export type ContactPeopleQueryResult = ApolloReactCommon.QueryResult<
    ContactPeopleQuery,
    ContactPeopleQueryVariables
>;
export const CustomerTypesDocument = gql`
    query customerTypes($pagination: PaginationInput, $filters: CustomerTypeFilterInput) {
        customerTypes(pagination: $pagination, filters: $filters) {
            id
            name
            code
            salesTeamGroup {
                id
                name
            }
        }
    }
`;

/**
 * __useCustomerTypesQuery__
 *
 * To run a query within a React component, call `useCustomerTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerTypesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomerTypesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<CustomerTypesQuery, CustomerTypesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CustomerTypesQuery, CustomerTypesQueryVariables>(
        CustomerTypesDocument,
        options
    );
}
export function useCustomerTypesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        CustomerTypesQuery,
        CustomerTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CustomerTypesQuery, CustomerTypesQueryVariables>(
        CustomerTypesDocument,
        options
    );
}
export type CustomerTypesQueryHookResult = ReturnType<typeof useCustomerTypesQuery>;
export type CustomerTypesLazyQueryHookResult = ReturnType<typeof useCustomerTypesLazyQuery>;
export type CustomerTypesQueryResult = ApolloReactCommon.QueryResult<
    CustomerTypesQuery,
    CustomerTypesQueryVariables
>;
export const CustomerAreasDocument = gql`
    query customerAreas($pagination: PaginationInput, $filters: CustomerAreaFilterInput) {
        customerAreas(pagination: $pagination, filters: $filters) {
            id
            name
        }
    }
`;

/**
 * __useCustomerAreasQuery__
 *
 * To run a query within a React component, call `useCustomerAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerAreasQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomerAreasQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<CustomerAreasQuery, CustomerAreasQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CustomerAreasQuery, CustomerAreasQueryVariables>(
        CustomerAreasDocument,
        options
    );
}
export function useCustomerAreasLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        CustomerAreasQuery,
        CustomerAreasQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CustomerAreasQuery, CustomerAreasQueryVariables>(
        CustomerAreasDocument,
        options
    );
}
export type CustomerAreasQueryHookResult = ReturnType<typeof useCustomerAreasQuery>;
export type CustomerAreasLazyQueryHookResult = ReturnType<typeof useCustomerAreasLazyQuery>;
export type CustomerAreasQueryResult = ApolloReactCommon.QueryResult<
    CustomerAreasQuery,
    CustomerAreasQueryVariables
>;
export const CustomerCertificateTypesDocument = gql`
    query customerCertificateTypes(
        $pagination: PaginationInput
        $filters: CustomerCertificateTypeFilterInput
    ) {
        customerCertificateTypes(pagination: $pagination, filters: $filters) {
            id
            name
        }
    }
`;

/**
 * __useCustomerCertificateTypesQuery__
 *
 * To run a query within a React component, call `useCustomerCertificateTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerCertificateTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerCertificateTypesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomerCertificateTypesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        CustomerCertificateTypesQuery,
        CustomerCertificateTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        CustomerCertificateTypesQuery,
        CustomerCertificateTypesQueryVariables
    >(CustomerCertificateTypesDocument, options);
}
export function useCustomerCertificateTypesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        CustomerCertificateTypesQuery,
        CustomerCertificateTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        CustomerCertificateTypesQuery,
        CustomerCertificateTypesQueryVariables
    >(CustomerCertificateTypesDocument, options);
}
export type CustomerCertificateTypesQueryHookResult = ReturnType<
    typeof useCustomerCertificateTypesQuery
>;
export type CustomerCertificateTypesLazyQueryHookResult = ReturnType<
    typeof useCustomerCertificateTypesLazyQuery
>;
export type CustomerCertificateTypesQueryResult = ApolloReactCommon.QueryResult<
    CustomerCertificateTypesQuery,
    CustomerCertificateTypesQueryVariables
>;
export const CustomerCategoriesDocument = gql`
    query customerCategories($pagination: PaginationInput, $filters: CustomerCategoryFilterInput) {
        customerCategories(pagination: $pagination, filters: $filters) {
            id
            name
        }
    }
`;

/**
 * __useCustomerCategoriesQuery__
 *
 * To run a query within a React component, call `useCustomerCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerCategoriesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomerCategoriesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        CustomerCategoriesQuery,
        CustomerCategoriesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CustomerCategoriesQuery, CustomerCategoriesQueryVariables>(
        CustomerCategoriesDocument,
        options
    );
}
export function useCustomerCategoriesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        CustomerCategoriesQuery,
        CustomerCategoriesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CustomerCategoriesQuery, CustomerCategoriesQueryVariables>(
        CustomerCategoriesDocument,
        options
    );
}
export type CustomerCategoriesQueryHookResult = ReturnType<typeof useCustomerCategoriesQuery>;
export type CustomerCategoriesLazyQueryHookResult = ReturnType<
    typeof useCustomerCategoriesLazyQuery
>;
export type CustomerCategoriesQueryResult = ApolloReactCommon.QueryResult<
    CustomerCategoriesQuery,
    CustomerCategoriesQueryVariables
>;
export const CustomerLevelsDocument = gql`
    query customerLevels($pagination: PaginationInput, $filters: CustomerLevelFilterInput) {
        customerLevels(pagination: $pagination, filters: $filters) {
            createdAt
            id
            name
            salesTeamGroupId
            typeId
            updatedAt
            viewOrder
            salesTeamGroup {
                id
                name
                code
            }
            type {
                id
                name
                code
            }
        }
    }
`;

/**
 * __useCustomerLevelsQuery__
 *
 * To run a query within a React component, call `useCustomerLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerLevelsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomerLevelsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        CustomerLevelsQuery,
        CustomerLevelsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CustomerLevelsQuery, CustomerLevelsQueryVariables>(
        CustomerLevelsDocument,
        options
    );
}
export function useCustomerLevelsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        CustomerLevelsQuery,
        CustomerLevelsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CustomerLevelsQuery, CustomerLevelsQueryVariables>(
        CustomerLevelsDocument,
        options
    );
}
export type CustomerLevelsQueryHookResult = ReturnType<typeof useCustomerLevelsQuery>;
export type CustomerLevelsLazyQueryHookResult = ReturnType<typeof useCustomerLevelsLazyQuery>;
export type CustomerLevelsQueryResult = ApolloReactCommon.QueryResult<
    CustomerLevelsQuery,
    CustomerLevelsQueryVariables
>;
export const UsersDocument = gql`
    query users($pagination: PaginationInput, $filters: UserFilterInput) {
        users(pagination: $pagination, filters: $filters) {
            name
            id
            salesTeams {
                name
            }
            salesTeamGroups {
                name
            }
            code
        }
    }
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUsersQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export function useUsersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;
export const UsersSelectDocument = gql`
    query usersSelect($pagination: PaginationInput, $filters: UserFilterInput) {
        users(pagination: $pagination, filters: $filters) {
            id
            name
            code
        }
    }
`;

/**
 * __useUsersSelectQuery__
 *
 * To run a query within a React component, call `useUsersSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersSelectQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUsersSelectQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<UsersSelectQuery, UsersSelectQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<UsersSelectQuery, UsersSelectQueryVariables>(
        UsersSelectDocument,
        options
    );
}
export function useUsersSelectLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersSelectQuery, UsersSelectQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<UsersSelectQuery, UsersSelectQueryVariables>(
        UsersSelectDocument,
        options
    );
}
export type UsersSelectQueryHookResult = ReturnType<typeof useUsersSelectQuery>;
export type UsersSelectLazyQueryHookResult = ReturnType<typeof useUsersSelectLazyQuery>;
export type UsersSelectQueryResult = ApolloReactCommon.QueryResult<
    UsersSelectQuery,
    UsersSelectQueryVariables
>;
export const SalesTeamGroupDocument = gql`
    query salesTeamGroup($id: ID!) {
        salesTeamGroup(id: $id) {
            id
            name
            code
            region {
                id
                name
            }
            teams {
                ...teams
            }
            users {
                id
                name
                code
            }
        }
    }
    ${TeamsFragmentDoc}
`;

/**
 * __useSalesTeamGroupQuery__
 *
 * To run a query within a React component, call `useSalesTeamGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesTeamGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesTeamGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSalesTeamGroupQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        SalesTeamGroupQuery,
        SalesTeamGroupQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<SalesTeamGroupQuery, SalesTeamGroupQueryVariables>(
        SalesTeamGroupDocument,
        options
    );
}
export function useSalesTeamGroupLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        SalesTeamGroupQuery,
        SalesTeamGroupQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<SalesTeamGroupQuery, SalesTeamGroupQueryVariables>(
        SalesTeamGroupDocument,
        options
    );
}
export type SalesTeamGroupQueryHookResult = ReturnType<typeof useSalesTeamGroupQuery>;
export type SalesTeamGroupLazyQueryHookResult = ReturnType<typeof useSalesTeamGroupLazyQuery>;
export type SalesTeamGroupQueryResult = ApolloReactCommon.QueryResult<
    SalesTeamGroupQuery,
    SalesTeamGroupQueryVariables
>;
export const SalesTeamsDocument = gql`
    query salesTeams($pagination: PaginationInput, $filters: SalesTeamFilterInput) {
        salesTeams(pagination: $pagination, filters: $filters) {
            id
            code
            name
            nestedUsers {
                id
                name
                code
            }
            nestedUnits {
                id
                name
                user {
                    id
                    name
                    code
                }
            }
        }
    }
`;

/**
 * __useSalesTeamsQuery__
 *
 * To run a query within a React component, call `useSalesTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesTeamsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSalesTeamsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<SalesTeamsQuery, SalesTeamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<SalesTeamsQuery, SalesTeamsQueryVariables>(
        SalesTeamsDocument,
        options
    );
}
export function useSalesTeamsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SalesTeamsQuery, SalesTeamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<SalesTeamsQuery, SalesTeamsQueryVariables>(
        SalesTeamsDocument,
        options
    );
}
export type SalesTeamsQueryHookResult = ReturnType<typeof useSalesTeamsQuery>;
export type SalesTeamsLazyQueryHookResult = ReturnType<typeof useSalesTeamsLazyQuery>;
export type SalesTeamsQueryResult = ApolloReactCommon.QueryResult<
    SalesTeamsQuery,
    SalesTeamsQueryVariables
>;
export const SalesTeamGroupsDocument = gql`
    query salesTeamGroups($pagination: PaginationInput, $filters: SalesTeamGroupFilterInput) {
        salesTeamGroups(pagination: $pagination, filters: $filters) {
            id
            code
            name
            users {
                id
                name
                code
            }
        }
    }
`;

/**
 * __useSalesTeamGroupsQuery__
 *
 * To run a query within a React component, call `useSalesTeamGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesTeamGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesTeamGroupsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSalesTeamGroupsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        SalesTeamGroupsQuery,
        SalesTeamGroupsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<SalesTeamGroupsQuery, SalesTeamGroupsQueryVariables>(
        SalesTeamGroupsDocument,
        options
    );
}
export function useSalesTeamGroupsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        SalesTeamGroupsQuery,
        SalesTeamGroupsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<SalesTeamGroupsQuery, SalesTeamGroupsQueryVariables>(
        SalesTeamGroupsDocument,
        options
    );
}
export type SalesTeamGroupsQueryHookResult = ReturnType<typeof useSalesTeamGroupsQuery>;
export type SalesTeamGroupsLazyQueryHookResult = ReturnType<typeof useSalesTeamGroupsLazyQuery>;
export type SalesTeamGroupsQueryResult = ApolloReactCommon.QueryResult<
    SalesTeamGroupsQuery,
    SalesTeamGroupsQueryVariables
>;
export const PaginatedSalesTeamsDocument = gql`
    query paginatedSalesTeams($pagination: PaginationInput, $filters: SalesTeamFilterInput) {
        paginatedSalesTeams(pagination: $pagination, filters: $filters) {
            salesTeams {
                id
                name
                users {
                    id
                    code
                    name
                }
                children {
                    ...children
                    children {
                        ...children
                        children {
                            ...children
                            children {
                                ...children
                                children {
                                    ...children
                                    children {
                                        ...children
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${ChildrenFragmentDoc}
`;

/**
 * __usePaginatedSalesTeamsQuery__
 *
 * To run a query within a React component, call `usePaginatedSalesTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedSalesTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedSalesTeamsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedSalesTeamsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedSalesTeamsQuery,
        PaginatedSalesTeamsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PaginatedSalesTeamsQuery, PaginatedSalesTeamsQueryVariables>(
        PaginatedSalesTeamsDocument,
        options
    );
}
export function usePaginatedSalesTeamsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedSalesTeamsQuery,
        PaginatedSalesTeamsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedSalesTeamsQuery,
        PaginatedSalesTeamsQueryVariables
    >(PaginatedSalesTeamsDocument, options);
}
export type PaginatedSalesTeamsQueryHookResult = ReturnType<typeof usePaginatedSalesTeamsQuery>;
export type PaginatedSalesTeamsLazyQueryHookResult = ReturnType<
    typeof usePaginatedSalesTeamsLazyQuery
>;
export type PaginatedSalesTeamsQueryResult = ApolloReactCommon.QueryResult<
    PaginatedSalesTeamsQuery,
    PaginatedSalesTeamsQueryVariables
>;
export const SalesTeamUnitsDocument = gql`
    query SalesTeamUnits($pagination: PaginationInput, $filters: SalesTeamUnitFilterInput) {
        paginatedSalesTeamUnits(pagination: $pagination, filters: $filters) {
            count
            salesTeamUnits {
                ...SalesTeamUnit
            }
        }
    }
    ${SalesTeamUnitFragmentDoc}
`;

/**
 * __useSalesTeamUnitsQuery__
 *
 * To run a query within a React component, call `useSalesTeamUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesTeamUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesTeamUnitsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSalesTeamUnitsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        SalesTeamUnitsQuery,
        SalesTeamUnitsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<SalesTeamUnitsQuery, SalesTeamUnitsQueryVariables>(
        SalesTeamUnitsDocument,
        options
    );
}
export function useSalesTeamUnitsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        SalesTeamUnitsQuery,
        SalesTeamUnitsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<SalesTeamUnitsQuery, SalesTeamUnitsQueryVariables>(
        SalesTeamUnitsDocument,
        options
    );
}
export type SalesTeamUnitsQueryHookResult = ReturnType<typeof useSalesTeamUnitsQuery>;
export type SalesTeamUnitsLazyQueryHookResult = ReturnType<typeof useSalesTeamUnitsLazyQuery>;
export type SalesTeamUnitsQueryResult = ApolloReactCommon.QueryResult<
    SalesTeamUnitsQuery,
    SalesTeamUnitsQueryVariables
>;
export const SalesTeamUnitDocument = gql`
    query SalesTeamUnit($salesTeamUnitId: ID!) {
        salesTeamUnit(id: $salesTeamUnitId) {
            ...SalesTeamUnit
        }
    }
    ${SalesTeamUnitFragmentDoc}
`;

/**
 * __useSalesTeamUnitQuery__
 *
 * To run a query within a React component, call `useSalesTeamUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesTeamUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesTeamUnitQuery({
 *   variables: {
 *      salesTeamUnitId: // value for 'salesTeamUnitId'
 *   },
 * });
 */
export function useSalesTeamUnitQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<SalesTeamUnitQuery, SalesTeamUnitQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<SalesTeamUnitQuery, SalesTeamUnitQueryVariables>(
        SalesTeamUnitDocument,
        options
    );
}
export function useSalesTeamUnitLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        SalesTeamUnitQuery,
        SalesTeamUnitQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<SalesTeamUnitQuery, SalesTeamUnitQueryVariables>(
        SalesTeamUnitDocument,
        options
    );
}
export type SalesTeamUnitQueryHookResult = ReturnType<typeof useSalesTeamUnitQuery>;
export type SalesTeamUnitLazyQueryHookResult = ReturnType<typeof useSalesTeamUnitLazyQuery>;
export type SalesTeamUnitQueryResult = ApolloReactCommon.QueryResult<
    SalesTeamUnitQuery,
    SalesTeamUnitQueryVariables
>;
export const VisitTypesDocument = gql`
    query visitTypes($pagination: PaginationInput, $filters: VisitTypeFilterInput) {
        visitTypes(pagination: $pagination, filters: $filters) {
            id
            name
            code
            salesTeamGroup {
                ...SalesTeamGroup
            }
        }
    }
    ${SalesTeamGroupFragmentDoc}
`;

/**
 * __useVisitTypesQuery__
 *
 * To run a query within a React component, call `useVisitTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitTypesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useVisitTypesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<VisitTypesQuery, VisitTypesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<VisitTypesQuery, VisitTypesQueryVariables>(
        VisitTypesDocument,
        options
    );
}
export function useVisitTypesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VisitTypesQuery, VisitTypesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<VisitTypesQuery, VisitTypesQueryVariables>(
        VisitTypesDocument,
        options
    );
}
export type VisitTypesQueryHookResult = ReturnType<typeof useVisitTypesQuery>;
export type VisitTypesLazyQueryHookResult = ReturnType<typeof useVisitTypesLazyQuery>;
export type VisitTypesQueryResult = ApolloReactCommon.QueryResult<
    VisitTypesQuery,
    VisitTypesQueryVariables
>;
export const VisitTimePeriodsDocument = gql`
    query visitTimePeriods($pagination: PaginationInput, $filters: VisitTimePeriodFilterInput) {
        visitTimePeriods(pagination: $pagination, filters: $filters) {
            id
            name
            salesTeamGroup {
                ...SalesTeamGroup
            }
        }
    }
    ${SalesTeamGroupFragmentDoc}
`;

/**
 * __useVisitTimePeriodsQuery__
 *
 * To run a query within a React component, call `useVisitTimePeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitTimePeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitTimePeriodsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useVisitTimePeriodsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        VisitTimePeriodsQuery,
        VisitTimePeriodsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<VisitTimePeriodsQuery, VisitTimePeriodsQueryVariables>(
        VisitTimePeriodsDocument,
        options
    );
}
export function useVisitTimePeriodsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        VisitTimePeriodsQuery,
        VisitTimePeriodsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<VisitTimePeriodsQuery, VisitTimePeriodsQueryVariables>(
        VisitTimePeriodsDocument,
        options
    );
}
export type VisitTimePeriodsQueryHookResult = ReturnType<typeof useVisitTimePeriodsQuery>;
export type VisitTimePeriodsLazyQueryHookResult = ReturnType<typeof useVisitTimePeriodsLazyQuery>;
export type VisitTimePeriodsQueryResult = ApolloReactCommon.QueryResult<
    VisitTimePeriodsQuery,
    VisitTimePeriodsQueryVariables
>;
export const VisitPropertiesDocument = gql`
    query visitProperties($pagination: PaginationInput, $filters: VisitPropertyFilterInput) {
        visitProperties(pagination: $pagination, filters: $filters) {
            id
            name
            salesTeamGroup {
                ...SalesTeamGroup
            }
        }
    }
    ${SalesTeamGroupFragmentDoc}
`;

/**
 * __useVisitPropertiesQuery__
 *
 * To run a query within a React component, call `useVisitPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitPropertiesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useVisitPropertiesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        VisitPropertiesQuery,
        VisitPropertiesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<VisitPropertiesQuery, VisitPropertiesQueryVariables>(
        VisitPropertiesDocument,
        options
    );
}
export function useVisitPropertiesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        VisitPropertiesQuery,
        VisitPropertiesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<VisitPropertiesQuery, VisitPropertiesQueryVariables>(
        VisitPropertiesDocument,
        options
    );
}
export type VisitPropertiesQueryHookResult = ReturnType<typeof useVisitPropertiesQuery>;
export type VisitPropertiesLazyQueryHookResult = ReturnType<typeof useVisitPropertiesLazyQuery>;
export type VisitPropertiesQueryResult = ApolloReactCommon.QueryResult<
    VisitPropertiesQuery,
    VisitPropertiesQueryVariables
>;
export const VisitPropertyTypesDocument = gql`
    query visitPropertyTypes($pagination: PaginationInput, $filters: VisitPropertyTypeFilterInput) {
        visitPropertyTypes(pagination: $pagination, filters: $filters) {
            id
            name
            code
            salesTeamGroup {
                ...SalesTeamGroup
            }
            properties {
                id
                name
            }
        }
    }
    ${SalesTeamGroupFragmentDoc}
`;

/**
 * __useVisitPropertyTypesQuery__
 *
 * To run a query within a React component, call `useVisitPropertyTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitPropertyTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitPropertyTypesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useVisitPropertyTypesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        VisitPropertyTypesQuery,
        VisitPropertyTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<VisitPropertyTypesQuery, VisitPropertyTypesQueryVariables>(
        VisitPropertyTypesDocument,
        options
    );
}
export function useVisitPropertyTypesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        VisitPropertyTypesQuery,
        VisitPropertyTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<VisitPropertyTypesQuery, VisitPropertyTypesQueryVariables>(
        VisitPropertyTypesDocument,
        options
    );
}
export type VisitPropertyTypesQueryHookResult = ReturnType<typeof useVisitPropertyTypesQuery>;
export type VisitPropertyTypesLazyQueryHookResult = ReturnType<
    typeof useVisitPropertyTypesLazyQuery
>;
export type VisitPropertyTypesQueryResult = ApolloReactCommon.QueryResult<
    VisitPropertyTypesQuery,
    VisitPropertyTypesQueryVariables
>;
export const VisitGoalsDocument = gql`
    query visitGoals($pagination: PaginationInput, $filters: VisitGoalFilterInput) {
        visitGoals(pagination: $pagination, filters: $filters) {
            id
            name
        }
    }
`;

/**
 * __useVisitGoalsQuery__
 *
 * To run a query within a React component, call `useVisitGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitGoalsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useVisitGoalsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<VisitGoalsQuery, VisitGoalsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<VisitGoalsQuery, VisitGoalsQueryVariables>(
        VisitGoalsDocument,
        options
    );
}
export function useVisitGoalsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VisitGoalsQuery, VisitGoalsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<VisitGoalsQuery, VisitGoalsQueryVariables>(
        VisitGoalsDocument,
        options
    );
}
export type VisitGoalsQueryHookResult = ReturnType<typeof useVisitGoalsQuery>;
export type VisitGoalsLazyQueryHookResult = ReturnType<typeof useVisitGoalsLazyQuery>;
export type VisitGoalsQueryResult = ApolloReactCommon.QueryResult<
    VisitGoalsQuery,
    VisitGoalsQueryVariables
>;
export const VisitActionsDocument = gql`
    query visitActions($pagination: PaginationInput, $filters: VisitActionFilterInput) {
        visitActions(pagination: $pagination, filters: $filters) {
            id
            name
            salesTeamGroup {
                ...SalesTeamGroup
            }
        }
    }
    ${SalesTeamGroupFragmentDoc}
`;

/**
 * __useVisitActionsQuery__
 *
 * To run a query within a React component, call `useVisitActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitActionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useVisitActionsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<VisitActionsQuery, VisitActionsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<VisitActionsQuery, VisitActionsQueryVariables>(
        VisitActionsDocument,
        options
    );
}
export function useVisitActionsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        VisitActionsQuery,
        VisitActionsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<VisitActionsQuery, VisitActionsQueryVariables>(
        VisitActionsDocument,
        options
    );
}
export type VisitActionsQueryHookResult = ReturnType<typeof useVisitActionsQuery>;
export type VisitActionsLazyQueryHookResult = ReturnType<typeof useVisitActionsLazyQuery>;
export type VisitActionsQueryResult = ApolloReactCommon.QueryResult<
    VisitActionsQuery,
    VisitActionsQueryVariables
>;
export const UpdateBusinessDocument = gql`
    mutation updateBusiness($input: BusinessUpdateInput!) {
        updateBusiness(input: $input) {
            id
            code
        }
    }
`;
export type UpdateBusinessMutationFn = ApolloReactCommon.MutationFunction<
    UpdateBusinessMutation,
    UpdateBusinessMutationVariables
>;

/**
 * __useUpdateBusinessMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessMutation, { data, loading, error }] = useUpdateBusinessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateBusinessMutation,
        UpdateBusinessMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<UpdateBusinessMutation, UpdateBusinessMutationVariables>(
        UpdateBusinessDocument,
        options
    );
}
export type UpdateBusinessMutationHookResult = ReturnType<typeof useUpdateBusinessMutation>;
export type UpdateBusinessMutationResult = ApolloReactCommon.MutationResult<UpdateBusinessMutation>;
export type UpdateBusinessMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UpdateBusinessMutation,
    UpdateBusinessMutationVariables
>;
export const CreateBusinessDocument = gql`
    mutation createBusiness($input: BusinessCreateInput!) {
        createBusiness(input: $input) {
            id
            code
        }
    }
`;
export type CreateBusinessMutationFn = ApolloReactCommon.MutationFunction<
    CreateBusinessMutation,
    CreateBusinessMutationVariables
>;

/**
 * __useCreateBusinessMutation__
 *
 * To run a mutation, you first call `useCreateBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessMutation, { data, loading, error }] = useCreateBusinessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBusinessMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateBusinessMutation,
        CreateBusinessMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CreateBusinessMutation, CreateBusinessMutationVariables>(
        CreateBusinessDocument,
        options
    );
}
export type CreateBusinessMutationHookResult = ReturnType<typeof useCreateBusinessMutation>;
export type CreateBusinessMutationResult = ApolloReactCommon.MutationResult<CreateBusinessMutation>;
export type CreateBusinessMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateBusinessMutation,
    CreateBusinessMutationVariables
>;
export const CreateBusinessMemoDocument = gql`
    mutation createBusinessMemo($input: BusinessMemoCreateInput!) {
        createBusinessMemo(input: $input) {
            id
        }
    }
`;
export type CreateBusinessMemoMutationFn = ApolloReactCommon.MutationFunction<
    CreateBusinessMemoMutation,
    CreateBusinessMemoMutationVariables
>;

/**
 * __useCreateBusinessMemoMutation__
 *
 * To run a mutation, you first call `useCreateBusinessMemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessMemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessMemoMutation, { data, loading, error }] = useCreateBusinessMemoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBusinessMemoMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateBusinessMemoMutation,
        CreateBusinessMemoMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateBusinessMemoMutation,
        CreateBusinessMemoMutationVariables
    >(CreateBusinessMemoDocument, options);
}
export type CreateBusinessMemoMutationHookResult = ReturnType<typeof useCreateBusinessMemoMutation>;
export type CreateBusinessMemoMutationResult =
    ApolloReactCommon.MutationResult<CreateBusinessMemoMutation>;
export type CreateBusinessMemoMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateBusinessMemoMutation,
    CreateBusinessMemoMutationVariables
>;
export const UpdateBusinessMemoDocument = gql`
    mutation updateBusinessMemo($input: BusinessMemoUpdateInput!) {
        updateBusinessMemo(input: $input) {
            id
        }
    }
`;
export type UpdateBusinessMemoMutationFn = ApolloReactCommon.MutationFunction<
    UpdateBusinessMemoMutation,
    UpdateBusinessMemoMutationVariables
>;

/**
 * __useUpdateBusinessMemoMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessMemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessMemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessMemoMutation, { data, loading, error }] = useUpdateBusinessMemoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessMemoMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateBusinessMemoMutation,
        UpdateBusinessMemoMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateBusinessMemoMutation,
        UpdateBusinessMemoMutationVariables
    >(UpdateBusinessMemoDocument, options);
}
export type UpdateBusinessMemoMutationHookResult = ReturnType<typeof useUpdateBusinessMemoMutation>;
export type UpdateBusinessMemoMutationResult =
    ApolloReactCommon.MutationResult<UpdateBusinessMemoMutation>;
export type UpdateBusinessMemoMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UpdateBusinessMemoMutation,
    UpdateBusinessMemoMutationVariables
>;
export const DeleteBusinessDocument = gql`
    mutation DeleteBusiness($deleteBusinessId: ID!) {
        deleteBusiness(id: $deleteBusinessId)
    }
`;
export type DeleteBusinessMutationFn = ApolloReactCommon.MutationFunction<
    DeleteBusinessMutation,
    DeleteBusinessMutationVariables
>;

/**
 * __useDeleteBusinessMutation__
 *
 * To run a mutation, you first call `useDeleteBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBusinessMutation, { data, loading, error }] = useDeleteBusinessMutation({
 *   variables: {
 *      deleteBusinessId: // value for 'deleteBusinessId'
 *   },
 * });
 */
export function useDeleteBusinessMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteBusinessMutation,
        DeleteBusinessMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<DeleteBusinessMutation, DeleteBusinessMutationVariables>(
        DeleteBusinessDocument,
        options
    );
}
export type DeleteBusinessMutationHookResult = ReturnType<typeof useDeleteBusinessMutation>;
export type DeleteBusinessMutationResult = ApolloReactCommon.MutationResult<DeleteBusinessMutation>;
export type DeleteBusinessMutationOptions = ApolloReactCommon.BaseMutationOptions<
    DeleteBusinessMutation,
    DeleteBusinessMutationVariables
>;
export const BusinessesDocument = gql`
    query businesses($filters: BusinessFilterInput) {
        businesses(filters: $filters) {
            ...businessFragment
        }
    }
    ${BusinessFragmentFragmentDoc}
`;

/**
 * __useBusinessesQuery__
 *
 * To run a query within a React component, call `useBusinessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBusinessesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<BusinessesQuery, BusinessesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<BusinessesQuery, BusinessesQueryVariables>(
        BusinessesDocument,
        options
    );
}
export function useBusinessesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BusinessesQuery, BusinessesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<BusinessesQuery, BusinessesQueryVariables>(
        BusinessesDocument,
        options
    );
}
export type BusinessesQueryHookResult = ReturnType<typeof useBusinessesQuery>;
export type BusinessesLazyQueryHookResult = ReturnType<typeof useBusinessesLazyQuery>;
export type BusinessesQueryResult = ApolloReactCommon.QueryResult<
    BusinessesQuery,
    BusinessesQueryVariables
>;
export const PaginatedBusinessesDocument = gql`
    query paginatedBusinesses(
        $pagination: PaginationInput
        $sorts: [BusinessSortInput!]
        $filters: BusinessFilterInput
    ) {
        paginatedBusinesses(pagination: $pagination, sorts: $sorts, filters: $filters) {
            count
            budgetAmountSum
            businesses {
                ...businessFragment
            }
        }
    }
    ${BusinessFragmentFragmentDoc}
`;

/**
 * __usePaginatedBusinessesQuery__
 *
 * To run a query within a React component, call `usePaginatedBusinessesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedBusinessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedBusinessesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sorts: // value for 'sorts'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedBusinessesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedBusinessesQuery,
        PaginatedBusinessesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PaginatedBusinessesQuery, PaginatedBusinessesQueryVariables>(
        PaginatedBusinessesDocument,
        options
    );
}
export function usePaginatedBusinessesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedBusinessesQuery,
        PaginatedBusinessesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedBusinessesQuery,
        PaginatedBusinessesQueryVariables
    >(PaginatedBusinessesDocument, options);
}
export type PaginatedBusinessesQueryHookResult = ReturnType<typeof usePaginatedBusinessesQuery>;
export type PaginatedBusinessesLazyQueryHookResult = ReturnType<
    typeof usePaginatedBusinessesLazyQuery
>;
export type PaginatedBusinessesQueryResult = ApolloReactCommon.QueryResult<
    PaginatedBusinessesQuery,
    PaginatedBusinessesQueryVariables
>;
export const BusinessDocument = gql`
    query business($id: ID!) {
        business(id: $id) {
            ...businessFragment
        }
    }
    ${BusinessFragmentFragmentDoc}
`;

/**
 * __useBusinessQuery__
 *
 * To run a query within a React component, call `useBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusinessQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<BusinessQuery, BusinessQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<BusinessQuery, BusinessQueryVariables>(
        BusinessDocument,
        options
    );
}
export function useBusinessLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BusinessQuery, BusinessQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<BusinessQuery, BusinessQueryVariables>(
        BusinessDocument,
        options
    );
}
export type BusinessQueryHookResult = ReturnType<typeof useBusinessQuery>;
export type BusinessLazyQueryHookResult = ReturnType<typeof useBusinessLazyQuery>;
export type BusinessQueryResult = ApolloReactCommon.QueryResult<
    BusinessQuery,
    BusinessQueryVariables
>;
export const BusinessOpportunitiesDocument = gql`
    query businessOpportunities(
        $pagination: PaginationInput
        $filters: BusinessOpportunityFilterInput
    ) {
        businessOpportunities(pagination: $pagination, filters: $filters) {
            id
            name
            code
            enabled
            salesTeamGroup {
                id
                name
                teams {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useBusinessOpportunitiesQuery__
 *
 * To run a query within a React component, call `useBusinessOpportunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessOpportunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessOpportunitiesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBusinessOpportunitiesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        BusinessOpportunitiesQuery,
        BusinessOpportunitiesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        BusinessOpportunitiesQuery,
        BusinessOpportunitiesQueryVariables
    >(BusinessOpportunitiesDocument, options);
}
export function useBusinessOpportunitiesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BusinessOpportunitiesQuery,
        BusinessOpportunitiesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        BusinessOpportunitiesQuery,
        BusinessOpportunitiesQueryVariables
    >(BusinessOpportunitiesDocument, options);
}
export type BusinessOpportunitiesQueryHookResult = ReturnType<typeof useBusinessOpportunitiesQuery>;
export type BusinessOpportunitiesLazyQueryHookResult = ReturnType<
    typeof useBusinessOpportunitiesLazyQuery
>;
export type BusinessOpportunitiesQueryResult = ApolloReactCommon.QueryResult<
    BusinessOpportunitiesQuery,
    BusinessOpportunitiesQueryVariables
>;
export const BusinessProductsDocument = gql`
    query businessProducts($pagination: PaginationInput, $filters: BusinessProductFilterInput) {
        businessProducts(pagination: $pagination, filters: $filters) {
            ...BusinessProductFragment
        }
    }
    ${BusinessProductFragmentFragmentDoc}
`;

/**
 * __useBusinessProductsQuery__
 *
 * To run a query within a React component, call `useBusinessProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessProductsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBusinessProductsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        BusinessProductsQuery,
        BusinessProductsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<BusinessProductsQuery, BusinessProductsQueryVariables>(
        BusinessProductsDocument,
        options
    );
}
export function useBusinessProductsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BusinessProductsQuery,
        BusinessProductsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<BusinessProductsQuery, BusinessProductsQueryVariables>(
        BusinessProductsDocument,
        options
    );
}
export type BusinessProductsQueryHookResult = ReturnType<typeof useBusinessProductsQuery>;
export type BusinessProductsLazyQueryHookResult = ReturnType<typeof useBusinessProductsLazyQuery>;
export type BusinessProductsQueryResult = ApolloReactCommon.QueryResult<
    BusinessProductsQuery,
    BusinessProductsQueryVariables
>;
export const BusinessStatusesDocument = gql`
    query businessStatuses($pagination: PaginationInput, $filters: BusinessStatusFilterInput) {
        businessStatuses(pagination: $pagination, filters: $filters) {
            name
            id
            type
            buyingOpportunity
        }
    }
`;

/**
 * __useBusinessStatusesQuery__
 *
 * To run a query within a React component, call `useBusinessStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessStatusesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBusinessStatusesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        BusinessStatusesQuery,
        BusinessStatusesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<BusinessStatusesQuery, BusinessStatusesQueryVariables>(
        BusinessStatusesDocument,
        options
    );
}
export function useBusinessStatusesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BusinessStatusesQuery,
        BusinessStatusesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<BusinessStatusesQuery, BusinessStatusesQueryVariables>(
        BusinessStatusesDocument,
        options
    );
}
export type BusinessStatusesQueryHookResult = ReturnType<typeof useBusinessStatusesQuery>;
export type BusinessStatusesLazyQueryHookResult = ReturnType<typeof useBusinessStatusesLazyQuery>;
export type BusinessStatusesQueryResult = ApolloReactCommon.QueryResult<
    BusinessStatusesQuery,
    BusinessStatusesQueryVariables
>;
export const BusinessLosingReasonsDocument = gql`
    query businessLosingReasons(
        $pagination: PaginationInput
        $filters: BusinessLosingReasonFilterInput
    ) {
        businessLosingReasons(pagination: $pagination, filters: $filters) {
            name
            id
            salesTeamGroupId
        }
    }
`;

/**
 * __useBusinessLosingReasonsQuery__
 *
 * To run a query within a React component, call `useBusinessLosingReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessLosingReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessLosingReasonsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBusinessLosingReasonsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        BusinessLosingReasonsQuery,
        BusinessLosingReasonsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        BusinessLosingReasonsQuery,
        BusinessLosingReasonsQueryVariables
    >(BusinessLosingReasonsDocument, options);
}
export function useBusinessLosingReasonsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BusinessLosingReasonsQuery,
        BusinessLosingReasonsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        BusinessLosingReasonsQuery,
        BusinessLosingReasonsQueryVariables
    >(BusinessLosingReasonsDocument, options);
}
export type BusinessLosingReasonsQueryHookResult = ReturnType<typeof useBusinessLosingReasonsQuery>;
export type BusinessLosingReasonsLazyQueryHookResult = ReturnType<
    typeof useBusinessLosingReasonsLazyQuery
>;
export type BusinessLosingReasonsQueryResult = ApolloReactCommon.QueryResult<
    BusinessLosingReasonsQuery,
    BusinessLosingReasonsQueryVariables
>;
export const BusinessTypesDocument = gql`
    query businessTypes($pagination: PaginationInput, $filters: BusinessTypeFilterInput) {
        businessTypes(pagination: $pagination, filters: $filters) {
            name
            id
            salesTeamGroupId
        }
    }
`;

/**
 * __useBusinessTypesQuery__
 *
 * To run a query within a React component, call `useBusinessTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessTypesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBusinessTypesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<BusinessTypesQuery, BusinessTypesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<BusinessTypesQuery, BusinessTypesQueryVariables>(
        BusinessTypesDocument,
        options
    );
}
export function useBusinessTypesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BusinessTypesQuery,
        BusinessTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<BusinessTypesQuery, BusinessTypesQueryVariables>(
        BusinessTypesDocument,
        options
    );
}
export type BusinessTypesQueryHookResult = ReturnType<typeof useBusinessTypesQuery>;
export type BusinessTypesLazyQueryHookResult = ReturnType<typeof useBusinessTypesLazyQuery>;
export type BusinessTypesQueryResult = ApolloReactCommon.QueryResult<
    BusinessTypesQuery,
    BusinessTypesQueryVariables
>;
export const BusinessPropertyTypesDocument = gql`
    query businessPropertyTypes(
        $pagination: PaginationInput
        $filters: BusinessPropertyTypeFilterInput
    ) {
        businessPropertyTypes(pagination: $pagination, filters: $filters) {
            name
            id
            salesTeamGroupId
            code
            properties {
                id
                name
            }
        }
    }
`;

/**
 * __useBusinessPropertyTypesQuery__
 *
 * To run a query within a React component, call `useBusinessPropertyTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessPropertyTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessPropertyTypesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBusinessPropertyTypesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        BusinessPropertyTypesQuery,
        BusinessPropertyTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        BusinessPropertyTypesQuery,
        BusinessPropertyTypesQueryVariables
    >(BusinessPropertyTypesDocument, options);
}
export function useBusinessPropertyTypesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BusinessPropertyTypesQuery,
        BusinessPropertyTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        BusinessPropertyTypesQuery,
        BusinessPropertyTypesQueryVariables
    >(BusinessPropertyTypesDocument, options);
}
export type BusinessPropertyTypesQueryHookResult = ReturnType<typeof useBusinessPropertyTypesQuery>;
export type BusinessPropertyTypesLazyQueryHookResult = ReturnType<
    typeof useBusinessPropertyTypesLazyQuery
>;
export type BusinessPropertyTypesQueryResult = ApolloReactCommon.QueryResult<
    BusinessPropertyTypesQuery,
    BusinessPropertyTypesQueryVariables
>;
export const BusinessPropertiesDocument = gql`
    query businessProperties($pagination: PaginationInput, $filters: BusinessPropertyFilterInput) {
        businessProperties(pagination: $pagination, filters: $filters) {
            name
            id
            typeId
            type {
                id
                name
                code
                properties {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useBusinessPropertiesQuery__
 *
 * To run a query within a React component, call `useBusinessPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessPropertiesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBusinessPropertiesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        BusinessPropertiesQuery,
        BusinessPropertiesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<BusinessPropertiesQuery, BusinessPropertiesQueryVariables>(
        BusinessPropertiesDocument,
        options
    );
}
export function useBusinessPropertiesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BusinessPropertiesQuery,
        BusinessPropertiesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<BusinessPropertiesQuery, BusinessPropertiesQueryVariables>(
        BusinessPropertiesDocument,
        options
    );
}
export type BusinessPropertiesQueryHookResult = ReturnType<typeof useBusinessPropertiesQuery>;
export type BusinessPropertiesLazyQueryHookResult = ReturnType<
    typeof useBusinessPropertiesLazyQuery
>;
export type BusinessPropertiesQueryResult = ApolloReactCommon.QueryResult<
    BusinessPropertiesQuery,
    BusinessPropertiesQueryVariables
>;
export const BusinessesAnalysisDetailDocument = gql`
    query BusinessesAnalysisDetail(
        $expectedClosedDate1: String
        $expectedClosedDate2: String
        $primaryUserIds: [ID!]
        $pagination: PaginationInput
        $budgetProductIds: [ID!]
    ) {
        High: paginatedBusinesses(
            filters: {
                expectedClosedDate1: $expectedClosedDate1
                expectedClosedDate2: $expectedClosedDate2
                statusTypes: InProgress
                transactionOpportunity: High
                primaryUserIds: $primaryUserIds
                budgetProductIds: $budgetProductIds
            }
            pagination: $pagination
        ) {
            count
            budgetAmountSum
            businesses {
                id
                saleAmount
                budgetAmount
                code
                buyingOpportunity {
                    name
                }
                expectedClosedDate
                winningOpportunity {
                    name
                }
                customer {
                    name
                }
                mapBudgetProducts {
                    budgetProduct {
                        id
                        name
                    }
                }
                primaryUser {
                    id
                    code
                    name
                    salesTeams {
                        id
                        name
                    }
                }
                status {
                    id
                    name
                    buyingOpportunity
                }
            }
        }
        MediumHigh: paginatedBusinesses(
            filters: {
                expectedClosedDate1: $expectedClosedDate1
                expectedClosedDate2: $expectedClosedDate2
                statusTypes: InProgress
                transactionOpportunity: MediumHigh
                primaryUserIds: $primaryUserIds
                budgetProductIds: $budgetProductIds
            }
            pagination: $pagination
        ) {
            count
            budgetAmountSum
            businesses {
                id
                saleAmount
                budgetAmount
                code
                buyingOpportunity {
                    name
                }
                expectedClosedDate
                winningOpportunity {
                    name
                }
                customer {
                    name
                }
                mapBudgetProducts {
                    budgetProduct {
                        name
                    }
                }
                primaryUser {
                    id
                    code
                    name
                    salesTeams {
                        id
                        name
                    }
                }
                status {
                    id
                    name
                    buyingOpportunity
                }
            }
        }
        Medium: paginatedBusinesses(
            filters: {
                expectedClosedDate1: $expectedClosedDate1
                expectedClosedDate2: $expectedClosedDate2
                statusTypes: InProgress
                transactionOpportunity: Medium
                primaryUserIds: $primaryUserIds
                budgetProductIds: $budgetProductIds
            }
            pagination: $pagination
        ) {
            count
            budgetAmountSum
            businesses {
                id
                saleAmount
                budgetAmount
                code
                buyingOpportunity {
                    name
                }
                expectedClosedDate
                winningOpportunity {
                    name
                }
                customer {
                    name
                }
                mapBudgetProducts {
                    budgetProduct {
                        name
                    }
                }
                primaryUser {
                    id
                    code
                    name
                    salesTeams {
                        id
                        name
                    }
                }
                status {
                    id
                    name
                    buyingOpportunity
                }
            }
        }
    }
`;

/**
 * __useBusinessesAnalysisDetailQuery__
 *
 * To run a query within a React component, call `useBusinessesAnalysisDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessesAnalysisDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessesAnalysisDetailQuery({
 *   variables: {
 *      expectedClosedDate1: // value for 'expectedClosedDate1'
 *      expectedClosedDate2: // value for 'expectedClosedDate2'
 *      primaryUserIds: // value for 'primaryUserIds'
 *      pagination: // value for 'pagination'
 *      budgetProductIds: // value for 'budgetProductIds'
 *   },
 * });
 */
export function useBusinessesAnalysisDetailQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        BusinessesAnalysisDetailQuery,
        BusinessesAnalysisDetailQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        BusinessesAnalysisDetailQuery,
        BusinessesAnalysisDetailQueryVariables
    >(BusinessesAnalysisDetailDocument, options);
}
export function useBusinessesAnalysisDetailLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BusinessesAnalysisDetailQuery,
        BusinessesAnalysisDetailQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        BusinessesAnalysisDetailQuery,
        BusinessesAnalysisDetailQueryVariables
    >(BusinessesAnalysisDetailDocument, options);
}
export type BusinessesAnalysisDetailQueryHookResult = ReturnType<
    typeof useBusinessesAnalysisDetailQuery
>;
export type BusinessesAnalysisDetailLazyQueryHookResult = ReturnType<
    typeof useBusinessesAnalysisDetailLazyQuery
>;
export type BusinessesAnalysisDetailQueryResult = ApolloReactCommon.QueryResult<
    BusinessesAnalysisDetailQuery,
    BusinessesAnalysisDetailQueryVariables
>;
export const BusinessAnalysisDocument = gql`
    query businessAnalysis(
        $expectedClosedDate1: String
        $expectedClosedDate2: String
        $primaryUserIds: [ID!]
        $budgetProductIds: [ID!]
    ) {
        businessAnalysis {
            High: productRank(
                filters: {
                    expectedClosedDate1: $expectedClosedDate1
                    expectedClosedDate2: $expectedClosedDate2
                    statusTypes: InProgress
                    transactionOpportunity: High
                    primaryUserIds: $primaryUserIds
                    budgetProductIds: $budgetProductIds
                }
            ) {
                ...ProductRankFragment
            }
            MediumHigh: productRank(
                filters: {
                    expectedClosedDate1: $expectedClosedDate1
                    expectedClosedDate2: $expectedClosedDate2
                    statusTypes: InProgress
                    transactionOpportunity: MediumHigh
                    primaryUserIds: $primaryUserIds
                    budgetProductIds: $budgetProductIds
                }
            ) {
                ...ProductRankFragment
            }
            Medium: productRank(
                filters: {
                    expectedClosedDate1: $expectedClosedDate1
                    expectedClosedDate2: $expectedClosedDate2
                    statusTypes: InProgress
                    transactionOpportunity: Medium
                    primaryUserIds: $primaryUserIds
                    budgetProductIds: $budgetProductIds
                }
            ) {
                ...ProductRankFragment
            }
            Default: businessStat(
                filters: {
                    expectedClosedDate1: $expectedClosedDate1
                    expectedClosedDate2: $expectedClosedDate2
                    primaryUserIds: $primaryUserIds
                    budgetProductIds: $budgetProductIds
                }
            ) {
                ...BusinessAnalysisBusinessStatFragment
            }
            InProgress: businessStat(
                filters: {
                    expectedClosedDate1: $expectedClosedDate1
                    expectedClosedDate2: $expectedClosedDate2
                    primaryUserIds: $primaryUserIds
                    statusTypes: InProgress
                    budgetProductIds: $budgetProductIds
                }
            ) {
                ...BusinessAnalysisBusinessStatFragment
            }
        }
    }
    ${ProductRankFragmentFragmentDoc}
    ${BusinessAnalysisBusinessStatFragmentFragmentDoc}
`;

/**
 * __useBusinessAnalysisQuery__
 *
 * To run a query within a React component, call `useBusinessAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessAnalysisQuery({
 *   variables: {
 *      expectedClosedDate1: // value for 'expectedClosedDate1'
 *      expectedClosedDate2: // value for 'expectedClosedDate2'
 *      primaryUserIds: // value for 'primaryUserIds'
 *      budgetProductIds: // value for 'budgetProductIds'
 *   },
 * });
 */
export function useBusinessAnalysisQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        BusinessAnalysisQuery,
        BusinessAnalysisQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<BusinessAnalysisQuery, BusinessAnalysisQueryVariables>(
        BusinessAnalysisDocument,
        options
    );
}
export function useBusinessAnalysisLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BusinessAnalysisQuery,
        BusinessAnalysisQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<BusinessAnalysisQuery, BusinessAnalysisQueryVariables>(
        BusinessAnalysisDocument,
        options
    );
}
export type BusinessAnalysisQueryHookResult = ReturnType<typeof useBusinessAnalysisQuery>;
export type BusinessAnalysisLazyQueryHookResult = ReturnType<typeof useBusinessAnalysisLazyQuery>;
export type BusinessAnalysisQueryResult = ApolloReactCommon.QueryResult<
    BusinessAnalysisQuery,
    BusinessAnalysisQueryVariables
>;
export const PaginatedBusinessSalesMethodsDocument = gql`
    query PaginatedBusinessSalesMethods(
        $pagination: PaginationInput
        $filters: BusinessSalesMethodFilterInput
    ) {
        paginatedBusinessSalesMethods(pagination: $pagination, filters: $filters) {
            count
            businessSalesMethods {
                id
                name
                code
            }
        }
    }
`;

/**
 * __usePaginatedBusinessSalesMethodsQuery__
 *
 * To run a query within a React component, call `usePaginatedBusinessSalesMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedBusinessSalesMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedBusinessSalesMethodsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedBusinessSalesMethodsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedBusinessSalesMethodsQuery,
        PaginatedBusinessSalesMethodsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PaginatedBusinessSalesMethodsQuery,
        PaginatedBusinessSalesMethodsQueryVariables
    >(PaginatedBusinessSalesMethodsDocument, options);
}
export function usePaginatedBusinessSalesMethodsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedBusinessSalesMethodsQuery,
        PaginatedBusinessSalesMethodsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedBusinessSalesMethodsQuery,
        PaginatedBusinessSalesMethodsQueryVariables
    >(PaginatedBusinessSalesMethodsDocument, options);
}
export type PaginatedBusinessSalesMethodsQueryHookResult = ReturnType<
    typeof usePaginatedBusinessSalesMethodsQuery
>;
export type PaginatedBusinessSalesMethodsLazyQueryHookResult = ReturnType<
    typeof usePaginatedBusinessSalesMethodsLazyQuery
>;
export type PaginatedBusinessSalesMethodsQueryResult = ApolloReactCommon.QueryResult<
    PaginatedBusinessSalesMethodsQuery,
    PaginatedBusinessSalesMethodsQueryVariables
>;
export const PaginatedCurrenciesDocument = gql`
    query paginatedCurrencies($pagination: PaginationInput, $filters: CurrencyFilterInput) {
        paginatedCurrencies(pagination: $pagination, filters: $filters) {
            count
            currencies {
                id
                name
                code
                decimalPlace
            }
        }
    }
`;

/**
 * __usePaginatedCurrenciesQuery__
 *
 * To run a query within a React component, call `usePaginatedCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedCurrenciesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedCurrenciesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedCurrenciesQuery,
        PaginatedCurrenciesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PaginatedCurrenciesQuery, PaginatedCurrenciesQueryVariables>(
        PaginatedCurrenciesDocument,
        options
    );
}
export function usePaginatedCurrenciesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedCurrenciesQuery,
        PaginatedCurrenciesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedCurrenciesQuery,
        PaginatedCurrenciesQueryVariables
    >(PaginatedCurrenciesDocument, options);
}
export type PaginatedCurrenciesQueryHookResult = ReturnType<typeof usePaginatedCurrenciesQuery>;
export type PaginatedCurrenciesLazyQueryHookResult = ReturnType<
    typeof usePaginatedCurrenciesLazyQuery
>;
export type PaginatedCurrenciesQueryResult = ApolloReactCommon.QueryResult<
    PaginatedCurrenciesQuery,
    PaginatedCurrenciesQueryVariables
>;
export const PaginatedPaymentMethodsDocument = gql`
    query paginatedPaymentMethods(
        $pagination: PaginationInput
        $filters: PaymentMethodFilterInput
    ) {
        paginatedPaymentMethods(pagination: $pagination, filters: $filters) {
            count
            paymentMethods {
                id
                name
            }
        }
    }
`;

/**
 * __usePaginatedPaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaginatedPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedPaymentMethodsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedPaymentMethodsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedPaymentMethodsQuery,
        PaginatedPaymentMethodsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PaginatedPaymentMethodsQuery,
        PaginatedPaymentMethodsQueryVariables
    >(PaginatedPaymentMethodsDocument, options);
}
export function usePaginatedPaymentMethodsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedPaymentMethodsQuery,
        PaginatedPaymentMethodsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedPaymentMethodsQuery,
        PaginatedPaymentMethodsQueryVariables
    >(PaginatedPaymentMethodsDocument, options);
}
export type PaginatedPaymentMethodsQueryHookResult = ReturnType<
    typeof usePaginatedPaymentMethodsQuery
>;
export type PaginatedPaymentMethodsLazyQueryHookResult = ReturnType<
    typeof usePaginatedPaymentMethodsLazyQuery
>;
export type PaginatedPaymentMethodsQueryResult = ApolloReactCommon.QueryResult<
    PaginatedPaymentMethodsQuery,
    PaginatedPaymentMethodsQueryVariables
>;
export const PaginatedRegionsDocument = gql`
    query paginatedRegions($pagination: PaginationInput, $filters: RegionFilterInput) {
        paginatedRegions(pagination: $pagination, filters: $filters) {
            count
            regions {
                id
                name
            }
        }
    }
`;

/**
 * __usePaginatedRegionsQuery__
 *
 * To run a query within a React component, call `usePaginatedRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedRegionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedRegionsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedRegionsQuery,
        PaginatedRegionsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PaginatedRegionsQuery, PaginatedRegionsQueryVariables>(
        PaginatedRegionsDocument,
        options
    );
}
export function usePaginatedRegionsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedRegionsQuery,
        PaginatedRegionsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PaginatedRegionsQuery, PaginatedRegionsQueryVariables>(
        PaginatedRegionsDocument,
        options
    );
}
export type PaginatedRegionsQueryHookResult = ReturnType<typeof usePaginatedRegionsQuery>;
export type PaginatedRegionsLazyQueryHookResult = ReturnType<typeof usePaginatedRegionsLazyQuery>;
export type PaginatedRegionsQueryResult = ApolloReactCommon.QueryResult<
    PaginatedRegionsQuery,
    PaginatedRegionsQueryVariables
>;
export const CreateCompetitorDocument = gql`
    mutation createCompetitor($input: CompetitorCreateInput!) {
        createCompetitor(input: $input) {
            id
        }
    }
`;
export type CreateCompetitorMutationFn = ApolloReactCommon.MutationFunction<
    CreateCompetitorMutation,
    CreateCompetitorMutationVariables
>;

/**
 * __useCreateCompetitorMutation__
 *
 * To run a mutation, you first call `useCreateCompetitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompetitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompetitorMutation, { data, loading, error }] = useCreateCompetitorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompetitorMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateCompetitorMutation,
        CreateCompetitorMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateCompetitorMutation,
        CreateCompetitorMutationVariables
    >(CreateCompetitorDocument, options);
}
export type CreateCompetitorMutationHookResult = ReturnType<typeof useCreateCompetitorMutation>;
export type CreateCompetitorMutationResult =
    ApolloReactCommon.MutationResult<CreateCompetitorMutation>;
export type CreateCompetitorMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateCompetitorMutation,
    CreateCompetitorMutationVariables
>;
export const UpdateCompetitorDocument = gql`
    mutation updateCompetitor($input: CompetitorUpdateInput!) {
        updateCompetitor(input: $input) {
            id
        }
    }
`;
export type UpdateCompetitorMutationFn = ApolloReactCommon.MutationFunction<
    UpdateCompetitorMutation,
    UpdateCompetitorMutationVariables
>;

/**
 * __useUpdateCompetitorMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitorMutation, { data, loading, error }] = useUpdateCompetitorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompetitorMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateCompetitorMutation,
        UpdateCompetitorMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateCompetitorMutation,
        UpdateCompetitorMutationVariables
    >(UpdateCompetitorDocument, options);
}
export type UpdateCompetitorMutationHookResult = ReturnType<typeof useUpdateCompetitorMutation>;
export type UpdateCompetitorMutationResult =
    ApolloReactCommon.MutationResult<UpdateCompetitorMutation>;
export type UpdateCompetitorMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UpdateCompetitorMutation,
    UpdateCompetitorMutationVariables
>;
export const CompetitorsDocument = gql`
    query competitors($filters: CompetitorFilterInput) {
        competitors(filters: $filters) {
            id
            salesTeamGroup {
                id
                name
            }
            name
            advantage
            disadvantage
            strategy
            memo
            createdUser {
                id
                name
            }
            updatedUser {
                id
                name
            }
            brand
            model
            agents
            businessProducts {
                id
                name
            }
            createdAt
            updatedAt
        }
    }
`;

/**
 * __useCompetitorsQuery__
 *
 * To run a query within a React component, call `useCompetitorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetitorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetitorsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCompetitorsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<CompetitorsQuery, CompetitorsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CompetitorsQuery, CompetitorsQueryVariables>(
        CompetitorsDocument,
        options
    );
}
export function useCompetitorsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompetitorsQuery, CompetitorsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CompetitorsQuery, CompetitorsQueryVariables>(
        CompetitorsDocument,
        options
    );
}
export type CompetitorsQueryHookResult = ReturnType<typeof useCompetitorsQuery>;
export type CompetitorsLazyQueryHookResult = ReturnType<typeof useCompetitorsLazyQuery>;
export type CompetitorsQueryResult = ApolloReactCommon.QueryResult<
    CompetitorsQuery,
    CompetitorsQueryVariables
>;
export const PaginatedCompetitorsDocument = gql`
    query paginatedCompetitors($pagination: PaginationInput, $filters: CompetitorFilterInput) {
        paginatedCompetitors(pagination: $pagination, filters: $filters) {
            count
            competitors {
                id
                salesTeamGroup {
                    id
                    name
                }
                name
                advantage
                disadvantage
                strategy
                memo
                createdUser {
                    id
                    name
                }
                updatedUser {
                    id
                    name
                }
                brand
                model
                agents
                businessProducts {
                    id
                    name
                }
                createdAt
                updatedAt
            }
        }
    }
`;

/**
 * __usePaginatedCompetitorsQuery__
 *
 * To run a query within a React component, call `usePaginatedCompetitorsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedCompetitorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedCompetitorsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedCompetitorsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedCompetitorsQuery,
        PaginatedCompetitorsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PaginatedCompetitorsQuery, PaginatedCompetitorsQueryVariables>(
        PaginatedCompetitorsDocument,
        options
    );
}
export function usePaginatedCompetitorsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedCompetitorsQuery,
        PaginatedCompetitorsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedCompetitorsQuery,
        PaginatedCompetitorsQueryVariables
    >(PaginatedCompetitorsDocument, options);
}
export type PaginatedCompetitorsQueryHookResult = ReturnType<typeof usePaginatedCompetitorsQuery>;
export type PaginatedCompetitorsLazyQueryHookResult = ReturnType<
    typeof usePaginatedCompetitorsLazyQuery
>;
export type PaginatedCompetitorsQueryResult = ApolloReactCommon.QueryResult<
    PaginatedCompetitorsQuery,
    PaginatedCompetitorsQueryVariables
>;
export const CompetitorDocument = gql`
    query competitor($id: ID!) {
        competitor(id: $id) {
            id
            salesTeamGroup {
                id
                name
            }
            name
            advantage
            disadvantage
            strategy
            memo
            createdUser {
                id
                name
            }
            updatedUser {
                id
                name
            }
            brand
            model
            agents
            businessProducts {
                id
                name
            }
            createdAt
            updatedAt
        }
    }
`;

/**
 * __useCompetitorQuery__
 *
 * To run a query within a React component, call `useCompetitorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetitorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetitorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompetitorQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CompetitorQuery, CompetitorQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CompetitorQuery, CompetitorQueryVariables>(
        CompetitorDocument,
        options
    );
}
export function useCompetitorLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompetitorQuery, CompetitorQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CompetitorQuery, CompetitorQueryVariables>(
        CompetitorDocument,
        options
    );
}
export type CompetitorQueryHookResult = ReturnType<typeof useCompetitorQuery>;
export type CompetitorLazyQueryHookResult = ReturnType<typeof useCompetitorLazyQuery>;
export type CompetitorQueryResult = ApolloReactCommon.QueryResult<
    CompetitorQuery,
    CompetitorQueryVariables
>;
export const UpdateContactPersonDocument = gql`
    mutation updateContactPerson($input: ContactPersonUpdateInput!) {
        updateContactPerson(input: $input) {
            id
        }
    }
`;
export type UpdateContactPersonMutationFn = ApolloReactCommon.MutationFunction<
    UpdateContactPersonMutation,
    UpdateContactPersonMutationVariables
>;

/**
 * __useUpdateContactPersonMutation__
 *
 * To run a mutation, you first call `useUpdateContactPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactPersonMutation, { data, loading, error }] = useUpdateContactPersonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactPersonMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateContactPersonMutation,
        UpdateContactPersonMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateContactPersonMutation,
        UpdateContactPersonMutationVariables
    >(UpdateContactPersonDocument, options);
}
export type UpdateContactPersonMutationHookResult = ReturnType<
    typeof useUpdateContactPersonMutation
>;
export type UpdateContactPersonMutationResult =
    ApolloReactCommon.MutationResult<UpdateContactPersonMutation>;
export type UpdateContactPersonMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UpdateContactPersonMutation,
    UpdateContactPersonMutationVariables
>;
export const CreateContactPersonDocument = gql`
    mutation createContactPerson($input: ContactPersonCreateInput!) {
        createContactPerson(input: $input) {
            id
            code
            name
        }
    }
`;
export type CreateContactPersonMutationFn = ApolloReactCommon.MutationFunction<
    CreateContactPersonMutation,
    CreateContactPersonMutationVariables
>;

/**
 * __useCreateContactPersonMutation__
 *
 * To run a mutation, you first call `useCreateContactPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactPersonMutation, { data, loading, error }] = useCreateContactPersonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactPersonMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateContactPersonMutation,
        CreateContactPersonMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateContactPersonMutation,
        CreateContactPersonMutationVariables
    >(CreateContactPersonDocument, options);
}
export type CreateContactPersonMutationHookResult = ReturnType<
    typeof useCreateContactPersonMutation
>;
export type CreateContactPersonMutationResult =
    ApolloReactCommon.MutationResult<CreateContactPersonMutation>;
export type CreateContactPersonMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateContactPersonMutation,
    CreateContactPersonMutationVariables
>;
export const DeleteContactPersonDocument = gql`
    mutation deleteContactPerson($id: ID!) {
        deleteContactPerson(id: $id)
    }
`;
export type DeleteContactPersonMutationFn = ApolloReactCommon.MutationFunction<
    DeleteContactPersonMutation,
    DeleteContactPersonMutationVariables
>;

/**
 * __useDeleteContactPersonMutation__
 *
 * To run a mutation, you first call `useDeleteContactPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactPersonMutation, { data, loading, error }] = useDeleteContactPersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactPersonMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteContactPersonMutation,
        DeleteContactPersonMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteContactPersonMutation,
        DeleteContactPersonMutationVariables
    >(DeleteContactPersonDocument, options);
}
export type DeleteContactPersonMutationHookResult = ReturnType<
    typeof useDeleteContactPersonMutation
>;
export type DeleteContactPersonMutationResult =
    ApolloReactCommon.MutationResult<DeleteContactPersonMutation>;
export type DeleteContactPersonMutationOptions = ApolloReactCommon.BaseMutationOptions<
    DeleteContactPersonMutation,
    DeleteContactPersonMutationVariables
>;
export const DeleteCustomerToContactPeopleDocument = gql`
    mutation deleteCustomerToContactPeople($input: DeleteCustomerToContactPeopleInput!) {
        deleteCustomerToContactPeople(input: $input) {
            id
        }
    }
`;
export type DeleteCustomerToContactPeopleMutationFn = ApolloReactCommon.MutationFunction<
    DeleteCustomerToContactPeopleMutation,
    DeleteCustomerToContactPeopleMutationVariables
>;

/**
 * __useDeleteCustomerToContactPeopleMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerToContactPeopleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerToContactPeopleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerToContactPeopleMutation, { data, loading, error }] = useDeleteCustomerToContactPeopleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomerToContactPeopleMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteCustomerToContactPeopleMutation,
        DeleteCustomerToContactPeopleMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteCustomerToContactPeopleMutation,
        DeleteCustomerToContactPeopleMutationVariables
    >(DeleteCustomerToContactPeopleDocument, options);
}
export type DeleteCustomerToContactPeopleMutationHookResult = ReturnType<
    typeof useDeleteCustomerToContactPeopleMutation
>;
export type DeleteCustomerToContactPeopleMutationResult =
    ApolloReactCommon.MutationResult<DeleteCustomerToContactPeopleMutation>;
export type DeleteCustomerToContactPeopleMutationOptions = ApolloReactCommon.BaseMutationOptions<
    DeleteCustomerToContactPeopleMutation,
    DeleteCustomerToContactPeopleMutationVariables
>;
export const CreateCustomerToContactPeopleDocument = gql`
    mutation createCustomerToContactPeople($input: CreateCustomerToContactPeopleInput!) {
        createCustomerToContactPeople(input: $input) {
            id
        }
    }
`;
export type CreateCustomerToContactPeopleMutationFn = ApolloReactCommon.MutationFunction<
    CreateCustomerToContactPeopleMutation,
    CreateCustomerToContactPeopleMutationVariables
>;

/**
 * __useCreateCustomerToContactPeopleMutation__
 *
 * To run a mutation, you first call `useCreateCustomerToContactPeopleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerToContactPeopleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerToContactPeopleMutation, { data, loading, error }] = useCreateCustomerToContactPeopleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerToContactPeopleMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateCustomerToContactPeopleMutation,
        CreateCustomerToContactPeopleMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateCustomerToContactPeopleMutation,
        CreateCustomerToContactPeopleMutationVariables
    >(CreateCustomerToContactPeopleDocument, options);
}
export type CreateCustomerToContactPeopleMutationHookResult = ReturnType<
    typeof useCreateCustomerToContactPeopleMutation
>;
export type CreateCustomerToContactPeopleMutationResult =
    ApolloReactCommon.MutationResult<CreateCustomerToContactPeopleMutation>;
export type CreateCustomerToContactPeopleMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateCustomerToContactPeopleMutation,
    CreateCustomerToContactPeopleMutationVariables
>;
export const PaginatedContactPeopleDocument = gql`
    query paginatedContactPeople($pagination: PaginationInput, $filters: ContactPersonFilterInput) {
        paginatedContactPeople(pagination: $pagination, filters: $filters) {
            count
            contactPeople {
                ...contactPeopleInfo
            }
        }
    }
    ${ContactPeopleInfoFragmentDoc}
`;

/**
 * __usePaginatedContactPeopleQuery__
 *
 * To run a query within a React component, call `usePaginatedContactPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedContactPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedContactPeopleQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedContactPeopleQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedContactPeopleQuery,
        PaginatedContactPeopleQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PaginatedContactPeopleQuery,
        PaginatedContactPeopleQueryVariables
    >(PaginatedContactPeopleDocument, options);
}
export function usePaginatedContactPeopleLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedContactPeopleQuery,
        PaginatedContactPeopleQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedContactPeopleQuery,
        PaginatedContactPeopleQueryVariables
    >(PaginatedContactPeopleDocument, options);
}
export type PaginatedContactPeopleQueryHookResult = ReturnType<
    typeof usePaginatedContactPeopleQuery
>;
export type PaginatedContactPeopleLazyQueryHookResult = ReturnType<
    typeof usePaginatedContactPeopleLazyQuery
>;
export type PaginatedContactPeopleQueryResult = ApolloReactCommon.QueryResult<
    PaginatedContactPeopleQuery,
    PaginatedContactPeopleQueryVariables
>;
export const ContactPersonDocument = gql`
    query contactPerson($id: ID!) {
        contactPerson(id: $id) {
            ...contactPeopleInfo
        }
    }
    ${ContactPeopleInfoFragmentDoc}
`;

/**
 * __useContactPersonQuery__
 *
 * To run a query within a React component, call `useContactPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactPersonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactPersonQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<ContactPersonQuery, ContactPersonQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ContactPersonQuery, ContactPersonQueryVariables>(
        ContactPersonDocument,
        options
    );
}
export function useContactPersonLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ContactPersonQuery,
        ContactPersonQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ContactPersonQuery, ContactPersonQueryVariables>(
        ContactPersonDocument,
        options
    );
}
export type ContactPersonQueryHookResult = ReturnType<typeof useContactPersonQuery>;
export type ContactPersonLazyQueryHookResult = ReturnType<typeof useContactPersonLazyQuery>;
export type ContactPersonQueryResult = ApolloReactCommon.QueryResult<
    ContactPersonQuery,
    ContactPersonQueryVariables
>;
export const ContactPersonDepartmentsDocument = gql`
    query ContactPersonDepartments(
        $pagination: PaginationInput
        $filters: ContactPersonDepartmentFilterInput
    ) {
        contactPersonDepartments(pagination: $pagination, filters: $filters) {
            id
            name
        }
    }
`;

/**
 * __useContactPersonDepartmentsQuery__
 *
 * To run a query within a React component, call `useContactPersonDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactPersonDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactPersonDepartmentsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useContactPersonDepartmentsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        ContactPersonDepartmentsQuery,
        ContactPersonDepartmentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        ContactPersonDepartmentsQuery,
        ContactPersonDepartmentsQueryVariables
    >(ContactPersonDepartmentsDocument, options);
}
export function useContactPersonDepartmentsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ContactPersonDepartmentsQuery,
        ContactPersonDepartmentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ContactPersonDepartmentsQuery,
        ContactPersonDepartmentsQueryVariables
    >(ContactPersonDepartmentsDocument, options);
}
export type ContactPersonDepartmentsQueryHookResult = ReturnType<
    typeof useContactPersonDepartmentsQuery
>;
export type ContactPersonDepartmentsLazyQueryHookResult = ReturnType<
    typeof useContactPersonDepartmentsLazyQuery
>;
export type ContactPersonDepartmentsQueryResult = ApolloReactCommon.QueryResult<
    ContactPersonDepartmentsQuery,
    ContactPersonDepartmentsQueryVariables
>;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($input: CustomerUpdateInput!) {
        updateCustomer(input: $input) {
            id
            name
            code
        }
    }
`;
export type UpdateCustomerMutationFn = ApolloReactCommon.MutationFunction<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateCustomerMutation,
        UpdateCustomerMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(
        UpdateCustomerDocument,
        options
    );
}
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = ApolloReactCommon.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
>;
export const CreateCustomerDocument = gql`
    mutation createCustomer($input: CustomerCreateInput!) {
        createCustomer(input: $input) {
            id
            name
            code
        }
    }
`;
export type CreateCustomerMutationFn = ApolloReactCommon.MutationFunction<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateCustomerMutation,
        CreateCustomerMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(
        CreateCustomerDocument,
        options
    );
}
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = ApolloReactCommon.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
>;
export const CreateCustomerCertificateDocument = gql`
    mutation createCustomerCertificate($input: CustomerCertificateCreateInput!) {
        createCustomerCertificate(input: $input) {
            id
            code
        }
    }
`;
export type CreateCustomerCertificateMutationFn = ApolloReactCommon.MutationFunction<
    CreateCustomerCertificateMutation,
    CreateCustomerCertificateMutationVariables
>;

/**
 * __useCreateCustomerCertificateMutation__
 *
 * To run a mutation, you first call `useCreateCustomerCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerCertificateMutation, { data, loading, error }] = useCreateCustomerCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerCertificateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateCustomerCertificateMutation,
        CreateCustomerCertificateMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateCustomerCertificateMutation,
        CreateCustomerCertificateMutationVariables
    >(CreateCustomerCertificateDocument, options);
}
export type CreateCustomerCertificateMutationHookResult = ReturnType<
    typeof useCreateCustomerCertificateMutation
>;
export type CreateCustomerCertificateMutationResult =
    ApolloReactCommon.MutationResult<CreateCustomerCertificateMutation>;
export type CreateCustomerCertificateMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateCustomerCertificateMutation,
    CreateCustomerCertificateMutationVariables
>;
export const UpdateCustomerCertificateDocument = gql`
    mutation updateCustomerCertificate($input: CustomerCertificateUpdateInput!) {
        updateCustomerCertificate(input: $input) {
            id
            code
        }
    }
`;
export type UpdateCustomerCertificateMutationFn = ApolloReactCommon.MutationFunction<
    UpdateCustomerCertificateMutation,
    UpdateCustomerCertificateMutationVariables
>;

/**
 * __useUpdateCustomerCertificateMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerCertificateMutation, { data, loading, error }] = useUpdateCustomerCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerCertificateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateCustomerCertificateMutation,
        UpdateCustomerCertificateMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateCustomerCertificateMutation,
        UpdateCustomerCertificateMutationVariables
    >(UpdateCustomerCertificateDocument, options);
}
export type UpdateCustomerCertificateMutationHookResult = ReturnType<
    typeof useUpdateCustomerCertificateMutation
>;
export type UpdateCustomerCertificateMutationResult =
    ApolloReactCommon.MutationResult<UpdateCustomerCertificateMutation>;
export type UpdateCustomerCertificateMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UpdateCustomerCertificateMutation,
    UpdateCustomerCertificateMutationVariables
>;
export const CreateCustomerAttachmentDocument = gql`
    mutation createCustomerAttachment($input: CustomerAttachmentCreateInput!) {
        createCustomerAttachment(input: $input) {
            id
            url
            name
            s3Key
        }
    }
`;
export type CreateCustomerAttachmentMutationFn = ApolloReactCommon.MutationFunction<
    CreateCustomerAttachmentMutation,
    CreateCustomerAttachmentMutationVariables
>;

/**
 * __useCreateCustomerAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateCustomerAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerAttachmentMutation, { data, loading, error }] = useCreateCustomerAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerAttachmentMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateCustomerAttachmentMutation,
        CreateCustomerAttachmentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateCustomerAttachmentMutation,
        CreateCustomerAttachmentMutationVariables
    >(CreateCustomerAttachmentDocument, options);
}
export type CreateCustomerAttachmentMutationHookResult = ReturnType<
    typeof useCreateCustomerAttachmentMutation
>;
export type CreateCustomerAttachmentMutationResult =
    ApolloReactCommon.MutationResult<CreateCustomerAttachmentMutation>;
export type CreateCustomerAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateCustomerAttachmentMutation,
    CreateCustomerAttachmentMutationVariables
>;
export const UpdateCustomerAttachmentDocument = gql`
    mutation updateCustomerAttachment($input: CustomerAttachmentUpdateInput!) {
        updateCustomerAttachment(input: $input) {
            id
            url
            name
            s3Key
        }
    }
`;
export type UpdateCustomerAttachmentMutationFn = ApolloReactCommon.MutationFunction<
    UpdateCustomerAttachmentMutation,
    UpdateCustomerAttachmentMutationVariables
>;

/**
 * __useUpdateCustomerAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerAttachmentMutation, { data, loading, error }] = useUpdateCustomerAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerAttachmentMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateCustomerAttachmentMutation,
        UpdateCustomerAttachmentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateCustomerAttachmentMutation,
        UpdateCustomerAttachmentMutationVariables
    >(UpdateCustomerAttachmentDocument, options);
}
export type UpdateCustomerAttachmentMutationHookResult = ReturnType<
    typeof useUpdateCustomerAttachmentMutation
>;
export type UpdateCustomerAttachmentMutationResult =
    ApolloReactCommon.MutationResult<UpdateCustomerAttachmentMutation>;
export type UpdateCustomerAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UpdateCustomerAttachmentMutation,
    UpdateCustomerAttachmentMutationVariables
>;
export const CreateCustomerCertificateAttachmentDocument = gql`
    mutation createCustomerCertificateAttachment(
        $input: CustomerCertificateAttachmentCreateInput!
    ) {
        createCustomerCertificateAttachment(input: $input) {
            id
            url
            name
            s3Key
        }
    }
`;
export type CreateCustomerCertificateAttachmentMutationFn = ApolloReactCommon.MutationFunction<
    CreateCustomerCertificateAttachmentMutation,
    CreateCustomerCertificateAttachmentMutationVariables
>;

/**
 * __useCreateCustomerCertificateAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateCustomerCertificateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerCertificateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerCertificateAttachmentMutation, { data, loading, error }] = useCreateCustomerCertificateAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerCertificateAttachmentMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateCustomerCertificateAttachmentMutation,
        CreateCustomerCertificateAttachmentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateCustomerCertificateAttachmentMutation,
        CreateCustomerCertificateAttachmentMutationVariables
    >(CreateCustomerCertificateAttachmentDocument, options);
}
export type CreateCustomerCertificateAttachmentMutationHookResult = ReturnType<
    typeof useCreateCustomerCertificateAttachmentMutation
>;
export type CreateCustomerCertificateAttachmentMutationResult =
    ApolloReactCommon.MutationResult<CreateCustomerCertificateAttachmentMutation>;
export type CreateCustomerCertificateAttachmentMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        CreateCustomerCertificateAttachmentMutation,
        CreateCustomerCertificateAttachmentMutationVariables
    >;
export const UpdateCustomerCertificateAttachmentDocument = gql`
    mutation updateCustomerCertificateAttachment(
        $input: CustomerCertificateAttachmentUpdateInput!
    ) {
        updateCustomerCertificateAttachment(input: $input) {
            id
            url
            name
            s3Key
        }
    }
`;
export type UpdateCustomerCertificateAttachmentMutationFn = ApolloReactCommon.MutationFunction<
    UpdateCustomerCertificateAttachmentMutation,
    UpdateCustomerCertificateAttachmentMutationVariables
>;

/**
 * __useUpdateCustomerCertificateAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerCertificateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerCertificateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerCertificateAttachmentMutation, { data, loading, error }] = useUpdateCustomerCertificateAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerCertificateAttachmentMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateCustomerCertificateAttachmentMutation,
        UpdateCustomerCertificateAttachmentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateCustomerCertificateAttachmentMutation,
        UpdateCustomerCertificateAttachmentMutationVariables
    >(UpdateCustomerCertificateAttachmentDocument, options);
}
export type UpdateCustomerCertificateAttachmentMutationHookResult = ReturnType<
    typeof useUpdateCustomerCertificateAttachmentMutation
>;
export type UpdateCustomerCertificateAttachmentMutationResult =
    ApolloReactCommon.MutationResult<UpdateCustomerCertificateAttachmentMutation>;
export type UpdateCustomerCertificateAttachmentMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        UpdateCustomerCertificateAttachmentMutation,
        UpdateCustomerCertificateAttachmentMutationVariables
    >;
export const DeleteCustomerCertificateAttachmentDocument = gql`
    mutation deleteCustomerCertificateAttachment($id: ID!) {
        deleteCustomerCertificateAttachment(id: $id)
    }
`;
export type DeleteCustomerCertificateAttachmentMutationFn = ApolloReactCommon.MutationFunction<
    DeleteCustomerCertificateAttachmentMutation,
    DeleteCustomerCertificateAttachmentMutationVariables
>;

/**
 * __useDeleteCustomerCertificateAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerCertificateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerCertificateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerCertificateAttachmentMutation, { data, loading, error }] = useDeleteCustomerCertificateAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerCertificateAttachmentMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteCustomerCertificateAttachmentMutation,
        DeleteCustomerCertificateAttachmentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteCustomerCertificateAttachmentMutation,
        DeleteCustomerCertificateAttachmentMutationVariables
    >(DeleteCustomerCertificateAttachmentDocument, options);
}
export type DeleteCustomerCertificateAttachmentMutationHookResult = ReturnType<
    typeof useDeleteCustomerCertificateAttachmentMutation
>;
export type DeleteCustomerCertificateAttachmentMutationResult =
    ApolloReactCommon.MutationResult<DeleteCustomerCertificateAttachmentMutation>;
export type DeleteCustomerCertificateAttachmentMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        DeleteCustomerCertificateAttachmentMutation,
        DeleteCustomerCertificateAttachmentMutationVariables
    >;
export const BulkCreateCustomerAttachmentDocument = gql`
    mutation bulkCreateCustomerAttachment($input: CustomerAttachmentBulkCreateInput!) {
        bulkCreateCustomerAttachment(input: $input) {
            id
            url
            name
            s3Key
        }
    }
`;
export type BulkCreateCustomerAttachmentMutationFn = ApolloReactCommon.MutationFunction<
    BulkCreateCustomerAttachmentMutation,
    BulkCreateCustomerAttachmentMutationVariables
>;

/**
 * __useBulkCreateCustomerAttachmentMutation__
 *
 * To run a mutation, you first call `useBulkCreateCustomerAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateCustomerAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateCustomerAttachmentMutation, { data, loading, error }] = useBulkCreateCustomerAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkCreateCustomerAttachmentMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        BulkCreateCustomerAttachmentMutation,
        BulkCreateCustomerAttachmentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        BulkCreateCustomerAttachmentMutation,
        BulkCreateCustomerAttachmentMutationVariables
    >(BulkCreateCustomerAttachmentDocument, options);
}
export type BulkCreateCustomerAttachmentMutationHookResult = ReturnType<
    typeof useBulkCreateCustomerAttachmentMutation
>;
export type BulkCreateCustomerAttachmentMutationResult =
    ApolloReactCommon.MutationResult<BulkCreateCustomerAttachmentMutation>;
export type BulkCreateCustomerAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
    BulkCreateCustomerAttachmentMutation,
    BulkCreateCustomerAttachmentMutationVariables
>;
export const DeleteCustomerAttachmentDocument = gql`
    mutation deleteCustomerAttachment($id: ID!) {
        deleteCustomerAttachment(id: $id)
    }
`;
export type DeleteCustomerAttachmentMutationFn = ApolloReactCommon.MutationFunction<
    DeleteCustomerAttachmentMutation,
    DeleteCustomerAttachmentMutationVariables
>;

/**
 * __useDeleteCustomerAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerAttachmentMutation, { data, loading, error }] = useDeleteCustomerAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerAttachmentMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteCustomerAttachmentMutation,
        DeleteCustomerAttachmentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteCustomerAttachmentMutation,
        DeleteCustomerAttachmentMutationVariables
    >(DeleteCustomerAttachmentDocument, options);
}
export type DeleteCustomerAttachmentMutationHookResult = ReturnType<
    typeof useDeleteCustomerAttachmentMutation
>;
export type DeleteCustomerAttachmentMutationResult =
    ApolloReactCommon.MutationResult<DeleteCustomerAttachmentMutation>;
export type DeleteCustomerAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
    DeleteCustomerAttachmentMutation,
    DeleteCustomerAttachmentMutationVariables
>;
export const DeleteCustomerCertificateDocument = gql`
    mutation deleteCustomerCertificate($id: ID!) {
        deleteCustomerCertificate(id: $id)
    }
`;
export type DeleteCustomerCertificateMutationFn = ApolloReactCommon.MutationFunction<
    DeleteCustomerCertificateMutation,
    DeleteCustomerCertificateMutationVariables
>;

/**
 * __useDeleteCustomerCertificateMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerCertificateMutation, { data, loading, error }] = useDeleteCustomerCertificateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerCertificateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteCustomerCertificateMutation,
        DeleteCustomerCertificateMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteCustomerCertificateMutation,
        DeleteCustomerCertificateMutationVariables
    >(DeleteCustomerCertificateDocument, options);
}
export type DeleteCustomerCertificateMutationHookResult = ReturnType<
    typeof useDeleteCustomerCertificateMutation
>;
export type DeleteCustomerCertificateMutationResult =
    ApolloReactCommon.MutationResult<DeleteCustomerCertificateMutation>;
export type DeleteCustomerCertificateMutationOptions = ApolloReactCommon.BaseMutationOptions<
    DeleteCustomerCertificateMutation,
    DeleteCustomerCertificateMutationVariables
>;
export const CustomersDocument = gql`
    query customers($pagination: PaginationInput, $filters: CustomerFilterInput) {
        customers(pagination: $pagination, filters: $filters) {
            ...Customer
        }
    }
    ${CustomerFragmentDoc}
`;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomersQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<CustomersQuery, CustomersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CustomersQuery, CustomersQueryVariables>(
        CustomersDocument,
        options
    );
}
export function useCustomersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CustomersQuery, CustomersQueryVariables>(
        CustomersDocument,
        options
    );
}
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = ApolloReactCommon.QueryResult<
    CustomersQuery,
    CustomersQueryVariables
>;
export const PaginatedCustomersDocument = gql`
    query paginatedCustomers($pagination: PaginationInput, $filters: CustomerFilterInput) {
        paginatedCustomers(pagination: $pagination, filters: $filters) {
            count
            customers {
                ...Customer
            }
        }
    }
    ${CustomerFragmentDoc}
`;

/**
 * __usePaginatedCustomersQuery__
 *
 * To run a query within a React component, call `usePaginatedCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedCustomersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedCustomersQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedCustomersQuery,
        PaginatedCustomersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PaginatedCustomersQuery, PaginatedCustomersQueryVariables>(
        PaginatedCustomersDocument,
        options
    );
}
export function usePaginatedCustomersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedCustomersQuery,
        PaginatedCustomersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PaginatedCustomersQuery, PaginatedCustomersQueryVariables>(
        PaginatedCustomersDocument,
        options
    );
}
export type PaginatedCustomersQueryHookResult = ReturnType<typeof usePaginatedCustomersQuery>;
export type PaginatedCustomersLazyQueryHookResult = ReturnType<
    typeof usePaginatedCustomersLazyQuery
>;
export type PaginatedCustomersQueryResult = ApolloReactCommon.QueryResult<
    PaginatedCustomersQuery,
    PaginatedCustomersQueryVariables
>;
export const CustomerDocument = gql`
    query customer($id: ID!) {
        customer(id: $id) {
            ...Customer
        }
    }
    ${CustomerFragmentDoc}
`;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CustomerQuery, CustomerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CustomerQuery, CustomerQueryVariables>(
        CustomerDocument,
        options
    );
}
export function useCustomerLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CustomerQuery, CustomerQueryVariables>(
        CustomerDocument,
        options
    );
}
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = ApolloReactCommon.QueryResult<
    CustomerQuery,
    CustomerQueryVariables
>;
export const CustomerCertificatesDocument = gql`
    query customerCertificates(
        $pagination: PaginationInput
        $filters: CustomerCertificateFilterInput
    ) {
        customerCertificates(pagination: $pagination, filters: $filters) {
            id
            code
            scope
            customer {
                id
                name
            }
            type {
                id
                name
            }
            effectiveDate
            expiryDate
            attachments {
                id
                name
                s3Key
                url
                memo
            }
        }
    }
`;

/**
 * __useCustomerCertificatesQuery__
 *
 * To run a query within a React component, call `useCustomerCertificatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerCertificatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerCertificatesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomerCertificatesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        CustomerCertificatesQuery,
        CustomerCertificatesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CustomerCertificatesQuery, CustomerCertificatesQueryVariables>(
        CustomerCertificatesDocument,
        options
    );
}
export function useCustomerCertificatesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        CustomerCertificatesQuery,
        CustomerCertificatesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        CustomerCertificatesQuery,
        CustomerCertificatesQueryVariables
    >(CustomerCertificatesDocument, options);
}
export type CustomerCertificatesQueryHookResult = ReturnType<typeof useCustomerCertificatesQuery>;
export type CustomerCertificatesLazyQueryHookResult = ReturnType<
    typeof useCustomerCertificatesLazyQuery
>;
export type CustomerCertificatesQueryResult = ApolloReactCommon.QueryResult<
    CustomerCertificatesQuery,
    CustomerCertificatesQueryVariables
>;
export const CustomerAttachmentsDocument = gql`
    query customerAttachments(
        $pagination: PaginationInput
        $filters: CustomerAttachmentFilterInput
    ) {
        customerAttachments(pagination: $pagination, filters: $filters) {
            id
            url
            s3Key
            customer {
                id
                name
            }
            name
        }
    }
`;

/**
 * __useCustomerAttachmentsQuery__
 *
 * To run a query within a React component, call `useCustomerAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerAttachmentsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomerAttachmentsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        CustomerAttachmentsQuery,
        CustomerAttachmentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CustomerAttachmentsQuery, CustomerAttachmentsQueryVariables>(
        CustomerAttachmentsDocument,
        options
    );
}
export function useCustomerAttachmentsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        CustomerAttachmentsQuery,
        CustomerAttachmentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        CustomerAttachmentsQuery,
        CustomerAttachmentsQueryVariables
    >(CustomerAttachmentsDocument, options);
}
export type CustomerAttachmentsQueryHookResult = ReturnType<typeof useCustomerAttachmentsQuery>;
export type CustomerAttachmentsLazyQueryHookResult = ReturnType<
    typeof useCustomerAttachmentsLazyQuery
>;
export type CustomerAttachmentsQueryResult = ApolloReactCommon.QueryResult<
    CustomerAttachmentsQuery,
    CustomerAttachmentsQueryVariables
>;
export const EyeServiceOrdersDocument = gql`
    query eyeServiceOrders($pagination: PaginationInput, $filters: EyeServiceOrderFilterInput) {
        eyeServiceOrders(pagination: $pagination, filters: $filters) {
            id
            code
            priority
            contactPerson
            contactPhone
            address
            assigneeUser {
                id
                name
            }
            approverUser {
                id
                name
            }
            eyeWarrantyContract {
                id
                code
            }
            estimatedDate
            eyeServiceOrderType {
                id
                name
            }
            status
            customer {
                id
                code
                name
            }
            assigneeDept {
                id
                code
                name
            }
        }
    }
`;

/**
 * __useEyeServiceOrdersQuery__
 *
 * To run a query within a React component, call `useEyeServiceOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeServiceOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeServiceOrdersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeServiceOrdersQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        EyeServiceOrdersQuery,
        EyeServiceOrdersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<EyeServiceOrdersQuery, EyeServiceOrdersQueryVariables>(
        EyeServiceOrdersDocument,
        options
    );
}
export function useEyeServiceOrdersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EyeServiceOrdersQuery,
        EyeServiceOrdersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<EyeServiceOrdersQuery, EyeServiceOrdersQueryVariables>(
        EyeServiceOrdersDocument,
        options
    );
}
export type EyeServiceOrdersQueryHookResult = ReturnType<typeof useEyeServiceOrdersQuery>;
export type EyeServiceOrdersLazyQueryHookResult = ReturnType<typeof useEyeServiceOrdersLazyQuery>;
export type EyeServiceOrdersQueryResult = ApolloReactCommon.QueryResult<
    EyeServiceOrdersQuery,
    EyeServiceOrdersQueryVariables
>;
export const CreditPeriodsDocument = gql`
    query CreditPeriods($pagination: PaginationInput, $filters: CreditPeriodFilterInput) {
        creditPeriods(pagination: $pagination, filters: $filters) {
            id
            name
        }
    }
`;

/**
 * __useCreditPeriodsQuery__
 *
 * To run a query within a React component, call `useCreditPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditPeriodsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCreditPeriodsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<CreditPeriodsQuery, CreditPeriodsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CreditPeriodsQuery, CreditPeriodsQueryVariables>(
        CreditPeriodsDocument,
        options
    );
}
export function useCreditPeriodsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        CreditPeriodsQuery,
        CreditPeriodsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CreditPeriodsQuery, CreditPeriodsQueryVariables>(
        CreditPeriodsDocument,
        options
    );
}
export type CreditPeriodsQueryHookResult = ReturnType<typeof useCreditPeriodsQuery>;
export type CreditPeriodsLazyQueryHookResult = ReturnType<typeof useCreditPeriodsLazyQuery>;
export type CreditPeriodsQueryResult = ApolloReactCommon.QueryResult<
    CreditPeriodsQuery,
    CreditPeriodsQueryVariables
>;
export const LevelTypeStatDocument = gql`
    query LevelTypeStat($filters: CustomerAnalysisLevelTypeStatFilterInput) {
        customerAnalysis {
            levelTypeStat(filters: $filters) {
                details {
                    levelFieldTypeId
                    levelFieldType {
                        code
                        name
                        id
                    }
                    levelFields {
                        levelFieldId
                        levelField {
                            id
                            name
                            code
                        }
                        countPercent
                        count
                        areas {
                            area {
                                id
                                name
                            }
                            areaId
                            count
                            countPercent
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useLevelTypeStatQuery__
 *
 * To run a query within a React component, call `useLevelTypeStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useLevelTypeStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLevelTypeStatQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useLevelTypeStatQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<LevelTypeStatQuery, LevelTypeStatQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<LevelTypeStatQuery, LevelTypeStatQueryVariables>(
        LevelTypeStatDocument,
        options
    );
}
export function useLevelTypeStatLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        LevelTypeStatQuery,
        LevelTypeStatQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<LevelTypeStatQuery, LevelTypeStatQueryVariables>(
        LevelTypeStatDocument,
        options
    );
}
export type LevelTypeStatQueryHookResult = ReturnType<typeof useLevelTypeStatQuery>;
export type LevelTypeStatLazyQueryHookResult = ReturnType<typeof useLevelTypeStatLazyQuery>;
export type LevelTypeStatQueryResult = ApolloReactCommon.QueryResult<
    LevelTypeStatQuery,
    LevelTypeStatQueryVariables
>;
export const PaginatedEyeFixedAssetRentalRecordsDocument = gql`
    query paginatedEyeFixedAssetRentalRecords(
        $pagination: PaginationInput
        $filters: EyeFixedAssetRentalRecordFilterInput
    ) {
        paginatedEyeFixedAssetRentalRecords(pagination: $pagination, filters: $filters) {
            count
            eyeFixedAssetRentalRecords {
                ...rentalRecordsInfo
            }
        }
    }
    ${RentalRecordsInfoFragmentDoc}
`;

/**
 * __usePaginatedEyeFixedAssetRentalRecordsQuery__
 *
 * To run a query within a React component, call `usePaginatedEyeFixedAssetRentalRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedEyeFixedAssetRentalRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedEyeFixedAssetRentalRecordsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedEyeFixedAssetRentalRecordsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedEyeFixedAssetRentalRecordsQuery,
        PaginatedEyeFixedAssetRentalRecordsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PaginatedEyeFixedAssetRentalRecordsQuery,
        PaginatedEyeFixedAssetRentalRecordsQueryVariables
    >(PaginatedEyeFixedAssetRentalRecordsDocument, options);
}
export function usePaginatedEyeFixedAssetRentalRecordsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedEyeFixedAssetRentalRecordsQuery,
        PaginatedEyeFixedAssetRentalRecordsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedEyeFixedAssetRentalRecordsQuery,
        PaginatedEyeFixedAssetRentalRecordsQueryVariables
    >(PaginatedEyeFixedAssetRentalRecordsDocument, options);
}
export type PaginatedEyeFixedAssetRentalRecordsQueryHookResult = ReturnType<
    typeof usePaginatedEyeFixedAssetRentalRecordsQuery
>;
export type PaginatedEyeFixedAssetRentalRecordsLazyQueryHookResult = ReturnType<
    typeof usePaginatedEyeFixedAssetRentalRecordsLazyQuery
>;
export type PaginatedEyeFixedAssetRentalRecordsQueryResult = ApolloReactCommon.QueryResult<
    PaginatedEyeFixedAssetRentalRecordsQuery,
    PaginatedEyeFixedAssetRentalRecordsQueryVariables
>;
export const EyeFixedAssetRentalRecordDocument = gql`
    query eyeFixedAssetRentalRecord($id: ID!) {
        eyeFixedAssetRentalRecord(id: $id) {
            ...rentalRecordsInfo
        }
    }
    ${RentalRecordsInfoFragmentDoc}
`;

/**
 * __useEyeFixedAssetRentalRecordQuery__
 *
 * To run a query within a React component, call `useEyeFixedAssetRentalRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeFixedAssetRentalRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeFixedAssetRentalRecordQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEyeFixedAssetRentalRecordQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        EyeFixedAssetRentalRecordQuery,
        EyeFixedAssetRentalRecordQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        EyeFixedAssetRentalRecordQuery,
        EyeFixedAssetRentalRecordQueryVariables
    >(EyeFixedAssetRentalRecordDocument, options);
}
export function useEyeFixedAssetRentalRecordLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EyeFixedAssetRentalRecordQuery,
        EyeFixedAssetRentalRecordQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        EyeFixedAssetRentalRecordQuery,
        EyeFixedAssetRentalRecordQueryVariables
    >(EyeFixedAssetRentalRecordDocument, options);
}
export type EyeFixedAssetRentalRecordQueryHookResult = ReturnType<
    typeof useEyeFixedAssetRentalRecordQuery
>;
export type EyeFixedAssetRentalRecordLazyQueryHookResult = ReturnType<
    typeof useEyeFixedAssetRentalRecordLazyQuery
>;
export type EyeFixedAssetRentalRecordQueryResult = ApolloReactCommon.QueryResult<
    EyeFixedAssetRentalRecordQuery,
    EyeFixedAssetRentalRecordQueryVariables
>;
export const EyeFixedAssetsDocument = gql`
    query EyeFixedAssets($pagination: PaginationInput, $filters: EyeFixedAssetFilterInput) {
        paginatedEyeFixedAssets(pagination: $pagination, filters: $filters) {
            eyeFixedAssets {
                ...eyeFixedAssets
            }
            count
        }
        eyeFixedAssets {
            id
            name
            code
        }
    }
    ${EyeFixedAssetsFragmentDoc}
`;

/**
 * __useEyeFixedAssetsQuery__
 *
 * To run a query within a React component, call `useEyeFixedAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeFixedAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeFixedAssetsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeFixedAssetsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        EyeFixedAssetsQuery,
        EyeFixedAssetsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<EyeFixedAssetsQuery, EyeFixedAssetsQueryVariables>(
        EyeFixedAssetsDocument,
        options
    );
}
export function useEyeFixedAssetsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EyeFixedAssetsQuery,
        EyeFixedAssetsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<EyeFixedAssetsQuery, EyeFixedAssetsQueryVariables>(
        EyeFixedAssetsDocument,
        options
    );
}
export type EyeFixedAssetsQueryHookResult = ReturnType<typeof useEyeFixedAssetsQuery>;
export type EyeFixedAssetsLazyQueryHookResult = ReturnType<typeof useEyeFixedAssetsLazyQuery>;
export type EyeFixedAssetsQueryResult = ApolloReactCommon.QueryResult<
    EyeFixedAssetsQuery,
    EyeFixedAssetsQueryVariables
>;
export const EyeFixedAssetDocument = gql`
    query EyeFixedAsset($eyeFixedAssetId: ID!) {
        eyeFixedAsset(id: $eyeFixedAssetId) {
            ...eyeFixedAssets
        }
    }
    ${EyeFixedAssetsFragmentDoc}
`;

/**
 * __useEyeFixedAssetQuery__
 *
 * To run a query within a React component, call `useEyeFixedAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeFixedAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeFixedAssetQuery({
 *   variables: {
 *      eyeFixedAssetId: // value for 'eyeFixedAssetId'
 *   },
 * });
 */
export function useEyeFixedAssetQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<EyeFixedAssetQuery, EyeFixedAssetQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<EyeFixedAssetQuery, EyeFixedAssetQueryVariables>(
        EyeFixedAssetDocument,
        options
    );
}
export function useEyeFixedAssetLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EyeFixedAssetQuery,
        EyeFixedAssetQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<EyeFixedAssetQuery, EyeFixedAssetQueryVariables>(
        EyeFixedAssetDocument,
        options
    );
}
export type EyeFixedAssetQueryHookResult = ReturnType<typeof useEyeFixedAssetQuery>;
export type EyeFixedAssetLazyQueryHookResult = ReturnType<typeof useEyeFixedAssetLazyQuery>;
export type EyeFixedAssetQueryResult = ApolloReactCommon.QueryResult<
    EyeFixedAssetQuery,
    EyeFixedAssetQueryVariables
>;
export const EyeFixedAssetTypesDocument = gql`
    query EyeFixedAssetTypes($pagination: PaginationInput, $filters: EyeFixedAssetTypeFilterInput) {
        paginatedEyeFixedAssetTypes(pagination: $pagination, filters: $filters) {
            eyeFixedAssetTypes {
                ...EyeFixedAssetType
            }
        }
    }
    ${EyeFixedAssetTypeFragmentDoc}
`;

/**
 * __useEyeFixedAssetTypesQuery__
 *
 * To run a query within a React component, call `useEyeFixedAssetTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeFixedAssetTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeFixedAssetTypesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeFixedAssetTypesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        EyeFixedAssetTypesQuery,
        EyeFixedAssetTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<EyeFixedAssetTypesQuery, EyeFixedAssetTypesQueryVariables>(
        EyeFixedAssetTypesDocument,
        options
    );
}
export function useEyeFixedAssetTypesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EyeFixedAssetTypesQuery,
        EyeFixedAssetTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<EyeFixedAssetTypesQuery, EyeFixedAssetTypesQueryVariables>(
        EyeFixedAssetTypesDocument,
        options
    );
}
export type EyeFixedAssetTypesQueryHookResult = ReturnType<typeof useEyeFixedAssetTypesQuery>;
export type EyeFixedAssetTypesLazyQueryHookResult = ReturnType<
    typeof useEyeFixedAssetTypesLazyQuery
>;
export type EyeFixedAssetTypesQueryResult = ApolloReactCommon.QueryResult<
    EyeFixedAssetTypesQuery,
    EyeFixedAssetTypesQueryVariables
>;
export const EyeFixedAssetRentalGoalsDocument = gql`
    query EyeFixedAssetRentalGoals {
        paginatedEyeFixedAssetRentalGoals {
            eyeFixedAssetRentalGoals {
                ...EyeFixedAssetRentalGoal
            }
        }
    }
    ${EyeFixedAssetRentalGoalFragmentDoc}
`;

/**
 * __useEyeFixedAssetRentalGoalsQuery__
 *
 * To run a query within a React component, call `useEyeFixedAssetRentalGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeFixedAssetRentalGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeFixedAssetRentalGoalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEyeFixedAssetRentalGoalsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        EyeFixedAssetRentalGoalsQuery,
        EyeFixedAssetRentalGoalsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        EyeFixedAssetRentalGoalsQuery,
        EyeFixedAssetRentalGoalsQueryVariables
    >(EyeFixedAssetRentalGoalsDocument, options);
}
export function useEyeFixedAssetRentalGoalsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EyeFixedAssetRentalGoalsQuery,
        EyeFixedAssetRentalGoalsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        EyeFixedAssetRentalGoalsQuery,
        EyeFixedAssetRentalGoalsQueryVariables
    >(EyeFixedAssetRentalGoalsDocument, options);
}
export type EyeFixedAssetRentalGoalsQueryHookResult = ReturnType<
    typeof useEyeFixedAssetRentalGoalsQuery
>;
export type EyeFixedAssetRentalGoalsLazyQueryHookResult = ReturnType<
    typeof useEyeFixedAssetRentalGoalsLazyQuery
>;
export type EyeFixedAssetRentalGoalsQueryResult = ApolloReactCommon.QueryResult<
    EyeFixedAssetRentalGoalsQuery,
    EyeFixedAssetRentalGoalsQueryVariables
>;
export const CreateEyeServiceOrderWorkDiaryDocument = gql`
    mutation CreateEyeServiceOrderWorkDiary($input: EyeServiceOrderWorkDiaryCreateInput!) {
        createEyeServiceOrderWorkDiary(input: $input) {
            ...EyeServiceOrderWorkDiarys
        }
    }
    ${EyeServiceOrderWorkDiarysFragmentDoc}
`;
export type CreateEyeServiceOrderWorkDiaryMutationFn = ApolloReactCommon.MutationFunction<
    CreateEyeServiceOrderWorkDiaryMutation,
    CreateEyeServiceOrderWorkDiaryMutationVariables
>;

/**
 * __useCreateEyeServiceOrderWorkDiaryMutation__
 *
 * To run a mutation, you first call `useCreateEyeServiceOrderWorkDiaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEyeServiceOrderWorkDiaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEyeServiceOrderWorkDiaryMutation, { data, loading, error }] = useCreateEyeServiceOrderWorkDiaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEyeServiceOrderWorkDiaryMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateEyeServiceOrderWorkDiaryMutation,
        CreateEyeServiceOrderWorkDiaryMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateEyeServiceOrderWorkDiaryMutation,
        CreateEyeServiceOrderWorkDiaryMutationVariables
    >(CreateEyeServiceOrderWorkDiaryDocument, options);
}
export type CreateEyeServiceOrderWorkDiaryMutationHookResult = ReturnType<
    typeof useCreateEyeServiceOrderWorkDiaryMutation
>;
export type CreateEyeServiceOrderWorkDiaryMutationResult =
    ApolloReactCommon.MutationResult<CreateEyeServiceOrderWorkDiaryMutation>;
export type CreateEyeServiceOrderWorkDiaryMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateEyeServiceOrderWorkDiaryMutation,
    CreateEyeServiceOrderWorkDiaryMutationVariables
>;
export const UpdateEyeServiceOrderWorkDiaryDocument = gql`
    mutation UpdateEyeServiceOrderWorkDiary($input: EyeServiceOrderWorkDiaryUpdateInput!) {
        updateEyeServiceOrderWorkDiary(input: $input) {
            ...EyeServiceOrderWorkDiarys
        }
    }
    ${EyeServiceOrderWorkDiarysFragmentDoc}
`;
export type UpdateEyeServiceOrderWorkDiaryMutationFn = ApolloReactCommon.MutationFunction<
    UpdateEyeServiceOrderWorkDiaryMutation,
    UpdateEyeServiceOrderWorkDiaryMutationVariables
>;

/**
 * __useUpdateEyeServiceOrderWorkDiaryMutation__
 *
 * To run a mutation, you first call `useUpdateEyeServiceOrderWorkDiaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEyeServiceOrderWorkDiaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEyeServiceOrderWorkDiaryMutation, { data, loading, error }] = useUpdateEyeServiceOrderWorkDiaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEyeServiceOrderWorkDiaryMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateEyeServiceOrderWorkDiaryMutation,
        UpdateEyeServiceOrderWorkDiaryMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateEyeServiceOrderWorkDiaryMutation,
        UpdateEyeServiceOrderWorkDiaryMutationVariables
    >(UpdateEyeServiceOrderWorkDiaryDocument, options);
}
export type UpdateEyeServiceOrderWorkDiaryMutationHookResult = ReturnType<
    typeof useUpdateEyeServiceOrderWorkDiaryMutation
>;
export type UpdateEyeServiceOrderWorkDiaryMutationResult =
    ApolloReactCommon.MutationResult<UpdateEyeServiceOrderWorkDiaryMutation>;
export type UpdateEyeServiceOrderWorkDiaryMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UpdateEyeServiceOrderWorkDiaryMutation,
    UpdateEyeServiceOrderWorkDiaryMutationVariables
>;
export const PaginatedEyeServiceOrderWorkDiariesDocument = gql`
    query PaginatedEyeServiceOrderWorkDiaries(
        $pagination: PaginationInput
        $filters: EyeServiceOrderWorkDiaryFilterInput
    ) {
        paginatedEyeServiceOrderWorkDiaries(pagination: $pagination, filters: $filters) {
            count
            eyeServiceOrderWorkDiaries {
                ...EyeServiceOrderWorkDiarys
            }
        }
    }
    ${EyeServiceOrderWorkDiarysFragmentDoc}
`;

/**
 * __usePaginatedEyeServiceOrderWorkDiariesQuery__
 *
 * To run a query within a React component, call `usePaginatedEyeServiceOrderWorkDiariesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedEyeServiceOrderWorkDiariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedEyeServiceOrderWorkDiariesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedEyeServiceOrderWorkDiariesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedEyeServiceOrderWorkDiariesQuery,
        PaginatedEyeServiceOrderWorkDiariesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PaginatedEyeServiceOrderWorkDiariesQuery,
        PaginatedEyeServiceOrderWorkDiariesQueryVariables
    >(PaginatedEyeServiceOrderWorkDiariesDocument, options);
}
export function usePaginatedEyeServiceOrderWorkDiariesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedEyeServiceOrderWorkDiariesQuery,
        PaginatedEyeServiceOrderWorkDiariesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedEyeServiceOrderWorkDiariesQuery,
        PaginatedEyeServiceOrderWorkDiariesQueryVariables
    >(PaginatedEyeServiceOrderWorkDiariesDocument, options);
}
export type PaginatedEyeServiceOrderWorkDiariesQueryHookResult = ReturnType<
    typeof usePaginatedEyeServiceOrderWorkDiariesQuery
>;
export type PaginatedEyeServiceOrderWorkDiariesLazyQueryHookResult = ReturnType<
    typeof usePaginatedEyeServiceOrderWorkDiariesLazyQuery
>;
export type PaginatedEyeServiceOrderWorkDiariesQueryResult = ApolloReactCommon.QueryResult<
    PaginatedEyeServiceOrderWorkDiariesQuery,
    PaginatedEyeServiceOrderWorkDiariesQueryVariables
>;
export const EyeServiceOrderWorkDiaryDocument = gql`
    query EyeServiceOrderWorkDiary($eyeServiceOrderWorkDiaryId: ID!) {
        eyeServiceOrderWorkDiary(id: $eyeServiceOrderWorkDiaryId) {
            ...EyeServiceOrderWorkDiarys
        }
    }
    ${EyeServiceOrderWorkDiarysFragmentDoc}
`;

/**
 * __useEyeServiceOrderWorkDiaryQuery__
 *
 * To run a query within a React component, call `useEyeServiceOrderWorkDiaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeServiceOrderWorkDiaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeServiceOrderWorkDiaryQuery({
 *   variables: {
 *      eyeServiceOrderWorkDiaryId: // value for 'eyeServiceOrderWorkDiaryId'
 *   },
 * });
 */
export function useEyeServiceOrderWorkDiaryQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        EyeServiceOrderWorkDiaryQuery,
        EyeServiceOrderWorkDiaryQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        EyeServiceOrderWorkDiaryQuery,
        EyeServiceOrderWorkDiaryQueryVariables
    >(EyeServiceOrderWorkDiaryDocument, options);
}
export function useEyeServiceOrderWorkDiaryLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EyeServiceOrderWorkDiaryQuery,
        EyeServiceOrderWorkDiaryQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        EyeServiceOrderWorkDiaryQuery,
        EyeServiceOrderWorkDiaryQueryVariables
    >(EyeServiceOrderWorkDiaryDocument, options);
}
export type EyeServiceOrderWorkDiaryQueryHookResult = ReturnType<
    typeof useEyeServiceOrderWorkDiaryQuery
>;
export type EyeServiceOrderWorkDiaryLazyQueryHookResult = ReturnType<
    typeof useEyeServiceOrderWorkDiaryLazyQuery
>;
export type EyeServiceOrderWorkDiaryQueryResult = ApolloReactCommon.QueryResult<
    EyeServiceOrderWorkDiaryQuery,
    EyeServiceOrderWorkDiaryQueryVariables
>;
export const PaginatedEyeServiceOrderWorkDiaryTypesDocument = gql`
    query PaginatedEyeServiceOrderWorkDiaryTypes(
        $pagination: PaginationInput
        $filters: EyeServiceOrderWorkDiaryTypeFilterInput
    ) {
        paginatedEyeServiceOrderWorkDiaryTypes(pagination: $pagination, filters: $filters) {
            eyeServiceOrderWorkDiaryTypes {
                id
                name
            }
            count
        }
    }
`;

/**
 * __usePaginatedEyeServiceOrderWorkDiaryTypesQuery__
 *
 * To run a query within a React component, call `usePaginatedEyeServiceOrderWorkDiaryTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedEyeServiceOrderWorkDiaryTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedEyeServiceOrderWorkDiaryTypesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedEyeServiceOrderWorkDiaryTypesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedEyeServiceOrderWorkDiaryTypesQuery,
        PaginatedEyeServiceOrderWorkDiaryTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PaginatedEyeServiceOrderWorkDiaryTypesQuery,
        PaginatedEyeServiceOrderWorkDiaryTypesQueryVariables
    >(PaginatedEyeServiceOrderWorkDiaryTypesDocument, options);
}
export function usePaginatedEyeServiceOrderWorkDiaryTypesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedEyeServiceOrderWorkDiaryTypesQuery,
        PaginatedEyeServiceOrderWorkDiaryTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedEyeServiceOrderWorkDiaryTypesQuery,
        PaginatedEyeServiceOrderWorkDiaryTypesQueryVariables
    >(PaginatedEyeServiceOrderWorkDiaryTypesDocument, options);
}
export type PaginatedEyeServiceOrderWorkDiaryTypesQueryHookResult = ReturnType<
    typeof usePaginatedEyeServiceOrderWorkDiaryTypesQuery
>;
export type PaginatedEyeServiceOrderWorkDiaryTypesLazyQueryHookResult = ReturnType<
    typeof usePaginatedEyeServiceOrderWorkDiaryTypesLazyQuery
>;
export type PaginatedEyeServiceOrderWorkDiaryTypesQueryResult = ApolloReactCommon.QueryResult<
    PaginatedEyeServiceOrderWorkDiaryTypesQuery,
    PaginatedEyeServiceOrderWorkDiaryTypesQueryVariables
>;
export const PaginatedEyeWarrantyContractsDocument = gql`
    query paginatedEyeWarrantyContracts(
        $pagination: PaginationInput
        $filters: EyeWarrantyContractFilterInput
    ) {
        paginatedEyeWarrantyContracts(pagination: $pagination, filters: $filters) {
            count
            eyeWarrantyContracts {
                ...EyeWarrantyContracts
            }
        }
    }
    ${EyeWarrantyContractsFragmentDoc}
`;

/**
 * __usePaginatedEyeWarrantyContractsQuery__
 *
 * To run a query within a React component, call `usePaginatedEyeWarrantyContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedEyeWarrantyContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedEyeWarrantyContractsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedEyeWarrantyContractsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedEyeWarrantyContractsQuery,
        PaginatedEyeWarrantyContractsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PaginatedEyeWarrantyContractsQuery,
        PaginatedEyeWarrantyContractsQueryVariables
    >(PaginatedEyeWarrantyContractsDocument, options);
}
export function usePaginatedEyeWarrantyContractsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedEyeWarrantyContractsQuery,
        PaginatedEyeWarrantyContractsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedEyeWarrantyContractsQuery,
        PaginatedEyeWarrantyContractsQueryVariables
    >(PaginatedEyeWarrantyContractsDocument, options);
}
export type PaginatedEyeWarrantyContractsQueryHookResult = ReturnType<
    typeof usePaginatedEyeWarrantyContractsQuery
>;
export type PaginatedEyeWarrantyContractsLazyQueryHookResult = ReturnType<
    typeof usePaginatedEyeWarrantyContractsLazyQuery
>;
export type PaginatedEyeWarrantyContractsQueryResult = ApolloReactCommon.QueryResult<
    PaginatedEyeWarrantyContractsQuery,
    PaginatedEyeWarrantyContractsQueryVariables
>;
export const EyeWarrantyContractDocument = gql`
    query eyeWarrantyContract($id: ID!) {
        eyeWarrantyContract(id: $id) {
            ...EyeWarrantyContracts
        }
    }
    ${EyeWarrantyContractsFragmentDoc}
`;

/**
 * __useEyeWarrantyContractQuery__
 *
 * To run a query within a React component, call `useEyeWarrantyContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeWarrantyContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeWarrantyContractQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEyeWarrantyContractQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        EyeWarrantyContractQuery,
        EyeWarrantyContractQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<EyeWarrantyContractQuery, EyeWarrantyContractQueryVariables>(
        EyeWarrantyContractDocument,
        options
    );
}
export function useEyeWarrantyContractLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EyeWarrantyContractQuery,
        EyeWarrantyContractQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        EyeWarrantyContractQuery,
        EyeWarrantyContractQueryVariables
    >(EyeWarrantyContractDocument, options);
}
export type EyeWarrantyContractQueryHookResult = ReturnType<typeof useEyeWarrantyContractQuery>;
export type EyeWarrantyContractLazyQueryHookResult = ReturnType<
    typeof useEyeWarrantyContractLazyQuery
>;
export type EyeWarrantyContractQueryResult = ApolloReactCommon.QueryResult<
    EyeWarrantyContractQuery,
    EyeWarrantyContractQueryVariables
>;
export const EcInventoryModelsDocument = gql`
    query EcInventoryModels {
        EcInventoryModels {
            id
            name
        }
    }
`;

/**
 * __useEcInventoryModelsQuery__
 *
 * To run a query within a React component, call `useEcInventoryModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcInventoryModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcInventoryModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEcInventoryModelsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        EcInventoryModelsQuery,
        EcInventoryModelsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<EcInventoryModelsQuery, EcInventoryModelsQueryVariables>(
        EcInventoryModelsDocument,
        options
    );
}
export function useEcInventoryModelsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EcInventoryModelsQuery,
        EcInventoryModelsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<EcInventoryModelsQuery, EcInventoryModelsQueryVariables>(
        EcInventoryModelsDocument,
        options
    );
}
export type EcInventoryModelsQueryHookResult = ReturnType<typeof useEcInventoryModelsQuery>;
export type EcInventoryModelsLazyQueryHookResult = ReturnType<typeof useEcInventoryModelsLazyQuery>;
export type EcInventoryModelsQueryResult = ApolloReactCommon.QueryResult<
    EcInventoryModelsQuery,
    EcInventoryModelsQueryVariables
>;
export const EcInventorySpecsDocument = gql`
    query EcInventorySpecs {
        EcInventorySpecs {
            id
            name
            model {
                id
                name
            }
        }
    }
`;

/**
 * __useEcInventorySpecsQuery__
 *
 * To run a query within a React component, call `useEcInventorySpecsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcInventorySpecsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcInventorySpecsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEcInventorySpecsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        EcInventorySpecsQuery,
        EcInventorySpecsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<EcInventorySpecsQuery, EcInventorySpecsQueryVariables>(
        EcInventorySpecsDocument,
        options
    );
}
export function useEcInventorySpecsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EcInventorySpecsQuery,
        EcInventorySpecsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<EcInventorySpecsQuery, EcInventorySpecsQueryVariables>(
        EcInventorySpecsDocument,
        options
    );
}
export type EcInventorySpecsQueryHookResult = ReturnType<typeof useEcInventorySpecsQuery>;
export type EcInventorySpecsLazyQueryHookResult = ReturnType<typeof useEcInventorySpecsLazyQuery>;
export type EcInventorySpecsQueryResult = ApolloReactCommon.QueryResult<
    EcInventorySpecsQuery,
    EcInventorySpecsQueryVariables
>;
export const EcInventoryWarehousesDocument = gql`
    query EcInventoryWarehouses {
        EcInventoryWarehouses {
            id
            name
        }
    }
`;

/**
 * __useEcInventoryWarehousesQuery__
 *
 * To run a query within a React component, call `useEcInventoryWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcInventoryWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcInventoryWarehousesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEcInventoryWarehousesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        EcInventoryWarehousesQuery,
        EcInventoryWarehousesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        EcInventoryWarehousesQuery,
        EcInventoryWarehousesQueryVariables
    >(EcInventoryWarehousesDocument, options);
}
export function useEcInventoryWarehousesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EcInventoryWarehousesQuery,
        EcInventoryWarehousesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        EcInventoryWarehousesQuery,
        EcInventoryWarehousesQueryVariables
    >(EcInventoryWarehousesDocument, options);
}
export type EcInventoryWarehousesQueryHookResult = ReturnType<typeof useEcInventoryWarehousesQuery>;
export type EcInventoryWarehousesLazyQueryHookResult = ReturnType<
    typeof useEcInventoryWarehousesLazyQuery
>;
export type EcInventoryWarehousesQueryResult = ApolloReactCommon.QueryResult<
    EcInventoryWarehousesQuery,
    EcInventoryWarehousesQueryVariables
>;
export const EcInventoryStatDocument = gql`
    query EcInventoryStat($filter: EcInventoryStatFilterInput!) {
        EcInventoryStat(filter: $filter) {
            details {
                modelId
                qty
                warehouses {
                    warehouse
                    qty
                    statuses {
                        status
                        qty
                    }
                }
                rentalPeriods {
                    rentalPeriod
                    qty
                }
            }
        }
    }
`;

/**
 * __useEcInventoryStatQuery__
 *
 * To run a query within a React component, call `useEcInventoryStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcInventoryStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcInventoryStatQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEcInventoryStatQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        EcInventoryStatQuery,
        EcInventoryStatQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<EcInventoryStatQuery, EcInventoryStatQueryVariables>(
        EcInventoryStatDocument,
        options
    );
}
export function useEcInventoryStatLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EcInventoryStatQuery,
        EcInventoryStatQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<EcInventoryStatQuery, EcInventoryStatQueryVariables>(
        EcInventoryStatDocument,
        options
    );
}
export type EcInventoryStatQueryHookResult = ReturnType<typeof useEcInventoryStatQuery>;
export type EcInventoryStatLazyQueryHookResult = ReturnType<typeof useEcInventoryStatLazyQuery>;
export type EcInventoryStatQueryResult = ApolloReactCommon.QueryResult<
    EcInventoryStatQuery,
    EcInventoryStatQueryVariables
>;
export const EcInventoryDetailsDocument = gql`
    query EcInventoryDetails($filter: EcInventoryDetailFilterInput!) {
        EcInventoryDetails(filter: $filter) {
            key
            model
            spec
            status
            rentalPeriod
            warehouse
            materialCode
            materialName
            customerCode
            customerName
            limitDate
            orderDate
            qty
        }
    }
`;

/**
 * __useEcInventoryDetailsQuery__
 *
 * To run a query within a React component, call `useEcInventoryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcInventoryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcInventoryDetailsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEcInventoryDetailsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        EcInventoryDetailsQuery,
        EcInventoryDetailsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<EcInventoryDetailsQuery, EcInventoryDetailsQueryVariables>(
        EcInventoryDetailsDocument,
        options
    );
}
export function useEcInventoryDetailsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EcInventoryDetailsQuery,
        EcInventoryDetailsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<EcInventoryDetailsQuery, EcInventoryDetailsQueryVariables>(
        EcInventoryDetailsDocument,
        options
    );
}
export type EcInventoryDetailsQueryHookResult = ReturnType<typeof useEcInventoryDetailsQuery>;
export type EcInventoryDetailsLazyQueryHookResult = ReturnType<
    typeof useEcInventoryDetailsLazyQuery
>;
export type EcInventoryDetailsQueryResult = ApolloReactCommon.QueryResult<
    EcInventoryDetailsQuery,
    EcInventoryDetailsQueryVariables
>;
export const BulkUpdateSalesPerformanceSalesUserForecastViewDocument = gql`
    mutation BulkUpdateSalesPerformanceSalesUserForecastView(
        $input: SalesPerformanceSalesUserForecastViewBulkUpdateInput!
    ) {
        bulkUpdateSalesPerformanceSalesUserForecastView(input: $input) {
            id
            userForecastId
            quota
            amountEarlyMonth
            amountLateMonth
            createdAt
            updatedAt
        }
    }
`;
export type BulkUpdateSalesPerformanceSalesUserForecastViewMutationFn =
    ApolloReactCommon.MutationFunction<
        BulkUpdateSalesPerformanceSalesUserForecastViewMutation,
        BulkUpdateSalesPerformanceSalesUserForecastViewMutationVariables
    >;

/**
 * __useBulkUpdateSalesPerformanceSalesUserForecastViewMutation__
 *
 * To run a mutation, you first call `useBulkUpdateSalesPerformanceSalesUserForecastViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateSalesPerformanceSalesUserForecastViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateSalesPerformanceSalesUserForecastViewMutation, { data, loading, error }] = useBulkUpdateSalesPerformanceSalesUserForecastViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateSalesPerformanceSalesUserForecastViewMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        BulkUpdateSalesPerformanceSalesUserForecastViewMutation,
        BulkUpdateSalesPerformanceSalesUserForecastViewMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        BulkUpdateSalesPerformanceSalesUserForecastViewMutation,
        BulkUpdateSalesPerformanceSalesUserForecastViewMutationVariables
    >(BulkUpdateSalesPerformanceSalesUserForecastViewDocument, options);
}
export type BulkUpdateSalesPerformanceSalesUserForecastViewMutationHookResult = ReturnType<
    typeof useBulkUpdateSalesPerformanceSalesUserForecastViewMutation
>;
export type BulkUpdateSalesPerformanceSalesUserForecastViewMutationResult =
    ApolloReactCommon.MutationResult<BulkUpdateSalesPerformanceSalesUserForecastViewMutation>;
export type BulkUpdateSalesPerformanceSalesUserForecastViewMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        BulkUpdateSalesPerformanceSalesUserForecastViewMutation,
        BulkUpdateSalesPerformanceSalesUserForecastViewMutationVariables
    >;
export const BulkUpdateSalesPerformanceSalesProductUserForecastViewDocument = gql`
    mutation BulkUpdateSalesPerformanceSalesProductUserForecastView(
        $input: SalesPerformanceSalesProductUserForecastViewBulkUpdateInput!
    ) {
        bulkUpdateSalesPerformanceSalesProductUserForecastView(input: $input) {
            id
            userForecastId
            quota
            qtyEarlyMonth
            qtyLateMonth
            createdAt
            updatedAt
        }
    }
`;
export type BulkUpdateSalesPerformanceSalesProductUserForecastViewMutationFn =
    ApolloReactCommon.MutationFunction<
        BulkUpdateSalesPerformanceSalesProductUserForecastViewMutation,
        BulkUpdateSalesPerformanceSalesProductUserForecastViewMutationVariables
    >;

/**
 * __useBulkUpdateSalesPerformanceSalesProductUserForecastViewMutation__
 *
 * To run a mutation, you first call `useBulkUpdateSalesPerformanceSalesProductUserForecastViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateSalesPerformanceSalesProductUserForecastViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateSalesPerformanceSalesProductUserForecastViewMutation, { data, loading, error }] = useBulkUpdateSalesPerformanceSalesProductUserForecastViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateSalesPerformanceSalesProductUserForecastViewMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        BulkUpdateSalesPerformanceSalesProductUserForecastViewMutation,
        BulkUpdateSalesPerformanceSalesProductUserForecastViewMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        BulkUpdateSalesPerformanceSalesProductUserForecastViewMutation,
        BulkUpdateSalesPerformanceSalesProductUserForecastViewMutationVariables
    >(BulkUpdateSalesPerformanceSalesProductUserForecastViewDocument, options);
}
export type BulkUpdateSalesPerformanceSalesProductUserForecastViewMutationHookResult = ReturnType<
    typeof useBulkUpdateSalesPerformanceSalesProductUserForecastViewMutation
>;
export type BulkUpdateSalesPerformanceSalesProductUserForecastViewMutationResult =
    ApolloReactCommon.MutationResult<BulkUpdateSalesPerformanceSalesProductUserForecastViewMutation>;
export type BulkUpdateSalesPerformanceSalesProductUserForecastViewMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        BulkUpdateSalesPerformanceSalesProductUserForecastViewMutation,
        BulkUpdateSalesPerformanceSalesProductUserForecastViewMutationVariables
    >;
export const BulkUpdateSalesPerformanceSalesProductOverallViewDocument = gql`
    mutation BulkUpdateSalesPerformanceSalesProductOverallView(
        $input: SalesPerformanceSalesProductOverallViewBulkUpdateInput!
    ) {
        bulkUpdateSalesPerformanceSalesProductOverallView(input: $input) {
            goals {
                id
                amount
                unitPrice
                qty
            }
            forecasts {
                id
                amount
                unitPrice
                qty
            }
        }
    }
`;
export type BulkUpdateSalesPerformanceSalesProductOverallViewMutationFn =
    ApolloReactCommon.MutationFunction<
        BulkUpdateSalesPerformanceSalesProductOverallViewMutation,
        BulkUpdateSalesPerformanceSalesProductOverallViewMutationVariables
    >;

/**
 * __useBulkUpdateSalesPerformanceSalesProductOverallViewMutation__
 *
 * To run a mutation, you first call `useBulkUpdateSalesPerformanceSalesProductOverallViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateSalesPerformanceSalesProductOverallViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateSalesPerformanceSalesProductOverallViewMutation, { data, loading, error }] = useBulkUpdateSalesPerformanceSalesProductOverallViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateSalesPerformanceSalesProductOverallViewMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        BulkUpdateSalesPerformanceSalesProductOverallViewMutation,
        BulkUpdateSalesPerformanceSalesProductOverallViewMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        BulkUpdateSalesPerformanceSalesProductOverallViewMutation,
        BulkUpdateSalesPerformanceSalesProductOverallViewMutationVariables
    >(BulkUpdateSalesPerformanceSalesProductOverallViewDocument, options);
}
export type BulkUpdateSalesPerformanceSalesProductOverallViewMutationHookResult = ReturnType<
    typeof useBulkUpdateSalesPerformanceSalesProductOverallViewMutation
>;
export type BulkUpdateSalesPerformanceSalesProductOverallViewMutationResult =
    ApolloReactCommon.MutationResult<BulkUpdateSalesPerformanceSalesProductOverallViewMutation>;
export type BulkUpdateSalesPerformanceSalesProductOverallViewMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        BulkUpdateSalesPerformanceSalesProductOverallViewMutation,
        BulkUpdateSalesPerformanceSalesProductOverallViewMutationVariables
    >;
export const PaginatedNavOrdersDocument = gql`
    query PaginatedNavOrders($pagination: PaginationInput, $filters: NavOrderFilterInput) {
        paginatedNavOrders(pagination: $pagination, filters: $filters) {
            count
            navOrders {
                ...NavOrder
            }
        }
    }
    ${NavOrderFragmentDoc}
`;

/**
 * __usePaginatedNavOrdersQuery__
 *
 * To run a query within a React component, call `usePaginatedNavOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedNavOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedNavOrdersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedNavOrdersQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedNavOrdersQuery,
        PaginatedNavOrdersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PaginatedNavOrdersQuery, PaginatedNavOrdersQueryVariables>(
        PaginatedNavOrdersDocument,
        options
    );
}
export function usePaginatedNavOrdersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedNavOrdersQuery,
        PaginatedNavOrdersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PaginatedNavOrdersQuery, PaginatedNavOrdersQueryVariables>(
        PaginatedNavOrdersDocument,
        options
    );
}
export type PaginatedNavOrdersQueryHookResult = ReturnType<typeof usePaginatedNavOrdersQuery>;
export type PaginatedNavOrdersLazyQueryHookResult = ReturnType<
    typeof usePaginatedNavOrdersLazyQuery
>;
export type PaginatedNavOrdersQueryResult = ApolloReactCommon.QueryResult<
    PaginatedNavOrdersQuery,
    PaginatedNavOrdersQueryVariables
>;
export const NavOrderDocument = gql`
    query NavOrder($navOrderId: ID!) {
        navOrder(id: $navOrderId) {
            ...NavOrder
        }
    }
    ${NavOrderFragmentDoc}
`;

/**
 * __useNavOrderQuery__
 *
 * To run a query within a React component, call `useNavOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavOrderQuery({
 *   variables: {
 *      navOrderId: // value for 'navOrderId'
 *   },
 * });
 */
export function useNavOrderQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<NavOrderQuery, NavOrderQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<NavOrderQuery, NavOrderQueryVariables>(
        NavOrderDocument,
        options
    );
}
export function useNavOrderLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NavOrderQuery, NavOrderQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<NavOrderQuery, NavOrderQueryVariables>(
        NavOrderDocument,
        options
    );
}
export type NavOrderQueryHookResult = ReturnType<typeof useNavOrderQuery>;
export type NavOrderLazyQueryHookResult = ReturnType<typeof useNavOrderLazyQuery>;
export type NavOrderQueryResult = ApolloReactCommon.QueryResult<
    NavOrderQuery,
    NavOrderQueryVariables
>;
export const PaginatedNavSalesInvoicesDocument = gql`
    query PaginatedNavSalesInvoices(
        $pagination: PaginationInput
        $filters: NavSalesInvoiceFilterInput
    ) {
        paginatedNavSalesInvoices(pagination: $pagination, filters: $filters) {
            count
            navSalesInvoices {
                ...NavSalesInvoice
            }
        }
    }
    ${NavSalesInvoiceFragmentDoc}
`;

/**
 * __usePaginatedNavSalesInvoicesQuery__
 *
 * To run a query within a React component, call `usePaginatedNavSalesInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedNavSalesInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedNavSalesInvoicesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedNavSalesInvoicesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedNavSalesInvoicesQuery,
        PaginatedNavSalesInvoicesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PaginatedNavSalesInvoicesQuery,
        PaginatedNavSalesInvoicesQueryVariables
    >(PaginatedNavSalesInvoicesDocument, options);
}
export function usePaginatedNavSalesInvoicesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedNavSalesInvoicesQuery,
        PaginatedNavSalesInvoicesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedNavSalesInvoicesQuery,
        PaginatedNavSalesInvoicesQueryVariables
    >(PaginatedNavSalesInvoicesDocument, options);
}
export type PaginatedNavSalesInvoicesQueryHookResult = ReturnType<
    typeof usePaginatedNavSalesInvoicesQuery
>;
export type PaginatedNavSalesInvoicesLazyQueryHookResult = ReturnType<
    typeof usePaginatedNavSalesInvoicesLazyQuery
>;
export type PaginatedNavSalesInvoicesQueryResult = ApolloReactCommon.QueryResult<
    PaginatedNavSalesInvoicesQuery,
    PaginatedNavSalesInvoicesQueryVariables
>;
export const NavSalesInvoiceDocument = gql`
    query NavSalesInvoice($navSalesInvoiceId: ID!) {
        navSalesInvoice(id: $navSalesInvoiceId) {
            ...NavSalesInvoice
        }
    }
    ${NavSalesInvoiceFragmentDoc}
`;

/**
 * __useNavSalesInvoiceQuery__
 *
 * To run a query within a React component, call `useNavSalesInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavSalesInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavSalesInvoiceQuery({
 *   variables: {
 *      navSalesInvoiceId: // value for 'navSalesInvoiceId'
 *   },
 * });
 */
export function useNavSalesInvoiceQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        NavSalesInvoiceQuery,
        NavSalesInvoiceQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<NavSalesInvoiceQuery, NavSalesInvoiceQueryVariables>(
        NavSalesInvoiceDocument,
        options
    );
}
export function useNavSalesInvoiceLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        NavSalesInvoiceQuery,
        NavSalesInvoiceQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<NavSalesInvoiceQuery, NavSalesInvoiceQueryVariables>(
        NavSalesInvoiceDocument,
        options
    );
}
export type NavSalesInvoiceQueryHookResult = ReturnType<typeof useNavSalesInvoiceQuery>;
export type NavSalesInvoiceLazyQueryHookResult = ReturnType<typeof useNavSalesInvoiceLazyQuery>;
export type NavSalesInvoiceQueryResult = ApolloReactCommon.QueryResult<
    NavSalesInvoiceQuery,
    NavSalesInvoiceQueryVariables
>;
export const NavSalesAnalysisDocument = gql`
    query navSalesAnalysis($date1: String, $date2: String, $primaryUserIds: [ID!]) {
        navSalesAnalysis {
            orderStat(filters: { date1: $date1, date2: $date2, primaryUserIds: $primaryUserIds }) {
                ...NavSalesAnalysisOrderStat
            }
            salesStat(filters: { date1: $date1, date2: $date2, primaryUserIds: $primaryUserIds }) {
                ...NavSalesAnalysisSalesStat
            }
            customerRank(
                filters: { date1: $date1, date2: $date2, primaryUserIds: $primaryUserIds }
            ) {
                ...NavSalesAnalysisCustomerRank
            }
            productRank(
                filters: { date1: $date1, date2: $date2, primaryUserIds: $primaryUserIds }
            ) {
                ...NavSalesAnalysisProductRank
            }
        }
    }
    ${NavSalesAnalysisOrderStatFragmentDoc}
    ${NavSalesAnalysisSalesStatFragmentDoc}
    ${NavSalesAnalysisCustomerRankFragmentDoc}
    ${NavSalesAnalysisProductRankFragmentDoc}
`;

/**
 * __useNavSalesAnalysisQuery__
 *
 * To run a query within a React component, call `useNavSalesAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavSalesAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavSalesAnalysisQuery({
 *   variables: {
 *      date1: // value for 'date1'
 *      date2: // value for 'date2'
 *      primaryUserIds: // value for 'primaryUserIds'
 *   },
 * });
 */
export function useNavSalesAnalysisQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        NavSalesAnalysisQuery,
        NavSalesAnalysisQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<NavSalesAnalysisQuery, NavSalesAnalysisQueryVariables>(
        NavSalesAnalysisDocument,
        options
    );
}
export function useNavSalesAnalysisLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        NavSalesAnalysisQuery,
        NavSalesAnalysisQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<NavSalesAnalysisQuery, NavSalesAnalysisQueryVariables>(
        NavSalesAnalysisDocument,
        options
    );
}
export type NavSalesAnalysisQueryHookResult = ReturnType<typeof useNavSalesAnalysisQuery>;
export type NavSalesAnalysisLazyQueryHookResult = ReturnType<typeof useNavSalesAnalysisLazyQuery>;
export type NavSalesAnalysisQueryResult = ApolloReactCommon.QueryResult<
    NavSalesAnalysisQuery,
    NavSalesAnalysisQueryVariables
>;
export const YearSalesAnalysisDocument = gql`
    query yearSalesAnalysis($year: Int!, $primaryUserIds: [ID!], $keyword: String) {
        navSalesAnalysis {
            yearlySalesStatByYear(filters: { year: $year, primaryUserIds: $primaryUserIds }) {
                ...NavSalesAnalysisYearlySalesStatByYear
            }
            yearlySalesStatByUser(
                filters: { year: $year, primaryUserIds: $primaryUserIds, keyword: $keyword }
            ) {
                ...NavSalesAnalysisYearlySalesStatByUser
            }
            yearlySalesStatBySalesTeam(
                filters: { year: $year, primaryUserIds: $primaryUserIds, keyword: $keyword }
            ) {
                ...NavSalesAnalysisYearlySalesStatBySalesTeam
            }
        }
    }
    ${NavSalesAnalysisYearlySalesStatByYearFragmentDoc}
    ${NavSalesAnalysisYearlySalesStatByUserFragmentDoc}
    ${NavSalesAnalysisYearlySalesStatBySalesTeamFragmentDoc}
`;

/**
 * __useYearSalesAnalysisQuery__
 *
 * To run a query within a React component, call `useYearSalesAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useYearSalesAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYearSalesAnalysisQuery({
 *   variables: {
 *      year: // value for 'year'
 *      primaryUserIds: // value for 'primaryUserIds'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useYearSalesAnalysisQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        YearSalesAnalysisQuery,
        YearSalesAnalysisQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<YearSalesAnalysisQuery, YearSalesAnalysisQueryVariables>(
        YearSalesAnalysisDocument,
        options
    );
}
export function useYearSalesAnalysisLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        YearSalesAnalysisQuery,
        YearSalesAnalysisQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<YearSalesAnalysisQuery, YearSalesAnalysisQueryVariables>(
        YearSalesAnalysisDocument,
        options
    );
}
export type YearSalesAnalysisQueryHookResult = ReturnType<typeof useYearSalesAnalysisQuery>;
export type YearSalesAnalysisLazyQueryHookResult = ReturnType<typeof useYearSalesAnalysisLazyQuery>;
export type YearSalesAnalysisQueryResult = ApolloReactCommon.QueryResult<
    YearSalesAnalysisQuery,
    YearSalesAnalysisQueryVariables
>;
export const YearOrderAnalysisDocument = gql`
    query yearOrderAnalysis($year: Int!, $primaryUserIds: [ID!], $keyword: String) {
        navSalesAnalysis {
            yearlyOrderStatByYear(filters: { year: $year, primaryUserIds: $primaryUserIds }) {
                ...NavSalesAnalysisYearlyOrderStatByYear
            }
            yearlyOrderStatByUser(
                filters: { year: $year, primaryUserIds: $primaryUserIds, keyword: $keyword }
            ) {
                ...NavSalesAnalysisYearlyOrderStatByUser
            }
            yearlyOrderStatBySalesTeam(
                filters: { year: $year, primaryUserIds: $primaryUserIds, keyword: $keyword }
            ) {
                ...NavSalesAnalysisYearlyOrderStatBySalesTeam
            }
        }
    }
    ${NavSalesAnalysisYearlyOrderStatByYearFragmentDoc}
    ${NavSalesAnalysisYearlyOrderStatByUserFragmentDoc}
    ${NavSalesAnalysisYearlyOrderStatBySalesTeamFragmentDoc}
`;

/**
 * __useYearOrderAnalysisQuery__
 *
 * To run a query within a React component, call `useYearOrderAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useYearOrderAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYearOrderAnalysisQuery({
 *   variables: {
 *      year: // value for 'year'
 *      primaryUserIds: // value for 'primaryUserIds'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useYearOrderAnalysisQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        YearOrderAnalysisQuery,
        YearOrderAnalysisQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<YearOrderAnalysisQuery, YearOrderAnalysisQueryVariables>(
        YearOrderAnalysisDocument,
        options
    );
}
export function useYearOrderAnalysisLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        YearOrderAnalysisQuery,
        YearOrderAnalysisQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<YearOrderAnalysisQuery, YearOrderAnalysisQueryVariables>(
        YearOrderAnalysisDocument,
        options
    );
}
export type YearOrderAnalysisQueryHookResult = ReturnType<typeof useYearOrderAnalysisQuery>;
export type YearOrderAnalysisLazyQueryHookResult = ReturnType<typeof useYearOrderAnalysisLazyQuery>;
export type YearOrderAnalysisQueryResult = ApolloReactCommon.QueryResult<
    YearOrderAnalysisQuery,
    YearOrderAnalysisQueryVariables
>;
export const SalesPerformanceSalesUserForecastViewDocument = gql`
    query SalesPerformanceSalesUserForecastView(
        $filters: SalesPerformanceSalesUserForecastViewFilterInput!
    ) {
        salesPerformanceSalesUserForecastView(filters: $filters) {
            year
            salesTeamGroup {
                id
                name
            }
            overall {
                months {
                    month
                    types {
                        salesType {
                            id
                            name
                        }
                        quota
                        quotaAchievementRate
                        amountEarlyMonth
                        amountEarlyMonthAccuracyRate
                        amountLateMonth
                        amountLateMonthAccuracyRate
                        amountActual
                    }
                }
            }
            users {
                salesTeamUnit {
                    id
                    name
                }
                months {
                    month
                    types {
                        salesType {
                            id
                            name
                        }
                        quota
                        quotaAchievementRate
                        amountEarlyMonth
                        amountEarlyMonthAccuracyRate
                        amountLateMonth
                        amountLateMonthAccuracyRate
                        amountActual
                    }
                }
            }
        }
    }
`;

/**
 * __useSalesPerformanceSalesUserForecastViewQuery__
 *
 * To run a query within a React component, call `useSalesPerformanceSalesUserForecastViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesPerformanceSalesUserForecastViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesPerformanceSalesUserForecastViewQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSalesPerformanceSalesUserForecastViewQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        SalesPerformanceSalesUserForecastViewQuery,
        SalesPerformanceSalesUserForecastViewQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        SalesPerformanceSalesUserForecastViewQuery,
        SalesPerformanceSalesUserForecastViewQueryVariables
    >(SalesPerformanceSalesUserForecastViewDocument, options);
}
export function useSalesPerformanceSalesUserForecastViewLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        SalesPerformanceSalesUserForecastViewQuery,
        SalesPerformanceSalesUserForecastViewQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        SalesPerformanceSalesUserForecastViewQuery,
        SalesPerformanceSalesUserForecastViewQueryVariables
    >(SalesPerformanceSalesUserForecastViewDocument, options);
}
export type SalesPerformanceSalesUserForecastViewQueryHookResult = ReturnType<
    typeof useSalesPerformanceSalesUserForecastViewQuery
>;
export type SalesPerformanceSalesUserForecastViewLazyQueryHookResult = ReturnType<
    typeof useSalesPerformanceSalesUserForecastViewLazyQuery
>;
export type SalesPerformanceSalesUserForecastViewQueryResult = ApolloReactCommon.QueryResult<
    SalesPerformanceSalesUserForecastViewQuery,
    SalesPerformanceSalesUserForecastViewQueryVariables
>;
export const PaginatedSalesPerformanceSalesTypesDocument = gql`
    query PaginatedSalesPerformanceSalesTypes {
        paginatedSalesPerformanceSalesTypes {
            count
            salesPerformanceSalesTypes {
                id
                name
            }
        }
    }
`;

/**
 * __usePaginatedSalesPerformanceSalesTypesQuery__
 *
 * To run a query within a React component, call `usePaginatedSalesPerformanceSalesTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedSalesPerformanceSalesTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedSalesPerformanceSalesTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaginatedSalesPerformanceSalesTypesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedSalesPerformanceSalesTypesQuery,
        PaginatedSalesPerformanceSalesTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PaginatedSalesPerformanceSalesTypesQuery,
        PaginatedSalesPerformanceSalesTypesQueryVariables
    >(PaginatedSalesPerformanceSalesTypesDocument, options);
}
export function usePaginatedSalesPerformanceSalesTypesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedSalesPerformanceSalesTypesQuery,
        PaginatedSalesPerformanceSalesTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedSalesPerformanceSalesTypesQuery,
        PaginatedSalesPerformanceSalesTypesQueryVariables
    >(PaginatedSalesPerformanceSalesTypesDocument, options);
}
export type PaginatedSalesPerformanceSalesTypesQueryHookResult = ReturnType<
    typeof usePaginatedSalesPerformanceSalesTypesQuery
>;
export type PaginatedSalesPerformanceSalesTypesLazyQueryHookResult = ReturnType<
    typeof usePaginatedSalesPerformanceSalesTypesLazyQuery
>;
export type PaginatedSalesPerformanceSalesTypesQueryResult = ApolloReactCommon.QueryResult<
    PaginatedSalesPerformanceSalesTypesQuery,
    PaginatedSalesPerformanceSalesTypesQueryVariables
>;
export const SalesPerformanceSalesProductUserForecastViewDocument = gql`
    query SalesPerformanceSalesProductUserForecastView(
        $filters: SalesPerformanceSalesProductUserForecastViewFilterInput!
    ) {
        salesPerformanceSalesProductUserForecastView(filters: $filters) {
            year
            salesTeamGroup {
                id
                name
            }
            overall {
                months {
                    month
                    types {
                        salesProductType {
                            id
                            name
                        }
                        quota
                        quotaAchievementRate
                        qtyEarlyMonth
                        qtyEarlyMonthAccuracyRate
                        qtyLateMonth
                        qtyLateMonthAccuracyRate
                        qtyActual
                    }
                }
            }
            users {
                salesTeamUnit {
                    id
                    name
                }
                months {
                    month
                    types {
                        salesProductType {
                            id
                            name
                        }
                        quota
                        quotaAchievementRate
                        qtyEarlyMonth
                        qtyEarlyMonthAccuracyRate
                        qtyLateMonth
                        qtyLateMonthAccuracyRate
                        qtyActual
                    }
                }
            }
        }
    }
`;

/**
 * __useSalesPerformanceSalesProductUserForecastViewQuery__
 *
 * To run a query within a React component, call `useSalesPerformanceSalesProductUserForecastViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesPerformanceSalesProductUserForecastViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesPerformanceSalesProductUserForecastViewQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSalesPerformanceSalesProductUserForecastViewQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        SalesPerformanceSalesProductUserForecastViewQuery,
        SalesPerformanceSalesProductUserForecastViewQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        SalesPerformanceSalesProductUserForecastViewQuery,
        SalesPerformanceSalesProductUserForecastViewQueryVariables
    >(SalesPerformanceSalesProductUserForecastViewDocument, options);
}
export function useSalesPerformanceSalesProductUserForecastViewLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        SalesPerformanceSalesProductUserForecastViewQuery,
        SalesPerformanceSalesProductUserForecastViewQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        SalesPerformanceSalesProductUserForecastViewQuery,
        SalesPerformanceSalesProductUserForecastViewQueryVariables
    >(SalesPerformanceSalesProductUserForecastViewDocument, options);
}
export type SalesPerformanceSalesProductUserForecastViewQueryHookResult = ReturnType<
    typeof useSalesPerformanceSalesProductUserForecastViewQuery
>;
export type SalesPerformanceSalesProductUserForecastViewLazyQueryHookResult = ReturnType<
    typeof useSalesPerformanceSalesProductUserForecastViewLazyQuery
>;
export type SalesPerformanceSalesProductUserForecastViewQueryResult = ApolloReactCommon.QueryResult<
    SalesPerformanceSalesProductUserForecastViewQuery,
    SalesPerformanceSalesProductUserForecastViewQueryVariables
>;
export const PaginatedSalesPerformanceSalesProductTypesDocument = gql`
    query paginatedSalesPerformanceSalesProductTypes {
        paginatedSalesPerformanceSalesProductTypes {
            count
            salesPerformanceSalesProductTypes {
                id
                name
            }
        }
    }
`;

/**
 * __usePaginatedSalesPerformanceSalesProductTypesQuery__
 *
 * To run a query within a React component, call `usePaginatedSalesPerformanceSalesProductTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedSalesPerformanceSalesProductTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedSalesPerformanceSalesProductTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaginatedSalesPerformanceSalesProductTypesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedSalesPerformanceSalesProductTypesQuery,
        PaginatedSalesPerformanceSalesProductTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PaginatedSalesPerformanceSalesProductTypesQuery,
        PaginatedSalesPerformanceSalesProductTypesQueryVariables
    >(PaginatedSalesPerformanceSalesProductTypesDocument, options);
}
export function usePaginatedSalesPerformanceSalesProductTypesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedSalesPerformanceSalesProductTypesQuery,
        PaginatedSalesPerformanceSalesProductTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedSalesPerformanceSalesProductTypesQuery,
        PaginatedSalesPerformanceSalesProductTypesQueryVariables
    >(PaginatedSalesPerformanceSalesProductTypesDocument, options);
}
export type PaginatedSalesPerformanceSalesProductTypesQueryHookResult = ReturnType<
    typeof usePaginatedSalesPerformanceSalesProductTypesQuery
>;
export type PaginatedSalesPerformanceSalesProductTypesLazyQueryHookResult = ReturnType<
    typeof usePaginatedSalesPerformanceSalesProductTypesLazyQuery
>;
export type PaginatedSalesPerformanceSalesProductTypesQueryResult = ApolloReactCommon.QueryResult<
    PaginatedSalesPerformanceSalesProductTypesQuery,
    PaginatedSalesPerformanceSalesProductTypesQueryVariables
>;
export const SalesPerformanceSalesProductTypeGroupsDocument = gql`
    query SalesPerformanceSalesProductTypeGroups {
        salesPerformanceSalesProductTypeGroups {
            id
            name
        }
    }
`;

/**
 * __useSalesPerformanceSalesProductTypeGroupsQuery__
 *
 * To run a query within a React component, call `useSalesPerformanceSalesProductTypeGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesPerformanceSalesProductTypeGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesPerformanceSalesProductTypeGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSalesPerformanceSalesProductTypeGroupsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        SalesPerformanceSalesProductTypeGroupsQuery,
        SalesPerformanceSalesProductTypeGroupsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        SalesPerformanceSalesProductTypeGroupsQuery,
        SalesPerformanceSalesProductTypeGroupsQueryVariables
    >(SalesPerformanceSalesProductTypeGroupsDocument, options);
}
export function useSalesPerformanceSalesProductTypeGroupsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        SalesPerformanceSalesProductTypeGroupsQuery,
        SalesPerformanceSalesProductTypeGroupsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        SalesPerformanceSalesProductTypeGroupsQuery,
        SalesPerformanceSalesProductTypeGroupsQueryVariables
    >(SalesPerformanceSalesProductTypeGroupsDocument, options);
}
export type SalesPerformanceSalesProductTypeGroupsQueryHookResult = ReturnType<
    typeof useSalesPerformanceSalesProductTypeGroupsQuery
>;
export type SalesPerformanceSalesProductTypeGroupsLazyQueryHookResult = ReturnType<
    typeof useSalesPerformanceSalesProductTypeGroupsLazyQuery
>;
export type SalesPerformanceSalesProductTypeGroupsQueryResult = ApolloReactCommon.QueryResult<
    SalesPerformanceSalesProductTypeGroupsQuery,
    SalesPerformanceSalesProductTypeGroupsQueryVariables
>;
export const SalesProductTypeSalesAnalysisDocument = gql`
    query SalesProductTypeSalesAnalysis(
        $filters: SalesProductTypeSalesAnalysisSalesStatFilterInput
    ) {
        salesProductTypeSalesAnalysis {
            salesStat(filters: $filters) {
                details {
                    salesProductTypeGroupId
                    salesProductTypeGroup {
                        id
                        name
                    }
                    types {
                        salesProductTypeId
                        salesProductType {
                            id
                            name
                        }
                        amountGoal
                        amountForecastGoal
                        amountCurrent
                        amountMom
                        amountMomGrowthRate
                        amountYoy
                        amountYoyGrowthRate
                        amountYtd
                        amountLastYearYtd
                        amountLastYearYtdGrowthRate
                        qtyGoal
                        qtyForecastGoal
                        qtyCurrent
                        qtyMom
                        qtyMomGrowthRate
                        qtyYoy
                        qtyYoyGrowthRate
                        qtyLastYearYtd
                        qtyLastYearYtdGrowthRate
                    }
                }
                dateRange {
                    currentDate1
                    currentDate2
                    yoyDate1
                    yoyDate2
                    momDate1
                    momDate2
                    ytdDate1
                    ytdDate2
                    lastYearYtdDate1
                    lastYearYtdDate2
                }
            }
        }
    }
`;

/**
 * __useSalesProductTypeSalesAnalysisQuery__
 *
 * To run a query within a React component, call `useSalesProductTypeSalesAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesProductTypeSalesAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesProductTypeSalesAnalysisQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSalesProductTypeSalesAnalysisQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        SalesProductTypeSalesAnalysisQuery,
        SalesProductTypeSalesAnalysisQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        SalesProductTypeSalesAnalysisQuery,
        SalesProductTypeSalesAnalysisQueryVariables
    >(SalesProductTypeSalesAnalysisDocument, options);
}
export function useSalesProductTypeSalesAnalysisLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        SalesProductTypeSalesAnalysisQuery,
        SalesProductTypeSalesAnalysisQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        SalesProductTypeSalesAnalysisQuery,
        SalesProductTypeSalesAnalysisQueryVariables
    >(SalesProductTypeSalesAnalysisDocument, options);
}
export type SalesProductTypeSalesAnalysisQueryHookResult = ReturnType<
    typeof useSalesProductTypeSalesAnalysisQuery
>;
export type SalesProductTypeSalesAnalysisLazyQueryHookResult = ReturnType<
    typeof useSalesProductTypeSalesAnalysisLazyQuery
>;
export type SalesProductTypeSalesAnalysisQueryResult = ApolloReactCommon.QueryResult<
    SalesProductTypeSalesAnalysisQuery,
    SalesProductTypeSalesAnalysisQueryVariables
>;
export const SalesPerformanceSalesProductOverallViewDocument = gql`
    query SalesPerformanceSalesProductOverallView(
        $filters: SalesPerformanceSalesProductOverallViewFilterInput!
    ) {
        salesPerformanceSalesProductOverallView(filters: $filters) {
            year
            salesTeamGroup {
                id
                code
                name
            }
            overall {
                salesAmount
                salesUnitPrice
                salesQty
                forecastAmount
                forecastQty
                forecastUnitPrice
                goalAmount
                goalAmountAchievementRate
                goalUnitPrice
                goalUnitPriceAchievementRate
                goalQty
                goalQtyAchievementRate
                ytdCurrentAmount
                ytdCurrentUnitPrice
                ytdCurrentQty
                ytdGoalAmount
                ytdGoalAmountAchievementRate
                ytdGoalUnitPrice
                ytdGoalUnitPriceAchievementRate
                ytdGoalQty
                ytdGoalQtyAchievementRate
                months {
                    salesAmount
                    salesUnitPrice
                    salesQty
                    forecastAmount
                    forecastQty
                    forecastUnitPrice
                    goalAmount
                    goalUnitPrice
                    goalQty
                }
            }
            types {
                salesProductType {
                    id
                    code
                    name
                }
                salesAmount
                salesUnitPrice
                salesQty
                forecastAmount
                forecastQty
                forecastUnitPrice
                goalAmount
                goalAmountAchievementRate
                goalUnitPrice
                goalUnitPriceAchievementRate
                goalQty
                goalQtyAchievementRate
                ytdCurrentAmount
                ytdCurrentUnitPrice
                ytdCurrentQty
                ytdGoalAmount
                ytdGoalAmountAchievementRate
                ytdGoalUnitPrice
                ytdGoalUnitPriceAchievementRate
                ytdGoalQty
                ytdGoalQtyAchievementRate
                months {
                    month
                    salesAmount
                    salesUnitPrice
                    salesQty
                    forecastAmount
                    forecastQty
                    forecastUnitPrice
                    goalAmount
                    goalUnitPrice
                    goalQty
                }
            }
        }
    }
`;

/**
 * __useSalesPerformanceSalesProductOverallViewQuery__
 *
 * To run a query within a React component, call `useSalesPerformanceSalesProductOverallViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesPerformanceSalesProductOverallViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesPerformanceSalesProductOverallViewQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSalesPerformanceSalesProductOverallViewQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        SalesPerformanceSalesProductOverallViewQuery,
        SalesPerformanceSalesProductOverallViewQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        SalesPerformanceSalesProductOverallViewQuery,
        SalesPerformanceSalesProductOverallViewQueryVariables
    >(SalesPerformanceSalesProductOverallViewDocument, options);
}
export function useSalesPerformanceSalesProductOverallViewLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        SalesPerformanceSalesProductOverallViewQuery,
        SalesPerformanceSalesProductOverallViewQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        SalesPerformanceSalesProductOverallViewQuery,
        SalesPerformanceSalesProductOverallViewQueryVariables
    >(SalesPerformanceSalesProductOverallViewDocument, options);
}
export type SalesPerformanceSalesProductOverallViewQueryHookResult = ReturnType<
    typeof useSalesPerformanceSalesProductOverallViewQuery
>;
export type SalesPerformanceSalesProductOverallViewLazyQueryHookResult = ReturnType<
    typeof useSalesPerformanceSalesProductOverallViewLazyQuery
>;
export type SalesPerformanceSalesProductOverallViewQueryResult = ApolloReactCommon.QueryResult<
    SalesPerformanceSalesProductOverallViewQuery,
    SalesPerformanceSalesProductOverallViewQueryVariables
>;
export const EcDashboardAnalysisDocument = gql`
    query EcDashboardAnalysis(
        $filters: EcDashboardAnalysisSalesCustomerRankFilterInput
        $salesMaterialRankFilters2: EcDashboardAnalysisSalesMaterialRankFilterInput
        $visitCustomerRankFilters2: EcDashboardAnalysisVisitCustomerRankFilterInput
        $visitGoalRankFilters2: EcDashboardAnalysisVisitGoalRankFilterInput
        $visitMentionProductRankFilters2: EcDashboardAnalysisVisitMentionProductRankFilterInput
        $visitsByDayOfWeekFilters2: EcDashboardAnalysisVisitByDayOfWeekFilterInput
        $visitStatFilters2: EcDashboardAnalysisVisitStatFilterInput
        $salesStatFilters2: EcDashboardAnalysisSalesStatFilterInput
    ) {
        EcDashboardAnalysis {
            salesCustomerRank(filters: $filters) {
                dateRange {
                    currentDate1
                    currentDate2
                    yoyDate1
                    yoyDate2
                    lastYearYoyDate1
                    lastYearYoyDate2
                }
                details {
                    seq
                    rank
                    customerName
                    customerCode
                    visitCurrent
                    visitYoy
                    visitLastYearYoy
                    amountCurrent
                    amountYoy
                    amountLastYearYoy
                    qtyCurrent
                    qtyYoy
                    qtyLastYearYoy
                }
            }
            salesMaterialRank(filters: $salesMaterialRankFilters2) {
                dateRange {
                    currentDate1
                    currentDate2
                    yoyDate1
                    yoyDate2
                    lastYearYoyDate1
                    lastYearYoyDate2
                }
                details {
                    seq
                    rank
                    materialName
                    materialCode
                    amountCurrent
                    amountYoy
                    amountLastYearYoy
                    qtyCurrent
                    qtyYoy
                    qtyLastYearYoy
                }
            }
            visitCustomerRank(filters: $visitCustomerRankFilters2) {
                dateRange {
                    currentDate1
                    yoyDate1
                }
                details {
                    seq
                    rank
                    customerId
                    customer {
                        id
                        name
                    }
                    visitCountCurrent
                    visitCountYoy
                }
            }
            visitGoalRank(filters: $visitGoalRankFilters2) {
                dateRange {
                    currentDate1
                    yoyDate1
                }
                details {
                    seq
                    rank
                    goalId
                    goal {
                        id
                        name
                    }
                    visitCountCurrent
                    visitCountYoy
                }
            }
            visitMentionProductRank(filters: $visitMentionProductRankFilters2) {
                dateRange {
                    currentDate1
                    yoyDate1
                }
                details {
                    seq
                    rank
                    mentionProductId
                    mentionProduct {
                        id
                        name
                    }
                    visitCountCurrent
                    visitCountYoy
                }
            }
            visitsByDayOfWeek(filters: $visitsByDayOfWeekFilters2) {
                details {
                    dayOfWeek
                    visitCountCurrent
                }
            }
            visitStat(filters: $visitStatFilters2) {
                dateRange {
                    currentDate1
                    currentDate2
                    lastYearYtdDate1
                    lastYearYtdDate2
                    momDate1
                    momDate2
                    yoyDate1
                    yoyDate2
                    ytdDate1
                    ytdDate2
                }
                overall {
                    visitCountCurrent
                    visitCountMom
                    visitCountMomGrowthRate
                    visitCountYoy
                    visitCountYoyGrowthRate
                    visitCountYtd
                    visitCountLastYearYtd
                    visitCountLastYearYtdGrowthRate
                }
            }
            salesStat(filters: $salesStatFilters2) {
                salesTeamGroupId
                dateRange {
                    currentDate1
                    currentDate2
                    lastYearYtdDate1
                    lastYearYtdDate2
                    momDate1
                    momDate2
                    yoyDate1
                    yoyDate2
                    ytdDate1
                    ytdDate2
                }
                details {
                    salesTypeId
                    salesType {
                        id
                        code
                        name
                    }
                    amountCurrent
                    amountGoalCurrent
                    amountGoalCurrentAchievementRate
                    amountMom
                    amountMomGrowthRate
                    amountYoy
                    amountYoyGrowthRate
                    amountYtd
                    amountLastYearYtd
                    amountLastYearYtdGrowthRate
                }
            }
        }
    }
`;

/**
 * __useEcDashboardAnalysisQuery__
 *
 * To run a query within a React component, call `useEcDashboardAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcDashboardAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcDashboardAnalysisQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      salesMaterialRankFilters2: // value for 'salesMaterialRankFilters2'
 *      visitCustomerRankFilters2: // value for 'visitCustomerRankFilters2'
 *      visitGoalRankFilters2: // value for 'visitGoalRankFilters2'
 *      visitMentionProductRankFilters2: // value for 'visitMentionProductRankFilters2'
 *      visitsByDayOfWeekFilters2: // value for 'visitsByDayOfWeekFilters2'
 *      visitStatFilters2: // value for 'visitStatFilters2'
 *      salesStatFilters2: // value for 'salesStatFilters2'
 *   },
 * });
 */
export function useEcDashboardAnalysisQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        EcDashboardAnalysisQuery,
        EcDashboardAnalysisQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<EcDashboardAnalysisQuery, EcDashboardAnalysisQueryVariables>(
        EcDashboardAnalysisDocument,
        options
    );
}
export function useEcDashboardAnalysisLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EcDashboardAnalysisQuery,
        EcDashboardAnalysisQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        EcDashboardAnalysisQuery,
        EcDashboardAnalysisQueryVariables
    >(EcDashboardAnalysisDocument, options);
}
export type EcDashboardAnalysisQueryHookResult = ReturnType<typeof useEcDashboardAnalysisQuery>;
export type EcDashboardAnalysisLazyQueryHookResult = ReturnType<
    typeof useEcDashboardAnalysisLazyQuery
>;
export type EcDashboardAnalysisQueryResult = ApolloReactCommon.QueryResult<
    EcDashboardAnalysisQuery,
    EcDashboardAnalysisQueryVariables
>;
export const EcDashboardAnalysisDetailDocument = gql`
    query EcDashboardAnalysisDetail($filters: EcDashboardAnalysisSalesDetailFilterInput!) {
        EcDashboardAnalysisDetail(filters: $filters) {
            salesTeamGroup {
                id
                name
            }
            salesType {
                id
                name
            }
            details {
                key
                companyCode
                salesType
                salesOrderCode
                salesDate
                salesCustomerCode
                salesCustomerName
                accountCustomerCode
                accountCustomerName
                materialCode
                materialName
                qty
                untaxedAmount
                taxedAmount
                userCode
                userName
            }
        }
    }
`;

/**
 * __useEcDashboardAnalysisDetailQuery__
 *
 * To run a query within a React component, call `useEcDashboardAnalysisDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcDashboardAnalysisDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcDashboardAnalysisDetailQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEcDashboardAnalysisDetailQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        EcDashboardAnalysisDetailQuery,
        EcDashboardAnalysisDetailQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        EcDashboardAnalysisDetailQuery,
        EcDashboardAnalysisDetailQueryVariables
    >(EcDashboardAnalysisDetailDocument, options);
}
export function useEcDashboardAnalysisDetailLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EcDashboardAnalysisDetailQuery,
        EcDashboardAnalysisDetailQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        EcDashboardAnalysisDetailQuery,
        EcDashboardAnalysisDetailQueryVariables
    >(EcDashboardAnalysisDetailDocument, options);
}
export type EcDashboardAnalysisDetailQueryHookResult = ReturnType<
    typeof useEcDashboardAnalysisDetailQuery
>;
export type EcDashboardAnalysisDetailLazyQueryHookResult = ReturnType<
    typeof useEcDashboardAnalysisDetailLazyQuery
>;
export type EcDashboardAnalysisDetailQueryResult = ApolloReactCommon.QueryResult<
    EcDashboardAnalysisDetailQuery,
    EcDashboardAnalysisDetailQueryVariables
>;
export const PaginatedEyeProductsDocument = gql`
    query paginatedEyeProducts($pagination: PaginationInput, $filters: EyeProductFilterInput) {
        paginatedEyeProducts(pagination: $pagination, filters: $filters) {
            count
            eyeProducts {
                ...eyeProductsInfo
            }
        }
    }
    ${EyeProductsInfoFragmentDoc}
`;

/**
 * __usePaginatedEyeProductsQuery__
 *
 * To run a query within a React component, call `usePaginatedEyeProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedEyeProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedEyeProductsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedEyeProductsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedEyeProductsQuery,
        PaginatedEyeProductsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PaginatedEyeProductsQuery, PaginatedEyeProductsQueryVariables>(
        PaginatedEyeProductsDocument,
        options
    );
}
export function usePaginatedEyeProductsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedEyeProductsQuery,
        PaginatedEyeProductsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedEyeProductsQuery,
        PaginatedEyeProductsQueryVariables
    >(PaginatedEyeProductsDocument, options);
}
export type PaginatedEyeProductsQueryHookResult = ReturnType<typeof usePaginatedEyeProductsQuery>;
export type PaginatedEyeProductsLazyQueryHookResult = ReturnType<
    typeof usePaginatedEyeProductsLazyQuery
>;
export type PaginatedEyeProductsQueryResult = ApolloReactCommon.QueryResult<
    PaginatedEyeProductsQuery,
    PaginatedEyeProductsQueryVariables
>;
export const PaginatedEyeProductTypesDocument = gql`
    query paginatedEyeProductTypes(
        $pagination: PaginationInput
        $filters: EyeProductTypeFilterInput
    ) {
        paginatedEyeProductTypes(pagination: $pagination, filters: $filters) {
            count
            eyeProductTypes {
                id
                name
            }
        }
    }
`;

/**
 * __usePaginatedEyeProductTypesQuery__
 *
 * To run a query within a React component, call `usePaginatedEyeProductTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedEyeProductTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedEyeProductTypesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedEyeProductTypesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedEyeProductTypesQuery,
        PaginatedEyeProductTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PaginatedEyeProductTypesQuery,
        PaginatedEyeProductTypesQueryVariables
    >(PaginatedEyeProductTypesDocument, options);
}
export function usePaginatedEyeProductTypesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedEyeProductTypesQuery,
        PaginatedEyeProductTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedEyeProductTypesQuery,
        PaginatedEyeProductTypesQueryVariables
    >(PaginatedEyeProductTypesDocument, options);
}
export type PaginatedEyeProductTypesQueryHookResult = ReturnType<
    typeof usePaginatedEyeProductTypesQuery
>;
export type PaginatedEyeProductTypesLazyQueryHookResult = ReturnType<
    typeof usePaginatedEyeProductTypesLazyQuery
>;
export type PaginatedEyeProductTypesQueryResult = ApolloReactCommon.QueryResult<
    PaginatedEyeProductTypesQuery,
    PaginatedEyeProductTypesQueryVariables
>;
export const EyeProductDocument = gql`
    query eyeProduct($id: ID!) {
        eyeProduct(id: $id) {
            ...eyeProductsInfo
        }
    }
    ${EyeProductsInfoFragmentDoc}
`;

/**
 * __useEyeProductQuery__
 *
 * To run a query within a React component, call `useEyeProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEyeProductQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<EyeProductQuery, EyeProductQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<EyeProductQuery, EyeProductQueryVariables>(
        EyeProductDocument,
        options
    );
}
export function useEyeProductLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EyeProductQuery, EyeProductQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<EyeProductQuery, EyeProductQueryVariables>(
        EyeProductDocument,
        options
    );
}
export type EyeProductQueryHookResult = ReturnType<typeof useEyeProductQuery>;
export type EyeProductLazyQueryHookResult = ReturnType<typeof useEyeProductLazyQuery>;
export type EyeProductQueryResult = ApolloReactCommon.QueryResult<
    EyeProductQuery,
    EyeProductQueryVariables
>;
export const PaginatedEyePromotionsDocument = gql`
    query paginatedEyePromotions($pagination: PaginationInput, $filters: EyePromotionFilterInput) {
        paginatedEyePromotions(pagination: $pagination, filters: $filters) {
            count
            eyePromotions {
                id
                name
                region {
                    id
                    name
                }
                isAddon
                discountRate
                discountAmount
                date1
                date2
                description
                eyeProducts {
                    id
                    name
                }
                eyePromotionAddonProductItems {
                    id
                    isOptional
                    material {
                        id
                    }
                    qty
                }
                eyeProducts {
                    id
                }
            }
        }
    }
`;

/**
 * __usePaginatedEyePromotionsQuery__
 *
 * To run a query within a React component, call `usePaginatedEyePromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedEyePromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedEyePromotionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedEyePromotionsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedEyePromotionsQuery,
        PaginatedEyePromotionsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PaginatedEyePromotionsQuery,
        PaginatedEyePromotionsQueryVariables
    >(PaginatedEyePromotionsDocument, options);
}
export function usePaginatedEyePromotionsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedEyePromotionsQuery,
        PaginatedEyePromotionsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedEyePromotionsQuery,
        PaginatedEyePromotionsQueryVariables
    >(PaginatedEyePromotionsDocument, options);
}
export type PaginatedEyePromotionsQueryHookResult = ReturnType<
    typeof usePaginatedEyePromotionsQuery
>;
export type PaginatedEyePromotionsLazyQueryHookResult = ReturnType<
    typeof usePaginatedEyePromotionsLazyQuery
>;
export type PaginatedEyePromotionsQueryResult = ApolloReactCommon.QueryResult<
    PaginatedEyePromotionsQuery,
    PaginatedEyePromotionsQueryVariables
>;
export const EyePromotionDocument = gql`
    query eyePromotion($id: ID!) {
        eyePromotion(id: $id) {
            id
            name
            region {
                id
                name
            }
            isAddon
            discountRate
            discountAmount
            date1
            date2
            description
            eyeProducts {
                id
                eyeProductType {
                    id
                    regionId
                    name
                    createdAt
                    updatedAt
                }
                name
                salePrice
                status
                description
                currencyId
                model
                regionId
                brand
                createdAt
                updatedAt
                eyeProductTypeId
                eyeProductItems {
                    id
                    eyeProductId
                    eyeProductItemTypeId
                    qty
                    isOptional
                    createdAt
                    material {
                        id
                        code
                        name
                        model
                        spec
                        unit
                    }
                }
                eyePromotions {
                    id
                    name
                    isAddon
                    discountRate
                    discountAmount
                    createdAt
                    date1
                    regionId
                    updatedAt
                    currencyId
                    eyePromotionAddonProductItems {
                        id
                        qty
                        isOptional
                        material {
                            id
                            unit
                        }
                    }
                }
            }
            eyePromotionAddonProductItems {
                id
                isOptional
                material {
                    id
                }
                qty
            }
            currency {
                id
                name
            }
        }
    }
`;

/**
 * __useEyePromotionQuery__
 *
 * To run a query within a React component, call `useEyePromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyePromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyePromotionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEyePromotionQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<EyePromotionQuery, EyePromotionQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<EyePromotionQuery, EyePromotionQueryVariables>(
        EyePromotionDocument,
        options
    );
}
export function useEyePromotionLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EyePromotionQuery,
        EyePromotionQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<EyePromotionQuery, EyePromotionQueryVariables>(
        EyePromotionDocument,
        options
    );
}
export type EyePromotionQueryHookResult = ReturnType<typeof useEyePromotionQuery>;
export type EyePromotionLazyQueryHookResult = ReturnType<typeof useEyePromotionLazyQuery>;
export type EyePromotionQueryResult = ApolloReactCommon.QueryResult<
    EyePromotionQuery,
    EyePromotionQueryVariables
>;
export const CreateEyeQuotationOrderDocument = gql`
    mutation createEyeQuotationOrder($input: EyeQuotationOrderCreateInput!) {
        createEyeQuotationOrder(input: $input) {
            id
            code
        }
    }
`;
export type CreateEyeQuotationOrderMutationFn = ApolloReactCommon.MutationFunction<
    CreateEyeQuotationOrderMutation,
    CreateEyeQuotationOrderMutationVariables
>;

/**
 * __useCreateEyeQuotationOrderMutation__
 *
 * To run a mutation, you first call `useCreateEyeQuotationOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEyeQuotationOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEyeQuotationOrderMutation, { data, loading, error }] = useCreateEyeQuotationOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEyeQuotationOrderMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateEyeQuotationOrderMutation,
        CreateEyeQuotationOrderMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateEyeQuotationOrderMutation,
        CreateEyeQuotationOrderMutationVariables
    >(CreateEyeQuotationOrderDocument, options);
}
export type CreateEyeQuotationOrderMutationHookResult = ReturnType<
    typeof useCreateEyeQuotationOrderMutation
>;
export type CreateEyeQuotationOrderMutationResult =
    ApolloReactCommon.MutationResult<CreateEyeQuotationOrderMutation>;
export type CreateEyeQuotationOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateEyeQuotationOrderMutation,
    CreateEyeQuotationOrderMutationVariables
>;
export const RequestEyeQuotationOrderOfficialSealDocument = gql`
    mutation RequestEyeQuotationOrderOfficialSeal(
        $input: RequestEyeQuotationOrderOfficialSealInput!
    ) {
        requestEyeQuotationOrderOfficialSeal(input: $input)
    }
`;
export type RequestEyeQuotationOrderOfficialSealMutationFn = ApolloReactCommon.MutationFunction<
    RequestEyeQuotationOrderOfficialSealMutation,
    RequestEyeQuotationOrderOfficialSealMutationVariables
>;

/**
 * __useRequestEyeQuotationOrderOfficialSealMutation__
 *
 * To run a mutation, you first call `useRequestEyeQuotationOrderOfficialSealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestEyeQuotationOrderOfficialSealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestEyeQuotationOrderOfficialSealMutation, { data, loading, error }] = useRequestEyeQuotationOrderOfficialSealMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestEyeQuotationOrderOfficialSealMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        RequestEyeQuotationOrderOfficialSealMutation,
        RequestEyeQuotationOrderOfficialSealMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        RequestEyeQuotationOrderOfficialSealMutation,
        RequestEyeQuotationOrderOfficialSealMutationVariables
    >(RequestEyeQuotationOrderOfficialSealDocument, options);
}
export type RequestEyeQuotationOrderOfficialSealMutationHookResult = ReturnType<
    typeof useRequestEyeQuotationOrderOfficialSealMutation
>;
export type RequestEyeQuotationOrderOfficialSealMutationResult =
    ApolloReactCommon.MutationResult<RequestEyeQuotationOrderOfficialSealMutation>;
export type RequestEyeQuotationOrderOfficialSealMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        RequestEyeQuotationOrderOfficialSealMutation,
        RequestEyeQuotationOrderOfficialSealMutationVariables
    >;
export const PaginatedEyeQuotationOrdersDocument = gql`
    query paginatedEyeQuotationOrders(
        $pagination: PaginationInput
        $filters: EyeQuotationOrderFilterInput
    ) {
        paginatedEyeQuotationOrders(pagination: $pagination, filters: $filters) {
            count
            eyeQuotationOrders {
                ...EyeQuotationOrder
            }
        }
    }
    ${EyeQuotationOrderFragmentDoc}
`;

/**
 * __usePaginatedEyeQuotationOrdersQuery__
 *
 * To run a query within a React component, call `usePaginatedEyeQuotationOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedEyeQuotationOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedEyeQuotationOrdersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedEyeQuotationOrdersQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedEyeQuotationOrdersQuery,
        PaginatedEyeQuotationOrdersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PaginatedEyeQuotationOrdersQuery,
        PaginatedEyeQuotationOrdersQueryVariables
    >(PaginatedEyeQuotationOrdersDocument, options);
}
export function usePaginatedEyeQuotationOrdersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedEyeQuotationOrdersQuery,
        PaginatedEyeQuotationOrdersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedEyeQuotationOrdersQuery,
        PaginatedEyeQuotationOrdersQueryVariables
    >(PaginatedEyeQuotationOrdersDocument, options);
}
export type PaginatedEyeQuotationOrdersQueryHookResult = ReturnType<
    typeof usePaginatedEyeQuotationOrdersQuery
>;
export type PaginatedEyeQuotationOrdersLazyQueryHookResult = ReturnType<
    typeof usePaginatedEyeQuotationOrdersLazyQuery
>;
export type PaginatedEyeQuotationOrdersQueryResult = ApolloReactCommon.QueryResult<
    PaginatedEyeQuotationOrdersQuery,
    PaginatedEyeQuotationOrdersQueryVariables
>;
export const EyeQuotationOrderDocument = gql`
    query eyeQuotationOrder($id: ID!) {
        eyeQuotationOrder(id: $id) {
            ...EyeQuotationOrder
        }
    }
    ${EyeQuotationOrderFragmentDoc}
`;

/**
 * __useEyeQuotationOrderQuery__
 *
 * To run a query within a React component, call `useEyeQuotationOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeQuotationOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeQuotationOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEyeQuotationOrderQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        EyeQuotationOrderQuery,
        EyeQuotationOrderQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<EyeQuotationOrderQuery, EyeQuotationOrderQueryVariables>(
        EyeQuotationOrderDocument,
        options
    );
}
export function useEyeQuotationOrderLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EyeQuotationOrderQuery,
        EyeQuotationOrderQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<EyeQuotationOrderQuery, EyeQuotationOrderQueryVariables>(
        EyeQuotationOrderDocument,
        options
    );
}
export type EyeQuotationOrderQueryHookResult = ReturnType<typeof useEyeQuotationOrderQuery>;
export type EyeQuotationOrderLazyQueryHookResult = ReturnType<typeof useEyeQuotationOrderLazyQuery>;
export type EyeQuotationOrderQueryResult = ApolloReactCommon.QueryResult<
    EyeQuotationOrderQuery,
    EyeQuotationOrderQueryVariables
>;
export const QuotatioProductDocument = gql`
    query quotatioProduct($id: ID!) {
        eyeProduct(id: $id) {
            ...Quotation_Product
        }
    }
    ${Quotation_ProductFragmentDoc}
`;

/**
 * __useQuotatioProductQuery__
 *
 * To run a query within a React component, call `useQuotatioProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotatioProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotatioProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuotatioProductQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        QuotatioProductQuery,
        QuotatioProductQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<QuotatioProductQuery, QuotatioProductQueryVariables>(
        QuotatioProductDocument,
        options
    );
}
export function useQuotatioProductLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        QuotatioProductQuery,
        QuotatioProductQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<QuotatioProductQuery, QuotatioProductQueryVariables>(
        QuotatioProductDocument,
        options
    );
}
export type QuotatioProductQueryHookResult = ReturnType<typeof useQuotatioProductQuery>;
export type QuotatioProductLazyQueryHookResult = ReturnType<typeof useQuotatioProductLazyQuery>;
export type QuotatioProductQueryResult = ApolloReactCommon.QueryResult<
    QuotatioProductQuery,
    QuotatioProductQueryVariables
>;
export const QuotatioProductsDocument = gql`
    query quotatioProducts($pagination: PaginationInput, $filters: EyeProductFilterInput) {
        paginatedEyeProducts(pagination: $pagination, filters: $filters) {
            count
            eyeProducts {
                ...Quotation_Product
            }
        }
    }
    ${Quotation_ProductFragmentDoc}
`;

/**
 * __useQuotatioProductsQuery__
 *
 * To run a query within a React component, call `useQuotatioProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotatioProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotatioProductsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useQuotatioProductsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        QuotatioProductsQuery,
        QuotatioProductsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<QuotatioProductsQuery, QuotatioProductsQueryVariables>(
        QuotatioProductsDocument,
        options
    );
}
export function useQuotatioProductsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        QuotatioProductsQuery,
        QuotatioProductsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<QuotatioProductsQuery, QuotatioProductsQueryVariables>(
        QuotatioProductsDocument,
        options
    );
}
export type QuotatioProductsQueryHookResult = ReturnType<typeof useQuotatioProductsQuery>;
export type QuotatioProductsLazyQueryHookResult = ReturnType<typeof useQuotatioProductsLazyQuery>;
export type QuotatioProductsQueryResult = ApolloReactCommon.QueryResult<
    QuotatioProductsQuery,
    QuotatioProductsQueryVariables
>;
export const QuotatioMaterialDocument = gql`
    query quotatioMaterial($id: ID!) {
        material(id: $id) {
            ...Quotation_Material
        }
    }
    ${Quotation_MaterialFragmentDoc}
`;

/**
 * __useQuotatioMaterialQuery__
 *
 * To run a query within a React component, call `useQuotatioMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotatioMaterialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotatioMaterialQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuotatioMaterialQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        QuotatioMaterialQuery,
        QuotatioMaterialQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<QuotatioMaterialQuery, QuotatioMaterialQueryVariables>(
        QuotatioMaterialDocument,
        options
    );
}
export function useQuotatioMaterialLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        QuotatioMaterialQuery,
        QuotatioMaterialQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<QuotatioMaterialQuery, QuotatioMaterialQueryVariables>(
        QuotatioMaterialDocument,
        options
    );
}
export type QuotatioMaterialQueryHookResult = ReturnType<typeof useQuotatioMaterialQuery>;
export type QuotatioMaterialLazyQueryHookResult = ReturnType<typeof useQuotatioMaterialLazyQuery>;
export type QuotatioMaterialQueryResult = ApolloReactCommon.QueryResult<
    QuotatioMaterialQuery,
    QuotatioMaterialQueryVariables
>;
export const QuotatioMaterialsDocument = gql`
    query quotatioMaterials($pagination: PaginationInput, $filters: MaterialFilterInput) {
        paginatedMaterials(pagination: $pagination, filters: $filters) {
            count
            materials {
                ...Quotation_Material
            }
        }
    }
    ${Quotation_MaterialFragmentDoc}
`;

/**
 * __useQuotatioMaterialsQuery__
 *
 * To run a query within a React component, call `useQuotatioMaterialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotatioMaterialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotatioMaterialsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useQuotatioMaterialsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        QuotatioMaterialsQuery,
        QuotatioMaterialsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<QuotatioMaterialsQuery, QuotatioMaterialsQueryVariables>(
        QuotatioMaterialsDocument,
        options
    );
}
export function useQuotatioMaterialsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        QuotatioMaterialsQuery,
        QuotatioMaterialsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<QuotatioMaterialsQuery, QuotatioMaterialsQueryVariables>(
        QuotatioMaterialsDocument,
        options
    );
}
export type QuotatioMaterialsQueryHookResult = ReturnType<typeof useQuotatioMaterialsQuery>;
export type QuotatioMaterialsLazyQueryHookResult = ReturnType<typeof useQuotatioMaterialsLazyQuery>;
export type QuotatioMaterialsQueryResult = ApolloReactCommon.QueryResult<
    QuotatioMaterialsQuery,
    QuotatioMaterialsQueryVariables
>;
export const QuotatioPromotionDocument = gql`
    query quotatioPromotion($id: ID!) {
        eyePromotion(id: $id) {
            ...Quotation_Promotion
        }
    }
    ${Quotation_PromotionFragmentDoc}
`;

/**
 * __useQuotatioPromotionQuery__
 *
 * To run a query within a React component, call `useQuotatioPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotatioPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotatioPromotionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuotatioPromotionQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        QuotatioPromotionQuery,
        QuotatioPromotionQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<QuotatioPromotionQuery, QuotatioPromotionQueryVariables>(
        QuotatioPromotionDocument,
        options
    );
}
export function useQuotatioPromotionLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        QuotatioPromotionQuery,
        QuotatioPromotionQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<QuotatioPromotionQuery, QuotatioPromotionQueryVariables>(
        QuotatioPromotionDocument,
        options
    );
}
export type QuotatioPromotionQueryHookResult = ReturnType<typeof useQuotatioPromotionQuery>;
export type QuotatioPromotionLazyQueryHookResult = ReturnType<typeof useQuotatioPromotionLazyQuery>;
export type QuotatioPromotionQueryResult = ApolloReactCommon.QueryResult<
    QuotatioPromotionQuery,
    QuotatioPromotionQueryVariables
>;
export const QuotatioPromotionsDocument = gql`
    query quotatioPromotions($filters: EyePromotionFilterInput, $pagination: PaginationInput) {
        eyePromotions(filters: $filters, pagination: $pagination) {
            ...Quotation_Promotion
        }
    }
    ${Quotation_PromotionFragmentDoc}
`;

/**
 * __useQuotatioPromotionsQuery__
 *
 * To run a query within a React component, call `useQuotatioPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotatioPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotatioPromotionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useQuotatioPromotionsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        QuotatioPromotionsQuery,
        QuotatioPromotionsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<QuotatioPromotionsQuery, QuotatioPromotionsQueryVariables>(
        QuotatioPromotionsDocument,
        options
    );
}
export function useQuotatioPromotionsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        QuotatioPromotionsQuery,
        QuotatioPromotionsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<QuotatioPromotionsQuery, QuotatioPromotionsQueryVariables>(
        QuotatioPromotionsDocument,
        options
    );
}
export type QuotatioPromotionsQueryHookResult = ReturnType<typeof useQuotatioPromotionsQuery>;
export type QuotatioPromotionsLazyQueryHookResult = ReturnType<
    typeof useQuotatioPromotionsLazyQuery
>;
export type QuotatioPromotionsQueryResult = ApolloReactCommon.QueryResult<
    QuotatioPromotionsQuery,
    QuotatioPromotionsQueryVariables
>;
export const CurrenciesDocument = gql`
    query currencies {
        currencies {
            id
            name
            code
        }
    }
`;

/**
 * __useCurrenciesQuery__
 *
 * To run a query within a React component, call `useCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrenciesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CurrenciesQuery, CurrenciesQueryVariables>(
        CurrenciesDocument,
        options
    );
}
export function useCurrenciesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CurrenciesQuery, CurrenciesQueryVariables>(
        CurrenciesDocument,
        options
    );
}
export type CurrenciesQueryHookResult = ReturnType<typeof useCurrenciesQuery>;
export type CurrenciesLazyQueryHookResult = ReturnType<typeof useCurrenciesLazyQuery>;
export type CurrenciesQueryResult = ApolloReactCommon.QueryResult<
    CurrenciesQuery,
    CurrenciesQueryVariables
>;
export const ExchangeRatesDocument = gql`
    query exchangeRates($pagination: PaginationInput, $filters: ExchangeRateFilterInput) {
        exchangeRates(pagination: $pagination, filters: $filters) {
            id
            rate
            currency1Id
            currency2Id
        }
    }
`;

/**
 * __useExchangeRatesQuery__
 *
 * To run a query within a React component, call `useExchangeRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeRatesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useExchangeRatesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<ExchangeRatesQuery, ExchangeRatesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ExchangeRatesQuery, ExchangeRatesQueryVariables>(
        ExchangeRatesDocument,
        options
    );
}
export function useExchangeRatesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ExchangeRatesQuery,
        ExchangeRatesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ExchangeRatesQuery, ExchangeRatesQueryVariables>(
        ExchangeRatesDocument,
        options
    );
}
export type ExchangeRatesQueryHookResult = ReturnType<typeof useExchangeRatesQuery>;
export type ExchangeRatesLazyQueryHookResult = ReturnType<typeof useExchangeRatesLazyQuery>;
export type ExchangeRatesQueryResult = ApolloReactCommon.QueryResult<
    ExchangeRatesQuery,
    ExchangeRatesQueryVariables
>;
export const EyeWarrantyPeriodTypesDocument = gql`
    query EyeWarrantyPeriodTypes {
        eyeWarrantyPeriodTypes {
            name
            id
        }
    }
`;

/**
 * __useEyeWarrantyPeriodTypesQuery__
 *
 * To run a query within a React component, call `useEyeWarrantyPeriodTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeWarrantyPeriodTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeWarrantyPeriodTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEyeWarrantyPeriodTypesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        EyeWarrantyPeriodTypesQuery,
        EyeWarrantyPeriodTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        EyeWarrantyPeriodTypesQuery,
        EyeWarrantyPeriodTypesQueryVariables
    >(EyeWarrantyPeriodTypesDocument, options);
}
export function useEyeWarrantyPeriodTypesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EyeWarrantyPeriodTypesQuery,
        EyeWarrantyPeriodTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        EyeWarrantyPeriodTypesQuery,
        EyeWarrantyPeriodTypesQueryVariables
    >(EyeWarrantyPeriodTypesDocument, options);
}
export type EyeWarrantyPeriodTypesQueryHookResult = ReturnType<
    typeof useEyeWarrantyPeriodTypesQuery
>;
export type EyeWarrantyPeriodTypesLazyQueryHookResult = ReturnType<
    typeof useEyeWarrantyPeriodTypesLazyQuery
>;
export type EyeWarrantyPeriodTypesQueryResult = ApolloReactCommon.QueryResult<
    EyeWarrantyPeriodTypesQuery,
    EyeWarrantyPeriodTypesQueryVariables
>;
export const EyeQuotationOrderTypesDocument = gql`
    query EyeQuotationOrderTypes(
        $filters: EyeQuotationOrderTypeFilterInput
        $pagination: PaginationInput
    ) {
        eyeQuotationOrderTypes(filters: $filters, pagination: $pagination) {
            ...EyeQuotationOrderType
        }
    }
    ${EyeQuotationOrderTypeFragmentDoc}
`;

/**
 * __useEyeQuotationOrderTypesQuery__
 *
 * To run a query within a React component, call `useEyeQuotationOrderTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeQuotationOrderTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeQuotationOrderTypesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useEyeQuotationOrderTypesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        EyeQuotationOrderTypesQuery,
        EyeQuotationOrderTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        EyeQuotationOrderTypesQuery,
        EyeQuotationOrderTypesQueryVariables
    >(EyeQuotationOrderTypesDocument, options);
}
export function useEyeQuotationOrderTypesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EyeQuotationOrderTypesQuery,
        EyeQuotationOrderTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        EyeQuotationOrderTypesQuery,
        EyeQuotationOrderTypesQueryVariables
    >(EyeQuotationOrderTypesDocument, options);
}
export type EyeQuotationOrderTypesQueryHookResult = ReturnType<
    typeof useEyeQuotationOrderTypesQuery
>;
export type EyeQuotationOrderTypesLazyQueryHookResult = ReturnType<
    typeof useEyeQuotationOrderTypesLazyQuery
>;
export type EyeQuotationOrderTypesQueryResult = ApolloReactCommon.QueryResult<
    EyeQuotationOrderTypesQuery,
    EyeQuotationOrderTypesQueryVariables
>;
export const CreateEyeServiceOrderDocument = gql`
    mutation CreateEyeServiceOrder($input: EyeServiceOrderCreateInput!) {
        createEyeServiceOrder(input: $input) {
            ...EyeServiceOrder
        }
    }
    ${EyeServiceOrderFragmentDoc}
`;
export type CreateEyeServiceOrderMutationFn = ApolloReactCommon.MutationFunction<
    CreateEyeServiceOrderMutation,
    CreateEyeServiceOrderMutationVariables
>;

/**
 * __useCreateEyeServiceOrderMutation__
 *
 * To run a mutation, you first call `useCreateEyeServiceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEyeServiceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEyeServiceOrderMutation, { data, loading, error }] = useCreateEyeServiceOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEyeServiceOrderMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateEyeServiceOrderMutation,
        CreateEyeServiceOrderMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateEyeServiceOrderMutation,
        CreateEyeServiceOrderMutationVariables
    >(CreateEyeServiceOrderDocument, options);
}
export type CreateEyeServiceOrderMutationHookResult = ReturnType<
    typeof useCreateEyeServiceOrderMutation
>;
export type CreateEyeServiceOrderMutationResult =
    ApolloReactCommon.MutationResult<CreateEyeServiceOrderMutation>;
export type CreateEyeServiceOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateEyeServiceOrderMutation,
    CreateEyeServiceOrderMutationVariables
>;
export const UpdateEyeServiceOrderDocument = gql`
    mutation UpdateEyeServiceOrder($input: EyeServiceOrderUpdateInput!) {
        updateEyeServiceOrder(input: $input) {
            ...EyeServiceOrder
        }
    }
    ${EyeServiceOrderFragmentDoc}
`;
export type UpdateEyeServiceOrderMutationFn = ApolloReactCommon.MutationFunction<
    UpdateEyeServiceOrderMutation,
    UpdateEyeServiceOrderMutationVariables
>;

/**
 * __useUpdateEyeServiceOrderMutation__
 *
 * To run a mutation, you first call `useUpdateEyeServiceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEyeServiceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEyeServiceOrderMutation, { data, loading, error }] = useUpdateEyeServiceOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEyeServiceOrderMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateEyeServiceOrderMutation,
        UpdateEyeServiceOrderMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateEyeServiceOrderMutation,
        UpdateEyeServiceOrderMutationVariables
    >(UpdateEyeServiceOrderDocument, options);
}
export type UpdateEyeServiceOrderMutationHookResult = ReturnType<
    typeof useUpdateEyeServiceOrderMutation
>;
export type UpdateEyeServiceOrderMutationResult =
    ApolloReactCommon.MutationResult<UpdateEyeServiceOrderMutation>;
export type UpdateEyeServiceOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UpdateEyeServiceOrderMutation,
    UpdateEyeServiceOrderMutationVariables
>;
export const RequestEyeServiceOrderExtensionDocument = gql`
    mutation RequestEyeServiceOrderExtension($input: RequestEyeServiceOrderExtensionInput!) {
        requestEyeServiceOrderExtension(input: $input)
    }
`;
export type RequestEyeServiceOrderExtensionMutationFn = ApolloReactCommon.MutationFunction<
    RequestEyeServiceOrderExtensionMutation,
    RequestEyeServiceOrderExtensionMutationVariables
>;

/**
 * __useRequestEyeServiceOrderExtensionMutation__
 *
 * To run a mutation, you first call `useRequestEyeServiceOrderExtensionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestEyeServiceOrderExtensionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestEyeServiceOrderExtensionMutation, { data, loading, error }] = useRequestEyeServiceOrderExtensionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestEyeServiceOrderExtensionMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        RequestEyeServiceOrderExtensionMutation,
        RequestEyeServiceOrderExtensionMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        RequestEyeServiceOrderExtensionMutation,
        RequestEyeServiceOrderExtensionMutationVariables
    >(RequestEyeServiceOrderExtensionDocument, options);
}
export type RequestEyeServiceOrderExtensionMutationHookResult = ReturnType<
    typeof useRequestEyeServiceOrderExtensionMutation
>;
export type RequestEyeServiceOrderExtensionMutationResult =
    ApolloReactCommon.MutationResult<RequestEyeServiceOrderExtensionMutation>;
export type RequestEyeServiceOrderExtensionMutationOptions = ApolloReactCommon.BaseMutationOptions<
    RequestEyeServiceOrderExtensionMutation,
    RequestEyeServiceOrderExtensionMutationVariables
>;
export const PaginatedEyeServiceOrdersDocument = gql`
    query PaginatedEyeServiceOrders(
        $pagination: PaginationInput
        $filters: EyeServiceOrderFilterInput
    ) {
        paginatedEyeServiceOrders(pagination: $pagination, filters: $filters) {
            count
            eyeServiceOrders {
                ...EyeServiceOrder
            }
        }
    }
    ${EyeServiceOrderFragmentDoc}
`;

/**
 * __usePaginatedEyeServiceOrdersQuery__
 *
 * To run a query within a React component, call `usePaginatedEyeServiceOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedEyeServiceOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedEyeServiceOrdersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedEyeServiceOrdersQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedEyeServiceOrdersQuery,
        PaginatedEyeServiceOrdersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PaginatedEyeServiceOrdersQuery,
        PaginatedEyeServiceOrdersQueryVariables
    >(PaginatedEyeServiceOrdersDocument, options);
}
export function usePaginatedEyeServiceOrdersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedEyeServiceOrdersQuery,
        PaginatedEyeServiceOrdersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedEyeServiceOrdersQuery,
        PaginatedEyeServiceOrdersQueryVariables
    >(PaginatedEyeServiceOrdersDocument, options);
}
export type PaginatedEyeServiceOrdersQueryHookResult = ReturnType<
    typeof usePaginatedEyeServiceOrdersQuery
>;
export type PaginatedEyeServiceOrdersLazyQueryHookResult = ReturnType<
    typeof usePaginatedEyeServiceOrdersLazyQuery
>;
export type PaginatedEyeServiceOrdersQueryResult = ApolloReactCommon.QueryResult<
    PaginatedEyeServiceOrdersQuery,
    PaginatedEyeServiceOrdersQueryVariables
>;
export const EyeServiceOrderDocument = gql`
    query EyeServiceOrder($eyeServiceOrderId: ID!) {
        eyeServiceOrder(id: $eyeServiceOrderId) {
            ...EyeServiceOrder
        }
    }
    ${EyeServiceOrderFragmentDoc}
`;

/**
 * __useEyeServiceOrderQuery__
 *
 * To run a query within a React component, call `useEyeServiceOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeServiceOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeServiceOrderQuery({
 *   variables: {
 *      eyeServiceOrderId: // value for 'eyeServiceOrderId'
 *   },
 * });
 */
export function useEyeServiceOrderQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        EyeServiceOrderQuery,
        EyeServiceOrderQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<EyeServiceOrderQuery, EyeServiceOrderQueryVariables>(
        EyeServiceOrderDocument,
        options
    );
}
export function useEyeServiceOrderLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EyeServiceOrderQuery,
        EyeServiceOrderQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<EyeServiceOrderQuery, EyeServiceOrderQueryVariables>(
        EyeServiceOrderDocument,
        options
    );
}
export type EyeServiceOrderQueryHookResult = ReturnType<typeof useEyeServiceOrderQuery>;
export type EyeServiceOrderLazyQueryHookResult = ReturnType<typeof useEyeServiceOrderLazyQuery>;
export type EyeServiceOrderQueryResult = ApolloReactCommon.QueryResult<
    EyeServiceOrderQuery,
    EyeServiceOrderQueryVariables
>;
export const EyeServiceOrderTypesDocument = gql`
    query EyeServiceOrderTypes {
        paginatedEyeServiceOrderTypes {
            eyeServiceOrderTypes {
                id
                code
                name
            }
        }
    }
`;

/**
 * __useEyeServiceOrderTypesQuery__
 *
 * To run a query within a React component, call `useEyeServiceOrderTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeServiceOrderTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeServiceOrderTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEyeServiceOrderTypesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        EyeServiceOrderTypesQuery,
        EyeServiceOrderTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<EyeServiceOrderTypesQuery, EyeServiceOrderTypesQueryVariables>(
        EyeServiceOrderTypesDocument,
        options
    );
}
export function useEyeServiceOrderTypesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EyeServiceOrderTypesQuery,
        EyeServiceOrderTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        EyeServiceOrderTypesQuery,
        EyeServiceOrderTypesQueryVariables
    >(EyeServiceOrderTypesDocument, options);
}
export type EyeServiceOrderTypesQueryHookResult = ReturnType<typeof useEyeServiceOrderTypesQuery>;
export type EyeServiceOrderTypesLazyQueryHookResult = ReturnType<
    typeof useEyeServiceOrderTypesLazyQuery
>;
export type EyeServiceOrderTypesQueryResult = ApolloReactCommon.QueryResult<
    EyeServiceOrderTypesQuery,
    EyeServiceOrderTypesQueryVariables
>;
export const CostCentersDocument = gql`
    query CostCenters {
        paginatedCostCenters {
            costCenters {
                ...CostCenter
            }
        }
    }
    ${CostCenterFragmentDoc}
`;

/**
 * __useCostCentersQuery__
 *
 * To run a query within a React component, call `useCostCentersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCentersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCentersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCostCentersQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<CostCentersQuery, CostCentersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CostCentersQuery, CostCentersQueryVariables>(
        CostCentersDocument,
        options
    );
}
export function useCostCentersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CostCentersQuery, CostCentersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CostCentersQuery, CostCentersQueryVariables>(
        CostCentersDocument,
        options
    );
}
export type CostCentersQueryHookResult = ReturnType<typeof useCostCentersQuery>;
export type CostCentersLazyQueryHookResult = ReturnType<typeof useCostCentersLazyQuery>;
export type CostCentersQueryResult = ApolloReactCommon.QueryResult<
    CostCentersQuery,
    CostCentersQueryVariables
>;
export const EyeFixedAssetRentalObjectsDocument = gql`
    query EyeFixedAssetRentalObjects($filters: EyeFixedAssetRentalObjectFilterInput) {
        eyeFixedAssetRentalObjects(filters: $filters) {
            id
            name
            isActive
        }
    }
`;

/**
 * __useEyeFixedAssetRentalObjectsQuery__
 *
 * To run a query within a React component, call `useEyeFixedAssetRentalObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeFixedAssetRentalObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeFixedAssetRentalObjectsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeFixedAssetRentalObjectsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        EyeFixedAssetRentalObjectsQuery,
        EyeFixedAssetRentalObjectsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        EyeFixedAssetRentalObjectsQuery,
        EyeFixedAssetRentalObjectsQueryVariables
    >(EyeFixedAssetRentalObjectsDocument, options);
}
export function useEyeFixedAssetRentalObjectsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        EyeFixedAssetRentalObjectsQuery,
        EyeFixedAssetRentalObjectsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        EyeFixedAssetRentalObjectsQuery,
        EyeFixedAssetRentalObjectsQueryVariables
    >(EyeFixedAssetRentalObjectsDocument, options);
}
export type EyeFixedAssetRentalObjectsQueryHookResult = ReturnType<
    typeof useEyeFixedAssetRentalObjectsQuery
>;
export type EyeFixedAssetRentalObjectsLazyQueryHookResult = ReturnType<
    typeof useEyeFixedAssetRentalObjectsLazyQuery
>;
export type EyeFixedAssetRentalObjectsQueryResult = ApolloReactCommon.QueryResult<
    EyeFixedAssetRentalObjectsQuery,
    EyeFixedAssetRentalObjectsQueryVariables
>;
export const ServiceOrderEyeFixedAssetsDocument = gql`
    query serviceOrderEyeFixedAssets($filters: EyeFixedAssetFilterInput) {
        eyeFixedAssets(filters: $filters) {
            nextEyeFixedAssetRentalRecord {
                ...serviceOrderAssetRentalRecord
            }
            prevEyeFixedAssetRentalRecord {
                ...serviceOrderAssetRentalRecord
            }
        }
    }
    ${ServiceOrderAssetRentalRecordFragmentDoc}
`;

/**
 * __useServiceOrderEyeFixedAssetsQuery__
 *
 * To run a query within a React component, call `useServiceOrderEyeFixedAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceOrderEyeFixedAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceOrderEyeFixedAssetsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useServiceOrderEyeFixedAssetsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        ServiceOrderEyeFixedAssetsQuery,
        ServiceOrderEyeFixedAssetsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        ServiceOrderEyeFixedAssetsQuery,
        ServiceOrderEyeFixedAssetsQueryVariables
    >(ServiceOrderEyeFixedAssetsDocument, options);
}
export function useServiceOrderEyeFixedAssetsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ServiceOrderEyeFixedAssetsQuery,
        ServiceOrderEyeFixedAssetsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ServiceOrderEyeFixedAssetsQuery,
        ServiceOrderEyeFixedAssetsQueryVariables
    >(ServiceOrderEyeFixedAssetsDocument, options);
}
export type ServiceOrderEyeFixedAssetsQueryHookResult = ReturnType<
    typeof useServiceOrderEyeFixedAssetsQuery
>;
export type ServiceOrderEyeFixedAssetsLazyQueryHookResult = ReturnType<
    typeof useServiceOrderEyeFixedAssetsLazyQuery
>;
export type ServiceOrderEyeFixedAssetsQueryResult = ApolloReactCommon.QueryResult<
    ServiceOrderEyeFixedAssetsQuery,
    ServiceOrderEyeFixedAssetsQueryVariables
>;
export const LoginBySsoDocument = gql`
    mutation loginBySSO($SSOToken: String!) {
        loginBySSO(SSOToken: $SSOToken) {
            token
            user {
                id
                name
                email
                code
            }
        }
    }
`;
export type LoginBySsoMutationFn = ApolloReactCommon.MutationFunction<
    LoginBySsoMutation,
    LoginBySsoMutationVariables
>;

/**
 * __useLoginBySsoMutation__
 *
 * To run a mutation, you first call `useLoginBySsoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginBySsoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginBySsoMutation, { data, loading, error }] = useLoginBySsoMutation({
 *   variables: {
 *      SSOToken: // value for 'SSOToken'
 *   },
 * });
 */
export function useLoginBySsoMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        LoginBySsoMutation,
        LoginBySsoMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<LoginBySsoMutation, LoginBySsoMutationVariables>(
        LoginBySsoDocument,
        options
    );
}
export type LoginBySsoMutationHookResult = ReturnType<typeof useLoginBySsoMutation>;
export type LoginBySsoMutationResult = ApolloReactCommon.MutationResult<LoginBySsoMutation>;
export type LoginBySsoMutationOptions = ApolloReactCommon.BaseMutationOptions<
    LoginBySsoMutation,
    LoginBySsoMutationVariables
>;
export const PaginatedUsersDocument = gql`
    query paginatedUsers($pagination: PaginationInput, $filters: UserFilterInput) {
        paginatedUsers(pagination: $pagination, filters: $filters) {
            count
            users {
                id
                name
                code
                email
            }
        }
    }
`;

/**
 * __usePaginatedUsersQuery__
 *
 * To run a query within a React component, call `usePaginatedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedUsersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedUsersQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedUsersQuery,
        PaginatedUsersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PaginatedUsersQuery, PaginatedUsersQueryVariables>(
        PaginatedUsersDocument,
        options
    );
}
export function usePaginatedUsersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedUsersQuery,
        PaginatedUsersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PaginatedUsersQuery, PaginatedUsersQueryVariables>(
        PaginatedUsersDocument,
        options
    );
}
export type PaginatedUsersQueryHookResult = ReturnType<typeof usePaginatedUsersQuery>;
export type PaginatedUsersLazyQueryHookResult = ReturnType<typeof usePaginatedUsersLazyQuery>;
export type PaginatedUsersQueryResult = ApolloReactCommon.QueryResult<
    PaginatedUsersQuery,
    PaginatedUsersQueryVariables
>;
export const PaginatedDepartmentsDocument = gql`
    query paginatedDepartments($pagination: PaginationInput, $filters: DepartmentFilterInput) {
        paginatedDepartments(pagination: $pagination, filters: $filters) {
            count
            departments {
                ...Department
            }
        }
    }
    ${DepartmentFragmentDoc}
`;

/**
 * __usePaginatedDepartmentsQuery__
 *
 * To run a query within a React component, call `usePaginatedDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedDepartmentsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedDepartmentsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedDepartmentsQuery,
        PaginatedDepartmentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PaginatedDepartmentsQuery, PaginatedDepartmentsQueryVariables>(
        PaginatedDepartmentsDocument,
        options
    );
}
export function usePaginatedDepartmentsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedDepartmentsQuery,
        PaginatedDepartmentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedDepartmentsQuery,
        PaginatedDepartmentsQueryVariables
    >(PaginatedDepartmentsDocument, options);
}
export type PaginatedDepartmentsQueryHookResult = ReturnType<typeof usePaginatedDepartmentsQuery>;
export type PaginatedDepartmentsLazyQueryHookResult = ReturnType<
    typeof usePaginatedDepartmentsLazyQuery
>;
export type PaginatedDepartmentsQueryResult = ApolloReactCommon.QueryResult<
    PaginatedDepartmentsQuery,
    PaginatedDepartmentsQueryVariables
>;
export const DepartmentDocument = gql`
    query Department($departmentId: ID!) {
        department(id: $departmentId) {
            ...Department
        }
    }
    ${DepartmentFragmentDoc}
`;

/**
 * __useDepartmentQuery__
 *
 * To run a query within a React component, call `useDepartmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentQuery({
 *   variables: {
 *      departmentId: // value for 'departmentId'
 *   },
 * });
 */
export function useDepartmentQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<DepartmentQuery, DepartmentQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<DepartmentQuery, DepartmentQueryVariables>(
        DepartmentDocument,
        options
    );
}
export function useDepartmentLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepartmentQuery, DepartmentQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<DepartmentQuery, DepartmentQueryVariables>(
        DepartmentDocument,
        options
    );
}
export type DepartmentQueryHookResult = ReturnType<typeof useDepartmentQuery>;
export type DepartmentLazyQueryHookResult = ReturnType<typeof useDepartmentLazyQuery>;
export type DepartmentQueryResult = ApolloReactCommon.QueryResult<
    DepartmentQuery,
    DepartmentQueryVariables
>;
export const MeDocument = gql`
    query me {
        me {
            id
            name
            email
            code
            salesTeamUnit {
                id
                name
                user {
                    id
                    name
                }
                salesTeam {
                    id
                    name
                    code
                }
            }
            defaultAvailableSalesTeamGroup {
                id
                name
                code
                region {
                    id
                    name
                    localCurrency {
                        id
                        code
                        name
                    }
                    taxRates {
                        id
                        rate
                    }
                }
            }
            salesTeams {
                id
                name
                top {
                    code
                    id
                    name
                }
            }
            availableSalesTeamGroups {
                id
                name
                code
                region {
                    id
                    name
                    localCurrency {
                        id
                        code
                        name
                    }
                    taxRates {
                        id
                        rate
                    }
                }
            }
            dept {
                id
                name
                manager {
                    id
                    name
                }
            }
            region {
                id
                name
                localCurrency {
                    id
                    code
                    name
                }
                taxRates {
                    id
                    rate
                }
            }
            company {
                id
                name
            }
            userPermission {
                codes
                allowRegionIds
                allowCompanyIds
                allowSalesTeamGroupIds
                allowSalesTeamIds
            }
        }
    }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const CreateVisitDocument = gql`
    mutation createVisit($input: VisitCreateInput!) {
        createVisit(input: $input) {
            id
            visitDate
        }
    }
`;
export type CreateVisitMutationFn = ApolloReactCommon.MutationFunction<
    CreateVisitMutation,
    CreateVisitMutationVariables
>;

/**
 * __useCreateVisitMutation__
 *
 * To run a mutation, you first call `useCreateVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVisitMutation, { data, loading, error }] = useCreateVisitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVisitMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateVisitMutation,
        CreateVisitMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CreateVisitMutation, CreateVisitMutationVariables>(
        CreateVisitDocument,
        options
    );
}
export type CreateVisitMutationHookResult = ReturnType<typeof useCreateVisitMutation>;
export type CreateVisitMutationResult = ApolloReactCommon.MutationResult<CreateVisitMutation>;
export type CreateVisitMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateVisitMutation,
    CreateVisitMutationVariables
>;
export const UpdateVisitDocument = gql`
    mutation updateVisit($input: VisitUpdateInput!) {
        updateVisit(input: $input) {
            id
            visitDate
            mapRentFixedAssets {
                rentFixedAsset {
                    id
                    name
                }
            }
        }
    }
`;
export type UpdateVisitMutationFn = ApolloReactCommon.MutationFunction<
    UpdateVisitMutation,
    UpdateVisitMutationVariables
>;

/**
 * __useUpdateVisitMutation__
 *
 * To run a mutation, you first call `useUpdateVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVisitMutation, { data, loading, error }] = useUpdateVisitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVisitMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateVisitMutation,
        UpdateVisitMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<UpdateVisitMutation, UpdateVisitMutationVariables>(
        UpdateVisitDocument,
        options
    );
}
export type UpdateVisitMutationHookResult = ReturnType<typeof useUpdateVisitMutation>;
export type UpdateVisitMutationResult = ApolloReactCommon.MutationResult<UpdateVisitMutation>;
export type UpdateVisitMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UpdateVisitMutation,
    UpdateVisitMutationVariables
>;
export const BulkUpdateVisitDocument = gql`
    mutation bulkUpdateVisit($input: VisitBulkUpdateInput!) {
        bulkUpdateVisit(input: $input) {
            id
            visitDate
        }
    }
`;
export type BulkUpdateVisitMutationFn = ApolloReactCommon.MutationFunction<
    BulkUpdateVisitMutation,
    BulkUpdateVisitMutationVariables
>;

/**
 * __useBulkUpdateVisitMutation__
 *
 * To run a mutation, you first call `useBulkUpdateVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateVisitMutation, { data, loading, error }] = useBulkUpdateVisitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateVisitMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        BulkUpdateVisitMutation,
        BulkUpdateVisitMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<BulkUpdateVisitMutation, BulkUpdateVisitMutationVariables>(
        BulkUpdateVisitDocument,
        options
    );
}
export type BulkUpdateVisitMutationHookResult = ReturnType<typeof useBulkUpdateVisitMutation>;
export type BulkUpdateVisitMutationResult =
    ApolloReactCommon.MutationResult<BulkUpdateVisitMutation>;
export type BulkUpdateVisitMutationOptions = ApolloReactCommon.BaseMutationOptions<
    BulkUpdateVisitMutation,
    BulkUpdateVisitMutationVariables
>;
export const PaginatedVisitsDocument = gql`
    query paginatedVisits($filters: VisitFilterInput, $pagination: PaginationInput) {
        paginatedVisits(filters: $filters, pagination: $pagination) {
            count
            visits {
                ...VisitBasicInfo
                formattedProperties {
                    propertyType {
                        id
                        name
                    }
                    properties {
                        id
                        name
                    }
                }
            }
        }
    }
    ${VisitBasicInfoFragmentDoc}
`;

/**
 * __usePaginatedVisitsQuery__
 *
 * To run a query within a React component, call `usePaginatedVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedVisitsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePaginatedVisitsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedVisitsQuery,
        PaginatedVisitsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PaginatedVisitsQuery, PaginatedVisitsQueryVariables>(
        PaginatedVisitsDocument,
        options
    );
}
export function usePaginatedVisitsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedVisitsQuery,
        PaginatedVisitsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PaginatedVisitsQuery, PaginatedVisitsQueryVariables>(
        PaginatedVisitsDocument,
        options
    );
}
export type PaginatedVisitsQueryHookResult = ReturnType<typeof usePaginatedVisitsQuery>;
export type PaginatedVisitsLazyQueryHookResult = ReturnType<typeof usePaginatedVisitsLazyQuery>;
export type PaginatedVisitsQueryResult = ApolloReactCommon.QueryResult<
    PaginatedVisitsQuery,
    PaginatedVisitsQueryVariables
>;
export const VisitDocument = gql`
    query visit($id: ID!) {
        visit(id: $id) {
            ...VisitBasicInfo
            ...VisitDetailData
            estimatedSurgeryCount
            surgeryCount
            formattedProperties {
                propertyType {
                    id
                    name
                }
                properties {
                    id
                    name
                }
            }
        }
    }
    ${VisitBasicInfoFragmentDoc}
    ${VisitDetailDataFragmentDoc}
`;

/**
 * __useVisitQuery__
 *
 * To run a query within a React component, call `useVisitQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVisitQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<VisitQuery, VisitQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<VisitQuery, VisitQueryVariables>(VisitDocument, options);
}
export function useVisitLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VisitQuery, VisitQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<VisitQuery, VisitQueryVariables>(VisitDocument, options);
}
export type VisitQueryHookResult = ReturnType<typeof useVisitQuery>;
export type VisitLazyQueryHookResult = ReturnType<typeof useVisitLazyQuery>;
export type VisitQueryResult = ApolloReactCommon.QueryResult<VisitQuery, VisitQueryVariables>;
export const VisitsDocument = gql`
    query visits($pagination: PaginationInput, $filters: VisitFilterInput) {
        visits(pagination: $pagination, filters: $filters) {
            ...VisitBasicInfo
            formattedProperties {
                propertyType {
                    id
                    name
                }
                properties {
                    id
                    name
                }
            }
        }
    }
    ${VisitBasicInfoFragmentDoc}
`;

/**
 * __useVisitsQuery__
 *
 * To run a query within a React component, call `useVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useVisitsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<VisitsQuery, VisitsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<VisitsQuery, VisitsQueryVariables>(VisitsDocument, options);
}
export function useVisitsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VisitsQuery, VisitsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<VisitsQuery, VisitsQueryVariables>(
        VisitsDocument,
        options
    );
}
export type VisitsQueryHookResult = ReturnType<typeof useVisitsQuery>;
export type VisitsLazyQueryHookResult = ReturnType<typeof useVisitsLazyQuery>;
export type VisitsQueryResult = ApolloReactCommon.QueryResult<VisitsQuery, VisitsQueryVariables>;
export const PaginatedVisitCheckInsDocument = gql`
    query PaginatedVisitCheckIns($pagination: PaginationInput, $filters: VisitCheckInFilterInput) {
        paginatedVisitCheckIns(pagination: $pagination, filters: $filters) {
            count
            visitCheckIns {
                ...VisitCheckIn
            }
        }
    }
    ${VisitCheckInFragmentDoc}
`;

/**
 * __usePaginatedVisitCheckInsQuery__
 *
 * To run a query within a React component, call `usePaginatedVisitCheckInsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedVisitCheckInsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedVisitCheckInsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedVisitCheckInsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PaginatedVisitCheckInsQuery,
        PaginatedVisitCheckInsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PaginatedVisitCheckInsQuery,
        PaginatedVisitCheckInsQueryVariables
    >(PaginatedVisitCheckInsDocument, options);
}
export function usePaginatedVisitCheckInsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PaginatedVisitCheckInsQuery,
        PaginatedVisitCheckInsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PaginatedVisitCheckInsQuery,
        PaginatedVisitCheckInsQueryVariables
    >(PaginatedVisitCheckInsDocument, options);
}
export type PaginatedVisitCheckInsQueryHookResult = ReturnType<
    typeof usePaginatedVisitCheckInsQuery
>;
export type PaginatedVisitCheckInsLazyQueryHookResult = ReturnType<
    typeof usePaginatedVisitCheckInsLazyQuery
>;
export type PaginatedVisitCheckInsQueryResult = ApolloReactCommon.QueryResult<
    PaginatedVisitCheckInsQuery,
    PaginatedVisitCheckInsQueryVariables
>;
export const VisitOptionsDocument = gql`
    query visitOptions {
        visitActions {
            ...VisitActionBasicInfo
        }
        visitGoals {
            ...VisitGoalBasicInfo
        }
        visitPropertyTypes {
            ...VisitPropertyTypeBasicInfo
        }
        visitProperties {
            ...VisitPropertyBasicInfo
            type {
                ...VisitPropertyTypeBasicInfo
            }
        }
        visitTimePeriods {
            ...VisitTimePeriodBasicInfo
        }
        visitTypes {
            ...VisitTypeBasicInfo
        }
        businessProducts {
            ...VisitProductBasicInfo
        }
    }
    ${VisitActionBasicInfoFragmentDoc}
    ${VisitGoalBasicInfoFragmentDoc}
    ${VisitPropertyTypeBasicInfoFragmentDoc}
    ${VisitPropertyBasicInfoFragmentDoc}
    ${VisitTimePeriodBasicInfoFragmentDoc}
    ${VisitTypeBasicInfoFragmentDoc}
    ${VisitProductBasicInfoFragmentDoc}
`;

/**
 * __useVisitOptionsQuery__
 *
 * To run a query within a React component, call `useVisitOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVisitOptionsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<VisitOptionsQuery, VisitOptionsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<VisitOptionsQuery, VisitOptionsQueryVariables>(
        VisitOptionsDocument,
        options
    );
}
export function useVisitOptionsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        VisitOptionsQuery,
        VisitOptionsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<VisitOptionsQuery, VisitOptionsQueryVariables>(
        VisitOptionsDocument,
        options
    );
}
export type VisitOptionsQueryHookResult = ReturnType<typeof useVisitOptionsQuery>;
export type VisitOptionsLazyQueryHookResult = ReturnType<typeof useVisitOptionsLazyQuery>;
export type VisitOptionsQueryResult = ApolloReactCommon.QueryResult<
    VisitOptionsQuery,
    VisitOptionsQueryVariables
>;
export const VisitAnalysisDocument = gql`
    query visitAnalysis(
        $visitDate1: String
        $visitDate2: String
        $primaryUserIds: [ID!]
        $businessExpectedClosedDate1: String
        $businessExpectedClosedDate2: String
    ) {
        visitAnalysis {
            visitStat(
                filters: {
                    visitDate1: $visitDate1
                    visitDate2: $visitDate2
                    primaryUserIds: $primaryUserIds
                    businessExpectedClosedDate1: $businessExpectedClosedDate1
                    businessExpectedClosedDate2: $businessExpectedClosedDate2
                }
            ) {
                ...visitStat
            }
            weeklyVisitStat(
                filters: {
                    visitDate1: $visitDate1
                    visitDate2: $visitDate2
                    primaryUserIds: $primaryUserIds
                }
            ) {
                ...weeklyVisitStat
            }
            visitedBusinessRank(
                filters: {
                    visitDate1: $visitDate1
                    visitDate2: $visitDate2
                    primaryUserIds: $primaryUserIds
                }
                pagination: { limit: null, offset: null }
            ) {
                ...visitAnalysisBusinessRank
            }
            unvisitedBusinessRank(
                filters: {
                    visitDate1: $visitDate1
                    visitDate2: $visitDate2
                    primaryUserIds: $primaryUserIds
                }
                pagination: { limit: null, offset: null }
            ) {
                ...visitAnalysisBusinessRank
            }
            mentionProductRank(
                filters: {
                    visitDate1: $visitDate1
                    visitDate2: $visitDate2
                    primaryUserIds: $primaryUserIds
                }
                pagination: { limit: null, offset: null }
            ) {
                ...VisitAnalysisMentionProductRank
            }
        }
    }
    ${VisitStatFragmentDoc}
    ${WeeklyVisitStatFragmentDoc}
    ${VisitAnalysisBusinessRankFragmentDoc}
    ${VisitAnalysisMentionProductRankFragmentDoc}
`;

/**
 * __useVisitAnalysisQuery__
 *
 * To run a query within a React component, call `useVisitAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitAnalysisQuery({
 *   variables: {
 *      visitDate1: // value for 'visitDate1'
 *      visitDate2: // value for 'visitDate2'
 *      primaryUserIds: // value for 'primaryUserIds'
 *      businessExpectedClosedDate1: // value for 'businessExpectedClosedDate1'
 *      businessExpectedClosedDate2: // value for 'businessExpectedClosedDate2'
 *   },
 * });
 */
export function useVisitAnalysisQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<VisitAnalysisQuery, VisitAnalysisQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<VisitAnalysisQuery, VisitAnalysisQueryVariables>(
        VisitAnalysisDocument,
        options
    );
}
export function useVisitAnalysisLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        VisitAnalysisQuery,
        VisitAnalysisQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<VisitAnalysisQuery, VisitAnalysisQueryVariables>(
        VisitAnalysisDocument,
        options
    );
}
export type VisitAnalysisQueryHookResult = ReturnType<typeof useVisitAnalysisQuery>;
export type VisitAnalysisLazyQueryHookResult = ReturnType<typeof useVisitAnalysisLazyQuery>;
export type VisitAnalysisQueryResult = ApolloReactCommon.QueryResult<
    VisitAnalysisQuery,
    VisitAnalysisQueryVariables
>;
export const VisitGoalAnalysisDocument = gql`
    query VisitGoalAnalysis($filters: VisitGoalAnalysisMentionProductStatFilterInput) {
        visitGoalAnalysis {
            mentionProductStat(filters: $filters) {
                details {
                    count
                    countPercent
                    mentionProducts {
                        count
                        mentionProduct {
                            name
                            id
                        }
                        mentionProductId
                    }
                    visitGoalId
                    visitGoal {
                        name
                    }
                }
            }
        }
    }
`;

/**
 * __useVisitGoalAnalysisQuery__
 *
 * To run a query within a React component, call `useVisitGoalAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitGoalAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitGoalAnalysisQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useVisitGoalAnalysisQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        VisitGoalAnalysisQuery,
        VisitGoalAnalysisQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<VisitGoalAnalysisQuery, VisitGoalAnalysisQueryVariables>(
        VisitGoalAnalysisDocument,
        options
    );
}
export function useVisitGoalAnalysisLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        VisitGoalAnalysisQuery,
        VisitGoalAnalysisQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<VisitGoalAnalysisQuery, VisitGoalAnalysisQueryVariables>(
        VisitGoalAnalysisDocument,
        options
    );
}
export type VisitGoalAnalysisQueryHookResult = ReturnType<typeof useVisitGoalAnalysisQuery>;
export type VisitGoalAnalysisLazyQueryHookResult = ReturnType<typeof useVisitGoalAnalysisLazyQuery>;
export type VisitGoalAnalysisQueryResult = ApolloReactCommon.QueryResult<
    VisitGoalAnalysisQuery,
    VisitGoalAnalysisQueryVariables
>;
export const VisitCustomerLevelTypeAnalysisDocument = gql`
    query VisitCustomerLevelTypeAnalysis(
        $filters: VisitCustomerLevelTypeAnalysisCustomerStatFilterInput!
        $mentionProductStatFilters2: VisitCustomerLevelTypeAnalysisMentionProductStatFilterInput!
        $visitGoalStatFilters2: VisitCustomerLevelTypeAnalysisVisitGoalStatFilterInput
    ) {
        visitCustomerLevelTypeAnalysis {
            customerStat(filters: $filters) {
                details {
                    ...customerStatDetail
                }
                sortByFirstFieldType {
                    name
                }
                sortedDetailsByFirstFieldType {
                    ...customerStatDetail
                }
                sortedDetailsBySecondFieldType {
                    ...customerStatDetail
                }
            }
            mentionProductStat(filters: $mentionProductStatFilters2) {
                details {
                    mentionCount
                    mentionCountPercent
                    mentionProductId
                    mentionProduct {
                        name
                    }
                }
            }
            visitGoalStat(filters: $visitGoalStatFilters2) {
                details {
                    visitCount
                    visitCountPercent
                    visitGoalId
                    mentionProducts {
                        mentionProductId
                        visitCount
                        mentionProduct {
                            name
                            id
                        }
                    }
                    visitGoal {
                        name
                    }
                }
            }
        }
    }
    ${CustomerStatDetailFragmentDoc}
`;

/**
 * __useVisitCustomerLevelTypeAnalysisQuery__
 *
 * To run a query within a React component, call `useVisitCustomerLevelTypeAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitCustomerLevelTypeAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitCustomerLevelTypeAnalysisQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      mentionProductStatFilters2: // value for 'mentionProductStatFilters2'
 *      visitGoalStatFilters2: // value for 'visitGoalStatFilters2'
 *   },
 * });
 */
export function useVisitCustomerLevelTypeAnalysisQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        VisitCustomerLevelTypeAnalysisQuery,
        VisitCustomerLevelTypeAnalysisQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        VisitCustomerLevelTypeAnalysisQuery,
        VisitCustomerLevelTypeAnalysisQueryVariables
    >(VisitCustomerLevelTypeAnalysisDocument, options);
}
export function useVisitCustomerLevelTypeAnalysisLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        VisitCustomerLevelTypeAnalysisQuery,
        VisitCustomerLevelTypeAnalysisQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        VisitCustomerLevelTypeAnalysisQuery,
        VisitCustomerLevelTypeAnalysisQueryVariables
    >(VisitCustomerLevelTypeAnalysisDocument, options);
}
export type VisitCustomerLevelTypeAnalysisQueryHookResult = ReturnType<
    typeof useVisitCustomerLevelTypeAnalysisQuery
>;
export type VisitCustomerLevelTypeAnalysisLazyQueryHookResult = ReturnType<
    typeof useVisitCustomerLevelTypeAnalysisLazyQuery
>;
export type VisitCustomerLevelTypeAnalysisQueryResult = ApolloReactCommon.QueryResult<
    VisitCustomerLevelTypeAnalysisQuery,
    VisitCustomerLevelTypeAnalysisQueryVariables
>;
export const RentProductsDocument = gql`
    query RentProducts {
        rentProducts {
            id
            name
        }
    }
`;

/**
 * __useRentProductsQuery__
 *
 * To run a query within a React component, call `useRentProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRentProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRentProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRentProductsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<RentProductsQuery, RentProductsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<RentProductsQuery, RentProductsQueryVariables>(
        RentProductsDocument,
        options
    );
}
export function useRentProductsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        RentProductsQuery,
        RentProductsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<RentProductsQuery, RentProductsQueryVariables>(
        RentProductsDocument,
        options
    );
}
export type RentProductsQueryHookResult = ReturnType<typeof useRentProductsQuery>;
export type RentProductsLazyQueryHookResult = ReturnType<typeof useRentProductsLazyQuery>;
export type RentProductsQueryResult = ApolloReactCommon.QueryResult<
    RentProductsQuery,
    RentProductsQueryVariables
>;
export const VisitCustomerAnalysisDocument = gql`
    query VisitCustomerAnalysis($filters: VisitCustomerAnalysisVisitStatFilterInput) {
        visitCustomerAnalysis {
            visitStat(filters: $filters) {
                details {
                    customerId
                    customer {
                        id
                        name
                        type {
                            name
                        }
                        levels {
                            name
                            type {
                                name
                                id
                                code
                            }
                        }
                    }
                    visitCount
                    salesTeamUnits {
                        salesTeamUnitId
                        salesTeamUnit {
                            id
                            name
                            user {
                                id
                                name
                            }
                        }
                        visitCount
                    }
                    mentionProducts {
                        mentionProductId
                        mentionProduct {
                            id
                            name
                        }
                        visitCount
                    }
                }
            }
        }
    }
`;

/**
 * __useVisitCustomerAnalysisQuery__
 *
 * To run a query within a React component, call `useVisitCustomerAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitCustomerAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitCustomerAnalysisQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useVisitCustomerAnalysisQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        VisitCustomerAnalysisQuery,
        VisitCustomerAnalysisQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        VisitCustomerAnalysisQuery,
        VisitCustomerAnalysisQueryVariables
    >(VisitCustomerAnalysisDocument, options);
}
export function useVisitCustomerAnalysisLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        VisitCustomerAnalysisQuery,
        VisitCustomerAnalysisQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        VisitCustomerAnalysisQuery,
        VisitCustomerAnalysisQueryVariables
    >(VisitCustomerAnalysisDocument, options);
}
export type VisitCustomerAnalysisQueryHookResult = ReturnType<typeof useVisitCustomerAnalysisQuery>;
export type VisitCustomerAnalysisLazyQueryHookResult = ReturnType<
    typeof useVisitCustomerAnalysisLazyQuery
>;
export type VisitCustomerAnalysisQueryResult = ApolloReactCommon.QueryResult<
    VisitCustomerAnalysisQuery,
    VisitCustomerAnalysisQueryVariables
>;
