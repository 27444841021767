import React from 'react';
import { I_router } from '..';
import { ROUTE_PATH } from '@/consts';
import { HomeOutlined } from '@ant-design/icons';

const CommonRouter: I_router = {
    [`${ROUTE_PATH.HOME}`]: {
        auth: [],
        component: () => import('@/modules/common/pages/home.page'),
        title: 'home',
        icon: <HomeOutlined />,
    },
};
export { CommonRouter };
