import React from 'react';
import { I_router } from '..';
import { ROUTE_PATH } from '@/consts';
import { SearchOutlined } from '@ant-design/icons';

const InventoryRouter: I_router = {
    [`${ROUTE_PATH.INVENTORY.SEARCH}`]: {
        auth: ['ec_inventory_stat.read'], //to do
        component: () => import('@/modules/inventory/page/search.page'),
        title: 'inventory search router title',
        icon: <SearchOutlined />,
    },
};
export { InventoryRouter };
