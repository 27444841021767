import React from 'react';
import { I_router } from '..';
import { ROUTE_PATH } from '@/consts';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';

const ServiceOrderRouter: I_router = {
    //服務單
    [`${ROUTE_PATH.SERVICE_ORDER.SEARCH}`]: {
        auth: ['service_order.read'],
        component: () => import('../../modules/serviceOrder/page/search.page'),
        title: 'service order search',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.SERVICE_ORDER.DETAIL}`]: {
        auth: ['service_order.read'],
        component: () => import('../../modules/serviceOrder/page/detail.page'),
        title: 'service order',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.SERVICE_ORDER.CREATE}`]: {
        auth: ['service_order.create'],
        component: () => import('../../modules/serviceOrder/page/create.page'),
        title: 'service order create',
        icon: <PlusOutlined />,
    },
    [`${ROUTE_PATH.SERVICE_ORDER.PENDING}`]: {
        auth: ['service_order.read'],
        component: () => import('../../modules/serviceOrder/page/pending.page'),
        title: 'service_order_pending',
        icon: <SearchOutlined />,
    },

    //派工單
    [`${ROUTE_PATH.SERVICE_ORDER.WORK_DIARY.SEARCH}`]: {
        auth: ['service_order_work_diary.read'],
        component: () => import('../../modules/eyeServiceOrderWorkDiary/page/search.page'),
        title: 'work_diaries_search',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.SERVICE_ORDER.WORK_DIARY.DETAIL}`]: {
        auth: ['service_order_work_diary.read'],
        component: () => import('../../modules/eyeServiceOrderWorkDiary/page/detail.page'),
        title: 'work_diaries',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.SERVICE_ORDER.WORK_DIARY.CREATE}`]: {
        auth: ['service_order_work_diary.create'],
        component: () => import('../../modules/eyeServiceOrderWorkDiary/page/create.pgae'),
        title: 'work_diaries_create',
        icon: <PlusOutlined />,
    },
    [`${ROUTE_PATH.SERVICE_ORDER.WORK_DIARY.CALENDAR}`]: {
        auth: ['service_order_work_diary.read'],
        component: () => import('../../modules/eyeServiceOrderWorkDiary/page/workCalendar.page'),
        title: 'work order list',
        icon: <PlusOutlined />,
    },
};
export { ServiceOrderRouter };
