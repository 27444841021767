import React, { useEffect, useState } from 'react';
import { Col, Divider, Layout, Menu, MenuProps, Row, Badge, Button } from 'antd';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import { BellOutlined, CheckOutlined, CloseOutlined, ControlOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import * as localStorageHelper from '@/helpers/localStorage.helper';
import { PageView, PageViewTabs } from '@/modules/common/components/tabs/pageView.tabs';
import styles from './multiTabs.layout.module.css';
import { LanguageDropdown } from '../components/dropdown/language.dropdown';
import { ProfileDropdown } from '../components/dropdown/profile.dropdown';
import { MeQuery } from '@/graphql/types/generate';
import { SalesGroupDropdown } from '../components/dropdown/sales.dropdown';
import _ from 'lodash';
import classnames from 'classnames';
import configs from '@/configs';
import { DndContext, DragEndEvent, DragStartEvent, PointerSensor, useSensor } from '@dnd-kit/core';
import { SortableContext, arrayMove, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { DraggableMenuNode, iconToElement } from '../components/menu/draggable.menuNode';
import { SubMenuType } from 'antd/es/menu/hooks/useItems';
import DragOverlayMenu from '../components/menu/dragOverlay.menu';
interface DynamicMenu extends SubMenuType {
    icon?: string;
}
interface Props {
    name: string;
    menuItems: MenuProps['items'];
    defaultOpenKeys: string[];
    defaultSelectedKeys: string[];
    pages: PageView[];
    selectedKey: string[];
    salesTeamGroup: MeQuery['me']['defaultAvailableSalesTeamGroup'][];
    defaultSalesGroup: MeQuery['me']['defaultAvailableSalesTeamGroup'];
    onProfileItemClick: (key: string) => void;
    onLanguageChange: (key: string) => void;
    onMenuItemClick: (key: string) => void;
    onPageChange: (key: string) => void;
    onPageRemove: (key: string) => void;
}

export const MultiTabsLayout: React.FC<Props> = (props) => {
    const [activeId, setActiveId] = useState<number | string | null>(null);
    const [collapsed, setCollapsed] = useState(false);
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const [menuList, setMenuList] = useState<MenuProps['items']>([]);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const { t } = useTranslation();
    const sensor = useSensor(PointerSensor, {
        activationConstraint: { distance: 10 },
    });
    const {
        name,
        menuItems,
        defaultOpenKeys,
        defaultSelectedKeys,
        pages,
        selectedKey,
        salesTeamGroup,
        defaultSalesGroup,
        onProfileItemClick,
        onLanguageChange,
        onMenuItemClick,
        onPageRemove,
        onPageChange,
    } = props;
    const setMenuListByKey = (list: string[]) => {
        const defaultMenuObj = _.groupBy(menuItems as DynamicMenu[], 'key');
        const filteredList = list.filter((key) => key in defaultMenuObj);
        const newList = filteredList.map((key) => {
            return {
                key: key,
                icon: defaultMenuObj[key][0].icon,
                label: defaultMenuObj[key][0].label,
                children: defaultMenuObj[key][0].children,
            };
        });
        setMenuList(newList);
    };
    const toggleCollapsed = () => {
        if (!collapsed) {
            setOpenKeys([]);
        }
        if (collapsed) {
            setTimeout(() => {
                setOpenKeys(defaultOpenKeys);
            }, 300);
        }
        setCollapsed(!collapsed);
    };

    function onDragStart(event: DragStartEvent) {
        setActiveId(event.active.id);
    }
    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (menuList !== undefined && active.id !== over?.id) {
            setMenuList((prev) => {
                const activeIndex = prev!.findIndex((i) => i?.key === active.id);
                const overIndex = prev!.findIndex((i) => i?.key === over?.id);
                return arrayMove(prev!, activeIndex, overIndex);
            });
        }
    };
    const handleEdit = () => {
        setOpenKeys([]);
        setIsEdit(true);
    };
    const handleEditCancel = () => {
        setIsEdit(false);
        const list = localStorageHelper.getMenu();
        if (list) {
            setMenuListByKey(list);
        }
    };
    const handleEditSave = () => {
        setIsEdit(false);
        localStorageHelper.setMenu(menuList?.map((e: any) => e.key) || []);
    };
    useEffect(() => {
        setOpenKeys(defaultOpenKeys);
    }, []);
    useEffect(() => {
        const list = localStorageHelper.getMenu();
        if (list) {
            // 檢查清單如果有新增的項目，新增在最後
            let oldItems = menuItems?.map((e: any) => e.key) || [];
            let index;
            for (let i = 0; i < list.length; i++) {
                index = oldItems.indexOf(list[i]);
                if (index > -1) {
                    oldItems.splice(index, 1);
                }
            }
            const newList = list.concat(oldItems);
            setMenuListByKey(newList);
            localStorageHelper.setMenu(newList);
        } else {
            const defaultMenu = menuItems as DynamicMenu[];
            setMenuList(menuItems);
            localStorageHelper.setMenu(defaultMenu?.map((e) => e.key));
        }
    }, []);

    return (
        <Layout className={styles['root']}>
            <Layout.Sider
                style={{ position: 'fixed', background: '#406087' }}
                className={`w-[200px] h-screen fixed left-0 top-0 min-h-full z-50  ease-in duration-300`}
                collapsed={collapsed}
            >
                <div className={`flex flex-col z-20 `}>
                    {
                        <div
                            className={`overflow-hidden py-2 ease-in duration-300 flex items-center justify-center cursor-pointer h-[52px] text-#000 ${
                                collapsed ? ' w-[80px] ' : 'w-[200px] z-20  bg-primary'
                            }`}
                        >
                            <img src={'/images/logo/logo_1.png'} height={35} width={35} />
                            <p
                                className={`flex flex-col text-sm font-bold  font-sans overflow-hidden duration-300 truncate mb-0 ${
                                    collapsed ? ' w-0 ' : 'w-[120px]  '
                                }`}
                            >
                                <span className="flex justify-center text-clinico">CLINICO</span>
                                <span className=" flex justify-center text-clinico">SALES REP</span>
                            </p>
                        </div>
                    }
                    <DndContext sensors={[sensor]} onDragEnd={onDragEnd} onDragStart={onDragStart}>
                        <SortableContext
                            items={menuList!.map((i) => i?.key ?? '')}
                            strategy={horizontalListSortingStrategy}
                        >
                            <Menu
                                style={{ background: 'none' }}
                                className={`side-menu   overflow-y-scroll text-[#fff] ${
                                    collapsed
                                        ? 'h-[calc(100vh_-_50px)]'
                                        : 'h-[calc(100vh_-_100px)] mb-3'
                                }  ${isEdit ? 'border-dotted border-2' : ''}`}
                                mode="inline"
                                items={menuList?.map((e) => {
                                    const subItem = e as DynamicMenu;
                                    return {
                                        ...subItem,
                                        icon: iconToElement(subItem.icon),
                                        label: (
                                            <DraggableMenuNode
                                                data-node-key={subItem?.key ?? '0'}
                                                key={subItem?.key}
                                                onActiveBarTransform={() => {}}
                                                isEdit={isEdit}
                                            >
                                                <div>{subItem?.label ?? '0'}</div>
                                            </DraggableMenuNode>
                                        ),
                                        children: subItem.children.map((child: any) => ({
                                            ...child,
                                            icon: iconToElement(child?.icon),
                                        })),
                                    };
                                })}
                                openKeys={openKeys}
                                defaultSelectedKeys={defaultSelectedKeys}
                                selectedKeys={selectedKey}
                                onClick={(info) => (isEdit ? null : onMenuItemClick(info.key))}
                                onOpenChange={(key) => (isEdit ? null : setOpenKeys(key))}
                            />
                        </SortableContext>
                        <DragOverlayMenu menuList={menuList} activeId={activeId} />
                    </DndContext>
                    <div className={`flex justify-end`}>
                        {isEdit ? (
                            <div className={`w-full flex justify-between`}>
                                <Button
                                    key="reset"
                                    icon={<CloseOutlined />}
                                    className="mx-3 btn-danger border-0"
                                    onClick={handleEditCancel}
                                    size="small"
                                />
                                <Button
                                    key="create"
                                    type="primary"
                                    icon={<CheckOutlined />}
                                    className="mx-3"
                                    onClick={handleEditSave}
                                    size="small"
                                />
                            </div>
                        ) : (
                            <ControlOutlined className={styles['editIcon']} onClick={handleEdit} />
                        )}
                    </div>
                </div>
            </Layout.Sider>
            <Layout
                className={`mb-3 ${collapsed ? 'ml-[80px]' : 'ml-[200px]'} ease-in duration-300`}
            >
                <Layout.Header
                    style={{ background: '#f5f5f5', paddingInline: '1rem' }}
                    className={`h-[52px]  pl-4 pr-4 shadow ease-in duration-300 fixed right-0 z-50 ${
                        collapsed ? 'left-[80px]' : 'left-[200px] header'
                    }`}
                >
                    <Row justify="space-between">
                        <Col>
                            {React.createElement(
                                collapsed ? AiOutlineMenuUnfold : AiOutlineMenuFold,
                                {
                                    className: styles['trigger'],
                                    onClick: () => toggleCollapsed(),
                                }
                            )}
                        </Col>
                        <Col>
                            {/* <Badge count={999}>
                                <BellOutlined className={styles['bell']} />
                            </Badge> */}
                            <Divider type="vertical" className={`${styles['divider']} ml-5`} />
                            <SalesGroupDropdown
                                salesTeamGroup={salesTeamGroup}
                                defaultSalesGroup={defaultSalesGroup}
                            />
                            <Divider type="vertical" className={`${styles['divider']} ml-5`} />
                            <ProfileDropdown name={name} onItemClick={onProfileItemClick} />
                            <Divider type="vertical" className={styles['divider']} />
                            <LanguageDropdown onLanguageChange={onLanguageChange} />
                        </Col>
                    </Row>
                </Layout.Header>
                <Layout.Content className={styles['content']}>
                    <input
                        type="checkbox"
                        id={'page-tabs-trigger'}
                        checked={collapsed}
                        onChange={() => {}}
                    />
                    <PageViewTabs pages={pages} onRemove={onPageRemove} onChange={onPageChange} />
                </Layout.Content>
                <Layout.Footer
                    className={classnames(styles['footer'], {
                        ['w-[calc(100vw_-_80px)]']: collapsed,
                        ['w-[calc(100vw_-_200px)]']: !collapsed,
                    })}
                >
                    {t('current system version')}: {configs.version}
                    <Divider type="vertical" className={styles['divider']} />
                    {t('system suggestion')}
                </Layout.Footer>
            </Layout>
        </Layout>
    );
};
