import React from 'react';
import { I_router } from '..';
import { ROUTE_PATH } from '@/consts';
import { SearchOutlined, StockOutlined } from '@ant-design/icons';

const BusinessRouter: I_router = {
    [`${ROUTE_PATH.BUSINESS.SEARCH}`]: {
        auth: ['business.read'],
        component: () => import('@/modules/businesses/pages/search.page'),
        title: 'business search',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.BUSINESS.CREATE}`]: {
        auth: ['business.create'],
        component: () => import('@/modules/businesses/pages/create.page'),
        title: 'business create',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.BUSINESS.DETAIL}`]: {
        auth: ['business.read'],
        component: () => import('@/modules/businesses/pages/detail.page'),
        title: 'business',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.BUSINESS.ANALYSIS}`]: {
        auth: ['business.read'],
        component: () => import('@/modules/businesses/pages/analysis.page'),
        title: 'business analysis',
        icon: <StockOutlined />,
    },
};
export { BusinessRouter };
