import React from 'react';
import '@/styles/antd/custom.less';
import '@/styles/index.css';
import MainRouter from '@/router/index';

interface Props {}

export const App: React.FC<Props> = () => {
    return <MainRouter />;
};
