import React, { useEffect } from 'react';
import { ROUTE_PATH } from '@/consts';
import { PageView } from '@/modules/common/components/tabs/pageView.tabs';
import { pageViewsState } from '@/store/page.store';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { lazyWithSuspense, routers, TabRouterKeys } from '@/router/index';
type Params = {
    path: string;
    targetId?: string | number;
    search?: { [key: string]: any };
    state?: { [key: string]: any };
};

export const usePageProperty = () => {
    const { t } = useTranslation();

    const toSearchParams = (search?: { [key: string]: any }): string | undefined => {
        const value = search ? `?${createSearchParams(search)}` : undefined;
        return value;
    };

    const getKey = (params: Params): string => {
        const search = toSearchParams(params.search);
        const key = `${params.path}${search || ''}`;
        return key;
    };

    const getTitle = (path: string, id?: string, name?: string): React.ReactElement => {
        return (
            <>
                {routers[path].icon} {t(routers[path].title)}
                {id ? ` - ${name ? name : id}` : ''}
            </>
        );
    };

    return { getKey, getTitle, toSearchParams };
};

export const usePageView = () => {
    const [pages, setPages] = useRecoilState(pageViewsState);
    const { getKey, getTitle, toSearchParams } = usePageProperty();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (pages.find((page: PageView) => page.key === '/home')) return;

        const title = getTitle(ROUTE_PATH.HOME);
        const page = {
            key: ROUTE_PATH.HOME,
            title: title,
            path: ROUTE_PATH.HOME,
            content: React.createElement(lazyWithSuspense(routers[ROUTE_PATH.HOME].component)),
            closable: false,
        };

        setPages([page]);
    }, []);

    const handleOpenPage = (params: Params) => {
        const key = getKey(params);
        navigate(key, { state: params.state });
    };

    const handlePageRemove = (params: Params) => {
        const key = getKey(params);

        let filteredPages = pages.filter((p) => p.key != key);
        setPages(filteredPages);

        if (`${location.pathname}${location.search}` === key) {
            let newKey =
                pages[pages.length - 1].key === key
                    ? pages[pages.length - 2].key
                    : pages[pages.length - 1].key;
            navigate(newKey);
        }
    };

    const handlePageChange = (params: Params) => {
        const key = getKey(params);
        navigate(key);
    };

    const openPage = (params: Params) => {
        const tabPath = Object.keys(routers).find((path: TabRouterKeys) => path === params.path);
        let page: PageView;
        const searchParams = toSearchParams(params.search);
        const key = getKey(params);
        const id = params.search?.get('code') || '';
        const name = params.search?.get('name') || '';

        if (key && !pages.find((p) => p.key == key) && tabPath) {
            const title = getTitle(tabPath, id, name);
            page = {
                key: key,
                title: title,
                path: params.path,
                params: searchParams,
                content: React.createElement(lazyWithSuspense(routers[tabPath].component)),
                closable: key != '/home',
            };
            setPages((pre) => [...pre, page]);
        }
    };

    return {
        handleOpenPage,
        handlePageRemove,
        handlePageChange,
        openPage,
    };
};
