import { Dropdown, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { MeQuery, SalesTeamGroup } from '@/graphql/types/generate';
import * as localStorageHelper from '@/helpers/localStorage.helper';
import { useRecoilState } from 'recoil';
import { salesGroupState } from '@/store/salesGroup.store';
import { usePageView } from '@/hooks/usePage.hook';
import { ROUTE_PATH } from '@/consts';

interface Props {
    salesTeamGroup: MeQuery['me']['defaultAvailableSalesTeamGroup'][];
    defaultSalesGroup: MeQuery['me']['defaultAvailableSalesTeamGroup'];
    onItemClick?: (key: string) => void;
}

export const SalesGroupDropdown: React.FC<Props> = ({ salesTeamGroup, defaultSalesGroup }) => {
    const [salesGroup, setSalesGroup] = useRecoilState(salesGroupState);
    const { handlePageChange } = usePageView();

    const onChange = (value: any) => {
        const findKey = salesTeamGroup.find((item) => {
            return item?.code === value.key;
        });
        let me = localStorageHelper.getMe();
        setSalesGroup(findKey);
        localStorageHelper.setMe({ ...me, defaultAvailableSalesTeamGroup: findKey });
        handlePageChange({ path: ROUTE_PATH.HOME });
        window.location.reload();
    };
    const group = salesTeamGroup.map((item) => ({ key: item?.code ?? '', label: item?.name }));

    return (
        <Dropdown
            menu={{
                items: group,
                onClick: onChange,
            }}
            overlayClassName="header-dropdown-menus"
        >
            <span className="header-dropdown">
                {<span className="font-bold">{salesGroup?.name}</span>}
            </span>
        </Dropdown>
    );
};
