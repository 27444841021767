import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { RecoilRoot } from 'recoil';
import './i18n';
import {
    ApolloProvider,
    ApolloClient,
    InMemoryCache,
    from,
    Observable,
    ApolloLink,
    split,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import configs from './configs';
import * as localStorageHelper from '@/helpers/localStorage.helper';
import { logout, relogin } from '@/hooks/useAuth.hook';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';

const wsClient = createClient({
    url: `${configs.api.endpoint!}`.replace(/^http/, 'ws'),
    connectionParams: () => {
        return { authorization: localStorageHelper.getToken() || null };
    },
});
const wsLink = new GraphQLWsLink(wsClient);

const ErrorLogLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        if (
            graphQLErrors[0].extensions.statusCode == 401 &&
            graphQLErrors[0].extensions.code == 'UNAUTHORIZED_ERROR' &&
            graphQLErrors[0].message == 'Relogin required'
        ) {
            return new Observable((observer) => {
                (async () => {
                    try {
                        await relogin(client);

                        const previousHeaders = operation.getContext().headers;
                        const newToken = localStorageHelper.getToken();
                        operation.setContext({
                            headers: {
                                ...previousHeaders,
                                authorization: newToken,
                            },
                        });

                        const subscriber = {
                            next: observer.next.bind(observer),
                            error: observer.error.bind(observer),
                            complete: observer.complete.bind(observer),
                        };

                        forward(operation).subscribe(subscriber);
                    } catch (error) {
                        observer.error(error);
                    }
                })();
            });
        }
        if (
            graphQLErrors[0].extensions.statusCode == 401 &&
            graphQLErrors[0].extensions.code == 'UNAUTHORIZED_ERROR' &&
            graphQLErrors[0].message == 'Invalid SSO Token'
        ) {
            logout();
        }
    }
});

const httpLink = createUploadLink({
    uri: configs.api.endpoint,
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
);

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const token = localStorageHelper.getToken();
    let authorization = {};
    let me = localStorageHelper.getMe();
    if (token) {
        authorization = { authorization: token };
    }
    if (me) {
        authorization = {
            authorization: token,
            'x-sales-team-group-code': me?.defaultAvailableSalesTeamGroup?.code,
        };
    }
    operation.setContext({
        headers: {
            'web-version': configs.version,
            'Apollo-Require-Preflight': true,
            ...authorization,
        },
    });

    return forward(operation);
});

const link = from([ErrorLogLink, authMiddleware, splitLink]);

const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
        },
        query: {
            fetchPolicy: 'no-cache',
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <ApolloProvider client={client}>
        <RecoilRoot>
            <App />
        </RecoilRoot>
    </ApolloProvider>
);
