import React, { ReactNode, useEffect, useMemo } from 'react';
import type { PropsWithChildren } from 'react';
import { MultiTabsLayout } from './modules/common/layouts/multiTabs.layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuProps } from 'antd';
import { useRecoilState } from 'recoil';
import { ROUTE_PATH } from '@/consts';
import { pageViewsState } from './store/page.store';
import { salesGroupState } from './store/salesGroup.store';
import { usePageView } from './hooks/usePage.hook';
import * as localStorageHelper from '@/helpers/localStorage.helper';
import { SubMenuType } from 'antd/lib/menu/hooks/useItems';
import { routers } from '@/router/index';

interface Props extends PropsWithChildren {}

const Container: React.FC<Props> = (props) => {
    const [pages, setPages] = useRecoilState(pageViewsState);
    const [_, setSalesGroup] = useRecoilState(salesGroupState);
    const { handleOpenPage, handlePageRemove, handlePageChange, openPage } = usePageView();

    const me = localStorageHelper.getMe();
    const location = useLocation();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (location.pathname !== ROUTE_PATH.HOME) {
            let params = new URLSearchParams(location.search);
            openPage({
                path: location.pathname,
                search: location.search ? params : undefined,
            });
        }
    }, [location.key]);

    useEffect(() => {
        const meState = localStorageHelper.getMe();
        setSalesGroup(meState?.defaultAvailableSalesTeamGroup);
    }, []);

    const handleProfileItemClick = (key: string) => {
        navigate(key);
    };

    const handleLanguageChange = async (key: string) => {
        console.log('lanchange', key);
        await i18n.changeLanguage(key);
        let newPage = pages.map((page) => ({
            ...page,
        }));
        setPages(newPage);
    };

    const items: MenuProps['items'] = [
        {
            key: ROUTE_PATH.HOME,
            icon: 'HomeOutlined',
            label: t('home'),
        },
        {
            key: ROUTE_PATH.CUSTOMER.INDEX,
            label: t('customer'),
            icon: 'TeamOutlined',
            children: [
                {
                    key: ROUTE_PATH.CUSTOMER.SEARCH,
                    label: t('customer search'),
                    icon: 'SearchOutlined',
                },
                // 隱藏建立客戶
                // {
                //     key: ROUTE_PATH.CUSTOMER.CREATE,
                //     label: t('customer create'),
                //     icon: "PlusOutlined",
                // },
                {
                    key: ROUTE_PATH.CONTACT_PERSON.SEARCH,
                    label: t('search contact person'),
                    icon: 'SearchOutlined',
                },
                {
                    key: ROUTE_PATH.CONTACT_PERSON.CREATE,
                    label: t('create contact person'),
                    icon: 'PlusOutlined',
                },
                {
                    key: ROUTE_PATH.COMPETITOR.SEARCH,
                    label: t('competitor search'),
                    icon: 'SearchOutlined',
                },
                // 隱藏建立競爭對手
                // {
                //     key: ROUTE_PATH.COMPETITOR.CREATE,
                //     label: t('create competitor'),
                //     icon: "PlusOutlined",
                // },
                {
                    key: ROUTE_PATH.CUSTOMER.ANALYSIS,
                    label: t('customer analysis'),
                    icon: 'StockOutlined',
                },
            ],
        },
        {
            key: ROUTE_PATH.BUSINESS.INDEX,
            label: t('business'),
            icon: 'RocketOutlined',
            children: [
                {
                    key: ROUTE_PATH.BUSINESS.SEARCH,
                    label: t('business search'),
                    icon: 'SearchOutlined',
                },
                {
                    key: ROUTE_PATH.BUSINESS.CREATE,
                    label: t('business create'),
                    icon: 'PlusOutlined',
                },
                {
                    key: ROUTE_PATH.BUSINESS.ANALYSIS,
                    label: t('business analysis'),
                    icon: 'StockOutlined',
                },
            ],
        },
        {
            key: ROUTE_PATH.VISIT.INDEX,
            label: t('visit'),
            icon: 'CalendarOutlined',
            children: [
                {
                    key: ROUTE_PATH.VISIT.SEARCH,
                    label: t('visit search'),
                    icon: 'SearchOutlined',
                },
                {
                    key: ROUTE_PATH.VISIT.CREATE,
                    label: t('visit create'),
                    icon: 'PlusOutlined',
                },
                {
                    key: ROUTE_PATH.VISIT.CALENDAR,
                    label: '拜訪一覽表',
                    icon: 'ScheduleOutlined',
                },
                {
                    key: ROUTE_PATH.VISIT.ANALYSIS,
                    label: t('visit_analysis'),
                    icon: 'StockOutlined',
                },
                {
                    key: ROUTE_PATH.VISIT.ACTIONANALYSIS,
                    label: t('visit_action_analysis'),
                    icon: 'StockOutlined',
                },
                {
                    key: ROUTE_PATH.VISIT.GOALANALYSIS,
                    label: t('visit_goal_analysis'),
                    icon: 'UnorderedListOutlined',
                },
                {
                    key: ROUTE_PATH.VISIT.ACTIONDETAIL,
                    label: t('visit_action_detail'),
                    icon: 'UnorderedListOutlined',
                },
            ],
        },
        {
            key: ROUTE_PATH.QUOTATION.INDEX,
            label: t('quotation'),
            icon: 'DollarOutlined',
            children: [
                {
                    key: ROUTE_PATH.QUOTATION.SEARCH,
                    label: t('quotation search'),
                    icon: 'SearchOutlined',
                },
                {
                    key: ROUTE_PATH.QUOTATION.CREATE,
                    label: t('quotation create'),
                    icon: 'PlusOutlined',
                },
            ],
        },
        {
            key: ROUTE_PATH.PRODUCT.INDEX,
            label: t('product eye'),
            icon: 'ShoppingCartOutlined',
            children: [
                {
                    key: ROUTE_PATH.PRODUCT.SEARCH,
                    label: t('product search'),
                    icon: 'SearchOutlined',
                },
                {
                    key: ROUTE_PATH.PRODUCT.PROMOTIONSEARCH,
                    label: t('promotion search'),
                    icon: 'SearchOutlined',
                },
            ],
        },
        {
            key: ROUTE_PATH.PERFORMANCE.INDEX,
            label: t('performance'),
            icon: 'RocketOutlined',
            children: [
                {
                    key: ROUTE_PATH.PERFORMANCE.ORDER.SEARCH,
                    label: t('order_search'),
                    icon: 'SearchOutlined',
                },
                {
                    key: ROUTE_PATH.PERFORMANCE.SALES_INVOICE.SEARCH,
                    label: t('nav_sales_invoice_search'),
                    icon: 'SearchOutlined',
                },
                {
                    key: ROUTE_PATH.PERFORMANCE.ANALYSIS,
                    label: t('sales_analysis'),
                    icon: 'StockOutlined',
                },
                {
                    key: ROUTE_PATH.PERFORMANCE.DASHBOARD,
                    label: t('dashboard_performance'),
                    icon: 'RocketOutlined',
                },
                {
                    key: ROUTE_PATH.PERFORMANCE.DASHBOARDPRODUCTS,
                    label: t('dashboard_products'),
                    icon: 'RocketOutlined',
                },
                {
                    key: ROUTE_PATH.PERFORMANCE.SALESFORECAST,
                    label: t('sales_forecast'),
                    icon: 'TeamOutlined',
                },
                {
                    key: ROUTE_PATH.PERFORMANCE.PRODUCTSFORECAST,
                    label: t('products_forecast'),
                    icon: 'TeamOutlined',
                },
                {
                    key: ROUTE_PATH.PERFORMANCE.MARKETINGTARGET,
                    label: t('marketing_target'),
                    icon: 'TeamOutlined',
                },
            ],
        },
        {
            key: ROUTE_PATH.SERVICE_ORDER.INDEX,
            label: t('service order'),
            icon: 'SnippetsOutlined',
            children: [
                {
                    key: ROUTE_PATH.SERVICE_ORDER.SEARCH,
                    label: t('service order search'),
                    icon: 'SearchOutlined',
                },
                {
                    key: ROUTE_PATH.SERVICE_ORDER.PENDING,
                    label: t('service_order_pending'),
                    icon: 'SearchOutlined',
                },
                {
                    key: ROUTE_PATH.SERVICE_ORDER.CREATE,
                    label: t('service order create'),
                    icon: 'PlusOutlined',
                },
                {
                    key: ROUTE_PATH.SERVICE_ORDER.WORK_DIARY.SEARCH,
                    label: t('work_diaries_search'),
                    icon: 'SearchOutlined',
                },
                {
                    key: ROUTE_PATH.SERVICE_ORDER.WORK_DIARY.CREATE,
                    label: t('work_diaries_create'),
                    icon: 'PlusOutlined',
                },
                {
                    key: ROUTE_PATH.SERVICE_ORDER.WORK_DIARY.CALENDAR,
                    label: t('work order list'),
                    icon: 'CalendarOutlined',
                },
            ],
        },
        {
            key: ROUTE_PATH.INVENTORY.INDEX,
            label: t('inventory'),
            icon: 'SnippetsOutlined',
            children: [
                {
                    key: ROUTE_PATH.INVENTORY.SEARCH,
                    label: t('inventory search'),
                    icon: 'SearchOutlined',
                },
            ],
        },
    ];

    const defaultOpenKeys: string[] = [
        ROUTE_PATH.CUSTOMER.INDEX,
        ROUTE_PATH.BUSINESS.INDEX,
        // ROUTE_PATH.CONTACT_PERSON.INDEX,
        ROUTE_PATH.VISIT.INDEX,
    ];

    const defaultSelectedKeys: string[] = [`${location.pathname}${location.search}`];

    const selectedKey: string[] = [`${location.pathname}${location.search}`];
    const filteredAuthMenuItems = useMemo(
        () =>
            items?.filter((item) => {
                const permissions = me?.userPermission?.codes;
                const subMenuItem = item as SubMenuType;
                const filteredChildrenItem = subMenuItem?.children?.filter((child) =>
                    permissions?.some((code: string) => {
                        const auth = routers[child?.key as string]?.auth as string[];
                        if (!auth) return true;
                        return auth.includes(code);
                    })
                );
                subMenuItem.children = filteredChildrenItem;
                return filteredChildrenItem?.length > 0;
            }),
        [items, me?.userPermission?.codes, routers]
    );
    return (
        <MultiTabsLayout
            name={me?.name!}
            salesTeamGroup={me?.availableSalesTeamGroups || []}
            defaultSalesGroup={me?.defaultAvailableSalesTeamGroup}
            menuItems={filteredAuthMenuItems}
            defaultOpenKeys={defaultOpenKeys}
            defaultSelectedKeys={defaultSelectedKeys}
            pages={pages}
            onProfileItemClick={handleProfileItemClick}
            onLanguageChange={handleLanguageChange}
            selectedKey={selectedKey}
            onMenuItemClick={(key) => handleOpenPage({ path: key })}
            onPageChange={(key) => handlePageChange({ path: key })}
            onPageRemove={(key) => handlePageRemove({ path: key })}
        />
    );
};
export default Container;
