import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ROUTE_PATH } from '@/consts';
import * as localStorageHelper from '@/helpers/localStorage.helper';

interface Props {}

const AuthGuard: React.FC<Props> = (props) => {
    const location = useLocation();
    const me = localStorageHelper.getMe();

    if (!me) {
        return (
            <Navigate
                replace
                to={ROUTE_PATH.LOGIN}
                state={{
                    page: location.pathname,
                    query: location.search,
                }}
            />
        );
    }

    return <Outlet />;
};

export default AuthGuard;
