import common from './common.json';
import business from './business.json';
import customer from './customer.json';
import contactPerson from './contactPerson.json';
import competitor from './competitor.json';
import serviceOrder from './serviceOrder.json';
import product from './product.json';
import promotion from './promotion.json';
import visit from './visit.json';
import quotation from './quotation.json';
import performance from './performance.json';
import workOrder from './workOrder.json';
import inventory from './inventory.json';

export const zhTW = {
    translation: {
        ...common,
        ...business,
        ...competitor,
        ...contactPerson,
        ...customer,
        ...quotation,
        ...performance,
        ...product,
        ...promotion,
        ...serviceOrder,
        ...visit,
        ...workOrder,
        ...inventory,
    },
};
