import React from 'react';
import { I_router } from '..';
import { ROUTE_PATH } from '@/consts';
import { SearchOutlined } from '@ant-design/icons';

const ProductRouter: I_router = {
    [`${ROUTE_PATH.PRODUCT.SEARCH}`]: {
        auth: ['product.read'],
        component: () => import('@/modules/products/page/searchProducts.page'),
        title: 'product search',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.PRODUCT.DETAIL}`]: {
        auth: ['product.read'],
        component: () => import('@/modules/products/page/productDetail.page'),
        title: 'product search',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.PRODUCT.PROMOTIONSEARCH}`]: {
        auth: ['promotion.read'],
        component: () => import('@/modules/promotions/page/searchPromotionProducts.page'),
        title: 'promotion search',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.PRODUCT.PROMOTIONDETAIL}`]: {
        auth: ['promotion.read'],
        component: () => import('@/modules/promotions/page/promotionProductsDetail.page'),
        title: 'promotion search',
        icon: <SearchOutlined />,
    },
};
export { ProductRouter };
