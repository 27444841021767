import { MeQuery } from '@/graphql/types/generate';
import { CommonlySearch } from '@/modules/common/components/input/search.form';
import { MenuProps } from 'antd';

const KEY = {
    USER_INFO: 'user_info',
    TOKEN: 'token',
    ME: 'me',
    COMMONLY_SEARCH: 'commonly_search',
    MENU: 'menu',
};

const getJSONObject = <T>(key: string): T | null => {
    let data: T | null = null;
    const value = localStorage.getItem(key);
    if (value) {
        try {
            data = JSON.parse(value);
        } catch {
            data = null;
        }
    }
    return data;
};

export const getUserInfo = (): any | null => {
    return getJSONObject<any>(KEY.USER_INFO);
};

export const setUserInfo = (value: any) => {
    localStorage.setItem(KEY.USER_INFO, JSON.stringify(value));
};

export const getToken = (): string | null => {
    return localStorage.getItem(KEY.TOKEN);
};

export const setToken = (value: string) => {
    localStorage.setItem(KEY.TOKEN, value);
};

export const getMe = (): MeQuery['me'] | null => {
    return getJSONObject<MeQuery['me']>(KEY.ME);
};

export const setMe = (value: any['me']) => {
    localStorage.setItem(KEY.ME, JSON.stringify(value));
};

export const getCommonlySearch = () => {
    return getJSONObject<CommonlySearch[]>(KEY.COMMONLY_SEARCH);
};

export const setCommonlySearch = (value: CommonlySearch[]) => {
    localStorage.setItem(KEY.COMMONLY_SEARCH, JSON.stringify(value));
};

export const setLanguage = (value: string) => {
    localStorage.setItem('language', value);
};

export const getLanguage = () => {
    return localStorage.getItem('language');
};

export const cleanAll = (): void => {
    localStorage.removeItem(KEY.USER_INFO);
    localStorage.removeItem(KEY.TOKEN);
    localStorage.removeItem(KEY.ME);
};

export const getMenu = (): string[] | null => {
    return getJSONObject<string[]>(KEY.MENU);
};

export const setMenu = (value: string[]) => {
    localStorage.setItem(KEY.MENU, JSON.stringify(value));
};
