import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// resources
import { zhCN, zhTW } from '@/locales';

i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
        debug: process.env.NODE_ENV !== 'production',
        fallbackLng: 'zh-TW',
        lng: 'zh-TW',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            'zh-TW': zhTW,
            'zh-CN': zhCN,
        },
    });

export default i18next;
