import React from 'react';
import { I_router } from '..';
import { ROUTE_PATH } from '@/consts';
import { RocketOutlined, SearchOutlined, StockOutlined, TeamOutlined } from '@ant-design/icons';

const PerformanceRouter: I_router = {
    [`${ROUTE_PATH.PERFORMANCE.ORDER.SEARCH}`]: {
        auth: ['achievement.nav_order.read'],
        component: () => import('@/modules/performance/page/order.search.page'),
        title: 'order_search',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.PERFORMANCE.ORDER.DETAIL}`]: {
        auth: ['achievement.nav_order.read'],
        component: () => import('@/modules/performance/page/order.detail.page'),
        title: 'order',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.PERFORMANCE.SALES_INVOICE.SEARCH}`]: {
        auth: ['achievement.nav_sales_invoice.read'],
        component: () => import('@/modules/performance/page/salesInvoices.search.page'),
        title: 'nav_sales_invoice_search',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.PERFORMANCE.SALES_INVOICE.DETAIL}`]: {
        auth: ['achievement.nav_sales_invoice.read'],
        component: () => import('@/modules/performance/page/salesInvoices.detail.page'),
        title: 'nav_sales_invoice',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.PERFORMANCE.ANALYSIS}`]: {
        auth: ['achievement.analysis'],
        component: () => import('@/modules/performance/page/analysis.page'),
        title: 'sales_analysis',
        icon: <StockOutlined />,
    },
    [`${ROUTE_PATH.PERFORMANCE.DASHBOARD}`]: {
        auth: ['ec_dashboard_analysis.read'],
        component: () => import('@/modules/performance/page/dashboard.page'),
        title: 'dashboard_performance',
        icon: <RocketOutlined />,
    },
    [`${ROUTE_PATH.PERFORMANCE.DASHBOARDPRODUCTS}`]: {
        auth: ['sales_product_analysis.read'],
        component: () => import('@/modules/performance/page/dashboardProducts.page'),
        title: 'dashboard_products',
        icon: <RocketOutlined />,
    },
    [`${ROUTE_PATH.PERFORMANCE.SALESFORECAST}`]: {
        auth: ['sales_user_forecast.read'],
        component: () => import('@/modules/performance/page/salesForecast.search.page'),
        title: 'sales_forecast',
        icon: <TeamOutlined />,
    },
    [`${ROUTE_PATH.PERFORMANCE.PRODUCTSFORECAST}`]: {
        auth: ['sales_product_user_forecast.read'],
        component: () => import('@/modules/performance/page/productsForecast.search.page'),
        title: 'products_forecast',
        icon: <TeamOutlined />,
    },
    [`${ROUTE_PATH.PERFORMANCE.MARKETINGTARGET}`]: {
        auth: ['sales_product_overall.read'],
        component: () => import('@/modules/performance/page/marketingTarget.search.page'),
        title: 'marketing_target',
        icon: <TeamOutlined />,
    },
};
export { PerformanceRouter };
