import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Spin } from 'antd';

import { ROUTE_PATH } from '@/consts';
import { DeafultSpin } from '@/modules/common/components/spin/default.spin';
import AuthGuard from '@/modules/common/components/authGuard';
import Container from '@/container';
import { CommonRouter } from './routers/common';
import { BusinessRouter } from './routers/business';
import { CustomerRouter } from './routers/customer';
import { VisitRouter } from './routers/visit';
import { QuotationRouter } from './routers/quotation';
import { FixedAssetRouter } from './routers/fixedAsset';
import { ProductRouter } from './routers/product';
import { PerformanceRouter } from './routers/performance';
import { ServiceOrderRouter } from './routers/serviceOrder';
import { InventoryRouter } from './routers/inventory';

export interface I_router {
    [key: string]: {
        auth: any[];
        component: () => Promise<any>;
        title: string;
        icon: JSX.Element;
    };
}

export function lazyWithSuspense<T extends React.ComponentType<any>>(
    factory: () => Promise<{ default: T }>
) {
    const Page: any = React.lazy<T>(factory);
    const Wrapped: React.FC<React.ComponentProps<T>> = (props) => (
        <React.Suspense
            fallback={
                <div
                    style={{
                        marginTop: '30px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Spin indicator={<DeafultSpin />} />
                </div>
            }
        >
            <Page {...props} />
        </React.Suspense>
    );
    return Wrapped;
}

const LoginPage = lazyWithSuspense(() => import('@/modules/common/pages/login.page'));

const MainRouter: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Navigate to={ROUTE_PATH.HOME} replace />} />
                <Route path={ROUTE_PATH.LOGIN} element={<LoginPage />} />
                <Route element={<AuthGuard />}>
                    <Route element={<Container />}>
                        {Object.keys(routers).map(((path: TabRouterKeys) => (
                            <Route
                                key={path}
                                path={path as string}
                                element={React.createElement(
                                    lazyWithSuspense(routers[path].component)
                                )}
                            />
                        )) as any)}
                    </Route>
                </Route>
                <Route
                    path="*"
                    element={
                        <main style={{ textAlign: 'center' }}>
                            <h1>404</h1>
                        </main>
                    }
                />
            </Routes>
        </Router>
    );
};

export default MainRouter;

export const routers: I_router = {
    ...CommonRouter,
    ...CustomerRouter,
    ...BusinessRouter,
    ...VisitRouter,
    ...QuotationRouter,
    ...FixedAssetRouter,
    ...ProductRouter,
    ...PerformanceRouter,
    ...ServiceOrderRouter,
    ...InventoryRouter,
};

export type TabRouter = typeof routers;
export type TabRouterKeys = keyof TabRouter;
