import React from 'react';
import { I_router } from '..';
import { ROUTE_PATH } from '@/consts';
import { SearchOutlined } from '@ant-design/icons';

const QuotationRouter: I_router = {
    [`${ROUTE_PATH.QUOTATION.SEARCH}`]: {
        auth: ['quotation.read'],
        component: () => import('@/modules/quotations/pages/search.page'),
        title: 'quotation search',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.QUOTATION.CREATE}`]: {
        auth: ['quotation.create'],
        component: () => import('@/modules/quotations/pages/create.page'),
        title: 'quotation create',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.QUOTATION.DETAIL}`]: {
        auth: ['quotation.read'],
        component: () => import('@/modules/quotations/pages/detail.page'),
        title: 'quotation',
        icon: <SearchOutlined />,
    },
};
export { QuotationRouter };
