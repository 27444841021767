import packageInfo from '../package.json';
export default {
    api: {
        endpoint: process.env['REACT_APP_API_ENDPOINT'],
        exportEndpoint: process.env['REACT_APP_EXPORT_ENDPOINT'],
    },
    sso: {
        url: process.env['REACT_APP_SSO_URL'],
        domain: process.env['REACT_APP_SSO_DOMAIN'],
    },
    table: {
        perPageRows: 10,
    },
    list: {
        limit: 50,
    },
    product: {
        table: {
            perPageRows: 5,
        },
    },
    version: packageInfo.version,
};
