import React, { useState, useEffect } from 'react';
import { Dropdown } from 'antd';
import { IoLanguageOutline } from 'react-icons/io5';
import { CheckOutlined } from '@ant-design/icons';
import { MenuDividerType } from 'antd/es/menu/hooks/useItems';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { useTranslation } from 'react-i18next';
import * as languageHeler from '@/helpers/localStorage.helper';

interface Props {
    onLanguageChange: (key: string) => void;
}

interface Props {
    onLanguageChange: (key: string) => void;
}

enum LangKey {
    'zh-TW' = 'zh-TW',
    'zh-CN' = 'zh-CN',
}

export const LanguageDropdown: React.FC<Props> = ({ onLanguageChange }) => {
    const [language, setLanguage] = useState<LangKey>();
    const { i18n } = useTranslation();
    const dividerItem: MenuDividerType = {
        type: 'divider',
    };
    const items = [
        {
            key: LangKey['zh-TW'],
            label: (
                <div className="flex justify-between">
                    <span>中文(繁)</span>
                    {language === LangKey['zh-TW'] && <CheckOutlined />}
                </div>
            ),
        },
        dividerItem,
        {
            key: LangKey['zh-CN'],
            label: (
                <div className="flex justify-between">
                    <span>中文(简)</span>
                    {language === LangKey['zh-CN'] && <CheckOutlined />}
                </div>
            ),
        },
    ];

    const handleLanguageChange: MenuClickEventHandler = (item) => {
        setLanguage(item.key as LangKey);
        onLanguageChange(item.key);
        languageHeler.setLanguage(item.key);
    };

    useEffect(() => {
        const defaultLangue = languageHeler.getLanguage() ?? navigator.language ?? 'zh-TW';

        const setDefaultLanguage = async () => {
            console.log(defaultLangue);
            await i18n.changeLanguage(defaultLangue);
            setLanguage(defaultLangue as LangKey);
        };
        setDefaultLanguage();
    }, []);

    return (
        <Dropdown
            menu={{
                items: items,
                onClick: handleLanguageChange,
            }}
            overlayClassName="header-dropdown-menus"
        >
            <span className="header-dropdown">
                <IoLanguageOutline className="text-lg" />
            </span>
        </Dropdown>
    );
};
