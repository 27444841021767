import React from 'react';
import { I_router } from '..';
import { ROUTE_PATH } from '@/consts';
import { SearchOutlined } from '@ant-design/icons';

const FixedAssetRouter: I_router = {
    [`${ROUTE_PATH.RENTALRECORD.DETAIL}`]: {
        auth: [],
        component: () => import('@/modules/eyeFixedAssetRentalRecords/pages/detail.page'),
        title: 'eye fixed asset rental record',
        icon: <SearchOutlined />,
    },
};
export { FixedAssetRouter };
