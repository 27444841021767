import React from 'react';
import { I_router } from '..';
import { ROUTE_PATH } from '@/consts';
import { ScheduleOutlined, SearchOutlined } from '@ant-design/icons';

const VisitRouter: I_router = {
    [`${ROUTE_PATH.VISIT.SEARCH}`]: {
        auth: ['visit.read'],
        component: () => import('@/modules/visits/pages/search.page'),
        title: 'visit search',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.VISIT.CREATE}`]: {
        auth: ['visit.create'],
        component: () => import('@/modules/visits/pages/create.page'),
        title: 'visit create',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.VISIT.DETAIL}`]: {
        auth: ['visit.read'],
        component: () => import('@/modules/visits/pages/detail.page'),
        title: 'visit',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.VISIT.CALENDAR}`]: {
        auth: ['visit.read'],
        component: () => import('@/modules/visits/pages/calendar.page'),
        title: '拜訪一覽表',
        icon: <ScheduleOutlined />,
    },
    [`${ROUTE_PATH.VISIT.CHECK_IN}`]: {
        auth: ['visit.read'],
        component: () => import('@/modules/visits/pages/checkIn.detail.page'),
        title: 'checkIn',
        icon: <ScheduleOutlined />,
    },
    [`${ROUTE_PATH.VISIT.ANALYSIS}`]: {
        auth: ['visit.read'],
        component: () => import('@/modules/visits/pages/analysis.page'),
        title: 'visit_analysis',
        icon: <ScheduleOutlined />,
    },
    [`${ROUTE_PATH.VISIT.ACTIONANALYSIS}`]: {
        auth: ['analysis.visit_action.read'],
        component: () => import('@/modules/visits/pages/actionAnalysis.page'),
        title: 'visit_action_analysis',
        icon: <ScheduleOutlined />,
    },
    [`${ROUTE_PATH.VISIT.GOALANALYSIS}`]: {
        auth: ['analysis.visit_goal.read'],
        component: () => import('@/modules/visits/pages/goalAnalysis.page'),
        title: 'visit_goal_analysis',
        icon: <ScheduleOutlined />,
    },
    [`${ROUTE_PATH.VISIT.ACTIONDETAIL}`]: {
        auth: ['analysis.visit_detail.read'],
        component: () => import('@/modules/visits/pages/actionDetail.page'),
        title: 'visit_action_detail',
        icon: <ScheduleOutlined />,
    },
};
export { VisitRouter };
