import React from 'react';
import { I_router } from '..';
import { ROUTE_PATH } from '@/consts';
import { SearchOutlined } from '@ant-design/icons';

const CustomerRouter: I_router = {
    [`${ROUTE_PATH.CUSTOMER.SEARCH}`]: {
        auth: ['customer.read'],
        component: () => import('@/modules/customers/pages/search.page'),
        title: 'customer search',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.CUSTOMER.CREATE}`]: {
        auth: ['customer.create'],
        component: () => import('@/modules/customers/pages/create.page'),
        title: 'customer create',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.CUSTOMER.DETAIL}`]: {
        auth: ['customer.read'],
        component: () => import('@/modules/customers/pages/detail.page'),
        title: 'customer detail',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.CUSTOMER.ANALYSIS}`]: {
        auth: ['analysis.customer_level.read'],
        component: () => import('@/modules/customers/pages/analysis.page'),
        title: 'customer analysis',
        icon: <SearchOutlined />,
    },

    //競爭對手
    [`${ROUTE_PATH.COMPETITOR.SEARCH}`]: {
        auth: ['provider.read'],
        component: () => import('@/modules/competitors/pages/search.page'),
        title: 'competitor search',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.COMPETITOR.CREATE}`]: {
        auth: ['provider.create'],
        component: () => import('@/modules/competitors/pages/create.page'),
        title: 'create competitor',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.COMPETITOR.DETAIL}`]: {
        auth: ['provider.read'],
        component: () => import('@/modules/competitors/pages/detail.page'),
        title: 'competitor',
        icon: <SearchOutlined />,
    },

    //聯絡人
    [`${ROUTE_PATH.CONTACT_PERSON.SEARCH}`]: {
        auth: ['contact_people.read'],
        component: () => import('@/modules/contactPerson/pages/search.page'),
        title: 'search contact person',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.CONTACT_PERSON.DETAIL}`]: {
        auth: ['contact_people.read'],
        component: () => import('@/modules/contactPerson/pages/detail.page'),
        title: 'detail contact person',
        icon: <SearchOutlined />,
    },
    [`${ROUTE_PATH.CONTACT_PERSON.CREATE}`]: {
        auth: ['contact_people.create'],
        component: () => import('@/modules/contactPerson/pages/create.page'),
        title: 'create contact person',
        icon: <SearchOutlined />,
    },
};
export { CustomerRouter };
