import React, { useEffect } from 'react';
import '@/styles/antd/custom.less';
import '@/styles/index.css';

interface Props {}

const HomePage: React.FC<Props> = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={'/images/home_image.svg'} width={800} />
        </div>
    );
};

export default HomePage;
