import { EnumSortDirection } from '@/graphql/types/generate';

export const ROUTE_PATH = {
    LOGIN: '/login',
    HOME: '/home',
    CUSTOMER: {
        INDEX: '/customer',
        SEARCH: '/customer/search',
        CREATE: '/customer/create',
        DETAIL: '/customer/detail',
        ANALYSIS: '/customer/analysis',
    },
    CONTACT_PERSON: {
        SEARCH: '/customer/contact-person/search',
        CREATE: '/customer/contact-person/create',
        DETAIL: '/customer/contact-person/detail',
    },
    BUSINESS: {
        INDEX: '/business',
        SEARCH: '/business/search',
        CREATE: '/business/create',
        DETAIL: '/business/detail',
        ANALYSIS: '/business/analysis',
    },
    VISIT: {
        INDEX: '/visit',
        SEARCH: '/visit/search',
        CREATE: '/visit/create',
        DETAIL: '/visit/detail',
        CALENDAR: '/visit/calendar',
        CHECK_IN: '/visit/check_in',
        ANALYSIS: '/visit/analysis',
        ACTIONANALYSIS: '/visit/actionAnalysis',
        GOALANALYSIS: '/visit/goalAnalysis',
        ACTIONDETAIL: '/visit/actionDetail',
    },
    COMPETITOR: {
        INDEX: '/competitor',
        SEARCH: '/competitor/search',
        CREATE: '/competitor/create',
        DETAIL: '/competitor/detail',
    },
    QUOTATION: {
        INDEX: '/quotation',
        SEARCH: '/quotation/search',
        CREATE: '/quotation/create',
        DETAIL: '/quotation/detail',
    },
    PRODUCT: {
        INDEX: '/product',
        SEARCH: '/product/search',
        DETAIL: '/product/detail',
        PROMOTIONSEARCH: '/product/promotionSearch',
        PROMOTIONDETAIL: '/product/promotionDetail',
    },
    RENTALRECORD: {
        INDEX: '/eyeFixedAssetRentalRecords',
        DETAIL: '/eyeFixedAssetRentalRecordsuct/detail',
    },
    PERFORMANCE: {
        INDEX: '/performance',
        ANALYSIS: '/performance/analysis',
        ORDER: {
            SEARCH: '/performance/order/search',
            DETAIL: '/performance/order/detail',
        },
        SALES_INVOICE: {
            SEARCH: '/performance/sales_invoice/search',
            DETAIL: '/performance/sales_invoice/detail',
        },
        DASHBOARD: '/performance/dashboard',
        DASHBOARDPRODUCTS: '/performance/dashboard_products',
        SALESFORECAST: '/performance/sales_forecast',
        PRODUCTSFORECAST: '/performance/products_forecast',
        MARKETINGTARGET: '/performance/marketing_target',
    },

    SERVICE_ORDER: {
        INDEX: '/service_order',
        SEARCH: '/service_order/search',
        DETAIL: '/service_order/detail',
        CREATE: '/service_order/create',
        PENDING: '/service_order/pending',
        WORK_DIARY: {
            SEARCH: '/work_diary/search',
            DETAIL: '/work_diary/detail',
            CREATE: '/work_diary/create',
            CALENDAR: '/work_diary/calendar',
        },
    },
    INVENTORY: { INDEX: '/inventory', SEARCH: '/inventory/search' },
};

export const SORT_DIRECTION = {
    ascend: EnumSortDirection.Asc,
    descend: EnumSortDirection.Desc,
};
