import React from 'react';

import { DragOverlay } from '@dnd-kit/core';
import { Menu } from 'antd';
import { iconToElement } from './draggable.menuNode';

interface Props {
    menuList: any[] | undefined;
    activeId: string | number | null;
}

const DragOverlayMenu = ({ menuList, activeId }: Props) => {
    const filterIconAndLabel: any = (id: string | number) => {
        return menuList
            ?.filter((e) => e?.key === id)
            .map((e: any) => ({ ...e, icon: iconToElement(e.icon) }));
    };
    return (
        <DragOverlay>
            {activeId ? (
                <Menu
                    style={{ background: '#406087', width: 184 }}
                    className={`side-menu overflow-y-scroll text-[#fff] rounded-lg`}
                    mode="inline"
                >
                    <Menu.Item
                        icon={filterIconAndLabel(activeId || '')?.[0]?.icon}
                        key="DragOverlay"
                    >
                        {filterIconAndLabel(activeId || '')?.[0]?.label}
                    </Menu.Item>
                </Menu>
            ) : null}
        </DragOverlay>
    );
};
export default DragOverlayMenu;
