import React, { useContext, useState } from 'react';
import { SSOLogin, SSOLogout, getSSOToken, removeSSOToken } from '@clinico/sso';
import {
    LoginBySsoDocument,
    MeDocument,
    useLoginBySsoMutation,
    useMeLazyQuery,
} from '@/graphql/types/generate';
import * as localStorageHelper from '@/helpers/localStorage.helper';
import configs from '@/configs';
import { ApolloClient } from '@apollo/client';

export const useAuth = () => {
    const [processing, setProcessing] = useState(false);
    const [userLogin] = useLoginBySsoMutation({});
    const [meQuery] = useMeLazyQuery();

    const getMe = async () => {
        const { data, error } = await meQuery();
        if (data) {
            localStorageHelper.setMe(data.me);
        } else {
            logout();
        }
    };

    /**
     * 登入
     */
    const login = async () => {
        setProcessing(true);

        let ssoToken = getSSOToken();
        if (!ssoToken) {
            // SSO Login
            ssoToken = await SSOLogin({
                url: configs.sso.url,
                forceCookie: process.env.NODE_ENV != 'production',
            });
        }

        const { data } = await userLogin({
            variables: { SSOToken: ssoToken },
        });
        localStorageHelper.setUserInfo(data!.loginBySSO.user);
        localStorageHelper.setToken(data!.loginBySSO.token ?? '');
        await getMe();

        setProcessing(false);
    };

    /**
     * 登出
     */
    const logout = async () => {
        await SSOLogout(configs.sso.url);
        removeSSOToken({
            domain: process.env.NODE_ENV === 'development' ? undefined : configs.sso.domain,
        });

        localStorageHelper.cleanAll();
        window.location.reload();
    };
    return { login, logout, processing };
};

const login = async (client: ApolloClient<any>) => {
    let ssoToken = getSSOToken();
    if (!ssoToken) {
        // SSO Login
        ssoToken = await SSOLogin({
            url: configs.sso.url,
            forceCookie: process.env.NODE_ENV != 'production',
        });
    }

    const { data } = await client.mutate({
        mutation: LoginBySsoDocument,
        variables: { SSOToken: ssoToken },
    });

    localStorageHelper.setUserInfo(data!.userLogin);
    localStorageHelper.setToken(data!.loginBySSO.token);
};

const getMe = async (client: ApolloClient<any>) => {
    const { data } = await client.query({
        query: MeDocument,
    });
    if (!localStorageHelper.getMe()) {
        localStorageHelper.setMe(data!.me);
    }
};

export const relogin = async (client: ApolloClient<any>) => {
    await login(client);
    await getMe(client);
};

export const logout = async () => {
    await SSOLogout(configs.sso.url);
    removeSSOToken({
        domain: process.env.NODE_ENV === 'development' ? undefined : configs.sso.domain,
    });
    localStorageHelper.cleanAll();
    window.location.reload();
};
